import React, { useCallback, useState } from 'react';

import Modal from '@material-ui/core/Modal';
import { DataGrid, GridColDef, GridRowId, ptBR } from '@material-ui/data-grid';
import { createMuiTheme, ThemeProvider, makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import Backdrop from '@material-ui/core/Backdrop';
import Tooltip from '@material-ui/core/Tooltip';
import Fade from '@material-ui/core/Fade';
import ButtonDialog from '@material-ui/core/Button';
import PageviewIcon from '@material-ui/icons/Pageview';
import ClearIcon from '@material-ui/icons/Clear';
import { useAuth } from '../../hooks/Auth';
import { useLookup } from '../../hooks/Lookup/index';
import IHistoricoLookup from '../../hooks/Lookup/IHistoricoLookup';

import api from '../../services/api';

import ICadHistPadrao from './ICadHistPadrao';
import { ColumnCodigoHistorico, ColumnDescricaoHistorico, ColumnId } from './GridColumns';
import { TitleSearch, ButtonGroup, ButtonSearch, ValuesSelected, ContainerSearch } from './styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    paper: {
      backgroundColor: theme.palette.background.paper,
      border: '1px solid #006699',
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
    },
  }),
);

const theme = createMuiTheme(
  {
    palette: {
      primary: { main: '#006699' },
    },
  },
  ptBR,
);

type functionConfirm = (list: IHistoricoLookup[]) => void;
type getIdConta = () => string;
interface ILookupHistoricoPadraoProps {
  onIdConta?: getIdConta;
  onTeste?: getIdConta;
  onConfirm?: functionConfirm;
  multiSelect?: boolean;
}

const titulo = 'H I S T Ó R I C O S    P A D R Õ E S';

const LookupHistoricoPadrao: React.FC<ILookupHistoricoPadraoProps> = ({
  onIdConta,

  multiSelect,
  onConfirm,
}) => {
  const { user } = useAuth();
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [selectedIds, setSelectedIds] = useState<GridRowId[]>([]);
  const { getListHistoricoPadrao, updateListHistoricoPadrao } = useLookup();
  const [listCadHistPadrao, setListCadHistPadrao] = useState<ICadHistPadrao[]>([]);
  const columns: GridColDef[] = [ColumnCodigoHistorico, ColumnDescricaoHistorico, ColumnId];

  const getSelected = useCallback((): string => {
    const listHistoricoPadrao = getListHistoricoPadrao();
    const text = listHistoricoPadrao.map(item => `${item.codigo_historico.trim()}-${item.descricao_historico.trim()}`);

    if (text.join() === '') return 'Nenhum histórico selecionado';

    return `${text.join(',  ')}`;
  }, [getListHistoricoPadrao]);

  const handleOnClickConfirm = useCallback((): void => {
    const filterList: IHistoricoLookup[] = selectedIds.map(id => {
      const item = listCadHistPadrao.find(valor => valor.id === id);

      if (item) {
        return {
          id: item.id,
          id_cad_hist_padrao: item.id,
          codigo_historico: item.codigo_historico,
          descricao_historico: item.descricao_historico,
        };
      }

      return {
        id: '',
        id_cad_hist_padrao: '',
        codigo_historico: '',
        descricao_historico: '',
      };
    });

    const list: IHistoricoLookup[] = filterList.filter(item => item.id !== '');

    updateListHistoricoPadrao(list);

    setOpen(false);
    if (onConfirm) {
      onConfirm(list);
    }
  }, [selectedIds, listCadHistPadrao, updateListHistoricoPadrao, onConfirm]);

  const handleOnClickCancel = (): void => {
    setOpen(false);
  };

  const getCadHistPadrao = useCallback(async () => {
    let responseList: ICadHistPadrao[] = [];
    let id_conta = '';
    let temConta = false;

    if (onIdConta) {
      id_conta = onIdConta();
      temConta = id_conta !== '' && id_conta !== null && id_conta !== undefined;

      if (temConta) {
        const response = await api.get<ICadHistPadrao[]>(`/demgercadcontahistorico`, {
          params: {
            id_license: user.license.id,
            id_company: user.empresa.id,
            id_conta,
          },
        });
        responseList = response.data;
      }

      if (!temConta || responseList.length <= 0) {
        const response = await api.get<ICadHistPadrao[]>(`/cadhistpadrao`, {
          params: {
            id_license: user.license.id,
            id_company: user.empresa.id,
          },
        });
        responseList = response.data;
      }
    } else {
      const response = await api.get<ICadHistPadrao[]>(`/cadhistpadrao`, {
        params: {
          id_license: user.license.id,
          id_company: user.empresa.id,
        },
      });
      responseList = response.data;
    }

    setListCadHistPadrao(responseList);

    const list = responseList.map(item => {
      const listHistoricoPadrao = getListHistoricoPadrao();
      const fintItem = listHistoricoPadrao.find(hist => hist.id === item.id);
      if (fintItem) return fintItem.id;

      return '';
    });
    const filterList = list.filter(item => item !== '');

    setSelectedIds(filterList);
  }, [user, getListHistoricoPadrao, onIdConta]);

  const handleOnClickOpen = useCallback(async () => {
    await getCadHistPadrao();

    setOpen(true);
  }, [getCadHistPadrao]);

  const handleOnClickClear = useCallback(async () => {
    updateListHistoricoPadrao([]);
  }, [updateListHistoricoPadrao]);

  return (
    <div>
      <ContainerSearch>
        <Tooltip title="Pesquisar" placement="top">
          <ButtonSearch type="button" onClick={handleOnClickOpen}>
            <PageviewIcon />
          </ButtonSearch>
        </Tooltip>
        <Tooltip title="Limpar" placement="top">
          <ButtonSearch type="button" onClick={handleOnClickClear}>
            <ClearIcon />
          </ButtonSearch>
        </Tooltip>

        <ValuesSelected>{getSelected()}</ValuesSelected>
      </ContainerSearch>

      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleOnClickConfirm}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
        disableBackdropClick
      >
        <Fade in={open}>
          <div className={classes.paper}>
            <TitleSearch id="transition-modal-title">{titulo}</TitleSearch>
            <div style={{ height: '600px', width: '700px' }}>
              <ThemeProvider theme={theme}>
                <DataGrid
                  rows={listCadHistPadrao}
                  columns={columns}
                  checkboxSelection={multiSelect}
                  selectionModel={selectedIds}
                  onSelectionModelChange={e => setSelectedIds(e.selectionModel)}
                />
              </ThemeProvider>
            </div>
            <ButtonGroup>
              <ButtonDialog onClick={handleOnClickCancel} color="secondary" autoFocus>
                Cancelar
              </ButtonDialog>
              <ButtonDialog onClick={handleOnClickConfirm} color="primary">
                Confirmar
              </ButtonDialog>
            </ButtonGroup>
          </div>
        </Fade>
      </Modal>
    </div>
  );
};

export default LookupHistoricoPadrao;
