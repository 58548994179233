import React, { useRef, useState, useEffect, useCallback } from 'react';
import * as Yup from 'yup';

import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';

import { DataGrid, GridColDef, GridCellParams, GridRowId, ptBR } from '@material-ui/data-grid';
import { createMuiTheme, ThemeProvider, makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';

import { FiCopy } from 'react-icons/fi';
import { MdDelete, MdEdit, MdClear, MdSkipNext, MdSkipPrevious, MdContentCopy } from 'react-icons/md';
import {
  AiOutlinePlus,
  AiOutlineSave,
  AiFillFastBackward,
  AiFillFastForward,
  AiOutlineCaretDown,
  AiOutlineCaretUp,
} from 'react-icons/ai';
import { GoPlus } from 'react-icons/go';
import { RiDeleteBinLine } from 'react-icons/ri';

import Header from '../../components/Header';
import InputComum from '../../components/InputComum';
import Select from '../../components/Select';
import Checkbox from '../../components/Checkbox';
import LookupHistoricoPadrao from '../../components/LookupHistoricoPadrao';
import FormModalDialogConfirm from '../../components/FormModalDialogConfirm';

import api from '../../services/api';
import { useAuth } from '../../hooks/Auth';
import { useToast } from '../../hooks/Toast';
import { useLookup } from '../../hooks/Lookup/index';

import getListUpdate from '../../utils/getListUpdate';
import { getPathGrid } from '../../utils/storagePath';
import getValidationErrors from '../../utils/getValidationErrors';

import {
  ColumnCodigoConta,
  ColumnCodigoContaSuperior,
  ColumnNomeConta,
  ColumnIndicadorConta,
  ColumnNivel,
  ColumnClassificacao,
  ColumnTPClassifDRE,
  ColumnCodNat,
  ColumnExigirCentroCusto,
  ColumnObservacao,
  ColumnInf1,
  ColumnInf2,
  ColumnInf3,
  ColumnInf4,
  ColumnInf5,
  ColumnId,
  ColumnAtiva,
} from './GridColumns';

import IDemGerCadConta, { emptyDemGerCadConta } from './IDemGerCadConta';
import IDemGerCadContaHistorico from './IDemGerCadContaHistorico';
import {
  Container,
  ContainerFields,
  Tab,
  TabButton,
  TabContent,
  FormFields,
  FormContainer,
  FormNavigate,
  ContainerFieldCheckbox,
  ContainerFieldGoup,
  TabTitulo,
  TabButtonArea,
  TabButtonNovo,
  TabButtonDelete,
  TabButtonGridRowHeight,
} from './styles';

const useStylesLoading = makeStyles((theme: Theme) =>
  createStyles({
    backdrop: {
      zIndex: theme.zIndex.drawer + 2000,
      color: '#fff',
    },
  }),
);

const theme = createMuiTheme(
  {
    palette: {
      primary: { main: '#006699' },
    },
  },
  ptBR,
);

const options_cod_nat = [
  { value: '01-Contas de Ativo', label: '01-Contas de Ativo' },
  { value: '02-Contas de Passivo', label: '02-Contas de Passivo' },
  { value: '03-Patrimônio Líquido', label: '03-Patrimônio Líquido' },
  { value: '04-Contas de Resultado', label: '04-Contas de Resultado' },
  { value: '05-Contas de Compensação', label: '05-Contas de Compensação' },
  { value: '09-Outras', label: '09-Outras' },
];

const options_ind_cta = [
  { value: '1-Sintético', label: '1-Sintético' },
  { value: '2-Analítico', label: '2-Analítico' },
];

const options_tp_classif_dre = [
  { value: '00-Não Influência', label: '00-Não Influência' },
  { value: '01-Receitas', label: '01-Receitas' },
  { value: '02-Deduções Receita', label: '02-Deduções Receita' },
  {
    value: '03-Custo Produtos/Mercadorias',
    label: '03-Custo Produtos/Mercadorias',
  },
  { value: '04-Custo Fixo', label: '04-Custo Fixo' },
  { value: '05-Custo Variável', label: '05-Custo Variável' },
  { value: '06-Despesa Fixa', label: '06-Despesa Fixa' },
  { value: '07-Despesa Variável', label: '07-Despesa Variável' },
  { value: '08-Receita Financeira', label: '08-Receita Financeira' },
  { value: '09-Despesa Financeira', label: '09-Despesa Financeira' },
  { value: '98-Outras Receitas', label: '98-Outras Receitas' },
  { value: '99-Outras Despesas', label: '99-Outras Despesas' },
];

const pageTitle = 'C O N T A S';
const fieldIdSelectTpClassifDRE = '9f6eb7105cb99d36920fe8344501df4f';

const DemGerCadConta: React.FC = () => {
  const { user } = useAuth();
  const { updateListHistoricoPadrao, getListHistoricoPadrao } = useLookup();
  const { addToast } = useToast();
  const formRef = useRef<FormHandles>(null);
  const [demGerCadConta, setDemGerCadConta] = useState<IDemGerCadConta>();
  const [listDemGerCadConta, setListDemGerCadConta] = useState<IDemGerCadConta[]>([]);
  const [hiddenPesquisar, setHiddenPesquisar] = useState<boolean>(true);
  const [hiddenCadastrar, setHiddenCadastrar] = useState<boolean>(true);
  const [demGerCadContaIndex, setDemGerCadContaIndex] = useState<number>(0);
  const [rowToEdit, setRowToEdit] = useState(null);
  const [idDeleteDemGerCadConta, setIdDeleteDemGerCadConta] = useState<string>('');
  const [selectedIds, setSelectedIds] = useState<GridRowId[]>([]);
  const [openDeleteSingle, setOpenDeleteSingle] = useState(false);
  const [openDeleteMultiple, setOpenDeleteMultiple] = useState(false);
  const [openLoading, setOpenLoading] = useState(false);
  const cssLoading = useStylesLoading();
  const [gridRowHeight, setGridRowHeight] = useState<number>(46);

  const handleGridRowHeightUp = useCallback(async () => {
    const heightValue: number = gridRowHeight + 1;
    setGridRowHeight(heightValue);
    localStorage.setItem(getPathGrid(), heightValue.toString());
  }, [gridRowHeight]);

  const handleGridRowHeightDown = useCallback(async () => {
    const heightValue: number = gridRowHeight - 1;
    setGridRowHeight(heightValue);
    localStorage.setItem(getPathGrid(), heightValue.toString());
  }, [gridRowHeight]);

  const licencaInvalida = useCallback((): boolean => {
    if (!user.license || user.license.id === '') {
      addToast({
        type: 'error',
        title: 'Licença inválida',
        description: 'Usuário não está vinculado a nenhuma licença',
      });

      return true;
    }

    return false;
  }, [addToast, user]);

  const defineDemGerCadConta = useCallback(
    (data: IDemGerCadConta) => {
      setDemGerCadConta(data);
      formRef.current?.setData(data);
      updateListHistoricoPadrao(data.historico);
    },
    [updateListHistoricoPadrao],
  );

  const onNewRegister = useCallback(async () => {
    defineDemGerCadConta(emptyDemGerCadConta);
  }, [defineDemGerCadConta]);

  const OnGridSetRegisterToEdit = useCallback(
    async (params: GridCellParams) => {
      const localDemGerCadConta = params.row as IDemGerCadConta;

      setDemGerCadContaIndex(listDemGerCadConta.indexOf(localDemGerCadConta));
      setHiddenCadastrar(false);
      setHiddenPesquisar(true);
      setRowToEdit(null);
      defineDemGerCadConta(localDemGerCadConta);
    },
    [listDemGerCadConta, defineDemGerCadConta],
  );

  const OnSetRegisterToAdd = useCallback(async () => {
    onNewRegister();
    setHiddenCadastrar(false);
    setHiddenPesquisar(true);
  }, [onNewRegister]);

  const OnGridRowDuplicate = useCallback(
    async (baseDemGerCadConta: IDemGerCadConta | undefined) => {
      if (baseDemGerCadConta !== undefined) {
        const newDemGerCadConta: IDemGerCadConta = {
          acao: baseDemGerCadConta.acao,
          classificacao: baseDemGerCadConta.classificacao,
          tp_classif_dre: baseDemGerCadConta.tp_classif_dre,
          cod_cta: '',
          cod_cta_superior: baseDemGerCadConta.cod_cta_superior,
          nivel: baseDemGerCadConta.nivel,
          cod_nat: baseDemGerCadConta.cod_nat,
          id_company: baseDemGerCadConta.id_company,
          id_license: baseDemGerCadConta.id_license,
          ind_cta: baseDemGerCadConta.ind_cta,
          exigir_centro_custo: baseDemGerCadConta.exigir_centro_custo,
          historico_permite_complemento: baseDemGerCadConta.historico_permite_complemento,
          inf1: baseDemGerCadConta.inf1,
          inf2: baseDemGerCadConta.inf2,
          inf3: baseDemGerCadConta.inf3,
          inf4: baseDemGerCadConta.inf4,
          inf5: baseDemGerCadConta.inf5,
          obs: baseDemGerCadConta.obs,
          historico: baseDemGerCadConta.historico,
          ativa: baseDemGerCadConta.ativa,
          nome_cta: '',
          id: '',
        };

        defineDemGerCadConta(newDemGerCadConta);

        setHiddenCadastrar(false);
        setHiddenPesquisar(true);
      }
    },
    [defineDemGerCadConta],
  );

  const OnConfirmationDeleteSingle = useCallback(
    async (id: string) => {
      if (licencaInvalida()) return;

      if (id === '') return;

      setIdDeleteDemGerCadConta(id);
      setOpenDeleteSingle(true);
    },
    [licencaInvalida],
  );

  const ColumnAcao: GridColDef = {
    field: 'acao',
    headerName: 'Ação',
    editable: false,
    filterable: false,
    hideSortIcons: true,
    sortable: false,
    disableColumnMenu: true,
    width: 160,
    renderCell: (params: GridCellParams) => (
      <strong>
        <Tooltip title="Editar Registro" placement="top">
          <IconButton color="primary" aria-label="Editar" onClick={() => OnGridSetRegisterToEdit(params)}>
            <MdEdit />
          </IconButton>
        </Tooltip>

        <Tooltip title="Duplicar Registro" placement="top">
          <IconButton
            color="primary"
            aria-label="Duplicar"
            onClick={() => OnGridRowDuplicate(params.row as IDemGerCadConta)}
          >
            <FiCopy />
          </IconButton>
        </Tooltip>

        <Tooltip title="Excluir Registro" placement="top">
          <IconButton
            color="primary"
            aria-label="Excluir"
            onClick={() => OnConfirmationDeleteSingle((params.row as IDemGerCadConta).id)}
          >
            <MdDelete />
          </IconButton>
        </Tooltip>
      </strong>
    ),
  };
  const columns: GridColDef[] = [
    ColumnAcao,
    ColumnCodigoConta,
    ColumnCodigoContaSuperior,
    ColumnNomeConta,
    ColumnIndicadorConta,
    ColumnNivel,
    ColumnClassificacao,
    ColumnTPClassifDRE,
    ColumnCodNat,
    ColumnExigirCentroCusto,
    ColumnAtiva,
    ColumnObservacao,
    ColumnInf1,
    ColumnInf2,
    ColumnInf3,
    ColumnInf4,
    ColumnInf5,
    ColumnId,
  ];

  const getDemGerCadConta = useCallback(async () => {
    setOpenLoading(true);
    const response = await api.get<IDemGerCadConta[]>(`/demgercadconta`, {
      params: {
        id_license: user.license.id,
        id_company: user.empresa.id,
      },
    });

    setListDemGerCadConta(response.data);

    if (rowToEdit !== null) {
      const findEditDemGerCadConta = response.data.find(item => item.id === rowToEdit);

      if (findEditDemGerCadConta) {
        setDemGerCadContaIndex(response.data.indexOf(findEditDemGerCadConta));
        setHiddenCadastrar(false);
        setHiddenPesquisar(true);
        setRowToEdit(null);
      }
    }

    setOpenLoading(false);
  }, [user, rowToEdit]);

  const getValues = useCallback(async () => {
    if (licencaInvalida()) return;

    await getDemGerCadConta();
    setHiddenPesquisar(false);
  }, [licencaInvalida, getDemGerCadConta]);

  useEffect(() => {
    getValues();

    const rowHeight = localStorage.getItem(getPathGrid());
    if (rowHeight) {
      setGridRowHeight(parseInt(rowHeight, 10));
    }
  }, [getValues]);

  const handleOnRowDeleteSingle = useCallback(async () => {
    setOpenLoading(true);

    await api.delete(`/demgercadconta/${idDeleteDemGerCadConta}`);

    setListDemGerCadConta(oldDemGerCadConta => oldDemGerCadConta.filter(item => item.id !== idDeleteDemGerCadConta));

    defineDemGerCadConta(emptyDemGerCadConta);

    addToast({
      type: 'success',
      title: 'Removido',
      description: 'Registro excluído com sucesso!',
    });

    setOpenDeleteSingle(false);
    setOpenLoading(false);
  }, [defineDemGerCadConta, addToast, idDeleteDemGerCadConta]);

  const handleOnRowDeleteMultiple = useCallback(async () => {
    setOpenLoading(true);

    if (selectedIds.length > 0) {
      await Promise.all(
        selectedIds.map(itemId => {
          setListDemGerCadConta(oldDemGerCadConta => oldDemGerCadConta.filter(item => item.id !== itemId));

          return api.delete(`/demgercadconta/${itemId}`);
        }),
      );

      defineDemGerCadConta(emptyDemGerCadConta);

      addToast({
        type: 'success',
        title: 'Removido',
        description: 'Registro excluído com sucesso!',
      });
    }

    setSelectedIds([]);
    setOpenDeleteMultiple(false);
    setOpenLoading(false);
  }, [addToast, defineDemGerCadConta, selectedIds]);

  const handleClick = (id: string): void => {
    setHiddenCadastrar(id === 'pesquisar');
    setHiddenPesquisar(id === 'cadastrar');
  };

  const onNextRegister = useCallback(async () => {
    if (demGerCadContaIndex !== undefined) {
      if (demGerCadContaIndex < listDemGerCadConta.length - 1) {
        const localIndex = demGerCadContaIndex + 1;
        setDemGerCadContaIndex(localIndex);
        const findEditDemGerCadConta = listDemGerCadConta[localIndex];
        if (findEditDemGerCadConta) {
          defineDemGerCadConta(findEditDemGerCadConta);
        }
      }
    }
  }, [demGerCadContaIndex, listDemGerCadConta, defineDemGerCadConta]);

  const onFirstRegister = useCallback(async () => {
    if (listDemGerCadConta.length > 0) {
      setDemGerCadContaIndex(0);
      const findEditDemGerCadConta = listDemGerCadConta[0];
      if (findEditDemGerCadConta) {
        defineDemGerCadConta(findEditDemGerCadConta);
      }
    }
  }, [listDemGerCadConta, defineDemGerCadConta]);

  const onLastRegister = useCallback(async () => {
    if (listDemGerCadConta.length > 0) {
      setDemGerCadContaIndex(listDemGerCadConta.length - 1);
      const findEditDemGerCadConta = listDemGerCadConta[listDemGerCadConta.length - 1];
      if (findEditDemGerCadConta) {
        defineDemGerCadConta(findEditDemGerCadConta);
      }
    }
  }, [listDemGerCadConta, defineDemGerCadConta]);

  const onPriorRegister = useCallback(async () => {
    if (demGerCadContaIndex !== undefined) {
      if (demGerCadContaIndex > 0) {
        const localIndex = demGerCadContaIndex - 1;
        setDemGerCadContaIndex(localIndex);
        const findEditDemGerCadConta = listDemGerCadConta[localIndex];
        if (findEditDemGerCadConta) {
          defineDemGerCadConta(findEditDemGerCadConta);
        }
      }
    }
  }, [demGerCadContaIndex, listDemGerCadConta, defineDemGerCadConta]);

  const onCancelRegister = useCallback(async () => {
    formRef.current?.setErrors({});

    if (demGerCadContaIndex !== undefined) {
      const findEditDemGerCadConta = listDemGerCadConta[demGerCadContaIndex];
      if (findEditDemGerCadConta) {
        defineDemGerCadConta(findEditDemGerCadConta);
      }
    }

    onNewRegister();
  }, [demGerCadContaIndex, listDemGerCadConta, onNewRegister, defineDemGerCadConta]);

  const handleSubmit = useCallback(
    async (data: IDemGerCadConta, { reset }) => {
      if (licencaInvalida()) return;

      formRef.current?.setErrors({});
      try {
        const schema = Yup.object().shape({
          ind_cta: Yup.string().required('Indicador da conta é obrigatório'),
          cod_cta: Yup.string().required('Conta é obrigatória'),
          nome_cta: Yup.string().required('Nome da conta é obrigatória'),
          nivel: Yup.number().required('Nível é obrigatório'),
          cod_cta_superior: Yup.string().required('Conta superior é obrigatória'),
          cod_nat: Yup.string().required('Grupo de contas é obrigatório'),
          classificacao: Yup.string().required('Classificação é obrigatória'),
          tp_classif_dre: Yup.string(),
          obs: Yup.string(),
          inf1: Yup.string(),
          inf2: Yup.string(),
          inf3: Yup.string(),
          inf4: Yup.string(),
          inf5: Yup.string(),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        setOpenLoading(true);
        data.historico = getListHistoricoPadrao();

        const formData: IDemGerCadConta = {
          id_company: user.empresa.id,
          id_license: user.license.id,
          ind_cta: data.ind_cta,
          nivel: data.nivel,
          cod_cta: data.cod_cta,
          nome_cta: data.nome_cta,
          cod_cta_superior: data.cod_cta_superior,
          cod_nat: data.cod_nat,
          classificacao: data.classificacao,
          tp_classif_dre: data.tp_classif_dre,
          obs: data.obs,
          inf1: data.inf1,
          inf2: data.inf2,
          inf3: data.inf3,
          inf4: data.inf4,
          inf5: data.inf5,
          historico: data.historico,
          acao: data.acao,
          exigir_centro_custo: data.exigir_centro_custo,
          historico_permite_complemento: data.historico_permite_complemento,
          ativa: data.ativa,
          id: data.id,
        };

        if (data.id === '') {
          const response = await api.post(`/demgercadconta`, formData);
          formData.id = response.data.id;
          await api.delete(`/demgercadcontahistorico/${formData.id}`);
          await Promise.all(
            formData.historico.map(item => {
              const valor: IDemGerCadContaHistorico = {
                id_cad_hist_padrao: item.id_cad_hist_padrao,
                id_company: user.empresa.id,
                id_license: user.license.id,
                id_dem_ger_cad_conta: formData.id,
              };

              return api.post(`/demgercadcontahistorico`, valor);
            }),
          );
          setListDemGerCadConta(oldLicenses => [...oldLicenses, formData]);

          addToast({
            type: 'success',
            title: 'Adicionado',
            description: 'Registro adicionado com sucesso!',
          });
        } else {
          await api.put(`/demgercadconta/${formData.id}`, formData);

          await api.delete(`/demgercadcontahistorico/${formData.id}`);

          await Promise.all(
            formData.historico.map(item => {
              const valor: IDemGerCadContaHistorico = {
                id_cad_hist_padrao: item.id_cad_hist_padrao,
                id_company: user.empresa.id,
                id_license: user.license.id,
                id_dem_ger_cad_conta: formData.id,
              };

              return api.post(`/demgercadcontahistorico`, valor);
            }),
          );

          setListDemGerCadConta(getListUpdate(listDemGerCadConta, formData));

          addToast({
            type: 'success',
            title: 'Alterado',
            description: 'Registro alterado com sucesso!',
          });
        }

        defineDemGerCadConta(emptyDemGerCadConta);
        reset();
        setOpenLoading(false);
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          formRef.current?.setErrors(errors);
        }
        setOpenLoading(false);
      }
    },
    [licencaInvalida, listDemGerCadConta, user, addToast, getListHistoricoPadrao, defineDemGerCadConta],
  );

  return (
    <Container>
      <Header />

      <Tab>
        <TabButtonArea>
          <TabButton id="pesquisar" isActive={!hiddenPesquisar} onClick={() => handleClick('pesquisar')}>
            Listagem
          </TabButton>
          <TabButton id="cadastrar" isActive={!hiddenCadastrar} onClick={() => handleClick('cadastrar')}>
            Cadastro
          </TabButton>

          <Tooltip title="Reduzir o tamanho da linha" placement="top">
            <TabButtonGridRowHeight id="gridrowheightdown" onClick={() => handleGridRowHeightDown()}>
              <AiOutlineCaretDown />
            </TabButtonGridRowHeight>
          </Tooltip>

          <Tooltip title="Aumentar o tamanho da linha" placement="top">
            <TabButtonGridRowHeight id="gridrowheightup" onClick={() => handleGridRowHeightUp()}>
              <AiOutlineCaretUp />
            </TabButtonGridRowHeight>
          </Tooltip>

          <Tooltip title="Novo Registro" placement="top">
            <TabButtonNovo id="new_register" onClick={() => OnSetRegisterToAdd()}>
              <GoPlus />
              Novo
            </TabButtonNovo>
          </Tooltip>

          <Tooltip title="Remover Registros Selecionados" placement="top">
            <span>
              <span hidden={selectedIds.length <= 0}>
                <TabButtonDelete
                  id="remove_all"
                  onClick={() => {
                    setIdDeleteDemGerCadConta('');
                    setOpenDeleteMultiple(true);
                  }}
                >
                  <RiDeleteBinLine />
                  Remover
                </TabButtonDelete>
              </span>
            </span>
          </Tooltip>
        </TabButtonArea>

        <TabTitulo>
          <span>{pageTitle}</span>
        </TabTitulo>
      </Tab>

      <TabContent id="pesquisar" isActive={!hiddenPesquisar}>
        <div style={{ height: '85vh', width: '100%' }}>
          <ThemeProvider theme={theme}>
            <DataGrid
              rows={listDemGerCadConta}
              columns={columns}
              rowHeight={gridRowHeight}
              checkboxSelection
              selectionModel={selectedIds}
              disableSelectionOnClick
              onSelectionModelChange={e => setSelectedIds(e.selectionModel)}
            />
          </ThemeProvider>
          ;
        </div>
      </TabContent>

      <TabContent id="cadasto" isActive={!hiddenCadastrar}>
        <Form ref={formRef} onSubmit={handleSubmit} initialData={demGerCadConta}>
          <FormContainer>
            <FormNavigate>
              <Tooltip title="Novo Registro" placement="top">
                <IconButton color="primary" aria-label="adicionar" onClick={() => onNewRegister()}>
                  <AiOutlinePlus />
                </IconButton>
              </Tooltip>

              <Tooltip title="Duplicar Registro" placement="top">
                <IconButton color="primary" aria-label="Duplicar" onClick={() => OnGridRowDuplicate(demGerCadConta)}>
                  <MdContentCopy />
                </IconButton>
              </Tooltip>

              <Tooltip title="Excluir Registro" placement="top">
                <IconButton
                  color="primary"
                  aria-label="Excluir"
                  onClick={() => OnConfirmationDeleteSingle(demGerCadConta ? demGerCadConta.id : '')}
                >
                  <RiDeleteBinLine />
                </IconButton>
              </Tooltip>

              <Tooltip title="Cancelar" placement="top">
                <IconButton type="reset" color="primary" aria-label="Cancelar" onClick={() => onCancelRegister()}>
                  <MdClear />
                </IconButton>
              </Tooltip>

              <Tooltip title="Salvar" placement="top">
                <IconButton type="submit" color="primary" aria-label="Confirmar">
                  <AiOutlineSave />
                </IconButton>
              </Tooltip>

              <Tooltip title="Primeiro Registro" placement="top">
                <IconButton color="primary" aria-label="Primeiro" onClick={() => onFirstRegister()}>
                  <AiFillFastBackward />
                </IconButton>
              </Tooltip>

              <Tooltip title="Registro Anterior" placement="top">
                <IconButton color="primary" aria-label="Anterior" onClick={() => onPriorRegister()}>
                  <MdSkipPrevious />
                </IconButton>
              </Tooltip>

              <Tooltip title="Próximo Registro" placement="top">
                <IconButton color="primary" aria-label="Próximo" onClick={() => onNextRegister()}>
                  <MdSkipNext />
                </IconButton>
              </Tooltip>

              <Tooltip title="Último Registro" placement="top">
                <IconButton color="primary" aria-label="Último registro" onClick={() => onLastRegister()}>
                  <AiFillFastForward />
                </IconButton>
              </Tooltip>
            </FormNavigate>

            <FormFields>
              <ContainerFieldCheckbox field="T300px">
                <Checkbox name="ativa" />
                <span>Ativa</span>
              </ContainerFieldCheckbox>

              <ContainerFieldGoup>
                <ContainerFields field="T200px">
                  <span>Conta:</span>
                  <InputComum autoFocus name="cod_cta" />
                </ContainerFields>

                <ContainerFields field="T200px" className="field2">
                  <span>Conta superior:</span>
                  <InputComum name="cod_cta_superior" />
                </ContainerFields>
              </ContainerFieldGoup>

              <ContainerFields field="T100">
                <span>Nome conta:</span>
                <InputComum name="nome_cta" />
              </ContainerFields>

              <ContainerFields field="T300px">
                <span>Indicador conta:</span>
                <Select name="ind_cta" defaultValue="1-Sintético">
                  {options_ind_cta.map(option => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </Select>
              </ContainerFields>

              <ContainerFields field="T100">
                <span>Nível:</span>
                <InputComum name="nivel" type="number" min="0" />
              </ContainerFields>

              <ContainerFields field="T100">
                <span>Classificação:</span>
                <InputComum name="classificacao" />
              </ContainerFields>

              <ContainerFieldGoup>
                <ContainerFields field="T300px">
                  <span>Grupo de contas:</span>
                  <Select name="cod_nat" defaultValue="01-Contas de Ativo">
                    {options_cod_nat.map(option => (
                      <option key={option.value} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </Select>
                </ContainerFields>

                <ContainerFields field="T400px" className="field2">
                  <span>Tipo classificação DRE:</span>
                  <Select id={fieldIdSelectTpClassifDRE} name="tp_classif_dre" defaultValue="00-Não Influência">
                    {options_tp_classif_dre.map(option => (
                      <option key={option.value} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </Select>
                </ContainerFields>

                <ContainerFieldCheckbox field="T300px" className="field2">
                  <Checkbox name="exigir_centro_custo" />
                  <span>Exigir Centro Custo</span>
                </ContainerFieldCheckbox>
              </ContainerFieldGoup>

              <ContainerFields field="T100">
                <span>Históricos:</span>
                <LookupHistoricoPadrao multiSelect />
              </ContainerFields>

              <ContainerFieldCheckbox field="T300px">
                <Checkbox name="historico_permite_complemento" />
                <span>Histórico Permite Complemento</span>
              </ContainerFieldCheckbox>

              <ContainerFields field="T100">
                <span>Observação:</span>
                <InputComum name="obs" />
              </ContainerFields>

              <ContainerFields field="T100">
                <span>Informação 1:</span>
                <InputComum name="inf1" />
              </ContainerFields>

              <ContainerFields field="T100">
                <span>Informação 2:</span>
                <InputComum name="inf2" />
              </ContainerFields>

              <ContainerFields field="T100">
                <span>Informação 3:</span>
                <InputComum name="inf3" />
              </ContainerFields>

              <ContainerFields field="T100">
                <span>Informação 4:</span>
                <InputComum name="inf4" />
              </ContainerFields>

              <ContainerFields field="T100">
                <span>Informação 5:</span>
                <InputComum name="inf5" />
              </ContainerFields>

              <ContainerFields field="id">
                <InputComum name="id" hidden />
              </ContainerFields>
            </FormFields>
          </FormContainer>
        </Form>
      </TabContent>

      <FormModalDialogConfirm
        open={openDeleteSingle}
        onClose={() => setOpenDeleteSingle(false)}
        onConfirm={() => handleOnRowDeleteSingle()}
        buttonCancelText="Cancelar"
        buttonConfirmText="Sim"
        formTitle="Excluir?"
        messageConfirm="Tem certeza que deseja excluir?"
      />

      <FormModalDialogConfirm
        open={openDeleteMultiple}
        onClose={() => setOpenDeleteMultiple(false)}
        onConfirm={() => handleOnRowDeleteMultiple()}
        buttonCancelText="Cancelar"
        buttonConfirmText="Sim"
        formTitle="Excluir?"
        messageConfirm="Tem certeza que deseja excluir todos os registros selecionados?"
      />

      <Backdrop className={cssLoading.backdrop} open={openLoading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </Container>
  );
};

export default DemGerCadConta;
