import React, { useRef, useState, useEffect, useCallback } from 'react';
import * as Yup from 'yup';
import { uuid } from 'uuidv4';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';

import { DataGrid, GridColDef, GridCellParams, GridRowId, ptBR } from '@material-ui/data-grid';
import { createMuiTheme, ThemeProvider, makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import { FiCopy } from 'react-icons/fi';
import { MdDelete, MdEdit, MdClear, MdSkipNext, MdSkipPrevious, MdContentCopy } from 'react-icons/md';
import {
  AiOutlinePlus,
  AiOutlineSave,
  AiFillFastBackward,
  AiFillFastForward,
  AiOutlineCaretDown,
  AiOutlineCaretUp,
} from 'react-icons/ai';
import { RiDeleteBinLine } from 'react-icons/ri';
import { GoPlus } from 'react-icons/go';
import InputComum from '../../components/InputComum';
import InputID from '../../components/InputID';
import Select from '../../components/Select';
import Header from '../../components/Header';
import FormModalDialogConfirm from '../../components/FormModalDialogConfirm';

import api from '../../services/api';
import { useAuth } from '../../hooks/Auth';
import { useToast } from '../../hooks/Toast';

import getListUpdateV2 from '../../utils/getListUpdateV2';
import { getPathGrid } from '../../utils/storagePath';
import getValidationErrors from '../../utils/getValidationErrors';

import {
  ColumnAno,
  Columnsituacao,
  ColumnNomeOrigem,
  ColumnIdUsuarioResponsavel,
  ColumnUsuarioResponsavel,
  ColumnObservacao,
  ColumnInformacao1,
  ColumnInformacao2,
  ColumnInformacao3,
  ColumnInformacao4,
  ColumnInformacao5,
  ColumnID,
  ColumnCompany,
  ColumnIndTipoMovimento,
} from './GridColumns';

import ICfgPeriodoContabil, { emptyCfgPeriodoContabil } from './ICfgPeriodoContabil';
import IAPIPeriodoContabil from './IAPIPeriodoContabil';
import IUsers from './IUsers';
import ICompany from './ICompany';
import {
  Container,
  ContainerFields,
  Tab,
  TabButton,
  TabContent,
  FormFields,
  FormContainer,
  FormNavigate,
  TabTitulo,
  TabButtonArea,
  TabButtonNovo,
  TabButtonDelete,
  TabButtonGridRowHeight,
  ContainerFieldGoup,
} from './styles';

const useStylesLoading = makeStyles((theme: Theme) =>
  createStyles({
    backdrop: {
      zIndex: theme.zIndex.drawer + 2000,
      color: '#fff',
    },
  }),
);

const theme = createMuiTheme(
  {
    palette: {
      primary: { main: '#006699' },
    },
  },
  ptBR,
);

const options_situacao = [
  { value: '0-Aberto', label: '0-Aberto' },
  { value: '1-Em Edição', label: '1-Em Edição' },
  { value: '2-Congelado', label: '2-Congelado' },
];

const options_origem = [
  { value: '0-Sped Contábil', label: '0-Sped Contábil' },
  { value: '1-Ferramenta ETL', label: '1-Ferramenta ETL' },
  { value: '2-Recebido via API', label: '2-Recebido via API' },
  { value: '3-Inserido Manual', label: '3-Inserido Manual' },
  { value: '4-Importado Excel', label: '4-Importado Excel' },
  { value: '9-Outras Fontes', label: '9-Outras Fontes' },
];

const options_ind_tipo_movimento = [
  { value: '1-Saldos contábeis', label: '1-Saldos contábeis' },
  { value: '2-Lançamentos', label: '2-Lançamentos' },
];

const titulo = 'P E R Í O D O     C O N T A B I L';
const idfieldSelectUser = '22swdf515533rasd';
const idfieldSelectCompany = 'uiasdy7-92';

const CfgPeriodoContabil: React.FC = () => {
  const { user } = useAuth();
  const { addToast } = useToast();
  const formRef = useRef<FormHandles>(null);
  const [cfgPeriodoContabil, setCfgPeriodoContabil] = useState<ICfgPeriodoContabil>();
  const [listCfgPeriodoContabil, setListCfgPeriodoContabil] = useState<ICfgPeriodoContabil[]>([]);
  const [hiddenPesquisar, setHiddenPesquisar] = useState<boolean>(false);
  const [hiddenCadastrar, setHiddenCadastrar] = useState<boolean>(true);
  const [cfgPeriodoContabilIndex, setCfgPeriodoContabilIndex] = useState<number>(0);
  const [rowToEdit, setRowToEdit] = useState(null);
  const [accessKeyDeleteCfgPeriodoContabil, setAccessKeyDeleteCfgPeriodoContabil] = useState<string>('');
  const [selectedIds, setSelectedIds] = useState<GridRowId[]>([]);
  const [openDeleteSingle, setOpenDeleteSingle] = useState(false);
  const [openDeleteMultiple, setOpenDeleteMultiple] = useState(false);
  const [openLoading, setOpenLoading] = useState(false);
  const cssLoading = useStylesLoading();
  const [gridRowHeight, setGridRowHeight] = useState<number>(46);
  const [listUsers, setListUsers] = useState<IUsers[]>([]);
  const [listCompany, setListCompany] = useState<ICompany[]>([]);

  const handleGridRowHeightUp = useCallback(async () => {
    const heightValue: number = gridRowHeight + 1;
    setGridRowHeight(heightValue);
    localStorage.setItem(getPathGrid(), heightValue.toString());
  }, [gridRowHeight]);

  const handleGridRowHeightDown = useCallback(async () => {
    const heightValue: number = gridRowHeight - 1;
    setGridRowHeight(heightValue);
    localStorage.setItem(getPathGrid(), heightValue.toString());
  }, [gridRowHeight]);

  const licencaInvalida = useCallback((): boolean => {
    if (!user.license || user.license.id === '') {
      addToast({
        type: 'error',
        title: 'Licença inválida',
        description: 'Usuário não está vinculado a nenhuma licença',
      });

      return true;
    }

    return false;
  }, [addToast, user]);

  const defineCfgPeriodoContabil = useCallback(async (data: ICfgPeriodoContabil) => {
    const temp = {
      ...data,
      ano: data.ano || new Date().getFullYear(),
      situacao: data.situacao || '0-Aberto',
      origem: data.origem || '0-Sped Contábil',
      ind_tipo_movimento: data.ind_tipo_movimento || '1-Saldos contábeis',
    };
    setCfgPeriodoContabil(temp);
    formRef.current?.setData(temp);
  }, []);

  const OnGridSetRegisterToEdit = useCallback(
    async (params: GridCellParams) => {
      const localRow = params.row as ICfgPeriodoContabil;

      setCfgPeriodoContabilIndex(listCfgPeriodoContabil.indexOf(localRow));
      setHiddenCadastrar(false);
      setHiddenPesquisar(true);
      setRowToEdit(null);
      defineCfgPeriodoContabil(localRow);
    },
    [listCfgPeriodoContabil, defineCfgPeriodoContabil],
  );

  const onNewRegister = useCallback(async () => {
    defineCfgPeriodoContabil(emptyCfgPeriodoContabil);
  }, [defineCfgPeriodoContabil]);

  const OnSetRegisterToAdd = useCallback(async () => {
    onNewRegister();
    setHiddenCadastrar(false);
    setHiddenPesquisar(true);
  }, [onNewRegister]);

  const OnGridRowDuplicate = useCallback(
    async (baseTipoDemGerCompany: ICfgPeriodoContabil | undefined) => {
      if (baseTipoDemGerCompany !== undefined) {
        const newRow: ICfgPeriodoContabil = {
          ...baseTipoDemGerCompany,
          access_key: '',
          id: 0,
        };

        defineCfgPeriodoContabil(newRow);
        setHiddenCadastrar(false);
        setHiddenPesquisar(true);
      }
    },
    [defineCfgPeriodoContabil],
  );

  const OnConfirmationDeleteSingle = useCallback(
    async (inAccessKey: string) => {
      if (licencaInvalida()) return;

      if (inAccessKey === '') return;

      setAccessKeyDeleteCfgPeriodoContabil(inAccessKey);
      setOpenDeleteSingle(true);
    },
    [licencaInvalida],
  );

  const colunaAcao: GridColDef = {
    field: 'acao',
    headerName: 'Ação',
    editable: false,
    filterable: false,
    hideSortIcons: true,
    sortable: false,
    disableColumnMenu: true,
    width: 160,
    renderCell: (params: GridCellParams) => (
      <strong>
        <Tooltip title="Editar Registro" placement="top">
          <IconButton color="primary" aria-label="Editar" onClick={() => OnGridSetRegisterToEdit(params)}>
            <MdEdit />
          </IconButton>
        </Tooltip>

        <Tooltip title="Duplicar Registro" placement="top">
          <IconButton
            color="primary"
            aria-label="Duplicar"
            onClick={() => OnGridRowDuplicate(params.row as ICfgPeriodoContabil)}
          >
            <FiCopy />
          </IconButton>
        </Tooltip>

        <Tooltip title="Excluir Registro" placement="top">
          <IconButton
            color="primary"
            aria-label="Excluir"
            onClick={() => OnConfirmationDeleteSingle((params.row as ICfgPeriodoContabil).access_key)}
          >
            <MdDelete />
          </IconButton>
        </Tooltip>
      </strong>
    ),
  };

  const ColumnDataUltimaAtualizacao: GridColDef = {
    field: 'data_ultima_atualizacao',
    headerName: 'Data última atualização',
    width: 240,
    type: 'date',
    headerAlign: 'left',
    align: 'left',
    renderCell: (params: GridCellParams) => {
      if (params.value === null || !params.value) return <strong />;

      const ano = (params.value as string).substring(0, 4);
      const mes = (params.value as string).substring(5, 7);
      const dia = (params.value as string).substring(8, 10);
      const dateLocaleString = params.value.toLocaleString();
      const tmpDate = new Date(dateLocaleString);

      const dataFormatada = `${dia}/${mes}/${ano} ${tmpDate
        .getHours()
        .toString()
        .padStart(2, '0')}:${tmpDate
        .getMinutes()
        .toString()
        .padStart(2, '0')}:${tmpDate.getSeconds().toString().padStart(2, '0')}`;
      return <strong>{dataFormatada}</strong>;
    },
  };

  const columns: GridColDef[] = [
    colunaAcao,
    ColumnCompany,
    ColumnAno,
    Columnsituacao,
    ColumnIndTipoMovimento,
    ColumnNomeOrigem,
    ColumnDataUltimaAtualizacao,
    ColumnIdUsuarioResponsavel,
    ColumnUsuarioResponsavel,
    ColumnObservacao,
    ColumnInformacao1,
    ColumnInformacao2,
    ColumnInformacao3,
    ColumnInformacao4,
    ColumnInformacao5,
    ColumnID,
  ];

  const getCfgPeriodoContabil = useCallback(async () => {
    const response = await api.post<IAPIPeriodoContabil[]>(`/cfgperiodocontabil/filter`, {
      license: user.license.id,
    });

    const tempList: ICfgPeriodoContabil[] = response.data
      .map(iemList => {
        return {
          ...iemList,
          access_key: iemList.access_key || uuid(),
          company: `${iemList.code}-${iemList.name}[${iemList.cnpj}]`,
        };
      })
      .sort((a, b) => {
        if (a.code.toLowerCase() < b.code.toLowerCase()) return -1;
        if (a.code.toLowerCase() > b.code.toLowerCase()) return 1;
        if (a.name.toLowerCase() < b.name.toLowerCase()) return -1;
        if (a.name.toLowerCase() > b.name.toLowerCase()) return 1;
        if (a.ano < b.ano) return -1;
        if (a.ano > b.ano) return 1;
        return 0;
      });

    setListCfgPeriodoContabil(tempList);

    if (rowToEdit !== null) {
      const findItem = tempList.find(item => item.access_key === rowToEdit);

      if (findItem) {
        defineCfgPeriodoContabil(findItem);
        setCfgPeriodoContabilIndex(tempList.indexOf(findItem));
        setHiddenCadastrar(false);
        setHiddenPesquisar(true);
        setRowToEdit(null);
      }
    }
  }, [user, rowToEdit, defineCfgPeriodoContabil]);

  const getUsers = useCallback(async () => {
    const response = await api.get<IUsers[]>(`/userlicense/all/${user.license.id}`);
    const list = response.data.filter(item => !['suporte@assessortech.com.br'].includes(item.email));
    setListUsers(list);
  }, [user]);

  const getCompany = useCallback(async () => {
    const response = await api.get<ICompany[]>(`/company/${user.license.id}`);
    setListCompany(
      response.data.sort((a, b) => {
        if (a.code.toLowerCase() < b.code.toLowerCase()) return -1;
        if (a.code.toLowerCase() > b.code.toLowerCase()) return 1;
        if (a.name.toLowerCase() < b.name.toLowerCase()) return -1;
        if (a.name.toLowerCase() > b.name.toLowerCase()) return 1;
        return 0;
      }),
    );
  }, [user]);

  const getValues = useCallback(async () => {
    if (licencaInvalida()) return;

    setOpenLoading(true);
    await getCfgPeriodoContabil();
    await getUsers();
    await getCompany();
    setOpenLoading(false);
    setHiddenPesquisar(false);
  }, [licencaInvalida, getCfgPeriodoContabil, getUsers, getCompany]);

  useEffect(() => {
    getValues();

    const rowHeight = localStorage.getItem(getPathGrid());
    if (rowHeight) {
      setGridRowHeight(parseInt(rowHeight, 10));
    }
  }, [getValues]);

  const handleOnRowDeleteSingle = useCallback(async () => {
    setOpenLoading(true);

    await api.delete(`/cfgperiodocontabil/${accessKeyDeleteCfgPeriodoContabil}`);
    await getValues();

    addToast({
      type: 'success',
      title: 'Removido',
      description: 'Registro excluído com sucesso!',
    });

    setOpenDeleteSingle(false);
    setOpenLoading(false);
  }, [accessKeyDeleteCfgPeriodoContabil, addToast, getValues]);

  const handleOnRowDeleteMultiple = useCallback(async () => {
    setOpenLoading(true);

    if (selectedIds.length > 0) {
      await Promise.all(
        selectedIds.map(accessKey => {
          setListCfgPeriodoContabil(oldDemGerCadCCusto =>
            oldDemGerCadCCusto.filter(item => item.access_key !== accessKey),
          );

          return api.delete(`/cfgperiodocontabil/${accessKey}`);
        }),
      );

      await getValues();

      addToast({
        type: 'success',
        title: 'Removido',
        description: 'Registro excluído com sucesso!',
      });
    }

    setSelectedIds([]);
    setOpenDeleteMultiple(false);
    setOpenLoading(false);
  }, [addToast, getValues, selectedIds]);

  const handleClick = (id: string): void => {
    setHiddenCadastrar(id === 'pesquisar');
    setHiddenPesquisar(id === 'cadastrar');
  };

  const onNextRegister = useCallback(async () => {
    if (cfgPeriodoContabilIndex !== undefined) {
      if (cfgPeriodoContabilIndex < listCfgPeriodoContabil.length - 1) {
        const localIndex = cfgPeriodoContabilIndex + 1;
        setCfgPeriodoContabilIndex(localIndex);
        const findItem = listCfgPeriodoContabil[localIndex];
        if (findItem) {
          defineCfgPeriodoContabil(findItem);
        }
      }
    }
  }, [cfgPeriodoContabilIndex, listCfgPeriodoContabil, defineCfgPeriodoContabil]);

  const onFirstRegister = useCallback(async () => {
    if (listCfgPeriodoContabil.length > 0) {
      setCfgPeriodoContabilIndex(0);
      const findItem = listCfgPeriodoContabil[0];
      if (findItem) {
        defineCfgPeriodoContabil(findItem);
      }
    }
  }, [listCfgPeriodoContabil, defineCfgPeriodoContabil]);

  const onLastRegister = useCallback(async () => {
    if (listCfgPeriodoContabil.length > 0) {
      setCfgPeriodoContabilIndex(listCfgPeriodoContabil.length - 1);
      const findItem = listCfgPeriodoContabil[listCfgPeriodoContabil.length - 1];
      if (findItem) {
        defineCfgPeriodoContabil(findItem);
      }
    }
  }, [listCfgPeriodoContabil, defineCfgPeriodoContabil]);

  const onPriorRegister = useCallback(async () => {
    if (cfgPeriodoContabilIndex !== undefined) {
      if (cfgPeriodoContabilIndex > 0) {
        const localIndex = cfgPeriodoContabilIndex - 1;
        setCfgPeriodoContabilIndex(localIndex);
        const findItem = listCfgPeriodoContabil[localIndex];
        if (findItem) {
          defineCfgPeriodoContabil(findItem);
        }
      }
    }
  }, [cfgPeriodoContabilIndex, listCfgPeriodoContabil, defineCfgPeriodoContabil]);

  const onCancelRegister = useCallback(async () => {
    formRef.current?.setErrors({});

    if (cfgPeriodoContabilIndex !== undefined) {
      const findItem = listCfgPeriodoContabil[cfgPeriodoContabilIndex];
      if (findItem) {
        defineCfgPeriodoContabil(findItem);
      }
    }
  }, [cfgPeriodoContabilIndex, listCfgPeriodoContabil, defineCfgPeriodoContabil]);

  const getDateFormated = (inValue: Date | undefined): string => {
    if (!inValue) return '-';

    const localData = new Date(inValue.toString());
    const localFullYear = localData.getFullYear();
    const localMonth = (localData.getMonth() + 1).toString().padStart(2, '0');
    const localDate = localData.getDate().toString().padStart(2, '0');
    const localHours = localData.getHours().toString().padStart(2, '0');
    const localMinutes = localData.getMinutes().toString().padStart(2, '0');
    const localSeconds = localData.getSeconds().toString().padStart(2, '0');
    return `${localDate}/${localMonth}/${localFullYear}  ${localHours}:${localMinutes}:${localSeconds}`;
  };

  const getDescricaoCompanySelectOption = useCallback((value: ICompany): string => {
    if (!value) return '';

    if (value.code) {
      return `${value.code.trim()}-${value.name.trim()}[${value.cnpj.trim()}]`;
    }
    return `${value.name.trim()}[${value.cnpj.trim()}]`;
  }, []);

  const getCompanySelectOption = useCallback(
    (inId: string): ICompany => {
      return listCompany.filter(item => item.id === inId)[0];
    },
    [listCompany],
  );

  const handleSubmit = useCallback(
    async (data: ICfgPeriodoContabil, { reset }) => {
      if (licencaInvalida()) return;

      const fieldSelectCompany = document.getElementById(idfieldSelectCompany);
      if (fieldSelectCompany) {
        const localId = (fieldSelectCompany as HTMLSelectElement).value;
        data.id_company = localId;
        data.company = getDescricaoCompanySelectOption(getCompanySelectOption(localId));
      }

      formRef.current?.setErrors({});
      try {
        const schema = Yup.object().shape({
          id_company: Yup.string().required('Empresa é obrigatória'),
          ano: Yup.number().required('Ano é obrigatório'),
          situacao: Yup.string().required('Situação é obrigatória'),
          origem: Yup.string().required('Origem é obrigatório'),
          id_usuario_responsavel: Yup.string().required('Responsável é obrigatório'),
          ind_tipo_movimento: Yup.string().required('Tipo movimento é obrigatório'),
          obs: Yup.string(),
          inf1: Yup.string(),
          inf2: Yup.string(),
          inf3: Yup.string(),
          inf4: Yup.string(),
          inf5: Yup.string(),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        if ([0, '0', null, undefined].includes(data.id)) {
          const findItem = listCfgPeriodoContabil.find(
            itemList =>
              itemList.id_company === data.id_company && itemList.origem === data.origem && itemList.ano === data.ano,
          );

          if (findItem) {
            addToast({
              type: 'error',
              title: 'Configuração já existe',
              description: `Já existe uma configuração para ${data.ano}, com a origem ${data.origem}, para a empresa ${data.company}!`,
            });
            return;
          }
        }

        setOpenLoading(true);

        const formData: ICfgPeriodoContabil = {
          acao: data.acao,
          access_key: data.access_key,
          ano: data.ano,
          data_ultima_atualizacao: data.data_ultima_atualizacao,
          id: data.id,
          id_usuario_responsavel: data.id_usuario_responsavel,
          ind_tipo_movimento: data.ind_tipo_movimento,
          origem: data.origem,
          situacao: data.situacao,
          usuario_responsavel: data.usuario_responsavel,
          id_company: data.id_company,
          id_license: data.id_license,
          inf1: data.inf1,
          inf2: data.inf2,
          inf3: data.inf3,
          inf4: data.inf4,
          inf5: data.inf5,
          obs: data.obs,
          company: data.company,
          updated_at: data.updated_at,
          created_at: data.created_at,
        };

        if ([0, '0', null, undefined].includes(data.id)) {
          const response = await api.post(`/cfgperiodocontabil`, {
            ...formData,
            id_license: user.license.id,
            id: 0,
            access_key: '',
          });
          formData.id = response.data.id;
          setListCfgPeriodoContabil(oldValues => [...oldValues, formData]);

          addToast({
            type: 'success',
            title: 'Adicionado',
            description: 'Registro adicionado com sucesso!',
          });
        } else {
          await api.put(`/cfgperiodocontabil/${formData.access_key}`, formData);

          setListCfgPeriodoContabil(getListUpdateV2(listCfgPeriodoContabil, formData));

          addToast({
            type: 'success',
            title: 'Alterado',
            description: 'Registro alterado com sucesso!',
          });
        }
        await getValues();
        setHiddenPesquisar(true);
        reset();
        setOpenLoading(false);
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          formRef.current?.setErrors(errors);
        }
        setOpenLoading(false);
      }
    },
    [
      licencaInvalida,
      listCfgPeriodoContabil,
      addToast,
      getValues,
      getCompanySelectOption,
      getDescricaoCompanySelectOption,
      user,
    ],
  );

  return (
    <Container>
      <Header />

      <Tab>
        <TabButtonArea>
          <TabButton id="pesquisar" isActive={!hiddenPesquisar} onClick={() => handleClick('pesquisar')}>
            Listagem
          </TabButton>
          <TabButton id="cadastrar" isActive={!hiddenCadastrar} onClick={() => handleClick('cadastrar')}>
            Cadastro
          </TabButton>

          <Tooltip title="Reduzir o tamanho da linha" placement="top">
            <TabButtonGridRowHeight id="gridrowheightdown" onClick={() => handleGridRowHeightDown()}>
              <AiOutlineCaretDown />
            </TabButtonGridRowHeight>
          </Tooltip>

          <Tooltip title="Aumentar o tamanho da linha" placement="top">
            <TabButtonGridRowHeight id="gridrowheightup" onClick={() => handleGridRowHeightUp()}>
              <AiOutlineCaretUp />
            </TabButtonGridRowHeight>
          </Tooltip>

          <Tooltip title="Novo Registro" placement="top">
            <TabButtonNovo id="new_register" onClick={() => OnSetRegisterToAdd()}>
              <GoPlus />
              Novo
            </TabButtonNovo>
          </Tooltip>

          <Tooltip title="Remover Registros Selecionados" placement="top">
            <span>
              <span hidden={selectedIds.length <= 0}>
                <TabButtonDelete
                  id="remove_all"
                  onClick={() => {
                    setAccessKeyDeleteCfgPeriodoContabil('');
                    setOpenDeleteMultiple(true);
                  }}
                >
                  <RiDeleteBinLine />
                  Remover
                </TabButtonDelete>
              </span>
            </span>
          </Tooltip>
        </TabButtonArea>

        <TabTitulo>
          <span>{titulo}</span>
        </TabTitulo>
      </Tab>

      <TabContent id="pesquisar" isActive={!hiddenPesquisar}>
        <div style={{ height: '85vh', width: '100%' }}>
          <ThemeProvider theme={theme}>
            <DataGrid
              rows={listCfgPeriodoContabil}
              columns={columns}
              rowHeight={gridRowHeight}
              checkboxSelection
              selectionModel={selectedIds}
              disableSelectionOnClick
              getRowId={r => r.access_key}
              onSelectionModelChange={e => setSelectedIds(e.selectionModel)}
            />
          </ThemeProvider>
        </div>
      </TabContent>

      <TabContent id="cadasto" isActive={!hiddenCadastrar}>
        <Form ref={formRef} onSubmit={handleSubmit} initialData={cfgPeriodoContabil}>
          <FormContainer>
            <FormNavigate>
              <Tooltip title="Novo Registro" placement="top">
                <IconButton color="primary" aria-label="adicionar" onClick={() => onNewRegister()}>
                  <AiOutlinePlus />
                </IconButton>
              </Tooltip>

              <Tooltip title="Duplicar Registro" placement="top">
                <IconButton
                  color="primary"
                  aria-label="Duplicar"
                  onClick={() => OnGridRowDuplicate(cfgPeriodoContabil)}
                >
                  <MdContentCopy />
                </IconButton>
              </Tooltip>

              <Tooltip title="Excluir Registro" placement="top">
                <IconButton
                  color="primary"
                  aria-label="Excluir"
                  disabled={!cfgPeriodoContabil?.id}
                  onClick={() => OnConfirmationDeleteSingle(cfgPeriodoContabil ? cfgPeriodoContabil.access_key : '')}
                >
                  <RiDeleteBinLine />
                </IconButton>
              </Tooltip>

              <Tooltip title="Cancelar" placement="top">
                <IconButton type="reset" color="primary" aria-label="Cancelar" onClick={() => onCancelRegister()}>
                  <MdClear />
                </IconButton>
              </Tooltip>

              <Tooltip title="Salvar" placement="top">
                <IconButton type="submit" color="primary" aria-label="Confirmar">
                  <AiOutlineSave />
                </IconButton>
              </Tooltip>

              <Tooltip title="Primeiro Registro" placement="top">
                <IconButton color="primary" aria-label="Primeiro" onClick={() => onFirstRegister()}>
                  <AiFillFastBackward />
                </IconButton>
              </Tooltip>

              <Tooltip title="Registro Anterior" placement="top">
                <IconButton color="primary" aria-label="Anterior" onClick={() => onPriorRegister()}>
                  <MdSkipPrevious />
                </IconButton>
              </Tooltip>

              <Tooltip title="Próximo Registro" placement="top">
                <IconButton color="primary" aria-label="Próximo" onClick={() => onNextRegister()}>
                  <MdSkipNext />
                </IconButton>
              </Tooltip>

              <Tooltip title="Último Registro" placement="top">
                <IconButton color="primary" aria-label="Último registro" onClick={() => onLastRegister()}>
                  <AiFillFastForward />
                </IconButton>
              </Tooltip>
            </FormNavigate>

            <FormFields>
              <ContainerFields field="T100">
                <span>Empresa:</span>
                <Select id={idfieldSelectCompany} name="id_company">
                  {listCompany.map(item => (
                    <option key={item.id} value={item.id}>
                      {getDescricaoCompanySelectOption(item)}
                    </option>
                  ))}
                </Select>
              </ContainerFields>

              <ContainerFieldGoup>
                <ContainerFields field="T80px">
                  <span>Ano:</span>
                  <InputComum
                    type="number"
                    name="ano"
                    id="ano"
                    step={1}
                    max="3000"
                    defaultValue={new Date().getFullYear()}
                  />
                </ContainerFields>

                <ContainerFields field="T200px" className="field2">
                  <span>Situação:</span>
                  <Select name="situacao" defaultValue="0-Aberto">
                    {options_situacao.map(itemList => (
                      <option key={itemList.value} value={itemList.value}>
                        {itemList.label}
                      </option>
                    ))}
                  </Select>
                </ContainerFields>

                <ContainerFields field="T300px" className="field2">
                  <span>Tipo movimento:</span>
                  <Select name="ind_tipo_movimento" defaultValue="1-Saldos contábeis">
                    {options_ind_tipo_movimento.map(itemList => (
                      <option key={itemList.value} value={itemList.value}>
                        {itemList.label}
                      </option>
                    ))}
                  </Select>
                </ContainerFields>

                <ContainerFields field="T300px" className="field2">
                  <span>Origem Dados Contábeis:</span>
                  <Select name="origem" defaultValue="0-Sped Contábil">
                    {options_origem.map(itemList => (
                      <option key={itemList.value} value={itemList.value}>
                        {itemList.label}
                      </option>
                    ))}
                  </Select>
                </ContainerFields>
              </ContainerFieldGoup>

              <ContainerFields field="T100">
                <span>Usuário Responsável:</span>
                <Select id={idfieldSelectUser} name="id_usuario_responsavel">
                  {listUsers.map(itemUser => (
                    <option key={itemUser.id_user} value={itemUser.id_user}>
                      {itemUser.name} [{itemUser.email}]
                    </option>
                  ))}
                </Select>
              </ContainerFields>

              <ContainerFields field="T100">
                <span>Observação:</span>
                <InputComum name="obs" />
              </ContainerFields>

              <ContainerFields field="T100">
                <span>Informação 1:</span>
                <InputComum name="inf1" />
              </ContainerFields>

              <ContainerFields field="T100">
                <span>Informação 2:</span>
                <InputComum name="inf2" />
              </ContainerFields>

              <ContainerFields field="T100">
                <span>Informação 3:</span>
                <InputComum name="inf3" />
              </ContainerFields>

              <ContainerFields field="T100">
                <span>Informação 4:</span>
                <InputComum name="inf4" />
              </ContainerFields>

              <ContainerFields field="T100">
                <span>Informação 5:</span>
                <InputComum name="inf5" />
              </ContainerFields>

              <ContainerFieldGoup>
                <ContainerFields field="T200px">
                  <span>Inserido em:</span>
                  <div>
                    <span
                      style={{
                        fontWeight: 'bold',
                      }}
                    >
                      {getDateFormated(cfgPeriodoContabil?.created_at)}
                    </span>
                  </div>
                </ContainerFields>

                <ContainerFields field="T300px" className="field2">
                  <span>Alterado em:</span>
                  <div>
                    <span
                      style={{
                        fontWeight: 'bold',
                      }}
                    >
                      {getDateFormated(cfgPeriodoContabil?.updated_at)}
                    </span>
                  </div>
                </ContainerFields>

                <ContainerFields field="T300px" className="field2">
                  <span>Última atualização contábil:</span>
                  <div>
                    <span
                      style={{
                        fontWeight: 'bold',
                      }}
                    >
                      {getDateFormated(cfgPeriodoContabil?.data_ultima_atualizacao)}
                    </span>
                  </div>
                </ContainerFields>
              </ContainerFieldGoup>

              <ContainerFields field="TID">
                <InputID name="id" hidden />
                <InputID name="access_key" hidden />
                <InputID name="id_license" hidden />
                <InputID name="company" hidden />
                <InputID name="usuario_responsavel" hidden />
                <InputID name="data_ultima_atualizacao" hidden />
              </ContainerFields>
            </FormFields>
          </FormContainer>
        </Form>
      </TabContent>

      <FormModalDialogConfirm
        open={openDeleteSingle}
        onClose={() => setOpenDeleteSingle(false)}
        onConfirm={() => handleOnRowDeleteSingle()}
        buttonCancelText="Cancelar"
        buttonConfirmText="Sim"
        formTitle="Excluir?"
        messageConfirm="Tem certeza que deseja excluir?"
      />

      <FormModalDialogConfirm
        open={openDeleteMultiple}
        onClose={() => setOpenDeleteMultiple(false)}
        onConfirm={() => handleOnRowDeleteMultiple()}
        buttonCancelText="Cancelar"
        buttonConfirmText="Sim"
        formTitle="Excluir?"
        messageConfirm="Tem certeza que deseja excluir todos os registros selecionados?"
      />

      <Backdrop className={cssLoading.backdrop} open={openLoading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </Container>
  );
};

export default CfgPeriodoContabil;
