import React from 'react';
import { useHistory } from 'react-router-dom';

import AccountCircle from '@material-ui/icons/AccountCircle';
import ExitToApp from '@material-ui/icons/ExitToApp';
import Apartment from '@material-ui/icons/Apartment';
import Settings from '@material-ui/icons/Settings';

import { useAuth } from '../../hooks/Auth';
import { ROUTE_PROFILE, ROUTE_COMPANY_CHANGE } from '../../utils/constants';

import ButtonDropdownMenu from './ButtonDropdownMenu';
import { ContainerDropdown, DropdownContentUserSettings } from './styles';

const MenuUserSettings: React.FC = () => {
  const { signOut } = useAuth();
  const history = useHistory();

  const handleGoProfile = (): void => {
    history.push(ROUTE_PROFILE);
  };

  const handleGoCompanyChange = (): void => {
    history.push(ROUTE_COMPANY_CHANGE);
  };

  const handleSignOut = (): void => {
    signOut();
  };

  return (
    <ContainerDropdown>
      <button type="button">
        <AccountCircle />
      </button>
      <DropdownContentUserSettings>
        <ButtonDropdownMenu onClick={handleGoProfile} icon={Settings}>
          <span>Perfil</span>
        </ButtonDropdownMenu>
        <ButtonDropdownMenu onClick={handleGoCompanyChange} icon={Apartment}>
          <span>Acessar Empresa</span>
        </ButtonDropdownMenu>
        <ButtonDropdownMenu onClick={handleSignOut} icon={ExitToApp}>
          <span>Sair</span>
        </ButtonDropdownMenu>
      </DropdownContentUserSettings>
    </ContainerDropdown>
  );
};

export default MenuUserSettings;
