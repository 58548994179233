import React, { useCallback, useState, useEffect, KeyboardEvent } from 'react';

import Modal from '@material-ui/core/Modal';
import { DataGrid, GridColDef, GridRowId, ptBR } from '@material-ui/data-grid';
import { createMuiTheme, ThemeProvider, makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import Backdrop from '@material-ui/core/Backdrop';
import Tooltip from '@material-ui/core/Tooltip';
import Fade from '@material-ui/core/Fade';
import ButtonDialog from '@material-ui/core/Button';
import PageviewIcon from '@material-ui/icons/Pageview';

import { useLookup } from '../../hooks/Lookup/index';
import IBancoLookup, { emptyBancoLookup } from '../../hooks/Lookup/IBancoLookup';

import api from '../../services/api';

import { GridColumnBanco, GridColumnDescricao, GridColumnId } from './GridColumns';
import { TitleSearch, ButtonGroup, ButtonSearch, ContainerLookup, ContainerSearch } from './styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    paper: {
      backgroundColor: theme.palette.background.paper,
      border: '1px solid #006699',
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
    },
  }),
);

const theme = createMuiTheme(
  {
    palette: {
      primary: { main: '#006699' },
    },
  },
  ptBR,
);

type functionConfirm = (list: IBancoLookup[]) => void;
type functionGetTextInput = (value: string) => void;
type getIdBanco = () => string;
interface ILookupBancoProps {
  in_id?: string;
  idInputLookup: string;
  onIdBanco?: getIdBanco;
  onConfirm?: functionConfirm;
  onGetTextInput?: functionGetTextInput;
  multiSelect?: boolean;
  clearInputSearchField?: boolean;
}

const LookupBanco: React.FC<ILookupBancoProps> = ({
  in_id,
  idInputLookup,
  onIdBanco,
  multiSelect,
  onConfirm,
  onGetTextInput,
  clearInputSearchField,
}) => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [idBanco, setIdBanco] = React.useState<string>('');
  const [inputTextValue, setInputTextValue] = React.useState<string>('');
  const [selectedIds, setSelectedIds] = useState<GridRowId[]>([]);
  const { getListBanco, updateListBanco } = useLookup();
  const [listBancoRegister, setListBancoRegister] = useState<IBancoLookup[]>([]);

  const columns: GridColDef[] = [GridColumnBanco, GridColumnDescricao, GridColumnId];

  const handleOnClickConfirm = useCallback((): void => {
    const filterList: IBancoLookup[] = selectedIds.map(id => {
      const item = listBancoRegister.find(valor => valor.id === id);

      if (item) {
        return {
          id: item.id,
          banco: item.banco,
          descricao: item.descricao,
        };
      }

      return emptyBancoLookup;
    });

    const list: IBancoLookup[] = filterList.filter(item => item.id !== '');

    const fieldInputSearch = document.getElementById(idInputLookup);
    if (fieldInputSearch) {
      if (clearInputSearchField) {
        setInputTextValue('');
        (fieldInputSearch as HTMLInputElement).value = '';
      } else {
        const inputValue = list.map(item => item.descricao);
        if (onGetTextInput) {
          onGetTextInput(inputValue.join());
        }
        setInputTextValue(inputValue.join());
        (fieldInputSearch as HTMLInputElement).value = inputValue.join();
      }
    }

    updateListBanco(list);

    setOpen(false);
    if (onConfirm) {
      onConfirm(list);
    }
  }, [
    selectedIds,
    listBancoRegister,
    updateListBanco,
    onConfirm,
    onGetTextInput,
    clearInputSearchField,
    idInputLookup,
  ]);

  const handleOnClickCancel = useCallback((): void => {
    setOpen(false);
  }, []);

  const getCadBancoById = useCallback(
    async (id: string) => {
      let responseList: IBancoLookup[] = [];
      if (id !== '' && id !== null && id !== undefined) {
        const response = await api.get<IBancoLookup[]>(`/cadbanco/filter`, {
          params: {
            id_banco: id,
            descricao: '',
          },
        });

        responseList = response.data;
      } else {
        setInputTextValue('');
      }

      setListBancoRegister(responseList);
      let item: IBancoLookup = emptyBancoLookup;
      if (responseList.length > 0) {
        item = {
          id: responseList[0].id,
          banco: responseList[0].banco,
          descricao: responseList[0].descricao,
        };
      }

      const fieldInputSearch = document.getElementById(idInputLookup);
      if (fieldInputSearch) {
        setInputTextValue(item.descricao);
        (fieldInputSearch as HTMLInputElement).value = item.descricao;
      }

      updateListBanco([item]);

      if (onConfirm) {
        onConfirm([item]);
      }
    },
    [updateListBanco, onConfirm, idInputLookup],
  );

  const getInputValue = useCallback((): string => {
    const fieldInputSearch = document.getElementById(idInputLookup);
    let inputValue = '';
    if (fieldInputSearch) {
      inputValue = (fieldInputSearch as HTMLInputElement).value;
    }

    return inputValue;
  }, [idInputLookup]);

  const getCadBanco = useCallback(
    async (cadBancoDescricao: string) => {
      let responseList: IBancoLookup[] = [];
      let id_Banco = '';
      let assignIdBanco = false;

      if (onIdBanco) {
        id_Banco = onIdBanco();
        assignIdBanco = id_Banco !== '' && id_Banco !== null && id_Banco !== undefined;

        if (assignIdBanco) {
          const response = await api.get<IBancoLookup[]>(`/cadbanco/filter`, {
            params: {
              id_banco: id_Banco,
              descricao: '',
            },
          });
          responseList = response.data;
        }

        if (!assignIdBanco || responseList.length <= 0) {
          const response = await api.get<IBancoLookup[]>(`/cadbanco/filter`, {
            params: {
              id_banco: '',
              descricao: cadBancoDescricao,
            },
          });
          responseList = response.data;
        }
      } else if (cadBancoDescricao.trim() !== cadBancoDescricao && inputTextValue !== getInputValue()) {
        const response = await api.get<IBancoLookup[]>(`/cadbanco/filter`, {
          params: {
            id_banco: '',
            descricao: cadBancoDescricao,
          },
        });
        responseList = response.data;
      } else {
        const response = await api.get<IBancoLookup[]>(`/cadbanco/filter`, {
          params: {
            id_banco: '',
            descricao: '',
          },
        });
        responseList = response.data;
      }

      setListBancoRegister(responseList);
      const listCadBanco = getListBanco();

      const list = responseList.map(item => {
        const fintItem = listCadBanco.find(itemBanco => itemBanco.id === item.id);
        if (fintItem) return fintItem.id;

        return '';
      });

      const filterList = list.filter(item => item !== '');

      setSelectedIds(filterList);

      setOpen(true);
    },
    [onIdBanco, getListBanco, getInputValue, inputTextValue],
  );

  useEffect(() => {
    if (in_id !== undefined && in_id !== idBanco) {
      setIdBanco(in_id);
      getCadBancoById(in_id);
    }
  }, [idBanco, in_id, getCadBancoById]);

  const handleOnClickOpen = useCallback(async () => {
    await getCadBanco(getInputValue());
  }, [getCadBanco, getInputValue]);

  const handleOnKeyUpInputSearch = useCallback(
    (e: KeyboardEvent<HTMLInputElement>) => {
      if (e.key.toUpperCase() === 'ENTER') {
        e.preventDefault();
        getCadBanco(e.currentTarget.value);
      } else if (e.key.toUpperCase() === 'BACKSPACE') {
        updateListBanco([]);
      }
    },
    [getCadBanco, updateListBanco],
  );

  return (
    <div id={`w2${idInputLookup}87`} style={{ width: '100%' }}>
      <ContainerLookup>
        <ContainerSearch>
          <Tooltip title="Pesquisar Banco" placement="top">
            <ButtonSearch type="button" onClick={handleOnClickOpen}>
              <PageviewIcon />
            </ButtonSearch>
          </Tooltip>

          <input id={idInputLookup} type="text" placeholder="Pesquisar Banco..." onKeyDown={handleOnKeyUpInputSearch} />
        </ContainerSearch>
      </ContainerLookup>

      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleOnClickConfirm}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
        disableBackdropClick
      >
        <Fade in={open}>
          <div className={classes.paper}>
            <TitleSearch id="transition-modal-title">T O T A L I Z A D O R E S</TitleSearch>
            <div style={{ height: '450px', width: '700px' }}>
              <ThemeProvider theme={theme}>
                <DataGrid
                  headerHeight={40}
                  rowHeight={25}
                  rows={listBancoRegister}
                  columns={columns}
                  checkboxSelection={multiSelect}
                  selectionModel={selectedIds}
                  getRowId={r => r.id}
                  onSelectionModelChange={e => setSelectedIds(e.selectionModel)}
                  onRowDoubleClick={handleOnClickConfirm}
                />
              </ThemeProvider>
            </div>
            <ButtonGroup>
              <ButtonDialog onClick={handleOnClickCancel} color="secondary" autoFocus>
                Cancelar
              </ButtonDialog>
              <ButtonDialog onClick={handleOnClickConfirm} color="primary">
                Confirmar
              </ButtonDialog>
            </ButtonGroup>
          </div>
        </Fade>
      </Modal>
    </div>
  );
};

export default LookupBanco;
