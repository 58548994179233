import styled, { css } from 'styled-components';

interface IContainerFieldsProps {
  field: 'T200px' | 'T280px' | 'T300px' | 'T400px' | 'T100' | 'check' | 'id';
}

export const Container = styled.div`
  width: 400px;

  p {
    &.ext_file {
      font-size: 10px;
      color: #006699;
      margin-bottom: 10px;
    }
  }
`;

export const TitleHead = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  align-content: space-between;
`;

export const TitleDiv = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  align-content: space-between;
  width: 85%;

  svg {
    margin-left: 20px;
    width: 32px;
    height: 32px;
  }
`;

export const ContainerFields = styled.div<IContainerFieldsProps>`
  padding: 4px 0px 0px 0px;

  ${props =>
    props.field &&
    props.field === 'T200px' &&
    css`
      width: 200px;
    `}

  ${props =>
    props.field &&
    props.field === 'T400px' &&
    css`
      width: 400px;
    `}

  ${props =>
    props.field &&
    props.field === 'T300px' &&
    css`
      width: 300px;
    `}

  ${props =>
    props.field &&
    props.field === 'T100' &&
    css`
      width: 100%;
    `}

  ${props =>
    props.field &&
    props.field === 'id' &&
    css`
      padding: 0px 0px 30px 0px;
      height: 0px;
      width: 0px;
    `}

  &.field2 {
    margin-left: 60px;
  }

  &.fieldmr {
    margin-right: 6px;
  }

  @media (max-width: 720px) {
    &.field2 {
      margin-left: 0px;
    }
  }
`;

export const ContainerFilterSelect = styled.div`
  background: #fff;
  border-radius: 4px;
  padding: 5.5px;
  width: 100%;

  border: 1px solid #666360;
  color: #666360;

  display: flex;
  align-items: center;

  & + div {
    margin-top: 8px;
  }

  select {
    flex: 1;
    background: transparent;
    border: 0;
    color: #000;
    width: 100%;
    border: none;
    height: 100%;
    font-size: 16px;
    cursor: pointer;

    &::placeholder {
      color: #989898;
    }
  }

  option {
    display: block;
    position: absolute;
    cursor: pointer;
    padding: 15px 0;
    font-size: 16px;
    zoom: 1.2;
  }

  svg {
    margin-right: 16px;
  }
`;

export const ContainerDiv = styled.div`
  display: flex;
  flex-direction: row;

  &.price2 {
    margin-left: 60px;
  }

  @media (max-width: 720px) {
    flex-direction: column;

    &.price2 {
      margin-left: 0px;
    }
  }
`;

export const ContainerFilterInput = styled.div`
  background: #fff;
  border-radius: 4px;
  padding: 5px;
  width: 100%;
  border: 1px solid #666360;
  color: #666360;
  display: flex;
  align-items: center;

  & + div {
    margin-top: 1px;
  }

  input {
    border: none;
    width: 100%;
  }
`;
