import { GridColDef } from '@material-ui/data-grid';

export const ColumnTipoLancamento: GridColDef = {
  headerName: 'Tipo Lançamento',
  field: 'tipo',
  width: 190,
};

export const ColumnSituacao: GridColDef = {
  headerName: 'Situação',
  field: 'situacao',
  width: 150,
};

export const ColumnValor: GridColDef = {
  headerName: 'Valor',
  field: 'valor_original',
  width: 150,
};

export const ColumnValorQuitacao: GridColDef = {
  headerName: 'Valor Quitação',
  field: 'valor_quitacao',
  width: 170,
};

export const ColumnDescricao: GridColDef = {
  headerName: 'Descrição',
  field: 'descricao_movimento',
  width: 400,
};

export const ColumnContaCorrente: GridColDef = {
  headerName: 'Conta Corrente',
  field: 'conta_corrente',
  width: 400,
};

export const ColumnBanco: GridColDef = {
  headerName: 'Banco',
  field: 'banco',
  width: 400,
};

export const ColumnConta: GridColDef = {
  headerName: 'Conta',
  field: 'conta',
  width: 400,
};

export const ColumnCentroCusto: GridColDef = {
  headerName: 'Centro Custo',
  field: 'centro_custo',
  width: 400,
};

export const ColumnContaTransferencia: GridColDef = {
  headerName: 'Conta Transferência',
  field: 'conta_corrente_transferencia_descricao',
  width: 400,
};

export const ColumnLoteImportacao: GridColDef = {
  headerName: 'Lote importacao',
  field: 'lote_importacao',
  width: 500,
};

export const ColumnId: GridColDef = {
  headerName: '#',
  field: 'id',
  hide: true,
  width: 400,
  editable: false,
  filterable: false,
  hideSortIcons: true,
  sortable: false,
  disableColumnMenu: true,
};
