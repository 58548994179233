import React, { useRef, useState, useCallback, useEffect } from 'react';
import * as Yup from 'yup';
import { uuid } from 'uuidv4';

import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';

import { DataGrid, GridColDef, GridCellParams, GridRowId, ptBR } from '@material-ui/data-grid';
import {
  createMuiTheme,
  ThemeProvider,
  makeStyles,
  createStyles,
  darken,
  lighten,
  Theme,
} from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import Chip from '@material-ui/core/Chip';
import IconButton from '@material-ui/core/IconButton';
import CircularProgress from '@material-ui/core/CircularProgress';
import Backdrop from '@material-ui/core/Backdrop';
import AddIcon from '@material-ui/icons/Add';
import ButtonDialog from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';

import { FiCopy, FiUpload, FiDownload, FiUploadCloud } from 'react-icons/fi';
import { MdDelete, MdEdit, MdClear, MdSkipNext, MdSkipPrevious, MdContentCopy } from 'react-icons/md';
import {
  AiOutlineSearch,
  AiOutlineSave,
  AiFillFastBackward,
  AiFillFastForward,
  AiOutlineCaretDown,
  AiOutlineCaretUp,
} from 'react-icons/ai';

import { GoPlus } from 'react-icons/go';

import { RiDeleteBinLine } from 'react-icons/ri';
import { BsClipboard } from 'react-icons/bs';

import InputComum from '../../components/InputComum';
import InputDate from '../../components/InputDate';
import Select from '../../components/Select';
import ModalUploadFileOFX from '../../components/ModalUploadFileOFX';
import ModalUploadFileCSV from '../../components/ModalUploadFileCSV';
import ModalDownloadContabilidade from '../../components/ModalDownloadContabilidade';
import LookupHistoricoPadrao from '../../components/LookupHistoricoPadrao';
import Header from '../../components/Header';
import FormModalDialogConfirm from '../../components/FormModalDialogConfirm';

import api from '../../services/api';
import { useAuth } from '../../hooks/Auth';
import { IToastMessage, useToast } from '../../hooks/Toast';
import { useLookup } from '../../hooks/Lookup/index';
import IHistoricoLookup from '../../hooks/Lookup/IHistoricoLookup';

import getListUpdate from '../../utils/getListUpdate';
import getValidationErrors from '../../utils/getValidationErrors';
import { getPathGrid } from '../../utils/storagePath';

import {
  ColumnTipoLancamento,
  ColumnSituacao,
  ColumnValor,
  ColumnValorQuitacao,
  ColumnDescricao,
  ColumnContaCorrente,
  ColumnBanco,
  ColumnConta,
  ColumnCentroCusto,
  ColumnContaTransferencia,
  ColumnLoteImportacao,
  ColumnId,
} from './GridColumns';

import IFinMovimentos from './IFinMovimentos';
import IDemGerCadConta from './IDemGerCadConta';
import IDemGerCadContaCorrente from './IDemGerCadContaCorrente';
import IDemGerCadCCusto from './IDemGerCadCCusto';
import IFinMovimentosFilter from './IFinMovimentosFilter';
import ICadHistPadrao from './ICadHistPadrao';

import {
  Container,
  ContainerFields,
  Tab,
  TabButton,
  TabContent,
  FormFields,
  FormContainer,
  FormNavigate,
  ContainerFieldGoup,
  ContainerChip,
  ContainerFieldsHidden,
  ContainerFilterSelect,
  ContainerFilterInput,
  TituloFiltro,
  SpanFiltro,
  TabTitulo,
  TabButtonArea,
  TabButtonNovo,
  TabButtonDelete,
  TabButtonGridRowHeight,
  ContentWithExport,
} from './styles';

const useStylesLoading = makeStyles((theme: Theme) =>
  createStyles({
    backdrop: {
      zIndex: theme.zIndex.drawer + 2000,
      color: '#fff',
    },
  }),
);

const theme = createMuiTheme(
  {
    palette: {
      primary: { main: '#006699' },
    },
  },
  ptBR,
);

function getThemePaletteMode(palette: any): string {
  return palette.type || palette.mode;
}

const useStylesGrid = makeStyles((themeGrid: Theme) => {
  const getBackgroundColor = (color: string): string =>
    getThemePaletteMode(themeGrid.palette) === 'dark' ? darken(color, 0.6) : lighten(color, 0.6);

  const getHoverBackgroundColor = (color: string): string =>
    getThemePaletteMode(themeGrid.palette) === 'dark' ? darken(color, 0.5) : lighten(color, 0.5);

  return {
    root: {
      '& .super-app-theme--2-Pendente': {
        backgroundColor: getBackgroundColor('#FFFFE0'),
        '&:hover': {
          backgroundColor: getHoverBackgroundColor('#FFFFE0'),
        },
      },
      '& .super-app-theme--3-Quitado': {
        backgroundColor: getBackgroundColor('#C1FFC1'),
        '&:hover': {
          backgroundColor: getHoverBackgroundColor('#C1FFC1'),
        },
      },
      '& .super-app-theme--11': {
        backgroundColor: getBackgroundColor('#E0FFFF'),
        '&:hover': {
          backgroundColor: getHoverBackgroundColor('#E0FFFF'),
        },
      },
      '& .super-app-theme--12': {
        backgroundColor: getBackgroundColor('#E0FFFF'),
        '&:hover': {
          backgroundColor: getHoverBackgroundColor('#E0FFFF'),
        },
      },
    },
  };
});

const useStylesQuitado = makeStyles({
  root: {
    borderColor: '#669933',
    color: '#669933',
  },
});

const useStylesOutros = makeStyles({
  root: {
    borderColor: '#000',
    color: '#000',
  },
});

const useStylesQuitar = makeStyles({
  root: {
    borderColor: '#006699',
    color: '#006699',
  },
});

const emptyFinMovimentos: IFinMovimentos = {
  acao: '',
  id: '',
  id_license: '',
  id_company: '',
  id_conta_corrente: '',
  id_conta_corrente_transferencia: '',
  id_conta_financeira: '',
  id_centro_custo: '',
  id_banco: '',
  id_cad_hist_padrao: '',
  id_ofx: '',
  tipo: '1-Receita',
  situacao: '1-Previsto',
  data: '',
  data_vencimento: '',
  data_quitacao: '',
  valor_original: 0,
  valor_quitacao: 0,
  descricao_movimento: '',
  descricao_ofx: '',
  created_user: '',
  centro_custo: '',
  conta: '',
  conta_corrente: '',
  conta_corrente_transferencia: '',
  conta_corrente_transferencia_descricao: '',
  conta_corrente_descricao: '',
  exigir_centro_custo: false,
  banco: '',
  quitar: '',
  codigo_historico: '',
  descricao_historico: '',
  lote_importacao: '',
};

const options_tipo = [
  { value: '1-Receita', label: '1-Receita' },
  { value: '2-Despesa', label: '2-Despesa' },
  { value: '3-Transferência', label: '3-Transferência' },
  { value: '11-Receita Estimada', label: '11-Receita Estimada' },
  { value: '12-Despesa Estimada', label: '12-Despesa Estimada' },
];

const options_situacao = [
  { value: '1-Previsto', label: '1-Previsto' },
  { value: '2-Pendente', label: '2-Pendente' },
  { value: '3-Quitado', label: '3-Quitado' },
];

const titulo = 'R E C E I T A S     E     D E S P E S A S';

const idfieldSelectContaCorrente = 'be87c1612dda6f34801c10fee85f8507';
const idfieldSelectContaFinanceira = 'bfc17a9838087a79eeb0784d94a43202';
const idDivfieldSelectContaFinanceira = '25416fcfa0aa95440f7bf3d67b95b09c';
const idfieldSelectCentroCusto = 'aa1619a052f4ac605f6e2a92a9fe6081';
const idDivfieldSelectCentroCusto = 'ebad01d0916292728b49f67ee71c7804';
const idfieldValor = '87a45db05dca4072e669344bb9e3bb95';
const idfieldValorQuitacao = 'd8353d4f652667e9bdf6dc1dc42ea37a';
const idfieldDataVencimento = '75770597e2280fac0f4231d2b09d2643';
const idfieldDataQuitacao = '02c0df366bc4a1c517fbe3c482020993';
const emptyValueSelect = ' ';
const fieldDescricao = 'aoskjhdgusthdhbqw4d';
const fieldFilterTipo = '241e5b825cdff658bf000ac093de278d';
const fieldFilterSituacao = 'f873c26f110cd919cd58ce0ce38295c5';
const fieldFilterDataInicio = '1861267f10e92453f1fd8495c61ed94a';
const fieldFilterDataFim = 'fced4d843aee1682a79c157743a94968';
const fieldFilterDescricao = 'd5824a3ba1d0a033b10103d5e2b8dea3';
const fieldFilterLoteImportacao = 'as897d68as7dn8a76sdg7a6f5sdq3487i3yhn4';
const idfieldSelectContaCorrenteTransferencia = '761ea53670a52243ea875448a67d8842';
const idDivfieldSelectContaCorrenteTransferencia = 'c767da80b79fe51fd5c512b3b5eb4f9e';
const localStorageIdFilter = '@AssessortechWEBCli:filter_412637b102cf6dc1e9acd306e733b77b';

const FinMovimentos: React.FC = () => {
  const { user } = useAuth();
  const { addToast } = useToast();
  const formRef = useRef<FormHandles>(null);
  const formFilterRef = useRef(null);
  const formConfirmQuitarRef = useRef(null);
  const [finMovimentos, setFinMovimentos] = useState<IFinMovimentos>();
  const [finMovimentosQuitar, setFinMovimentosQuitar] = useState<IFinMovimentos>();
  const [listFinMovimentos, setListFinMovimentos] = useState<IFinMovimentos[]>([]);
  const [listDemGerCadConta, setListDemGerCadConta] = useState<IDemGerCadConta[]>([]);
  const [listDemGerCadContaCorrente, setListDemGerCadContaCorrente] = useState<IDemGerCadContaCorrente[]>([]);
  const [listDemGerCadCCusto, setListDemGerCadCCusto] = useState<IDemGerCadCCusto[]>([]);

  const [listDemGerCadContaSelect, setListDemGerCadContaSelect] = useState<IDemGerCadConta[]>([]);
  const [listDemGerCadContaCorrenteSelect, setListDemGerCadContaCorrenteSelect] = useState<IDemGerCadContaCorrente[]>(
    [],
  );
  const [listDemGerCadContaCorrenteTransferenciaSelect, setListDemGerCadContaCorrenteTransferenciaSelect] = useState<
    IDemGerCadContaCorrente[]
  >([]);

  const [listDemGerCadCCustoSelect, setListDemGerCadCCustoSelect] = useState<IDemGerCadCCusto[]>([]);

  const [estaSalvando, setEstaSalvando] = useState<boolean>(false);
  const [hiddenPesquisar, setHiddenPesquisar] = useState<boolean>(true);
  const [hiddenCadastrar, setHiddenCadastrar] = useState<boolean>(true);
  const [finMovimentosIndex, setFinMovimentosIndex] = useState<number>(0);
  const [rowToEdit, setRowToEdit] = useState(null);
  const [idDeleteFinMovimentos, setIdDeleteFinMovimentos] = useState<string>('');
  const [selectedIds, setSelectedIds] = useState<GridRowId[]>([]);
  const [openDeleteSingle, setOpenDeleteSingle] = useState(false);
  const [openDeleteMultiple, setOpenDeleteMultiple] = useState(false);
  const [openLoading, setOpenLoading] = useState(false);
  const [gridRowHeight, setGridRowHeight] = useState<number>(46);
  const [enableCentroCusto, setEnableCentroCusto] = useState<boolean>(false);
  const [openFilter, setOpenFilter] = React.useState(true);
  const [oldSituacao, setOldSituacao] = useState<string>('');
  const [textFiltro, setTextFiltro] = useState<string>('');
  const { updateListHistoricoPadrao, getListHistoricoPadrao } = useLookup();
  const cssQuitarChip = useStylesQuitar();
  const cssQuitadoChip = useStylesQuitado();
  const cssOutrosChip = useStylesOutros();
  const cssLoading = useStylesLoading();
  const cssGrid = useStylesGrid();
  const [disableComponents, setDisableComponents] = useState<boolean>(false);
  const [isTransferencia, setIsTransferencia] = useState<boolean>(false);
  const idSelectTipoDataPesquisa = uuid();
  const [openConfirmDataQuitacao, setOpenConfirmDataQuitacao] = useState<boolean>(false);
  const [openUploadFile, setOpenUploadFile] = useState<boolean>(false);
  const [openUploadFileCsv, setOpenUploadFileCsv] = useState<boolean>(false);
  const [openDownloadContabilidade, setOpenDownloadContabilidade] = useState<boolean>(false);
  const [finMovimentosFile, setFinMovimentosFile] = useState<string>('');
  const [finMovimentosFileCsv, setFinMovimentosFileCsv] = useState<string>('');
  const formConfirmIdFieldDataQuitar = uuid();
  const formIdFieldDataCompetencia = uuid();
  const formFilterIdFieldContaCorrente = uuid();
  const formFilterIdFieldContaFinanceira = uuid();

  const handleGridRowHeightUp = useCallback(async () => {
    const heightValue: number = gridRowHeight + 1;
    setGridRowHeight(heightValue);
    localStorage.setItem(getPathGrid(), heightValue.toString());
  }, [gridRowHeight]);

  const handleGridRowHeightDown = useCallback(async () => {
    const heightValue: number = gridRowHeight - 1;
    setGridRowHeight(heightValue);
    localStorage.setItem(getPathGrid(), heightValue.toString());
  }, [gridRowHeight]);

  const licencaInvalida = useCallback((): boolean => {
    if (!user.license || user.license.id === '') {
      addToast({
        type: 'error',
        title: 'Licença inválida',
        description: 'Usuário não está vinculado a nenhuma licença',
      });

      return true;
    }

    return false;
  }, [addToast, user]);

  const disableComponentByTipoLancamento = (id: string): void => {
    const fieldDataVencimento = document.getElementById(idfieldDataVencimento);
    const fieldDataQuitacao = document.getElementById(idfieldDataQuitacao);
    const fieldValorQuitacao = document.getElementById(idfieldValorQuitacao);

    const localIsTransferencia: boolean = id === '3-Transferência';
    setIsTransferencia(localIsTransferencia);
    if (id === '1-Receita' || id === '2-Despesa') {
      setDisableComponents(false);
      if (fieldDataVencimento) {
        (fieldDataVencimento as HTMLInputElement).disabled = false;
      }

      if (fieldDataQuitacao) {
        (fieldDataQuitacao as HTMLInputElement).disabled = false;
      }

      if (fieldValorQuitacao) {
        (fieldValorQuitacao as HTMLInputElement).disabled = false;
      }
    } else {
      setDisableComponents(true);
      const fieldData = document.getElementById(idfieldDataVencimento);
      if (fieldData && fieldDataVencimento) {
        const data = (fieldData as HTMLInputElement).value;
        (fieldDataVencimento as HTMLInputElement).value = data;
        (fieldDataVencimento as HTMLInputElement).disabled = true;
      }

      if (fieldDataQuitacao) {
        (fieldDataQuitacao as HTMLInputElement).value = '';
        (fieldDataQuitacao as HTMLInputElement).disabled = true;
      }

      if (fieldValorQuitacao) {
        (fieldValorQuitacao as HTMLInputElement).value = '0.00';
        (fieldValorQuitacao as HTMLInputElement).disabled = true;
      }
    }
  };

  const defineFinMovimentos = useCallback(
    async (data: IFinMovimentos) => {
      setFinMovimentos(data);
      setOldSituacao(data.situacao);
      setEnableCentroCusto(data.exigir_centro_custo);

      // Define Conta corrente
      const localListConta: IDemGerCadConta[] = listDemGerCadConta.filter(item => item.ativa === true);
      if (data.id_conta_financeira && data.id_conta_financeira.trim() !== '') {
        const localConta = listDemGerCadConta.find(item => item.id === data.id_conta_financeira);

        if (localConta && localConta.ativa === false) {
          localListConta.push(localConta);
        }
      }
      setListDemGerCadContaSelect(localListConta);

      // Define Conta Financeira
      const localListContaCorrente: IDemGerCadContaCorrente[] = listDemGerCadContaCorrente.filter(
        item => item.ativa === true,
      );

      if (data.id_conta_corrente && data.id_conta_corrente.trim() !== '') {
        const localContaCorrente = listDemGerCadContaCorrente.find(item => item.id === data.id_conta_corrente);

        if (localContaCorrente && localContaCorrente.ativa === false) {
          localListContaCorrente.push(localContaCorrente);
        }
      }
      setListDemGerCadContaCorrenteSelect(localListContaCorrente);

      // Define Conta Financeira Transferência
      const localListContaCorrenteTransferencia: IDemGerCadContaCorrente[] = listDemGerCadContaCorrente.filter(
        item => item.ativa === true,
      );
      if (data.id_conta_corrente_transferencia && data.id_conta_corrente_transferencia.trim() !== '') {
        const localContaCorrenteTransferencia = listDemGerCadContaCorrente.find(
          item => item.id === data.id_conta_corrente_transferencia,
        );

        if (localContaCorrenteTransferencia && localContaCorrenteTransferencia.ativa === false) {
          localListContaCorrenteTransferencia.push(localContaCorrenteTransferencia);
        }
      }
      setListDemGerCadContaCorrenteTransferenciaSelect(localListContaCorrenteTransferencia);

      // Define Centro de Custo
      const localListCentroCusto: IDemGerCadCCusto[] = listDemGerCadCCusto.filter(item => item.ativo === true);
      if (data.id_centro_custo && data.id_centro_custo.trim() !== '') {
        const localCentroCusto = listDemGerCadCCusto.find(item => item.id === data.id_centro_custo);

        if (localCentroCusto && localCentroCusto.ativo === false) {
          localListCentroCusto.push(localCentroCusto);
        }
      }
      setListDemGerCadCCustoSelect(localListCentroCusto);

      if (data.id_cad_hist_padrao !== '' && data.id_cad_hist_padrao !== null && data.id_cad_hist_padrao !== undefined) {
        updateListHistoricoPadrao([
          {
            id: data.id_cad_hist_padrao,
            id_cad_hist_padrao: data.id_cad_hist_padrao,
            codigo_historico: data.codigo_historico,
            descricao_historico: data.descricao_historico,
          },
        ]);
      } else {
        updateListHistoricoPadrao([]);
      }

      formRef.current?.setData(data);
      disableComponentByTipoLancamento(data.tipo);
    },
    [updateListHistoricoPadrao, listDemGerCadConta, listDemGerCadContaCorrente, listDemGerCadCCusto],
  );

  const onNewRegister = useCallback(async () => {
    defineFinMovimentos(emptyFinMovimentos);
  }, [defineFinMovimentos]);

  const OnGridSetRegisterToEdit = useCallback(
    async (params: GridCellParams) => {
      const localContaCorrente = params.row as IFinMovimentos;

      setFinMovimentosIndex(listFinMovimentos.indexOf(localContaCorrente));
      setHiddenCadastrar(false);
      setHiddenPesquisar(true);
      setRowToEdit(null);
      defineFinMovimentos(localContaCorrente);
    },
    [listFinMovimentos, defineFinMovimentos],
  );

  const OnSetRegisterToAdd = useCallback(async () => {
    onNewRegister();
    setHiddenCadastrar(false);
    setHiddenPesquisar(true);
  }, [onNewRegister]);

  const OnGridRowDuplicate = useCallback(
    async (baseFinMovimentos: IFinMovimentos | undefined) => {
      if (baseFinMovimentos !== undefined) {
        const newFinMovimentos: IFinMovimentos = {
          acao: baseFinMovimentos.acao,
          id: '',
          id_license: baseFinMovimentos.id_license,
          id_company: baseFinMovimentos.id_company,
          id_conta_corrente: baseFinMovimentos.id_conta_corrente,
          id_conta_corrente_transferencia: baseFinMovimentos.id_conta_corrente_transferencia,
          id_conta_financeira: baseFinMovimentos.id_conta_financeira,
          id_centro_custo: baseFinMovimentos.id_centro_custo,
          id_cad_hist_padrao: baseFinMovimentos.id_cad_hist_padrao,
          id_ofx: baseFinMovimentos.id_ofx,
          descricao_ofx: baseFinMovimentos.descricao_ofx,
          tipo: baseFinMovimentos.tipo,
          situacao: baseFinMovimentos.situacao,
          data: baseFinMovimentos.data,
          data_vencimento: baseFinMovimentos.data_vencimento,
          data_quitacao: baseFinMovimentos.data_quitacao,
          valor_original: baseFinMovimentos.valor_original,
          valor_quitacao: baseFinMovimentos.valor_quitacao,
          descricao_movimento: baseFinMovimentos.descricao_movimento,
          created_user: baseFinMovimentos.created_user,
          centro_custo: baseFinMovimentos.centro_custo,
          conta: baseFinMovimentos.conta,
          exigir_centro_custo: baseFinMovimentos.exigir_centro_custo,
          conta_corrente: baseFinMovimentos.conta_corrente,
          conta_corrente_transferencia: baseFinMovimentos.conta_corrente_transferencia,
          id_banco: baseFinMovimentos.id_banco,
          banco: baseFinMovimentos.banco,
          quitar: baseFinMovimentos.quitar,
          codigo_historico: baseFinMovimentos.codigo_historico,
          descricao_historico: baseFinMovimentos.descricao_historico,
          conta_corrente_descricao: baseFinMovimentos.conta_corrente_descricao,
          conta_corrente_transferencia_descricao: baseFinMovimentos.conta_corrente_transferencia_descricao,
          lote_importacao: '',
        };

        defineFinMovimentos(newFinMovimentos);
        setHiddenCadastrar(false);
        setHiddenPesquisar(true);
      }
    },
    [defineFinMovimentos],
  );

  const semData = useCallback((value: string | undefined | null): boolean => {
    if (value === null) return true;
    if (value === undefined) return true;
    if (value.trim() === '') return true;
    return false;
  }, []);
  /*
  const getFomatedDate = useCallback((value: Date | null): string => {
    if (value === null) return '';

    const dia = value.getDate();
    const mes = value.getMonth() + 1;
    const ano = value.getFullYear();

    const diaText: string = dia < 10 ? `0${dia}` : `${dia}`;
    const mesText: string = mes < 10 ? `0${mes}` : `${mes}`;

    return `${diaText}/${mesText}/${ano}`;
  }, []);
  */

  const contaEhValidaParaDataLancamento = useCallback(
    (dataLancamento: Date, idContaCorrente: string, tituloMensagem: string, campo: string): boolean => {
      let retorno = true;
      const findContaCorrente = listDemGerCadContaCorrente.find(item => item.id === idContaCorrente);

      if (findContaCorrente) {
        const { data_inicio } = findContaCorrente;
        const { data_fim } = findContaCorrente;

        if (semData(data_inicio) && semData(data_fim)) {
          return true;
        }

        let dtInicio: Date | null = null;
        if (data_inicio) dtInicio = new Date(`${data_inicio.substring(0, 10)}T12:00:00`);
        let dtFim: Date | null = null;
        if (data_fim) dtFim = new Date(`${data_fim.substring(0, 10)}T12:00:00`);

        const toastMessage: IToastMessage = {
          id: uuid(),
          type: 'error',
          title: tituloMensagem,
          description: `${campo} está fora do período permitido. Verifique as datas configuradas na conta corrente`,
        };

        if (dtInicio !== null && dtFim !== null) {
          retorno = dataLancamento >= dtInicio && dataLancamento <= dtFim;
          if (!retorno) addToast(toastMessage);
          return retorno;
        }

        if (dtInicio !== null) {
          retorno = dataLancamento >= dtInicio;
          if (!retorno) addToast(toastMessage);
          return retorno;
        }

        if (dtFim !== null) {
          retorno = dataLancamento <= dtFim;
          if (!retorno) addToast(toastMessage);
          return retorno;
        }

        addToast(toastMessage);
        return false;
      }

      return true;
    },
    [addToast, semData, listDemGerCadContaCorrente],
  );

  const OnConfirmationDeleteSingle = useCallback(
    async (id: string) => {
      if (licencaInvalida()) return;

      if (id === '') return;

      setIdDeleteFinMovimentos(id);

      if (finMovimentos) {
        if (
          contaEhValidaParaDataLancamento(
            new Date(`${finMovimentos.data.substring(0, 10)}T12:00:00`),
            finMovimentos.id_conta_corrente,
            'Não é possível excluir o registro',
            'Data do lançamento',
          )
        ) {
          setOpenDeleteSingle(true);
        }
      }
    },
    [contaEhValidaParaDataLancamento, licencaInvalida, finMovimentos],
  );

  const OnConfirmQuitar = useCallback(
    async (value: IFinMovimentos) => {
      if (value.data_quitacao === null) {
        addToast({
          type: 'error',
          title: 'Não é possível salvar o registro',
          description: 'Data de quitação inválida',
        });

        return;
      }

      if (
        !contaEhValidaParaDataLancamento(
          new Date(`${value.data_quitacao.substring(0, 10)}T12:00:00`),
          value.id_conta_corrente,
          'Não é possível salvar o registro',
          'Data da quitação',
        )
      ) {
        return;
      }

      try {
        setOpenLoading(true);
        await api.put(`/finmovimentos/${value.id}`, value);

        setListFinMovimentos(getListUpdate(listFinMovimentos, value));
        setOpenLoading(false);

        addToast({
          type: 'success',
          title: 'Alterado',
          description: 'Registro alterado com sucesso!',
        });
      } catch (error) {
        setOpenLoading(false);
        addToast({
          type: 'error',
          title: 'Erro',
          description: 'Ocorreu uma falha.',
        });
      }
    },
    [addToast, listFinMovimentos, contaEhValidaParaDataLancamento],
  );

  const OnClickChipQuitar = useCallback(
    async (id: string) => {
      if (licencaInvalida()) return;

      const findEditFinMovimentos = listFinMovimentos.find(item => item.id === id);

      if (findEditFinMovimentos) {
        setFinMovimentosQuitar(findEditFinMovimentos);
        if (
          findEditFinMovimentos.id_conta_financeira === '' ||
          findEditFinMovimentos.id_conta_financeira === undefined ||
          findEditFinMovimentos.id_conta_financeira === null
        ) {
          setFinMovimentosIndex(listFinMovimentos.indexOf(findEditFinMovimentos));
          setHiddenCadastrar(false);
          setHiddenPesquisar(true);
          setRowToEdit(null);

          findEditFinMovimentos.quitar = '';
          findEditFinMovimentos.situacao = '3-Quitado';
          findEditFinMovimentos.valor_quitacao = findEditFinMovimentos.valor_original;

          defineFinMovimentos(findEditFinMovimentos);
        } else {
          const hoje: string = new Date().toJSON().substring(0, 10);
          if (
            findEditFinMovimentos.data_quitacao === null ||
            findEditFinMovimentos.data_quitacao.trim() === '' ||
            findEditFinMovimentos.data_quitacao.substring(0, 10) !== hoje
          ) {
            setOpenConfirmDataQuitacao(true);
          } else {
            findEditFinMovimentos.quitar = '';
            findEditFinMovimentos.situacao = '3-Quitado';
            findEditFinMovimentos.valor_quitacao = findEditFinMovimentos.valor_original;
            OnConfirmQuitar(findEditFinMovimentos);
          }
        }
      }
    },
    [listFinMovimentos, licencaInvalida, OnConfirmQuitar, defineFinMovimentos],
  );

  const OnClickConfirmQuitar = useCallback(async () => {
    if (!finMovimentosQuitar) {
      return;
    }

    const fieldDataQuitacao = document.getElementById(formConfirmIdFieldDataQuitar);
    let dataQuitacao = new Date().toJSON();
    if (fieldDataQuitacao) {
      const dtQuitacao = (fieldDataQuitacao as HTMLInputElement).value;

      if (dtQuitacao !== '' && dtQuitacao !== undefined && dtQuitacao !== null) {
        dataQuitacao = dtQuitacao;
      }
    }

    const formData: IFinMovimentos = {
      id: finMovimentosQuitar.id,
      id_company: finMovimentosQuitar.id_company,
      id_license: finMovimentosQuitar.id_license,
      tipo: finMovimentosQuitar.tipo,
      situacao: '3-Quitado',
      data: finMovimentosQuitar.data,
      data_vencimento: finMovimentosQuitar.data_vencimento,
      valor_original: finMovimentosQuitar.valor_original,
      data_quitacao: dataQuitacao,
      valor_quitacao: finMovimentosQuitar.valor_original,
      descricao_movimento: finMovimentosQuitar.descricao_movimento,
      id_conta_corrente: finMovimentosQuitar.id_conta_corrente,
      id_conta_corrente_transferencia: finMovimentosQuitar.id_conta_corrente_transferencia,
      id_conta_financeira: finMovimentosQuitar.id_conta_financeira,
      id_centro_custo: finMovimentosQuitar.id_centro_custo,
      id_cad_hist_padrao: finMovimentosQuitar.id_cad_hist_padrao,
      id_ofx: finMovimentosQuitar.id_ofx,
      descricao_ofx: finMovimentosQuitar.descricao_ofx,
      created_user: finMovimentosQuitar.created_user,
      acao: finMovimentosQuitar.acao,
      centro_custo: finMovimentosQuitar.centro_custo,
      conta: finMovimentosQuitar.conta,
      conta_corrente: finMovimentosQuitar.conta_corrente,
      conta_corrente_transferencia: finMovimentosQuitar.conta_corrente_transferencia,
      exigir_centro_custo: finMovimentosQuitar.exigir_centro_custo,
      id_banco: finMovimentosQuitar.id_banco,
      banco: finMovimentosQuitar.banco,
      quitar: '',
      codigo_historico: finMovimentosQuitar.codigo_historico,
      descricao_historico: finMovimentosQuitar.descricao_historico,
      conta_corrente_descricao: finMovimentosQuitar.conta_corrente_descricao,
      conta_corrente_transferencia_descricao: finMovimentosQuitar.conta_corrente_transferencia_descricao,
      lote_importacao: finMovimentosQuitar.lote_importacao,
    };

    OnConfirmQuitar(formData);
    setOpenConfirmDataQuitacao(false);
  }, [OnConfirmQuitar, finMovimentosQuitar, formConfirmIdFieldDataQuitar]);

  const ColumnAcao: GridColDef = {
    field: 'acao',
    headerName: 'Ação',
    editable: false,
    filterable: false,
    hideSortIcons: true,
    sortable: false,
    disableColumnMenu: true,
    width: 160,
    renderCell: (params: GridCellParams) => (
      <strong>
        <Tooltip title="Editar Registro" placement="top">
          <IconButton color="primary" aria-label="Editar" onClick={() => OnGridSetRegisterToEdit(params)}>
            <MdEdit />
          </IconButton>
        </Tooltip>

        <Tooltip title="Duplicar Registro" placement="top">
          <IconButton
            color="primary"
            aria-label="Duplicar"
            onClick={() => OnGridRowDuplicate(params.row as IFinMovimentos)}
          >
            <FiCopy />
          </IconButton>
        </Tooltip>

        <Tooltip title="Excluir Registro" placement="top">
          <IconButton
            color="primary"
            aria-label="Excluir"
            onClick={() => OnConfirmationDeleteSingle((params.row as IFinMovimentos).id)}
          >
            <MdDelete />
          </IconButton>
        </Tooltip>
      </strong>
    ),
  };

  const ColumnData: GridColDef = {
    headerName: 'Data',
    field: 'data',
    width: 130,
    type: 'date',
    renderCell: (params: GridCellParams) => {
      if (params.value === null) return <strong />;

      const ano = (params.value as string).substring(0, 4);
      const mes = (params.value as string).substring(5, 7);
      const dia = (params.value as string).substring(8, 10);

      const dataFormatada = `${dia}/${mes}/${ano}`;
      return <strong>{dataFormatada}</strong>;
    },
  };

  const ColumnDataVencimento: GridColDef = {
    headerName: 'Data Vencimento',
    field: 'data_vencimento',
    width: 190,
    type: 'date',
    renderCell: (params: GridCellParams) => {
      if (params.value === null) return <strong />;

      const ano = (params.value as string).substring(0, 4);
      const mes = (params.value as string).substring(5, 7);
      const dia = (params.value as string).substring(8, 10);

      const dataFormatada = `${dia}/${mes}/${ano}`;
      return <strong>{dataFormatada}</strong>;
    },
  };

  const ColumnDataQuitacao: GridColDef = {
    headerName: 'Data Quitação',
    field: 'data_quitacao',
    width: 170,
    type: 'date',
    renderCell: (params: GridCellParams) => {
      if (params.value === null) return <strong />;
      const ano = (params.value as string).substring(0, 4);
      const mes = (params.value as string).substring(5, 7);
      const dia = (params.value as string).substring(8, 10);

      const dataFormatada = `${dia}/${mes}/${ano}`;
      return <strong>{dataFormatada}</strong>;
    },
  };

  const ColumnQuitacao: GridColDef = {
    headerName: ' ',
    field: 'quitar',
    width: 120,
    align: 'center',
    editable: false,
    filterable: false,
    hideSortIcons: true,
    sortable: false,
    disableColumnMenu: true,

    renderCell: (params: GridCellParams) => {
      if (params.row.tipo !== '1-Receita' && params.row.tipo !== '2-Despesa') {
        return (
          <ContainerChip>
            <Chip label="Outros" variant="default" className={cssOutrosChip.root} />
          </ContainerChip>
        );
      }

      if (params.value === null || params.value === '' || params.value === undefined) {
        return (
          <ContainerChip>
            <Chip label="Quitado" variant="default" className={cssQuitadoChip.root} />
          </ContainerChip>
        );
      }

      const id: string = params.value as string;

      return (
        <ContainerChip>
          <Chip
            label="Quitar"
            variant="outlined"
            className={cssQuitarChip.root}
            clickable
            onClick={() => OnClickChipQuitar(id)}
          />
        </ContainerChip>
      );
    },
  };

  const columns: GridColDef[] = [
    ColumnAcao,
    ColumnQuitacao,
    ColumnTipoLancamento,
    ColumnSituacao,
    ColumnData,
    ColumnDataVencimento,
    ColumnDataQuitacao,
    ColumnValor,
    ColumnValorQuitacao,
    ColumnDescricao,
    ColumnContaCorrente,
    ColumnBanco,
    ColumnConta,
    ColumnCentroCusto,
    ColumnContaTransferencia,
    ColumnLoteImportacao,
    ColumnId,
  ];

  const getDemGerCadConta = useCallback(async () => {
    const response = await api.get<IDemGerCadConta[]>(`/demgercadconta`, {
      params: {
        id_license: user.license.id,
        id_company: user.empresa.id,
        ind_cta: '2-Analítico',
      },
    });

    setListDemGerCadConta(response.data);
  }, [user]);

  const getDemGerCadContaCorrente = useCallback(async () => {
    const response = await api.post<IDemGerCadContaCorrente[]>(`/demgercadcontacorrente/filter`, {
      id_license: user.license.id,
      id_company: user.empresa.id,
    });

    setListDemGerCadContaCorrente(response.data);
  }, [user]);

  const getDemGerCadCCusto = useCallback(async () => {
    const response = await api.get<IDemGerCadCCusto[]>(`/demgercadccusto`, {
      params: {
        id_license: user.license.id,
        id_company: user.empresa.id,
        ind_ccusto: '2-Analítico',
      },
    });

    setListDemGerCadCCusto(response.data);
  }, [user]);

  const getFinMovimentos = useCallback(
    async (filtro: IFinMovimentosFilter) => {
      const response = await api.get<IFinMovimentos[]>(`/finmovimentos/filter`, {
        params: {
          id_license: user.license.id,
          id_company: user.empresa.id,
          tipo: filtro.tipo,
          situacao: filtro.situacao,
          tipo_data_pesquisa: filtro.tipo_data_pesquisa,
          data_inicio: filtro.data_inicio,
          data_fim: filtro.data_fim,
          descricao_movimento: filtro.descricao_movimento,
          idContaCorrente: filtro.idContaCorrente,
          idContaFinanceira: filtro.idContaFinanceira,
          lote_importacao: filtro.lote_importacao,
        },
      });

      setListFinMovimentos(response.data);

      let findEditFinMovimentos: IFinMovimentos | undefined = response.data[0] ? response.data[0] : undefined;
      if (rowToEdit !== null) {
        findEditFinMovimentos = response.data.find(item => item.id === rowToEdit);
      }

      if (findEditFinMovimentos) {
        defineFinMovimentos(findEditFinMovimentos);
        setFinMovimentosIndex(response.data.indexOf(findEditFinMovimentos));
        setRowToEdit(null);
      }

      setHiddenPesquisar(false);
      setOpenFilter(false);
    },
    [user, rowToEdit, defineFinMovimentos],
  );

  const getValues = useCallback(async () => {
    if (licencaInvalida()) return;

    await getDemGerCadConta();
    await getDemGerCadContaCorrente();
    await getDemGerCadCCusto();
  }, [licencaInvalida, getDemGerCadConta, getDemGerCadContaCorrente, getDemGerCadCCusto]);

  const onUploadFileClose = useCallback(() => {
    setOpenUploadFile(false);
  }, []);

  const onUploadFileCloseCsv = useCallback(async () => {
    setOpenUploadFileCsv(false);
    setOpenLoading(true);
    const filterValue = localStorage.getItem(localStorageIdFilter);
    if (filterValue) {
      const value = JSON.parse(filterValue) as IFinMovimentosFilter;
      await getFinMovimentos(value);
    }
    setOpenLoading(false);
  }, [getFinMovimentos]);

  const onCloseDownloadContabilidade = useCallback(() => {
    setOpenDownloadContabilidade(false);
  }, []);

  useEffect(() => {
    if (user.license && user.empresa) {
      setOpenLoading(true);

      setFinMovimentosFile(`/finmovimentos/file/${user.license.id}?id_company=${user.empresa.id}`);

      setFinMovimentosFileCsv(`/finmovimentos/file/${user.license.id}/csv?id_company=${user.empresa.id}`);

      getValues();

      const rowHeight = localStorage.getItem(getPathGrid());
      if (rowHeight) {
        setGridRowHeight(parseInt(rowHeight, 10));
      }

      setOpenLoading(false);
    }
  }, [user.license, user.empresa, getValues]);

  const handleOnRowDeleteSingle = useCallback(async () => {
    setOpenLoading(true);
    await api.delete(`/finmovimentos/${idDeleteFinMovimentos}`);

    setListFinMovimentos(oldValues => oldValues.filter(item => item.id !== idDeleteFinMovimentos));

    defineFinMovimentos(emptyFinMovimentos);

    addToast({
      type: 'success',
      title: 'Removido',
      description: 'Registro excluído com sucesso!',
    });

    setOpenDeleteSingle(false);
    setOpenLoading(false);
  }, [idDeleteFinMovimentos, addToast, defineFinMovimentos]);

  const handleOnRowDeleteMultiple = useCallback(async () => {
    setOpenLoading(true);
    if (selectedIds.length > 0) {
      await Promise.all(
        selectedIds.map(itemId => {
          setListFinMovimentos(oldValues => oldValues.filter(item => item.id !== itemId));

          return api.delete(`/finmovimentos/${itemId}`);
        }),
      );

      defineFinMovimentos(emptyFinMovimentos);

      addToast({
        type: 'success',
        title: 'Removido',
        description: 'Registro excluído com sucesso!',
      });
    }

    setSelectedIds([]);
    setOpenDeleteMultiple(false);
    setOpenLoading(false);
  }, [addToast, defineFinMovimentos, selectedIds]);

  const handleClick = (id: string): void => {
    if (id === 'filtro') {
      setOpenFilter(id === 'filtro');
      return;
    }

    setHiddenCadastrar(id === 'pesquisar');
    setHiddenPesquisar(id === 'cadastrar');
  };

  const onNextRegister = useCallback(async () => {
    if (finMovimentosIndex !== undefined) {
      if (finMovimentosIndex < listFinMovimentos.length - 1) {
        const localIndex = finMovimentosIndex + 1;
        setFinMovimentosIndex(localIndex);
        const findEditFinMovimentos = listFinMovimentos[localIndex];
        if (findEditFinMovimentos) {
          defineFinMovimentos(findEditFinMovimentos);
        }
      }
    }
  }, [finMovimentosIndex, listFinMovimentos, defineFinMovimentos]);

  const onFirstRegister = useCallback(async () => {
    if (listFinMovimentos.length > 0) {
      setFinMovimentosIndex(0);
      const findEditFinMovimentos = listFinMovimentos[0];
      if (findEditFinMovimentos) {
        defineFinMovimentos(findEditFinMovimentos);
      }
    }
  }, [listFinMovimentos, defineFinMovimentos]);

  const onLastRegister = useCallback(async () => {
    if (listFinMovimentos.length > 0) {
      setFinMovimentosIndex(listFinMovimentos.length - 1);
      const findEditFinMovimentos = listFinMovimentos[listFinMovimentos.length - 1];
      if (findEditFinMovimentos) {
        defineFinMovimentos(findEditFinMovimentos);
      }
    }
  }, [listFinMovimentos, defineFinMovimentos]);

  const onPriorRegister = useCallback(async () => {
    if (finMovimentosIndex !== undefined) {
      if (finMovimentosIndex > 0) {
        const localIndex = finMovimentosIndex - 1;
        setFinMovimentosIndex(localIndex);
        const findEditFinMovimentos = listFinMovimentos[localIndex];
        if (findEditFinMovimentos) {
          defineFinMovimentos(findEditFinMovimentos);
        }
      }
    }
  }, [finMovimentosIndex, listFinMovimentos, defineFinMovimentos]);

  const onCancelRegister = useCallback(async () => {
    formRef.current?.setErrors({});

    if (finMovimentosIndex !== undefined) {
      const findEditFinMovimentos = listFinMovimentos[finMovimentosIndex];
      if (findEditFinMovimentos) {
        defineFinMovimentos(findEditFinMovimentos);
      }
    }

    onNewRegister();
  }, [finMovimentosIndex, listFinMovimentos, onNewRegister, defineFinMovimentos]);

  const getValueId = (value: string | null): string | null => {
    if (value === null) return null;

    return value.trim() === '' ? null : value;
  };

  const handleSubmit = useCallback(
    async (data: IFinMovimentos, { reset }) => {
      if (estaSalvando) return;

      try {
        setEstaSalvando(true);
        if (licencaInvalida()) return;

        if (data.data_quitacao?.toString() === 'Invalid Date') data.data_quitacao = null;
        data.exigir_centro_custo = enableCentroCusto;
        if (!enableCentroCusto) data.id_centro_custo = null;

        data.quitar = data.situacao === '3-Quitado' ? '' : data.id;

        formRef.current?.setErrors({});
        try {
          if (data.tipo === '11-Receita Estimada' || data.tipo === '12-Despesa Estimada') {
            data.situacao = '4-Estimada';
          }

          if (data.tipo === '3-Transferência') {
            data.situacao = '5-Transferência Realizada';
          }

          if (data.tipo !== '1-Receita' && data.tipo !== '2-Despesa') {
            data.data_vencimento = data.data;
          }

          if (data.tipo === '3-Transferência') {
            data.id_conta_corrente_transferencia = getValueId(data.id_conta_corrente_transferencia);
            data.id_conta_financeira = null;
            data.id_centro_custo = null;
          } else {
            data.id_conta_corrente_transferencia = null;
            data.id_conta_financeira = getValueId(data.id_conta_financeira);
            data.id_centro_custo = getValueId(data.id_centro_custo);
          }

          if (
            data.valor_quitacao === null ||
            data.valor_quitacao === undefined ||
            data.valor_quitacao.toString().trim() === ''
          ) {
            data.valor_quitacao = 0;
          }

          const schema = Yup.object().shape({
            tipo: Yup.string().required('Tipo lançamento é obrigatório'),
            situacao: Yup.string().required('Situação é obrigatória'),
            data: Yup.date().required('Data é obrigatória'),
            data_vencimento: Yup.date().required('Data de vencimento é obrigatória'),
            data_quitacao: Yup.date()
              .nullable()
              .label('Data quitação: ')
              .when('situacao', {
                is: (situacao: string) => {
                  if (situacao === '3-Quitado') return true;

                  return false;
                },
                then: Yup.date().required('Data de quitação é obrigatória'),
                otherwise: Yup.date().nullable(),
              }),
            valor_original: Yup.number().min(0.01, 'Valor deve ser superior a 0.01').required('Valor é obrigatório'),
            valor_quitacao: Yup.number()
              .nullable()
              .label('Valor quitação: ')
              .when('situacao', {
                is: (situacao: string) => {
                  if (situacao === '3-Quitado') return true;

                  return false;
                },
                then: Yup.number().min(0.01, 'Valor deve ser superior a 0.01').required('Valor é obrigatório'),
                otherwise: Yup.number().nullable(),
              }),
            descricao_movimento: Yup.string().required('Descrição é obrigatório'),
            id_conta_corrente: Yup.string().required('Conta corrente é obrigatória'),

            id_conta_corrente_transferencia: Yup.string()
              .nullable()
              .when('tipo', {
                is: (tipo: string) => {
                  if (tipo === '3-Transferência') return true;

                  return false;
                },
                then: Yup.string().trim().required('Conta financeira é obrigatório'),
                otherwise: Yup.string().nullable(),
              }),

            id_conta_financeira: Yup.string()
              .nullable()
              .when('tipo', {
                is: (tipo: string) => {
                  if (tipo !== '3-Transferência') return true;

                  return false;
                },
                then: Yup.string().trim().required('Conta financeira é obrigatório'),
                otherwise: Yup.string().nullable(),
              }),
            id_centro_custo: Yup.string()
              .nullable()
              .when('exigir_centro_custo', {
                is: (exigir_centro_custo: boolean) => {
                  if (exigir_centro_custo) return true;

                  return false;
                },
                then: Yup.string().trim().required('Centro de custo é obrigatório'),
                otherwise: Yup.string().nullable(),
              }),
          });

          await schema.validate(data, {
            abortEarly: false,
          });

          let isDataInvalida = false;
          if (
            !contaEhValidaParaDataLancamento(
              new Date(`${data.data.substring(0, 10)}T12:00:00`),
              data.id_conta_corrente,
              'Não é possível salvar o registro',
              'Data do lançamento',
            )
          ) {
            isDataInvalida = true;
          }

          if (
            !contaEhValidaParaDataLancamento(
              new Date(`${data.data_vencimento.substring(0, 10)}T12:00:00`),
              data.id_conta_corrente,
              'Não é possível salvar o registro',
              'Data do vencimento',
            )
          ) {
            isDataInvalida = true;
          }

          if (data.data_quitacao !== null) {
            if (
              !contaEhValidaParaDataLancamento(
                new Date(`${data.data_quitacao.substring(0, 10)}T12:00:00`),
                data.id_conta_corrente,
                'Não é possível salvar o registro',
                'Data da quitação',
              )
            ) {
              isDataInvalida = true;
            }
          }

          if (isDataInvalida) {
            return;
          }

          setOpenLoading(true);
          const listHistoricoPadrao = getListHistoricoPadrao();
          if (listHistoricoPadrao[0]) {
            data.id_cad_hist_padrao = listHistoricoPadrao[0].id;
            data.codigo_historico = listHistoricoPadrao[0].codigo_historico;
            data.descricao_historico = listHistoricoPadrao[0].descricao_historico;
          } else {
            data.id_cad_hist_padrao = null;
            data.codigo_historico = '';
            data.descricao_historico = '';
          }

          let contaCorrenteTransferenciaDescricao = data.conta_corrente_transferencia_descricao;
          if (
            data.id_conta_corrente_transferencia !== null &&
            data.id_conta_corrente_transferencia !== undefined &&
            data.id_conta_corrente_transferencia !== ''
          ) {
            const findCCTransferencia = listDemGerCadContaCorrente.find(
              item => item.id === data.id_conta_corrente_transferencia,
            );
            if (findCCTransferencia) {
              contaCorrenteTransferenciaDescricao = `${findCCTransferencia.descricao_banco.trim()}-${findCCTransferencia.conta_corrente.trim()}-${findCCTransferencia.descricao_conta.trim()}`;
            }
          }

          if (
            data.id_conta_corrente !== null &&
            data.id_conta_corrente !== undefined &&
            data.id_conta_corrente !== ''
          ) {
            const findCCorrente = listDemGerCadContaCorrente.find(item => item.id === data.id_conta_corrente);
            if (findCCorrente) {
              data.conta_corrente = findCCorrente.conta_corrente;
              data.banco = findCCorrente.descricao_banco;
              data.conta_corrente_descricao = `${findCCorrente.descricao_banco.trim()}-${findCCorrente.conta_corrente.trim()}-${findCCorrente.descricao_conta.trim()}`;
            }
          }

          const formData: IFinMovimentos = {
            id: data.id,
            id_company: user.empresa.id,
            id_license: user.license.id,
            tipo: data.tipo,
            situacao: data.situacao,
            data: data.data,
            data_vencimento: data.data_vencimento,
            valor_original: data.valor_original,
            data_quitacao: data.data_quitacao,
            valor_quitacao: data.valor_quitacao,
            descricao_movimento: data.descricao_movimento.trim().substring(0, 254),
            id_conta_corrente: data.id_conta_corrente,
            id_conta_corrente_transferencia: data.id_conta_corrente_transferencia,
            id_conta_financeira: data.id_conta_financeira,
            id_centro_custo: data.id_centro_custo,
            id_cad_hist_padrao: data.id_cad_hist_padrao,
            id_ofx: data.id_ofx,
            descricao_ofx: data.descricao_ofx,
            created_user: user.id,
            acao: data.acao,
            centro_custo: data.centro_custo,
            conta: data.conta,
            conta_corrente: data.conta_corrente,
            conta_corrente_transferencia: data.conta_corrente_transferencia,
            exigir_centro_custo: data.exigir_centro_custo,
            id_banco: data.id_banco,
            banco: data.banco,
            quitar: data.quitar,
            codigo_historico: data.codigo_historico,
            descricao_historico: data.descricao_historico,
            conta_corrente_descricao: data.conta_corrente_descricao,
            conta_corrente_transferencia_descricao: contaCorrenteTransferenciaDescricao,
            lote_importacao: data.lote_importacao,
          };

          if (data.id === '') {
            const response = await api.post(`/finmovimentos`, formData);
            formData.id = response.data.id;
            formData.quitar = formData.situacao !== '3-Quitado' ? response.data.id : '';
            formData.lote_importacao = response.data.lote_importacao;

            setListFinMovimentos(oldValues => [...oldValues, formData]);
            addToast({
              type: 'success',
              title: 'Adicionado',
              description: 'Registro adicionado com sucesso!',
            });
          } else {
            await api.put(`/finmovimentos/${data.id}`, formData);
            formData.quitar = formData.situacao !== '3-Quitado' ? formData.id : '';

            setListFinMovimentos(getListUpdate(listFinMovimentos, formData));

            addToast({
              type: 'success',
              title: 'Alterado',
              description: 'Registro alterado com sucesso!',
            });
          }

          defineFinMovimentos(emptyFinMovimentos);
          reset();
          setOpenLoading(false);
        } catch (err) {
          if (err instanceof Yup.ValidationError) {
            const errors = getValidationErrors(err);
            formRef.current?.setErrors(errors);
          }

          setOpenLoading(false);

          addToast({
            type: 'error',
            title: 'Revise as informações',
            description: 'Algumas informações devem ser revistas',
          });
        }
      } finally {
        setEstaSalvando(false);
      }
    },
    [
      estaSalvando,
      licencaInvalida,
      listFinMovimentos,
      getListHistoricoPadrao,
      user,
      addToast,
      enableCentroCusto,
      defineFinMovimentos,
      contaEhValidaParaDataLancamento,
      listDemGerCadContaCorrente,
    ],
  );

  const OnSelectTipoLancamento = (event: React.ChangeEvent<{ value: unknown }>): void => {
    const id: string = event.target.value as string;
    disableComponentByTipoLancamento(id);
  };

  const OnSelectSituacao = (event: React.ChangeEvent<{ value: unknown }>): void => {
    const id: string = event.target.value as string;
    const formData = formRef.current?.getData();

    if (id === '3-Quitado') {
      if (formData) {
        (formData as IFinMovimentos).quitar = '';
      }

      const fieldData = document.getElementById(idfieldDataVencimento);
      const fieldDataQuitacao = document.getElementById(idfieldDataQuitacao);
      if (fieldData && fieldDataQuitacao) {
        const dtQuiotacao = (fieldDataQuitacao as HTMLInputElement).value;
        if (dtQuiotacao === '' || dtQuiotacao === undefined || dtQuiotacao === null) {
          const dtVencimento = (fieldData as HTMLInputElement).value;
          (fieldDataQuitacao as HTMLInputElement).value = dtVencimento;
        }
      }

      const fieldValor = document.getElementById(idfieldValor);
      const fieldValorQuitacao = document.getElementById(idfieldValorQuitacao);
      if (fieldValor && fieldValorQuitacao) {
        const vlQuiotacao = (fieldValorQuitacao as HTMLInputElement).value;
        if (
          vlQuiotacao === '' ||
          vlQuiotacao === '0' ||
          vlQuiotacao === '0.0' ||
          vlQuiotacao === '0.00' ||
          vlQuiotacao === undefined ||
          vlQuiotacao === null
        ) {
          const vlPago = (fieldValor as HTMLInputElement).value;
          (fieldValorQuitacao as HTMLInputElement).value = vlPago;
        }
      }
    } else if (formData) {
      (formData as IFinMovimentos).quitar = (formData as IFinMovimentos).id;
    }

    if (id !== '3-Quitado' && oldSituacao === '3-Quitado') {
      (formData as IFinMovimentos).quitar = (formData as IFinMovimentos).id;
      const fieldDataQuitacao = document.getElementById(idfieldDataQuitacao);
      if (fieldDataQuitacao) {
        (fieldDataQuitacao as HTMLInputElement).value = '';
      }

      const fieldValorQuitacao = document.getElementById(idfieldValorQuitacao);
      if (fieldValorQuitacao) {
        (fieldValorQuitacao as HTMLInputElement).value = '0.00';
      }
    }

    setOldSituacao(id);
  };

  const onConfirmHistoricoLookup = (list: IHistoricoLookup[]): void => {
    const fieldInputDescricao = document.getElementById(fieldDescricao);
    if (fieldInputDescricao && list[0]) {
      const formData = formRef.current?.getData();
      let descriCaoComplementar = '';
      if (formData) {
        const idContaFinanceira = (formData as IFinMovimentos).id_conta_financeira;
        const findConta = listDemGerCadConta.find(item => item.id === idContaFinanceira);

        if (findConta) {
          if (findConta.historico_permite_complemento) {
            const descricaoOFX = (formData as IFinMovimentos).descricao_ofx;
            if (descricaoOFX !== null && descricaoOFX !== '') {
              descriCaoComplementar = descricaoOFX;
            }
          }
        }
      }

      (fieldInputDescricao as HTMLInputElement).value = `${list[0].descricao_historico} ${descriCaoComplementar}`;
    }
  };

  const OnSelectContaFinanceira = (event: React.ChangeEvent<{ value: unknown }>): void => {
    const id: string = event.target.value as string;
    api
      .get<ICadHistPadrao[]>(`/demgercadcontahistorico`, {
        params: {
          id_license: user.license.id,
          id_company: user.empresa.id,
          id_conta: id,
        },
      })
      .then(response => {
        if (response.data.length === 1) {
          updateListHistoricoPadrao([
            {
              id: response.data[0].id,
              id_cad_hist_padrao: response.data[0].id,
              codigo_historico: response.data[0].codigo_historico,
              descricao_historico: response.data[0].descricao_historico,
            },
          ]);

          onConfirmHistoricoLookup([
            {
              id: response.data[0].id,
              id_cad_hist_padrao: response.data[0].id,
              codigo_historico: response.data[0].codigo_historico,
              descricao_historico: response.data[0].descricao_historico,
            },
          ]);
        }
      });

    const localConta = listDemGerCadConta.find(item => item.id === id);
    if (localConta) {
      setEnableCentroCusto(localConta.exigir_centro_custo);

      if (!localConta.exigir_centro_custo) {
        const fieldSelectCentroCusto = document.getElementById(idfieldSelectCentroCusto);
        if (fieldSelectCentroCusto) {
          (fieldSelectCentroCusto as HTMLInputElement).value = '';
        }
      }
    } else {
      setEnableCentroCusto(false);
    }
  };

  const handleCloseFilter = useCallback(() => {
    setOpenFilter(false);
  }, []);

  const formatarData = (data: string): string => {
    const ano = data.substr(0, 4);
    const mes = data.substr(5, 2);
    const dia = data.substr(8, 2);

    return `${dia}/${mes}/${ano}`;
  };

  const getOptionValue = useCallback((id: string, listOptions: NodeList): string => {
    let valor = '';
    listOptions.forEach((item: Node) => {
      if ((item as HTMLOptionElement).value === id && item.textContent !== null) {
        valor = item.textContent;
      }
    });

    return valor;
  }, []);

  const handleOnFilter = useCallback(async () => {
    let id_tipo = '';
    let id_situacao = '';
    let descricao = '';
    let tipoDataPesquisa = '';
    let dt_inicio = '';
    let dt_fim = '';
    let idContaCorrente = '';
    let idContaFinanceira = '';
    let lote_importacao = '';

    const fieldInputDataInicio = document.getElementById(fieldFilterDataInicio);
    if (fieldInputDataInicio) {
      dt_inicio = (fieldInputDataInicio as HTMLInputElement).value;
    }

    const fieldInputDataFim = document.getElementById(fieldFilterDataFim);
    if (fieldInputDataFim) {
      dt_fim = (fieldInputDataFim as HTMLInputElement).value;
    }

    let dataInvalida = false;
    if (new Date(dt_inicio).toString() === 'Invalid Date') {
      addToast({
        type: 'error',
        title: 'Data início',
        description: 'Data início é obigatória',
      });

      dataInvalida = true;
    }

    if (new Date(dt_fim).toString() === 'Invalid Date') {
      addToast({
        type: 'error',
        title: 'Data fim',
        description: 'Data fim é obigatória',
      });

      dataInvalida = true;
    }

    if (dataInvalida) {
      return;
    }

    setOpenLoading(true);

    const fieldSelectTipo = document.getElementById(fieldFilterTipo);
    if (fieldSelectTipo) {
      id_tipo = (fieldSelectTipo as HTMLSelectElement).value;
    }

    const fieldSelectSituacao = document.getElementById(fieldFilterSituacao);
    if (fieldSelectSituacao) {
      id_situacao = (fieldSelectSituacao as HTMLSelectElement).value;
    }

    let textoFiltroContaCorrente = '';
    const fieldSelectContaCorrente = document.getElementById(formFilterIdFieldContaCorrente);
    if (fieldSelectContaCorrente) {
      idContaCorrente = (fieldSelectContaCorrente as HTMLSelectElement).value;
      if (idContaCorrente !== '9-Todos') {
        textoFiltroContaCorrente = `C.C: ${getOptionValue(idContaCorrente, fieldSelectContaCorrente.childNodes)}`;
      }
    }

    let textoFiltroContaFinanceira = '';
    const fieldSelectContaFinanceira = document.getElementById(formFilterIdFieldContaFinanceira);
    if (fieldSelectContaFinanceira) {
      idContaFinanceira = (fieldSelectContaFinanceira as HTMLSelectElement).value;
      if (idContaFinanceira !== '9-Todos') {
        textoFiltroContaFinanceira = `C. Financeira: ${getOptionValue(
          idContaFinanceira,
          fieldSelectContaFinanceira.childNodes,
        )}`;
      }
    }

    const fieldInputDescricao = document.getElementById(fieldFilterDescricao);
    if (fieldInputDescricao) {
      descricao = (fieldInputDescricao as HTMLInputElement).value;
    }

    const fieldSelectTipoDataPesquisa = document.getElementById(idSelectTipoDataPesquisa);
    if (fieldSelectTipoDataPesquisa) {
      tipoDataPesquisa = (fieldSelectTipoDataPesquisa as HTMLInputElement).value;
    }

    const fieldInputLoteImportacao = document.getElementById(fieldFilterLoteImportacao);
    if (fieldInputLoteImportacao) {
      lote_importacao = (fieldInputLoteImportacao as HTMLInputElement).value;
    }

    const filtro: IFinMovimentosFilter = {
      tipo: id_tipo,
      situacao: id_situacao,
      data_inicio: new Date(`${dt_inicio.substring(0, 10)}T12:00:00`),
      data_fim: new Date(`${dt_fim.substring(0, 10)}T12:00:00`),
      tipo_data_pesquisa: tipoDataPesquisa,
      descricao_movimento: descricao,
      idContaCorrente,
      idContaFinanceira,
      lote_importacao,
    };

    localStorage.setItem(localStorageIdFilter, JSON.stringify(filtro));

    let textoFiltroTipo = '';
    if (filtro.tipo !== '99-Todos') {
      if (filtro.tipo.substring(1, 2) === '-') {
        textoFiltroTipo = `[${filtro.tipo.substring(2, filtro.tipo.length)}]`;
      } else {
        textoFiltroTipo = `[${filtro.tipo.substring(3, filtro.tipo.length)}]`;
      }
    }

    const textoFiltroSituacao =
      filtro.situacao !== '9-Todos' ? `Situação: [${filtro.situacao.substring(2, filtro.situacao.length)}]` : '';
    const textoFiltroTipoDataPesquisa = `${filtro.tipo_data_pesquisa.substring(
      2,
      filtro.tipo_data_pesquisa.length,
    )}: [${formatarData(dt_inicio)} à ${formatarData(dt_fim)}]`;

    const textoFiltroDescricao =
      descricao.trim() !== '' ? `Descrição Movimento Financeiro: [${filtro.descricao_movimento}]` : '';

    const textoFiltroLote = lote_importacao.trim() !== '' ? `Lote: [${filtro.lote_importacao}]` : '';

    setTextFiltro(
      `   Filtrado por: ${textoFiltroTipo} ${textoFiltroSituacao} ${textoFiltroTipoDataPesquisa} ${textoFiltroDescricao} ${textoFiltroContaCorrente} ${textoFiltroContaFinanceira} ${textoFiltroLote}`,
    );

    getValues();

    await getFinMovimentos(filtro);

    setOpenLoading(false);
    handleClick('pesquisar');
  }, [
    addToast,
    getValues,
    getFinMovimentos,
    idSelectTipoDataPesquisa,
    formFilterIdFieldContaCorrente,
    formFilterIdFieldContaFinanceira,
    getOptionValue,
  ]);

  const defaultFilterTipo = useCallback((): string => {
    const filterValue = localStorage.getItem(localStorageIdFilter);
    if (filterValue) {
      const value = JSON.parse(filterValue).tipo as string;
      if (value.trim() !== '') {
        return value;
      }
    }

    if (options_tipo[0]) {
      return options_tipo[0].value;
    }

    return '';
  }, []);

  const defaultFilterSituacao = useCallback((): string => {
    const filterValue = localStorage.getItem(localStorageIdFilter);
    if (filterValue) {
      const value = JSON.parse(filterValue).situacao as string;
      if (value.trim() !== '') {
        return value;
      }
    }

    if (options_situacao[0]) {
      return options_situacao[0].value;
    }

    return '';
  }, []);

  const defaultFilterTipoDataPesquisa = useCallback((): string => {
    const filterValue = localStorage.getItem(localStorageIdFilter);
    if (filterValue) {
      const value = JSON.parse(filterValue).tipo_data_pesquisa as string;
      if (value !== undefined && value.trim() !== '') {
        return value;
      }
    }

    return '1-Data Competência';
  }, []);

  const defaultFilterContaCorrente = useCallback((): string => {
    const filterValue = localStorage.getItem(localStorageIdFilter);
    if (filterValue) {
      const value = JSON.parse(filterValue).idContaCorrente as string;
      if (value !== undefined && value.trim() !== '') {
        return value;
      }
    }

    return '9-Todos';
  }, []);

  const defaultFilterContaFinanceira = useCallback((): string => {
    const filterValue = localStorage.getItem(localStorageIdFilter);
    if (filterValue) {
      const value = JSON.parse(filterValue).idContaFinanceira as string;
      if (value !== undefined && value.trim() !== '') {
        return value;
      }
    }

    return '9-Todos';
  }, []);

  const defaultFilterDataInicio = (): string => {
    const filterValue = localStorage.getItem(localStorageIdFilter);
    if (filterValue) {
      return (JSON.parse(filterValue).data_inicio as string).substring(0, 10);
    }

    const data = new Date();
    let mes = data.getMonth();
    let ano = data.getFullYear();

    const mesRest = mes - 5;

    if (mesRest < 0) {
      ano -= 1;
      mes = 11 - mesRest;
    } else {
      mes -= 5;
    }
    const mesText: string = mes < 10 ? `0${mes}` : `${mes}`;
    return `${ano}-${mesText}-01`;
  };

  const getLastDay = (y: number, m: number): number => {
    return new Date(y, m, 0).getDate();
  };

  const defaultFilterDataFim = (): string => {
    const filterValue = localStorage.getItem(localStorageIdFilter);
    if (filterValue) {
      return (JSON.parse(filterValue).data_fim as string).substring(0, 10);
    }

    const data = new Date();
    let mes = data.getMonth() + 1;
    let ano = data.getFullYear();

    const mesRest = mes + 6;
    if (mesRest > 12) {
      ano += 1;
      mes = mesRest - 12;
    } else {
      mes += 6;
      if (mes === 13) mes = 1;
    }

    const dia = getLastDay(ano, mes);
    const mesText: string = mes < 10 ? `0${mes}` : `${mes}`;
    return `${ano}-${mesText}-${dia}`;
  };

  const defaultFilterDataQuitacao = (): string => {
    let dataRetorno = '';
    if (finMovimentosQuitar) {
      if (finMovimentosQuitar.data_quitacao) {
        dataRetorno = finMovimentosQuitar.data_quitacao.substring(0, 10);
      } else {
        dataRetorno = finMovimentosQuitar.data_vencimento.substring(0, 10);
      }
    }

    if (dataRetorno === '') {
      const data = new Date();
      const mes = data.getMonth() + 1;
      const ano = data.getFullYear();
      const dia = data.getDate();
      const diaText: string = dia < 10 ? `0${dia}` : `${dia}`;
      const mesText: string = mes < 10 ? `0${mes}` : `${mes}`;
      dataRetorno = `${ano}-${mesText}-${diaText}`;
    }

    return dataRetorno;
  };

  const getIdConta = (): string => {
    const fieldInput = document.getElementById(idfieldSelectContaFinanceira);
    if (fieldInput) {
      const id = (fieldInput as HTMLInputElement).value;

      return id;
    }

    return '';
  };

  const handleOnFocusDataVencimento = useCallback(() => {
    const fieldData = document.getElementById(formIdFieldDataCompetencia);
    const fieldDataVencimento = document.getElementById(idfieldDataVencimento);
    if (fieldData && fieldDataVencimento) {
      if ((fieldDataVencimento as HTMLInputElement).valueAsDate === null) {
        if ((fieldData as HTMLInputElement).valueAsDate !== null) {
          const data = (fieldData as HTMLInputElement).value;
          (fieldDataVencimento as HTMLInputElement).value = data;
        }
      }
    }
  }, [formIdFieldDataCompetencia]);

  const getJsonContaCorrente = useCallback((inValue: IDemGerCadContaCorrente): string => {
    return `{"Banco": "${inValue.descricao_banco.trim()}", "Agencia": "${inValue.agencia.trim()}", "Conta": "${inValue.conta_corrente.trim()}", "Descricao": "${inValue.descricao_conta.trim()}"}`;
  }, []);

  const handleOnCopyContaCorrente = useCallback(() => {
    const listClipboard = listDemGerCadContaCorrenteSelect.map(itemList => getJsonContaCorrente(itemList));
    navigator.clipboard.writeText(listClipboard.join('\n'));
    addToast({
      type: 'success',
      title: 'Copiado!',
      description: '',
    });
  }, [listDemGerCadContaCorrenteSelect, addToast, getJsonContaCorrente]);

  const handleOnCopyContaCorrenteTransferencia = useCallback(() => {
    const listClipboard = listDemGerCadContaCorrenteTransferenciaSelect.map(itemList => getJsonContaCorrente(itemList));

    navigator.clipboard.writeText(listClipboard.join('\n'));
    addToast({
      type: 'success',
      title: 'Copiado!',
      description: '',
    });
  }, [listDemGerCadContaCorrenteTransferenciaSelect, addToast, getJsonContaCorrente]);

  const handleOnCopyCentroCusto = useCallback(() => {
    const listClipboard = listDemGerCadCCustoSelect.map(itemList => `${itemList.centro_custo.trim()}`);

    navigator.clipboard.writeText(listClipboard.join('\n'));
    addToast({
      type: 'success',
      title: 'Copiado!',
      description: '',
    });
  }, [listDemGerCadCCustoSelect, addToast]);

  const handleOnCopyContaFinanceira = useCallback(() => {
    const listClipboard = listDemGerCadContaSelect.map(
      itemList => `${itemList.cod_cta.trim()}-${itemList.nome_cta.trim()}`,
    );

    navigator.clipboard.writeText(listClipboard.join('\n'));
    addToast({
      type: 'success',
      title: 'Copiado!',
      description: '',
    });
  }, [listDemGerCadContaSelect, addToast]);

  return (
    <Container>
      <Header />

      <Tab>
        <TabButtonArea>
          <TabButton id="pesquisar" isActive={!hiddenPesquisar} onClick={() => handleClick('pesquisar')}>
            Listagem
          </TabButton>
          <TabButton id="cadastrar" isActive={!hiddenCadastrar} onClick={() => handleClick('cadastrar')}>
            Cadastro
          </TabButton>

          <Tooltip title="Reduzir o tamanho da linha" placement="top">
            <TabButtonGridRowHeight id="gridrowheightdown" onClick={() => handleGridRowHeightDown()}>
              <AiOutlineCaretDown />
            </TabButtonGridRowHeight>
          </Tooltip>

          <Tooltip title="Aumentar o tamanho da linha" placement="top">
            <TabButtonGridRowHeight id="gridrowheightup" onClick={() => handleGridRowHeightUp()}>
              <AiOutlineCaretUp />
            </TabButtonGridRowHeight>
          </Tooltip>

          <TabButton id="filtro" isActive={false} onClick={() => handleClick('filtro')}>
            <AiOutlineSearch />
          </TabButton>

          <Tooltip title="Importar arquivo Excel" placement="top">
            <TabButton id="importar_csv" isActive={false} onClick={() => setOpenUploadFileCsv(true)}>
              <FiUploadCloud />
            </TabButton>
          </Tooltip>

          <Tooltip title="Importar arquivo OFX" placement="top">
            <TabButton id="importar_ofx" isActive={false} onClick={() => setOpenUploadFile(true)}>
              <FiUpload />
            </TabButton>
          </Tooltip>

          <Tooltip title="Download Contabilidade" placement="top">
            <TabButton id="download_contabilidade" isActive={false} onClick={() => setOpenDownloadContabilidade(true)}>
              <FiDownload />
            </TabButton>
          </Tooltip>

          <Tooltip title="Novo Registro" placement="top">
            <TabButtonNovo id="new_register" onClick={() => OnSetRegisterToAdd()}>
              <GoPlus />
              Novo
            </TabButtonNovo>
          </Tooltip>

          <Tooltip title="Remover Registros Selecionados" placement="top">
            <span>
              <span hidden={selectedIds.length <= 0}>
                <TabButtonDelete
                  id="remove_all"
                  onClick={() => {
                    setIdDeleteFinMovimentos('');
                    setOpenDeleteMultiple(true);
                  }}
                >
                  <RiDeleteBinLine />
                  Remover
                </TabButtonDelete>
              </span>
            </span>
          </Tooltip>
        </TabButtonArea>

        <TabTitulo>
          <span>{titulo}</span>
        </TabTitulo>
      </Tab>
      <SpanFiltro>{textFiltro}</SpanFiltro>

      <TabContent id="pesquisar" isActive={!hiddenPesquisar}>
        <div style={{ height: '85vh', width: '100%' }} className={cssGrid.root}>
          <ThemeProvider theme={theme}>
            <DataGrid
              rows={listFinMovimentos}
              columns={columns}
              rowHeight={gridRowHeight}
              checkboxSelection
              selectionModel={selectedIds}
              disableSelectionOnClick
              onSelectionModelChange={e => setSelectedIds(e.selectionModel)}
              getRowClassName={params =>
                `super-app-theme--${
                  params.getValue(params.id, 'situacao') === '2-Pendente' ||
                  params.getValue(params.id, 'situacao') === '3-Quitado'
                    ? params.getValue(params.id, 'situacao')
                    : (params.getValue(params.id, 'tipo') as string).substring(0, 2)
                }`
              }
            />
          </ThemeProvider>
        </div>
      </TabContent>

      <TabContent id="cadasto" isActive={!hiddenCadastrar}>
        <Form ref={formRef} onSubmit={handleSubmit} initialData={finMovimentos}>
          <FormContainer>
            <FormNavigate>
              <Tooltip title="Novo Registro" placement="top">
                <IconButton color="primary" aria-label="adicionar" onClick={() => onNewRegister()}>
                  <AddIcon />
                </IconButton>
              </Tooltip>

              <Tooltip title="Duplicar Registro" placement="top">
                <IconButton color="primary" aria-label="Duplicar" onClick={() => OnGridRowDuplicate(finMovimentos)}>
                  <MdContentCopy />
                </IconButton>
              </Tooltip>

              <Tooltip title="Excluir Registro" placement="top">
                <IconButton
                  color="primary"
                  aria-label="Excluir"
                  onClick={() => OnConfirmationDeleteSingle(finMovimentos ? finMovimentos.id : '')}
                >
                  <RiDeleteBinLine />
                </IconButton>
              </Tooltip>

              <Tooltip title="Cancelar" placement="top">
                <IconButton type="reset" color="primary" aria-label="Cancelar" onClick={() => onCancelRegister()}>
                  <MdClear />
                </IconButton>
              </Tooltip>

              <Tooltip title="Salvar" placement="top">
                <IconButton type="submit" color="primary" aria-label="Confirmar">
                  <AiOutlineSave />
                </IconButton>
              </Tooltip>

              <Tooltip title="Primeiro Registro" placement="top">
                <IconButton color="primary" aria-label="Primeiro" onClick={() => onFirstRegister()}>
                  <AiFillFastBackward />
                </IconButton>
              </Tooltip>

              <Tooltip title="Registro Anterior" placement="top">
                <IconButton color="primary" aria-label="Anterior" onClick={() => onPriorRegister()}>
                  <MdSkipPrevious />
                </IconButton>
              </Tooltip>

              <Tooltip title="Próximo Registro" placement="top">
                <IconButton color="primary" aria-label="Próximo" onClick={() => onNextRegister()}>
                  <MdSkipNext />
                </IconButton>
              </Tooltip>

              <Tooltip title="Último Registro" placement="top">
                <IconButton color="primary" aria-label="Último registro" onClick={() => onLastRegister()}>
                  <AiFillFastForward />
                </IconButton>
              </Tooltip>
            </FormNavigate>

            <FormFields>
              <ContainerFieldGoup>
                <ContainerFields field="T500px">
                  <span>Tipo Lançamento:</span>
                  <Select name="tipo" onChange={OnSelectTipoLancamento}>
                    {options_tipo.map(itemTipo => (
                      <option key={itemTipo.value} value={itemTipo.value}>
                        {itemTipo.label}
                      </option>
                    ))}
                  </Select>
                </ContainerFields>

                <ContainerFields
                  field="T227px"
                  className="field2"
                  disable={disableComponents}
                  ocultar={disableComponents}
                >
                  <span>Situação:</span>
                  <Select name="situacao" onChange={OnSelectSituacao}>
                    {options_situacao.map(itemTipo => (
                      <option key={itemTipo.value} value={itemTipo.value}>
                        {itemTipo.label}
                      </option>
                    ))}
                  </Select>
                </ContainerFields>
              </ContainerFieldGoup>

              <ContainerFieldGoup>
                <ContainerFields field="T250px">
                  <span>Data Competência:</span>
                  <InputDate id={formIdFieldDataCompetencia} autoFocus name="data" />
                </ContainerFields>

                <ContainerFields
                  disable={disableComponents}
                  ocultar={disableComponents}
                  field="T250px"
                  className="field2"
                >
                  <span>Data Vencimento:</span>
                  <InputDate
                    autoFocus
                    name="data_vencimento"
                    id={idfieldDataVencimento}
                    onFocus={handleOnFocusDataVencimento}
                  />
                </ContainerFields>

                <ContainerFields
                  disable={disableComponents}
                  ocultar={disableComponents}
                  field="T250px"
                  className="field2"
                >
                  <span>Data Quitação:</span>
                  <InputDate autoFocus name="data_quitacao" id={idfieldDataQuitacao} />
                </ContainerFields>
              </ContainerFieldGoup>

              <ContainerFieldGoup>
                <ContainerFields field="T227px">
                  <span>Valor:</span>
                  <InputComum name="valor_original" id={idfieldValor} placeholder="0,00" type="number" step="0.01" />
                </ContainerFields>

                <ContainerFields
                  disable={disableComponents}
                  ocultar={disableComponents}
                  field="T227px"
                  className="field2"
                >
                  <span>Valor Quitação:</span>
                  <InputComum
                    name="valor_quitacao"
                    id={idfieldValorQuitacao}
                    placeholder="0,00"
                    type="number"
                    step="0.01"
                  />
                </ContainerFields>
              </ContainerFieldGoup>

              <ContainerFields field="T100">
                <span>Conta Corrente:</span>
                <ContentWithExport>
                  <Select id={idfieldSelectContaCorrente} name="id_conta_corrente">
                    {listDemGerCadContaCorrenteSelect.map(itemContaCorrente => (
                      <option key={itemContaCorrente.id} value={itemContaCorrente.id}>
                        {itemContaCorrente.descricao_banco.trim()}-{itemContaCorrente.conta_corrente.trim()}-
                        {itemContaCorrente.descricao_conta.trim()}
                      </option>
                    ))}
                  </Select>

                  <Tooltip title="Copiar" placement="top">
                    <IconButton color="primary" aria-label="adicionar" onClick={() => handleOnCopyContaCorrente()}>
                      <BsClipboard />
                    </IconButton>
                  </Tooltip>
                </ContentWithExport>
              </ContainerFields>

              <ContainerFields field="T100" ocultar={!isTransferencia} id={idDivfieldSelectContaCorrenteTransferencia}>
                <span>Conta Corrente de Destino para a Transferência:</span>
                <ContentWithExport>
                  <Select id={idfieldSelectContaCorrenteTransferencia} name="id_conta_corrente_transferencia">
                    {listDemGerCadContaCorrenteTransferenciaSelect.map(itemContaCorrente => (
                      <option key={itemContaCorrente.id} value={itemContaCorrente.id}>
                        {itemContaCorrente.descricao_banco.trim()}-{itemContaCorrente.conta_corrente.trim()}-
                        {itemContaCorrente.descricao_conta.trim()}
                      </option>
                    ))}
                  </Select>
                  <Tooltip title="Copiar" placement="top">
                    <IconButton
                      color="primary"
                      aria-label="adicionar"
                      onClick={() => handleOnCopyContaCorrenteTransferencia()}
                    >
                      <BsClipboard />
                    </IconButton>
                  </Tooltip>
                </ContentWithExport>
              </ContainerFields>

              <ContainerFields field="T100" ocultar={isTransferencia} id={idDivfieldSelectContaFinanceira}>
                <span>Conta Financeira:</span>
                <ContentWithExport>
                  <Select
                    id={idfieldSelectContaFinanceira}
                    name="id_conta_financeira"
                    onChange={OnSelectContaFinanceira}
                  >
                    {listDemGerCadContaSelect.map(itemConta => (
                      <option key={itemConta.id} value={itemConta.id}>
                        {itemConta.cod_cta.trim()}-{itemConta.nome_cta.trim()}
                      </option>
                    ))}
                  </Select>
                  <Tooltip title="Copiar" placement="top">
                    <IconButton color="primary" aria-label="adicionar" onClick={() => handleOnCopyContaFinanceira()}>
                      <BsClipboard />
                    </IconButton>
                  </Tooltip>
                </ContentWithExport>
              </ContainerFields>

              <ContainerFields
                id={idDivfieldSelectCentroCusto}
                ocultar={isTransferencia}
                field="T100"
                disable={!enableCentroCusto}
              >
                <span>Centro de Custo:</span>
                <ContentWithExport>
                  <Select id={idfieldSelectCentroCusto} name="id_centro_custo" disabled={!enableCentroCusto}>
                    <option key="" value="">
                      {emptyValueSelect}
                    </option>
                    {listDemGerCadCCustoSelect.map(itemCentroCusto => (
                      <option key={itemCentroCusto.id} value={itemCentroCusto.id}>
                        {itemCentroCusto.centro_custo}
                      </option>
                    ))}
                  </Select>
                  <Tooltip title="Copiar" placement="top">
                    <IconButton color="primary" aria-label="adicionar" onClick={() => handleOnCopyCentroCusto()}>
                      <BsClipboard />
                    </IconButton>
                  </Tooltip>
                </ContentWithExport>
              </ContainerFields>

              <ContainerFields field="T100">
                <span>Histórico Padrão:</span>
                <LookupHistoricoPadrao onConfirm={onConfirmHistoricoLookup} onIdConta={getIdConta} />
              </ContainerFields>

              <ContainerFields field="T100">
                <span>Descrição Lançamento:</span>
                <InputComum id={fieldDescricao} autoFocus name="descricao_movimento" />
              </ContainerFields>

              <ContainerFields field="T100" className="desabilitado">
                <span>Lote importacao:</span>
                <InputComum name="lote_importacao" disabled />
              </ContainerFields>

              <ContainerFieldsHidden>
                <InputComum name="conta" hidden />
                <InputComum name="banco" hidden />
                <InputComum name="id_banco" hidden />
                <InputComum name="centro_custo" hidden />
                <InputComum name="quitar" hidden />
                <InputComum name="conta_corrente" hidden />
                <InputComum name="conta_corrente_transferencia" hidden />
                <InputComum name="conta_corrente_descricao" hidden />
                <InputComum name="conta_corrente_transferencia_descricao" hidden />
                <InputComum name="exigir_centro_custo" hidden />
                <InputComum name="id_ofx" hidden />
                <InputComum name="descricao_ofx" hidden />
                <InputComum name="id" hidden />
              </ContainerFieldsHidden>
            </FormFields>
          </FormContainer>
        </Form>
      </TabContent>

      <FormModalDialogConfirm
        open={openDeleteSingle}
        onClose={() => setOpenDeleteSingle(false)}
        onConfirm={() => handleOnRowDeleteSingle()}
        buttonCancelText="Cancelar"
        buttonConfirmText="Sim"
        formTitle="Excluir?"
        messageConfirm="Tem certeza que deseja excluir?"
      />

      <FormModalDialogConfirm
        open={openDeleteMultiple}
        onClose={() => setOpenDeleteMultiple(false)}
        onConfirm={() => handleOnRowDeleteMultiple()}
        buttonCancelText="Cancelar"
        buttonConfirmText="Sim"
        formTitle="Excluir?"
        messageConfirm="Tem certeza que deseja excluir todos os registros selecionados?"
      />

      <Dialog ref={formFilterRef} open={openFilter} onClose={handleCloseFilter}>
        <TituloFiltro id="alert-dialog-title">Filtro</TituloFiltro>
        <DialogContent>
          <ContainerFieldGoup>
            <ContainerFields field="T250px" filter_field>
              <span>Tipo Lançamento:</span>
              <ContainerFilterSelect>
                <select id={fieldFilterTipo} defaultValue={defaultFilterTipo()}>
                  {options_tipo.map(itemTipo => (
                    <option key={itemTipo.value} value={itemTipo.value}>
                      {itemTipo.label}
                    </option>
                  ))}

                  <option key="99-Todos" value="99-Todos">
                    99-Todos
                  </option>
                </select>
              </ContainerFilterSelect>
            </ContainerFields>

            <ContainerFields field="T250px" filter_field>
              <span>Situação:</span>
              <ContainerFilterSelect>
                <select id={fieldFilterSituacao} defaultValue={defaultFilterSituacao()}>
                  {options_situacao.map(itemTipo => (
                    <option key={itemTipo.value} value={itemTipo.value}>
                      {itemTipo.label}
                    </option>
                  ))}

                  <option key="4-Estimada" value="4-Estimada">
                    4-Estimada
                  </option>
                  <option key="5-Transferência Realizada" value="5-Transferência Realizada">
                    5-Transferência Realizada
                  </option>
                  <option key="9-Todos" value="9-Todos">
                    9-Todos
                  </option>
                </select>
              </ContainerFilterSelect>
            </ContainerFields>
          </ContainerFieldGoup>

          <ContainerFields field="T100" filter_field>
            <span>Conta Corrente:</span>
            <ContainerFilterSelect>
              <select id={formFilterIdFieldContaCorrente} defaultValue={defaultFilterContaCorrente()}>
                <option key="9-Todos" value="9-Todos">
                  9-Todos
                </option>

                {listDemGerCadContaCorrente.map(itemContaCorrente => (
                  <option key={itemContaCorrente.id} value={itemContaCorrente.id}>
                    {itemContaCorrente.descricao_banco.trim()}-{itemContaCorrente.conta_corrente.trim()}-
                    {itemContaCorrente.descricao_conta.trim()}
                  </option>
                ))}
              </select>
            </ContainerFilterSelect>
          </ContainerFields>

          <ContainerFields field="T100" filter_field>
            <span>Conta Financeira:</span>
            <ContainerFilterSelect>
              <select id={formFilterIdFieldContaFinanceira} defaultValue={defaultFilterContaFinanceira()}>
                <option key="9-Todos" value="9-Todos">
                  9-Todos
                </option>

                {listDemGerCadConta.map(itemConta => (
                  <option key={itemConta.id} value={itemConta.id}>
                    {itemConta.cod_cta.trim()}-{itemConta.nome_cta.trim()}
                  </option>
                ))}
              </select>
            </ContainerFilterSelect>
          </ContainerFields>

          <ContainerFieldGoup>
            <ContainerFields field="T250px" filter_field>
              <span>Data Inicio:</span>
              <ContainerFilterInput>
                <input type="date" id={fieldFilterDataInicio} required defaultValue={defaultFilterDataInicio()} />
              </ContainerFilterInput>
            </ContainerFields>

            <ContainerFields field="T250px" className="field2filter" filter_field>
              <span>Data Fim:</span>
              <ContainerFilterInput>
                <input type="date" id={fieldFilterDataFim} required defaultValue={defaultFilterDataFim()} />
              </ContainerFilterInput>
            </ContainerFields>

            <ContainerFields field="T250px" className="field2filter" filter_field>
              <span>Tipo Data pesquisa:</span>
              <ContainerFilterSelect>
                <select id={idSelectTipoDataPesquisa} defaultValue={defaultFilterTipoDataPesquisa()}>
                  <option key="1-Data Competência" value="1-Data Competência">
                    1-Data Competência
                  </option>
                  <option key="2-Data Vencimento" value="2-Data Vencimento">
                    2-Data Vencimento
                  </option>
                  <option key="3-Data Quitação" value="3-Data Quitação">
                    3-Data Quitação
                  </option>
                </select>
              </ContainerFilterSelect>
            </ContainerFields>
          </ContainerFieldGoup>

          <ContainerFields field="T100" filter_field>
            <span>Descrição Movimento Financeiro:</span>
            <ContainerFilterInput>
              <input id={fieldFilterDescricao} required />
            </ContainerFilterInput>
          </ContainerFields>

          <ContainerFields field="T100" filter_field>
            <span>Lote importação:</span>
            <ContainerFilterInput>
              <input id={fieldFilterLoteImportacao} />
            </ContainerFilterInput>
          </ContainerFields>
        </DialogContent>

        <DialogActions>
          <ButtonDialog onClick={handleCloseFilter} color="primary" autoFocus>
            Cancelar
          </ButtonDialog>
          <ButtonDialog onClick={handleOnFilter} color="primary">
            Filtrar
          </ButtonDialog>
        </DialogActions>
      </Dialog>

      <Dialog
        ref={formConfirmQuitarRef}
        open={openConfirmDataQuitacao}
        onClose={() => setOpenConfirmDataQuitacao(false)}
      >
        <TituloFiltro id="alert-dialog-title">Quitar Lançamento</TituloFiltro>
        <DialogContent>
          <ContainerFields field="T250px" filter_field>
            <span>Data Quitação:</span>
            <ContainerFilterInput>
              <input
                type="date"
                id={formConfirmIdFieldDataQuitar}
                required
                defaultValue={defaultFilterDataQuitacao()}
              />
            </ContainerFilterInput>
          </ContainerFields>
        </DialogContent>

        <DialogActions>
          <ButtonDialog onClick={() => setOpenConfirmDataQuitacao(false)} color="primary" autoFocus>
            Cancelar
          </ButtonDialog>
          <ButtonDialog onClick={OnClickConfirmQuitar} color="primary">
            Confirmar
          </ButtonDialog>
        </DialogActions>
      </Dialog>

      <ModalUploadFileOFX open={openUploadFile} onClose={onUploadFileClose} apiSource={finMovimentosFile} />

      <ModalUploadFileCSV
        open={openUploadFileCsv}
        onClose={onUploadFileCloseCsv}
        apiSource={finMovimentosFileCsv}
        apiSourceFileExample="/finmovimentos/file/csv/example"
      />

      <ModalDownloadContabilidade open={openDownloadContabilidade} onClose={onCloseDownloadContabilidade} />

      <Backdrop className={cssLoading.backdrop} open={openLoading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </Container>
  );
};

export default FinMovimentos;
