import React, { useCallback, useState, useEffect } from 'react';
import { uuid } from 'uuidv4';
import filesize from 'filesize';
import { DropEvent } from 'react-dropzone';

import Dialog from '@material-ui/core/Dialog';
import ButtonDialog from '@material-ui/core/Button';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import api from '../../services/api';
import { useToast } from '../../hooks/Toast';
import { useAuth } from '../../hooks/Auth';
import UploadFile from '../UploadFile';
import FileList from '../FileList';
import IFile from '../FileList/IFile';
import {
  TitleHead,
  TitleDiv,
  ContainerFields,
  ContainerFilterSelect,
  ContainerFieldGoup,
  ContainerFilterInput,
} from './styles';

type functionVoid = () => void;

interface IModalUploadFileProps {
  open: boolean;
  onClose: functionVoid;
}

const ModalUploadFile: React.FC<IModalUploadFileProps> = ({ onClose, open }) => {
  const { user } = useAuth();
  const { addToast } = useToast();
  const [uploadedFiles, setUploadedFiles] = useState<IFile[]>([]);
  const [uploadedFilesError, setUploadedFilesError] = useState<IFile[]>([]);
  const [uploadedFilesFinished, setUploadedFilesFinished] = useState<IFile[]>([]);

  const handleClose = useCallback(() => {
    setUploadedFiles([]);
    if (onClose) {
      onClose();
    }
  }, [onClose]);

  const processUpload = useCallback(
    async uploadedFile => {
      const data = new FormData();

      const localDate = new Date();
      const dia = localDate.getDate();
      const mes = localDate.getMonth() + 1;
      const ano = localDate.getFullYear();
      const dt_envio = `${ano}-${mes.toString().padStart(2, '0')}-${dia.toString().padStart(2, '0')}`;
      let dt_inicio = '';
      let dt_fim = '';
      let descricao = '';
      let tp_arquivo = '';

      const fieldInputDataInicio = document.getElementById('fieldfilterdatainicio');
      if (fieldInputDataInicio) {
        dt_inicio = (fieldInputDataInicio as HTMLInputElement).value;
      }
      const fieldInputDataFim = document.getElementById('fieldFilterDataFim');
      if (fieldInputDataFim) {
        dt_fim = (fieldInputDataFim as HTMLInputElement).value;
      }

      const fieldInputDescricao = document.getElementById('fieldFilterDescricao');
      if (fieldInputDescricao) {
        descricao = (fieldInputDescricao as HTMLInputElement).value;
      }

      const fieldSelectTipoArquivo = document.getElementById('tipoarquivo');
      if (fieldSelectTipoArquivo) {
        tp_arquivo = (fieldSelectTipoArquivo as HTMLInputElement).value;
      }

      let dataInvalida = false;
      if (new Date(dt_inicio).toString() === 'Invalid Date') {
        addToast({
          type: 'error',
          title: 'Data início',
          description: 'Data início é obigatória',
        });

        dataInvalida = true;
      }

      if (new Date(dt_fim).toString() === 'Invalid Date') {
        addToast({
          type: 'error',
          title: 'Data fim',
          description: 'Data fim é obigatória',
        });

        dataInvalida = true;
      }

      if (new Date(dt_envio).toString() === 'Invalid Date') {
        addToast({
          type: 'error',
          title: 'Data fim',
          description: 'Data é obigatória',
        });

        dataInvalida = true;
      }

      if (dataInvalida) {
        return;
      }

      const localDateInicio = dt_inicio.substring(0, 10).split('-');
      const mesInicio = parseInt(localDateInicio[1], 10);
      const anoInicio = parseInt(localDateInicio[0], 10);

      const localDateFim = dt_fim.substring(0, 10).split('-');
      const mesFim = parseInt(localDateFim[1], 10);
      const anoFim = parseInt(localDateFim[0], 10);

      if (tp_arquivo === '1') {
        // 1-Sped Contábil
        if (anoInicio !== anoFim) {
          addToast({
            type: 'error',
            title: 'Data início/fim',
            description: `Data de início (${anoInicio}) e fim deve (${anoFim}) ser no mesmo ano`,
          });
          dataInvalida = true;
        }
      } else if (mesInicio !== mesFim || anoInicio !== anoFim) {
        // 2-Sped Fiscal
        // 3-Sped Contribuições
        addToast({
          type: 'error',
          title: 'Data início/fim',
          description: `Data de início (${mesInicio}/${anoInicio}) e fim (${mesFim}/${anoFim}) deve ser no mesmo mês`,
        });
        dataInvalida = true;
      }

      if (dataInvalida) {
        return;
      }
      data.append('file', uploadedFile.file, uploadedFile.name);
      data.append('id_license', user.license.id);
      data.append('id_company', user.empresa.id);
      data.append('tp_arquivo', tp_arquivo);
      data.append('observacao', descricao);
      data.append('dt_envio', dt_envio);
      data.append('dt_inicio', `${dt_inicio.substring(0, 10)}T12:00:00`);
      data.append('dt_fim', `${dt_fim.substring(0, 10)}T12:00:00`);
      data.append('system_kind', user.empresa.active_system_kind.toString());

      const item: IFile = {
        uploaded: false,
        id: uploadedFile.id,
        name: uploadedFile.name,
        readableSize: uploadedFile.readableSize,
        isUploading: true,
        error: false,
      };

      api
        .post('/historicoimportacao/file', data)
        .then(response => {
          item.uploaded = true;
          item.isUploading = false;

          setUploadedFilesFinished([...uploadedFilesFinished, item]);

          addToast({
            type: 'success',
            title: 'Carregado',
            description: `Arquivo ${uploadedFile.name} carregado com sucesso`,
          });
          handleClose();
        })

        .catch(() => {
          item.uploaded = false;
          item.error = true;
          item.isUploading = false;

          setUploadedFilesError([...uploadedFilesError, item]);

          addToast({
            type: 'error',
            title: 'Falhou',
            description: `Ocorreu uma falha ao carregar o arquivo ${uploadedFile.name}. Por favor verifique a estrutura do arquivo.`,
          });
        });
    },

    [addToast, uploadedFilesFinished, uploadedFilesError, user, handleClose],
  );

  const handleUpload = useCallback(async () => {
    const newList = await Promise.all(
      uploadedFiles.map(item => ({
        ...item,
        error: false,
        isUploading: !item.uploaded,
      })),
    );
    setUploadedFiles(newList);

    const listUpload = newList.filter(item => item.isUploading);

    listUpload.forEach(processUpload);
  }, [uploadedFiles, processUpload]);

  const handleSelectFiles = useCallback(
    <T extends File>(files: T[], event: DropEvent): void => {
      const uploadedList = files.map(file => {
        const newFile: IFile = {
          file,
          id: uuid(),
          name: file.name,
          readableSize: filesize(file.size),
          isUploading: false,
          uploaded: false,
          error: false,
        };

        return newFile;
      });

      const newList = uploadedList.concat(uploadedFiles);

      setUploadedFiles(newList);
    },
    [uploadedFiles],
  );

  useEffect(() => {
    uploadedFilesFinished.map(item => {
      const findItem = uploadedFiles.find(it => it.id === item.id);
      if (findItem) {
        findItem.isUploading = false;
        findItem.uploaded = true;
      }

      return findItem;
    });

    uploadedFilesError.map(item => {
      const findItem = uploadedFiles.find(it => it.id === item.id);
      if (findItem) {
        findItem.isUploading = false;
        findItem.error = true;
      }

      return findItem;
    });
  }, [uploadedFiles, uploadedFilesError, uploadedFilesFinished]);

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <TitleHead>
        <TitleDiv>
          <DialogTitle id="alert-dialog-title">Carregar arquivos</DialogTitle>
        </TitleDiv>
      </TitleHead>

      <DialogContent>
        <ContainerFields field="T100" filter_field>
          <span>Tipo de arquivo</span>
          <ContainerFilterSelect>
            <select id="tipoarquivo" defaultValue={1}>
              <option key="1" value="1">
                1-Sped Contábil
              </option>
              <option key="2" value="2">
                2-Sped Fiscal
              </option>
              <option key="3" value="3">
                3-Sped Contribuições
              </option>
            </select>
          </ContainerFilterSelect>
        </ContainerFields>

        <ContainerFieldGoup>
          <ContainerFields field="T250px" filter_field>
            <span>Data Inicio</span>
            <ContainerFilterInput>
              <input type="date" id="fieldfilterdatainicio" required />
            </ContainerFilterInput>
          </ContainerFields>

          <ContainerFields field="T250px" className="field2filter" filter_field>
            <span>Data Fim</span>
            <ContainerFilterInput>
              <input type="date" id="fieldFilterDataFim" required />
            </ContainerFilterInput>
          </ContainerFields>
        </ContainerFieldGoup>

        <ContainerFields field="T100" filter_field>
          <span>Observação</span>
          <ContainerFilterInput>
            <input id="fieldFilterDescricao" />
          </ContainerFilterInput>
        </ContainerFields>

        <ContainerFields field="T100" filter_field>
          <span>Arquivos válidos (TXT)</span>
          <UploadFile onUpload={handleSelectFiles} isTXTFile />
          {!!uploadedFiles.length && <FileList files={uploadedFiles} />}
        </ContainerFields>
      </DialogContent>
      <DialogActions>
        <ButtonDialog onClick={handleClose} color="secondary" autoFocus variant="contained">
          Cancelar
        </ButtonDialog>
        <ButtonDialog onClick={handleUpload} color="primary" variant="contained">
          Enviar
        </ButtonDialog>
      </DialogActions>
    </Dialog>
  );
};

export default ModalUploadFile;
