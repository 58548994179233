import { GridColDef } from '@material-ui/data-grid';

export const ColumnCodigo: GridColDef = {
  headerName: 'Código',
  field: 'codigo',
  width: 200,
};

export const ColumnDescricao: GridColDef = {
  headerName: 'Descrição',
  field: 'descricao',
  width: 600,
};

export const ColumnObservacao: GridColDef = {
  headerName: 'Observação',
  field: 'obs',
  width: 600,
};

export const ColumnPermiteCalcularProjecaoOrcamentaria: GridColDef = {
  headerName: 'Permite Calcular Projeção Orçamentária',
  field: 'permite_calc_projecao_orc',
  type: 'boolean',
  width: 400,
};

export const ColumnId: GridColDef = {
  headerName: '#',
  field: 'id',
  hide: true,
  width: 400,
  editable: false,
  filterable: false,
  hideSortIcons: true,
  sortable: false,
  disableColumnMenu: true,
};
