import React, { useCallback, useState, useEffect, KeyboardEvent } from 'react';

import Modal from '@material-ui/core/Modal';
import { DataGrid, GridColDef, GridRowId, ptBR } from '@material-ui/data-grid';
import { createMuiTheme, ThemeProvider, makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import Backdrop from '@material-ui/core/Backdrop';
import Tooltip from '@material-ui/core/Tooltip';
import Fade from '@material-ui/core/Fade';
import ButtonDialog from '@material-ui/core/Button';
import PageviewIcon from '@material-ui/icons/Pageview';

import { useAuth } from '../../hooks/Auth';
import { useLookup } from '../../hooks/Lookup/index';
import IDemGerLookup, { emptyDemGerLookup } from '../../hooks/Lookup/IDemGerLookup';

import api from '../../services/api';

import {
  GridColumnCodTot,
  GridColumnNomeTot,
  GridColumnClassifTot,
  GridColumnCodNat,
  GridColumnId,
} from './GridColumns';
import { TitleSearch, ButtonGroup, ButtonSearch, ContainerLookup, ContainerSearch } from './styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    paper: {
      backgroundColor: theme.palette.background.paper,
      border: '1px solid #006699',
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
    },
  }),
);

const theme = createMuiTheme(
  {
    palette: {
      primary: { main: '#006699' },
    },
  },
  ptBR,
);

type functionConfirm = (list: IDemGerLookup[]) => void;
type functionGetTextInput = (value: string) => void;
type functionVoid = () => void;
type functionString = () => string;

interface ILookupDemGerProps {
  in_id?: string;
  idInputLookup: string;
  onIdDemGer?: functionString;
  onClearId?: functionVoid;
  onConfirm?: functionConfirm;
  onGetTextInput?: functionGetTextInput;
  onCodigoTotalizadorBase?: functionString;
  multiSelect?: boolean;
  clearInputSearchField?: boolean;
  permiteCalcProjecaoOrc?: boolean;
  inIdTipoDemGer?: string;
  inPlaceholder?: string;
}

const LookupDemGer: React.FC<ILookupDemGerProps> = ({
  in_id,
  idInputLookup,
  onIdDemGer,
  onClearId,
  multiSelect,
  onConfirm,
  onGetTextInput,
  onCodigoTotalizadorBase,
  clearInputSearchField,
  permiteCalcProjecaoOrc,
  inIdTipoDemGer,
  inPlaceholder,
}) => {
  const { user } = useAuth();
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [idDemGer, setIdDemGer] = React.useState<string>('');
  const [inputTextValue, setInputTextValue] = React.useState<string>('');
  const [selectedIds, setSelectedIds] = useState<GridRowId[]>([]);
  const { getListDemGer, updateListDemGer } = useLookup();
  const [listDemGersRegister, setListDemGersRegister] = useState<IDemGerLookup[]>([]);

  const columns: GridColDef[] = [
    GridColumnCodTot,
    GridColumnNomeTot,
    GridColumnClassifTot,
    GridColumnCodNat,
    GridColumnId,
  ];

  const handleOnClickConfirm = useCallback((): void => {
    const filterList: IDemGerLookup[] = selectedIds.map(id => {
      const item = listDemGersRegister.find(valor => valor.id === id);

      if (item) {
        return {
          id: item.id,
          cod_tot: item.cod_tot,
          nome_tot: item.nome_tot,
          classif_tot: item.classif_tot,
          cod_nat: item.cod_nat,
        };
      }

      return emptyDemGerLookup;
    });

    const list: IDemGerLookup[] = filterList.filter(item => item.id !== '');

    const fieldInputSearch = document.getElementById(idInputLookup);
    if (fieldInputSearch) {
      if (clearInputSearchField) {
        setInputTextValue('');
        (fieldInputSearch as HTMLInputElement).value = '';
      } else {
        const inputValue = list.map(item => item.nome_tot);
        if (onGetTextInput) {
          onGetTextInput(inputValue.join());
        }
        setInputTextValue(inputValue.join());
        (fieldInputSearch as HTMLInputElement).value = inputValue.join();
      }
    }

    updateListDemGer(list);

    setOpen(false);
    if (onConfirm) {
      onConfirm(list);
    }
  }, [
    selectedIds,
    listDemGersRegister,
    updateListDemGer,
    onConfirm,
    onGetTextInput,
    clearInputSearchField,
    idInputLookup,
  ]);

  const handleOnClickCancel = useCallback((): void => {
    setOpen(false);
  }, []);

  const getDemGerById = useCallback(
    async (id: string) => {
      let responseList: IDemGerLookup[] = [];
      if (id !== '' && id !== null && id !== undefined) {
        const response = await api.get<IDemGerLookup[]>(`/demger/filter`, {
          params: {
            license: user.license.id,
            cnpj: `'${user.empresa.cnpj}'`,
            id_dem_ger: id,
            id_tipo_dem_ger: inIdTipoDemGer ? `'${inIdTipoDemGer}'` : '',
            tot_titulo: '',
            nome_tot: '',
            permite_calc_projecao_orc: permiteCalcProjecaoOrc,
            cod_tot_base: '',
          },
        });

        responseList = response.data;
      } else {
        setInputTextValue('');
      }

      setListDemGersRegister(responseList);
      let item: IDemGerLookup = emptyDemGerLookup;
      if (responseList.length > 0) {
        item = {
          id: responseList[0].id,
          cod_tot: responseList[0].cod_tot,
          nome_tot: responseList[0].nome_tot,
          classif_tot: responseList[0].classif_tot,
          cod_nat: responseList[0].cod_nat,
        };
      }

      const fieldInputSearch = document.getElementById(idInputLookup);
      if (fieldInputSearch) {
        setInputTextValue(item.nome_tot);
        (fieldInputSearch as HTMLInputElement).value = item.nome_tot;
      }

      updateListDemGer([item]);

      if (onConfirm) {
        onConfirm([item]);
      }
    },
    [
      updateListDemGer,
      onConfirm,
      idInputLookup,
      user.license.id,
      permiteCalcProjecaoOrc,
      user.empresa.cnpj,
      inIdTipoDemGer,
    ],
  );

  const getInputValue = useCallback((): string => {
    const fieldInputSearch = document.getElementById(idInputLookup);
    let inputValue = '';
    if (fieldInputSearch) {
      inputValue = (fieldInputSearch as HTMLInputElement).value;
    }

    return inputValue;
  }, [idInputLookup]);

  const getDemGer = useCallback(
    async (demGer_Nome: string) => {
      let responseList: IDemGerLookup[] = [];
      let id_DemGer = '';
      let assignIdDemGer = false;

      let codigoTotalizadorBase = '';
      if (onCodigoTotalizadorBase) {
        codigoTotalizadorBase = onCodigoTotalizadorBase();
      }

      if (onIdDemGer) {
        id_DemGer = onIdDemGer();
        assignIdDemGer = id_DemGer !== '' && id_DemGer !== null && id_DemGer !== undefined;

        if (assignIdDemGer) {
          const response = await api.get<IDemGerLookup[]>(`/demger/filter`, {
            params: {
              license: user.license.id,
              cnpj: `'${user.empresa.cnpj}'`,
              id_dem_ger: id_DemGer,
              id_tipo_dem_ger: inIdTipoDemGer ? `'${inIdTipoDemGer}'` : '',
              tot_titulo: '',
              nome_tot: '',
              permite_calc_projecao_orc: permiteCalcProjecaoOrc,
              cod_tot_base: codigoTotalizadorBase,
            },
          });
          responseList = response.data;
        }

        if (!assignIdDemGer || responseList.length <= 0) {
          const response = await api.get<IDemGerLookup[]>(`/demger/filter`, {
            params: {
              license: user.license.id,
              cnpj: `'${user.empresa.cnpj}'`,
              id_dem_ger: '',
              id_tipo_dem_ger: inIdTipoDemGer ? `'${inIdTipoDemGer}'` : '',
              tot_titulo: '',
              nome_tot: demGer_Nome,
              permite_calc_projecao_orc: permiteCalcProjecaoOrc,
              cod_tot_base: codigoTotalizadorBase,
            },
          });
          responseList = response.data;
        }
      } else if (demGer_Nome.trim() !== demGer_Nome && inputTextValue !== getInputValue()) {
        const response = await api.get<IDemGerLookup[]>(`/demger/filter`, {
          params: {
            license: user.license.id,
            cnpj: `'${user.empresa.cnpj}'`,
            id_dem_ger: '',
            id_tipo_dem_ger: inIdTipoDemGer ? `'${inIdTipoDemGer}'` : '',
            tot_titulo: '',
            nome_tot: demGer_Nome,
            permite_calc_projecao_orc: permiteCalcProjecaoOrc,
            cod_tot_base: codigoTotalizadorBase,
          },
        });
        responseList = response.data;
      } else {
        const response = await api.get<IDemGerLookup[]>(`/demger/filter`, {
          params: {
            license: user.license.id,
            cnpj: `'${user.empresa.cnpj}'`,
            id_dem_ger: '',
            id_tipo_dem_ger: inIdTipoDemGer ? `'${inIdTipoDemGer}'` : '',
            tot_titulo: '',
            nome_tot: '',
            permite_calc_projecao_orc: permiteCalcProjecaoOrc,
            cod_tot_base: codigoTotalizadorBase,
          },
        });
        responseList = response.data;
      }

      setListDemGersRegister(responseList);
      const listDemGer = getListDemGer();

      const list = responseList.map(item => {
        const fintItem = listDemGer.find(itemDemGer => itemDemGer.id === item.id);
        if (fintItem) return fintItem.id;

        return '';
      });

      const filterList = list.filter(item => item !== '');

      setSelectedIds(filterList);

      setOpen(true);
    },
    [
      user,
      onIdDemGer,
      getListDemGer,
      getInputValue,
      inputTextValue,
      permiteCalcProjecaoOrc,
      inIdTipoDemGer,
      onCodigoTotalizadorBase,
    ],
  );

  useEffect(() => {
    if (in_id !== undefined && in_id !== idDemGer) {
      setIdDemGer(in_id);
      getDemGerById(in_id);
    }
  }, [idDemGer, in_id, getDemGerById]);

  const handleOnClickOpen = useCallback(async () => {
    await getDemGer(getInputValue());
  }, [getDemGer, getInputValue]);

  const handleOnKeyUpInputSearch = useCallback(
    (e: KeyboardEvent<HTMLInputElement>) => {
      if (e.key.toUpperCase() === 'ENTER') {
        e.preventDefault();
        getDemGer(e.currentTarget.value);
      } else if (e.key.toUpperCase() === 'BACKSPACE') {
        updateListDemGer([]);
        if (onClearId) {
          onClearId();
        }
      }
    },
    [getDemGer, updateListDemGer, onClearId],
  );

  return (
    <div id={`w2${idInputLookup}87`} style={{ width: '100%' }}>
      <ContainerLookup>
        <ContainerSearch>
          <Tooltip title="Pesquisar Totalizador" placement="top">
            <ButtonSearch type="button" onClick={handleOnClickOpen}>
              <PageviewIcon />
            </ButtonSearch>
          </Tooltip>

          <input
            id={idInputLookup}
            type="text"
            placeholder={inPlaceholder && inPlaceholder.trim() !== '' ? inPlaceholder : 'Pesquisar Totalizador...'}
            onKeyDown={handleOnKeyUpInputSearch}
          />
        </ContainerSearch>
      </ContainerLookup>

      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleOnClickConfirm}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
        disableBackdropClick
      >
        <Fade in={open}>
          <div className={classes.paper}>
            <TitleSearch id="transition-modal-title">T O T A L I Z A D O R E S</TitleSearch>
            <div style={{ height: '450px', width: '700px' }}>
              <ThemeProvider theme={theme}>
                <DataGrid
                  headerHeight={40}
                  rowHeight={25}
                  rows={listDemGersRegister}
                  columns={columns}
                  checkboxSelection={multiSelect}
                  selectionModel={selectedIds}
                  getRowId={r => r.id}
                  onSelectionModelChange={e => setSelectedIds(e.selectionModel)}
                  onRowDoubleClick={handleOnClickConfirm}
                />
              </ThemeProvider>
            </div>
            <ButtonGroup>
              <ButtonDialog onClick={handleOnClickCancel} color="secondary" autoFocus>
                Cancelar
              </ButtonDialog>
              <ButtonDialog onClick={handleOnClickConfirm} color="primary">
                Confirmar
              </ButtonDialog>
            </ButtonGroup>
          </div>
        </Fade>
      </Modal>
    </div>
  );
};

export default LookupDemGer;
