import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';

import Settings from '@material-ui/icons/Settings';
import Apartment from '@material-ui/icons/Apartment';
import PersonAdd from '@material-ui/icons/PersonAdd';
import Security from '@material-ui/icons/Security';
import Computer from '@material-ui/icons/Computer';
import ViewList from '@material-ui/icons/ViewList';
import Bookmarks from '@material-ui/icons/Bookmarks';
import Description from '@material-ui/icons/Description';

import {
  ROUTE_COMPANY,
  ROUTE_USERS_LICENSE,
  ROUTE_USERS_COMPANY,
  ROUTE_KEY_ASSOCIATE,
  ROUTE_TIPO_DEM_GER_COMPANY,
  ROUTE_DEM_GER_PARAMETRO,
  ROUTE_DEM_GER_CAD_CONTA,
  ROUTE_DEM_GER_CAD_CENTRO_CUSTO,
  ROUTE_HISTORICO_IMPORTACAO,
  ROUTE_CAD_INDICADOR_PERSON,
} from '../../utils/constants';
import { useAuth } from '../../hooks/Auth';
import ButtonDropdownMenu from './ButtonDropdownMenu';
import { ContainerDropdown, DropdownContent } from './styles';
import { ActiveSystemKindOptions } from '../../utils/appInfo';

const MenuAdminUser: React.FC = () => {
  const history = useHistory();
  const { user } = useAuth();

  const isGerenciamentoContratos = (): boolean => {
    if (user.license?.tipo_licenca === '9-Gerenciamento contratos') {
      return true;
    }

    return false;
  };

  const handleGoCompany = (): void => {
    history.push(ROUTE_COMPANY);
  };

  const handleGoUsersLicense = (): void => {
    history.push(ROUTE_USERS_LICENSE);
  };

  const handleGoUsersCompany = (): void => {
    history.push(ROUTE_USERS_COMPANY);
  };

  const handleGoKeyAssociate = (): void => {
    history.push(ROUTE_KEY_ASSOCIATE);
  };

  const handleGoTipoDemGerCompany = (): void => {
    history.push(ROUTE_TIPO_DEM_GER_COMPANY);
  };

  const handleGoDemGerParametro = (): void => {
    history.push(ROUTE_DEM_GER_PARAMETRO);
  };

  const handleGoDemGerCadConta = (): void => {
    history.push(ROUTE_DEM_GER_CAD_CONTA);
  };

  const handleGoDemGerCadCCusto = (): void => {
    history.push(ROUTE_DEM_GER_CAD_CENTRO_CUSTO);
  };

  const handleGoHistoricoImportacao = (): void => {
    history.push(ROUTE_HISTORICO_IMPORTACAO);
  };

  const handleGoCadIndicadorPersonalizado = (): void => {
    history.push(ROUTE_CAD_INDICADOR_PERSON);
  };

  const enableMenuTool = useCallback((): boolean => {
    if (user.empresa) {
      if (user.empresa.active_system_kind === ActiveSystemKindOptions.Option_FinancialAccounts) {
        return true;
      }

      if (user.empresa.active_system_kind === ActiveSystemKindOptions.Option_AccountingAnalysis) {
        return true;
      }
    }
    return false;
  }, [user]);

  return (
    <ContainerDropdown>
      <button type="button">
        <Settings />
      </button>
      <DropdownContent>
        <ButtonDropdownMenu onClick={handleGoCompany} icon={Apartment}>
          <span>Empresas</span>
        </ButtonDropdownMenu>
        <ButtonDropdownMenu onClick={handleGoUsersLicense} icon={PersonAdd}>
          <span>Usuários</span>
        </ButtonDropdownMenu>
        <ButtonDropdownMenu onClick={handleGoUsersCompany} icon={Security}>
          <span>Permissões</span>
        </ButtonDropdownMenu>
        {!isGerenciamentoContratos() && (
          <>
            <ButtonDropdownMenu onClick={handleGoKeyAssociate} icon={Computer}>
              <span>Computadores</span>
            </ButtonDropdownMenu>
            <ButtonDropdownMenu onClick={handleGoTipoDemGerCompany} icon={ViewList}>
              <span>Demonstrativos</span>
            </ButtonDropdownMenu>
            <ButtonDropdownMenu onClick={handleGoDemGerParametro} icon={Bookmarks}>
              <span>Parâmetros</span>
            </ButtonDropdownMenu>
            {!enableMenuTool() && (
              <>
                <ButtonDropdownMenu onClick={handleGoHistoricoImportacao} icon={Description}>
                  <span>Arquivos SPED</span>
                </ButtonDropdownMenu>
                <ButtonDropdownMenu onClick={handleGoDemGerCadConta} icon={ViewList}>
                  <span>Contas</span>
                </ButtonDropdownMenu>
                <ButtonDropdownMenu onClick={handleGoDemGerCadCCusto} icon={ViewList}>
                  <span>Centro de Custo</span>
                </ButtonDropdownMenu>
              </>
            )}
            <ButtonDropdownMenu onClick={handleGoCadIndicadorPersonalizado} icon={ViewList}>
              <span>Indicadores</span>
            </ButtonDropdownMenu>
          </>
        )}
      </DropdownContent>
    </ContainerDropdown>
  );
};

export default MenuAdminUser;
