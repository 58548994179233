export default interface IDemGerLookup {
  id: string;
  cod_tot: string;
  classif_tot: string;
  cod_nat: string;
  nome_tot: string;
}

export const emptyDemGerLookup: IDemGerLookup = {
  id: '',
  cod_tot: '',
  classif_tot: '',
  cod_nat: '',
  nome_tot: '',
};
