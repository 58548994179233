import styled from 'styled-components';

export const Container = styled.div`
  width: 400px;

  p {
    &.ext_file {
      font-size: 10px;
      color: #006699;
      margin-bottom: 10px;
    }
  }
`;

export const TitleHead = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  align-content: space-between;
`;

export const TitleDiv = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  align-content: space-between;
  width: 85%;

  svg {
    margin-left: 20px;
    width: 32px;
    height: 32px;
  }
`;

export const DivDownload = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  align-content: space-between;

  svg {
    color: #217346;
  }
`;
