export default interface IDemGerOrcadoTot {
  acao: string;
  id: string;
  id_license: string;
  id_dem_ger: string;
  id_dem_ger_bc: string | undefined;
  cnpj_estab_0000: string;
  ano: number;
  janeiro: number;
  fevereiro: number;
  marco: number;
  abril: number;
  maio: number;
  junho: number;
  julho: number;
  agosto: number;
  setembro: number;
  outubro: number;
  novembro: number;
  dezembro: number;
  obs: string;
  tipo: string;
  tipo_valor: string;
  totalizador: string;
  totalizador_bc: string;
  cod_tot_base: string;
}

export const emptyDemGerOrcadoTotTot: IDemGerOrcadoTot = {
  acao: '',
  id: '',
  id_license: '',
  cnpj_estab_0000: '',
  ano: 0,
  janeiro: 0,
  fevereiro: 0,
  marco: 0,
  abril: 0,
  maio: 0,
  junho: 0,
  julho: 0,
  agosto: 0,
  setembro: 0,
  outubro: 0,
  novembro: 0,
  dezembro: 0,
  obs: '',
  tipo: '1-Previsto',
  tipo_valor: '0-Valor',
  id_dem_ger: '',
  id_dem_ger_bc: '',
  totalizador: '',
  totalizador_bc: '',
  cod_tot_base: '',
};
