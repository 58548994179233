import React, { useRef, useState, useEffect, useCallback } from 'react';
import * as Yup from 'yup';

import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';

import { DataGrid, GridColDef, GridCellParams, GridRowId, ptBR } from '@material-ui/data-grid';
import { createMuiTheme, ThemeProvider, makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';

import { FiCopy } from 'react-icons/fi';
import { MdDelete, MdEdit, MdClear, MdSkipNext, MdSkipPrevious, MdContentCopy } from 'react-icons/md';
import {
  AiOutlinePlus,
  AiOutlineSave,
  AiFillFastBackward,
  AiFillFastForward,
  AiOutlineCaretDown,
  AiOutlineCaretUp,
} from 'react-icons/ai';
import { GoPlus } from 'react-icons/go';
import { RiDeleteBinLine } from 'react-icons/ri';

import InputComum from '../../components/InputComum';
import Select from '../../components/Select';
import Header from '../../components/Header';
import FormModalDialogConfirm from '../../components/FormModalDialogConfirm';

import api from '../../services/api';
import { useAuth } from '../../hooks/Auth';
import { useToast } from '../../hooks/Toast';

import getListUpdate from '../../utils/getListUpdate';
import getValidationErrors from '../../utils/getValidationErrors';
import { getPathGrid } from '../../utils/storagePath';

import {
  ColumnDemonstrativo,
  ColumnOrigem,
  ColumnTipo,
  ColumnDescricao,
  ColumnObservacao,
  ColumnInf1,
  ColumnInf2,
  ColumnInf3,
  ColumnInf4,
  ColumnInf5,
  ColumnId,
} from './GridColumns';
import ITipoDemGer from './ITipoDemGer';
import IDemGerParametro from './IDemGerParametro';
import {
  Container,
  ContainerFields,
  Tab,
  TabButton,
  TabContent,
  FormFields,
  FormContainer,
  FormNavigate,
  TabTitulo,
  TabButtonArea,
  TabButtonNovo,
  TabButtonDelete,
  TabButtonGridRowHeight,
} from './styles';

const useStylesLoading = makeStyles((theme: Theme) =>
  createStyles({
    backdrop: {
      zIndex: theme.zIndex.drawer + 2000,
      color: '#fff',
    },
  }),
);

const theme = createMuiTheme(
  {
    palette: {
      primary: { main: '#006699' },
    },
  },
  ptBR,
);

const emptyDemGerParametro: IDemGerParametro = {
  acao: '',
  id: '',
  id_license: '',
  id_tipo_dem_ger: '',
  demonstrativo: '',
  cnpj_estab_0000: '',
  descricao: '',
  tp: 0,
  origem: 0,
  obs: '',
  inf1: '',
  inf2: '',
  inf3: '',
  inf4: '',
  inf5: '',
};

const idfieldSelect = '1652NKJBWFERNJKFWE3_';
const titulo = 'P A R Â M E T R O S';

const DemGerParametros: React.FC = () => {
  const { user } = useAuth();
  const { addToast } = useToast();
  const formRef = useRef<FormHandles>(null);
  const [listTipoDemGer, setListTipoDemGer] = useState<ITipoDemGer[]>([]);
  const [demGerParametro, setDemGerParametro] = useState<IDemGerParametro>();
  const [listDemGerParametro, setListDemGerParametro] = useState<IDemGerParametro[]>([]);
  const [hiddenPesquisar, setHiddenPesquisar] = useState<boolean>(false);
  const [hiddenCadastrar, setHiddenCadastrar] = useState<boolean>(true);
  const [demGerParametroIndex, setDemGerParametroIndex] = useState<number>(0);
  const [rowToEdit, setRowToEdit] = useState(null);
  const [idDeleteDemGerParametro, setIdDeleteDemGerParametro] = useState<string>('');
  const [selectedIds, setSelectedIds] = useState<GridRowId[]>([]);
  const [openDeleteSingle, setOpenDeleteSingle] = useState(false);
  const [openDeleteMultiple, setOpenDeleteMultiple] = useState(false);
  const [openLoading, setOpenLoading] = useState(false);
  const cssLoading = useStylesLoading();
  const [gridRowHeight, setGridRowHeight] = useState<number>(46);

  const handleGridRowHeightUp = useCallback(async () => {
    const heightValue: number = gridRowHeight + 1;
    setGridRowHeight(heightValue);
    localStorage.setItem(getPathGrid(), heightValue.toString());
  }, [gridRowHeight]);

  const handleGridRowHeightDown = useCallback(async () => {
    const heightValue: number = gridRowHeight - 1;
    setGridRowHeight(heightValue);
    localStorage.setItem(getPathGrid(), heightValue.toString());
  }, [gridRowHeight]);

  const licencaInvalida = useCallback((): boolean => {
    if (!user.license || user.license.id === '') {
      addToast({
        type: 'error',
        title: 'Licença inválida',
        description: 'Usuário não está vinculado a nenhuma licença',
      });

      return true;
    }

    return false;
  }, [addToast, user]);

  const defineDemGerParametro = useCallback(async (data: IDemGerParametro) => {
    setDemGerParametro(data);
    formRef.current?.setData(data);
  }, []);

  const onNewRegister = useCallback(async () => {
    defineDemGerParametro(emptyDemGerParametro);
  }, [defineDemGerParametro]);

  const OnGridSetRegisterToEdit = useCallback(
    async (params: GridCellParams) => {
      const localDemGerParametro = params.row as IDemGerParametro;

      setDemGerParametroIndex(listDemGerParametro.indexOf(localDemGerParametro));
      setHiddenCadastrar(false);
      setHiddenPesquisar(true);
      setRowToEdit(null);
      defineDemGerParametro(localDemGerParametro);
    },
    [listDemGerParametro, defineDemGerParametro],
  );

  const OnSetRegisterToAdd = useCallback(async () => {
    onNewRegister();
    setHiddenCadastrar(false);
    setHiddenPesquisar(true);
  }, [onNewRegister]);

  const OnGridRowDuplicate = useCallback(
    async (baseDemGerParametro: IDemGerParametro | undefined) => {
      if (baseDemGerParametro !== undefined) {
        const newDemGerParametro: IDemGerParametro = {
          acao: baseDemGerParametro.acao,
          id: '',
          id_tipo_dem_ger: baseDemGerParametro.id_tipo_dem_ger,
          id_license: baseDemGerParametro.id_license,
          cnpj_estab_0000: user.empresa.cnpj,
          origem: baseDemGerParametro.origem,
          tp: baseDemGerParametro.tp,
          descricao: baseDemGerParametro.descricao,
          obs: baseDemGerParametro.obs,
          inf1: baseDemGerParametro.inf1,
          inf2: baseDemGerParametro.inf2,
          inf3: baseDemGerParametro.inf3,
          inf4: baseDemGerParametro.inf4,
          inf5: baseDemGerParametro.inf5,
          demonstrativo: baseDemGerParametro.demonstrativo,
        };

        defineDemGerParametro(newDemGerParametro);
        setHiddenCadastrar(false);
        setHiddenPesquisar(true);
      }
    },
    [defineDemGerParametro, user],
  );

  const OnConfirmationDeleteSingle = useCallback(
    async (id: string) => {
      if (licencaInvalida()) return;

      if (id === '') return;

      setIdDeleteDemGerParametro(id);
      setOpenDeleteSingle(true);
    },
    [licencaInvalida],
  );

  const colunaAcao: GridColDef = {
    field: 'acao',
    headerName: 'Ação',
    editable: false,
    filterable: false,
    hideSortIcons: true,
    sortable: false,
    disableColumnMenu: true,
    width: 160,
    renderCell: (params: GridCellParams) => (
      <strong>
        <Tooltip title="Editar Registro" placement="top">
          <IconButton color="primary" aria-label="Editar" onClick={() => OnGridSetRegisterToEdit(params)}>
            <MdEdit />
          </IconButton>
        </Tooltip>

        <Tooltip title="Duplicar Registro" placement="top">
          <IconButton
            color="primary"
            aria-label="Duplicar"
            onClick={() => OnGridRowDuplicate(params.row as IDemGerParametro)}
          >
            <FiCopy />
          </IconButton>
        </Tooltip>

        <Tooltip title="Excluir Registro" placement="top">
          <IconButton
            color="primary"
            aria-label="Excluir"
            onClick={() => OnConfirmationDeleteSingle((params.row as IDemGerParametro).id)}
          >
            <MdDelete />
          </IconButton>
        </Tooltip>
      </strong>
    ),
  };

  const columns: GridColDef[] = [
    colunaAcao,
    ColumnDemonstrativo,
    ColumnOrigem,
    ColumnTipo,
    ColumnDescricao,
    ColumnObservacao,
    ColumnInf1,
    ColumnInf2,
    ColumnInf3,
    ColumnInf4,
    ColumnInf5,
    ColumnId,
  ];

  const getTipoDemGer = useCallback(async () => {
    if (licencaInvalida()) return;

    const response = await api.get<ITipoDemGer[]>(`/tipodemger/all`);

    setListTipoDemGer(response.data);
  }, [licencaInvalida]);

  const getDemGerParametro = useCallback(async () => {
    if (licencaInvalida()) return;

    const response = await api.get<IDemGerParametro[]>(`/demgerparametro/allparam/${user.license.id}`);

    setListDemGerParametro(response.data);

    if (rowToEdit !== null) {
      const findEditDemGerParametro = response.data.find(item => item.id === rowToEdit);

      if (findEditDemGerParametro) {
        defineDemGerParametro(findEditDemGerParametro);
        setDemGerParametroIndex(response.data.indexOf(findEditDemGerParametro));
        setHiddenCadastrar(false);
        setHiddenPesquisar(true);
        setRowToEdit(null);
      }
    }
  }, [user, licencaInvalida, rowToEdit, defineDemGerParametro]);

  const getValues = useCallback(async () => {
    setOpenLoading(true);

    await getTipoDemGer();
    await getDemGerParametro();

    setOpenLoading(false);
  }, [getTipoDemGer, getDemGerParametro]);

  useEffect(() => {
    getValues();

    const rowHeight = localStorage.getItem(getPathGrid());
    if (rowHeight) {
      setGridRowHeight(parseInt(rowHeight, 10));
    }
  }, [getValues]);

  const getDescricaoTipoDemGerSelectOption = useCallback((value: ITipoDemGer): string => {
    if (!value) return '';

    return value.descricao;
  }, []);

  const getTipoDemGerSelectOption = useCallback(
    (id: string): ITipoDemGer => {
      return listTipoDemGer.filter(item => item.id === id)[0];
    },
    [listTipoDemGer],
  );

  const handleOnRowDeleteSingle = useCallback(async () => {
    setOpenLoading(true);
    await api.delete(`/demgerparametro/${idDeleteDemGerParametro}`);

    setListDemGerParametro(OldValue => OldValue.filter(item => item.id !== idDeleteDemGerParametro));

    defineDemGerParametro(emptyDemGerParametro);

    addToast({
      type: 'success',
      title: 'Removido',
      description: 'Registro excluído com sucesso!',
    });

    setOpenDeleteSingle(false);
    setOpenLoading(false);
  }, [idDeleteDemGerParametro, addToast, defineDemGerParametro]);

  const handleOnRowDeleteMultiple = useCallback(async () => {
    setOpenLoading(true);
    if (selectedIds.length > 0) {
      await Promise.all(
        selectedIds.map(itemId => {
          setListDemGerParametro(OldValue => OldValue.filter(item => item.id !== itemId));

          return api.delete(`/demgerparametro/${itemId}`);
        }),
      );

      defineDemGerParametro(emptyDemGerParametro);

      addToast({
        type: 'success',
        title: 'Removido',
        description: 'Registro excluído com sucesso!',
      });
    }

    setSelectedIds([]);
    setOpenDeleteMultiple(false);
    setOpenLoading(false);
  }, [addToast, defineDemGerParametro, selectedIds]);

  const handleClick = (id: string): void => {
    setHiddenCadastrar(id === 'pesquisar');
    setHiddenPesquisar(id === 'cadastrar');
  };

  const onNextRegister = useCallback(async () => {
    if (demGerParametroIndex !== undefined) {
      if (demGerParametroIndex < listDemGerParametro.length - 1) {
        const localIndex = demGerParametroIndex + 1;
        setDemGerParametroIndex(localIndex);
        const findDemGerParametro = listDemGerParametro[localIndex];
        if (findDemGerParametro) {
          defineDemGerParametro(findDemGerParametro);
        }
      }
    }
  }, [demGerParametroIndex, listDemGerParametro, defineDemGerParametro]);

  const onFirstRegister = useCallback(async () => {
    if (listDemGerParametro.length > 0) {
      setDemGerParametroIndex(0);
      const findDemGerParametro = listDemGerParametro[0];
      if (findDemGerParametro) {
        defineDemGerParametro(findDemGerParametro);
      }
    }
  }, [listDemGerParametro, defineDemGerParametro]);

  const onLastRegister = useCallback(async () => {
    if (listDemGerParametro.length > 0) {
      setDemGerParametroIndex(listDemGerParametro.length - 1);
      const findDemGerParametro = listDemGerParametro[listDemGerParametro.length - 1];
      if (findDemGerParametro) {
        defineDemGerParametro(findDemGerParametro);
      }
    }
  }, [listDemGerParametro, defineDemGerParametro]);

  const onPriorRegister = useCallback(async () => {
    if (demGerParametroIndex !== undefined) {
      if (demGerParametroIndex > 0) {
        const localIndex = demGerParametroIndex - 1;
        setDemGerParametroIndex(localIndex);
        const findDemGerParametro = listDemGerParametro[localIndex];
        if (findDemGerParametro) {
          defineDemGerParametro(findDemGerParametro);
        }
      }
    }
  }, [demGerParametroIndex, listDemGerParametro, defineDemGerParametro]);

  const onCancelRegister = useCallback(async () => {
    formRef.current?.setErrors({});

    if (demGerParametroIndex !== undefined) {
      const findDemGerParametro = listDemGerParametro[demGerParametroIndex];
      if (findDemGerParametro) {
        defineDemGerParametro(findDemGerParametro);
      }
    }

    onNewRegister();
  }, [demGerParametroIndex, listDemGerParametro, onNewRegister, defineDemGerParametro]);

  const handleSubmit = useCallback(
    async (data: IDemGerParametro, { reset }) => {
      if (licencaInvalida()) return;

      const fieldSelect = document.getElementById(idfieldSelect);
      if (fieldSelect) {
        const id = (fieldSelect as HTMLSelectElement).value;
        data.id_tipo_dem_ger = id;
        data.demonstrativo = getDescricaoTipoDemGerSelectOption(getTipoDemGerSelectOption(id));
      }

      formRef.current?.setErrors({});
      try {
        const schema = Yup.object().shape({
          id_tipo_dem_ger: Yup.string().required('Demonstrativo é obrigatório'),
          descricao: Yup.string().required('Descrição é obrigatória'),
          origem: Yup.number().required('Origem é obrigatória'),
          tp: Yup.number().required('Tipo é obrigatório'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        setOpenLoading(true);
        if (data.id === '') {
          const formData = {
            id_license: user.license.id,
            cnpj_estab_0000: user.empresa.cnpj,
            id_tipo_dem_ger: data.id_tipo_dem_ger,
            origem: data.origem,
            tp: data.tp,
            descricao: data.descricao,
            obs: data.obs,
            inf1: data.inf1,
            inf2: data.inf2,
            inf3: data.inf3,
            inf4: data.inf4,
            inf5: data.inf5,
          };

          await api.post(`/demgerparametro`, formData);
          setListDemGerParametro(oldLicenses => [...oldLicenses, data]);

          addToast({
            type: 'success',
            title: 'Adicionado',
            description: 'Registro adicionado com sucesso!',
          });
        } else {
          await api.put(`/demgerparametro/${data.id}`, data);
          data.demonstrativo = getDescricaoTipoDemGerSelectOption(getTipoDemGerSelectOption(data.id_tipo_dem_ger));
          setListDemGerParametro(getListUpdate(listDemGerParametro, data));

          addToast({
            type: 'success',
            title: 'Alterado',
            description: 'Registro alterado com sucesso!',
          });
        }

        reset();
        setOpenLoading(false);
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          formRef.current?.setErrors(errors);
        }

        setOpenLoading(false);
      }
    },
    [
      licencaInvalida,
      listDemGerParametro,
      user,
      addToast,

      getDescricaoTipoDemGerSelectOption,
      getTipoDemGerSelectOption,
    ],
  );

  return (
    <Container>
      <Header />

      <Tab>
        <TabButtonArea>
          <TabButton id="pesquisar" isActive={!hiddenPesquisar} onClick={() => handleClick('pesquisar')}>
            Listagem
          </TabButton>
          <TabButton id="cadastrar" isActive={!hiddenCadastrar} onClick={() => handleClick('cadastrar')}>
            Cadastro
          </TabButton>
          <Tooltip title="Reduzir o tamanho da linha" placement="top">
            <TabButtonGridRowHeight id="gridrowheightdown" onClick={() => handleGridRowHeightDown()}>
              <AiOutlineCaretDown />
            </TabButtonGridRowHeight>
          </Tooltip>

          <Tooltip title="Aumentar o tamanho da linha" placement="top">
            <TabButtonGridRowHeight id="gridrowheightup" onClick={() => handleGridRowHeightUp()}>
              <AiOutlineCaretUp />
            </TabButtonGridRowHeight>
          </Tooltip>

          <Tooltip title="Novo Registro" placement="top">
            <TabButtonNovo id="new_register" onClick={() => OnSetRegisterToAdd()}>
              <GoPlus />
              Novo
            </TabButtonNovo>
          </Tooltip>

          <Tooltip title="Remover Registros Selecionados" placement="top">
            <span>
              <span hidden={selectedIds.length <= 0}>
                <TabButtonDelete
                  id="remove_all"
                  onClick={() => {
                    setIdDeleteDemGerParametro('');
                    setOpenDeleteMultiple(true);
                  }}
                >
                  <RiDeleteBinLine />
                  Remover
                </TabButtonDelete>
              </span>
            </span>
          </Tooltip>
        </TabButtonArea>

        <TabTitulo>
          <span>{titulo}</span>
        </TabTitulo>
      </Tab>

      <TabContent id="pesquisar" isActive={!hiddenPesquisar}>
        <div style={{ height: '85vh', width: '100%' }}>
          <ThemeProvider theme={theme}>
            <DataGrid
              rows={listDemGerParametro}
              columns={columns}
              rowHeight={gridRowHeight}
              checkboxSelection
              selectionModel={selectedIds}
              disableSelectionOnClick
              onSelectionModelChange={e => setSelectedIds(e.selectionModel)}
            />
          </ThemeProvider>
        </div>
      </TabContent>

      <TabContent id="cadasto" isActive={!hiddenCadastrar}>
        <Form ref={formRef} onSubmit={handleSubmit} initialData={demGerParametro}>
          <FormContainer>
            <FormNavigate>
              <Tooltip title="Novo Registro" placement="top">
                <IconButton color="primary" aria-label="adicionar" onClick={() => onNewRegister()}>
                  <AiOutlinePlus />
                </IconButton>
              </Tooltip>

              <Tooltip title="Duplicar Registro" placement="top">
                <IconButton color="primary" aria-label="Duplicar" onClick={() => OnGridRowDuplicate(demGerParametro)}>
                  <MdContentCopy />
                </IconButton>
              </Tooltip>

              <Tooltip title="Excluir Registro" placement="top">
                <IconButton
                  color="primary"
                  aria-label="Excluir"
                  onClick={() => OnConfirmationDeleteSingle(demGerParametro ? demGerParametro.id : '')}
                >
                  <RiDeleteBinLine />
                </IconButton>
              </Tooltip>

              <Tooltip title="Cancelar" placement="top">
                <IconButton type="reset" color="primary" aria-label="Cancelar" onClick={() => onCancelRegister()}>
                  <MdClear />
                </IconButton>
              </Tooltip>

              <Tooltip title="Salvar" placement="top">
                <IconButton type="submit" color="primary" aria-label="Confirmar">
                  <AiOutlineSave />
                </IconButton>
              </Tooltip>

              <Tooltip title="Primeiro Registro" placement="top">
                <IconButton color="primary" aria-label="Primeiro" onClick={() => onFirstRegister()}>
                  <AiFillFastBackward />
                </IconButton>
              </Tooltip>

              <Tooltip title="Registro Anterior" placement="top">
                <IconButton color="primary" aria-label="Anterior" onClick={() => onPriorRegister()}>
                  <MdSkipPrevious />
                </IconButton>
              </Tooltip>

              <Tooltip title="Próximo Registro" placement="top">
                <IconButton color="primary" aria-label="Próximo" onClick={() => onNextRegister()}>
                  <MdSkipNext />
                </IconButton>
              </Tooltip>

              <Tooltip title="Último Registro" placement="top">
                <IconButton color="primary" aria-label="Último registro" onClick={() => onLastRegister()}>
                  <AiFillFastForward />
                </IconButton>
              </Tooltip>
            </FormNavigate>

            <FormFields>
              <ContainerFields field="T300px">
                <span>Origem:</span>
                <InputComum autoFocus name="origem" placeholder="0" type="number" step="1" />
              </ContainerFields>
              <ContainerFields field="T300px">
                <span>Tipo:</span>
                <InputComum autoFocus name="tp" placeholder="0" type="number" step="1" />
              </ContainerFields>
              <ContainerFields field="T100">
                <span>Demonstrativo:</span>
                <Select id={idfieldSelect} name="id_tipo_dem_ger">
                  {listTipoDemGer.map(option => (
                    <option key={option.id} value={option.id}>
                      {option.codigo.trim()}-{option.descricao.trim()}
                    </option>
                  ))}
                </Select>
              </ContainerFields>
              <ContainerFields field="T100">
                <span>Descrição:</span>
                <InputComum autoFocus name="descricao" />
              </ContainerFields>
              <ContainerFields field="T100">
                <span>Observação:</span>
                <InputComum autoFocus name="obs" />
              </ContainerFields>
              <ContainerFields field="T100">
                <span>Informação 1:</span>
                <InputComum autoFocus name="inf1" />
              </ContainerFields>
              <ContainerFields field="T100">
                <span>Informação 2:</span>
                <InputComum autoFocus name="inf2" />
              </ContainerFields>
              <ContainerFields field="T100">
                <span>Informação 3:</span>
                <InputComum autoFocus name="inf3" />
              </ContainerFields>
              <ContainerFields field="T100">
                <span>Informação 4:</span>
                <InputComum autoFocus name="inf4" />
              </ContainerFields>
              <ContainerFields field="T100">
                <span>Informação 5:</span>
                <InputComum autoFocus name="inf5" className="lastField" />
              </ContainerFields>
              <ContainerFields field="id">
                <InputComum name="id" hidden />
              </ContainerFields>
            </FormFields>
          </FormContainer>
        </Form>
      </TabContent>

      <FormModalDialogConfirm
        open={openDeleteSingle}
        onClose={() => setOpenDeleteSingle(false)}
        onConfirm={() => handleOnRowDeleteSingle()}
        buttonCancelText="Cancelar"
        buttonConfirmText="Sim"
        formTitle="Excluir?"
        messageConfirm="Tem certeza que deseja excluir?"
      />

      <FormModalDialogConfirm
        open={openDeleteMultiple}
        onClose={() => setOpenDeleteMultiple(false)}
        onConfirm={() => handleOnRowDeleteMultiple()}
        buttonCancelText="Cancelar"
        buttonConfirmText="Sim"
        formTitle="Excluir?"
        messageConfirm="Tem certeza que deseja excluir todos os registros selecionados?"
      />

      <Backdrop className={cssLoading.backdrop} open={openLoading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </Container>
  );
};

export default DemGerParametros;
