export const ROUTE_COMPANY = '/company';
export const ROUTE_DASHBOARD = '/dashboard';
export const ROUTE_DASHBOARD_POWERBI = '/dashboardpowerbi';
export const ROUTE_KEY_ASSOCIATE = '/key-associate';
export const ROUTE_TIPO_DEM_GER = '/tipo-dem-ger';
export const ROUTE_TIPO_DEM_GER_COMPANY = '/tipo-dem-ger-company';
export const ROUTE_HOME = '/home';
export const ROUTE_INFO = '/info';
export const ROUTE_USER_REQUEST_ACESS = '/user-request-acess';
export const ROUTE_USERS_LICENSE = '/users-license';
export const ROUTE_USERS_COMPANY = '/users-company';
export const ROUTE_DEM_GER = '/dem-ger';
export const ROUTE_DEM_GER_PARAMETRO = '/dem-ger-parametro';
export const ROUTE_DEM_GER_CAD_CONTA = '/dem-ger-cadconta';
export const ROUTE_DEM_GER_CAD_CONTA_CORRENTE = '/cadcontacorrente';
export const ROUTE_DEM_GER_CAD_CENTRO_CUSTO = '/dem-ger-cadccusto';
export const ROUTE_DEM_GER_RELAC = '/dem-ger-relac';
export const ROUTE_DEM_GER_LCTOS = '/dem-ger-lctos';
export const ROUTE_DEM_GER_LCTOS_COMENTARIOS = '/dem-ger-lctos-comentarios';
export const ROUTE_DEM_GER_ORCADO = '/dem-ger-orcado';
export const ROUTE_DEM_GER_ORCADO_TOT = '/dem-ger-orcado-tot';
export const ROUTE_DEM_GER_FOLHA = '/dem-ger-folha';
export const ROUTE_PROFILE = '/profile';
export const ROUTE_COMPANY_CHANGE = '/companychange';
export const ROUTE_SIGN_IN = '/signin';
export const ROUTE_SIGN_UP = '/signup';
export const ROUTE_MANUTENCAO = '/manutencao';
export const ROUTE_FORGOT_PASSWORD = '/forgot-password';
export const ROUTE_RESET_PASSWORD = '/reset-password';
export const ROUTE_LICENSE = '/license';
export const ROUTE_RECEITAS_DESPESAS = '/finreceitasdespesas';
export const ROUTE_CAD_HIST_PADRAO = '/cadhistopadrao';
export const ROUTE_HISTORICO_IMPORTACAO = '/historicoimportacao';
export const ROUTE_CAD_INDICADOR_PERSON = '/cadindicperson';
export const ROUTE_INDICADOR_PERSON_LCTO = '/indicpersonlcto';
export const ROUTE_CFG_PERIODO_CONTABIL = '/cfgperiodocontabil';
