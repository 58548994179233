import React, { useCallback, useState, useEffect } from 'react';
import { uuid } from 'uuidv4';
import filesize from 'filesize';
import { DropEvent } from 'react-dropzone';

import Dialog from '@material-ui/core/Dialog';
import ButtonDialog from '@material-ui/core/Button';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';

import { FaFileDownload } from 'react-icons/fa';

import api from '../../services/api';
import { useToast } from '../../hooks/Toast';

import UploadFile from '../UploadFile';
import FileList from '../FileList';
import IFile from '../FileList/IFile';

import { Container, TitleHead, TitleDiv, DivDownload } from './styles';

type functionVoid = () => void;

interface IModalUploadFileProps {
  open: boolean;
  onClose: functionVoid;
  apiSource: string;
  apiSourceFileExample: string;
}

const ModalUploadFile: React.FC<IModalUploadFileProps> = ({ apiSource, apiSourceFileExample, onClose, open }) => {
  const { addToast } = useToast();
  const [uploadedFiles, setUploadedFiles] = useState<IFile[]>([]);
  const [uploadedFilesError, setUploadedFilesError] = useState<IFile[]>([]);
  const [uploadedFilesFinished, setUploadedFilesFinished] = useState<IFile[]>([]);

  const [urlDownload, setURLDownload] = useState('');
  const [download, setDownload] = useState('');
  const [count, setCount] = useState(0);

  const handleClose = useCallback(() => {
    setUploadedFiles([]);
    setDownload('');
    if (onClose) {
      onClose();
    }
  }, [onClose]);

  const processUpload = useCallback(
    async uploadedFile => {
      const data = new FormData();

      data.append('file', uploadedFile.file, uploadedFile.name);

      const item: IFile = {
        uploaded: false,
        id: uploadedFile.id,
        name: uploadedFile.name,
        readableSize: uploadedFile.readableSize,
        isUploading: true,
        error: false,
      };

      api
        .post(apiSource, data)
        .then(response => {
          item.uploaded = true;
          item.isUploading = false;

          setUploadedFilesFinished([...uploadedFilesFinished, item]);

          addToast({
            type: 'success',
            title: 'Carregado',
            description: `Arquivo ${uploadedFile.name} carregado com sucesso`,
          });
        })

        .catch(() => {
          item.uploaded = false;
          item.error = true;
          item.isUploading = false;

          setUploadedFilesError([...uploadedFilesError, item]);

          addToast({
            type: 'error',
            title: 'Falhou',
            description: `Ocorreu uma falha ao carregar o arquivo ${uploadedFile.name}. Por favor verifique a estrutura do arquivo.`,
          });
        });
    },
    [addToast, apiSource, uploadedFilesFinished, uploadedFilesError],
  );

  const handleUpload = useCallback(async () => {
    setDownload('');
    const newList = await Promise.all(
      uploadedFiles.map(item => ({
        ...item,
        error: false,
        isUploading: !item.uploaded,
      })),
    );
    setUploadedFiles(newList);

    const listUpload = newList.filter(item => item.isUploading);

    listUpload.forEach(processUpload);
  }, [uploadedFiles, processUpload]);

  const handleSelectFiles = useCallback(
    <T extends File>(files: T[], event: DropEvent): void => {
      const uploadedList = files.map(file => {
        const newFile: IFile = {
          file,
          id: uuid(),
          name: file.name,
          readableSize: filesize(file.size),
          isUploading: false,
          uploaded: false,
          error: false,
        };

        return newFile;
      });

      const newList = uploadedList.concat(uploadedFiles);

      setUploadedFiles(newList);
    },
    [uploadedFiles],
  );

  useEffect(() => {
    api.get(apiSourceFileExample).then(resp => {
      setURLDownload(resp.data.file);
    });

    uploadedFilesFinished.map(item => {
      const findItem = uploadedFiles.find(it => it.id === item.id);
      if (findItem) {
        findItem.isUploading = false;
        findItem.uploaded = true;
      }

      return findItem;
    });

    uploadedFilesError.map(item => {
      const findItem = uploadedFiles.find(it => it.id === item.id);
      if (findItem) {
        findItem.isUploading = false;
        findItem.error = true;
      }

      return findItem;
    });
  }, [uploadedFiles, uploadedFilesError, uploadedFilesFinished, apiSourceFileExample]);

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <TitleHead>
        <TitleDiv>
          <svg role="img" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <title>Microsoft Excel</title>
            <path
              fill="#217346"
              d="M23 1.5q.41 0 .7.3.3.29.3.7v19q0 .41-.3.7-.29.3-.7.3H7q-.41 0-.7-.3-.3-.29-.3-.7V18H1q-.41 0-.7-.3-.3-.29-.3-.7V7q0-.41.3-.7Q.58 6 1 6h5V2.5q0-.41.3-.7.29-.3.7-.3zM6 13.28l1.42 2.66h2.14l-2.38-3.87 2.34-3.8H7.46l-1.3 2.4-.05.08-.04.09-.64-1.28-.66-1.29H2.59l2.27 3.82-2.48 3.85h2.16zM14.25 21v-3H7.5v3zm0-4.5v-3.75H12v3.75zm0-5.25V7.5H12v3.75zm0-5.25V3H7.5v3zm8.25 15v-3h-6.75v3zm0-4.5v-3.75h-6.75v3.75zm0-5.25V7.5h-6.75v3.75zm0-5.25V3h-6.75v3Z"
            />
          </svg>

          <DialogTitle id="alert-dialog-title">Carregar arquivos</DialogTitle>
        </TitleDiv>

        <DivDownload>
          <Tooltip title="Baixar arquivo modelo" placement="top">
            <IconButton
              color="primary"
              aria-label="download"
              onClick={() => {
                setDownload(urlDownload);
                setCount(old => old + 1);
              }}
            >
              <FaFileDownload />
            </IconButton>
          </Tooltip>
        </DivDownload>
      </TitleHead>

      <DialogContent>
        <Container>
          <p className="ext_file">Arquivos válidos: xls, xlt, xlsx, xltx, ods, ots</p>

          <UploadFile onUpload={handleSelectFiles} />
          {!!uploadedFiles.length && <FileList files={uploadedFiles} />}
        </Container>

        {download && <iframe title="Example file" src={`${download}?c=${count}`} style={{ display: 'none' }} />}
      </DialogContent>
      <DialogActions>
        <ButtonDialog onClick={handleClose} color="secondary" autoFocus variant="contained">
          Cancelar
        </ButtonDialog>
        <ButtonDialog onClick={handleUpload} color="primary" variant="contained">
          Enviar
        </ButtonDialog>
      </DialogActions>
    </Dialog>
  );
};

export default ModalUploadFile;
