export default interface IDemGerLctosComentarios {
  acao: string;
  id: string;
  id_license: string;
  id_tipo_dem_ger: string;
  id_company: string;
  data: string;
  cod_cta_i050: string;
  cod_ccusto_i100?: string;
  valor1?: number;
  valor2?: number;
  valor3?: number;
  valor4?: number;
  valor5?: number;
  comentario1?: string;
  comentario2?: string;
  comentario3?: string;
  comentario4?: string;
  comentario5?: string;
  demonstrativo?: string;
}

export const emptyDemGerLctosComentarios: IDemGerLctosComentarios = {
  acao: '',
  id: '',
  id_tipo_dem_ger: '',
  id_company: '',
  id_license: '',
  data: '',
  cod_cta_i050: '',
  cod_ccusto_i100: '',
  comentario1: '',
  comentario2: '',
  comentario3: '',
  comentario4: '',
  comentario5: '',
  valor1: 0,
  valor2: 0,
  valor3: 0,
  valor4: 0,
  valor5: 0,
  demonstrativo: '',
};
