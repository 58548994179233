import { GridColDef } from '@material-ui/data-grid';

export const ColumnDemonstrativo: GridColDef = {
  headerName: 'Demonstrativo',
  field: 'demonstrativo',
  width: 300,
};

export const ColumnConta: GridColDef = {
  field: 'cod_cta_i050',
  headerName: 'Conta',
  width: 160,
};

export const ColumnCentroCusto: GridColDef = {
  headerName: 'Centro Custo',
  field: 'cod_ccusto_i100',
  width: 200,
};

export const ColumnValorAjuste: GridColDef = {
  headerName: 'Valor Ajuste',
  field: 'valor_ajuste',
  width: 200,
  type: 'number',
};

export const ColumnValorOrcado: GridColDef = {
  headerName: 'Valor Orçado',
  field: 'valor_orcado',
  width: 200,
  type: 'number',
};

export const ColumnHistorico: GridColDef = {
  headerName: 'Historico',
  field: 'historico',
  width: 500,
};

export const ColumnTipoAjuste: GridColDef = {
  headerName: 'Tipo Ajuste',
  field: 'tp_ajuste',
  width: 200,
};

export const ColumnId: GridColDef = {
  headerName: '#',
  field: 'id',
  hide: true,
  width: 400,
  editable: false,
  filterable: false,
  hideSortIcons: true,
  sortable: false,
  disableColumnMenu: true,
};
