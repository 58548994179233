export default interface ICadIndicadorPersonalizado {
  kind_interface: 'cad_indic_person';
  id_license: string;
  id_cad_indic_person: string;
  sistema: number;
  grupo_indicador: string;
  cod_indicador: string;
  cod_indicador_sup: string;
  tipo_indicador: string;
  desc_indicador: string;
  nome_gestor: string;
  nome_auditor: string;
  obs: string;
  informacao01: string;
  informacao02: string;
  informacao03: string;
  informacao04: string;
  informacao05: string;
  created_user: string;
  acao: string;
  accountinganalysis: boolean;
  financialaccounts: boolean;
  costanalysis: boolean;
  humanresources: boolean;
  upload_ftp: number;
}

export const emptyCadIndicadorPersonalizado: ICadIndicadorPersonalizado = {
  acao: '',
  id_license: '',
  id_cad_indic_person: '',
  sistema: 0,
  grupo_indicador: '',
  cod_indicador: '',
  cod_indicador_sup: '',
  tipo_indicador: '1-Valor (R$)',
  desc_indicador: '',
  nome_gestor: '',
  nome_auditor: '',
  obs: '',
  informacao01: '',
  informacao02: '',
  informacao03: '',
  informacao04: '',
  informacao05: '',
  created_user: '',
  kind_interface: 'cad_indic_person',
  accountinganalysis: false,
  financialaccounts: false,
  costanalysis: false,
  humanresources: false,
  upload_ftp: 0,
};
