import React from 'react';

import { GridColDef, GridCellParams } from '@material-ui/data-grid';

export const ColumnCodigoIndicador: GridColDef = {
  field: 'cod_indicador',
  headerName: 'Código indicador',
  width: 200,
};

export const ColumnIndicador: GridColDef = {
  field: 'desc_indicador',
  headerName: 'Indicador',
  width: 300,
};

export const ColumnAno: GridColDef = {
  field: 'ano',
  headerName: 'Ano',
  width: 120,
  type: 'number',
};

export const ColumnTipoApontamento: GridColDef = {
  field: 'tipo_apontamento',
  headerName: 'Tipo',
  width: 200,
};

export const ColumnVlrJaneiro: GridColDef = {
  field: 'vlr_janeiro',
  headerName: 'Janeiro',
  width: 150,
  type: 'number',
  renderCell: (params: GridCellParams) => {
    if (!params.value) {
      return <span>0.0000</span>;
    }

    return <span>{parseFloat(params.value.toString()).toFixed(4)}</span>;
  },
};

export const ColumnVlrFevereiro: GridColDef = {
  field: 'vlr_fevereiro',
  headerName: 'Fevereiro',
  width: 150,
  type: 'number',
  renderCell: (params: GridCellParams) => {
    if (!params.value) {
      return <span>0.0000</span>;
    }

    return <span>{parseFloat(params.value.toString()).toFixed(4)}</span>;
  },
};

export const ColumnVlrMarco: GridColDef = {
  field: 'vlr_marco',
  headerName: 'Março',
  width: 150,
  type: 'number',
  renderCell: (params: GridCellParams) => {
    if (!params.value) {
      return <span>0.0000</span>;
    }

    return <span>{parseFloat(params.value.toString()).toFixed(4)}</span>;
  },
};

export const ColumnVlrAbril: GridColDef = {
  field: 'vlr_abril',
  headerName: 'Abril',
  width: 150,
  type: 'number',
  renderCell: (params: GridCellParams) => {
    if (!params.value) {
      return <span>0.0000</span>;
    }

    return <span>{parseFloat(params.value.toString()).toFixed(4)}</span>;
  },
};

export const ColumnVlrMaio: GridColDef = {
  field: 'vlr_maio',
  headerName: 'Maio',
  width: 150,
  type: 'number',
  renderCell: (params: GridCellParams) => {
    if (!params.value) {
      return <span>0.0000</span>;
    }

    return <span>{parseFloat(params.value.toString()).toFixed(4)}</span>;
  },
};

export const ColumnVlrJunho: GridColDef = {
  field: 'vlr_junho',
  headerName: 'Junho',
  width: 150,
  type: 'number',
  renderCell: (params: GridCellParams) => {
    if (!params.value) {
      return <span>0.0000</span>;
    }

    return <span>{parseFloat(params.value.toString()).toFixed(4)}</span>;
  },
};

export const ColumnVlrJulho: GridColDef = {
  field: 'vlr_julho',
  headerName: 'Julho',
  width: 150,
  type: 'number',
  renderCell: (params: GridCellParams) => {
    if (!params.value) {
      return <span>0.0000</span>;
    }

    return <span>{parseFloat(params.value.toString()).toFixed(4)}</span>;
  },
};

export const ColumnVlrAgosto: GridColDef = {
  field: 'vlr_agosto',
  headerName: 'Agosto',
  width: 150,
  type: 'number',
  renderCell: (params: GridCellParams) => {
    if (!params.value) {
      return <span>0.0000</span>;
    }

    return <span>{parseFloat(params.value.toString()).toFixed(4)}</span>;
  },
};

export const ColumnVlrSetembro: GridColDef = {
  field: 'vlr_setembro',
  headerName: 'Setembro',
  width: 150,
  type: 'number',
  renderCell: (params: GridCellParams) => {
    if (!params.value) {
      return <span>0.0000</span>;
    }

    return <span>{parseFloat(params.value.toString()).toFixed(4)}</span>;
  },
};

export const ColumnVlrOutubro: GridColDef = {
  field: 'vlr_outubro',
  headerName: 'Outubro',
  width: 150,
  type: 'number',
  renderCell: (params: GridCellParams) => {
    if (!params.value) {
      return <span>0.0000</span>;
    }

    return <span>{parseFloat(params.value.toString()).toFixed(4)}</span>;
  },
};

export const ColumnVlrNovembro: GridColDef = {
  field: 'vlr_novembro',
  headerName: 'Novembro',
  width: 150,
  type: 'number',
  renderCell: (params: GridCellParams) => {
    if (!params.value) {
      return <span>0.0000</span>;
    }

    return <span>{parseFloat(params.value.toString()).toFixed(4)}</span>;
  },
};

export const ColumnVlrDezembro: GridColDef = {
  field: 'vlr_dezembro',
  headerName: 'Dezembro',
  width: 150,
  type: 'number',
  renderCell: (params: GridCellParams) => {
    if (!params.value) {
      return <span>0.0000</span>;
    }

    return <span>{parseFloat(params.value.toString()).toFixed(4)}</span>;
  },
};

export const ColumnObservacao: GridColDef = {
  field: 'obs',
  headerName: 'Observação',
  width: 400,
};

export const ColumnInf1: GridColDef = {
  field: 'inf1',
  headerName: 'Informação 1',
  width: 400,
};

export const ColumnInf2: GridColDef = {
  field: 'inf2',
  headerName: 'Informação 2',
  width: 400,
};

export const ColumnInf3: GridColDef = {
  field: 'inf3',
  headerName: 'Informação 3',
  width: 400,
};

export const ColumnInf4: GridColDef = {
  field: 'inf4',
  headerName: 'Informação 4',
  width: 400,
};

export const ColumnInf5: GridColDef = {
  field: 'inf5',
  headerName: 'Informação 5',
  width: 400,
};

export const ColumnIdCadIndicPerson: GridColDef = {
  field: 'id_cad_indic_person',
  headerName: '# - ID indicador personalizado',
  hide: true,
  width: 320,
  editable: false,
  filterable: false,
  hideSortIcons: true,
  sortable: false,
  disableColumnMenu: true,
};

export const ColumnIdIndicPersonLcto: GridColDef = {
  headerName: '#',
  field: 'id_indic_person_lcto',
  hide: true,
  width: 400,
  editable: false,
  filterable: false,
  hideSortIcons: true,
  sortable: false,
  disableColumnMenu: true,
};
