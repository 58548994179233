interface IKeyIndicadorPersonalizadoLcto {
  kind_interface: 'indic_person_lcto';
  id_indic_person_lcto: string;
}

interface IKeyCadIndicadorPersonalizado {
  kind_interface: 'cad_indic_person';
  id_cad_indic_person: string;
}

interface IKey {
  kind_interface: 'default';
  id: string;
}

type IParam = IKey | IKeyCadIndicadorPersonalizado | IKeyIndicadorPersonalizadoLcto;

export default function getListUpdate<T extends IParam>(list: T[], value: T): T[] {
  const newList: T[] = [];
  for (let i = 0; i < list.length; i += 1) {
    const item = list[i];
    let add = false;

    switch (value.kind_interface) {
      case 'default':
        add = (item as IKey).id === (value as IKey).id;
        break;

      case 'cad_indic_person':
        add =
          (item as IKeyCadIndicadorPersonalizado).id_cad_indic_person ===
          (value as IKeyCadIndicadorPersonalizado).id_cad_indic_person;
        break;

      case 'indic_person_lcto':
        add =
          (item as IKeyIndicadorPersonalizadoLcto).id_indic_person_lcto ===
          (value as IKeyIndicadorPersonalizadoLcto).id_indic_person_lcto;
        break;

      default:
        add = false;
        break;
    }

    if (add) {
      newList.push(value);
    } else {
      newList.push(list[i]);
    }
  }

  return newList;
}
