import React, { useEffect, useState, useCallback } from 'react';

import MenuHome from './MenuHome';
import MenuSuperUser from './MenuSuperUser';
import MenuAdminUser from './MenuAdminUser';
import MenuUser from './MenuUser';
import MenuUserSettings from './MenuUserSettings';
import MenuFinacialAccouts from './MenuFinacialAccouts';
import MenuNone from './MenuNone';
import MenuHumanResources from './MenuHumanResources';
import MenuCostAnalysis from './MenuCostAnalysis';
import MenuTool from './MenuTool';
import logoImg from '../../assets/AssessorTech.svg';
import { useAuth } from '../../hooks/Auth';
import { Navbar, HeaderMenu, Informacao, LicenseInfo, CompanyInfo } from './styles';
import { ActiveSystemKindOptions } from '../../utils/appInfo';

const Header: React.FC = () => {
  const { user, isMasterUser, getUserPreferences } = useAuth();
  const [licenseInfo, setLicenseInfo] = useState('');
  const [companyInfo, setCompanyInfo] = useState('');

  const enableMenuFinancialAccounts = useCallback((): boolean => {
    if (user.empresa) {
      if (user.empresa.viewer) return false;

      if (user.empresa.active_system_kind !== ActiveSystemKindOptions.Option_FinancialAccounts) {
        return false;
      }

      if (user.empresa.admin) {
        return true;
      }
    }

    if (isMasterUser()) {
      return true;
    }

    if (user.redirectToCompanyRequest) {
      return false;
    }

    return true;
  }, [user, isMasterUser]);

  const enableMenuHumanResources = useCallback((): boolean => {
    if (user.empresa) {
      if (user.empresa.viewer) return false;

      if (user.empresa.active_system_kind !== ActiveSystemKindOptions.Option_HumanResources) {
        return false;
      }

      if (user.empresa.admin) {
        return true;
      }
    }

    if (isMasterUser()) {
      return true;
    }

    if (user.redirectToCompanyRequest) {
      return false;
    }

    return true;
  }, [user, isMasterUser]);

  const enableMenuCostAnalysis = useCallback((): boolean => {
    if (user.empresa) {
      if (user.empresa.viewer) return false;

      if (user.empresa.active_system_kind !== ActiveSystemKindOptions.Option_CostAnalysis) {
        return false;
      }

      if (user.empresa.admin) {
        return true;
      }
    }

    if (isMasterUser()) {
      return true;
    }

    if (user.redirectToCompanyRequest) {
      return false;
    }

    return true;
  }, [user, isMasterUser]);

  const enableMenuUserAccountingAnalysis = useCallback((): boolean => {
    if (user.license?.tipo_licenca === '9-Gerenciamento contratos') {
      return false;
    }

    if (user.empresa) {
      if (user.empresa.viewer) return false;

      if (user.empresa.active_system_kind !== ActiveSystemKindOptions.Option_AccountingAnalysis) {
        return false;
      }

      if (user.empresa.admin) {
        return true;
      }
    }

    if (isMasterUser()) {
      return true;
    }

    if (user.redirectToCompanyRequest) {
      return false;
    }

    return true;
  }, [user, isMasterUser]);

  const enableMenuTool = useCallback((): boolean => {
    if (user.empresa) {
      if (user.empresa.viewer) return false;

      if (user.empresa.active_system_kind === ActiveSystemKindOptions.Option_FinancialAccounts) {
        return true;
      }

      if (user.empresa.active_system_kind === ActiveSystemKindOptions.Option_AccountingAnalysis) {
        return true;
      }
    }
    return false;
  }, [user]);

  const enableMenuAdminUser = useCallback((): boolean => {
    if (user.empresa) {
      if (user.empresa.viewer) return false;
      if (user.empresa.admin) return true;
    }

    if (isMasterUser()) {
      return true;
    }

    return false;
  }, [user, isMasterUser]);

  const enableMenuLicenseUser = useCallback((): boolean => {
    if (user.empresa) {
      if (user.empresa.viewer) return false;
    }

    if (isMasterUser()) {
      return true;
    }
    return false;
  }, [user, isMasterUser]);

  useEffect(() => {
    async function loadSystemInfo(): Promise<void> {
      if (user.license && user.empresa) {
        setCompanyInfo(`Empresa: ${user.empresa.cnpj.trim()}-${user.empresa.name.trim()}`);
        setLicenseInfo(`Licença de uso: ${user.license.cnpj.trim()}-${user.license.descricao.trim()}`);
      }
    }

    loadSystemInfo();
  }, [user, getUserPreferences]);

  return (
    <>
      <HeaderMenu>
        <a target="_blank" rel="noopener noreferrer" href="https://www.assessortech.com.br/">
          <img src={logoImg} alt="Assessortech" />
        </a>

        <Navbar>
          <MenuNone />
          <MenuNone />
          <MenuUserSettings />
          {enableMenuUserAccountingAnalysis() && <MenuUser />}
          {enableMenuFinancialAccounts() && <MenuFinacialAccouts />}
          {enableMenuHumanResources() && <MenuHumanResources />}
          {enableMenuCostAnalysis() && <MenuCostAnalysis />}
          {enableMenuTool() && <MenuTool />}
          {enableMenuAdminUser() && <MenuAdminUser />}
          {enableMenuLicenseUser() && <MenuSuperUser />}
          <MenuHome />
        </Navbar>
      </HeaderMenu>
      <Informacao>
        <LicenseInfo>{licenseInfo}</LicenseInfo>
        <CompanyInfo>{companyInfo}</CompanyInfo>
      </Informacao>
    </>
  );
};

export default Header;
