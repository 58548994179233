import { SvgIconProps } from '@material-ui/core';
import React, { ButtonHTMLAttributes } from 'react';

import { ButtonItem } from './styles';

interface IButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  icon?: React.ComponentType<SvgIconProps>;
}

const ButtonDropdownMenu: React.FC<IButtonProps> = ({ icon: Icon, children, ...rest }) => {
  return (
    <ButtonItem type="button" {...rest}>
      {Icon && <Icon />}
      {children}
    </ButtonItem>
  );
};

export default ButtonDropdownMenu;
