const defaultFilterDataInicio = (): string => {
  const data = new Date();
  const mes = data.getMonth() + 1;
  const ano = data.getFullYear();
  const mesText: string = mes < 10 ? `0${mes}` : `${mes}`;
  return `${ano}-${mesText}-01`;
};

const getLastDay = (y: number, m: number): number => {
  return new Date(y, m, 0).getDate();
};

const defaultFilterDataFim = (): string => {
  const data = new Date();
  const mes = data.getMonth() + 1;
  const ano = data.getFullYear();
  const dia = getLastDay(ano, mes);
  const mesText: string = mes < 10 ? `0${mes}` : `${mes}`;
  return `${ano}-${mesText}-${dia}`;
};

export { defaultFilterDataInicio, defaultFilterDataFim, getLastDay };
