import React, { useRef, useState, useEffect, useCallback } from 'react';
import * as Yup from 'yup';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';

import { DataGrid, GridColDef, GridCellParams, GridRowId, ptBR } from '@material-ui/data-grid';
import { createMuiTheme, ThemeProvider, makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';

import { FiCopy } from 'react-icons/fi';
import { MdDelete, MdEdit, MdClear, MdSkipNext, MdSkipPrevious, MdContentCopy } from 'react-icons/md';
import {
  AiOutlinePlus,
  AiOutlineSave,
  AiFillFastBackward,
  AiFillFastForward,
  AiOutlineCaretDown,
  AiOutlineCaretUp,
} from 'react-icons/ai';
import { GoPlus } from 'react-icons/go';
import { RiDeleteBinLine } from 'react-icons/ri';

import InputComum from '../../components/InputComum';
import Header from '../../components/Header';
import FormModalDialogConfirm from '../../components/FormModalDialogConfirm';

import api from '../../services/api';
import { useAuth } from '../../hooks/Auth';
import { useToast } from '../../hooks/Toast';

import getListUpdate from '../../utils/getListUpdate';
import getValidationErrors from '../../utils/getValidationErrors';
import { getPathGrid } from '../../utils/storagePath';

import { ColumnCodigoHistorico, ColumnDescricaoHistorico, ColumnId } from './GridColumns';

import ICadHistPadrao from './ICadHistPadrao';
import {
  Container,
  ContainerFields,
  Tab,
  TabButton,
  TabContent,
  FormFields,
  FormContainer,
  FormNavigate,
  TabTitulo,
  TabButtonArea,
  TabButtonNovo,
  TabButtonDelete,
  TabButtonGridRowHeight,
} from './styles';

const useStylesLoading = makeStyles((theme: Theme) =>
  createStyles({
    backdrop: {
      zIndex: theme.zIndex.drawer + 2000,
      color: '#fff',
    },
  }),
);

const theme = createMuiTheme(
  {
    palette: {
      primary: { main: '#006699' },
    },
  },
  ptBR,
);

const emptyCadHistPadrao: ICadHistPadrao = {
  acao: '',
  id: '',
  id_company: '',
  id_license: '',
  codigo_historico: '',
  descricao_historico: '',
  created_user: '',
};

const titulo = 'H I S T Ó R I C O S    P A D R Õ E S';

const CadHistPadrao: React.FC = () => {
  const { user } = useAuth();
  const { addToast } = useToast();
  const formRef = useRef<FormHandles>(null);
  const [demGerCadConta, setCadHistPadrao] = useState<ICadHistPadrao>();
  const [listCadHistPadrao, setListCadHistPadrao] = useState<ICadHistPadrao[]>([]);
  const [hiddenPesquisar, setHiddenPesquisar] = useState<boolean>(false);
  const [hiddenCadastrar, setHiddenCadastrar] = useState<boolean>(true);
  const [cadHistPadraoIndex, setCadHistPadraoIndex] = useState<number>(0);
  const [rowToEdit, setRowToEdit] = useState(null);
  const [idDeleteCadHistPadrao, setIdDeleteCadHistPadrao] = useState<string>('');
  const [selectedIds, setSelectedIds] = useState<GridRowId[]>([]);
  const [openDeleteSingle, setOpenDeleteSingle] = useState(false);
  const [openDeleteMultiple, setOpenDeleteMultiple] = useState(false);
  const [openLoading, setOpenLoading] = useState(false);
  const cssLoading = useStylesLoading();
  const [gridRowHeight, setGridRowHeight] = useState<number>(46);

  const handleGridRowHeightUp = useCallback(async () => {
    const heightValue: number = gridRowHeight + 1;
    setGridRowHeight(heightValue);
    localStorage.setItem(getPathGrid(), heightValue.toString());
  }, [gridRowHeight]);

  const handleGridRowHeightDown = useCallback(async () => {
    const heightValue: number = gridRowHeight - 1;
    setGridRowHeight(heightValue);
    localStorage.setItem(getPathGrid(), heightValue.toString());
  }, [gridRowHeight]);

  const licencaInvalida = useCallback((): boolean => {
    if (!user.license || user.license.id === '') {
      addToast({
        type: 'error',
        title: 'Licença inválida',
        description: 'Usuário não está vinculado a nenhuma licença',
      });

      return true;
    }

    return false;
  }, [addToast, user]);

  const defineCadHistPadrao = useCallback(async (data: ICadHistPadrao) => {
    setCadHistPadrao(data);
    formRef.current?.setData(data);
  }, []);

  const onNewRegister = useCallback(async () => {
    defineCadHistPadrao(emptyCadHistPadrao);
  }, [defineCadHistPadrao]);

  const OnGridSetRegisterToEdit = useCallback(
    async (params: GridCellParams) => {
      const localCadHistPadrao = params.row as ICadHistPadrao;

      setCadHistPadraoIndex(listCadHistPadrao.indexOf(localCadHistPadrao));
      setHiddenCadastrar(false);
      setHiddenPesquisar(true);
      setRowToEdit(null);
      defineCadHistPadrao(localCadHistPadrao);
    },
    [listCadHistPadrao, defineCadHistPadrao],
  );

  const OnSetRegisterToAdd = useCallback(async () => {
    onNewRegister();
    setHiddenCadastrar(false);
    setHiddenPesquisar(true);
  }, [onNewRegister]);

  const OnGridRowDuplicate = useCallback(
    async (baseCadHistPadrao: ICadHistPadrao | undefined) => {
      if (baseCadHistPadrao !== undefined) {
        const newCadHistPadrao: ICadHistPadrao = {
          acao: baseCadHistPadrao.acao,
          descricao_historico: baseCadHistPadrao.descricao_historico,
          codigo_historico: baseCadHistPadrao.codigo_historico,
          id_company: baseCadHistPadrao.id_company,
          id_license: baseCadHistPadrao.id_license,
          created_user: baseCadHistPadrao.created_user,
          id: '',
        };

        defineCadHistPadrao(newCadHistPadrao);
        setHiddenCadastrar(false);
        setHiddenPesquisar(true);
      }
    },
    [defineCadHistPadrao],
  );

  const OnConfirmationDeleteSingle = useCallback(
    async (id: string) => {
      if (licencaInvalida()) return;

      if (id === '') return;

      setIdDeleteCadHistPadrao(id);
      setOpenDeleteSingle(true);
    },
    [licencaInvalida],
  );

  const ColumnAcao: GridColDef = {
    field: 'acao',
    headerName: 'Ação',
    editable: false,
    filterable: false,
    hideSortIcons: true,
    sortable: false,
    disableColumnMenu: true,
    width: 160,
    renderCell: (params: GridCellParams) => (
      <strong>
        <Tooltip title="Editar Registro" placement="top">
          <IconButton color="primary" aria-label="Editar" onClick={() => OnGridSetRegisterToEdit(params)}>
            <MdEdit />
          </IconButton>
        </Tooltip>

        <Tooltip title="Duplicar Registro" placement="top">
          <IconButton
            color="primary"
            aria-label="Duplicar"
            onClick={() => OnGridRowDuplicate(params.row as ICadHistPadrao)}
          >
            <FiCopy />
          </IconButton>
        </Tooltip>

        <Tooltip title="Excluir Registro" placement="top">
          <IconButton
            color="primary"
            aria-label="Excluir"
            onClick={() => OnConfirmationDeleteSingle((params.row as ICadHistPadrao).id)}
          >
            <MdDelete />
          </IconButton>
        </Tooltip>
      </strong>
    ),
  };
  const columns: GridColDef[] = [ColumnAcao, ColumnCodigoHistorico, ColumnDescricaoHistorico, ColumnId];

  const getCadHistPadrao = useCallback(async () => {
    if (licencaInvalida()) return;

    const response = await api.get<ICadHistPadrao[]>(`/cadhistpadrao`, {
      params: {
        id_license: user.license.id,
        id_company: user.empresa.id,
      },
    });

    setListCadHistPadrao(response.data);

    if (rowToEdit !== null) {
      const findEditCadHistPadrao = response.data.find(item => item.id === rowToEdit);

      if (findEditCadHistPadrao) {
        defineCadHistPadrao(findEditCadHistPadrao);
        setCadHistPadraoIndex(response.data.indexOf(findEditCadHistPadrao));
        setHiddenCadastrar(false);
        setHiddenPesquisar(true);
        setRowToEdit(null);
      }
    }

    setHiddenPesquisar(false);
  }, [user, licencaInvalida, rowToEdit, defineCadHistPadrao]);

  const getValues = useCallback(async () => {
    setOpenLoading(true);

    await getCadHistPadrao();

    setOpenLoading(false);
  }, [getCadHistPadrao]);

  useEffect(() => {
    getValues();

    const rowHeight = localStorage.getItem(getPathGrid());
    if (rowHeight) {
      setGridRowHeight(parseInt(rowHeight, 10));
    }
  }, [getValues]);

  const handleOnRowDeleteSingle = useCallback(async () => {
    setOpenLoading(true);
    await api.delete(`/cadhistpadrao/${idDeleteCadHistPadrao}`);

    setListCadHistPadrao(oldCadHistPadrao => oldCadHistPadrao.filter(item => item.id !== idDeleteCadHistPadrao));

    defineCadHistPadrao(emptyCadHistPadrao);

    addToast({
      type: 'success',
      title: 'Removido',
      description: 'Registro excluído com sucesso!',
    });

    setOpenDeleteSingle(false);
    setOpenLoading(false);
  }, [idDeleteCadHistPadrao, addToast, defineCadHistPadrao]);

  const handleOnRowDeleteMultiple = useCallback(async () => {
    setOpenLoading(true);
    if (selectedIds.length > 0) {
      await Promise.all(
        selectedIds.map(itemId => {
          setListCadHistPadrao(oldCadHistPadrao => oldCadHistPadrao.filter(item => item.id !== itemId));

          return api.delete(`/cadhistpadrao/${itemId}`);
        }),
      );

      defineCadHistPadrao(emptyCadHistPadrao);

      addToast({
        type: 'success',
        title: 'Removido',
        description: 'Registro excluído com sucesso!',
      });
    }

    setSelectedIds([]);
    setOpenDeleteMultiple(false);
    setOpenLoading(false);
  }, [addToast, defineCadHistPadrao, selectedIds]);

  const handleClick = (id: string): void => {
    setHiddenCadastrar(id === 'pesquisar');
    setHiddenPesquisar(id === 'cadastrar');
  };

  const onNextRegister = useCallback(async () => {
    if (cadHistPadraoIndex !== undefined) {
      if (cadHistPadraoIndex < listCadHistPadrao.length - 1) {
        const localIndex = cadHistPadraoIndex + 1;
        setCadHistPadraoIndex(localIndex);
        const findEditCadHistPadrao = listCadHistPadrao[localIndex];
        if (findEditCadHistPadrao) {
          defineCadHistPadrao(findEditCadHistPadrao);
        }
      }
    }
  }, [cadHistPadraoIndex, listCadHistPadrao, defineCadHistPadrao]);

  const onFirstRegister = useCallback(async () => {
    if (listCadHistPadrao.length > 0) {
      setCadHistPadraoIndex(0);
      const findEditCadHistPadrao = listCadHistPadrao[0];
      if (findEditCadHistPadrao) {
        defineCadHistPadrao(findEditCadHistPadrao);
      }
    }
  }, [listCadHistPadrao, defineCadHistPadrao]);

  const onLastRegister = useCallback(async () => {
    if (listCadHistPadrao.length > 0) {
      setCadHistPadraoIndex(listCadHistPadrao.length - 1);
      const findEditCadHistPadrao = listCadHistPadrao[listCadHistPadrao.length - 1];
      if (findEditCadHistPadrao) {
        defineCadHistPadrao(findEditCadHistPadrao);
      }
    }
  }, [listCadHistPadrao, defineCadHistPadrao]);

  const onPriorRegister = useCallback(async () => {
    if (cadHistPadraoIndex !== undefined) {
      if (cadHistPadraoIndex > 0) {
        const localIndex = cadHistPadraoIndex - 1;
        setCadHistPadraoIndex(localIndex);
        const findEditCadHistPadrao = listCadHistPadrao[localIndex];
        if (findEditCadHistPadrao) {
          defineCadHistPadrao(findEditCadHistPadrao);
        }
      }
    }
  }, [cadHistPadraoIndex, listCadHistPadrao, defineCadHistPadrao]);

  const onCancelRegister = useCallback(async () => {
    formRef.current?.setErrors({});

    if (cadHistPadraoIndex !== undefined) {
      const findEditCadHistPadrao = listCadHistPadrao[cadHistPadraoIndex];
      if (findEditCadHistPadrao) {
        defineCadHistPadrao(findEditCadHistPadrao);
      }
    }

    onNewRegister();
  }, [cadHistPadraoIndex, listCadHistPadrao, onNewRegister, defineCadHistPadrao]);

  const handleSubmit = useCallback(
    async (data: ICadHistPadrao, { reset }) => {
      if (licencaInvalida()) return;

      formRef.current?.setErrors({});
      try {
        const schema = Yup.object().shape({
          codigo_historico: Yup.string().required('Código é obrigatório'),
          descricao_historico: Yup.string().required('Descrição é obrigatória'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        setOpenLoading(true);
        if (data.id === '') {
          const formData: ICadHistPadrao = {
            id_company: user.empresa.id,
            id_license: user.license.id,
            codigo_historico: data.codigo_historico,
            descricao_historico: data.descricao_historico,
            acao: data.acao,
            id: '',
            created_user: user.id,
          };

          const response = await api.post(`/cadhistpadrao`, formData);
          formData.id = response.data.id;
          setListCadHistPadrao(oldValues => [...oldValues, formData]);

          addToast({
            type: 'success',
            title: 'Adicionado',
            description: 'Registro adicionado com sucesso!',
          });
        } else {
          await api.put(`/cadhistpadrao/${data.id}`, data);

          setListCadHistPadrao(getListUpdate(listCadHistPadrao, data));

          addToast({
            type: 'success',
            title: 'Alterado',
            description: 'Registro alterado com sucesso!',
          });
        }

        reset();
        setOpenLoading(false);
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          formRef.current?.setErrors(errors);
        }

        setOpenLoading(false);
      }
    },
    [licencaInvalida, listCadHistPadrao, user, addToast],
  );

  return (
    <Container>
      <Header />

      <Tab>
        <TabButtonArea>
          <TabButton id="pesquisar" isActive={!hiddenPesquisar} onClick={() => handleClick('pesquisar')}>
            Listagem
          </TabButton>
          <TabButton id="cadastrar" isActive={!hiddenCadastrar} onClick={() => handleClick('cadastrar')}>
            Cadastro
          </TabButton>
          <Tooltip title="Reduzir o tamanho da linha" placement="top">
            <TabButtonGridRowHeight id="gridrowheightdown" onClick={() => handleGridRowHeightDown()}>
              <AiOutlineCaretDown />
            </TabButtonGridRowHeight>
          </Tooltip>

          <Tooltip title="Aumentar o tamanho da linha" placement="top">
            <TabButtonGridRowHeight id="gridrowheightup" onClick={() => handleGridRowHeightUp()}>
              <AiOutlineCaretUp />
            </TabButtonGridRowHeight>
          </Tooltip>

          <Tooltip title="Novo Registro" placement="top">
            <TabButtonNovo id="new_register" onClick={() => OnSetRegisterToAdd()}>
              <GoPlus />
              Novo
            </TabButtonNovo>
          </Tooltip>

          <Tooltip title="Remover Registros Selecionados" placement="top">
            <span>
              <span hidden={selectedIds.length <= 0}>
                <TabButtonDelete
                  id="remove_all"
                  onClick={() => {
                    setIdDeleteCadHistPadrao('');
                    setOpenDeleteMultiple(true);
                  }}
                >
                  <RiDeleteBinLine />
                  Remover
                </TabButtonDelete>
              </span>
            </span>
          </Tooltip>
        </TabButtonArea>

        <TabTitulo>
          <span>{titulo}</span>
        </TabTitulo>
      </Tab>

      <TabContent id="pesquisar" isActive={!hiddenPesquisar}>
        <div style={{ height: '85vh', width: '100%' }}>
          <ThemeProvider theme={theme}>
            <DataGrid
              rows={listCadHistPadrao}
              columns={columns}
              rowHeight={gridRowHeight}
              checkboxSelection
              selectionModel={selectedIds}
              disableSelectionOnClick
              onSelectionModelChange={e => setSelectedIds(e.selectionModel)}
            />
          </ThemeProvider>
        </div>
      </TabContent>

      <TabContent id="cadasto" isActive={!hiddenCadastrar}>
        <Form ref={formRef} onSubmit={handleSubmit} initialData={demGerCadConta}>
          <FormContainer>
            <FormNavigate>
              <Tooltip title="Novo Registro" placement="top">
                <IconButton color="primary" aria-label="adicionar" onClick={() => onNewRegister()}>
                  <AiOutlinePlus />
                </IconButton>
              </Tooltip>

              <Tooltip title="Duplicar Registro" placement="top">
                <IconButton color="primary" aria-label="Duplicar" onClick={() => OnGridRowDuplicate(demGerCadConta)}>
                  <MdContentCopy />
                </IconButton>
              </Tooltip>

              <Tooltip title="Excluir Registro" placement="top">
                <IconButton
                  color="primary"
                  aria-label="Excluir"
                  onClick={() => OnConfirmationDeleteSingle(demGerCadConta ? demGerCadConta.id : '')}
                >
                  <RiDeleteBinLine />
                </IconButton>
              </Tooltip>

              <Tooltip title="Cancelar" placement="top">
                <IconButton type="reset" color="primary" aria-label="Cancelar" onClick={() => onCancelRegister()}>
                  <MdClear />
                </IconButton>
              </Tooltip>

              <Tooltip title="Salvar" placement="top">
                <IconButton type="submit" color="primary" aria-label="Confirmar">
                  <AiOutlineSave />
                </IconButton>
              </Tooltip>

              <Tooltip title="Primeiro Registro" placement="top">
                <IconButton color="primary" aria-label="Primeiro" onClick={() => onFirstRegister()}>
                  <AiFillFastBackward />
                </IconButton>
              </Tooltip>

              <Tooltip title="Registro Anterior" placement="top">
                <IconButton color="primary" aria-label="Anterior" onClick={() => onPriorRegister()}>
                  <MdSkipPrevious />
                </IconButton>
              </Tooltip>

              <Tooltip title="Próximo Registro" placement="top">
                <IconButton color="primary" aria-label="Próximo" onClick={() => onNextRegister()}>
                  <MdSkipNext />
                </IconButton>
              </Tooltip>

              <Tooltip title="Último Registro" placement="top">
                <IconButton color="primary" aria-label="Último registro" onClick={() => onLastRegister()}>
                  <AiFillFastForward />
                </IconButton>
              </Tooltip>
            </FormNavigate>

            <FormFields>
              <ContainerFields field="T300px">
                <span>Código:</span>
                <InputComum autoFocus name="codigo_historico" />
              </ContainerFields>

              <ContainerFields field="T100">
                <span>Descrição:</span>
                <InputComum name="descricao_historico" />
              </ContainerFields>

              <ContainerFields field="id">
                <InputComum name="id" hidden />
              </ContainerFields>
            </FormFields>
          </FormContainer>
        </Form>
      </TabContent>

      <FormModalDialogConfirm
        open={openDeleteSingle}
        onClose={() => setOpenDeleteSingle(false)}
        onConfirm={() => handleOnRowDeleteSingle()}
        buttonCancelText="Cancelar"
        buttonConfirmText="Sim"
        formTitle="Excluir?"
        messageConfirm="Tem certeza que deseja excluir?"
      />

      <FormModalDialogConfirm
        open={openDeleteMultiple}
        onClose={() => setOpenDeleteMultiple(false)}
        onConfirm={() => handleOnRowDeleteMultiple()}
        buttonCancelText="Cancelar"
        buttonConfirmText="Sim"
        formTitle="Excluir?"
        messageConfirm="Tem certeza que deseja excluir todos os registros selecionados?"
      />

      <Backdrop className={cssLoading.backdrop} open={openLoading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </Container>
  );
};

export default CadHistPadrao;
