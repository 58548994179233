import React from 'react';
import { useHistory } from 'react-router-dom';

import { Menu, LowPriority, Create, Eco, AttachMoney, Dashboard, Star, PostAdd } from '@material-ui/icons';

import {
  ROUTE_DEM_GER_RELAC,
  ROUTE_DEM_GER_LCTOS,
  ROUTE_DEM_GER_LCTOS_COMENTARIOS,
  ROUTE_DEM_GER_FOLHA,
  ROUTE_DEM_GER_ORCADO,
  ROUTE_DEM_GER_ORCADO_TOT,
  ROUTE_DASHBOARD_POWERBI,
  ROUTE_INDICADOR_PERSON_LCTO,
} from '../../utils/constants';

import ButtonDropdownMenu from './ButtonDropdownMenu';
import { ContainerDropdown, DropdownContentUser } from './styles';

const MenuUser: React.FC = () => {
  const history = useHistory();

  const handleGoDemGerRelac = (): void => {
    history.push(ROUTE_DEM_GER_RELAC);
  };

  const handleGoDemGerLctos = (): void => {
    history.push(ROUTE_DEM_GER_LCTOS);
  };

  const handleGoDemGerLctosComentarios = (): void => {
    history.push(ROUTE_DEM_GER_LCTOS_COMENTARIOS);
  };

  const handleGoDemGerFolha = (): void => {
    history.push(ROUTE_DEM_GER_FOLHA);
  };

  const handleGoDemGerOrcado = (): void => {
    history.push(ROUTE_DEM_GER_ORCADO);
  };

  const handleGoDemGerOrcadoTot = (): void => {
    history.push(ROUTE_DEM_GER_ORCADO_TOT);
  };

  const handleGoDashboard = (): void => {
    history.push(ROUTE_DASHBOARD_POWERBI);
  };

  const handleGoIndicadoresPersonalizados = (): void => {
    history.push(ROUTE_INDICADOR_PERSON_LCTO);
  };

  return (
    <ContainerDropdown>
      <button type="button">
        <Menu />
      </button>
      <DropdownContentUser>
        <ButtonDropdownMenu onClick={handleGoDemGerRelac} icon={LowPriority}>
          <span>Relacionamentos</span>
        </ButtonDropdownMenu>
        <ButtonDropdownMenu onClick={handleGoDemGerLctos} icon={Create}>
          <span>Ajustes gerenciais</span>
        </ButtonDropdownMenu>
        <ButtonDropdownMenu onClick={handleGoDemGerFolha} icon={Eco}>
          <span>Folha pagamento</span>
        </ButtonDropdownMenu>
        <ButtonDropdownMenu onClick={handleGoDemGerOrcadoTot} icon={AttachMoney}>
          <span>Orçamento Totalizador</span>
        </ButtonDropdownMenu>
        <ButtonDropdownMenu onClick={handleGoDemGerOrcado} icon={AttachMoney}>
          <span>Orçamento Contas</span>
        </ButtonDropdownMenu>
        <ButtonDropdownMenu onClick={handleGoDemGerLctosComentarios} icon={Star}>
          <span>Notas e Comentários</span>
        </ButtonDropdownMenu>
        <ButtonDropdownMenu onClick={handleGoIndicadoresPersonalizados} icon={PostAdd}>
          <span>Indicadores</span>
        </ButtonDropdownMenu>
        <ButtonDropdownMenu onClick={handleGoDashboard} icon={Dashboard}>
          <span>Dashboard</span>
        </ButtonDropdownMenu>
      </DropdownContentUser>
    </ContainerDropdown>
  );
};

export default MenuUser;
