import { GridColDef } from '@material-ui/data-grid';

export const ColumnDemonstrativo: GridColDef = {
  headerName: 'Demonstrativo',
  field: 'demonstrativo',
  width: 300,
};

export const ColumnOrigem: GridColDef = {
  headerName: 'Origem',
  field: 'origem',
  width: 200,
  type: 'number',
};

export const ColumnTipo: GridColDef = {
  headerName: 'Tipo',
  field: 'tp',
  width: 200,
  type: 'number',
};

export const ColumnDescricao: GridColDef = {
  headerName: 'Descrição',
  field: 'descricao',
  width: 400,
};

export const ColumnObservacao: GridColDef = {
  headerName: 'Observação',
  field: 'obs',
  width: 400,
};

export const ColumnInf1: GridColDef = {
  headerName: 'Informação 1',
  field: 'inf1',
  width: 400,
};

export const ColumnInf2: GridColDef = {
  headerName: 'Informação 2',
  field: 'inf2',
  width: 400,
};

export const ColumnInf3: GridColDef = {
  headerName: 'Informação 3',
  field: 'inf3',
  width: 400,
};

export const ColumnInf4: GridColDef = {
  headerName: 'Informação 4',
  field: 'inf4',
  width: 400,
};

export const ColumnInf5: GridColDef = {
  headerName: 'Informação 5',
  field: 'inf5',
  width: 400,
};

export const ColumnId: GridColDef = {
  headerName: '#',
  field: 'id',
  hide: true,
  width: 400,
  editable: false,
  filterable: false,
  hideSortIcons: true,
  sortable: false,
  disableColumnMenu: true,
};
