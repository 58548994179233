import React from 'react';
import { Switch } from 'react-router-dom';

import Route from './Route';
import SignIn from '../pages/SignIn';
import SignUp from '../pages/SignUp';
import ForgotPassword from '../pages/ForgotPassword';
import ResetPassword from '../pages/ResetPassword';
import Profile from '../pages/Profile';
import Dashboard from '../pages/Dashboard';
import DashboardPowerBI from '../pages/DashboardPowerBI';
import Home from '../pages/Home';
import UserRequestAcess from '../pages/UserRequestAcess';
// import License from '../pages/License';
import LicenseConfig from '../pages/LicenseConfig';
import DemGer from '../pages/DemGer';
import DemGerParametro from '../pages/DemGerParametro';
import DemGerRelac from '../pages/DemGerRelac';
import DemGerLctos from '../pages/DemGerLctos';
import DemGerLctosComentarios from '../pages/DemGerLctosComentarios';
import DemGerOrcado from '../pages/DemGerOrcado';
import DemGerOrcadoTot from '../pages/DemGerOrcadoTot';
import DemGerFolha from '../pages/DemGerFolha';
import DemGerCadConta from '../pages/DemGerCadConta';
import DemGerCadContaCorrente from '../pages/DemGerCadContaCorrente';
import DemGerCadCCusto from '../pages/DemGerCadCCusto';
import TipoDemGer from '../pages/TipoDemGer';
import Company from '../pages/Company';
import UsersLicense from '../pages/UsersLicense';
import UsersCompany from '../pages/UsersCompany';
import TipoDemGerCompany from '../pages/TipoDemGerCompany';
import Info from '../pages/Info';
import KeyAssociate from '../pages/KeyAssociate';
import CompanyChange from '../pages/CompanyChange';
import FinMovimentos from '../pages/FinMovimentos';
import CadHistPadrao from '../pages/CadHistPadrao';
import HistoricoImportacao from '../pages/HistoricoImportacao';
import CadIndicadorPersonalizado from '../pages/CadIndicadorPersonalizado';
import IndicadorPersonalizadoLcto from '../pages/IndicadorPersonalizadoLcto';

import CfgPeriodoContabil from '../pages/CfgPeriodoContabil';

// import Manutencao from '../pages/Manutencao';

import {
  ROUTE_DASHBOARD,
  ROUTE_DASHBOARD_POWERBI,
  ROUTE_DEM_GER,
  ROUTE_DEM_GER_CAD_CONTA,
  ROUTE_DEM_GER_CAD_CONTA_CORRENTE,
  ROUTE_COMPANY,
  ROUTE_USERS_LICENSE,
  ROUTE_USERS_COMPANY,
  ROUTE_TIPO_DEM_GER,
  ROUTE_TIPO_DEM_GER_COMPANY,
  ROUTE_HOME,
  ROUTE_DEM_GER_PARAMETRO,
  ROUTE_DEM_GER_CAD_CENTRO_CUSTO,
  ROUTE_USER_REQUEST_ACESS,
  ROUTE_KEY_ASSOCIATE,
  ROUTE_INFO,
  ROUTE_DEM_GER_RELAC,
  ROUTE_DEM_GER_LCTOS,
  ROUTE_DEM_GER_LCTOS_COMENTARIOS,
  ROUTE_DEM_GER_ORCADO,
  ROUTE_DEM_GER_ORCADO_TOT,
  ROUTE_DEM_GER_FOLHA,
  ROUTE_PROFILE,
  ROUTE_COMPANY_CHANGE,
  ROUTE_LICENSE,
  ROUTE_SIGN_IN,
  ROUTE_SIGN_UP,
  ROUTE_FORGOT_PASSWORD,
  ROUTE_RESET_PASSWORD,
  ROUTE_RECEITAS_DESPESAS,
  ROUTE_CAD_HIST_PADRAO,
  ROUTE_HISTORICO_IMPORTACAO,
  ROUTE_CAD_INDICADOR_PERSON,
  ROUTE_INDICADOR_PERSON_LCTO,
  ROUTE_CFG_PERIODO_CONTABIL,
} from '../utils/constants';

const Routes: React.FC = () => {
  return (
    <Switch>
      <Route path="/" exact component={SignIn} />
      <Route path={ROUTE_SIGN_IN} exact component={SignIn} />
      <Route path={ROUTE_SIGN_UP} component={SignUp} />
      <Route path={ROUTE_FORGOT_PASSWORD} component={ForgotPassword} />
      <Route path={ROUTE_RESET_PASSWORD} component={ResetPassword} />

      <Route isPrivate isAdminPage path={ROUTE_LICENSE} component={LicenseConfig} />

      <Route isPrivate path={ROUTE_HOME} component={Home} />
      <Route isPrivate path={ROUTE_DASHBOARD} component={Dashboard} />
      <Route isPrivate path={ROUTE_DASHBOARD_POWERBI} component={DashboardPowerBI} />
      <Route isPrivate path={ROUTE_USERS_COMPANY} component={UsersCompany} />

      <Route isPrivate path={ROUTE_USERS_LICENSE} component={UsersLicense} />
      <Route isPrivate path={ROUTE_PROFILE} component={Profile} />
      <Route isPrivate path={ROUTE_COMPANY} component={Company} />
      <Route isPrivate path={ROUTE_COMPANY_CHANGE} component={CompanyChange} />

      <Route isPrivate path={ROUTE_TIPO_DEM_GER} component={TipoDemGer} />

      <Route isPrivate path={ROUTE_TIPO_DEM_GER_COMPANY} component={TipoDemGerCompany} />
      <Route isPrivate path={ROUTE_DEM_GER} component={DemGer} />
      <Route isPrivate path={ROUTE_KEY_ASSOCIATE} component={KeyAssociate} />
      <Route isPrivate path={ROUTE_DEM_GER_RELAC} component={DemGerRelac} />
      <Route isPrivate path={ROUTE_DEM_GER_LCTOS} component={DemGerLctos} />
      <Route isPrivate path={ROUTE_DEM_GER_LCTOS_COMENTARIOS} component={DemGerLctosComentarios} />

      <Route isPrivate path={ROUTE_DEM_GER_ORCADO} component={DemGerOrcado} />
      <Route isPrivate path={ROUTE_DEM_GER_ORCADO_TOT} component={DemGerOrcadoTot} />
      <Route isPrivate path={ROUTE_DEM_GER_FOLHA} component={DemGerFolha} />
      <Route isPrivate path={ROUTE_DEM_GER_PARAMETRO} component={DemGerParametro} />
      <Route isPrivate path={ROUTE_DEM_GER_CAD_CONTA} component={DemGerCadConta} />
      <Route isPrivate path={ROUTE_DEM_GER_CAD_CONTA_CORRENTE} component={DemGerCadContaCorrente} />
      <Route isPrivate path={ROUTE_DEM_GER_CAD_CENTRO_CUSTO} component={DemGerCadCCusto} />
      <Route isPrivate path={ROUTE_INFO} component={Info} />
      <Route isPrivate path={ROUTE_USER_REQUEST_ACESS} component={UserRequestAcess} />
      <Route isPrivate path={ROUTE_RECEITAS_DESPESAS} component={FinMovimentos} />
      <Route isPrivate path={ROUTE_HISTORICO_IMPORTACAO} component={HistoricoImportacao} />
      <Route isPrivate path={ROUTE_CAD_HIST_PADRAO} component={CadHistPadrao} />
      <Route isPrivate path={ROUTE_CAD_INDICADOR_PERSON} component={CadIndicadorPersonalizado} />
      <Route isPrivate path={ROUTE_INDICADOR_PERSON_LCTO} component={IndicadorPersonalizadoLcto} />

      <Route isPrivate path={ROUTE_CFG_PERIODO_CONTABIL} component={CfgPeriodoContabil} />
    </Switch>
  );
};

export default Routes;
