import React, { useState, useCallback, useEffect, ReactElement } from 'react';
import { Box, IconButton } from '@material-ui/core';
import { DataGrid, GridColDef, GridCellParams, ptBR } from '@material-ui/data-grid';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import { AiOutlineSearch, AiOutlineCaretDown, AiOutlineCaretUp } from 'react-icons/ai';
import './style.css';

import {
  ColumnId,
  ColumnDescricao,
  ColumnSituacao,
  ColumnTipo,
  ColumnValorOriginal,
  ColumnValorQuitacao,
} from './TableCashFlowGridColumns';
import ModalFilterFluxoCaixa from './ModalFilterFluxoCaixa';
import { getPathGrid } from '../../../utils/storagePath';
import { getFormatedDate } from '../../../utils/formatValues';
import { IFilterFluxoCaixa, emptyFilterFluxoCaixa } from './IFilterFluxoCaixa';

export interface IDailyCashFlow {
  id: string;
  id_conta_corrente: string;
  id_conta_corrente_transferencia: string;
  tipo: string;
  valor_original: number;
  valor_quitacao: number;
  situacao: string;
  data: Date;
  data_vencimento: Date;
  data_quitacao: Date;
  descricao_movimento: string;
}

type functionVoid = (inFilter: IFilterFluxoCaixa) => void;

interface IDailyCashFlowProps {
  listDailyCashFlow: IDailyCashFlow[];
  onConfirmFilter: functionVoid;
}

const theme = createMuiTheme(
  {
    palette: {
      primary: { main: '#006699' },
    },
  },
  ptBR,
);

const TableCashFlow: React.FC<IDailyCashFlowProps> = ({ listDailyCashFlow, onConfirmFilter }) => {
  const [gridRowHeight, setGridRowHeight] = useState<number>(46);
  const [openFilterFluxoCaixa, setOpenFilterFluxoCaixa] = useState(false);
  const [textFiltro, setTextFiltro] = useState<string>('');

  const ColumnData: GridColDef = {
    headerName: 'Data',
    field: 'data',
    width: 150,
    type: 'date',
    renderCell: (params: GridCellParams) => {
      if (params.value === null) return <strong />;
      const ano = (params.value as string).substring(0, 4);
      const mes = (params.value as string).substring(5, 7);
      const dia = (params.value as string).substring(8, 10);

      const dataFormatada = `${dia}/${mes}/${ano}`;
      return <strong>{dataFormatada}</strong>;
    },
  };

  const ColumnDataVencimento: GridColDef = {
    headerName: 'Data vencimento',
    field: 'data_vencimento',
    width: 190,
    type: 'date',
    renderCell: (params: GridCellParams) => {
      if (params.value === null) return <strong />;
      const ano = (params.value as string).substring(0, 4);
      const mes = (params.value as string).substring(5, 7);
      const dia = (params.value as string).substring(8, 10);

      const dataFormatada = `${dia}/${mes}/${ano}`;
      return <strong>{dataFormatada}</strong>;
    },
  };

  const ColumnDataQuitacao: GridColDef = {
    headerName: 'Data quitação',
    field: 'data_quitacao',
    width: 170,
    type: 'date',
    renderCell: (params: GridCellParams) => {
      if (params.value === null) return <strong />;
      const ano = (params.value as string).substring(0, 4);
      const mes = (params.value as string).substring(5, 7);
      const dia = (params.value as string).substring(8, 10);

      const dataFormatada = `${dia}/${mes}/${ano}`;
      return <strong>{dataFormatada}</strong>;
    },
  };

  const columns: GridColDef[] = [
    ColumnTipo,
    ColumnSituacao,
    ColumnData,
    ColumnDataVencimento,
    ColumnDataQuitacao,
    ColumnValorOriginal,
    ColumnValorQuitacao,
    ColumnDescricao,
    ColumnId,
  ];

  const handleGridRowHeightUp = useCallback(async () => {
    const heightValue: number = gridRowHeight + 1;
    setGridRowHeight(heightValue);
    localStorage.setItem(getPathGrid(), heightValue.toString());
  }, [gridRowHeight]);

  const handleGridRowHeightDown = useCallback(async () => {
    const heightValue: number = gridRowHeight - 1;
    setGridRowHeight(heightValue);
    localStorage.setItem(getPathGrid(), heightValue.toString());
  }, [gridRowHeight]);

  const onCloseFilterCashFlow = useCallback(() => {
    setOpenFilterFluxoCaixa(false);
  }, []);

  const defineFilterText = useCallback((inFilter: IFilterFluxoCaixa) => {
    const localSituacao = [];
    if (!inFilter.situacao.pendente && !inFilter.situacao.previsto && !inFilter.situacao.quitado) {
      localSituacao.push('1-Previsto');
      localSituacao.push('2-Pendente');
      localSituacao.push('3-Quitado');
    } else {
      if (inFilter.situacao.previsto) localSituacao.push('1-Previsto');
      if (inFilter.situacao.pendente) localSituacao.push('2-Pendente');
      if (inFilter.situacao.quitado) localSituacao.push('3-Quitado');
    }

    const situacaoFilterText = `Situação: ${localSituacao
      .map(itemList => itemList.substring(2, itemList.length))
      .join(', ')}`;

    const localTipo = [];
    if (!inFilter.tipo.despesa && !inFilter.tipo.receita && !inFilter.tipo.transferencia) {
      localTipo.push('1-Receita');
      localTipo.push('2-Despesa');
      localTipo.push('3-Transferência');
    } else {
      if (inFilter.tipo.receita) localTipo.push('1-Receita');
      if (inFilter.tipo.despesa) localTipo.push('2-Despesa');
      if (inFilter.tipo.transferencia) localTipo.push('3-Transferência');
    }
    const tipoFilterText = `Tipo: ${localTipo.map(itemList => itemList.substring(2, itemList.length)).join(', ')}`;

    const tipoDataFilterText = inFilter.tipoData.substring(2, inFilter.tipoData.length);
    setTextFiltro(
      `Filtrado por: ${tipoDataFilterText} Período: ${getFormatedDate(
        new Date(inFilter.dataInicio),
      )} à ${getFormatedDate(new Date(inFilter.dataFim))}. ${situacaoFilterText}. ${tipoFilterText}`,
    );
  }, []);

  useEffect(() => {
    defineFilterText(emptyFilterFluxoCaixa);
  }, [defineFilterText]);

  const onConfirmFilterCashFlow = useCallback(
    (inFilter: IFilterFluxoCaixa) => {
      setOpenFilterFluxoCaixa(false);
      defineFilterText(inFilter);
      if (onConfirmFilter) {
        onConfirmFilter(inFilter);
      }
    },
    [onConfirmFilter, defineFilterText],
  );

  const onOpenFilterFluxoCaixa = useCallback(() => {
    setOpenFilterFluxoCaixa(true);
  }, []);

  const DataGridTitle = (): ReactElement => {
    return (
      <Box
        style={{
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <div className="astTableCashFlowHeader">
          <div className="astTableCashFlowHeaderButtons">
            <IconButton id="gridrowheightdown" color="primary" onClick={onOpenFilterFluxoCaixa}>
              <AiOutlineSearch />
            </IconButton>

            <IconButton id="gridrowheightdown" color="primary" onClick={() => handleGridRowHeightDown()}>
              <AiOutlineCaretDown />
            </IconButton>
            <IconButton color="primary" id="gridrowheightup" onClick={() => handleGridRowHeightUp()}>
              <AiOutlineCaretUp />
            </IconButton>
          </div>

          <div>
            <h2 style={{ color: '#006699' }}>Fluxo de caixa diário</h2>
            <span className="astTableCashFlowFilterText">{textFiltro}</span>
          </div>
        </div>
      </Box>
    );
  };

  return (
    <div className="astTableCashFlow">
      <ThemeProvider theme={theme}>
        <DataGrid
          rows={listDailyCashFlow}
          columns={columns}
          rowHeight={gridRowHeight}
          components={{ Toolbar: DataGridTitle }}
        />
      </ThemeProvider>

      <ModalFilterFluxoCaixa
        open={openFilterFluxoCaixa}
        onConfirm={onConfirmFilterCashFlow}
        onCancel={onCloseFilterCashFlow}
      />
    </div>
  );
};

export default TableCashFlow;
