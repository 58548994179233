import { GridColDef } from '@material-ui/data-grid';

export const ColumnAtivo: GridColDef = {
  headerName: 'Ativo',
  field: 'ativo',
  width: 120,
  type: 'boolean',
};

export const ColumnCodigoCentroCusto: GridColDef = {
  field: 'cod_ccusto',
  headerName: 'Cenrto custo',
  width: 200,
};

export const ColumnCodigoCentroCustoSuperior: GridColDef = {
  field: 'cod_ccusto_superior',
  headerName: 'Centro custo superior',
  width: 220,
};

export const ColumnNomeCentroCusto: GridColDef = {
  field: 'nome_ccusto',
  headerName: 'Nome centro custo',
  width: 400,
};

export const ColumnIndCentroCusto: GridColDef = {
  field: 'ind_ccusto',
  headerName: 'Indicador conta',
  width: 200,
};

export const ColumnNivel: GridColDef = {
  field: 'nivel',
  headerName: 'Nível',
  width: 120,
  type: 'number',
};

export const ColumnClassificacao: GridColDef = {
  field: 'classificacao',
  headerName: 'Classificação',
  width: 300,
};

export const ColumnObservacao: GridColDef = {
  field: 'obs',
  headerName: 'Observação',
  width: 500,
};

export const ColumnInformacao1: GridColDef = {
  field: 'inf1',
  headerName: 'Informação 1',
  width: 500,
};

export const ColumnInformacao2: GridColDef = {
  field: 'inf2',
  headerName: 'Informação 2',
  width: 500,
};

export const ColumnInformacao3: GridColDef = {
  field: 'inf3',
  headerName: 'Informação 3',
  width: 500,
};

export const ColumnInformacao4: GridColDef = {
  field: 'inf4',
  headerName: 'Informação 4',
  width: 500,
};

export const ColumnInformacao5: GridColDef = {
  field: 'inf5',
  headerName: 'Informação 5',
  width: 500,
};

export const ColumnID: GridColDef = {
  field: 'id',
  headerName: '#',
  width: 400,
  hide: true,
  editable: false,
  filterable: false,
  hideSortIcons: true,
  sortable: false,
  disableColumnMenu: true,
};
