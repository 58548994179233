import React, { useCallback, useState, useEffect } from 'react';
import { AxiosError } from 'axios';
import { uuid } from 'uuidv4';
import filesize from 'filesize';
import { DropEvent } from 'react-dropzone';

import Dialog from '@material-ui/core/Dialog';
import ButtonDialog from '@material-ui/core/Button';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';

import UploadFileIcon from '@material-ui/icons/Description';
import { FaFileDownload } from 'react-icons/fa';

import api from '../../services/api';
import { useToast } from '../../hooks/Toast';

import UploadFile from '../UploadFile';
import FileList from '../FileList';
import IFile from '../FileList/IFile';

import { Container, TitleHead, TitleDiv, DivDownload } from './styles';

type functionVoid = () => void;

interface IUploadError {
  message: string;
}

interface IModalUploadFileProps {
  open: boolean;
  onClose: functionVoid;
  apiSource: string;
  apiSourceFileExample: string;
}

const ModalUploadFileCSV: React.FC<IModalUploadFileProps> = ({ apiSource, onClose, open, apiSourceFileExample }) => {
  const { addToast } = useToast();
  const [uploadedFiles, setUploadedFiles] = useState<IFile[]>([]);
  const [uploadedFilesError, setUploadedFilesError] = useState<IFile[]>([]);
  const [uploadedFilesFinished, setUploadedFilesFinished] = useState<IFile[]>([]);
  const [urlDownload, setURLDownload] = useState('');
  const [download, setDownload] = useState('');
  const [count, setCount] = useState(0);

  const handleClose = useCallback(() => {
    setUploadedFiles([]);
    setDownload('');
    if (onClose) {
      onClose();
    }
  }, [onClose]);

  const processUpload = useCallback(
    async uploadedFile => {
      const data = new FormData();

      data.append('file', uploadedFile.file, uploadedFile.name);

      const item: IFile = {
        uploaded: false,
        id: uploadedFile.id,
        name: uploadedFile.name,
        readableSize: uploadedFile.readableSize,
        isUploading: true,
        error: false,
      };

      api
        .post(apiSource, data)
        .then(() => {
          item.uploaded = true;
          item.error = false;
          item.isUploading = false;
          item.message = '';

          setUploadedFilesFinished([...uploadedFilesFinished, item]);

          addToast({
            type: 'success',
            title: 'Carregado',
            description: `Arquivo ${uploadedFile.name} carregado com sucesso`,
          });
        })

        .catch((responseError: string | Error | AxiosError) => {
          let messageError = `Ocorreu uma falha ao carregar o arquivo ${uploadedFile.name}. `;
          if (typeof responseError === 'string') {
            messageError += responseError;
          } else {
            const err = responseError as AxiosError;
            messageError += err.response?.data.message;
          }

          item.uploaded = false;
          item.error = true;
          item.isUploading = false;
          item.message = messageError;

          setUploadedFilesError(prevState => [...prevState, item]);

          addToast({
            type: 'error',
            title: 'Falhou',
            description: messageError,
          });
        });
    },
    [addToast, apiSource, uploadedFilesFinished],
  );

  const handleUpload = useCallback(async () => {
    setDownload('');
    setUploadedFilesError([]);
    const newList = await Promise.all(
      uploadedFiles.map(item => ({
        ...item,
        error: false,
        isUploading: !item.uploaded,
      })),
    );
    setUploadedFiles(newList);

    const listUpload = newList.filter(item => item.isUploading);

    listUpload.forEach(processUpload);
  }, [uploadedFiles, processUpload]);

  const handleSelectFiles = useCallback(
    <T extends File>(files: T[], event: DropEvent): void => {
      const uploadedList = files.map(file => {
        const newFile: IFile = {
          file,
          id: uuid(),
          name: file.name,
          readableSize: filesize(file.size),
          isUploading: false,
          uploaded: false,
          error: false,
        };

        return newFile;
      });

      const newList = uploadedList.concat(uploadedFiles);

      setUploadedFiles(newList);
    },
    [uploadedFiles],
  );

  useEffect(() => {
    api.get(apiSourceFileExample).then(resp => {
      setURLDownload(resp.data.file);
    });

    uploadedFilesFinished.map(item => {
      const findItem = uploadedFiles.find(it => it.id === item.id);
      if (findItem) {
        findItem.isUploading = false;
        findItem.uploaded = true;
      }

      return findItem;
    });

    uploadedFilesError.map(item => {
      const findItem = uploadedFiles.find(it => it.id === item.id);
      if (findItem) {
        findItem.isUploading = false;
        findItem.error = true;
        findItem.message = item.message;
      }

      return findItem;
    });
  }, [uploadedFiles, uploadedFilesError, uploadedFilesFinished, apiSourceFileExample]);

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <TitleHead>
        <TitleDiv>
          <UploadFileIcon />

          <DialogTitle id="alert-dialog-title">Carregar arquivos</DialogTitle>
        </TitleDiv>

        <DivDownload>
          <Tooltip title="Baixar arquivo modelo" placement="top">
            <IconButton
              color="primary"
              aria-label="download"
              onClick={() => {
                setDownload(urlDownload);
                setCount(old => old + 1);
              }}
            >
              <FaFileDownload />
            </IconButton>
          </Tooltip>
        </DivDownload>
      </TitleHead>

      <DialogContent>
        <Container>
          <p className="ext_file">
            <span>Arquivos válidos: CSV, XLS, XLT, XLSX, XLTX, ODS, OTS</span>
          </p>

          <UploadFile isCSVFile onUpload={handleSelectFiles} />
          {!!uploadedFiles.length && <FileList files={uploadedFiles} />}
        </Container>

        {download && <iframe title="Example file" src={`${download}?c=${count}`} style={{ display: 'none' }} />}
      </DialogContent>
      <DialogActions>
        <ButtonDialog onClick={handleClose} color="secondary" autoFocus variant="contained">
          Cancelar
        </ButtonDialog>
        <ButtonDialog onClick={handleUpload} color="primary" variant="contained">
          Enviar
        </ButtonDialog>
      </DialogActions>
    </Dialog>
  );
};

export default ModalUploadFileCSV;
