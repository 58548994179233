import * as echarts from 'echarts';

type EChartsOption = echarts.EChartsOption;

const option: EChartsOption = {
  title: {
    text: 'Receitas x Despesas',
  },
  tooltip: {
    trigger: 'axis',
    axisPointer: {
      type: 'shadow',
    },
  },
  legend: {
    top: 'bottom',
  },
  grid: {
    left: '3%',
    right: '4%',
    bottom: '3%',
    containLabel: true,
  },
  xAxis: {
    type: 'value',
    boundaryGap: [0, 0.01],
  },
  yAxis: {
    type: 'category',
    data: ['01-Receitas', '03-Custo produtos', '04-Custo fixo', '06-Despesa fixa', '09-Despesa financeira'],
  },
  series: [
    {
      type: 'bar',
      label: {
        show: true,
      },
      data: [
        {
          value: 100896.32,
          name: '01-Receitas',
          label: {
            position: 'right',
          },
        },
        {
          value: 21556.1,
          name: '03-Custo produtos',
          label: {
            position: 'right',
          },
        },
        {
          value: 7663.99,
          name: '04-Custo fixo',
          label: {
            position: 'right',
          },
        },
        {
          value: 1654874.01,
          name: '06-Despesa fixa',
        },
        {
          value: 1654.0,
          name: '09-Despesa financeira',
          label: {
            position: 'right',
          },
        },
      ],
    },
  ],
};

export default option;
