export default interface IDemGerCadContaCorrente {
  id: string;
  id_license: string;
  id_company: string;
  id_banco: string;
  agencia: string;
  conta_corrente: string;
  descricao_conta: string;
  saldo_inicial: number;
  ativa: boolean;
  considerar_fluxo_caixa: boolean;
  obs: string;
  data_inicio: string;
  data_fim?: string;
  created_user: string;
  acao: string;
  descricao_banco: string;
  conta_contabil: string;
}

export const emptyContaCorrente: IDemGerCadContaCorrente = {
  acao: '',
  id: '',
  id_license: '',
  id_company: '',
  id_banco: '',
  agencia: '',
  conta_corrente: '',
  descricao_conta: '',
  obs: '',
  saldo_inicial: 0,
  data_inicio: '',
  data_fim: undefined,
  ativa: true,
  considerar_fluxo_caixa: true,
  descricao_banco: '',
  created_user: '',
  conta_contabil: '',
};
