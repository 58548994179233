import React from 'react';

import { ContainerDropdown } from './styles';

const MenuNone: React.FC = () => {
  return (
    <ContainerDropdown>
      <button type="button">
        <span />
      </button>
    </ContainerDropdown>
  );
};

export default MenuNone;
