import { GridColDef } from '@material-ui/data-grid';

export const ColumnCodIndicador: GridColDef = {
  headerName: 'Código indicador',
  field: 'cod_indicador',
  width: 200,
};

export const ColumnCodIndicadorSup: GridColDef = {
  headerName: 'Código indicador superior',
  field: 'cod_indicador_sup',
  width: 250,
};

export const ColumnGrupoIndicador: GridColDef = {
  headerName: 'Grupo indicador',
  field: 'grupo_indicador',
  width: 300,
};

export const ColumnTipoIndicador: GridColDef = {
  headerName: 'Tipo indicador',
  field: 'tipo_indicador',
  width: 400,
};

export const ColumnDescIndicador: GridColDef = {
  headerName: 'Descrição indicador',
  field: 'desc_indicador',
  width: 400,
};

export const ColumnIdCadIndicPerson: GridColDef = {
  headerName: '#',
  field: 'id_cad_indic_person',
  hide: true,
  width: 400,
  editable: false,
  filterable: false,
  hideSortIcons: true,
  sortable: false,
  disableColumnMenu: true,
};
