export interface IFilterFluxoCaixaSituacao {
  previsto: boolean;
  pendente: boolean;
  quitado: boolean;
}

export const emptyFilterFluxoCaixaSituacao: IFilterFluxoCaixaSituacao = {
  previsto: true,
  pendente: true,
  quitado: true,
};
