import React, { useRef, useState, useEffect, useCallback } from 'react';
import * as Yup from 'yup';

import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';

import { DataGrid, GridColDef, GridCellParams, GridRowId, ptBR } from '@material-ui/data-grid';
import { createMuiTheme, ThemeProvider, makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';

import { FiCopy } from 'react-icons/fi';

import { MdDelete, MdEdit, MdClear, MdSkipNext, MdSkipPrevious, MdContentCopy } from 'react-icons/md';
import {
  AiOutlinePlus,
  AiOutlineSave,
  AiFillFastBackward,
  AiFillFastForward,
  AiOutlineCaretDown,
  AiOutlineCaretUp,
} from 'react-icons/ai';
import { GoPlus } from 'react-icons/go';
import { RiDeleteBinLine } from 'react-icons/ri';

import InputComum from '../../components/InputComum';
import InputDate from '../../components/InputDate';
import ModalUploadFile from '../../components/ModalUploadFile';
import Header from '../../components/Header';
import FormModalDialogConfirm from '../../components/FormModalDialogConfirm';

import api from '../../services/api';
import { useAuth } from '../../hooks/Auth';
import { useToast } from '../../hooks/Toast';

import getListUpdate from '../../utils/getListUpdate';
import getValidationErrors from '../../utils/getValidationErrors';
import { getPathGrid } from '../../utils/storagePath';

import {
  ColumnCentroCusto,
  ColumnValorFolha,
  ColumnValorProlabore,
  ColumnQtdFuncionarios,
  ColumnQtdAdmissoes,
  ColumnQtdDemissoes,
  ColumnQtdAfastamentos,
  ColumnQtdHorasUteisMes,
  ColumnQtdHorasTrabalhadas,
  ColumnQtdHorasExtras,
  ColumnQtdHorasFalta,
  ColumnQtdHorasFerias,
  ColumnObservacao,
  ColumnInf1,
  ColumnInf2,
  ColumnInf3,
  ColumnInf4,
  ColumnInf5,
  ColumnId,
} from './GridColumns';

import IDemGerFolha from './IDemGerFolha';

import {
  Container,
  ContainerFields,
  Tab,
  TabButton,
  TabContent,
  FormFields,
  ContainerAllPrice,
  ContainerPrice,
  FormContainer,
  FormNavigate,
  TabTitulo,
  TabButtonArea,
  TabButtonNovo,
  TabButtonDelete,
  TabButtonGridRowHeight,
} from './styles';

const useStylesLoading = makeStyles((theme: Theme) =>
  createStyles({
    backdrop: {
      zIndex: theme.zIndex.drawer + 2000,
      color: '#fff',
    },
  }),
);

const theme = createMuiTheme(
  {
    palette: {
      primary: { main: '#006699' },
    },
  },
  ptBR,
);

const emptyDemGerFolha: IDemGerFolha = {
  acao: '',
  id: '',
  cnpj_estab: '',
  cod_ccusto: '',
  data: '',
  id_license: '',
  valor_folha: 0,
  valor_prolabore: 0,
  qtd_admissoes: 0,
  qtd_afastamentos: 0,
  qtd_demissoes: 0,
  qtd_funcionarios: 0,
  qtd_horas_extras: 0,
  qtd_horas_falta: 0,
  qtd_horas_ferias: 0,
  qtd_horas_trabalhadas: 0,
  qtd_horas_uteis_mes: 0,
  obs: '',
  inf1: '',
  inf2: '',
  inf3: '',
  inf4: '',
  inf5: '',
};
const titulo = 'F O L H A    P A G A M E N T O';

const DemGerFolha: React.FC = () => {
  const { user } = useAuth();
  const { addToast } = useToast();

  const formRef = useRef<FormHandles>(null);
  const [demGerFolha, setDemGerFolha] = useState<IDemGerFolha>();
  const [listDemGerFolha, setListDemGerFolha] = useState<IDemGerFolha[]>([]);
  const [hiddenPesquisar, setHiddenPesquisar] = useState<boolean>(true);
  const [hiddenCadastrar, setHiddenCadastrar] = useState<boolean>(true);
  const [demGerFolhaIndex, setDemGerFolhaIndex] = useState<number>(0);
  const [rowToEdit, setRowToEdit] = useState(null);
  const [idDeleteDemGerFolha, setIdDeleteDemGerFolha] = useState<string>('');
  const [selectedIds, setSelectedIds] = useState<GridRowId[]>([]);
  const [openDeleteSingle, setOpenDeleteSingle] = useState(false);
  const [openDeleteMultiple, setOpenDeleteMultiple] = useState(false);
  const [openLoading, setOpenLoading] = useState(false);
  const cssLoading = useStylesLoading();
  const [gridRowHeight, setGridRowHeight] = useState<number>(46);
  const [openUploadFile, setOpenUploadFile] = useState<boolean>(false);

  const handleGridRowHeightUp = useCallback(async () => {
    const heightValue: number = gridRowHeight + 1;
    setGridRowHeight(heightValue);
    localStorage.setItem(getPathGrid(), heightValue.toString());
  }, [gridRowHeight]);

  const handleGridRowHeightDown = useCallback(async () => {
    const heightValue: number = gridRowHeight - 1;
    setGridRowHeight(heightValue);
    localStorage.setItem(getPathGrid(), heightValue.toString());
  }, [gridRowHeight]);

  const licencaInvalida = useCallback((): boolean => {
    if (!user.license || user.license.id === '') {
      addToast({
        type: 'error',
        title: 'Licença inválida',
        description: 'Usuário não está vinculado a nenhuma licença',
      });

      return true;
    }

    return false;
  }, [addToast, user]);

  const defineDemGerFolha = useCallback(async (data: IDemGerFolha) => {
    setDemGerFolha(data);
    formRef.current?.setData(data);
  }, []);

  const onNewRegister = useCallback(async () => {
    defineDemGerFolha(emptyDemGerFolha);
  }, [defineDemGerFolha]);

  const OnGridSetRegisterToEdit = useCallback(
    async (params: GridCellParams) => {
      const localDemGerFolha = params.row as IDemGerFolha;

      setDemGerFolhaIndex(listDemGerFolha.indexOf(localDemGerFolha));
      setHiddenCadastrar(false);
      setHiddenPesquisar(true);
      setRowToEdit(null);
      defineDemGerFolha(localDemGerFolha);
    },
    [listDemGerFolha, defineDemGerFolha],
  );

  const OnSetRegisterToAdd = useCallback(async () => {
    onNewRegister();
    setHiddenCadastrar(false);
    setHiddenPesquisar(true);
  }, [onNewRegister]);

  const OnGridRowDuplicate = useCallback(
    async (baseDemGerFolha: IDemGerFolha | undefined) => {
      if (baseDemGerFolha !== undefined) {
        const newDemGerFolha: IDemGerFolha = {
          acao: baseDemGerFolha.acao,
          id: '',
          id_license: baseDemGerFolha.id_license,
          cnpj_estab: baseDemGerFolha.cnpj_estab,
          data: baseDemGerFolha.data,
          cod_ccusto: baseDemGerFolha.cod_ccusto,
          valor_folha: baseDemGerFolha.valor_folha,
          valor_prolabore: baseDemGerFolha.valor_prolabore,
          qtd_funcionarios: baseDemGerFolha.qtd_funcionarios,
          qtd_admissoes: baseDemGerFolha.qtd_admissoes,
          qtd_demissoes: baseDemGerFolha.qtd_demissoes,
          qtd_afastamentos: baseDemGerFolha.qtd_afastamentos,
          qtd_horas_uteis_mes: baseDemGerFolha.qtd_horas_uteis_mes,
          qtd_horas_trabalhadas: baseDemGerFolha.qtd_horas_trabalhadas,
          qtd_horas_extras: baseDemGerFolha.qtd_horas_extras,
          qtd_horas_falta: baseDemGerFolha.qtd_horas_falta,
          qtd_horas_ferias: baseDemGerFolha.qtd_horas_ferias,
          obs: baseDemGerFolha.obs,
          inf1: baseDemGerFolha.inf1,
          inf2: baseDemGerFolha.inf2,
          inf3: baseDemGerFolha.inf3,
          inf4: baseDemGerFolha.inf4,
          inf5: baseDemGerFolha.inf5,
        };

        defineDemGerFolha(newDemGerFolha);
        setHiddenCadastrar(false);
        setHiddenPesquisar(true);
      }
    },
    [defineDemGerFolha],
  );

  const OnConfirmationDeleteSingle = useCallback(
    async (id: string) => {
      if (licencaInvalida()) return;

      if (id === '') return;

      setIdDeleteDemGerFolha(id);
      setOpenDeleteSingle(true);
    },
    [licencaInvalida],
  );

  const colunaAcao: GridColDef = {
    field: 'acao',
    headerName: 'Ação',
    editable: false,
    filterable: false,
    hideSortIcons: true,
    sortable: false,
    disableColumnMenu: true,
    width: 160,
    renderCell: (params: GridCellParams) => (
      <strong>
        <Tooltip title="Editar Registro" placement="top">
          <IconButton color="primary" aria-label="Editar" onClick={() => OnGridSetRegisterToEdit(params)}>
            <MdEdit />
          </IconButton>
        </Tooltip>

        <Tooltip title="Duplicar Registro" placement="top">
          <IconButton
            color="primary"
            aria-label="Duplicar"
            onClick={() => OnGridRowDuplicate(params.row as IDemGerFolha)}
          >
            <FiCopy />
          </IconButton>
        </Tooltip>

        <Tooltip title="Excluir Registro" placement="top">
          <IconButton
            color="primary"
            aria-label="Excluir"
            onClick={() => OnConfirmationDeleteSingle((params.row as IDemGerFolha).id)}
          >
            <MdDelete />
          </IconButton>
        </Tooltip>
      </strong>
    ),
  };

  const ColumnData: GridColDef = {
    headerName: 'Data',
    field: 'data',
    width: 150,
    type: 'date',
    renderCell: (params: GridCellParams) => {
      if (params.value === null) return <strong />;
      const ano = (params.value as string).substring(0, 4);
      const mes = (params.value as string).substring(5, 7);
      const dia = (params.value as string).substring(8, 10);

      const dataFormatada = `${dia}/${mes}/${ano}`;
      return <strong>{dataFormatada}</strong>;
    },
  };

  const columns: GridColDef[] = [
    colunaAcao,
    ColumnData,
    ColumnCentroCusto,
    ColumnValorFolha,
    ColumnValorProlabore,
    ColumnQtdFuncionarios,
    ColumnQtdAdmissoes,
    ColumnQtdDemissoes,
    ColumnQtdAfastamentos,
    ColumnQtdHorasUteisMes,
    ColumnQtdHorasTrabalhadas,
    ColumnQtdHorasExtras,
    ColumnQtdHorasFalta,
    ColumnQtdHorasFerias,
    ColumnObservacao,
    ColumnInf1,
    ColumnInf2,
    ColumnInf3,
    ColumnInf4,
    ColumnInf5,
    ColumnId,
  ];

  const getDemGerFolha = useCallback(async () => {
    if (licencaInvalida()) return;

    const response = await api.get<IDemGerFolha[]>(`/demgerfolha/${user.empresa.cnpj}`);

    setListDemGerFolha(response.data);

    if (rowToEdit !== null) {
      const findEditDemGerFolha = response.data.find(item => item.id === rowToEdit);

      if (findEditDemGerFolha) {
        defineDemGerFolha(findEditDemGerFolha);
        setDemGerFolhaIndex(response.data.indexOf(findEditDemGerFolha));
        setHiddenCadastrar(false);
        setHiddenPesquisar(true);
        setRowToEdit(null);
      }
    }
  }, [user, licencaInvalida, rowToEdit, defineDemGerFolha]);

  const getValues = useCallback(async () => {
    setOpenLoading(true);

    await getDemGerFolha();

    setOpenLoading(false);
    setHiddenPesquisar(false);
  }, [getDemGerFolha]);

  useEffect(() => {
    getValues();

    const rowHeight = localStorage.getItem(getPathGrid());
    if (rowHeight) {
      setGridRowHeight(parseInt(rowHeight, 10));
    }
  }, [getValues]);

  const onUploadFileClose = useCallback(() => {
    getValues();
    setOpenUploadFile(false);
  }, [getValues]);

  const handleOnRowDeleteSingle = useCallback(async () => {
    setOpenLoading(true);
    await api.delete(`/demgerfolha/${idDeleteDemGerFolha}`);

    setListDemGerFolha(oldDemGerFolha => oldDemGerFolha.filter(item => item.id !== idDeleteDemGerFolha));

    defineDemGerFolha(emptyDemGerFolha);

    addToast({
      type: 'success',
      title: 'Removido',
      description: 'Registro excluído com sucesso!',
    });

    setOpenDeleteSingle(false);
    setOpenLoading(false);
  }, [idDeleteDemGerFolha, addToast, defineDemGerFolha]);

  const handleOnRowDeleteMultiple = useCallback(async () => {
    setOpenLoading(true);
    if (selectedIds.length > 0) {
      await Promise.all(
        selectedIds.map(itemId => {
          setListDemGerFolha(oldDemGerFolha => oldDemGerFolha.filter(item => item.id !== itemId));

          return api.delete(`/demgerfolha/${itemId}`);
        }),
      );

      defineDemGerFolha(emptyDemGerFolha);

      addToast({
        type: 'success',
        title: 'Removido',
        description: 'Registro excluído com sucesso!',
      });
    }

    setSelectedIds([]);
    setOpenDeleteMultiple(false);
    setOpenLoading(false);
  }, [addToast, defineDemGerFolha, selectedIds]);

  const handleClick = (id: string): void => {
    setHiddenCadastrar(id === 'pesquisar');
    setHiddenPesquisar(id === 'cadastrar');
  };

  const onNextRegister = useCallback(async () => {
    if (demGerFolhaIndex !== undefined) {
      if (demGerFolhaIndex < listDemGerFolha.length - 1) {
        const localIndex = demGerFolhaIndex + 1;
        setDemGerFolhaIndex(localIndex);
        const findDemGerFolha = listDemGerFolha[localIndex];
        if (findDemGerFolha) {
          defineDemGerFolha(findDemGerFolha);
        }
      }
    }
  }, [demGerFolhaIndex, listDemGerFolha, defineDemGerFolha]);

  const onFirstRegister = useCallback(async () => {
    if (listDemGerFolha.length > 0) {
      setDemGerFolhaIndex(0);
      const findDemGerFolha = listDemGerFolha[0];
      if (findDemGerFolha) {
        defineDemGerFolha(findDemGerFolha);
      }
    }
  }, [listDemGerFolha, defineDemGerFolha]);

  const onLastRegister = useCallback(async () => {
    if (listDemGerFolha.length > 0) {
      setDemGerFolhaIndex(listDemGerFolha.length - 1);
      const findDemGerFolha = listDemGerFolha[listDemGerFolha.length - 1];
      if (findDemGerFolha) {
        defineDemGerFolha(findDemGerFolha);
      }
    }
  }, [listDemGerFolha, defineDemGerFolha]);

  const onPriorRegister = useCallback(async () => {
    if (demGerFolhaIndex !== undefined) {
      if (demGerFolhaIndex > 0) {
        const localIndex = demGerFolhaIndex - 1;
        setDemGerFolhaIndex(localIndex);
        const findDemGerFolha = listDemGerFolha[localIndex];
        if (findDemGerFolha) {
          defineDemGerFolha(findDemGerFolha);
        }
      }
    }
  }, [demGerFolhaIndex, listDemGerFolha, defineDemGerFolha]);

  const onCancelRegister = useCallback(async () => {
    formRef.current?.setErrors({});

    if (demGerFolhaIndex !== undefined) {
      const findDemGerFolha = listDemGerFolha[demGerFolhaIndex];
      if (findDemGerFolha) {
        defineDemGerFolha(findDemGerFolha);
      }
    }

    onNewRegister();
  }, [demGerFolhaIndex, listDemGerFolha, onNewRegister, defineDemGerFolha]);

  const handleSubmit = useCallback(
    async (data: IDemGerFolha, { reset }) => {
      if (licencaInvalida()) return;

      formRef.current?.setErrors({});
      try {
        const schema = Yup.object().shape({
          data: Yup.date().label('Data inválida').required('Data é obrigatória'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        setOpenLoading(true);
        if (data.id === '') {
          const formData = {
            id_license: user.license.id,
            cnpj_estab: user.empresa.cnpj,
            data: new Date(`${data.data.substring(0, 10)}T12:00:00`),
            cod_ccusto: data.cod_ccusto,
            valor_folha: data.valor_folha ? data.valor_folha : 0,
            valor_prolabore: data.valor_prolabore ? data.valor_prolabore : 0,
            qtd_funcionarios: data.qtd_funcionarios ? data.qtd_funcionarios : 0,
            qtd_admissoes: data.qtd_admissoes ? data.qtd_admissoes : 0,
            qtd_demissoes: data.qtd_demissoes ? data.qtd_demissoes : 0,
            qtd_afastamentos: data.qtd_afastamentos ? data.qtd_afastamentos : 0,
            qtd_horas_uteis_mes: data.qtd_horas_uteis_mes ? data.qtd_horas_uteis_mes : 0,
            qtd_horas_trabalhadas: data.qtd_horas_trabalhadas ? data.qtd_horas_trabalhadas : 0,
            qtd_horas_extras: data.qtd_horas_extras ? data.qtd_horas_extras : 0,
            qtd_horas_falta: data.qtd_horas_falta ? data.qtd_horas_falta : 0,
            qtd_horas_ferias: data.qtd_horas_ferias ? data.qtd_horas_ferias : 0,
            obs: data.obs ? data.obs : '',
            inf1: data.inf1 ? data.inf1 : '',
            inf2: data.inf2 ? data.inf2 : '',
            inf3: data.inf3 ? data.inf3 : '',
            inf4: data.inf4 ? data.inf4 : '',
            inf5: data.inf5 ? data.inf5 : '',
          };

          const response = await api.post(`/demgerfolha`, formData);
          setListDemGerFolha(oldLicenses => [...oldLicenses, response.data]);

          addToast({
            type: 'success',
            title: 'Adicionado',
            description: 'Registro adicionado com sucesso!',
          });
        } else {
          await api.put(`/demgerfolha/${data.id}`, data);
          setListDemGerFolha(getListUpdate(listDemGerFolha, data));

          addToast({
            type: 'success',
            title: 'Alterado',
            description: 'Registro alterado com sucesso!',
          });
        }

        reset();
        setOpenLoading(false);
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          formRef.current?.setErrors(errors);
        }

        setOpenLoading(false);
      }
    },
    [licencaInvalida, listDemGerFolha, user, addToast],
  );

  return (
    <Container>
      <Header />

      <Tab>
        <TabButtonArea>
          <TabButton id="pesquisar" isActive={!hiddenPesquisar} onClick={() => handleClick('pesquisar')}>
            Listagem
          </TabButton>
          <TabButton id="cadastrar" isActive={!hiddenCadastrar} onClick={() => handleClick('cadastrar')}>
            Cadastro
          </TabButton>
          <Tooltip title="Reduzir o tamanho da linha" placement="top">
            <TabButtonGridRowHeight id="gridrowheightdown" onClick={() => handleGridRowHeightDown()}>
              <AiOutlineCaretDown />
            </TabButtonGridRowHeight>
          </Tooltip>

          <Tooltip title="Aumentar o tamanho da linha" placement="top">
            <TabButtonGridRowHeight id="gridrowheightup" onClick={() => handleGridRowHeightUp()}>
              <AiOutlineCaretUp />
            </TabButtonGridRowHeight>
          </Tooltip>

          <Tooltip title="Importar arquivo Excel" placement="top">
            <TabButton id="importar_xls" isActive={false} onClick={() => setOpenUploadFile(true)}>
              <CloudUploadIcon />
            </TabButton>
          </Tooltip>
          <Tooltip title="Novo Registro" placement="top">
            <TabButtonNovo id="new_register" onClick={() => OnSetRegisterToAdd()}>
              <GoPlus />
              Novo
            </TabButtonNovo>
          </Tooltip>

          <Tooltip title="Remover Registros Selecionados" placement="top">
            <span>
              <span hidden={selectedIds.length <= 0}>
                <TabButtonDelete
                  id="remove_all"
                  onClick={() => {
                    setIdDeleteDemGerFolha('');
                    setOpenDeleteMultiple(true);
                  }}
                >
                  <RiDeleteBinLine />
                  Remover
                </TabButtonDelete>
              </span>
            </span>
          </Tooltip>
        </TabButtonArea>

        <TabTitulo>
          <span>{titulo}</span>
        </TabTitulo>
      </Tab>

      <TabContent id="pesquisar" isActive={!hiddenPesquisar}>
        <div style={{ height: '85vh', width: '100%' }}>
          <ThemeProvider theme={theme}>
            <DataGrid
              rows={listDemGerFolha}
              columns={columns}
              rowHeight={gridRowHeight}
              checkboxSelection
              selectionModel={selectedIds}
              disableSelectionOnClick
              onSelectionModelChange={e => setSelectedIds(e.selectionModel)}
            />
          </ThemeProvider>
        </div>
      </TabContent>

      <TabContent id="cadasto" isActive={!hiddenCadastrar}>
        <Form ref={formRef} onSubmit={handleSubmit} initialData={demGerFolha}>
          <FormContainer>
            <FormNavigate>
              <Tooltip title="Novo Registro" placement="top">
                <IconButton color="primary" aria-label="adicionar" onClick={() => onNewRegister()}>
                  <AiOutlinePlus />
                </IconButton>
              </Tooltip>

              <Tooltip title="Duplicar Registro" placement="top">
                <IconButton color="primary" aria-label="Duplicar" onClick={() => OnGridRowDuplicate(demGerFolha)}>
                  <MdContentCopy />
                </IconButton>
              </Tooltip>

              <Tooltip title="Excluir Registro" placement="top">
                <IconButton
                  color="primary"
                  aria-label="Excluir"
                  onClick={() => OnConfirmationDeleteSingle(demGerFolha ? demGerFolha.id : '')}
                >
                  <RiDeleteBinLine />
                </IconButton>
              </Tooltip>

              <Tooltip title="Cancelar" placement="top">
                <IconButton type="reset" color="primary" aria-label="Cancelar" onClick={() => onCancelRegister()}>
                  <MdClear />
                </IconButton>
              </Tooltip>

              <Tooltip title="Salvar" placement="top">
                <IconButton type="submit" color="primary" aria-label="Confirmar">
                  <AiOutlineSave />
                </IconButton>
              </Tooltip>

              <Tooltip title="Primeiro Registro" placement="top">
                <IconButton color="primary" aria-label="Primeiro" onClick={() => onFirstRegister()}>
                  <AiFillFastBackward />
                </IconButton>
              </Tooltip>

              <Tooltip title="Registro Anterior" placement="top">
                <IconButton color="primary" aria-label="Anterior" onClick={() => onPriorRegister()}>
                  <MdSkipPrevious />
                </IconButton>
              </Tooltip>

              <Tooltip title="Próximo Registro" placement="top">
                <IconButton color="primary" aria-label="Próximo" onClick={() => onNextRegister()}>
                  <MdSkipNext />
                </IconButton>
              </Tooltip>

              <Tooltip title="Último Registro" placement="top">
                <IconButton color="primary" aria-label="Último registro" onClick={() => onLastRegister()}>
                  <AiFillFastForward />
                </IconButton>
              </Tooltip>
            </FormNavigate>

            <FormFields>
              <ContainerFields field="T280px">
                <span>Data:</span>
                <InputDate autoFocus name="data" />
              </ContainerFields>
              <ContainerFields field="T280px">
                <span>Centro de Custo:</span>
                <InputComum autoFocus name="cod_ccusto" />
              </ContainerFields>
              <ContainerAllPrice>
                <ContainerPrice>
                  <ContainerFields field="T280px">
                    <span>Valor Folha:</span>
                    <InputComum name="valor_folha" placeholder="0,00" type="number" step="0.01" />
                  </ContainerFields>
                  <ContainerFields field="T280px" className="field2">
                    <span>Valor Pró-Labore:</span>
                    <InputComum name="valor_prolabore" placeholder="0,00" type="number" step="0.01" />
                  </ContainerFields>
                </ContainerPrice>

                <ContainerPrice>
                  <ContainerFields field="T280px">
                    <span>Quantidade Funcionários:</span>
                    <InputComum name="qtd_funcionarios" type="number" min="0" />
                  </ContainerFields>
                  <ContainerFields field="T280px" className="field2">
                    <span>Quantidade Admissões:</span>
                    <InputComum name="qtd_admissoes" type="number" min="0" />
                  </ContainerFields>
                </ContainerPrice>

                <ContainerPrice>
                  <ContainerFields field="T280px">
                    <span>Quantidade Demissões:</span>
                    <InputComum name="qtd_demissoes" type="number" min="0" />
                  </ContainerFields>
                  <ContainerFields field="T280px" className="field2">
                    <span>Quantidade Afastamentos:</span>
                    <InputComum name="qtd_afastamentos" type="number" min="0" />
                  </ContainerFields>
                </ContainerPrice>

                <ContainerPrice>
                  <ContainerFields field="T280px">
                    <span>Quantidade Horas Uteis Mês:</span>
                    <InputComum name="qtd_horas_uteis_mes" placeholder="0,00" type="number" step="0.01" min="0" />
                  </ContainerFields>
                  <ContainerFields field="T280px" className="field2">
                    <span>Quantidade Horas Trabalhadas:</span>
                    <InputComum name="qtd_horas_trabalhadas" placeholder="0,00" type="number" step="0.01" min="0" />
                  </ContainerFields>
                </ContainerPrice>

                <ContainerPrice>
                  <ContainerFields field="T280px">
                    <span>Quantidade Horas Extras:</span>
                    <InputComum name="qtd_horas_extras" placeholder="0,00" type="number" step="0.01" min="0" />
                  </ContainerFields>
                  <ContainerFields field="T280px" className="field2">
                    <span>Quantidade Horas Falta:</span>
                    <InputComum name="qtd_horas_falta" placeholder="0,00" type="number" step="0.01" min="0" />
                  </ContainerFields>
                </ContainerPrice>
                <ContainerPrice>
                  <ContainerFields field="T280px">
                    <span>Quantidade Horas Férias:</span>
                    <InputComum name="qtd_horas_ferias" placeholder="0,00" type="number" step="0.01" min="0" />
                  </ContainerFields>
                </ContainerPrice>
              </ContainerAllPrice>

              <ContainerFields field="T100">
                <span>Observação:</span>
                <InputComum autoFocus name="obs" />
              </ContainerFields>
              <ContainerFields field="T100">
                <span>Informação 1:</span>
                <InputComum autoFocus name="inf1" />
              </ContainerFields>
              <ContainerFields field="T100">
                <span>Informação 2:</span>
                <InputComum autoFocus name="inf2" />
              </ContainerFields>
              <ContainerFields field="T100">
                <span>Informação 3:</span>
                <InputComum autoFocus name="inf3" />
              </ContainerFields>
              <ContainerFields field="T100">
                <span>Informação 4:</span>
                <InputComum autoFocus name="inf4" />
              </ContainerFields>
              <ContainerFields field="T100">
                <span>Informação 5:</span>
                <InputComum autoFocus name="inf5" className="lastField" />
              </ContainerFields>
              <ContainerFields field="id" className="lastField">
                <InputComum name="id" hidden />
              </ContainerFields>
            </FormFields>
          </FormContainer>
        </Form>
      </TabContent>

      <FormModalDialogConfirm
        open={openDeleteSingle}
        onClose={() => setOpenDeleteSingle(false)}
        onConfirm={() => handleOnRowDeleteSingle()}
        buttonCancelText="Cancelar"
        buttonConfirmText="Sim"
        formTitle="Excluir?"
        messageConfirm="Tem certeza que deseja excluir?"
      />

      <FormModalDialogConfirm
        open={openDeleteMultiple}
        onClose={() => setOpenDeleteMultiple(false)}
        onConfirm={() => handleOnRowDeleteMultiple()}
        buttonCancelText="Cancelar"
        buttonConfirmText="Sim"
        formTitle="Excluir?"
        messageConfirm="Tem certeza que deseja excluir todos os registros selecionados?"
      />

      <ModalUploadFile
        open={openUploadFile}
        onClose={onUploadFileClose}
        apiSource={`/demgerfolha/file/${user.license.id}?cnpj_estab=${user.empresa.cnpj}`}
        apiSourceFileExample="/demgerfolha/file/example"
      />

      <Backdrop className={cssLoading.backdrop} open={openLoading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </Container>
  );
};

export default DemGerFolha;
