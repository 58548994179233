import React, { useRef, useState, useEffect, useCallback } from 'react';
import { AxiosError } from 'axios';
import * as Yup from 'yup';

import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';

import { DataGrid, GridColDef, GridCellParams, GridRowId, ptBR } from '@material-ui/data-grid';
import { createMuiTheme, ThemeProvider, makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import ButtonDialog from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Checkbox from '@material-ui/core/Checkbox';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';

import SettingsIcon from '@material-ui/icons/Settings';
import { FiCopy } from 'react-icons/fi';
import { MdDelete, MdEdit, MdClear, MdSkipNext, MdSkipPrevious, MdContentCopy } from 'react-icons/md';
import {
  AiOutlinePlus,
  AiOutlineSave,
  AiOutlineSearch,
  AiOutlineCopy,
  AiFillFastBackward,
  AiFillFastForward,
  AiOutlineCaretDown,
  AiOutlineCaretUp,
} from 'react-icons/ai';
import { GoPlus } from 'react-icons/go';
import { RiDeleteBinLine } from 'react-icons/ri';

import InputComum from '../../components/InputComum';
import Select from '../../components/Select';
import LookupDemGer from '../../components/LookupDemGer';
import Header from '../../components/Header';
import FormModalDialogConfirm from '../../components/FormModalDialogConfirm';
import DemGerRelacManutencao from '../../components/Modal/DemGerRelacManutencao';

import api from '../../services/api';
import { useAuth } from '../../hooks/Auth';
import { useToast } from '../../hooks/Toast';
import { useLookup } from '../../hooks/Lookup/index';

import getListUpdate from '../../utils/getListUpdate';
import getValidationErrors from '../../utils/getValidationErrors';
import { getPathGrid } from '../../utils/storagePath';

import {
  ColumnTotalizador,
  ColumnCodCCustoI100,
  ColumnCodCtaI050,
  ColumnDescComplCta,
  ColumnNomeAuditorCta,
  ColumnNomeGestorCta,
  ColumnTPClassifDRE,
  ColumnObservacao,
  ColumnInf1,
  ColumnInf2,
  ColumnInf3,
  ColumnInf4,
  ColumnInf5,
  ColumnPercentRelacTot,
  ColumnId,
} from './GridColumns';

import ITipoDemGer from './ITipoDemGer';
import IDemGerRelac from './IDemGerRelac';
import IDemGer from './IDemGer';
import ICompany from './ICompany';

import {
  Container,
  ContainerFields,
  ContainerGroup,
  Tab,
  TabButton,
  TabContent,
  FormFields,
  FormContainer,
  FormNavigate,
  ContainerFilterSelect,
  SpanFiltro,
  DivGrupoContasCopy,
  TabTitulo,
  TabButtonArea,
  TabButtonNovo,
  TabButtonDelete,
  TabButtonGridRowHeight,
} from './styles';

const useStylesLoading = makeStyles((in_theme: Theme) =>
  createStyles({
    backdrop: {
      zIndex: in_theme.zIndex.drawer + 1700,
      color: '#fff',
    },
  }),
);

const theme = createMuiTheme(
  {
    palette: {
      primary: { main: '#006699' },
    },
  },
  ptBR,
);

const options_tp_classif_dre = [
  { value: '00 - Não Influencia', label: '00 - Não Influencia' },
  { value: '01-Receitas', label: '01-Receitas' },
  { value: '02-Deduções Receita', label: '02-Deduções Receita' },
  {
    value: '03-Custo Produtos/Mercadorias',
    label: '03-Custo Produtos/Mercadorias',
  },
  { value: '04-Custo Fixo', label: '04-Custo Fixo' },
  { value: '05-Custo Variável', label: '05-Custo Variável' },
  { value: '06-Despesa Fixa', label: '06-Despesa Fixa' },
  { value: '07-Despesa Variável', label: '07-Despesa Variável' },
  { value: '08-Receita Financeira', label: '08-Receita Financeira' },
  { value: '09-Despesa Financeira', label: '09-Despesa Financeira' },
  { value: '98-Outras Receitas', label: '98-Outras Receitas' },
  { value: '99-Outras Despesas', label: '99-Outras Despesas' },
];

const options_cod_nat = [
  { value: '01-Contas de Ativo', label: '01-Contas de Ativo' },
  { value: '02-Contas de Passivo', label: '02-Contas de Passivo' },
  { value: '03-Patrimônio Líquido', label: '03-Patrimônio Líquido' },
  { value: '04-Contas de Resultado', label: '04-Contas de Resultado' },
  { value: '05-Contas de Compensação', label: '05-Contas de Compensação' },
  { value: '09-Outras', label: '09-Outras' },
];

const emptyDemGerRelac: IDemGerRelac = {
  acao: '',
  id: '',
  id_dem_ger: '',
  id_license: '',
  cnpj_estab_0000: '',
  cod_ccusto_i100: '',
  cod_cta_i050: '',
  desc_compl_cta: '',
  nome_auditor_cta: '',
  nome_gestor_cta: '',
  tp_classif_dre: '00 - Não Influencia',
  obs: '',
  inf1: '',
  inf2: '',
  inf3: '',
  inf4: '',
  inf5: '',
  percent_relac_tot: 0,
  totalizador: '',
};

const fieldIdSelectTpClassifDRE = 'rc234345ftbug23h4yn6im';
const fieldIdSelectDemGer = '564rf499921klmscdv812o';
const fieldFilterIdSelect = '6sasa8903n34n342';
const fieldCopyIdSelect = '216sdf645665dsafi4i80p';
const titulo = 'R E L A C I O N A M E N T O S';

const DemGerRelac: React.FC = () => {
  const { user } = useAuth();
  const { addToast } = useToast();
  const { getListDemGer, updateListDemGer } = useLookup();
  const [openLoading, setOpenLoading] = React.useState(false);
  const cssLoading = useStylesLoading();
  const formRef = useRef<FormHandles>(null);
  const [listTipoDemGer, setListTipoDemGer] = useState<ITipoDemGer[]>([]);
  const [demGerRelac, setDemGerRelac] = useState<IDemGerRelac>();
  const [listDemGerRelac, setListDemGerRelac] = useState<IDemGerRelac[]>([]);
  const [listDemGer, setListDemGer] = useState<IDemGer[]>([]);
  const [listCompaniesFrom, setListCompaniesFrom] = useState<ICompany[]>([]);
  const [hiddenPesquisar, setHiddenPesquisar] = useState<boolean>(true);
  const [hiddenCadastrar, setHiddenCadastrar] = useState<boolean>(true);
  const [demGerRelacIndex, setDemGerRelacIndex] = useState<number>(0);
  const [rowToEdit, setRowToEdit] = useState(null);
  const [idDeleteDemGerRelac, setIdDeleteDemGerRelac] = useState<string>('');
  const [idTipoDemGerPesquisar, setIdTipoDemGerPesquisar] = useState<string>('');
  const [selectedIds, setSelectedIds] = useState<GridRowId[]>([]);
  const [openDeleteSingle, setOpenDeleteSingle] = useState(false);
  const [openDeleteMultiple, setOpenDeleteMultiple] = useState(false);
  const [gridRowHeight, setGridRowHeight] = useState<number>(46);
  const [openFilterTipoDemGer, setOpenFilterTipoDemGer] = React.useState(true);
  const [openCopyForm, setOpenOpenCopyForm] = React.useState(false);
  const [textFiltro, setTextFiltro] = useState<string>('');
  const [copyGrupoContas, setCopyGrupoContas] = useState<string[]>([]);
  const [openModalUpdate, setOpenModalUpdate] = useState<boolean>(false);

  const handleGridRowHeightUp = useCallback(async () => {
    const heightValue: number = gridRowHeight + 1;
    setGridRowHeight(heightValue);
    localStorage.setItem(getPathGrid(), heightValue.toString());
  }, [gridRowHeight]);

  const handleGridRowHeightDown = useCallback(async () => {
    const heightValue: number = gridRowHeight - 1;
    setGridRowHeight(heightValue);
    localStorage.setItem(getPathGrid(), heightValue.toString());
  }, [gridRowHeight]);

  const licencaInvalida = useCallback((): boolean => {
    if (!user.license || user.license.id === '') {
      addToast({
        type: 'error',
        title: 'Licença inválida',
        description: 'Usuário não está vinculado a nenhuma licença',
      });

      return true;
    }

    return false;
  }, [addToast, user]);

  const defineDemGerRelac = useCallback(async (data: IDemGerRelac) => {
    setDemGerRelac(data);
    formRef.current?.setData(data);
  }, []);

  const onNewRegister = useCallback(async () => {
    defineDemGerRelac(emptyDemGerRelac);
  }, [defineDemGerRelac]);

  const OnGridSetRegisterToEdit = useCallback(
    async (params: GridCellParams) => {
      const localDemGerRelac = params.row as IDemGerRelac;

      setDemGerRelacIndex(listDemGerRelac.indexOf(localDemGerRelac));
      setHiddenCadastrar(false);
      setHiddenPesquisar(true);
      setRowToEdit(null);
      defineDemGerRelac(localDemGerRelac);
    },
    [listDemGerRelac, defineDemGerRelac],
  );

  const OnSetRegisterToAdd = useCallback(async () => {
    onNewRegister();
    setHiddenCadastrar(false);
    setHiddenPesquisar(true);
  }, [onNewRegister]);

  const OnGridRowDuplicate = useCallback(
    async (baseDemGerRelac: IDemGerRelac | undefined) => {
      if (baseDemGerRelac !== undefined) {
        const newDemGerRelac: IDemGerRelac = {
          acao: baseDemGerRelac.acao,
          id: '',
          cnpj_estab_0000: baseDemGerRelac.cnpj_estab_0000,
          cod_ccusto_i100: baseDemGerRelac.cod_ccusto_i100,
          cod_cta_i050: baseDemGerRelac.cod_cta_i050,
          desc_compl_cta: baseDemGerRelac.desc_compl_cta,
          nome_auditor_cta: baseDemGerRelac.nome_auditor_cta,
          nome_gestor_cta: baseDemGerRelac.nome_gestor_cta,
          tp_classif_dre: baseDemGerRelac.tp_classif_dre,
          id_dem_ger: baseDemGerRelac.id_dem_ger,
          id_license: baseDemGerRelac.id_license,
          obs: baseDemGerRelac.obs,
          inf1: baseDemGerRelac.inf1,
          inf2: baseDemGerRelac.inf2,
          inf3: baseDemGerRelac.inf3,
          inf4: baseDemGerRelac.inf4,
          inf5: baseDemGerRelac.inf5,
          percent_relac_tot: baseDemGerRelac.percent_relac_tot,
          totalizador: baseDemGerRelac.totalizador,
        };

        defineDemGerRelac(newDemGerRelac);
        setHiddenCadastrar(false);
        setHiddenPesquisar(true);
      }
    },
    [defineDemGerRelac],
  );

  const OnConfirmationDeleteSingle = useCallback(
    async (id: string) => {
      if (licencaInvalida()) return;

      if (id === '') return;

      setIdDeleteDemGerRelac(id);
      setOpenDeleteSingle(true);
    },
    [licencaInvalida],
  );

  const colunaAcao: GridColDef = {
    field: 'acao',
    headerName: 'Ação',
    editable: false,
    filterable: false,
    hideSortIcons: true,
    sortable: false,
    disableColumnMenu: true,
    width: 160,
    renderCell: (params: GridCellParams) => (
      <strong>
        <Tooltip title="Editar Registro" placement="top">
          <IconButton color="primary" aria-label="Editar" onClick={() => OnGridSetRegisterToEdit(params)}>
            <MdEdit />
          </IconButton>
        </Tooltip>

        <Tooltip title="Duplicar Registro" placement="top">
          <IconButton
            color="primary"
            aria-label="Duplicar"
            onClick={() => OnGridRowDuplicate(params.row as IDemGerRelac)}
          >
            <FiCopy />
          </IconButton>
        </Tooltip>

        <Tooltip title="Excluir Registro" placement="top">
          <IconButton
            color="primary"
            aria-label="Excluir"
            onClick={() => OnConfirmationDeleteSingle((params.row as IDemGerRelac).id)}
          >
            <MdDelete />
          </IconButton>
        </Tooltip>
      </strong>
    ),
  };

  const columns: GridColDef[] = [
    colunaAcao,
    ColumnTotalizador,
    ColumnCodCtaI050,
    ColumnCodCCustoI100,
    ColumnPercentRelacTot,
    ColumnTPClassifDRE,
    ColumnDescComplCta,
    ColumnNomeGestorCta,
    ColumnNomeAuditorCta,
    ColumnObservacao,
    ColumnInf1,
    ColumnInf2,
    ColumnInf3,
    ColumnInf4,
    ColumnInf5,
    ColumnId,
  ];

  const handleCloseFormCopy = useCallback(() => {
    setOpenOpenCopyForm(false);
  }, []);

  const handleCloseFilterTipoDemGer = useCallback(() => {
    setOpenFilterTipoDemGer(false);
  }, []);

  const getDescricaoDemGerSelectOption = useCallback((value: IDemGer): string => {
    if (!value) return '';

    return `${value.cod_nat.substring(0, 3)}
      ${value.classif_tot.trim()}-${value.nome_tot.trim()}`;
  }, []);

  const getDemGerSelectOption = useCallback(
    (id: string): IDemGer => {
      return listDemGer.filter(item => item.id === id)[0];
    },
    [listDemGer],
  );

  const getCompany = useCallback(
    async (isLoadingActive: boolean) => {
      if (!isLoadingActive) setOpenLoading(true);
      const response = await api.get<ICompany[]>(`/company/${user.license.id}`);
      setListCompaniesFrom(response.data);

      if (!isLoadingActive) setOpenLoading(false);
    },
    [user],
  );

  const getTipoDemGer = useCallback(async () => {
    if (licencaInvalida()) return '';
    setOpenLoading(true);
    const response = await api.get<ITipoDemGer[]>(`/tipodemger/all/company/${user.empresa.id}`);

    setListTipoDemGer(response.data);
    setOpenLoading(false);
    if (response.data[0]) {
      setIdTipoDemGerPesquisar(response.data[0].id);
      setTextFiltro(`   Filtrado por: ${response.data[0].codigo.trim()}-${response.data[0].descricao.trim()}`);

      return response.data[0].id;
    }

    return '';
  }, [user, licencaInvalida]);

  const getDemGer = useCallback(
    async (id: string, isLoadingActive: boolean) => {
      if (!isLoadingActive) setOpenLoading(true);
      const response = await api.get<IDemGer[]>(`/demger/company/all/${user.license.id}`, {
        params: {
          cnpj: `'${user.empresa.cnpj}'`,
          id_tipo_dem_ger: `'${id}'`,
        },
      });

      setListDemGer(response.data);
      if (!isLoadingActive) setOpenLoading(false);
    },
    [user],
  );

  const getDemGerRelac = useCallback(
    async (id: string, listDemGerIDs: string[]) => {
      if (licencaInvalida()) return;

      if (id === '') return;
      setOpenLoading(true);

      await getCompany(true);
      await getDemGer(id, true);

      const response = await api.get<IDemGerRelac[]>(`/demgerrelac/all`, {
        params: {
          cnpj: `'${user.empresa.cnpj}'`,
          id_tipo_dem_ger: `'${id}'`,
          id_dem_ger: listDemGerIDs.join(),
        },
      });

      setListDemGerRelac(response.data);
      setOpenLoading(false);
      if (rowToEdit !== null) {
        const findEditDemGerRelac = response.data.find(item => item.id === rowToEdit);

        if (findEditDemGerRelac) {
          defineDemGerRelac(findEditDemGerRelac);
          setDemGerRelacIndex(response.data.indexOf(findEditDemGerRelac));
          setHiddenCadastrar(false);
          setHiddenPesquisar(true);
          setRowToEdit(null);
        }
      }

      setHiddenPesquisar(false);
    },
    [user, licencaInvalida, rowToEdit, defineDemGerRelac, getDemGer, getCompany],
  );

  const getValues = useCallback(async () => {
    setOpenLoading(true);

    await getTipoDemGer();

    setOpenLoading(false);
  }, [getTipoDemGer]);

  useEffect(() => {
    getValues();

    const rowHeight = localStorage.getItem(getPathGrid());
    if (rowHeight) {
      setGridRowHeight(parseInt(rowHeight, 10));
    }
  }, [getValues]);

  const handleOnRowDeleteSingle = useCallback(async () => {
    setOpenLoading(true);
    await api.delete(`/demgerrelac/${idDeleteDemGerRelac}`);

    setListDemGerRelac(oldDemGerRelac => oldDemGerRelac.filter(item => item.id !== idDeleteDemGerRelac));

    defineDemGerRelac(emptyDemGerRelac);

    addToast({
      type: 'success',
      title: 'Removido',
      description: 'Registro excluído com sucesso!',
    });

    setOpenDeleteSingle(false);
    setOpenLoading(false);
  }, [idDeleteDemGerRelac, addToast, defineDemGerRelac]);

  const handleOnRowDeleteMultiple = useCallback(async () => {
    setOpenLoading(true);
    if (selectedIds.length > 0) {
      await Promise.all(
        selectedIds.map(itemId => {
          setListDemGerRelac(oldDemGerRelac => oldDemGerRelac.filter(item => item.id !== itemId));

          return api.delete(`/demgerrelac/${itemId}`);
        }),
      );

      defineDemGerRelac(emptyDemGerRelac);

      addToast({
        type: 'success',
        title: 'Removido',
        description: 'Registro excluído com sucesso!',
      });
    }

    setSelectedIds([]);
    setOpenDeleteMultiple(false);
    setOpenLoading(false);
  }, [addToast, defineDemGerRelac, selectedIds]);

  const handleOnCopyDemGerRelac = useCallback(async () => {
    const fieldSelect = document.getElementById(fieldCopyIdSelect);

    if (fieldSelect) {
      const id = (fieldSelect as HTMLSelectElement).value;
      const companySelect = listCompaniesFrom.filter(item => item.id === id);

      const formData = {
        id_license: user.license.id,
        cnpjOrigin: companySelect[0].cnpj,
        idCompanyOrigin: companySelect[0].id,
        cnpjDestiny: user.empresa.cnpj,
        idCompanyDestiny: user.empresa.id,
        cod_nat: copyGrupoContas,
      };

      setOpenLoading(true);
      await api.post(`/demgerrelac/copy`, formData);

      const localListDemGer = getListDemGer();
      const list = localListDemGer.map(itemDemGer => {
        return `'${itemDemGer.id}'`;
      });
      setOpenLoading(false);
      await getDemGerRelac(idTipoDemGerPesquisar, list);

      addToast({
        type: 'success',
        title: 'Copiado',
        description: 'Registro copiado com sucesso!',
      });
    } else {
      addToast({
        type: 'error',
        title: 'Erro ao copiar',
        description: 'Não foi possível realizar a cópia!',
      });
    }

    setOpenOpenCopyForm(false);
  }, [addToast, user, listCompaniesFrom, idTipoDemGerPesquisar, getDemGerRelac, copyGrupoContas, getListDemGer]);

  const handleClick = useCallback(
    (id: string): void => {
      if (id === 'filtro') {
        updateListDemGer([]);
        setOpenFilterTipoDemGer(id === 'filtro');
        return;
      }

      if (id === 'button_update_values') {
        setOpenModalUpdate(id === 'button_update_values');
        return;
      }

      if (id === 'copiar') {
        const grupoContas = options_cod_nat.map(item => `'${item.value}'`);
        setCopyGrupoContas(grupoContas);

        setOpenOpenCopyForm(id === 'copiar');

        return;
      }

      setHiddenCadastrar(id === 'pesquisar');
      setHiddenPesquisar(id === 'cadastrar');
    },
    [updateListDemGer],
  );

  const handleFilterSelectDemonstrativoChange = (event: React.ChangeEvent<{ value: unknown }>): void => {
    const fieldSelect = document.getElementById(fieldFilterIdSelect);
    if (fieldSelect) {
      const id = (fieldSelect as HTMLSelectElement).value;
      setIdTipoDemGerPesquisar(id);
      return;
    }

    setIdTipoDemGerPesquisar('');
  };

  const handleOnFilter = useCallback(async () => {
    setOpenFilterTipoDemGer(false);
    const localListDemGer = getListDemGer();
    const list = localListDemGer.map(itemDemGer => {
      return `'${itemDemGer.id}'`;
    });

    if (idTipoDemGerPesquisar.trim() !== '') {
      setOpenLoading(true);
      await getDemGerRelac(idTipoDemGerPesquisar, list);

      const findTipoDemGer = listTipoDemGer.find(item => idTipoDemGerPesquisar === item.id);

      if (findTipoDemGer) {
        setTextFiltro(`   Filtrado por: ${findTipoDemGer.codigo.trim()}-${findTipoDemGer.descricao.trim()}`);
      }

      setOpenLoading(false);
    }

    handleClick('pesquisar');
  }, [getDemGerRelac, listTipoDemGer, handleClick, getListDemGer, idTipoDemGerPesquisar]);

  const onNextRegister = useCallback(async () => {
    if (demGerRelacIndex !== undefined) {
      if (demGerRelacIndex < listDemGerRelac.length - 1) {
        const localIndex = demGerRelacIndex + 1;
        setDemGerRelacIndex(localIndex);
        const findDemGerRelac = listDemGerRelac[localIndex];
        if (findDemGerRelac) {
          defineDemGerRelac(findDemGerRelac);
        }
      }
    }
  }, [demGerRelacIndex, listDemGerRelac, defineDemGerRelac]);

  const onFirstRegister = useCallback(async () => {
    if (listDemGerRelac.length > 0) {
      setDemGerRelacIndex(0);
      const findDemGerRelac = listDemGerRelac[0];
      if (findDemGerRelac) {
        defineDemGerRelac(findDemGerRelac);
      }
    }
  }, [listDemGerRelac, defineDemGerRelac]);

  const onLastRegister = useCallback(async () => {
    if (listDemGerRelac.length > 0) {
      setDemGerRelacIndex(listDemGerRelac.length - 1);
      const findDemGerRelac = listDemGerRelac[listDemGerRelac.length - 1];
      if (findDemGerRelac) {
        defineDemGerRelac(findDemGerRelac);
      }
    }
  }, [listDemGerRelac, defineDemGerRelac]);

  const onPriorRegister = useCallback(async () => {
    if (demGerRelacIndex !== undefined) {
      if (demGerRelacIndex > 0) {
        const localIndex = demGerRelacIndex - 1;
        setDemGerRelacIndex(localIndex);
        const findDemGerRelac = listDemGerRelac[localIndex];
        if (findDemGerRelac) {
          defineDemGerRelac(findDemGerRelac);
        }
      }
    }
  }, [demGerRelacIndex, listDemGerRelac, defineDemGerRelac]);

  const onCancelRegister = useCallback(async () => {
    formRef.current?.setErrors({});

    if (demGerRelacIndex !== undefined) {
      const findDemGerRelac = listDemGerRelac[demGerRelacIndex];
      if (findDemGerRelac) {
        defineDemGerRelac(findDemGerRelac);
      }
    }

    onNewRegister();
  }, [demGerRelacIndex, listDemGerRelac, onNewRegister, defineDemGerRelac]);

  const handleSubmit = useCallback(
    async (data: IDemGerRelac, { reset }) => {
      if (licencaInvalida()) return;

      const fieldSelect = document.getElementById(fieldIdSelectDemGer);
      if (fieldSelect) {
        const id = (fieldSelect as HTMLSelectElement).value;
        data.id_dem_ger = id;
        data.totalizador = getDescricaoDemGerSelectOption(getDemGerSelectOption(id));
      }

      formRef.current?.setErrors({});
      try {
        const findConta = listDemGerRelac.find(
          item =>
            item.cod_cta_i050 === data.cod_cta_i050 &&
            item.id_dem_ger === data.id_dem_ger &&
            item.cod_ccusto_i100 === data.cod_ccusto_i100 &&
            item.id !== data.id,
        );
        const isDuplicated = !!findConta;

        const schema = Yup.object().shape({
          id_dem_ger: Yup.string().required('Demonstrativo é obrigatório'),
          cod_cta_i050: Yup.string()
            .required('Código da conta é obrigatório')
            .test('conta-is-valid', 'Já existe uma conta com o mesmo totalizador e centro de custo', value => {
              if (isDuplicated) {
                return false;
              }

              return true;
            }),
          cod_ccusto_i100: Yup.string()
            .nullable()
            .notRequired()
            .test('ccusto-is-valid', 'Já existe uma conta com o mesmo totalizador e centro de custo', value => {
              if (isDuplicated) {
                return false;
              }

              return true;
            }),
          tp_classif_dre: Yup.string().required('Classificação é obrigatória'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        const formData: IDemGerRelac = {
          id_license: user.license.id,
          cnpj_estab_0000: user.empresa.cnpj,
          id: data.id,
          id_dem_ger: data.id_dem_ger,
          cod_cta_i050: data.cod_cta_i050,
          cod_ccusto_i100: data.cod_ccusto_i100,
          desc_compl_cta: data.desc_compl_cta ? data.desc_compl_cta : '',
          nome_gestor_cta: data.nome_gestor_cta ? data.nome_gestor_cta : '',
          nome_auditor_cta: data.nome_auditor_cta ? data.nome_auditor_cta : '',
          tp_classif_dre: data.tp_classif_dre,
          obs: data.obs ? data.obs : '',
          inf1: data.inf1 ? data.inf1 : '',
          inf2: data.inf2 ? data.inf2 : '',
          inf3: data.inf3 ? data.inf3 : '',
          inf4: data.inf4 ? data.inf4 : '',
          inf5: data.inf5 ? data.inf5 : '',
          percent_relac_tot: data.percent_relac_tot ? data.percent_relac_tot : 0,
          acao: '',
          totalizador: data.totalizador,
        };

        if (data.id === '') {
          setOpenLoading(true);
          const response = await api.post(`/demgerrelac`, formData);
          formData.id = response.data.id;

          setListDemGerRelac(oldDemGerRelac => [...oldDemGerRelac, formData]);
          setOpenLoading(false);
          addToast({
            type: 'success',
            title: 'Adicionado',
            description: 'Registro adicionado com sucesso!',
          });
        } else {
          setOpenLoading(true);
          await api.put(`/demgerrelac/${data.id}`, data);
          setOpenLoading(false);
          data.totalizador = getDescricaoDemGerSelectOption(getDemGerSelectOption(data.id_dem_ger));
          setListDemGerRelac(getListUpdate(listDemGerRelac, data));

          addToast({
            type: 'success',
            title: 'Alterado',
            description: 'Registro alterado com sucesso!',
          });
        }

        setOpenLoading(true);
        const responseSum = await api.get(`/demgerrelac/soma`, {
          params: {
            id_license: user.license.id,
            id_dem_ger: data.id_dem_ger,
            cnpj: user.empresa.cnpj,
          },
        });
        setOpenLoading(false);

        if (responseSum.data > 100) {
          addToast({
            type: 'warning',
            title: 'A soma do percentual é superior a 100%',
            description: `A soma do percentual nas contas para o totalizador é superior a 100% [soma ${responseSum.data}%]`,
          });
        }

        if (responseSum.data > 0 && responseSum.data < 100) {
          addToast({
            type: 'warning',
            title: 'A soma do percentual é diferente de 100%',
            description: `A soma do percentual nas contas para o totalizador não corresponde a 100% [soma ${responseSum.data}%]`,
          });
        }

        reset();
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          formRef.current?.setErrors(errors);
        }

        setOpenLoading(false);
      }
    },
    [licencaInvalida, listDemGerRelac, user, addToast, getDescricaoDemGerSelectOption, getDemGerSelectOption],
  );

  const handleChangeCheckboxCopyGrupoContas = (event: React.ChangeEvent<HTMLInputElement>): void => {
    let list: string[] = [];
    if (event.target.checked) {
      list = copyGrupoContas;
      list.push(`'${event.target.id}'`);
    } else {
      list = copyGrupoContas.filter(item => item !== `'${event.target.id}'`);
    }

    setCopyGrupoContas(list);
  };

  const onCloseModalUpdate = useCallback(async () => {
    setOpenModalUpdate(false);
  }, []);

  const onConfirmModalUpdate = useCallback(
    async (inFieldName: string, inValue: string) => {
      const listUpdates = selectedIds.map(itemId => {
        return {
          id_license: user.license.id,
          id: itemId,
          valueString: inValue,
          typeValue: 1,
          field: inFieldName,
          action: 1,
        };
      });
      try {
        await api.post(`/demgerrelac/updatefields`, { listUpdates });
        addToast({
          type: 'success',
          title: 'Alterado',
          description: 'Registro alterado com sucesso!',
        });
        setSelectedIds([]);
        handleOnFilter();
      } catch (error) {
        const err = error as AxiosError;
        const localMessage =
          err.response && err.response.data && err.response.data.msg && err.response.data.msg.message
            ? err.response.data.msg.message
            : 'Não foi possível executar a atualização';

        addToast({
          type: 'error',
          title: localMessage,
        });
      }

      setOpenModalUpdate(false);
    },
    [selectedIds, addToast, user, handleOnFilter],
  );

  return (
    <Container>
      <Header />

      <Tab>
        <TabButtonArea>
          <TabButton id="pesquisar" isActive={!hiddenPesquisar} onClick={() => handleClick('pesquisar')}>
            Listagem
          </TabButton>
          <TabButton id="cadastrar" isActive={!hiddenCadastrar} onClick={() => handleClick('cadastrar')}>
            Cadastro
          </TabButton>
          <Tooltip title="Reduzir o tamanho da linha" placement="top">
            <TabButtonGridRowHeight id="gridrowheightdown" onClick={() => handleGridRowHeightDown()}>
              <AiOutlineCaretDown />
            </TabButtonGridRowHeight>
          </Tooltip>

          <Tooltip title="Aumentar o tamanho da linha" placement="top">
            <TabButtonGridRowHeight id="gridrowheightup" onClick={() => handleGridRowHeightUp()}>
              <AiOutlineCaretUp />
            </TabButtonGridRowHeight>
          </Tooltip>
          <TabButton id="filtro" isActive={false} onClick={() => handleClick('filtro')}>
            <AiOutlineSearch />
          </TabButton>
          <TabButton id="copiar" isActive={false} onClick={() => handleClick('copiar')}>
            <AiOutlineCopy />
          </TabButton>

          <Tooltip title="Manutenção dos registros selecionados" placement="top">
            <span>
              <span hidden={selectedIds.length <= 0}>
                <TabButton
                  id="button_update_values"
                  isActive={false}
                  onClick={() => handleClick('button_update_values')}
                >
                  <SettingsIcon />
                </TabButton>
              </span>
            </span>
          </Tooltip>

          <Tooltip title="Novo Registro" placement="top">
            <TabButtonNovo id="new_register" onClick={() => OnSetRegisterToAdd()}>
              <GoPlus />
              Novo
            </TabButtonNovo>
          </Tooltip>

          <Tooltip title="Remover Registros Selecionados" placement="top">
            <span>
              <span hidden={selectedIds.length <= 0}>
                <TabButtonDelete
                  id="remove_all"
                  onClick={() => {
                    setIdDeleteDemGerRelac('');
                    setOpenDeleteMultiple(true);
                  }}
                >
                  <RiDeleteBinLine />
                  Remover
                </TabButtonDelete>
              </span>
            </span>
          </Tooltip>
        </TabButtonArea>

        <TabTitulo>
          <span>{titulo}</span>
        </TabTitulo>
      </Tab>
      <SpanFiltro>{textFiltro}</SpanFiltro>

      <TabContent id="pesquisar" isActive={!hiddenPesquisar}>
        <div style={{ height: '85vh', width: '100%' }}>
          <ThemeProvider theme={theme}>
            <DataGrid
              rows={listDemGerRelac}
              columns={columns}
              rowHeight={gridRowHeight}
              checkboxSelection
              selectionModel={selectedIds}
              disableSelectionOnClick
              onSelectionModelChange={e => setSelectedIds(e.selectionModel)}
            />
          </ThemeProvider>
        </div>
      </TabContent>

      <TabContent id="cadasto" isActive={!hiddenCadastrar}>
        <Form ref={formRef} onSubmit={handleSubmit} initialData={demGerRelac}>
          <FormContainer>
            <FormNavigate>
              <Tooltip title="Novo Registro" placement="top">
                <IconButton color="primary" aria-label="adicionar" onClick={() => onNewRegister()}>
                  <AiOutlinePlus />
                </IconButton>
              </Tooltip>

              <Tooltip title="Duplicar Registro" placement="top">
                <IconButton color="primary" aria-label="Duplicar" onClick={() => OnGridRowDuplicate(demGerRelac)}>
                  <MdContentCopy />
                </IconButton>
              </Tooltip>

              <Tooltip title="Excluir Registro" placement="top">
                <IconButton
                  color="primary"
                  aria-label="Excluir"
                  onClick={() => OnConfirmationDeleteSingle(demGerRelac ? demGerRelac.id : '')}
                >
                  <RiDeleteBinLine />
                </IconButton>
              </Tooltip>

              <Tooltip title="Cancelar" placement="top">
                <IconButton type="reset" color="primary" aria-label="Cancelar" onClick={() => onCancelRegister()}>
                  <MdClear />
                </IconButton>
              </Tooltip>

              <Tooltip title="Salvar" placement="top">
                <IconButton type="submit" color="primary" aria-label="Confirmar">
                  <AiOutlineSave />
                </IconButton>
              </Tooltip>

              <Tooltip title="Primeiro Registro" placement="top">
                <IconButton color="primary" aria-label="Primeiro" onClick={() => onFirstRegister()}>
                  <AiFillFastBackward />
                </IconButton>
              </Tooltip>

              <Tooltip title="Registro Anterior" placement="top">
                <IconButton color="primary" aria-label="Anterior" onClick={() => onPriorRegister()}>
                  <MdSkipPrevious />
                </IconButton>
              </Tooltip>

              <Tooltip title="Próximo Registro" placement="top">
                <IconButton color="primary" aria-label="Próximo" onClick={() => onNextRegister()}>
                  <MdSkipNext />
                </IconButton>
              </Tooltip>

              <Tooltip title="Último Registro" placement="top">
                <IconButton color="primary" aria-label="Último registro" onClick={() => onLastRegister()}>
                  <AiFillFastForward />
                </IconButton>
              </Tooltip>
            </FormNavigate>

            <FormFields>
              <ContainerFields field="T100">
                <span>Totalizador:</span>
                <Select id={fieldIdSelectDemGer} name="id_dem_ger" defaultValue={idTipoDemGerPesquisar}>
                  {listDemGer.map(value => (
                    <option key={value.id} value={value.id}>
                      {getDescricaoDemGerSelectOption(value)}
                    </option>
                  ))}
                </Select>
              </ContainerFields>

              <ContainerGroup>
                <ContainerFields field="T200px">
                  <span>Conta:</span>
                  <InputComum autoFocus name="cod_cta_i050" />
                </ContainerFields>
                <ContainerFields field="T200px" className="price2">
                  <span>Centro custo:</span>
                  <InputComum autoFocus name="cod_ccusto_i100" />
                </ContainerFields>
                <ContainerFields field="T200px" className="price2">
                  <Tooltip title="Percentual de Participação no Totalizador no Orçamento" placement="top">
                    <span>Percentual de Participação:</span>
                  </Tooltip>

                  <InputComum name="percent_relac_tot" placeholder="0,0000" type="number" step="0.0001" autoFocus />
                </ContainerFields>
              </ContainerGroup>

              <ContainerFields field="T300px">
                <span>Tipo classificação DRE:</span>
                <Select id={fieldIdSelectTpClassifDRE} name="tp_classif_dre" defaultValue="00 - Não Influencia">
                  {options_tp_classif_dre.map(option => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </Select>
              </ContainerFields>
              <ContainerFields field="T100">
                <span>Descrição complementar conta:</span>
                <InputComum autoFocus name="desc_compl_cta" />
              </ContainerFields>
              <ContainerFields field="T100">
                <span>Nome gestor conta:</span>
                <InputComum autoFocus name="nome_gestor_cta" />
              </ContainerFields>
              <ContainerFields field="T100">
                <span>Nome auditor conta:</span>
                <InputComum autoFocus name="nome_auditor_cta" />
              </ContainerFields>
              <ContainerFields field="T100">
                <span>Observação:</span>
                <InputComum autoFocus name="obs" />
              </ContainerFields>
              <ContainerFields field="T100">
                <span>Informação 1:</span>
                <InputComum autoFocus name="inf1" />
              </ContainerFields>
              <ContainerFields field="T100">
                <span>Informação 2:</span>
                <InputComum autoFocus name="inf2" />
              </ContainerFields>
              <ContainerFields field="T100">
                <span>Informação 3:</span>
                <InputComum autoFocus name="inf3" />
              </ContainerFields>
              <ContainerFields field="T100">
                <span>Informação 4:</span>
                <InputComum autoFocus name="inf4" />
              </ContainerFields>
              <ContainerFields field="T100">
                <span>Informação 5:</span>
                <InputComum autoFocus name="inf5" className="lastField" />
              </ContainerFields>
              <ContainerFields field="TID" className="lastField">
                <InputComum name="id" hidden />
              </ContainerFields>
            </FormFields>
          </FormContainer>
        </Form>
      </TabContent>

      <Dialog
        open={openFilterTipoDemGer}
        onClose={handleCloseFilterTipoDemGer}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Filtro</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">Demonstrativo:</DialogContentText>
          <ContainerFields field="T300px" className="filterField">
            <ContainerFilterSelect>
              <select id={fieldFilterIdSelect} onChange={handleFilterSelectDemonstrativoChange}>
                {listTipoDemGer.map(option => (
                  <option key={option.id} value={option.id}>
                    {option.codigo.trim()}-{option.descricao.trim()}
                  </option>
                ))}
              </select>
            </ContainerFilterSelect>
          </ContainerFields>

          <DialogContentText id="alert-dialog-description">Totalizador:</DialogContentText>
          <ContainerFields field="T300px" className="filterField">
            <LookupDemGer inIdTipoDemGer={idTipoDemGerPesquisar} idInputLookup="hyb3fgasy33242" multiSelect />
          </ContainerFields>
        </DialogContent>
        <DialogActions>
          <ButtonDialog onClick={handleCloseFilterTipoDemGer} color="primary" autoFocus>
            Cancelar
          </ButtonDialog>
          <ButtonDialog onClick={handleOnFilter} color="primary">
            Filtrar
          </ButtonDialog>
        </DialogActions>
      </Dialog>

      <FormModalDialogConfirm
        open={openDeleteSingle}
        onClose={() => setOpenDeleteSingle(false)}
        onConfirm={() => handleOnRowDeleteSingle()}
        buttonCancelText="Cancelar"
        buttonConfirmText="Sim"
        formTitle="Excluir?"
        messageConfirm="Tem certeza que deseja excluir?"
      />

      <FormModalDialogConfirm
        open={openDeleteMultiple}
        onClose={() => setOpenDeleteMultiple(false)}
        onConfirm={() => handleOnRowDeleteMultiple()}
        buttonCancelText="Cancelar"
        buttonConfirmText="Sim"
        formTitle="Excluir?"
        messageConfirm="Tem certeza que deseja excluir todos os registros selecionados?"
      />

      <Dialog
        open={openCopyForm}
        onClose={handleCloseFormCopy}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle id="alert-dialog-title">Copiar Relacionamento</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">Empresa origem</DialogContentText>

          <ContainerFields field="T100">
            <ContainerFilterSelect>
              <select id={fieldCopyIdSelect}>
                {listCompaniesFrom.map(
                  value =>
                    value.id !== user.empresa.id && (
                      <option key={value.id} value={value.id}>
                        {value.code.trim()}-{value.name.trim()}[{value.cnpj.trim()}]
                      </option>
                    ),
                )}
              </select>
            </ContainerFilterSelect>
          </ContainerFields>

          <DivGrupoContasCopy>
            <DialogContentText id="alert-dialog-description">Grupo de contas</DialogContentText>

            <div>
              {options_cod_nat.map(item => (
                <label id={`label${item.value}`} htmlFor={item.value} key={item.value}>
                  <Checkbox
                    id={item.value}
                    key={item.value}
                    color="primary"
                    inputProps={{ 'aria-label': 'secondary checkbox' }}
                    onChange={handleChangeCheckboxCopyGrupoContas}
                    defaultChecked
                  />
                  {item.label}
                </label>
              ))}
            </div>
          </DivGrupoContasCopy>

          <DialogContentText />
          <DialogContentText id="alert-dialog-description">
            Empresa destino: {user.empresa.cnpj.trim()}-{user.empresa.name.trim()}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <ButtonDialog onClick={handleCloseFormCopy} color="primary" autoFocus>
            Cancelar
          </ButtonDialog>
          <ButtonDialog onClick={handleOnCopyDemGerRelac} color="primary">
            Copiar
          </ButtonDialog>
        </DialogActions>
      </Dialog>

      <Backdrop className={cssLoading.backdrop} open={openLoading}>
        <CircularProgress color="inherit" />
      </Backdrop>

      <DemGerRelacManutencao open={openModalUpdate} onClose={onCloseModalUpdate} onConfirm={onConfirmModalUpdate} />
    </Container>
  );
};

export default DemGerRelac;
