import React, { useCallback, useState } from 'react';

import Dialog from '@material-ui/core/Dialog';
import ButtonDialog from '@material-ui/core/Button';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

import { TitleDiv, ContainerFields, ContainerFilterSelect, ContainerDiv, ContainerFilterInput } from './styles';

type functionVoid = () => void;
type functionSummit = (inFieldName: string, inValue: string) => void;

interface IDemGerRelacManutencaoProps {
  open: boolean;
  onClose: functionVoid;
  onConfirm: functionSummit;
}

const options_tipo = [{ value: '1-Alterar', label: '1-Alterar' }];
const options_campos = [
  { value: 'desc_compl_cta', label: 'Descrição complementar conta' },
  { value: 'cod_cta_i050', label: 'Conta' },
  { value: 'cod_ccusto_i100', label: 'Centro de Custo' },
  { value: 'nome_gestor_cta', label: 'Nome gestor conta' },
  { value: 'nome_auditor_cta', label: 'Nome auditor conta' },
  { value: 'tp_classif_dre', label: 'Tipo classificação DRE' },
  { value: 'obs', label: 'Observação' },
  { value: 'inf1', label: 'Informação 1' },
  { value: 'inf2', label: 'Informação 2' },
  { value: 'inf3', label: 'Informação 3' },
  { value: 'inf4', label: 'Informação 4' },
  { value: 'inf5', label: 'Informação 5' },
];

const options_tp_classif_dre = [
  { value: '00 - Não Influencia', label: '00 - Não Influencia' },
  { value: '01-Receitas', label: '01-Receitas' },
  { value: '02-Deduções Receita', label: '02-Deduções Receita' },
  {
    value: '03-Custo Produtos/Mercadorias',
    label: '03-Custo Produtos/Mercadorias',
  },
  { value: '04-Custo Fixo', label: '04-Custo Fixo' },
  { value: '05-Custo Variável', label: '05-Custo Variável' },
  { value: '06-Despesa Fixa', label: '06-Despesa Fixa' },
  { value: '07-Despesa Variável', label: '07-Despesa Variável' },
  { value: '08-Receita Financeira', label: '08-Receita Financeira' },
  { value: '09-Despesa Financeira', label: '09-Despesa Financeira' },
  { value: '98-Outras Receitas', label: '98-Outras Receitas' },
  { value: '99-Outras Despesas', label: '99-Outras Despesas' },
];

const DemGerRelacManutencao: React.FC<IDemGerRelacManutencaoProps> = ({ onClose, onConfirm, open }) => {
  const [fieldNameSelected, setFieldNameSelected] = useState('desc_compl_cta');

  const handleClose = useCallback(() => {
    if (onClose) {
      onClose();
    }
  }, [onClose]);

  const handleSubmit = useCallback(() => {
    const fieldSelectTpDre = document.getElementById('dfg58769─dfg16');
    let localNewValueTpDre = '';
    if (fieldSelectTpDre) {
      localNewValueTpDre = (fieldSelectTpDre as HTMLSelectElement).value;
    }

    const fieldInputDescricao = document.getElementById('j234bi7');
    let localFieldNewValue = '';
    if (fieldInputDescricao) {
      localFieldNewValue = (fieldInputDescricao as HTMLInputElement).value;
    }

    if (onConfirm) {
      onConfirm(fieldNameSelected, fieldNameSelected === 'tp_classif_dre' ? localNewValueTpDre : localFieldNewValue);
    }
  }, [onConfirm, fieldNameSelected]);

  const handleSelectFieldChange = (event: React.ChangeEvent<{ value: unknown }>): void => {
    const id: string = event.target.value as string;
    setFieldNameSelected(id);
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <TitleDiv>
        <DialogTitle id="alert-dialog-title">Manutenção dos relacionamentos</DialogTitle>
      </TitleDiv>

      <DialogContent>
        <ContainerDiv>
          <ContainerFields field="T200px">
            <span>Ação</span>
            <ContainerFilterSelect>
              <select id="asjhdfy5" defaultValue="1-Alterar">
                {options_tipo.map(itemList => (
                  <option key={itemList.value} value={itemList.value}>
                    {itemList.label}
                  </option>
                ))}
              </select>
            </ContainerFilterSelect>
          </ContainerFields>

          <ContainerFields field="T300px" className="field2">
            <span>Executar para o campo</span>
            <ContainerFilterSelect>
              <select id="dfg5dfg16" defaultValue="desc_compl_cta" onChange={handleSelectFieldChange}>
                {options_campos.map(itemList => (
                  <option key={itemList.value} value={itemList.value}>
                    {itemList.label}
                  </option>
                ))}
              </select>
            </ContainerFilterSelect>
          </ContainerFields>
        </ContainerDiv>

        <ContainerFields field="T100">
          <span>Novo conteúdo</span>
          {fieldNameSelected !== 'tp_classif_dre' && (
            <ContainerFilterInput>
              <input id="j234bi7" required />
            </ContainerFilterInput>
          )}

          {fieldNameSelected === 'tp_classif_dre' && (
            <ContainerFilterSelect>
              <select id="dfg58769─dfg16" defaultValue="00 - Não Influencia">
                {options_tp_classif_dre.map(option => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </select>
            </ContainerFilterSelect>
          )}
        </ContainerFields>
      </DialogContent>

      <DialogActions>
        <ButtonDialog onClick={handleClose} color="secondary" autoFocus variant="contained">
          Cancelar
        </ButtonDialog>
        <ButtonDialog onClick={handleSubmit} color="primary" variant="contained">
          Executar
        </ButtonDialog>
      </DialogActions>
    </Dialog>
  );
};

export default DemGerRelacManutencao;
