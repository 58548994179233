import React from 'react';
import { useHistory } from 'react-router-dom';
import { Dashboard, BarChartSharp, PostAdd } from '@material-ui/icons';
import { ROUTE_DASHBOARD_POWERBI, ROUTE_INDICADOR_PERSON_LCTO } from '../../utils/constants';
import ButtonDropdownMenu from './ButtonDropdownMenu';
import { ContainerDropdown, DropdownContentUser } from './styles';

const MenuCostAnalysis: React.FC = () => {
  const history = useHistory();

  const handleGoDashboard = (): void => {
    history.push(ROUTE_DASHBOARD_POWERBI);
  };

  const handleGoIndicadoresPersonalizados = (): void => {
    history.push(ROUTE_INDICADOR_PERSON_LCTO);
  };

  return (
    <ContainerDropdown>
      <button type="button">
        <BarChartSharp />
      </button>
      <DropdownContentUser>
        <ButtonDropdownMenu onClick={handleGoIndicadoresPersonalizados} icon={PostAdd}>
          <span>Indicadores</span>
        </ButtonDropdownMenu>
        <ButtonDropdownMenu onClick={handleGoDashboard} icon={Dashboard}>
          <span>Dashboard</span>
        </ButtonDropdownMenu>
      </DropdownContentUser>
    </ContainerDropdown>
  );
};

export default MenuCostAnalysis;
