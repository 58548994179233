import { GridColDef } from '@material-ui/data-grid';

export const ColumnEmail: GridColDef = {
  headerName: 'E-mail',
  field: 'email',
  width: 400,
};

export const ColumnName: GridColDef = {
  headerName: 'Usuário',
  field: 'name',
  width: 400,
};

export const ColumnActive: GridColDef = {
  headerName: 'Ativo',
  field: 'active',
  type: 'boolean',
  width: 150,
};

export const ColumnAdmin: GridColDef = {
  headerName: 'Administrador',
  field: 'admin',
  type: 'boolean',
  width: 200,
};

export const ColumnViewer: GridColDef = {
  headerName: 'Visualizador',
  field: 'viewer',
  type: 'boolean',
  width: 200,
};

export const ColumnId: GridColDef = {
  headerName: '#',
  field: 'id',
  hide: true,
  width: 400,
  editable: false,
  filterable: false,
  hideSortIcons: true,
  sortable: false,
  disableColumnMenu: true,
};
