import React, { FC, useState, useCallback, ReactElement } from 'react';
import {
  Button as ButtonDialog,
  Dialog,
  DialogActions,
  DialogContent,
  Select,
  MenuItem,
  FormControl,
  FormControlLabel,
  Grid,
  TextField,
  Checkbox,
} from '@material-ui/core';
import './style.css';
import { IFilterFluxoCaixa, emptyFilterFluxoCaixa } from './IFilterFluxoCaixa';
import IModalFilterFluxoCaixaProps from './IModalFilterFluxoCaixaProps';
import { defaultFilterDataInicio, defaultFilterDataFim } from '../../../utils/functions';

const ModalFilterFluxoCaixa: FC<IModalFilterFluxoCaixaProps> = ({ onConfirm, onCancel, open }) => {
  const [filterFluxoCaixa, setFilterFluxoCaixa] = useState<IFilterFluxoCaixa>(emptyFilterFluxoCaixa);

  const onCloseFilter = useCallback(() => {
    if (onCancel) {
      onCancel();
    }
  }, [onCancel]);

  const onFilter = useCallback(() => {
    let dt_inicio = '';
    let dt_fim = '';
    const fieldInputDataInicio = document.getElementById('filterdatainicio');
    if (fieldInputDataInicio) {
      dt_inicio = (fieldInputDataInicio as HTMLInputElement).value;
    }

    const fieldInputDataFim = document.getElementById('filterdatafim');
    if (fieldInputDataFim) {
      dt_fim = (fieldInputDataFim as HTMLInputElement).value;
    }

    if (onConfirm) {
      onConfirm({
        ...filterFluxoCaixa,
        dataInicio: dt_inicio,
        dataFim: dt_fim,
      });
    }
  }, [onConfirm, filterFluxoCaixa]);

  const onChangeCheckTipoDespesa = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const localValue = { ...filterFluxoCaixa.tipo };
    localValue.despesa = event.target.checked;
    setFilterFluxoCaixa({ ...filterFluxoCaixa, tipo: localValue });
  };

  const onChangeCheckTipoReceita = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const localValue = { ...filterFluxoCaixa.tipo };
    localValue.receita = event.target.checked;
    setFilterFluxoCaixa({ ...filterFluxoCaixa, tipo: localValue });
  };

  const onChangeCheckTipoTransferencia = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const localValue = { ...filterFluxoCaixa.tipo };
    localValue.transferencia = event.target.checked;
    setFilterFluxoCaixa({ ...filterFluxoCaixa, tipo: localValue });
  };

  const onChangeCheckSituacaoPendente = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const localValue = { ...filterFluxoCaixa.situacao };
    localValue.pendente = event.target.checked;
    setFilterFluxoCaixa({ ...filterFluxoCaixa, situacao: localValue });
  };

  const onChangeCheckSituacaoPrevisto = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const localValue = { ...filterFluxoCaixa.situacao };
    localValue.previsto = event.target.checked;
    setFilterFluxoCaixa({ ...filterFluxoCaixa, situacao: localValue });
  };

  const onChangeCheckSituacaoQuitado = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const localValue = { ...filterFluxoCaixa.situacao };
    localValue.quitado = event.target.checked;
    setFilterFluxoCaixa({ ...filterFluxoCaixa, situacao: localValue });
  };

  const onChangeFilterTypeDate = (event: React.ChangeEvent<{ value: unknown }>): void => {
    const localValue = { ...filterFluxoCaixa };
    localValue.tipoData = event.target.value as string;
    setFilterFluxoCaixa(localValue);
  };

  const checkboxDespesa: ReactElement = (
    <Checkbox checked={filterFluxoCaixa.tipo.despesa} color="primary" onChange={onChangeCheckTipoDespesa} />
  );

  const checkboxReceita: ReactElement = (
    <Checkbox checked={filterFluxoCaixa.tipo.receita} color="primary" onChange={onChangeCheckTipoReceita} />
  );

  const checkboxTransferencia: ReactElement = (
    <Checkbox checked={filterFluxoCaixa.tipo.transferencia} color="primary" onChange={onChangeCheckTipoTransferencia} />
  );

  const checkboxPrevisto: ReactElement = (
    <Checkbox checked={filterFluxoCaixa.situacao.previsto} color="primary" onChange={onChangeCheckSituacaoPrevisto} />
  );

  const checkboxPendente: ReactElement = (
    <Checkbox checked={filterFluxoCaixa.situacao.pendente} color="primary" onChange={onChangeCheckSituacaoPendente} />
  );

  const checkboxQuitado: ReactElement = (
    <Checkbox checked={filterFluxoCaixa.situacao.quitado} color="primary" onChange={onChangeCheckSituacaoQuitado} />
  );

  return (
    <Dialog open={open} fullWidth maxWidth="lg" onClose={onCloseFilter}>
      <h2 className="astDialogFilterTitle">Filtro</h2>
      <DialogContent style={{ height: '440px' }}>
        <div style={{ marginTop: '6px' }}>
          <div style={{ marginBottom: '8px' }}>
            <span>Tipo:</span>
          </div>
          <div
            style={{
              border: '1px solid #c2c2c2',
              borderRadius: '4px',
              padding: '10px',
            }}
          >
            <Grid container spacing={3}>
              <Grid item xs={12} md={4}>
                <FormControlLabel control={checkboxDespesa} label="Despesa" />
              </Grid>
              <Grid item xs={12} md={4}>
                <FormControlLabel control={checkboxReceita} label="Receita" />
              </Grid>
              <Grid item xs={12} md={4}>
                <FormControlLabel control={checkboxTransferencia} label="Transferência" />
              </Grid>
            </Grid>
          </div>
        </div>

        <div style={{ marginTop: '6px' }}>
          <div style={{ marginBottom: '8px' }}>
            <span>Situação:</span>
          </div>
          <div
            style={{
              border: '1px solid #c2c2c2',
              borderRadius: '4px',
              padding: '10px',
            }}
          >
            <Grid container spacing={3}>
              <Grid item xs={12} md={4}>
                <FormControlLabel control={checkboxPrevisto} label="Previsto" />
              </Grid>
              <Grid item xs={12} md={4}>
                <FormControlLabel control={checkboxPendente} label="Pendente" />
              </Grid>
              <Grid item xs={12} md={4}>
                <FormControlLabel control={checkboxQuitado} label="Quitado" />
              </Grid>
            </Grid>
          </div>
        </div>

        <div style={{ marginTop: '6px' }}>
          <div style={{ marginBottom: '8px' }}>
            <span>Tipo Data pesquisa:</span>
          </div>
          <div>
            <FormControl variant="outlined" style={{ width: '100%' }}>
              <Select value={filterFluxoCaixa.tipoData} onChange={onChangeFilterTypeDate}>
                <MenuItem value="1-Data Competência">1-Data Competência</MenuItem>
                <MenuItem value="2-Data Vencimento">2-Data Vencimento</MenuItem>
                <MenuItem value="3-Data Quitação">3-Data Quitação</MenuItem>
              </Select>
            </FormControl>
          </div>
        </div>

        <div style={{ marginTop: '6px' }}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
              <div style={{ marginBottom: '8px' }}>
                <span>Data Inicio:</span>
              </div>
              <div>
                <FormControl variant="outlined" style={{ width: '100%' }}>
                  <TextField
                    variant="outlined"
                    id="filterdatainicio"
                    type="date"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    defaultValue={defaultFilterDataInicio()}
                  />
                </FormControl>
              </div>
            </Grid>
            <Grid item xs={12} md={6}>
              <div style={{ marginBottom: '8px' }}>
                <span>Data fim:</span>
              </div>
              <div>
                <FormControl variant="outlined" style={{ width: '100%' }}>
                  <TextField
                    variant="outlined"
                    id="filterdatafim"
                    type="date"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    defaultValue={defaultFilterDataFim()}
                  />
                </FormControl>
              </div>
            </Grid>
          </Grid>
        </div>
      </DialogContent>

      <DialogActions>
        <ButtonDialog onClick={onCloseFilter} color="primary" autoFocus>
          Cancelar
        </ButtonDialog>
        <ButtonDialog onClick={onFilter} color="primary">
          Filtrar
        </ButtonDialog>
      </DialogActions>
    </Dialog>
  );
};

export default ModalFilterFluxoCaixa;
