import { GridColDef } from '@material-ui/data-grid';

export const ColumnDemonstrativo: GridColDef = {
  headerName: 'Demonstrativo',
  field: 'demonstrativo',
  width: 400,
};

export const ColumnEmpresa: GridColDef = {
  headerName: 'Empresa',
  field: 'empresa',
  width: 600,
};

export const ColumnId: GridColDef = {
  headerName: '#',
  field: 'id',
  hide: true,
  width: 400,
  editable: false,
  filterable: false,
  hideSortIcons: true,
  sortable: false,
  disableColumnMenu: true,
};
