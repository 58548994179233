import { GridColDef } from '@material-ui/data-grid';

export const ColumnId: GridColDef = {
  headerName: '#',
  field: 'id',
  hide: true,
  width: 400,
  editable: false,
  filterable: false,
  hideSortIcons: true,
  sortable: false,
  disableColumnMenu: true,
};

export const ColumnIdContaCorrente: GridColDef = {
  headerName: '# Conta Corrente',
  field: 'id_conta_corrente',
  hide: true,
  width: 400,
  editable: false,
  filterable: false,
  hideSortIcons: true,
  sortable: false,
  disableColumnMenu: true,
};

export const ColumnIdContaCorrenteTransferencia: GridColDef = {
  headerName: '# Conta Corrente Transferência',
  field: 'id_conta_corrente_transferencia',
  hide: true,
  width: 400,
  editable: false,
  filterable: false,
  hideSortIcons: true,
  sortable: false,
  disableColumnMenu: true,
};

export const ColumnTipo: GridColDef = {
  headerName: 'Tipo',
  field: 'tipo',
  width: 160,
};

export const ColumnValorOriginal: GridColDef = {
  headerName: 'Valor original',
  field: 'valor_original',
  width: 210,
  type: 'number',
};

export const ColumnValorQuitacao: GridColDef = {
  headerName: 'Valor quitação',
  field: 'valor_quitacao',
  width: 200,
  type: 'number',
};

export const ColumnSituacao: GridColDef = {
  headerName: 'situação',
  field: 'situacao',
  width: 200,
};

export const ColumnDescricao: GridColDef = {
  headerName: 'Descrição',
  field: 'descricao_movimento',
  width: 500,
};
