import styled, { css } from 'styled-components';

interface IContainerProps {
  isActive: boolean;
}

interface IContainerFieldsProps {
  field: 'T227px' | 'T250px' | 'T300px' | 'T500px' | 'T100' | 'check' | 'id';
  disable?: boolean;
  filter_field?: boolean;
  ocultar?: boolean;
}

export const Container = styled.div`
  > header {
    height: 100px;
    background: #28262e;

    display: flex;
    align-items: center;

    div {
      width: 100%;
      max-width: 20px;
      margin: 40px;

      svg {
        color: #999591;
        width: 24px;
        height: 24px;
      }
    }
  }
`;

export const Content = styled.div`
  display: flex;
  max-width: 3000px;
  align-items: center;
  justify-content: center;
  margin: 4px auto;
`;

export const Licenses = styled.div`
  width: 100%;
  border: solid 1px #006699;
  padding: 16px 16px 30px 16px;
`;

export const Tab = styled.div`
  overflow: hidden;
  border: 1px solid #fff;
  background-color: #fff;

  span {
    padding-left: 30%;
    color: #006699;
    font-weight: bold;
    font-family: sans-serif;
    white-space: pre-wrap;
  }
`;

export const TabButton = styled.button<IContainerProps>`
  background-color: inherit;
  float: left;
  border: none;
  outline: none;
  cursor: pointer;
  padding: 10px 12px;
  transition: 0.3s;
  font-size: 16px;
  font-family: sans-serif;

  &:hover {
    background-color: #006699;
    opacity: 0.8;
    font-size: 18px;
    color: #fff;
    font-weight: bold;
  }

  &:active {
    background-color: #006699;
    opacity: 0.2;
  }

  ${props =>
    props.isActive &&
    css`
      background-color: #006699;
      color: #fff;
      font-weight: bold;
      font-size: 16px;
    `}
`;

export const TabContent = styled.div<IContainerProps>`
  /* Style the tab content */
  display: none;
  background-color: #fff;
  border: 1px solid #666360;
  border-radius: 2px;
  height: 100%;

  form {
    height: 100%;
  }

  ${props =>
    props.isActive &&
    css`
      display: block;
    `}
`;

export const TabContentForm = styled.div<IContainerProps>`
  /* Style the tab content */
  display: none;
  background-color: #fff;

  ${props =>
    props.isActive &&
    css`
      display: block;
    `}
`;

export const FormFields = styled.div`
  margin-left: 20px;
  margin-right: 20px;
  width: 800px;

  span {
    margin: 10px 0;
    text-align: left;

    &.spBanco {
      color: #006699;
    }
  }

  @media (max-width: 810px) {
    width: 600px;
  }

  @media (max-width: 720px) {
    width: 450px;
  }
`;

export const FormButtons = styled.div`
  width: 450px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  button {
    margin: 0 10px 10px 0;
  }
`;

export const FormButtonDelete = styled.div`
  cursor: pointer;
  width: 80px;

  svg {
    color: #dc143c;
    width: 55px;
    height: 55px;
  }
`;

export const ContainerFieldCheckbox = styled.div<IContainerFieldsProps>`

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;


  ${props =>
    props.field &&
    props.field === 'T227px' &&
    css`
      width: 227px;
    `}

  ${props =>
    props.field &&
    props.field === 'T250px' &&
    css`
      width: 250px;
    `}

  ${props =>
    props.field &&
    props.field === 'T300px' &&
    css`
      width: 300px;
    `}

  ${props =>
    props.field &&
    props.field === 'T500px' &&
    css`
      width: 500px;
    `}

  ${props =>
    props.field &&
    props.field === 'T100' &&
    css`
      width: 100%;
    `}

  ${props =>
    props.field &&
    props.field === 'id' &&
    css`
      height: 0px;
      width: 0px;
    `}

  &.field2 {
    margin-top: 26px;
    margin-left: 40px;
  }

  @media (max-width: 720px) {
    &.field2 {
      margin-top: 4px;
      margin-left: -4px;
    }
  }
`;

export const ContainerFieldCnpj = styled.div`
  display: flex;
  flex-direction: row;

  @media (max-width: 720px) {
    flex-direction: column;
  }
`;

export const ContainerFields = styled.div<IContainerFieldsProps>`
  padding: 20px 0px 0px 0px;

  ${props =>
    props.field &&
    props.field === 'T227px' &&
    css`
      width: 227px;
    `}

  ${props =>
    props.field &&
    props.field === 'T250px' &&
    css`
      width: 250px;
    `}

  ${props =>
    props.field &&
    props.field === 'T300px' &&
    css`
      width: 300px;
    `}

  ${props =>
    props.field &&
    props.field === 'T500px' &&
    css`
      width: 516px;
    `}

  ${props =>
    props.field &&
    props.field === 'T100' &&
    css`
      width: 100%;
    `}

  ${props =>
    props.field &&
    props.field === 'id' &&
    css`
      padding: 0px 0px 30px 0px;
      height: 0px;
      width: 0px;
    `}

  span {
    ${props =>
      props.disable &&
      props.disable === true &&
      css`
        color: gray;
      `}

      ${props =>
        props.filter_field &&
        props.filter_field === true &&
        css`
          color: #006699;
        `}
  }

  div {
    ${props =>
      props.disable &&
      props.disable === true &&
      css`
        border-color: gray;
      `}

    ${props =>
      props.filter_field &&
      props.filter_field === true &&
      css`
        margin-top: 8px;
      `}

    input {
      ${props =>
        props.disable &&
        props.disable === true &&
        css`
          color: gray;
        `}
    }
  }

  select {
    ${props =>
      props.disable &&
      props.disable === true &&
      css`
        color: gray;
        border-color: gray;
      `}
  }

  ${props =>
    props.ocultar &&
    props.ocultar === true &&
    css`
      display: none;
    `}

  &.field2 {
    margin-left: 60px;
  }

  &.field2filter {
    margin-left: 6px;

    select {
      height: 23.2px;
    }
  }

  @media (max-width: 720px) {
    &.field2 {
      margin-left: 0px;
    }
  }

  @media (max-width: 720px) {
    &.field2filter {
      margin-left: 0px;
    }
  }
`;

export const ContainerFieldsHidden = styled.div`
  padding: 0px 0px 30px 0px;
  width: 0px;
  height: 0px;
  display: flex;
  flex-direction: row;
`;

export const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const FormNavigate = styled.div`
  width: 800px;
  margin-top: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  align-content: flex-start;

  border-bottom: 2px solid #006699;

  button {
    margin: 0 10px 10px 0;
  }

  @media (max-width: 810px) {
    width: 600px;
  }

  @media (max-width: 720px) {
    width: 450px;
    justify-content: space-between;
  }
`;

export const GradeButtons = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

export const ContainerFieldGoup = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  &.field2 {
    margin-left: 60px;
  }

  @media (max-width: 720px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

export const ContainerFieldDate = styled.div`
  display: flex;
  flex-direction: row;

  @media (max-width: 720px) {
    flex-direction: column;
  }
`;

export const ContainerChip = styled.div`
  margin-right: 8px;
`;

export const ContainerFilterSelect = styled.div`
  background: #fff;
  border-radius: 4px;
  padding: 5px;
  width: 100%;
  border: 1px solid #006699;
  color: #666360;
  display: flex;
  align-items: center;

  & + div {
    margin-top: 1px;
  }

  select {
    flex: 1;
    background: transparent;
    border: 0;
    color: #000;
    width: 100%;
    border: none;
    height: 100%;
    font-size: 16px;
    cursor: pointer;

    &::placeholder {
      color: #989898;
    }
  }

  option {
    display: block;
    position: absolute;
    cursor: pointer;
    padding: 15px 0;
    font-size: 16px;
    zoom: 1.2;
  }

  svg {
    margin-right: 16px;
  }
`;

export const ContainerFilterInput = styled.div`
  background: #fff;
  border-radius: 4px;
  padding: 5px;
  width: 100%;
  border: 1px solid #006699;
  color: #666360;
  display: flex;
  align-items: center;

  & + div {
    margin-top: 1px;
  }

  input {
    border: none;
    width: 100%;
  }
`;

export const TituloFiltro = styled.h2`
  color: #006699;
  margin-left: 25px;
  margin-top: 10px;
`;

export const SpanFiltro = styled.span`
  display: flex;
  width: 100%;
  background-color: #fff;
  color: #006699;
  font-family: sans-serif;
  font-size: 12px;
  white-space: pre-wrap;
`;
