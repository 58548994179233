import { GridColDef } from '@material-ui/data-grid';

export const ColumnCNPJ: GridColDef = {
  headerName: 'CNPJ',
  field: 'cnpj_estab_tot',
  width: 200,
};

export const ColumnDemonstrativo: GridColDef = {
  headerName: 'Demonstrativo',
  field: 'demonstrativo',
  width: 300,
};

export const ColumnCodigoTotalizador: GridColDef = {
  headerName: 'Código Totalizador',
  field: 'cod_tot',
  width: 250,
};

export const ColumnNivelTot: GridColDef = {
  headerName: 'Nível',
  field: 'nivel_tot',
  type: 'number',
  width: 150,
};

export const ColumnTotTitulo: GridColDef = {
  headerName: 'Título',
  field: 'tot_titulo',
  width: 150,
};

export const ColumnClassificacaoTotalizador: GridColDef = {
  headerName: 'Classificação Totalizador',
  field: 'classif_tot',
  width: 300,
};

export const ColumnGrupoContas: GridColDef = {
  headerName: 'Grupo Contas',
  field: 'cod_nat',
  width: 230,
};

export const ColumnDescricaoTotalizador: GridColDef = {
  headerName: 'Descrição Totalizador',
  field: 'nome_tot',
  width: 400,
};

export const ColumnTipoTotalizacao: GridColDef = {
  headerName: 'Tipo totalização',
  field: 'tp_totalizacao',
  width: 200,
};

export const ColumnCodigoTotalizadorDescarga: GridColDef = {
  headerName: 'Código Totalizador Descarga',
  field: 'cod_tot_descarga',
  width: 300,
};

export const ColumnObservacao: GridColDef = {
  headerName: 'Observação',
  field: 'obs',
  width: 400,
};

export const ColumnInf1: GridColDef = {
  headerName: 'Informação 1',
  field: 'inf1',
  width: 400,
};

export const ColumnInf2: GridColDef = {
  headerName: 'Informação 2',
  field: 'inf2',
  width: 400,
};

export const ColumnInf3: GridColDef = {
  headerName: 'Informação 3',
  field: 'inf3',
  width: 400,
};

export const ColumnInf4: GridColDef = {
  headerName: 'Informação 4',
  field: 'inf4',
  width: 400,
};

export const ColumnInf5: GridColDef = {
  headerName: 'Informação 5',
  field: 'inf5',
  width: 400,
};

export const ColumnId: GridColDef = {
  headerName: '#',
  field: 'id',
  hide: true,
  width: 400,
  editable: false,
  filterable: false,
  hideSortIcons: true,
  sortable: false,
  disableColumnMenu: true,
};
