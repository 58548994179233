import React, { useRef, useState, useEffect, useCallback } from 'react';
import * as Yup from 'yup';

import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';

import { DataGrid, GridColDef, GridCellParams, GridRowId, ptBR } from '@material-ui/data-grid';
import { createMuiTheme, ThemeProvider, makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import ButtonDialog from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';

import { FiCopy } from 'react-icons/fi';
import { MdDelete, MdEdit, MdClear, MdSkipNext, MdSkipPrevious, MdContentCopy } from 'react-icons/md';
import {
  AiOutlinePlus,
  AiOutlineSave,
  AiOutlineSearch,
  AiFillFastBackward,
  AiFillFastForward,
  AiOutlineCaretDown,
  AiOutlineCaretUp,
} from 'react-icons/ai';
import { GoPlus } from 'react-icons/go';
import { RiDeleteBinLine } from 'react-icons/ri';

import InputComum from '../../components/InputComum';
import Select from '../../components/Select';
import ModalUploadFile from '../../components/ModalUploadFile';
import Header from '../../components/Header';
import FormModalDialogConfirm from '../../components/FormModalDialogConfirm';

import api from '../../services/api';
import { useAuth } from '../../hooks/Auth';
import { useToast } from '../../hooks/Toast';

import getListUpdate from '../../utils/getListUpdate';
import getValidationErrors from '../../utils/getValidationErrors';
import { getCurrency } from '../../utils/formatValues';
import { getPathGrid } from '../../utils/storagePath';

import {
  ColumnAno,
  ColumnConta,
  ColumnCentroCusto,
  ColumnJaneiro,
  ColumnFevereiro,
  ColumnMarco,
  ColumnAbril,
  ColumnMaio,
  ColumnJunho,
  ColumnJulho,
  ColumnAgosto,
  ColumnSetembro,
  ColumnOutubro,
  ColumnNovembro,
  ColumnDezembro,
  ColumnObservacao,
  ColumnTipo,
  ColumnId,
} from './GridColumns';

import ITipoDemGer from './ITipoDemGer';
import IDemGerOrcado, { emptyDemGerOrcado } from './IDemGerOrcado';

import {
  Container,
  ContainerFields,
  Tab,
  TabButton,
  TabContent,
  FormFields,
  ContainerAllMonthPrice,
  ContainerMonthPrice,
  FormContainer,
  FormNavigate,
  ContainerFilterSelect,
  SpanFiltro,
  TabTitulo,
  TabButtonArea,
  TabButtonNovo,
  TabButtonDelete,
  TabButtonGridRowHeight,
  ContainerFieldTextFilter,
} from './styles';

const useStylesLoading = makeStyles((in_theme: Theme) =>
  createStyles({
    backdrop: {
      zIndex: in_theme.zIndex.drawer + 1700,
      color: '#fff',
    },
  }),
);

const theme = createMuiTheme(
  {
    palette: {
      primary: { main: '#006699' },
    },
  },
  ptBR,
);

const options_tipo = [
  { value: '1-Previsto', label: '1-Previsto' },
  { value: '2-Estimado', label: '2-Estimado' },
  { value: '3-Calculado AV', label: '3-Calculado AV' },
  { value: '4-Calculado AH', label: '4-Calculado AH' },
  {
    value: '5-Calculado Rateio Totalizador',
    label: '5-Calculado Rateio Totalizador',
  },
];

const titulo = 'O R Ç A M E N T O S   P O R   C O N T A   E   C E N T R O   D E   C U S T O';

const DemGerOrcado: React.FC = () => {
  const { user } = useAuth();
  const { addToast } = useToast();

  const formRef = useRef<FormHandles>(null);
  const [listTipoDemGer, setListTipoDemGer] = useState<ITipoDemGer[]>([]);
  const [demGerOrcado, setDemGerOrcado] = useState<IDemGerOrcado>();
  const [listDemGerOrcado, setListDemGerOrcado] = useState<IDemGerOrcado[]>([]);
  const [hiddenPesquisar, setHiddenPesquisar] = useState<boolean>(true);
  const [hiddenCadastrar, setHiddenCadastrar] = useState<boolean>(true);
  const [demGerOrcadoIndex, setDemGerOrcadoIndex] = useState<number>(0);
  const [rowToEdit, setRowToEdit] = useState(null);
  const [idDeleteDemGerOrcado, setIdDeleteDemGerOrcado] = useState<string>('');
  const [idTipoDemGerPesquisar, setIdTipoDemGerPesquisar] = useState<string>('');
  const [anoPesquisar, setAnoPesquisar] = useState<number>(0);
  const [selectedIds, setSelectedIds] = useState<GridRowId[]>([]);
  const [openDeleteSingle, setOpenDeleteSingle] = useState(false);
  const [openDeleteMultiple, setOpenDeleteMultiple] = useState(false);
  const [gridRowHeight, setGridRowHeight] = useState<number>(46);
  const [openFilterTipoDemGer, setOpenFilterTipoDemGer] = React.useState(true);
  const [textFiltro, setTextFiltro] = useState<string>('');
  const [openUploadFile, setOpenUploadFile] = useState<boolean>(false);
  const [openLoading, setOpenLoading] = React.useState(false);
  const cssLoading = useStylesLoading();

  const handleGridRowHeightUp = useCallback(async () => {
    const heightValue: number = gridRowHeight + 1;
    setGridRowHeight(heightValue);
    localStorage.setItem(getPathGrid(), heightValue.toString());
  }, [gridRowHeight]);

  const handleGridRowHeightDown = useCallback(async () => {
    const heightValue: number = gridRowHeight - 1;
    setGridRowHeight(heightValue);
    localStorage.setItem(getPathGrid(), heightValue.toString());
  }, [gridRowHeight]);

  const licencaInvalida = useCallback((): boolean => {
    if (!user.license || user.license.id === '') {
      addToast({
        type: 'error',
        title: 'Licença inválida',
        description: 'Usuário não está vinculado a nenhuma licença',
      });

      return true;
    }

    return false;
  }, [addToast, user]);

  const defineDemGerOrcado = useCallback(async (data: IDemGerOrcado) => {
    setDemGerOrcado(data);
    formRef.current?.setData(data);
  }, []);

  const onNewRegister = useCallback(async () => {
    defineDemGerOrcado(emptyDemGerOrcado);
  }, [defineDemGerOrcado]);

  const OnGridSetRegisterToEdit = useCallback(
    async (params: GridCellParams) => {
      const localDemGerOrcado = params.row as IDemGerOrcado;

      setDemGerOrcadoIndex(listDemGerOrcado.indexOf(localDemGerOrcado));
      setHiddenCadastrar(false);
      setHiddenPesquisar(true);
      setRowToEdit(null);
      defineDemGerOrcado(localDemGerOrcado);
    },
    [listDemGerOrcado, defineDemGerOrcado],
  );

  const OnSetRegisterToAdd = useCallback(async () => {
    onNewRegister();
    setHiddenCadastrar(false);
    setHiddenPesquisar(true);
  }, [onNewRegister]);

  const OnGridRowDuplicate = useCallback(
    async (baseDemGerOrcado: IDemGerOrcado | undefined) => {
      if (baseDemGerOrcado !== undefined) {
        const newDemGerOrcado: IDemGerOrcado = {
          acao: baseDemGerOrcado.acao,
          id: '',
          id_license: baseDemGerOrcado.id_license,
          cnpj_estab_0000: baseDemGerOrcado.cnpj_estab_0000,
          cod_ccusto_i100: baseDemGerOrcado.cod_ccusto_i100,
          cod_cta_i050: baseDemGerOrcado.cod_cta_i050,
          ano: baseDemGerOrcado.ano,
          janeiro: baseDemGerOrcado.janeiro,
          fevereiro: baseDemGerOrcado.fevereiro,
          marco: baseDemGerOrcado.marco,
          abril: baseDemGerOrcado.abril,
          maio: baseDemGerOrcado.maio,
          junho: baseDemGerOrcado.junho,
          julho: baseDemGerOrcado.julho,
          agosto: baseDemGerOrcado.agosto,
          setembro: baseDemGerOrcado.setembro,
          outubro: baseDemGerOrcado.outubro,
          novembro: baseDemGerOrcado.novembro,
          dezembro: baseDemGerOrcado.dezembro,
          obs: baseDemGerOrcado.obs,
          tipo: baseDemGerOrcado.tipo,
          id_tipo_dem_ger: baseDemGerOrcado.id_tipo_dem_ger,
          status_janeiro: baseDemGerOrcado.status_janeiro,
          status_fevereiro: baseDemGerOrcado.status_fevereiro,
          status_marco: baseDemGerOrcado.status_marco,
          status_abril: baseDemGerOrcado.status_abril,
          status_maio: baseDemGerOrcado.status_maio,
          status_junho: baseDemGerOrcado.status_junho,
          status_julho: baseDemGerOrcado.status_julho,
          status_agosto: baseDemGerOrcado.status_agosto,
          status_setembro: baseDemGerOrcado.status_setembro,
          status_outubro: baseDemGerOrcado.status_outubro,
          status_novembro: baseDemGerOrcado.status_novembro,
          status_dezembro: baseDemGerOrcado.status_dezembro,
        };

        defineDemGerOrcado(newDemGerOrcado);
        setHiddenCadastrar(false);
        setHiddenPesquisar(true);
      }
    },
    [defineDemGerOrcado],
  );

  const OnConfirmationDeleteSingle = useCallback(
    async (id: string) => {
      if (licencaInvalida()) return;

      if (id === '') return;

      setIdDeleteDemGerOrcado(id);
      setOpenDeleteSingle(true);
    },
    [licencaInvalida],
  );

  const colunaAcao: GridColDef = {
    field: 'acao',
    headerName: 'Ação',
    editable: false,
    filterable: false,
    hideSortIcons: true,
    sortable: false,
    disableColumnMenu: true,
    width: 160,
    renderCell: (params: GridCellParams) => (
      <strong>
        <Tooltip title="Editar Registro" placement="top">
          <IconButton color="primary" aria-label="Editar" onClick={() => OnGridSetRegisterToEdit(params)}>
            <MdEdit />
          </IconButton>
        </Tooltip>

        <Tooltip title="Duplicar Registro" placement="top">
          <IconButton
            color="primary"
            aria-label="Duplicar"
            onClick={() => OnGridRowDuplicate(params.row as IDemGerOrcado)}
          >
            <FiCopy />
          </IconButton>
        </Tooltip>

        <Tooltip title="Excluir Registro" placement="top">
          <IconButton
            color="primary"
            aria-label="Excluir"
            onClick={() => OnConfirmationDeleteSingle((params.row as IDemGerOrcado).id)}
          >
            <MdDelete />
          </IconButton>
        </Tooltip>
      </strong>
    ),
  };

  const columns: GridColDef[] = [
    colunaAcao,
    ColumnAno,
    ColumnTipo,
    ColumnConta,
    ColumnCentroCusto,
    ColumnJaneiro,
    ColumnFevereiro,
    ColumnMarco,
    ColumnAbril,
    ColumnMaio,
    ColumnJunho,
    ColumnJulho,
    ColumnAgosto,
    ColumnSetembro,
    ColumnOutubro,
    ColumnNovembro,
    ColumnDezembro,
    ColumnObservacao,
    ColumnId,
  ];

  const handleCloseFilterTipoDemGer = useCallback(() => {
    setOpenFilterTipoDemGer(false);
  }, []);

  const getTipoDemGer = useCallback(async () => {
    if (licencaInvalida()) return '';
    setOpenLoading(true);
    const response = await api.get<ITipoDemGer[]>(`/tipodemger/all/company/${user.empresa.id}`);

    setListTipoDemGer(response.data);
    setOpenLoading(false);
    if (response.data[0]) {
      setIdTipoDemGerPesquisar(response.data[0].id);

      return response.data[0].id;
    }

    return '';
  }, [user, licencaInvalida]);

  const getDemGerOrcado = useCallback(
    async (id: string, inYear: number) => {
      if (licencaInvalida()) return;

      if (id === '') return;
      setOpenLoading(true);

      const response = await api.get<IDemGerOrcado[]>(`/demgerorcado/cnpjandtipo`, {
        params: {
          license: user.license.id,
          id_tipo_dem_ger: `'${id}'`,
          cnpj: `'${user.empresa.cnpj}'`,
          year: inYear,
        },
      });

      setListDemGerOrcado(response.data);
      setOpenLoading(false);
      if (rowToEdit !== null) {
        const findEditDemGerOrcado = response.data.find(item => item.id === rowToEdit);

        if (findEditDemGerOrcado) {
          defineDemGerOrcado(findEditDemGerOrcado);
          setDemGerOrcadoIndex(response.data.indexOf(findEditDemGerOrcado));
          setHiddenCadastrar(false);
          setHiddenPesquisar(true);
          setRowToEdit(null);
        }
      }

      setHiddenPesquisar(false);
    },
    [user, licencaInvalida, rowToEdit, defineDemGerOrcado],
  );

  const getValues = useCallback(async () => {
    setOpenLoading(true);

    await getTipoDemGer();

    setOpenLoading(false);
  }, [getTipoDemGer]);

  useEffect(() => {
    getValues();

    const rowHeight = localStorage.getItem(getPathGrid());
    if (rowHeight) {
      setGridRowHeight(parseInt(rowHeight, 10));
    }
  }, [getValues]);

  const onUploadFileClose = useCallback(async () => {
    setOpenLoading(true);

    await getDemGerOrcado(idTipoDemGerPesquisar, anoPesquisar);

    setOpenLoading(false);
    setOpenUploadFile(false);
  }, [getDemGerOrcado, idTipoDemGerPesquisar, anoPesquisar]);

  const handleOnRowDeleteSingle = useCallback(async () => {
    setOpenLoading(true);
    await api.delete(`/demgerorcado/${idDeleteDemGerOrcado}`);

    setListDemGerOrcado(oldDemGerOrcado => oldDemGerOrcado.filter(item => item.id !== idDeleteDemGerOrcado));

    defineDemGerOrcado(emptyDemGerOrcado);

    addToast({
      type: 'success',
      title: 'Removido',
      description: 'Registro excluído com sucesso!',
    });

    setOpenDeleteSingle(false);
    setOpenLoading(false);
  }, [idDeleteDemGerOrcado, addToast, defineDemGerOrcado]);

  const handleOnRowDeleteMultiple = useCallback(async () => {
    setOpenLoading(true);
    if (selectedIds.length > 0) {
      await Promise.all(
        selectedIds.map(itemId => {
          setListDemGerOrcado(oldDemGerOrcado => oldDemGerOrcado.filter(item => item.id !== itemId));

          return api.delete(`/demgerorcado/${itemId}`);
        }),
      );

      defineDemGerOrcado(emptyDemGerOrcado);

      addToast({
        type: 'success',
        title: 'Removido',
        description: 'Registro excluído com sucesso!',
      });
    }

    setSelectedIds([]);
    setOpenDeleteMultiple(false);
    setOpenLoading(false);
  }, [addToast, defineDemGerOrcado, selectedIds]);

  const handleClick = useCallback((id: string): void => {
    if (id === 'filtro') {
      setOpenFilterTipoDemGer(id === 'filtro');
      return;
    }

    setHiddenCadastrar(id === 'pesquisar');
    setHiddenPesquisar(id === 'cadastrar');
  }, []);

  const handleOnFilter = useCallback(async () => {
    const fieldSelect = document.getElementById('filter64565asdTipo');
    setOpenFilterTipoDemGer(false);
    if (fieldSelect) {
      setOpenLoading(true);
      const id = (fieldSelect as HTMLSelectElement).value;
      setIdTipoDemGerPesquisar(id);

      let YearTextFilter = '';
      let yearFilterValue = 0;
      const fieldFiltroAno = document.getElementById('uzsad1u-sdf123-fgh99-as0');
      if (fieldFiltroAno && (fieldFiltroAno as HTMLSelectElement).value.trim() !== '') {
        yearFilterValue = parseInt((fieldFiltroAno as HTMLSelectElement).value, 10);
        YearTextFilter = `Ano: ${yearFilterValue}`;
      }

      setAnoPesquisar(yearFilterValue);
      await getDemGerOrcado(id, yearFilterValue);

      const findTipoDemGer = listTipoDemGer.find(item => id === item.id);
      if (findTipoDemGer) {
        setTextFiltro(
          `   Filtrado por: ${findTipoDemGer.codigo.trim()}-${findTipoDemGer.descricao.trim()}. ${YearTextFilter}`,
        );
      }
      setOpenLoading(false);
    }

    handleClick('pesquisar');
  }, [getDemGerOrcado, listTipoDemGer, handleClick]);

  const onNextRegister = useCallback(async () => {
    if (demGerOrcadoIndex !== undefined) {
      if (demGerOrcadoIndex < listDemGerOrcado.length - 1) {
        const localIndex = demGerOrcadoIndex + 1;
        setDemGerOrcadoIndex(localIndex);
        const findDemGerOrcado = listDemGerOrcado[localIndex];
        if (findDemGerOrcado) {
          defineDemGerOrcado(findDemGerOrcado);
        }
      }
    }
  }, [demGerOrcadoIndex, listDemGerOrcado, defineDemGerOrcado]);

  const onFirstRegister = useCallback(async () => {
    if (listDemGerOrcado.length > 0) {
      setDemGerOrcadoIndex(0);
      const findDemGerOrcado = listDemGerOrcado[0];
      if (findDemGerOrcado) {
        defineDemGerOrcado(findDemGerOrcado);
      }
    }
  }, [listDemGerOrcado, defineDemGerOrcado]);

  const onLastRegister = useCallback(async () => {
    if (listDemGerOrcado.length > 0) {
      setDemGerOrcadoIndex(listDemGerOrcado.length - 1);
      const findDemGerOrcado = listDemGerOrcado[listDemGerOrcado.length - 1];
      if (findDemGerOrcado) {
        defineDemGerOrcado(findDemGerOrcado);
      }
    }
  }, [listDemGerOrcado, defineDemGerOrcado]);

  const onPriorRegister = useCallback(async () => {
    if (demGerOrcadoIndex !== undefined) {
      if (demGerOrcadoIndex > 0) {
        const localIndex = demGerOrcadoIndex - 1;
        setDemGerOrcadoIndex(localIndex);
        const findDemGerOrcado = listDemGerOrcado[localIndex];
        if (findDemGerOrcado) {
          defineDemGerOrcado(findDemGerOrcado);
        }
      }
    }
  }, [demGerOrcadoIndex, listDemGerOrcado, defineDemGerOrcado]);

  const onCancelRegister = useCallback(async () => {
    formRef.current?.setErrors({});

    if (demGerOrcadoIndex !== undefined) {
      const findDemGerOrcado = listDemGerOrcado[demGerOrcadoIndex];
      if (findDemGerOrcado) {
        defineDemGerOrcado(findDemGerOrcado);
      }
    }

    onNewRegister();
  }, [demGerOrcadoIndex, listDemGerOrcado, onNewRegister, defineDemGerOrcado]);

  const handleSubmit = useCallback(
    async (data: IDemGerOrcado, { reset }) => {
      if (licencaInvalida()) return;

      const fieldSelect = document.getElementById('457e41fcfbeb879885d734e16dc9f178');

      if (fieldSelect) {
        const id = (fieldSelect as HTMLSelectElement).value;
        data.id_tipo_dem_ger = id;
      }

      formRef.current?.setErrors({});
      try {
        const schema = Yup.object().shape({
          id_tipo_dem_ger: Yup.string().required('Demonstrativo é obrigatório'),
          cod_cta_i050: Yup.string().required('Código da conta é obrigatório'),
          ano: Yup.number().moreThan(0, `Ano deve ser maior que 0`).required(`Ano é obrigatório`),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        setOpenLoading(true);
        if (data.id === '') {
          const formData: IDemGerOrcado = emptyDemGerOrcado;
          formData.acao = data.acao;
          formData.id_license = user.license.id;
          formData.cnpj_estab_0000 = user.empresa.cnpj;
          formData.id_tipo_dem_ger = data.id_tipo_dem_ger;
          formData.ano = data.ano;
          formData.cod_cta_i050 = data.cod_cta_i050;
          formData.cod_ccusto_i100 = data.cod_ccusto_i100;
          formData.janeiro = getCurrency(data.janeiro);
          formData.fevereiro = getCurrency(data.fevereiro);
          formData.marco = getCurrency(data.marco);
          formData.abril = getCurrency(data.abril);
          formData.maio = getCurrency(data.maio);
          formData.junho = getCurrency(data.junho);
          formData.julho = getCurrency(data.julho);
          formData.agosto = getCurrency(data.agosto);
          formData.setembro = getCurrency(data.setembro);
          formData.outubro = getCurrency(data.outubro);
          formData.novembro = getCurrency(data.novembro);
          formData.dezembro = getCurrency(data.dezembro);
          formData.obs = data.obs ? data.obs : '';
          formData.tipo = data.tipo;

          setOpenLoading(true);
          const response = await api.post(`/demgerorcado`, formData);
          formData.id = response.data.id;

          if (data.id_tipo_dem_ger === idTipoDemGerPesquisar) {
            setListDemGerOrcado(oldLicenses => [...oldLicenses, formData]);
          }
          setOpenLoading(false);
          addToast({
            type: 'success',
            title: 'Adicionado',
            description: 'Registro adicionado com sucesso!',
          });
        } else {
          if (data.status_janeiro === '1-Inserido Rateio') data.status_janeiro = '2-Editado usário [Rateio]';
          if (data.status_fevereiro === '1-Inserido Rateio') data.status_fevereiro = '2-Editado usário [Rateio]';
          if (data.status_marco === '1-Inserido Rateio') data.status_marco = '2-Editado usário [Rateio]';
          if (data.status_abril === '1-Inserido Rateio') data.status_abril = '2-Editado usário [Rateio]';
          if (data.status_maio === '1-Inserido Rateio') data.status_maio = '2-Editado usário [Rateio]';
          if (data.status_junho === '1-Inserido Rateio') data.status_junho = '2-Editado usário [Rateio]';
          if (data.status_julho === '1-Inserido Rateio') data.status_julho = '2-Editado usário [Rateio]';
          if (data.status_agosto === '1-Inserido Rateio') data.status_agosto = '2-Editado usário [Rateio]';
          if (data.status_setembro === '1-Inserido Rateio') data.status_setembro = '2-Editado usário [Rateio]';
          if (data.status_outubro === '1-Inserido Rateio') data.status_outubro = '2-Editado usário [Rateio]';
          if (data.status_novembro === '1-Inserido Rateio') data.status_novembro = '2-Editado usário [Rateio]';
          if (data.status_dezembro === '1-Inserido Rateio') data.status_dezembro = '2-Editado usário [Rateio]';

          setOpenLoading(true);
          await api.put(`/demgerorcado/${data.id}`, data);
          setListDemGerOrcado(getListUpdate(listDemGerOrcado, data));
          setOpenLoading(false);
          addToast({
            type: 'success',
            title: 'Alterado',
            description: 'Registro alterado com sucesso!',
          });
        }

        reset();
        setOpenLoading(false);
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          formRef.current?.setErrors(errors);
        }

        setOpenLoading(false);
      }
    },
    [licencaInvalida, listDemGerOrcado, user, addToast, idTipoDemGerPesquisar],
  );

  return (
    <Container>
      <Header />

      <Tab>
        <TabButtonArea>
          <TabButton id="pesquisar" isActive={!hiddenPesquisar} onClick={() => handleClick('pesquisar')}>
            Listagem
          </TabButton>
          <TabButton id="cadastrar" isActive={!hiddenCadastrar} onClick={() => handleClick('cadastrar')}>
            Cadastro
          </TabButton>

          <Tooltip title="Reduzir o tamanho da linha" placement="top">
            <TabButtonGridRowHeight id="gridrowheightdown" onClick={() => handleGridRowHeightDown()}>
              <AiOutlineCaretDown />
            </TabButtonGridRowHeight>
          </Tooltip>

          <Tooltip title="Aumentar o tamanho da linha" placement="top">
            <TabButtonGridRowHeight id="gridrowheightup" onClick={() => handleGridRowHeightUp()}>
              <AiOutlineCaretUp />
            </TabButtonGridRowHeight>
          </Tooltip>

          <TabButton id="filtro" isActive={false} onClick={() => handleClick('filtro')}>
            <AiOutlineSearch />
          </TabButton>

          <Tooltip title="Importar arquivo Excel" placement="top">
            <TabButton id="importar_xls" isActive={false} onClick={() => setOpenUploadFile(true)}>
              <CloudUploadIcon />
            </TabButton>
          </Tooltip>
          <Tooltip title="Novo Registro" placement="top">
            <TabButtonNovo id="new_register" onClick={() => OnSetRegisterToAdd()}>
              <GoPlus />
              Novo
            </TabButtonNovo>
          </Tooltip>

          <Tooltip title="Remover Registros Selecionados" placement="top">
            <span>
              <span hidden={selectedIds.length <= 0}>
                <TabButtonDelete
                  id="remove_all"
                  onClick={() => {
                    setIdDeleteDemGerOrcado('');
                    setOpenDeleteMultiple(true);
                  }}
                >
                  <RiDeleteBinLine />
                  Remover
                </TabButtonDelete>
              </span>
            </span>
          </Tooltip>
        </TabButtonArea>

        <TabTitulo>
          <span>{titulo}</span>
        </TabTitulo>
      </Tab>
      <SpanFiltro>{textFiltro}</SpanFiltro>

      <TabContent id="pesquisar" isActive={!hiddenPesquisar}>
        <div style={{ height: '85vh', width: '100%' }}>
          <ThemeProvider theme={theme}>
            <DataGrid
              rows={listDemGerOrcado}
              columns={columns}
              rowHeight={gridRowHeight}
              checkboxSelection
              selectionModel={selectedIds}
              disableSelectionOnClick
              onSelectionModelChange={e => setSelectedIds(e.selectionModel)}
            />
          </ThemeProvider>
        </div>
      </TabContent>

      <TabContent id="cadasto" isActive={!hiddenCadastrar}>
        <Form ref={formRef} onSubmit={handleSubmit} initialData={demGerOrcado}>
          <FormContainer>
            <FormNavigate>
              <Tooltip title="Novo Registro" placement="top">
                <IconButton color="primary" aria-label="adicionar" onClick={() => onNewRegister()}>
                  <AiOutlinePlus />
                </IconButton>
              </Tooltip>

              <Tooltip title="Duplicar Registro" placement="top">
                <IconButton color="primary" aria-label="Duplicar" onClick={() => OnGridRowDuplicate(demGerOrcado)}>
                  <MdContentCopy />
                </IconButton>
              </Tooltip>

              <Tooltip title="Excluir Registro" placement="top">
                <IconButton
                  color="primary"
                  aria-label="Excluir"
                  onClick={() => OnConfirmationDeleteSingle(demGerOrcado ? demGerOrcado.id : '')}
                >
                  <RiDeleteBinLine />
                </IconButton>
              </Tooltip>

              <Tooltip title="Cancelar" placement="top">
                <IconButton type="reset" color="primary" aria-label="Cancelar" onClick={() => onCancelRegister()}>
                  <MdClear />
                </IconButton>
              </Tooltip>

              <Tooltip title="Salvar" placement="top">
                <IconButton type="submit" color="primary" aria-label="Confirmar">
                  <AiOutlineSave />
                </IconButton>
              </Tooltip>

              <Tooltip title="Primeiro Registro" placement="top">
                <IconButton color="primary" aria-label="Primeiro" onClick={() => onFirstRegister()}>
                  <AiFillFastBackward />
                </IconButton>
              </Tooltip>

              <Tooltip title="Registro Anterior" placement="top">
                <IconButton color="primary" aria-label="Anterior" onClick={() => onPriorRegister()}>
                  <MdSkipPrevious />
                </IconButton>
              </Tooltip>

              <Tooltip title="Próximo Registro" placement="top">
                <IconButton color="primary" aria-label="Próximo" onClick={() => onNextRegister()}>
                  <MdSkipNext />
                </IconButton>
              </Tooltip>

              <Tooltip title="Último Registro" placement="top">
                <IconButton color="primary" aria-label="Último registro" onClick={() => onLastRegister()}>
                  <AiFillFastForward />
                </IconButton>
              </Tooltip>
            </FormNavigate>

            <FormFields>
              <ContainerFields field="text">
                <span>Ano:</span>
                <InputComum autoFocus name="ano" />
              </ContainerFields>
              <ContainerFields field="text">
                <span>Conta:</span>
                <InputComum autoFocus name="cod_cta_i050" />
              </ContainerFields>
              <ContainerFields field="text">
                <span>Centro custo:</span>
                <InputComum autoFocus name="cod_ccusto_i100" />
              </ContainerFields>
              <ContainerFields field="select">
                <span>Indicador conta:</span>
                <Select name="tipo" defaultValue="1-Previsto">
                  {options_tipo.map(option => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </Select>
              </ContainerFields>
              <ContainerFields field="select">
                <span>Demonstrativo:</span>
                <Select
                  id="457e41fcfbeb879885d734e16dc9f178"
                  name="id_tipo_dem_ger"
                  defaultValue={idTipoDemGerPesquisar}
                >
                  {listTipoDemGer.map(
                    itemTipoDemGer =>
                      itemTipoDemGer.permite_calc_projecao_orc && (
                        <option key={itemTipoDemGer.id} value={itemTipoDemGer.id}>
                          {itemTipoDemGer.codigo.trim()}-{itemTipoDemGer.descricao.trim()}
                        </option>
                      ),
                  )}
                </Select>
              </ContainerFields>

              <ContainerAllMonthPrice>
                <ContainerMonthPrice>
                  <ContainerFields field="price">
                    <span>Janeiro:</span>
                    <InputComum name="janeiro" placeholder="0,0000" type="number" step="0.0001" />
                  </ContainerFields>

                  <ContainerFields field="price" className="price2">
                    <span>Fevereiro:</span>
                    <InputComum name="fevereiro" placeholder="0,0000" type="number" step="0.0001" />
                  </ContainerFields>
                </ContainerMonthPrice>

                <ContainerMonthPrice>
                  <ContainerFields field="price">
                    <span>Março:</span>
                    <InputComum name="marco" placeholder="0,0000" type="number" step="0.0001" />
                  </ContainerFields>

                  <ContainerFields field="price" className="price2">
                    <span>Abril:</span>
                    <InputComum name="abril" placeholder="0,0000" type="number" step="0.0001" />
                  </ContainerFields>
                </ContainerMonthPrice>

                <ContainerMonthPrice>
                  <ContainerFields field="price">
                    <span>Maio:</span>
                    <InputComum name="maio" placeholder="0,0000" type="number" step="0.0001" />
                  </ContainerFields>
                  <ContainerFields field="price" className="price2">
                    <span>Junho:</span>
                    <InputComum name="junho" placeholder="0,0000" type="number" step="0.0001" />
                  </ContainerFields>
                </ContainerMonthPrice>

                <ContainerMonthPrice>
                  <ContainerFields field="price">
                    <span>Julho:</span>
                    <InputComum name="julho" placeholder="0,0000" type="number" step="0.0001" />
                  </ContainerFields>

                  <ContainerFields field="price" className="price2">
                    <span>Agosto:</span>
                    <InputComum name="agosto" placeholder="0,0000" type="number" step="0.0001" />
                  </ContainerFields>
                </ContainerMonthPrice>

                <ContainerMonthPrice>
                  <ContainerFields field="price">
                    <span>Setembro:</span>
                    <InputComum name="setembro" placeholder="0,0000" type="number" step="0.0001" />
                  </ContainerFields>

                  <ContainerFields field="price" className="price2">
                    <span>Outubro:</span>
                    <InputComum name="outubro" placeholder="0,0000" type="number" step="0.0001" />
                  </ContainerFields>
                </ContainerMonthPrice>
                <ContainerMonthPrice>
                  <ContainerFields field="price">
                    <span>Novembro:</span>
                    <InputComum name="novembro" placeholder="0,0000" type="number" step="0.0001" />
                  </ContainerFields>

                  <ContainerFields field="price" className="price2">
                    <span>Dezembro:</span>
                    <InputComum name="dezembro" placeholder="0,0000" type="number" step="0.0001" />
                  </ContainerFields>
                </ContainerMonthPrice>
              </ContainerAllMonthPrice>

              <ContainerFields field="text">
                <span>Observação:</span>
                <InputComum autoFocus name="obs" />
              </ContainerFields>
              <ContainerFields field="id">
                <InputComum name="id" hidden />
              </ContainerFields>
            </FormFields>
          </FormContainer>
        </Form>
      </TabContent>

      <Dialog
        open={openFilterTipoDemGer}
        onClose={handleCloseFilterTipoDemGer}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Filtro</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">Demonstrativo:</DialogContentText>
          <ContainerFields field="select">
            <ContainerFilterSelect>
              <select id="filter64565asdTipo">
                {listTipoDemGer.map(
                  itemTipoDemGer =>
                    itemTipoDemGer.permite_calc_projecao_orc && (
                      <option key={itemTipoDemGer.id} value={itemTipoDemGer.id}>
                        {itemTipoDemGer.codigo.trim()}-{itemTipoDemGer.descricao.trim()}
                      </option>
                    ),
                )}
              </select>
            </ContainerFilterSelect>
          </ContainerFields>

          <DialogContentText id="alert-dialog-description">Ano:</DialogContentText>
          <ContainerFieldTextFilter>
            <ContainerFields field="text">
              <input
                type="number"
                name="sano"
                id="uzsad1u-sdf123-fgh99-as0"
                step={1}
                defaultValue={new Date().getFullYear()}
              />
            </ContainerFields>
          </ContainerFieldTextFilter>
        </DialogContent>
        <DialogActions>
          <ButtonDialog onClick={handleCloseFilterTipoDemGer} color="primary" autoFocus>
            Cancelar
          </ButtonDialog>
          <ButtonDialog onClick={handleOnFilter} color="primary">
            Filtrar
          </ButtonDialog>
        </DialogActions>
      </Dialog>

      <FormModalDialogConfirm
        open={openDeleteSingle}
        onClose={() => setOpenDeleteSingle(false)}
        onConfirm={() => handleOnRowDeleteSingle()}
        buttonCancelText="Cancelar"
        buttonConfirmText="Sim"
        formTitle="Excluir?"
        messageConfirm="Tem certeza que deseja excluir?"
      />

      <FormModalDialogConfirm
        open={openDeleteMultiple}
        onClose={() => setOpenDeleteMultiple(false)}
        onConfirm={() => handleOnRowDeleteMultiple()}
        buttonCancelText="Cancelar"
        buttonConfirmText="Sim"
        formTitle="Excluir?"
        messageConfirm="Tem certeza que deseja excluir todos os registros selecionados?"
      />

      <ModalUploadFile
        open={openUploadFile}
        onClose={onUploadFileClose}
        apiSource={`/demgerorcado/file/${user.license.id}?cnpj_estab=${user.empresa.cnpj}`}
        apiSourceFileExample="/demgerorcado/file/example"
      />

      <Backdrop className={cssLoading.backdrop} open={openLoading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </Container>
  );
};

export default DemGerOrcado;
