import React, { createContext, useCallback, useState, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { IAppInfoEmpresa, IAppInfoLicenciamento } from '../utils/appInfo';

import { getPathEmailLogin, getPathToken, getPathUser } from '../utils/storagePath';
import api from '../services/api';

interface IUser {
  id: string;
  name: string;
  email: string;
  avatar_url: string;
  admin: boolean;
  superUser: boolean;
  redirectToCompanyRequest: boolean;
  empresa: IAppInfoEmpresa;
  license: IAppInfoLicenciamento;
}

interface IUserPreferences {
  lastAcessIdLicense: string;
  lastAcessIdCompany: string;
}

interface IAuthState {
  token: string;
  user: IUser;
}

interface IToken {
  cnpj: string;
  exp: number;
  iat: number;
  id_license: string;
  sub: string;
  userAdmin: boolean;
  userMaster: boolean;
  lastAcessIdLicense: string;
  lastAcessIdCompany: string;
  tipo_licenca: string;
}

interface ISignInCredentials {
  email: string;
  password: string;
}

interface IAuthContextData {
  user: IUser;
  signIn(credentials: ISignInCredentials): Promise<IUser>;
  signOut(): void;
  updateUser(user: IUser): void;
  isMasterUser(): boolean;
  getUserPreferences(): IUserPreferences;
}

const AuthContext = createContext<IAuthContextData>({} as IAuthContextData);

const AuthProvider: React.FC = ({ children }) => {
  const history = useHistory();
  const [data, setData] = useState<IAuthState>(() => {
    const token = localStorage.getItem(getPathToken());
    const user = localStorage.getItem(getPathUser());

    if (token && user) {
      api.defaults.headers.authorization = `Bearer ${token}`;
      const localUser: IUser = JSON.parse(user);
      return { token, user: localUser };
    }

    return {} as IAuthState;
  });

  const getStorageToken = (): string => {
    const localToken = localStorage.getItem(getPathToken());

    return localToken || '';
  };

  const parseJwt = (localToken: string): IToken => {
    if (localToken === '') {
      return {
        cnpj: '',
        exp: 0,
        iat: 0,
        id_license: '',
        sub: '',
        userAdmin: false,
        userMaster: false,
        lastAcessIdLicense: '',
        lastAcessIdCompany: '',
        tipo_licenca: '',
      };
    }

    const base64Url = localToken.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(
      atob(base64)
        .split('')
        .map(c => {
          return `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`;
        })
        .join(''),
    );

    return JSON.parse(jsonPayload);
  };

  const isMasterUser = useCallback((): boolean => {
    return parseJwt(getStorageToken()).userMaster;
  }, []);

  const signIn = useCallback(async ({ email, password }: ISignInCredentials): Promise<IUser> => {
    const response = await api.post('sessions', {
      email,
      password,
    });

    const { token, user } = response.data;

    localStorage.setItem(getPathToken(), token);
    localStorage.setItem(getPathEmailLogin(), email);

    api.defaults.headers.authorization = `Bearer ${token}`;
    setData({ token, user });

    return user;
  }, []);

  const signOut = useCallback(() => {
    localStorage.removeItem(getPathToken());
    localStorage.removeItem(getPathUser());

    setData({} as IAuthState);

    history.push('/signin');
  }, [history]);

  const updateUser = useCallback(
    (user: IUser) => {
      localStorage.setItem(getPathUser(), JSON.stringify(user));

      setData({
        token: data.token,
        user,
      });
    },
    [setData, data.token],
  );

  const getUserPreferences = useCallback((): IUserPreferences => {
    const userPreferences = {
      lastAcessIdLicense: parseJwt(getStorageToken()).lastAcessIdLicense,
      lastAcessIdCompany: parseJwt(getStorageToken()).lastAcessIdCompany,
    };

    return userPreferences;
  }, []);

  return (
    <AuthContext.Provider
      value={{
        user: data.user,
        signIn,
        signOut,
        updateUser,
        isMasterUser,
        getUserPreferences,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

function useAuth(): IAuthContextData {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }

  return context;
}

function isTokenValid(): boolean {
  const token = localStorage.getItem(getPathToken());
  if (token) {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(
      atob(base64)
        .split('')
        .map(c => {
          return `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`;
        })
        .join(''),
    );

    const localToken = JSON.parse(jsonPayload);
    const current_time = new Date().getTime() / 1000;

    if (current_time > localToken.exp) {
      localStorage.removeItem(getPathToken());
      localStorage.removeItem(getPathUser());
      return false;
    }
  }

  return true;
}

export { AuthProvider, useAuth, isTokenValid };
