import { GridColDef } from '@material-ui/data-grid';

export const ColumnCodigoHistorico: GridColDef = {
  headerName: 'Código',
  field: 'codigo_historico',
  width: 140,
};

export const ColumnDescricaoHistorico: GridColDef = {
  headerName: 'Descrição',
  field: 'descricao_historico',
  width: 500,
};

export const ColumnId: GridColDef = {
  headerName: '#',
  field: 'id',
  hide: true,
  width: 400,
  editable: false,
  filterable: false,
  hideSortIcons: true,
  sortable: false,
  disableColumnMenu: true,
};
