import { GridColDef } from '@material-ui/data-grid';

export const GridColumnBanco: GridColDef = {
  headerName: 'Banco',
  field: 'banco',
  width: 150,
};

export const GridColumnDescricao: GridColDef = {
  headerName: 'Descrição',
  field: 'descricao',
  width: 400,
};

export const GridColumnId: GridColDef = {
  headerName: '#',
  field: 'id',
  hide: true,
  width: 400,
  editable: false,
  filterable: false,
  hideSortIcons: true,
  sortable: false,
  disableColumnMenu: true,
};
