import React, { useCallback } from 'react';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

type functionVoid = () => void;

interface IInputProps {
  formTitle: string;
  buttonCancelText: string;
  buttonConfirmText: string;
  messageConfirm: string;
  open: boolean;
  onClose: functionVoid;
  onConfirm: functionVoid;
  buttonConfirmSecondText?: string;
  onConfirmSecond?: functionVoid;
}

const FormModalDialogConfirm: React.FC<IInputProps> = ({
  formTitle,
  buttonCancelText,
  buttonConfirmText,
  buttonConfirmSecondText,
  messageConfirm,
  open,
  onClose,
  onConfirm,
  onConfirmSecond,
}) => {
  const handleClose = useCallback(() => {
    if (onClose) {
      onClose();
    }
  }, [onClose]);

  const handleOnConfirm = useCallback(() => {
    if (onConfirm) {
      onConfirm();
    }
  }, [onConfirm]);

  const handleOnConfirmSecond = useCallback(() => {
    if (onConfirmSecond) {
      onConfirmSecond();
    }
  }, [onConfirmSecond]);

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{formTitle}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">{messageConfirm}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary" autoFocus>
          {buttonCancelText}
        </Button>

        {buttonConfirmSecondText && buttonConfirmSecondText !== '' && buttonConfirmSecondText !== undefined && (
          <Button onClick={handleOnConfirmSecond} color="primary" autoFocus>
            {buttonConfirmSecondText}
          </Button>
        )}

        <Button onClick={handleOnConfirm} color="primary">
          {buttonConfirmText}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

FormModalDialogConfirm.defaultProps = {
  buttonConfirmSecondText: '',
  onConfirmSecond: undefined,
};

export default FormModalDialogConfirm;
