import React from 'react';

import Dropzone, { DropEvent } from 'react-dropzone';

import { Container, DropContainer, UploadMessage } from './styles';

interface IUploadFileProps {
  onUpload?: <T extends File>(files: T[], event: DropEvent) => void;
  isOFXFile?: boolean;
  isCSVFile?: boolean;
  isTXTFile?: boolean;
  isHTMLFile?: boolean;
  isJSONFile?: boolean;
  isXMLFile?: boolean;
  isExcellFile?: boolean;
  isXLSMFile?: boolean;
  isAllFiles?: boolean;
}

const UploadFile: React.FC<IUploadFileProps> = inValue => {
  const xlsmFile = '.xlsm';
  const excelFile = '.xls, .xlt, .xlsx, .xltx, .ods, .ots';
  const ofxFile = '.ofx';
  const csvFile = '.csv';
  const txtFile = '.txt';
  const htmlFile = '.html';
  const jsonFile = '.json';
  const xmlFile = '.xml';
  let extensao = excelFile;
  if (inValue.isOFXFile) extensao = ofxFile;
  if (inValue.isCSVFile) extensao = `${excelFile}, ${csvFile}`;
  if (inValue.isTXTFile) extensao = txtFile;
  if (inValue.isHTMLFile) extensao = htmlFile;
  if (inValue.isJSONFile) extensao = jsonFile;
  if (inValue.isXMLFile) extensao = xmlFile;
  if (inValue.isExcellFile) extensao = excelFile;
  if (inValue.isXLSMFile) extensao = xlsmFile;
  if (inValue.isAllFiles) {
    extensao = `${excelFile}, ${csvFile}, ${xlsmFile}, ${ofxFile}, ${txtFile}, ${jsonFile}, ${htmlFile}, ${xmlFile}`;
  }

  const renderDragMessage = (isDragActive: boolean): JSX.Element => {
    if (!isDragActive) {
      return (
        <UploadMessage>
          <span>Clique ou arraste arquivos aqui...</span>
          <span
            style={{
              padding: '0px',
              fontSize: '10px',
            }}
          >
            {extensao}
          </span>
        </UploadMessage>
      );
    }
    return <UploadMessage type="success">Solte os arquivos aqui</UploadMessage>;
  };

  return (
    <Container>
      <Dropzone accept={extensao} onDropAccepted={inValue.onUpload}>
        {({ getRootProps, getInputProps, isDragActive, isDragReject }) => (
          <DropContainer
            className="dropzone"
            {...getRootProps()}
            isDragActive={isDragActive}
            isDragReject={isDragReject}
          >
            <input {...getInputProps()} accept={extensao} />
            {renderDragMessage(isDragActive)}
          </DropContainer>
        )}
      </Dropzone>
    </Container>
  );
};

export default UploadFile;
