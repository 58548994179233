import React from 'react';

import { AuthProvider } from './Auth';
import { ToastProvider } from './Toast';
import { LookupProvider } from './Lookup/index';

const AppProvider: React.FC = ({ children }) => {
  return (
    <AuthProvider>
      <ToastProvider>
        <LookupProvider>{children}</LookupProvider>
      </ToastProvider>
    </AuthProvider>
  );
};

export default AppProvider;
