import React, { useState, useCallback, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import { LocalAtm, Description, Dashboard, AccountBalance, PostAdd } from '@material-ui/icons';
import { FaFileUpload, FaFileExport } from 'react-icons/fa';
import ModalUploadFileOFX from '../ModalUploadFileOFX';
import ModalDownloadContabilidade from '../ModalDownloadContabilidade';

import {
  ROUTE_DASHBOARD_POWERBI,
  ROUTE_RECEITAS_DESPESAS,
  ROUTE_CAD_HIST_PADRAO,
  ROUTE_DEM_GER_CAD_CONTA_CORRENTE,
  ROUTE_INDICADOR_PERSON_LCTO,
} from '../../utils/constants';

import { useAuth } from '../../hooks/Auth';

import ButtonDropdownMenu from './ButtonDropdownMenu';
import { ContainerDropdown, DropdownContentUser } from './styles';

const MenuFinacialAccouts: React.FC = () => {
  const history = useHistory();
  const { user } = useAuth();
  const [openUploadFile, setOpenUploadFile] = useState<boolean>(false);
  const [openDownloadContabilidade, setOpenDownloadContabilidade] = useState<boolean>(false);
  const [finMovimentosFile, setFinMovimentosFile] = useState<string>('');

  const handleGoReceitasDespesas = (): void => {
    history.push(ROUTE_RECEITAS_DESPESAS);
  };

  const handleGoCadastroHistoricoPadrao = (): void => {
    history.push(ROUTE_CAD_HIST_PADRAO);
  };

  const handleGoDashboard = (): void => {
    history.push(ROUTE_DASHBOARD_POWERBI);
  };

  const handleGoDemGerCadContaCorrente = (): void => {
    history.push(ROUTE_DEM_GER_CAD_CONTA_CORRENTE);
  };

  const handleGoIndicadoresPersonalizados = (): void => {
    history.push(ROUTE_INDICADOR_PERSON_LCTO);
  };

  const handleGoImportarOFX = (): void => {
    setOpenUploadFile(true);
  };

  const handleGoContabilidade = (): void => {
    setOpenDownloadContabilidade(true);
  };

  const onUploadFileClose = useCallback(() => {
    setOpenUploadFile(false);
  }, []);

  const onCloseDownloadContabilidade = useCallback(() => {
    setOpenDownloadContabilidade(false);
  }, []);

  useEffect(() => {
    if (user.license && user.empresa) {
      setFinMovimentosFile(`/finmovimentos/file/${user.license.id}?id_company=${user.empresa.id}`);
    }
  }, [user.license, user.empresa]);

  return (
    <ContainerDropdown>
      <button type="button">
        <LocalAtm />
      </button>
      <DropdownContentUser>
        <ButtonDropdownMenu onClick={handleGoReceitasDespesas} icon={LocalAtm}>
          <span>Receitas e Despesas</span>
        </ButtonDropdownMenu>

        <ButtonDropdownMenu onClick={handleGoDemGerCadContaCorrente} icon={AccountBalance}>
          <span>Conta corrente</span>
        </ButtonDropdownMenu>

        <ButtonDropdownMenu onClick={handleGoCadastroHistoricoPadrao} icon={Description}>
          <span>Históricos</span>
        </ButtonDropdownMenu>

        <ButtonDropdownMenu onClick={handleGoImportarOFX} icon={FaFileUpload}>
          <span>Importar OFX</span>
        </ButtonDropdownMenu>

        <ButtonDropdownMenu onClick={handleGoContabilidade} icon={FaFileExport}>
          <span>Contabilidade</span>
        </ButtonDropdownMenu>

        <ButtonDropdownMenu onClick={handleGoIndicadoresPersonalizados} icon={PostAdd}>
          <span>Indicadores</span>
        </ButtonDropdownMenu>

        <ButtonDropdownMenu onClick={handleGoDashboard} icon={Dashboard}>
          <span>Dashboard</span>
        </ButtonDropdownMenu>
      </DropdownContentUser>

      <ModalUploadFileOFX open={openUploadFile} onClose={onUploadFileClose} apiSource={finMovimentosFile} />

      <ModalDownloadContabilidade open={openDownloadContabilidade} onClose={onCloseDownloadContabilidade} />
    </ContainerDropdown>
  );
};

export default MenuFinacialAccouts;
