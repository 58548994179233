import * as echarts from 'echarts';

type EChartsOption = echarts.EChartsOption;

const option: EChartsOption = {
  title: {
    text: 'Receitas, Despesas e Saldo Caixa',
  },
  tooltip: {
    trigger: 'axis',
    axisPointer: {
      type: 'cross',
      crossStyle: {
        color: '#999',
      },
    },
  },
  toolbox: {
    left: 'right',
    orient: 'vertical',
    feature: {
      dataView: { show: true, readOnly: true, title: 'Dados' },
      magicType: {
        show: true,
        type: ['line', 'bar'],
        title: {
          bar: 'Alterar para barras',
          line: 'Alterar para linhas',
          stack: 'Agrupar',
        },
      },
      restore: { show: true, title: 'Restaurar' },
      saveAsImage: { show: true, title: 'Salvar imagem' },
    },
  },
  legend: {
    data: [],
  },
  xAxis: [],
  yAxis: [
    {
      type: 'value',
      axisLabel: {
        formatter: 'R$ {value}',
      },
    },
    {
      type: 'value',
      name: 'Saldo',
      axisLabel: {
        formatter: 'R$ {value}',
      },
    },
  ],
  series: [],
};

export default option;
