interface IKey {
  id: number;
}

export default function getListUpdateV2<T extends IKey>(list: T[], value: T): T[] {
  const newList: T[] = [];
  for (let i = 0; i < list.length; i += 1) {
    if (list[i].id === value.id) {
      newList.push(value);
    } else {
      newList.push(list[i]);
    }
  }

  return newList;
}
