import React, { useRef, useCallback } from 'react';
import { Link, useHistory } from 'react-router-dom';
import * as Yup from 'yup';
import { FiLogIn, FiMail, FiLock } from 'react-icons/fi';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';

import { useAuth } from '../../hooks/Auth';
import { useToast } from '../../hooks/Toast';
import getValidationErrors from '../../utils/getValidationErrors';

import logoLoginImg from '../../assets/AssessorTech.png';
import Input from '../../components/Input';
import Button from '../../components/Button';
import api from '../../services/api';
import ILicense from './ILicense';
import { Container, Content, AnimationContainer, Background } from './styles';
import { getPathEmailLogin } from '../../utils/storagePath';

interface ISignInFormData {
  email: string;
  password: string;
}

const SignIn: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const { signIn, isMasterUser } = useAuth();
  const { addToast } = useToast();
  const history = useHistory();
  const storage_email_login = localStorage.getItem(getPathEmailLogin());
  const email_login = storage_email_login || '';

  const handleSubmit = useCallback(
    async (data: ISignInFormData) => {
      formRef.current?.setErrors({});
      try {
        const schema = Yup.object().shape({
          email: Yup.string().required('E-mail é obrigatório').email('Informe um e-mail válido'),
          password: Yup.string().required('A senha é obrigatória'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        const user = await signIn({
          email: data.email,
          password: data.password,
        });

        const response = await api.get<ILicense[]>(`/profile/alllicenses/${user.email}`);
        const licenses: ILicense[] = response.data;
        let userIsAdmin = false;
        if (licenses?.length === 1) {
          userIsAdmin = licenses[0].email_admin_user === user.email;
        }

        if (!isMasterUser() && !userIsAdmin && user?.redirectToCompanyRequest) {
          history.push('/user-request-acess');
        } else {
          history.push('/companychange');
        }
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          formRef.current?.setErrors(errors);
          return;
        }

        addToast({
          type: 'error',
          title: 'Erro na autenticação',
          description: 'Ocorreu um erro ao realizar o login, por favor confira as informações.',
        });
      }
    },
    [signIn, addToast, history, isMasterUser],
  );

  return (
    <Container>
      <Content>
        <AnimationContainer>
          <img src={logoLoginImg} alt="Assessortech" />

          <Form ref={formRef} onSubmit={handleSubmit}>
            <h1>Faça seu logon</h1>

            <Input
              name="email"
              icon={FiMail}
              placeholder="E-mail"
              autoComplete="email"
              defaultValue={email_login}
              autoFocus={email_login === ''}
            />

            <Input
              name="password"
              icon={FiLock}
              type="password"
              placeholder="Senha"
              autoComplete="current-password"
              autoFocus={email_login !== ''}
            />
            <Button type="submit">Entrar</Button>

            <Link to="/forgot-password">Esqueci minha senha</Link>
          </Form>

          <Link to="/signup">
            <FiLogIn />
            Criar conta
          </Link>
        </AnimationContainer>
      </Content>
      <Background />
    </Container>
  );
};

export default SignIn;
