import React from 'react';
import { useHistory } from 'react-router-dom';

import Home from '@material-ui/icons/Home';

import { ROUTE_DASHBOARD_POWERBI } from '../../utils/constants';

import { ContainerDropdown } from './styles';

const MenuHome: React.FC = () => {
  const history = useHistory();

  const handleGoHome = (): void => {
    history.push(ROUTE_DASHBOARD_POWERBI);
  };

  return (
    <ContainerDropdown>
      <button type="button" onClick={handleGoHome}>
        <Home />
      </button>
    </ContainerDropdown>
  );
};

export default MenuHome;
