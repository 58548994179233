import React from 'react';
import { useTransition } from 'react-spring';

import Toast from './Toast';

import { IToastMessage } from '../../hooks/Toast';

import { Container } from './styles';

interface IToastContainerProps {
  messages: IToastMessage[];
}

const ToastContainer: React.FC<IToastContainerProps> = ({ messages }) => {
  const messagesWithTransictions = useTransition(messages, message => message.id, {
    from: { right: '-130%', opacity: 0 },
    enter: { right: '0%', opacity: 1 },
    leave: { right: '-130%', opacity: 0 },
  });

  return (
    <Container>
      {messagesWithTransictions.map(({ item, key, props }) => (
        <Toast key={key} style={props} message={item} />
      ))}
    </Container>
  );
};

export default ToastContainer;
