import { GridColDef } from '@material-ui/data-grid';

export const ColumnName: GridColDef = {
  headerName: 'Name',
  field: 'name',
  width: 200,
};

export const ColumnToken: GridColDef = {
  headerName: 'Token',
  field: 'token',
  width: 400,
};

export const ColumnMacaddress: GridColDef = {
  headerName: 'Macaddress',
  field: 'macaddress',
  width: 400,
};

export const ColumnIdLicense: GridColDef = {
  headerName: 'License',
  field: 'id_license',
  width: 400,
  editable: false,
  filterable: false,
  hideSortIcons: true,
  sortable: false,
};

export const ColumnId: GridColDef = {
  headerName: '#',
  field: 'id',
  hide: true,
  width: 400,
  editable: false,
  filterable: false,
  hideSortIcons: true,
  sortable: false,
  disableColumnMenu: true,
};
