import React, { IframeHTMLAttributes } from 'react';

import { getPathPBIFrameWidth, getPathPBIFrameHeight } from '../../utils/storagePath';

interface IFrameProps extends IframeHTMLAttributes<HTMLIFrameElement> {
  ehDashboard: boolean;
}

const IFramePowerBI: React.FC<IFrameProps> = ({ src, title, ehDashboard }) => {
  let iFrameWidth = localStorage.getItem(getPathPBIFrameWidth());
  let iFrameHeight = localStorage.getItem(getPathPBIFrameHeight());
  if (!iFrameWidth) {
    iFrameWidth = '1260';
    localStorage.setItem(getPathPBIFrameWidth(), iFrameWidth);
  }

  if (!iFrameHeight) {
    iFrameHeight = '746.5';
    localStorage.setItem(getPathPBIFrameHeight(), iFrameHeight);
  }
  if (ehDashboard) {
    iFrameWidth = '1800';
    iFrameHeight = '840';
  }

  return (
    <div>
      <iframe
        style={{ left: 10 }}
        title={title}
        width={iFrameWidth}
        height={iFrameHeight}
        frameBorder="1"
        src={src}
        allowFullScreen
      />
    </div>
  );
};

export default IFramePowerBI;
