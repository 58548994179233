import { GridColDef } from '@material-ui/data-grid';

export const ColumnRemover: GridColDef = {
  headerName: 'Remover',
  field: 'remover',
  type: 'boolean',
  width: 160,
};

export const ColumnTipo: GridColDef = {
  headerName: 'Tipo',
  field: 'tipo',
  width: 250,
};

export const ColumnReferencia: GridColDef = {
  headerName: 'Referência',
  field: 'id_0000',
  width: 300,
};

export const ColumnObservacao: GridColDef = {
  headerName: 'Observação',
  field: 'observacao',
  width: 1000,
  editable: false,
  filterable: false,
  sortable: false,
};

export const ColumnFTP: GridColDef = {
  headerName: 'FTP',
  field: 'ftp',
  type: 'boolean',
  width: 160,
};

export const ColumnFile: GridColDef = {
  headerName: 'Arquivo',
  field: 'ftp_file',
  width: 2000,
  editable: false,
  filterable: false,
  sortable: false,
};

export const ColumnId: GridColDef = {
  headerName: '#',
  field: 'id',
  hide: true,
  width: 400,
  editable: false,
  filterable: false,
  hideSortIcons: true,
  sortable: false,
  disableColumnMenu: true,
};
