function getPathToken(): string {
  return '@AssessortechWEBCli:token';
}

function getPathUser(): string {
  return '@AssessortechWEBCli:user';
}

function getPathGrid(): string {
  return '@AssessortechWEBCli:grid';
}

function getPathEmailLogin(): string {
  return '@AssessortechWEBCli:email_login';
}

function getPathLastLicense(id_user: string): string {
  return `@AssessortechWEBCli:lastAcess[${id_user}]`;
}

function getPathLastCompany(id_user: string): string {
  return `@AssessortechWEBCli:lastCompany[${id_user}]`;
}

function getPathPBIFrameWidth(): string {
  return '@AssessortechWEBCli:PBIFrameWidth';
}

function getPathPBIFrameHeight(): string {
  return '@AssessortechWEBCli:PBIFrameHeight';
}

export {
  getPathToken,
  getPathUser,
  getPathEmailLogin,
  getPathLastLicense,
  getPathLastCompany,
  getPathPBIFrameWidth,
  getPathPBIFrameHeight,
  getPathGrid,
};
