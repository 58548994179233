import React from 'react';
import { useHistory } from 'react-router-dom';

import VerifiedUser from '@material-ui/icons/VerifiedUser';
import AssignmentInd from '@material-ui/icons/AssignmentInd';
import List from '@material-ui/icons/List';
import PlaylistAdd from '@material-ui/icons/PlaylistAdd';

import { ROUTE_LICENSE, ROUTE_TIPO_DEM_GER, ROUTE_DEM_GER } from '../../utils/constants';

import ButtonDropdownMenu from './ButtonDropdownMenu';
import { ContainerDropdown, DropdownContent } from './styles';

const MenuSuperUser: React.FC = () => {
  const history = useHistory();

  const handleGoLicense = (): void => {
    history.push(ROUTE_LICENSE);
  };

  const handleGoTipoDemGer = (): void => {
    history.push(ROUTE_TIPO_DEM_GER);
  };

  const handleGoDemGer = (): void => {
    history.push(ROUTE_DEM_GER);
  };

  return (
    <ContainerDropdown>
      <button type="button">
        <VerifiedUser />
      </button>
      <DropdownContent>
        <ButtonDropdownMenu onClick={handleGoLicense} icon={AssignmentInd}>
          <span>Licenciamento</span>
        </ButtonDropdownMenu>
        <ButtonDropdownMenu onClick={handleGoTipoDemGer} icon={List}>
          <span>Demonstrativos</span>
        </ButtonDropdownMenu>
        <ButtonDropdownMenu onClick={handleGoDemGer} icon={PlaylistAdd}>
          <span>Totalizadores</span>
        </ButtonDropdownMenu>
      </DropdownContent>
    </ContainerDropdown>
  );
};

export default MenuSuperUser;
