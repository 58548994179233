export default interface IIndicadorPersonalizadoLcto {
  kind_interface: 'indic_person_lcto';
  acao: string;
  id_indic_person_lcto: string;
  id_license: string;
  id_company: string;
  id_cad_indic_person: string;
  ano: number;
  tipo_apontamento: string;
  vlr_janeiro: number;
  vlr_fevereiro: number;
  vlr_marco: number;
  vlr_abril: number;
  vlr_maio: number;
  vlr_junho: number;
  vlr_julho: number;
  vlr_agosto: number;
  vlr_setembro: number;
  vlr_outubro: number;
  vlr_novembro: number;
  vlr_dezembro: number;
  obs: string;
  inf1: string;
  inf2: string;
  inf3: string;
  inf4: string;
  inf5: string;
  cod_indicador: string;
  desc_indicador: string;
  file_name: string;
  file_path: string;
  file_type: string;
}

export const emptyIndicadorPersonalizadoLcto: IIndicadorPersonalizadoLcto = {
  kind_interface: 'indic_person_lcto',
  acao: '',
  id_indic_person_lcto: '',
  id_license: '',
  id_company: '',
  id_cad_indic_person: '',
  ano: 0,
  tipo_apontamento: '1-Meta',
  vlr_janeiro: 0,
  vlr_fevereiro: 0,
  vlr_marco: 0,
  vlr_abril: 0,
  vlr_maio: 0,
  vlr_junho: 0,
  vlr_julho: 0,
  vlr_agosto: 0,
  vlr_setembro: 0,
  vlr_outubro: 0,
  vlr_novembro: 0,
  vlr_dezembro: 0,
  obs: '',
  inf1: '',
  inf2: '',
  inf3: '',
  inf4: '',
  inf5: '',
  cod_indicador: '',
  desc_indicador: '',
  file_name: '',
  file_path: '',
  file_type: '',
};
