export default interface IBancoLookup {
  id: string;
  banco: string;
  descricao: string;
}

export const emptyBancoLookup: IBancoLookup = {
  id: '',
  banco: '',
  descricao: '',
};
