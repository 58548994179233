import React, { useRef, useState, useEffect, useCallback } from 'react';
import * as Yup from 'yup';

import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';

import { DataGrid, GridColDef, GridCellParams, GridRowId, ptBR } from '@material-ui/data-grid';
import { createMuiTheme, ThemeProvider, makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';

import { FiCopy } from 'react-icons/fi';
import { MdDelete, MdEdit, MdClear, MdSkipNext, MdSkipPrevious, MdContentCopy } from 'react-icons/md';
import {
  AiOutlinePlus,
  AiOutlineSave,
  AiFillFastBackward,
  AiFillFastForward,
  AiOutlineCaretDown,
  AiOutlineCaretUp,
} from 'react-icons/ai';
import { GoPlus } from 'react-icons/go';
import { RiDeleteBinLine } from 'react-icons/ri';

import InputComum from '../../components/InputComum';
import Select from '../../components/Select';
import Header from '../../components/Header';
import FormModalDialogConfirm from '../../components/FormModalDialogConfirm';

import api from '../../services/api';
import { useAuth } from '../../hooks/Auth';
import { useToast } from '../../hooks/Toast';

import getListUpdate from '../../utils/getListUpdate';
import { getPathGrid } from '../../utils/storagePath';
import cnpj from '../../utils/getValidationCNPJ';
import getValidationErrors from '../../utils/getValidationErrors';

import {
  ColumnCNPJ,
  ColumnDemonstrativo,
  ColumnCodigoTotalizador,
  ColumnNivelTot,
  ColumnTotTitulo,
  ColumnClassificacaoTotalizador,
  ColumnGrupoContas,
  ColumnDescricaoTotalizador,
  ColumnTipoTotalizacao,
  ColumnCodigoTotalizadorDescarga,
  ColumnObservacao,
  ColumnInf1,
  ColumnInf2,
  ColumnInf3,
  ColumnInf4,
  ColumnInf5,
  ColumnId,
} from './GridColumns';

import IDemGer from './IDemGer';
import ITipoDemGer from './ITipoDemGer';

import {
  Container,
  ContainerFields,
  Tab,
  TabButton,
  TabContent,
  FormFields,
  FormContainer,
  FormNavigate,
  TabTitulo,
  TabButtonArea,
  TabButtonNovo,
  TabButtonDelete,
  TabButtonGridRowHeight,
} from './styles';

const useStylesLoading = makeStyles((theme: Theme) =>
  createStyles({
    backdrop: {
      zIndex: theme.zIndex.drawer + 2000,
      color: '#fff',
    },
  }),
);

const theme = createMuiTheme(
  {
    palette: {
      primary: { main: '#006699' },
    },
  },
  ptBR,
);

const emptyDemGer: IDemGer = {
  acao: '',
  id: '',
  id_license: '',
  id_tipo_dem_ger: '',
  cnpj_estab_tot: '',
  cod_tot: '',
  classif_tot: '',
  nivel_tot: 0,
  tot_titulo: '',
  tp_totalizacao: '',
  cod_tot_descarga: '',
  nome_tot: '',
  cod_nat: '',
  obs: '',
  inf1: '',
  inf2: '',
  inf3: '',
  inf4: '',
  inf5: '',
  demonstrativo: '',
};

const idfieldSelectTipoDemGer = 'b0f00001j2ihfd63nd';

const titulo = 'T O T A L I Z A D O R E S';

const options_titulo = [
  { value: '1-Sintético', label: '1-Sintético' },
  { value: '2-Analítico', label: '2-Analítico' },
];

const options_cod_nat = [
  { value: '01-Contas de Ativo', label: '01-Contas de Ativo' },
  { value: '02-Contas de Passivo', label: '02-Contas de Passivo' },
  { value: '03-Patrimônio Líquido', label: '03-Patrimônio Líquido' },
  { value: '04-Contas de Resultado', label: '04-Contas de Resultado' },
  { value: '05-Contas de Compensação', label: '05-Contas de Compensação' },
  { value: '09-Outras', label: '09-Outras' },
];

const options_tp_totalizacao = [
  { value: '1-Para Cima', label: '1-Para Cima' },
  { value: '2-Para Baixo', label: '2-Para Baixo' },
  { value: '3-Não Totalizar', label: '3-Não Totalizar' },
];

const DemGer: React.FC = () => {
  const { user } = useAuth();
  const { addToast } = useToast();
  const formRef = useRef<FormHandles>(null);
  const [demGerParametro, setDemGer] = useState<IDemGer>();
  const [listDemGer, setListDemGer] = useState<IDemGer[]>([]);
  const [listTipoDemGer, setListTipoDemGer] = useState<ITipoDemGer[]>([]);
  const [hiddenPesquisar, setHiddenPesquisar] = useState<boolean>(true);
  const [hiddenCadastrar, setHiddenCadastrar] = useState<boolean>(true);
  const [demGerIndex, setDemGerIndex] = useState<number>(0);
  const [rowToEdit, setRowToEdit] = useState(null);
  const [idDeleteDemGer, setIdDeleteDemGer] = useState<string>('');
  const [selectedIds, setSelectedIds] = useState<GridRowId[]>([]);
  const [openDeleteSingle, setOpenDeleteSingle] = useState(false);
  const [openDeleteMultiple, setOpenDeleteMultiple] = useState(false);
  const [openLoading, setOpenLoading] = useState(false);
  const cssLoading = useStylesLoading();
  const [gridRowHeight, setGridRowHeight] = useState<number>(46);

  const handleGridRowHeightUp = useCallback(async () => {
    const heightValue: number = gridRowHeight + 1;
    setGridRowHeight(heightValue);
    localStorage.setItem(getPathGrid(), heightValue.toString());
  }, [gridRowHeight]);

  const handleGridRowHeightDown = useCallback(async () => {
    const heightValue: number = gridRowHeight - 1;
    setGridRowHeight(heightValue);
    localStorage.setItem(getPathGrid(), heightValue.toString());
  }, [gridRowHeight]);

  const licencaInvalida = useCallback((): boolean => {
    if (!user.license || user.license.id === '') {
      addToast({
        type: 'error',
        title: 'Licença inválida',
        description: 'Usuário não está vinculado a nenhuma licença',
      });

      return true;
    }

    return false;
  }, [addToast, user]);

  const getDescricaoTipoDemGerSelectOption = useCallback((value: ITipoDemGer): string => {
    if (!value) return '';

    return `${value.codigo.trim()}-${value.descricao.trim()}`;
  }, []);

  const getTipoDemGerSelectOption = useCallback(
    (id: string): ITipoDemGer => {
      return listTipoDemGer.filter(item => item.id === id)[0];
    },
    [listTipoDemGer],
  );

  const defineDemGer = useCallback(async (data: IDemGer) => {
    setDemGer(data);
    formRef.current?.setData(data);
  }, []);

  const onNewRegister = useCallback(async () => {
    defineDemGer(emptyDemGer);
  }, [defineDemGer]);

  const OnGridSetRegisterToEdit = useCallback(
    async (params: GridCellParams) => {
      const localDemGer = params.row as IDemGer;

      setDemGerIndex(listDemGer.indexOf(localDemGer));
      setHiddenCadastrar(false);
      setHiddenPesquisar(true);
      setRowToEdit(null);
      defineDemGer(localDemGer);
    },
    [listDemGer, defineDemGer],
  );

  const OnSetRegisterToAdd = useCallback(async () => {
    onNewRegister();
    setHiddenCadastrar(false);
    setHiddenPesquisar(true);
  }, [onNewRegister]);

  const OnGridRowDuplicate = useCallback(
    async (baseDemGer: IDemGer | undefined) => {
      if (baseDemGer !== undefined) {
        const newDemGer: IDemGer = {
          acao: baseDemGer.acao,
          id: '',
          id_license: baseDemGer.id_license,
          id_tipo_dem_ger: baseDemGer.id_tipo_dem_ger,
          cnpj_estab_tot: baseDemGer.cnpj_estab_tot,
          cod_tot: baseDemGer.cod_tot,
          classif_tot: baseDemGer.classif_tot,
          nivel_tot: baseDemGer.nivel_tot,
          tot_titulo: baseDemGer.tot_titulo,
          tp_totalizacao: baseDemGer.tp_totalizacao,
          cod_tot_descarga: baseDemGer.cod_tot_descarga,
          nome_tot: baseDemGer.nome_tot,
          cod_nat: baseDemGer.cod_nat,
          obs: baseDemGer.obs,
          inf1: baseDemGer.inf1,
          inf2: baseDemGer.inf2,
          inf3: baseDemGer.inf3,
          inf4: baseDemGer.inf4,
          inf5: baseDemGer.inf5,
          demonstrativo: baseDemGer.demonstrativo,
        };

        defineDemGer(newDemGer);
        setHiddenCadastrar(false);
        setHiddenPesquisar(true);
      }
    },
    [defineDemGer],
  );

  const OnConfirmationDeleteSingle = useCallback(
    async (id: string) => {
      if (licencaInvalida()) return;

      if (id === '') return;

      setIdDeleteDemGer(id);
      setOpenDeleteSingle(true);
    },
    [licencaInvalida],
  );

  const colunaAcao: GridColDef = {
    field: 'acao',
    headerName: 'Ação',
    editable: false,
    filterable: false,
    hideSortIcons: true,
    sortable: false,
    disableColumnMenu: true,
    width: 160,
    renderCell: (params: GridCellParams) => (
      <strong>
        <Tooltip title="Editar Registro" placement="top">
          <IconButton color="primary" aria-label="Editar" onClick={() => OnGridSetRegisterToEdit(params)}>
            <MdEdit />
          </IconButton>
        </Tooltip>

        <Tooltip title="Duplicar Registro" placement="top">
          <IconButton color="primary" aria-label="Duplicar" onClick={() => OnGridRowDuplicate(params.row as IDemGer)}>
            <FiCopy />
          </IconButton>
        </Tooltip>

        <Tooltip title="Excluir Registro" placement="top">
          <IconButton
            color="primary"
            aria-label="Excluir"
            onClick={() => OnConfirmationDeleteSingle((params.row as IDemGer).id)}
          >
            <MdDelete />
          </IconButton>
        </Tooltip>
      </strong>
    ),
  };

  const columns: GridColDef[] = [
    colunaAcao,
    ColumnCNPJ,
    ColumnDemonstrativo,
    ColumnCodigoTotalizador,
    ColumnNivelTot,
    ColumnTotTitulo,
    ColumnClassificacaoTotalizador,
    ColumnGrupoContas,
    ColumnDescricaoTotalizador,
    ColumnTipoTotalizacao,
    ColumnCodigoTotalizadorDescarga,
    ColumnObservacao,
    ColumnInf1,
    ColumnInf2,
    ColumnInf3,
    ColumnInf4,
    ColumnInf5,
    ColumnId,
  ];

  const getTipoDemGer = useCallback(async () => {
    const response = await api.get<ITipoDemGer[]>(`/tipodemger/all`);
    setListTipoDemGer(response.data);
  }, []);

  const getDemGer = useCallback(async () => {
    const response = await api.get<IDemGer[]>(`/demger/all`);
    setListDemGer(response.data);
    if (rowToEdit !== null) {
      const findDemGer = response.data.find(item => item.id === rowToEdit);

      if (findDemGer) {
        defineDemGer(findDemGer);
        setDemGerIndex(response.data.indexOf(findDemGer));
        setHiddenCadastrar(false);
        setHiddenPesquisar(true);
        setRowToEdit(null);
      }
    }
  }, [defineDemGer, rowToEdit]);

  const getValues = useCallback(async () => {
    setOpenLoading(true);

    await getTipoDemGer();
    await getDemGer();

    setOpenLoading(false);
    setHiddenPesquisar(false);
  }, [getTipoDemGer, getDemGer]);

  useEffect(() => {
    getValues();

    const rowHeight = localStorage.getItem(getPathGrid());
    if (rowHeight) {
      setGridRowHeight(parseInt(rowHeight, 10));
    }
  }, [getValues]);

  const handleOnRowDeleteSingle = useCallback(async () => {
    setOpenLoading(true);

    await api.delete(`/demger/${idDeleteDemGer}`);

    setListDemGer(oldDemGer => oldDemGer.filter(item => item.id !== idDeleteDemGer));

    defineDemGer(emptyDemGer);

    addToast({
      type: 'success',
      title: 'Removido',
      description: 'Registro excluído com sucesso!',
    });

    setOpenDeleteSingle(false);
    setOpenLoading(false);
  }, [idDeleteDemGer, addToast, defineDemGer]);

  const handleOnRowDeleteMultiple = useCallback(async () => {
    setOpenLoading(true);

    if (selectedIds.length > 0) {
      await Promise.all(
        selectedIds.map(itemId => {
          setListDemGer(oldDemGer => oldDemGer.filter(item => item.id !== itemId));

          return api.delete(`/demger/${itemId}`);
        }),
      );

      defineDemGer(emptyDemGer);

      addToast({
        type: 'success',
        title: 'Removido',
        description: 'Registro excluído com sucesso!',
      });
    }

    setSelectedIds([]);
    setOpenDeleteMultiple(false);
    setOpenLoading(false);
  }, [addToast, defineDemGer, selectedIds]);

  const handleClick = (id: string): void => {
    setHiddenCadastrar(id === 'pesquisar');
    setHiddenPesquisar(id === 'cadastrar');
  };

  const onNextRegister = useCallback(async () => {
    if (demGerIndex !== undefined) {
      if (demGerIndex < listDemGer.length - 1) {
        const localIndex = demGerIndex + 1;
        setDemGerIndex(localIndex);
        const findItem = listDemGer[localIndex];
        if (findItem) {
          defineDemGer(findItem);
        }
      }
    }
  }, [demGerIndex, listDemGer, defineDemGer]);

  const onFirstRegister = useCallback(async () => {
    if (listDemGer.length > 0) {
      setDemGerIndex(0);
      const findItem = listDemGer[0];
      if (findItem) {
        defineDemGer(findItem);
      }
    }
  }, [listDemGer, defineDemGer]);

  const onLastRegister = useCallback(async () => {
    if (listDemGer.length > 0) {
      setDemGerIndex(listDemGer.length - 1);
      const findItem = listDemGer[listDemGer.length - 1];
      if (findItem) {
        defineDemGer(findItem);
      }
    }
  }, [listDemGer, defineDemGer]);

  const onPriorRegister = useCallback(async () => {
    if (demGerIndex !== undefined) {
      if (demGerIndex > 0) {
        const localIndex = demGerIndex - 1;
        setDemGerIndex(localIndex);
        const findItem = listDemGer[localIndex];
        if (findItem) {
          defineDemGer(findItem);
        }
      }
    }
  }, [demGerIndex, listDemGer, defineDemGer]);

  const onCancelRegister = useCallback(async () => {
    formRef.current?.setErrors({});

    if (demGerIndex !== undefined) {
      const findItem = listDemGer[demGerIndex];
      if (findItem) {
        defineDemGer(findItem);
      }
    }

    onNewRegister();
  }, [demGerIndex, listDemGer, onNewRegister, defineDemGer]);

  const handleSubmit = useCallback(
    async (data: IDemGer, { reset }) => {
      if (licencaInvalida()) return;

      const fieldSelectTipoDemGer = document.getElementById(idfieldSelectTipoDemGer);
      if (fieldSelectTipoDemGer) {
        const id = (fieldSelectTipoDemGer as HTMLSelectElement).value;
        data.id_tipo_dem_ger = id;
        data.demonstrativo = getDescricaoTipoDemGerSelectOption(getTipoDemGerSelectOption(id));
      }

      formRef.current?.setErrors({});
      try {
        const schema = Yup.object().shape({
          cnpj_estab_tot: Yup.string()
            .required('CNPJ inválido')
            .test('cnpj-valid', 'CNPJ inválido', value => {
              return cnpj.isValid(value as string);
            }),
          id_tipo_dem_ger: Yup.string().required('Demonstrativo é obrigatório'),
          cod_tot: Yup.string().required('Código do totalizador é obrigatório'),
          tot_titulo: Yup.string().required('Título é obrigatório'),
          classif_tot: Yup.string().required('Classificação do totalizador é obrigatório'),
          cod_nat: Yup.string().required('Grupo de contas é obrigatório'),
          nome_tot: Yup.string().required('Descrição é obrigatório'),
          tp_totalizacao: Yup.string().required('Tipo totalização é obrigatório'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        setOpenLoading(true);
        if (data.id === '') {
          const formData = {
            id_license: user.license.id,
            id_tipo_dem_ger: data.id_tipo_dem_ger,
            cnpj_estab_tot: data.cnpj_estab_tot,
            cod_tot: data.cod_tot,
            classif_tot: data.classif_tot,
            nivel_tot: data.nivel_tot,
            tot_titulo: data.tot_titulo,
            tp_totalizacao: data.tp_totalizacao,
            cod_tot_descarga: data.cod_tot_descarga,
            nome_tot: data.nome_tot,
            cod_nat: data.cod_nat,
            obs: data.obs,
            inf1: data.inf1,
            inf2: data.inf2,
            inf3: data.inf3,
            inf4: data.inf4,
            inf5: data.inf5,
            demonstrativo: data.demonstrativo,
          };

          await api.post(`/demger`, formData);

          setListDemGer(oldLicenses => [...oldLicenses, data]);
          addToast({
            type: 'success',
            title: 'Adicionado',
            description: 'Registro adicionado com sucesso!',
          });
        } else {
          const formEdit = {
            id_license: user.license.id,
            id_tipo_dem_ger: data.id_tipo_dem_ger,
            cnpj_estab_tot: data.cnpj_estab_tot,
            cod_tot: data.cod_tot,
            classif_tot: data.classif_tot,
            nivel_tot: data.nivel_tot,
            tot_titulo: data.tot_titulo,
            tp_totalizacao: data.tp_totalizacao,
            cod_tot_descarga: data.cod_tot_descarga,
            nome_tot: data.nome_tot,
            cod_nat: data.cod_nat,
            obs: data.obs,
            inf1: data.inf1,
            inf2: data.inf2,
            inf3: data.inf3,
            inf4: data.inf4,
            inf5: data.inf5,
            demonstrativo: data.demonstrativo,
          };

          await api.put(`/demger/${data.id}`, formEdit);
          setListDemGer(getListUpdate(listDemGer, data));
          addToast({
            type: 'success',
            title: 'Alterado',
            description: 'Registro alterado com sucesso!',
          });
        }

        reset();
        setOpenLoading(false);
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          formRef.current?.setErrors(errors);
        }
        setOpenLoading(false);
      }
    },
    [licencaInvalida, listDemGer, addToast, user, getDescricaoTipoDemGerSelectOption, getTipoDemGerSelectOption],
  );

  return (
    <Container>
      <Header />

      <Tab>
        <TabButtonArea>
          <TabButton id="pesquisar" isActive={!hiddenPesquisar} onClick={() => handleClick('pesquisar')}>
            Listagem
          </TabButton>
          <TabButton id="cadastrar" isActive={!hiddenCadastrar} onClick={() => handleClick('cadastrar')}>
            Cadastro
          </TabButton>

          <Tooltip title="Reduzir o tamanho da linha" placement="top">
            <TabButtonGridRowHeight id="gridrowheightdown" onClick={() => handleGridRowHeightDown()}>
              <AiOutlineCaretDown />
            </TabButtonGridRowHeight>
          </Tooltip>

          <Tooltip title="Aumentar o tamanho da linha" placement="top">
            <TabButtonGridRowHeight id="gridrowheightup" onClick={() => handleGridRowHeightUp()}>
              <AiOutlineCaretUp />
            </TabButtonGridRowHeight>
          </Tooltip>

          <Tooltip title="Novo Registro" placement="top">
            <TabButtonNovo id="new_register" onClick={() => OnSetRegisterToAdd()}>
              <GoPlus />
              Novo
            </TabButtonNovo>
          </Tooltip>

          <Tooltip title="Remover Registros Selecionados" placement="top">
            <span>
              <span hidden={selectedIds.length <= 0}>
                <TabButtonDelete
                  id="remove_all"
                  onClick={() => {
                    setIdDeleteDemGer('');
                    setOpenDeleteMultiple(true);
                  }}
                >
                  <RiDeleteBinLine />
                  Remover
                </TabButtonDelete>
              </span>
            </span>
          </Tooltip>
        </TabButtonArea>

        <TabTitulo>
          <span>{titulo}</span>
        </TabTitulo>
      </Tab>

      <TabContent id="pesquisar" isActive={!hiddenPesquisar}>
        <div style={{ height: '85vh', width: '100%' }}>
          <ThemeProvider theme={theme}>
            <DataGrid
              rows={listDemGer}
              columns={columns}
              rowHeight={gridRowHeight}
              checkboxSelection
              selectionModel={selectedIds}
              disableSelectionOnClick
              onSelectionModelChange={e => setSelectedIds(e.selectionModel)}
            />
          </ThemeProvider>
        </div>
      </TabContent>

      <TabContent id="cadasto" isActive={!hiddenCadastrar}>
        <Form ref={formRef} onSubmit={handleSubmit} initialData={demGerParametro}>
          <FormContainer>
            <FormNavigate>
              <Tooltip title="Novo Registro" placement="top">
                <IconButton color="primary" aria-label="adicionar" onClick={() => onNewRegister()}>
                  <AiOutlinePlus />
                </IconButton>
              </Tooltip>

              <Tooltip title="Duplicar Registro" placement="top">
                <IconButton color="primary" aria-label="Duplicar" onClick={() => OnGridRowDuplicate(demGerParametro)}>
                  <MdContentCopy />
                </IconButton>
              </Tooltip>

              <Tooltip title="Excluir Registro" placement="top">
                <IconButton
                  color="primary"
                  aria-label="Excluir"
                  onClick={() => OnConfirmationDeleteSingle(demGerParametro ? demGerParametro.id : '')}
                >
                  <RiDeleteBinLine />
                </IconButton>
              </Tooltip>

              <Tooltip title="Cancelar" placement="top">
                <IconButton type="reset" color="primary" aria-label="Cancelar" onClick={() => onCancelRegister()}>
                  <MdClear />
                </IconButton>
              </Tooltip>

              <Tooltip title="Salvar" placement="top">
                <IconButton type="submit" color="primary" aria-label="Confirmar">
                  <AiOutlineSave />
                </IconButton>
              </Tooltip>

              <Tooltip title="Primeiro Registro" placement="top">
                <IconButton color="primary" aria-label="Primeiro" onClick={() => onFirstRegister()}>
                  <AiFillFastBackward />
                </IconButton>
              </Tooltip>

              <Tooltip title="Registro Anterior" placement="top">
                <IconButton color="primary" aria-label="Anterior" onClick={() => onPriorRegister()}>
                  <MdSkipPrevious />
                </IconButton>
              </Tooltip>

              <Tooltip title="Próximo Registro" placement="top">
                <IconButton color="primary" aria-label="Próximo" onClick={() => onNextRegister()}>
                  <MdSkipNext />
                </IconButton>
              </Tooltip>

              <Tooltip title="Último Registro" placement="top">
                <IconButton color="primary" aria-label="Último registro" onClick={() => onLastRegister()}>
                  <AiFillFastForward />
                </IconButton>
              </Tooltip>
            </FormNavigate>

            <FormFields>
              <ContainerFields field="T200px">
                <span>CNPJ:</span>
                <InputComum autoFocus name="cnpj_estab_tot" />
              </ContainerFields>
              <ContainerFields field="T100">
                <span>Demonstrativo:</span>
                <Select id={idfieldSelectTipoDemGer} name="id_tipo_dem_ger">
                  {listTipoDemGer.map(item => (
                    <option key={item.id} value={item.id}>
                      {getDescricaoTipoDemGerSelectOption(item)}
                    </option>
                  ))}
                </Select>
              </ContainerFields>
              <ContainerFields field="T200px">
                <span>Código Totalizador:</span>
                <InputComum autoFocus name="cod_tot" />
              </ContainerFields>
              <ContainerFields field="T200px">
                <span>Nível:</span>
                <InputComum autoFocus name="nivel_tot" placeholder="0" type="number" step="1" />
              </ContainerFields>
              <ContainerFields field="T300px">
                <span>Título:</span>
                <Select name="tot_titulo">
                  {options_titulo.map(item => (
                    <option key={item.value} value={item.value}>
                      {item.label}
                    </option>
                  ))}
                </Select>
              </ContainerFields>
              <ContainerFields field="T100">
                <span>Classificação Totalizador:</span>
                <InputComum autoFocus name="classif_tot" />
              </ContainerFields>
              <ContainerFields field="T300px">
                <span>Grupo Contas:</span>
                <Select name="cod_nat">
                  {options_cod_nat.map(item => (
                    <option key={item.value} value={item.value}>
                      {item.label}
                    </option>
                  ))}
                </Select>
              </ContainerFields>
              <ContainerFields field="T100">
                <span>Descrição Totalizador:</span>
                <InputComum autoFocus name="nome_tot" />
              </ContainerFields>
              <ContainerFields field="T300px">
                <span>Tipo totalização:</span>
                <Select name="tp_totalizacao">
                  {options_tp_totalizacao.map(item => (
                    <option key={item.value} value={item.value}>
                      {item.label}
                    </option>
                  ))}
                </Select>
              </ContainerFields>
              <ContainerFields field="T100">
                <span>Código Totalizador Descarga:</span>
                <InputComum autoFocus name="cod_tot_descarga" />
              </ContainerFields>
              <ContainerFields field="T100">
                <span>Observação:</span>
                <InputComum autoFocus name="obs" />
              </ContainerFields>
              <ContainerFields field="T100">
                <span>Informação 1:</span>
                <InputComum autoFocus name="inf1" />
              </ContainerFields>
              <ContainerFields field="T100">
                <span>Informação 2:</span>
                <InputComum autoFocus name="inf2" />
              </ContainerFields>
              <ContainerFields field="T100">
                <span>Informação 3:</span>
                <InputComum autoFocus name="inf3" />
              </ContainerFields>
              <ContainerFields field="T100">
                <span>Informação 4:</span>
                <InputComum autoFocus name="inf4" />
              </ContainerFields>
              <ContainerFields field="T100">
                <span>Informação 5:</span>
                <InputComum autoFocus name="inf5" className="lastField" />
              </ContainerFields>
              <ContainerFields field="id" className="lastField">
                <InputComum name="id" hidden />
              </ContainerFields>
            </FormFields>
          </FormContainer>
        </Form>
      </TabContent>

      <FormModalDialogConfirm
        open={openDeleteSingle}
        onClose={() => setOpenDeleteSingle(false)}
        onConfirm={() => handleOnRowDeleteSingle()}
        buttonCancelText="Cancelar"
        buttonConfirmText="Sim"
        formTitle="Excluir?"
        messageConfirm="Tem certeza que deseja excluir?"
      />

      <FormModalDialogConfirm
        open={openDeleteMultiple}
        onClose={() => setOpenDeleteMultiple(false)}
        onConfirm={() => handleOnRowDeleteMultiple()}
        buttonCancelText="Cancelar"
        buttonConfirmText="Sim"
        formTitle="Excluir?"
        messageConfirm="Tem certeza que deseja excluir todos os registros selecionados?"
      />

      <Backdrop className={cssLoading.backdrop} open={openLoading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </Container>
  );
};

export default DemGer;
