import styled, { css } from 'styled-components';

interface IContainerProps {
  isActive: boolean;
}

interface IContainerFieldsProps {
  field:
    | 'code'
    | 'cnpj'
    | 'text'
    | 'check'
    | 'id'
    | 'date'
    | 'price'
    | 'select';
}

export const Container = styled.div`
  > header {
    height: 100px;
    background: #28262e;

    display: flex;
    align-items: center;

    div {
      width: 100%;
      max-width: 20px;
      margin: 40px;

      svg {
        color: #999591;
        width: 24px;
        height: 24px;
      }
    }
  }
`;

export const Content = styled.div`
  display: flex;
  max-width: 3000px;
  align-items: center;
  justify-content: center;
  margin: 10px auto;
`;

export const Tab = styled.div`
  overflow: hidden;
  border: 1px solid #fff;
  background-color: #fff;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-areas: 'tabbuttoms tabtitle';

  svg {
    background-color: #fff;
    color: #006699;
    width: 22px;
    height: 22px;

    &:hover {
      background-color: #006699;
      opacity: 0.8;
      font-size: 18px;
      color: #fff;
      font-weight: bold;
    }
  }

  @media (max-width: 1020px) {
    grid-template-columns: 1fr;
    grid-template-areas:
      'tabtitle'
      'tabbuttoms';
  }
`;

export const TabTitulo = styled.div`
  grid-area: tabtitle;
  width: 100%;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;

  span {
    margin-top: 10px;
    width: 100%;
    height: 100%;
    color: #006699;
    font-weight: bold;
    font-family: sans-serif;
    text-align: left;
    white-space: pre-wrap;
  }

  @media (max-width: 1020px) {
    span {
      text-align: center;
      white-space: pre-wrap;
    }
  }
`;

export const TabButtonArea = styled.div`
  grid-area: tabbuttoms;
  display: flex;
  flex-direction: row;
  align-items: center;
  align-content: center;
  height: 100%;
`;

export const TabButton = styled.button<IContainerProps>`
  background-color: inherit;
  float: left;
  border: none;
  outline: none;
  cursor: pointer;
  padding: 10px 12px;
  transition: 0.3s;
  font-size: 16px;
  font-family: sans-serif;

  &:hover {
    background-color: #006699;
    opacity: 0.8;
    font-size: 18px;
    color: #fff;
    font-weight: bold;

    svg {
      background-color: #006699;
      opacity: 0.8;
      color: #fff;
    }
  }

  &:active {
    background-color: #006699;
    opacity: 0.2;
  }

  ${props =>
    props.isActive &&
    css`
      background-color: #006699;
      color: #fff;
      font-weight: bold;
      font-size: 16px;
    `}
`;

export const TabContent = styled.div<IContainerProps>`
  /* Style the tab content */
  display: none;
  background-color: #fff;
  border: 1px solid #666360;
  border-radius: 2px;
  height: 100%;

  form {
    height: 100%;
  }

  ${props =>
    props.isActive &&
    css`
      display: block;
    `}
`;

export const TabContentForm = styled.div<IContainerProps>`
  /* Style the tab content */
  display: none;
  background-color: #fff;

  ${props =>
    props.isActive &&
    css`
      display: block;
    `}
`;

export const FormFields = styled.div`
  margin-left: 20px;
  margin-right: 20px;
  width: 800px;

  span {
    margin: 10px 0;
    text-align: left;
  }

  @media (max-width: 810px) {
    width: 600px;
  }

  @media (max-width: 720px) {
    width: 450px;
  }
`;

export const FormButtons = styled.div`
  width: 450px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  button {
    margin: 0 10px 10px 0;
  }
`;

export const FormButtonDelete = styled.div`
  cursor: pointer;
  width: 80px;

  svg {
    color: #dc143c;
    width: 55px;
    height: 55px;
  }
`;

export const ContainerFieldCheckbox = styled.div`
  margin-top: 6px;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
`;

export const ContainerAllMonthPrice = styled.div`
  display: flex;
  flex-direction: column;

  @media (max-width: 720px) {
    flex-direction: column;
  }
`;

export const ContainerMonthPrice = styled.div`
  display: flex;
  flex-direction: row;

  &.price2 {
    margin-left: 60px;
  }

  @media (max-width: 720px) {
    flex-direction: column;

    &.price2 {
      margin-left: 0px;
    }
  }
`;

export const ContainerFields = styled.div<IContainerFieldsProps>`
  padding: 1px 0px 8px 0px;

  ${props =>
    props.field &&
    props.field === 'code' &&
    css`
      width: 200px;
    `}

  ${props =>
    props.field &&
    props.field === 'price' &&
    css`
      width: 280px;
    `}

  ${props =>
    props.field &&
    props.field === 'cnpj' &&
    css`
      width: 300px;
    `}

  ${props =>
    props.field &&
    props.field === 'date' &&
    css`
      width: 300px;
    `}

  ${props =>
    props.field &&
    props.field === 'text' &&
    css`
      width: 100%;
    `}

  ${props =>
    props.field &&
    props.field === 'check' &&
    css`
      width: 300px;
    `}

  ${props =>
    props.field &&
    props.field === 'id' &&
    css`
      height: 0px;
      width: 0px;
    `}

    ${props =>
      props.field &&
      props.field === 'select' &&
      css`
        width: 300px;
      `}

  &.price2 {
    margin-left: 60px;
  }

  @media (max-width: 720px) {
    &.price2 {
      margin-left: 0px;
    }
  }
`;

export const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const FormNavigate = styled.div`
  width: 800px;
  margin-top: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  align-content: flex-start;

  border-bottom: 2px solid #006699;

  button {
    margin: 0 10px 10px 0;
  }

  @media (max-width: 810px) {
    width: 600px;
  }

  @media (max-width: 720px) {
    width: 450px;
    justify-content: space-between;
  }
`;

export const GradeButtons = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

export const ContainerFilterSelect = styled.div`
  background: #fff;
  border-radius: 4px;
  padding: 5.5px;
  width: 100%;

  border: 1px solid #666360;
  color: #666360;

  display: flex;
  align-items: center;

  & + div {
    margin-top: 8px;
  }

  select {
    flex: 1;
    background: transparent;
    border: 0;
    color: #000;
    width: 100%;
    border: none;
    height: 100%;
    font-size: 16px;
    cursor: pointer;

    &::placeholder {
      color: #989898;
    }
  }

  option {
    display: block;
    position: absolute;
    cursor: pointer;
    padding: 15px 0;
    font-size: 16px;
    zoom: 1.2;
  }

  svg {
    margin-right: 16px;
  }
`;

export const SpanFiltro = styled.span`
  display: flex;
  width: 100%;
  background-color: #fff;
  color: #006699;
  font-family: sans-serif;
  font-size: 12px;
  white-space: pre-wrap;
`;

export const TabButtonGridRowHeight = styled.button`
  background-color: inherit;
  float: left;
  border: none;
  outline: none;
  cursor: pointer;
  padding: 4px 4px;
  transition: 0.3s;
  font-size: 16px;
  font-family: sans-serif;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: 1px;

  svg {
    color: #006699;
  }

  &:hover {
    background-color: #006699;
    opacity: 0.8;
    font-size: 18px;
    color: #fff;
    font-weight: bold;

    svg {
      background-color: #006699;
      opacity: 0.8;
      color: #fff;
    }
  }
`;

export const TabButtonNovo = styled.button`
  background-color: inherit;
  float: left;
  border: none;
  outline: none;
  cursor: pointer;
  padding: 10px 12px;
  transition: 0.3s;
  font-size: 16px;
  font-family: sans-serif;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: 10px;

  svg {
    margin-right: 8px;
    background-color: #fff;
    color: #7ec850;
  }

  &:hover {
    background-color: #7ec850;
    opacity: 0.8;
    font-size: 18px;
    color: #fff;
    font-weight: bold;

    svg {
      background-color: #7ec850;
      opacity: 0.8;
      color: #fff;
    }
  }
`;

export const TabButtonDelete = styled.button`
  background-color: inherit;
  float: left;
  border: none;
  outline: none;
  cursor: pointer;
  padding: 10px 12px;
  transition: 0.3s;
  font-size: 16px;
  font-family: sans-serif;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: 10px;

  svg {
    margin-right: 8px;
    background-color: #fff;
    color: #cd001a;
  }

  &:hover {
    background-color: #cd001a;
    opacity: 0.8;
    font-size: 18px;
    color: #fff;
    font-weight: bold;

    svg {
      background-color: #cd001a;
      opacity: 0.8;
      color: #fff;
    }
  }
`;

export const ContainerFieldTextFilter = styled.div`
  input {
    background: #fff;
    border: 1px solid;
    border-radius: 4px;
    padding: 4px;
    width: 100%;
  }
`;
