import ICompany from './ICompany';

export default interface ILicense {
  acao: string;
  id: string;
  cnpj: string;
  descricao: string;
  email_admin_user: string;
  token: string;
  quantidade_cnpj: number;
  observacao: string;
  data_inicio: string;
  data_fim?: string;
  mensagem_cliente: string;
  blocked: boolean;
  rls_power_bi: boolean;
  url_powerbi: string;
  url_powerbi_financial_accounts: string;
  url_powerbi_human_resources: string;
  url_powerbi_cost_analysis: string;
  updated_user: string;
  // Computed Fields
  quantidadeCnpjUtilizada: number;
  quantidadeCnpjDisponivel: number;
  nro_serie: string;
  tipo_licenca: string;
  id_licenciado_agrupador: string;
  listaEmpresas: ICompany[];
}

export const emptyLicenseConfig: ILicense = {
  acao: '',
  id: '',
  cnpj: '',
  descricao: '',
  email_admin_user: '',
  token: '',
  quantidade_cnpj: 1,
  observacao: '',
  data_inicio: '',
  data_fim: undefined,
  mensagem_cliente: '',
  blocked: false,
  rls_power_bi: false,
  url_powerbi: '',
  url_powerbi_cost_analysis: '',
  url_powerbi_financial_accounts: '',
  url_powerbi_human_resources: '',
  updated_user: '',
  quantidadeCnpjUtilizada: 0,
  quantidadeCnpjDisponivel: 0,
  nro_serie: '',
  tipo_licenca: '1-Ferramentas Assessortech',
  id_licenciado_agrupador: '',
  listaEmpresas: [],
};
