import { GridColDef } from '@material-ui/data-grid';

export const ColumnCodIndicador: GridColDef = {
  headerName: 'Código indicador',
  field: 'cod_indicador',
  width: 200,
};

export const ColumnCodIndicadorSup: GridColDef = {
  headerName: 'Código indicador superior',
  field: 'cod_indicador_sup',
  width: 250,
};

export const ColumnGrupoIndicador: GridColDef = {
  headerName: 'Grupo indicador',
  field: 'grupo_indicador',
  width: 300,
};

export const ColumnTipoIndicador: GridColDef = {
  headerName: 'Tipo indicador',
  field: 'tipo_indicador',
  width: 400,
};

export const ColumnDescIndicador: GridColDef = {
  headerName: 'Descrição indicador',
  field: 'desc_indicador',
  width: 400,
};

export const ColumnNomeGestor: GridColDef = {
  headerName: 'Nome gestor',
  field: 'nome_gestor',
  width: 400,
};

export const ColumnNomeAuditor: GridColDef = {
  headerName: 'Nome auditor',
  field: 'nome_auditor',
  width: 400,
};

export const ColumnInformacao01: GridColDef = {
  headerName: 'Informacao 01',
  field: 'informacao01',
  width: 600,
};

export const ColumnInformacao02: GridColDef = {
  headerName: 'Informacao 02',
  field: 'informacao02',
  width: 600,
};

export const ColumnInformacao03: GridColDef = {
  headerName: 'Informacao 03',
  field: 'informacao03',
  width: 600,
};

export const ColumnInformacao04: GridColDef = {
  headerName: 'Informacao 04',
  field: 'informacao04',
  width: 600,
};

export const ColumnInformacao05: GridColDef = {
  headerName: 'Informacao 05',
  field: 'informacao05',
  width: 600,
};

export const ColumnObs: GridColDef = {
  headerName: 'Observação',
  field: 'obs',
  width: 600,
};

export const ColumnIdCadIndicPerson: GridColDef = {
  headerName: '#',
  field: 'id_cad_indic_person',
  hide: true,
  width: 400,
  editable: false,
  filterable: false,
  hideSortIcons: true,
  sortable: false,
  disableColumnMenu: true,
};
