import React, { useRef, useState, useEffect, useCallback } from 'react';
import * as Yup from 'yup';

import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';

import { DataGrid, GridColDef, GridCellParams, GridRowId, ptBR } from '@material-ui/data-grid';
import { createMuiTheme, ThemeProvider, makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';

import { FiCopy } from 'react-icons/fi';
import { MdDelete, MdEdit, MdClear, MdSkipNext, MdSkipPrevious, MdContentCopy } from 'react-icons/md';
import {
  AiOutlinePlus,
  AiOutlineSave,
  AiFillFastBackward,
  AiFillFastForward,
  AiOutlineCaretDown,
  AiOutlineCaretUp,
} from 'react-icons/ai';
import { GoPlus } from 'react-icons/go';
import { RiDeleteBinLine } from 'react-icons/ri';

import InputComum from '../../components/InputComum';
import Select from '../../components/Select';
import Checkbox from '../../components/Checkbox';
import Header from '../../components/Header';
import FormModalDialogConfirm from '../../components/FormModalDialogConfirm';

import api from '../../services/api';
import { useAuth } from '../../hooks/Auth';
import { useToast } from '../../hooks/Toast';

import getListUpdate from '../../utils/getListUpdate';
import { getPathGrid } from '../../utils/storagePath';
import getValidationErrors from '../../utils/getValidationErrors';

import {
  ColumnAtivo,
  ColumnCodigoCentroCusto,
  ColumnCodigoCentroCustoSuperior,
  ColumnNomeCentroCusto,
  ColumnIndCentroCusto,
  ColumnNivel,
  ColumnClassificacao,
  ColumnObservacao,
  ColumnInformacao1,
  ColumnInformacao2,
  ColumnInformacao3,
  ColumnInformacao4,
  ColumnInformacao5,
  ColumnID,
} from './GridColumns';

import IDemGerCadCCusto from './IDemGerCadCCusto';
import {
  Container,
  ContainerFields,
  Tab,
  TabButton,
  TabContent,
  FormFields,
  FormContainer,
  FormNavigate,
  TabTitulo,
  TabButtonArea,
  ContainerFieldCheckbox,
  TabButtonNovo,
  TabButtonDelete,
  TabButtonGridRowHeight,
} from './styles';

const useStylesLoading = makeStyles((theme: Theme) =>
  createStyles({
    backdrop: {
      zIndex: theme.zIndex.drawer + 2000,
      color: '#fff',
    },
  }),
);

const theme = createMuiTheme(
  {
    palette: {
      primary: { main: '#006699' },
    },
  },
  ptBR,
);

const options_ind_ccusto = [
  { value: '1-Sintético', label: '1-Sintético' },
  { value: '2-Analítico', label: '2-Analítico' },
];

const emptyDemGerCadCCusto: IDemGerCadCCusto = {
  acao: '',
  classificacao: '',
  cod_ccusto: '',
  cod_ccusto_superior: '',
  ind_ccusto: '1-Sintético',
  inf1: '',
  inf2: '',
  inf3: '',
  inf4: '',
  inf5: '',
  nome_ccusto: '',
  obs: '',
  nivel: 1,
  id: '',
  id_company: '',
  id_license: '',
  ativo: true,
};

const titulo = 'C E N T R O    D E    C U S T O';

const Company: React.FC = () => {
  const { user } = useAuth();
  const { addToast } = useToast();
  const formRef = useRef<FormHandles>(null);
  const [demGerCadCCusto, setDemGerCadCCusto] = useState<IDemGerCadCCusto>();
  const [listDemGerCadCCusto, setListDemGerCadCCusto] = useState<IDemGerCadCCusto[]>([]);
  const [hiddenPesquisar, setHiddenPesquisar] = useState<boolean>(false);
  const [hiddenCadastrar, setHiddenCadastrar] = useState<boolean>(true);
  const [demGerCadCCustoIndex, setDemGerCadCCustoIndex] = useState<number>(0);
  const [rowToEdit, setRowToEdit] = useState(null);
  const [idDeleteDemGerCadCCusto, setIdDeleteDemGerCadCCusto] = useState<string>('');
  const [selectedIds, setSelectedIds] = useState<GridRowId[]>([]);
  const [openDeleteSingle, setOpenDeleteSingle] = useState(false);
  const [openDeleteMultiple, setOpenDeleteMultiple] = useState(false);
  const [openLoading, setOpenLoading] = useState(false);
  const cssLoading = useStylesLoading();
  const [gridRowHeight, setGridRowHeight] = useState<number>(46);

  const handleGridRowHeightUp = useCallback(async () => {
    const heightValue: number = gridRowHeight + 1;
    setGridRowHeight(heightValue);
    localStorage.setItem(getPathGrid(), heightValue.toString());
  }, [gridRowHeight]);

  const handleGridRowHeightDown = useCallback(async () => {
    const heightValue: number = gridRowHeight - 1;
    setGridRowHeight(heightValue);
    localStorage.setItem(getPathGrid(), heightValue.toString());
  }, [gridRowHeight]);

  const licencaInvalida = useCallback((): boolean => {
    if (!user.license || user.license.id === '') {
      addToast({
        type: 'error',
        title: 'Licença inválida',
        description: 'Usuário não está vinculado a nenhuma licença',
      });

      return true;
    }

    return false;
  }, [addToast, user]);

  const defineDemGerCadCCusto = useCallback(async (data: IDemGerCadCCusto) => {
    setDemGerCadCCusto(data);
    formRef.current?.setData(data);
  }, []);

  const onNewRegister = useCallback(async () => {
    defineDemGerCadCCusto(emptyDemGerCadCCusto);
  }, [defineDemGerCadCCusto]);

  const OnGridSetRegisterToEdit = useCallback(
    async (params: GridCellParams) => {
      const localDemGerCadCCusto = params.row as IDemGerCadCCusto;

      setDemGerCadCCustoIndex(listDemGerCadCCusto.indexOf(localDemGerCadCCusto));
      setHiddenCadastrar(false);
      setHiddenPesquisar(true);
      setRowToEdit(null);
      defineDemGerCadCCusto(localDemGerCadCCusto);
    },
    [listDemGerCadCCusto, defineDemGerCadCCusto],
  );

  const OnSetRegisterToAdd = useCallback(async () => {
    onNewRegister();
    setHiddenCadastrar(false);
    setHiddenPesquisar(true);
  }, [onNewRegister]);

  const OnGridRowDuplicate = useCallback(
    async (baseDemGerCadCCusto: IDemGerCadCCusto | undefined) => {
      if (baseDemGerCadCCusto !== undefined) {
        const newDemGerCadCCusto: IDemGerCadCCusto = {
          acao: baseDemGerCadCCusto.acao,
          classificacao: baseDemGerCadCCusto.classificacao,
          cod_ccusto: '',
          cod_ccusto_superior: baseDemGerCadCCusto.cod_ccusto_superior,
          nivel: baseDemGerCadCCusto.nivel,
          id_company: baseDemGerCadCCusto.id_company,
          id_license: baseDemGerCadCCusto.id_license,
          ind_ccusto: baseDemGerCadCCusto.ind_ccusto,
          inf1: baseDemGerCadCCusto.inf1,
          inf2: baseDemGerCadCCusto.inf2,
          inf3: baseDemGerCadCCusto.inf3,
          inf4: baseDemGerCadCCusto.inf4,
          inf5: baseDemGerCadCCusto.inf5,
          obs: baseDemGerCadCCusto.obs,
          ativo: baseDemGerCadCCusto.ativo,
          nome_ccusto: '',
          id: '',
        };

        defineDemGerCadCCusto(newDemGerCadCCusto);
        setHiddenCadastrar(false);
        setHiddenPesquisar(true);
      }
    },
    [defineDemGerCadCCusto],
  );

  const OnConfirmationDeleteSingle = useCallback(
    async (id: string) => {
      if (licencaInvalida()) return;

      if (id === '') return;

      setIdDeleteDemGerCadCCusto(id);
      setOpenDeleteSingle(true);
    },
    [licencaInvalida],
  );

  const colunaAcao: GridColDef = {
    field: 'acao',
    headerName: 'Ação',
    editable: false,
    filterable: false,
    hideSortIcons: true,
    sortable: false,
    disableColumnMenu: true,
    width: 160,
    renderCell: (params: GridCellParams) => (
      <strong>
        <Tooltip title="Editar Registro" placement="top">
          <IconButton color="primary" aria-label="Editar" onClick={() => OnGridSetRegisterToEdit(params)}>
            <MdEdit />
          </IconButton>
        </Tooltip>

        <Tooltip title="Duplicar Registro" placement="top">
          <IconButton
            color="primary"
            aria-label="Duplicar"
            onClick={() => OnGridRowDuplicate(params.row as IDemGerCadCCusto)}
          >
            <FiCopy />
          </IconButton>
        </Tooltip>

        <Tooltip title="Excluir Registro" placement="top">
          <IconButton
            color="primary"
            aria-label="Excluir"
            onClick={() => OnConfirmationDeleteSingle((params.row as IDemGerCadCCusto).id)}
          >
            <MdDelete />
          </IconButton>
        </Tooltip>
      </strong>
    ),
  };
  const columns: GridColDef[] = [
    colunaAcao,
    ColumnCodigoCentroCusto,
    ColumnCodigoCentroCustoSuperior,
    ColumnNomeCentroCusto,
    ColumnIndCentroCusto,
    ColumnNivel,
    ColumnClassificacao,
    ColumnAtivo,
    ColumnObservacao,
    ColumnInformacao1,
    ColumnInformacao2,
    ColumnInformacao3,
    ColumnInformacao4,
    ColumnInformacao5,
    ColumnID,
  ];

  const getDemGerCadCCusto = useCallback(async () => {
    const response = await api.get<IDemGerCadCCusto[]>(`/demgercadccusto`, {
      params: {
        id_license: user.license.id,
        id_company: user.empresa.id,
      },
    });

    setListDemGerCadCCusto(response.data);

    if (rowToEdit !== null) {
      const findEditDemGerCadCCusto = response.data.find(item => item.id === rowToEdit);

      if (findEditDemGerCadCCusto) {
        defineDemGerCadCCusto(findEditDemGerCadCCusto);
        setDemGerCadCCustoIndex(response.data.indexOf(findEditDemGerCadCCusto));
        setHiddenCadastrar(false);
        setHiddenPesquisar(true);
        setRowToEdit(null);
      }
    }
  }, [user, rowToEdit, defineDemGerCadCCusto]);

  const getValues = useCallback(async () => {
    if (licencaInvalida()) return;

    setOpenLoading(true);
    await getDemGerCadCCusto();

    setOpenLoading(false);
    setHiddenPesquisar(false);
  }, [licencaInvalida, getDemGerCadCCusto]);

  useEffect(() => {
    getValues();

    const rowHeight = localStorage.getItem(getPathGrid());
    if (rowHeight) {
      setGridRowHeight(parseInt(rowHeight, 10));
    }
  }, [getValues]);

  const handleOnRowDeleteSingle = useCallback(async () => {
    setOpenLoading(true);

    await api.delete(`/demgercadccusto/${idDeleteDemGerCadCCusto}`);

    setListDemGerCadCCusto(oldDemGerCadCCusto =>
      oldDemGerCadCCusto.filter(item => item.id !== idDeleteDemGerCadCCusto),
    );

    defineDemGerCadCCusto(emptyDemGerCadCCusto);

    addToast({
      type: 'success',
      title: 'Removido',
      description: 'Registro excluído com sucesso!',
    });

    setOpenDeleteSingle(false);
    setOpenLoading(false);
  }, [idDeleteDemGerCadCCusto, addToast, defineDemGerCadCCusto]);

  const handleOnRowDeleteMultiple = useCallback(async () => {
    setOpenLoading(true);

    if (selectedIds.length > 0) {
      await Promise.all(
        selectedIds.map(itemId => {
          setListDemGerCadCCusto(oldDemGerCadCCusto => oldDemGerCadCCusto.filter(item => item.id !== itemId));

          return api.delete(`/demgercadccusto/${itemId}`);
        }),
      );

      defineDemGerCadCCusto(emptyDemGerCadCCusto);

      addToast({
        type: 'success',
        title: 'Removido',
        description: 'Registro excluído com sucesso!',
      });
    }

    setSelectedIds([]);
    setOpenDeleteMultiple(false);
    setOpenLoading(false);
  }, [addToast, defineDemGerCadCCusto, selectedIds]);

  const handleClick = (id: string): void => {
    setHiddenCadastrar(id === 'pesquisar');
    setHiddenPesquisar(id === 'cadastrar');
  };

  const onNextRegister = useCallback(async () => {
    if (demGerCadCCustoIndex !== undefined) {
      if (demGerCadCCustoIndex < listDemGerCadCCusto.length - 1) {
        const localIndex = demGerCadCCustoIndex + 1;
        setDemGerCadCCustoIndex(localIndex);
        const findEditDemGerCadCCusto = listDemGerCadCCusto[localIndex];
        if (findEditDemGerCadCCusto) {
          defineDemGerCadCCusto(findEditDemGerCadCCusto);
        }
      }
    }
  }, [demGerCadCCustoIndex, listDemGerCadCCusto, defineDemGerCadCCusto]);

  const onFirstRegister = useCallback(async () => {
    if (listDemGerCadCCusto.length > 0) {
      setDemGerCadCCustoIndex(0);
      const findEditDemGerCadCCusto = listDemGerCadCCusto[0];
      if (findEditDemGerCadCCusto) {
        defineDemGerCadCCusto(findEditDemGerCadCCusto);
      }
    }
  }, [listDemGerCadCCusto, defineDemGerCadCCusto]);

  const onLastRegister = useCallback(async () => {
    if (listDemGerCadCCusto.length > 0) {
      setDemGerCadCCustoIndex(listDemGerCadCCusto.length - 1);
      const findEditDemGerCadCCusto = listDemGerCadCCusto[listDemGerCadCCusto.length - 1];
      if (findEditDemGerCadCCusto) {
        defineDemGerCadCCusto(findEditDemGerCadCCusto);
      }
    }
  }, [listDemGerCadCCusto, defineDemGerCadCCusto]);

  const onPriorRegister = useCallback(async () => {
    if (demGerCadCCustoIndex !== undefined) {
      if (demGerCadCCustoIndex > 0) {
        const localIndex = demGerCadCCustoIndex - 1;
        setDemGerCadCCustoIndex(localIndex);
        const findEditDemGerCadCCusto = listDemGerCadCCusto[localIndex];
        if (findEditDemGerCadCCusto) {
          defineDemGerCadCCusto(findEditDemGerCadCCusto);
        }
      }
    }
  }, [demGerCadCCustoIndex, listDemGerCadCCusto, defineDemGerCadCCusto]);

  const onCancelRegister = useCallback(async () => {
    formRef.current?.setErrors({});

    if (demGerCadCCustoIndex !== undefined) {
      const findEditDemGerCadCCusto = listDemGerCadCCusto[demGerCadCCustoIndex];
      if (findEditDemGerCadCCusto) {
        defineDemGerCadCCusto(findEditDemGerCadCCusto);
      }
    }

    onNewRegister();
  }, [demGerCadCCustoIndex, listDemGerCadCCusto, onNewRegister, defineDemGerCadCCusto]);

  const handleSubmit = useCallback(
    async (data: IDemGerCadCCusto, { reset }) => {
      if (licencaInvalida()) return;

      formRef.current?.setErrors({});
      try {
        const schema = Yup.object().shape({
          ind_ccusto: Yup.string().required('Indicador do centro de custo é obrigatório'),
          cod_ccusto: Yup.string().required('Centro de custo é obrigatório'),
          nome_ccusto: Yup.string().required('Nome do centro de custo é obrigatório'),
          nivel: Yup.number().required('Nível é obrigatório'),
          cod_ccusto_superior: Yup.string().required('Centro de custo superior é obrigatória'),
          classificacao: Yup.string().required('Classificação é obrigatória'),
          obs: Yup.string(),
          inf1: Yup.string(),
          inf2: Yup.string(),
          inf3: Yup.string(),
          inf4: Yup.string(),
          inf5: Yup.string(),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        setOpenLoading(true);

        const formData: IDemGerCadCCusto = {
          acao: data.acao,
          id: data.id,
          id_company: user.empresa.id,
          id_license: user.license.id,
          ind_ccusto: data.ind_ccusto,
          ativo: data.ativo,
          nivel: data.nivel,
          cod_ccusto: data.cod_ccusto,
          nome_ccusto: data.nome_ccusto,
          cod_ccusto_superior: data.cod_ccusto_superior,
          classificacao: data.classificacao,
          obs: data.obs,
          inf1: data.inf1,
          inf2: data.inf2,
          inf3: data.inf3,
          inf4: data.inf4,
          inf5: data.inf5,
        };

        if (data.id === '') {
          const response = await api.post(`/demgercadccusto`, formData);
          formData.id = response.data.id;

          setListDemGerCadCCusto(oldLicenses => [...oldLicenses, response.data]);

          addToast({
            type: 'success',
            title: 'Adicionado',
            description: 'Registro adicionado com sucesso!',
          });
        } else {
          await api.put(`/demgercadccusto/${formData.id}`, formData);

          setListDemGerCadCCusto(getListUpdate(listDemGerCadCCusto, formData));

          addToast({
            type: 'success',
            title: 'Alterado',
            description: 'Registro alterado com sucesso!',
          });
        }

        reset();
        setOpenLoading(false);
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          formRef.current?.setErrors(errors);
        }
        setOpenLoading(false);
      }
    },
    [licencaInvalida, listDemGerCadCCusto, user, addToast],
  );

  return (
    <Container>
      <Header />

      <Tab>
        <TabButtonArea>
          <TabButton id="pesquisar" isActive={!hiddenPesquisar} onClick={() => handleClick('pesquisar')}>
            Listagem
          </TabButton>
          <TabButton id="cadastrar" isActive={!hiddenCadastrar} onClick={() => handleClick('cadastrar')}>
            Cadastro
          </TabButton>

          <Tooltip title="Reduzir o tamanho da linha" placement="top">
            <TabButtonGridRowHeight id="gridrowheightdown" onClick={() => handleGridRowHeightDown()}>
              <AiOutlineCaretDown />
            </TabButtonGridRowHeight>
          </Tooltip>

          <Tooltip title="Aumentar o tamanho da linha" placement="top">
            <TabButtonGridRowHeight id="gridrowheightup" onClick={() => handleGridRowHeightUp()}>
              <AiOutlineCaretUp />
            </TabButtonGridRowHeight>
          </Tooltip>

          <Tooltip title="Novo Registro" placement="top">
            <TabButtonNovo id="new_register" onClick={() => OnSetRegisterToAdd()}>
              <GoPlus />
              Novo
            </TabButtonNovo>
          </Tooltip>

          <Tooltip title="Remover Registros Selecionados" placement="top">
            <span>
              <span hidden={selectedIds.length <= 0}>
                <TabButtonDelete
                  id="remove_all"
                  onClick={() => {
                    setIdDeleteDemGerCadCCusto('');
                    setOpenDeleteMultiple(true);
                  }}
                >
                  <RiDeleteBinLine />
                  Remover
                </TabButtonDelete>
              </span>
            </span>
          </Tooltip>
        </TabButtonArea>

        <TabTitulo>
          <span>{titulo}</span>
        </TabTitulo>
      </Tab>

      <TabContent id="pesquisar" isActive={!hiddenPesquisar}>
        <div style={{ height: '85vh', width: '100%' }}>
          <ThemeProvider theme={theme}>
            <DataGrid
              rows={listDemGerCadCCusto}
              columns={columns}
              rowHeight={gridRowHeight}
              checkboxSelection
              selectionModel={selectedIds}
              disableSelectionOnClick
              onSelectionModelChange={e => setSelectedIds(e.selectionModel)}
            />
          </ThemeProvider>
          ;
        </div>
      </TabContent>

      <TabContent id="cadasto" isActive={!hiddenCadastrar}>
        <Form ref={formRef} onSubmit={handleSubmit} initialData={demGerCadCCusto}>
          <FormContainer>
            <FormNavigate>
              <Tooltip title="Novo Registro" placement="top">
                <IconButton color="primary" aria-label="adicionar" onClick={() => onNewRegister()}>
                  <AiOutlinePlus />
                </IconButton>
              </Tooltip>

              <Tooltip title="Duplicar Registro" placement="top">
                <IconButton color="primary" aria-label="Duplicar" onClick={() => OnGridRowDuplicate(demGerCadCCusto)}>
                  <MdContentCopy />
                </IconButton>
              </Tooltip>

              <Tooltip title="Excluir Registro" placement="top">
                <IconButton
                  color="primary"
                  aria-label="Excluir"
                  onClick={() => OnConfirmationDeleteSingle(demGerCadCCusto ? demGerCadCCusto.id : '')}
                >
                  <RiDeleteBinLine />
                </IconButton>
              </Tooltip>

              <Tooltip title="Cancelar" placement="top">
                <IconButton type="reset" color="primary" aria-label="Cancelar" onClick={() => onCancelRegister()}>
                  <MdClear />
                </IconButton>
              </Tooltip>

              <Tooltip title="Salvar" placement="top">
                <IconButton type="submit" color="primary" aria-label="Confirmar">
                  <AiOutlineSave />
                </IconButton>
              </Tooltip>

              <Tooltip title="Primeiro Registro" placement="top">
                <IconButton color="primary" aria-label="Primeiro" onClick={() => onFirstRegister()}>
                  <AiFillFastBackward />
                </IconButton>
              </Tooltip>

              <Tooltip title="Registro Anterior" placement="top">
                <IconButton color="primary" aria-label="Anterior" onClick={() => onPriorRegister()}>
                  <MdSkipPrevious />
                </IconButton>
              </Tooltip>

              <Tooltip title="Próximo Registro" placement="top">
                <IconButton color="primary" aria-label="Próximo" onClick={() => onNextRegister()}>
                  <MdSkipNext />
                </IconButton>
              </Tooltip>

              <Tooltip title="Último Registro" placement="top">
                <IconButton color="primary" aria-label="Último registro" onClick={() => onLastRegister()}>
                  <AiFillFastForward />
                </IconButton>
              </Tooltip>
            </FormNavigate>

            <FormFields>
              <ContainerFieldCheckbox field="T300px">
                <Checkbox name="ativo" />
                <span>Ativo</span>
              </ContainerFieldCheckbox>

              <ContainerFields field="T200px">
                <span>Centro de custo:</span>
                <InputComum autoFocus name="cod_ccusto" />
              </ContainerFields>

              <ContainerFields field="T200px">
                <span>Centro de custo superior:</span>
                <InputComum name="cod_ccusto_superior" />
              </ContainerFields>

              <ContainerFields field="T100">
                <span>Nome centro de custo:</span>
                <InputComum name="nome_ccusto" />
              </ContainerFields>

              <ContainerFields field="T300px">
                <span>Indicador centro de custo:</span>
                <Select name="ind_ccusto" defaultValue="1-Sintético">
                  {options_ind_ccusto.map(option => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </Select>
              </ContainerFields>

              <ContainerFields field="T100">
                <span>Nível:</span>
                <InputComum name="nivel" type="number" min="0" />
              </ContainerFields>

              <ContainerFields field="T100">
                <span>Classificação:</span>
                <InputComum name="classificacao" />
              </ContainerFields>

              <ContainerFields field="T100">
                <span>Observação:</span>
                <InputComum name="obs" />
              </ContainerFields>

              <ContainerFields field="T100">
                <span>Informação 1:</span>
                <InputComum name="inf1" />
              </ContainerFields>

              <ContainerFields field="T100">
                <span>Informação 2:</span>
                <InputComum name="inf2" />
              </ContainerFields>

              <ContainerFields field="T100">
                <span>Informação 3:</span>
                <InputComum name="inf3" />
              </ContainerFields>

              <ContainerFields field="T100">
                <span>Informação 4:</span>
                <InputComum name="inf4" />
              </ContainerFields>

              <ContainerFields field="T100">
                <span>Informação 5:</span>
                <InputComum name="inf5" />
              </ContainerFields>

              <ContainerFields field="TID">
                <InputComum name="id" hidden />
              </ContainerFields>
            </FormFields>
          </FormContainer>
        </Form>
      </TabContent>

      <FormModalDialogConfirm
        open={openDeleteSingle}
        onClose={() => setOpenDeleteSingle(false)}
        onConfirm={() => handleOnRowDeleteSingle()}
        buttonCancelText="Cancelar"
        buttonConfirmText="Sim"
        formTitle="Excluir?"
        messageConfirm="Tem certeza que deseja excluir?"
      />

      <FormModalDialogConfirm
        open={openDeleteMultiple}
        onClose={() => setOpenDeleteMultiple(false)}
        onConfirm={() => handleOnRowDeleteMultiple()}
        buttonCancelText="Cancelar"
        buttonConfirmText="Sim"
        formTitle="Excluir?"
        messageConfirm="Tem certeza que deseja excluir todos os registros selecionados?"
      />

      <Backdrop className={cssLoading.backdrop} open={openLoading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </Container>
  );
};

export default Company;
