import React, { ButtonHTMLAttributes } from 'react';
import { IconBaseProps } from 'react-icons';

import { Container } from './styles';

type ButtonProps = ButtonHTMLAttributes<HTMLButtonElement> & {
  loading?: boolean;
  eHSomenteImagem?: boolean;
  eHTextoEhImagem?: boolean;
  defColor?: 'red' | 'green' | 'defaul';
  icon?: React.ComponentType<IconBaseProps>;
};

const Button: React.FC<ButtonProps> = ({
  children,
  loading,
  eHSomenteImagem,
  eHTextoEhImagem,
  defColor,
  icon: Icon,
  ...rest
}) => (
  <Container type="button" {...rest} textoEhmagem={eHTextoEhImagem} somenteImagem={eHSomenteImagem} setColor={defColor}>
    {Icon && <Icon size={20} />}
    {loading ? 'Carregando...' : children}
  </Container>
);

export default Button;
