import React, { useRef, useState, useEffect, useCallback } from 'react';
import * as Yup from 'yup';

import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';

import { DataGrid, GridColDef, GridCellParams, GridRowId, ptBR } from '@material-ui/data-grid';
import { createMuiTheme, ThemeProvider, makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';

import { FiCopy } from 'react-icons/fi';
import { MdDelete, MdEdit, MdClear, MdSkipNext, MdSkipPrevious, MdContentCopy } from 'react-icons/md';
import {
  AiOutlinePlus,
  AiOutlineSave,
  AiFillFastBackward,
  AiFillFastForward,
  AiOutlineCaretDown,
  AiOutlineCaretUp,
} from 'react-icons/ai';
import { GoPlus } from 'react-icons/go';
import { RiDeleteBinLine } from 'react-icons/ri';

import InputComum from '../../components/InputComum';
import Checkbox from '../../components/Checkbox';
import Header from '../../components/Header';
import FormModalDialogConfirm from '../../components/FormModalDialogConfirm';

import api from '../../services/api';
import { useAuth } from '../../hooks/Auth';
import { useToast } from '../../hooks/Toast';

import getListUpdate from '../../utils/getListUpdate';
import getValidationErrors from '../../utils/getValidationErrors';
import { getPathGrid } from '../../utils/storagePath';

import {
  ColumnCodigo,
  ColumnDescricao,
  ColumnObservacao,
  ColumnPermiteCalcularProjecaoOrcamentaria,
  ColumnId,
} from './GridColumns';

import ITipoDemGer, { emptyTipoDemGer } from './ITipoDemGer';

import {
  Container,
  ContainerFields,
  Tab,
  TabButton,
  TabContent,
  FormFields,
  FormContainer,
  FormNavigate,
  TabTitulo,
  TabButtonArea,
  ContainerFieldCheckbox,
  ContainerGroup,
  TabButtonNovo,
  TabButtonDelete,
  TabButtonGridRowHeight,
} from './styles';

const useStylesLoading = makeStyles((theme: Theme) =>
  createStyles({
    backdrop: {
      zIndex: theme.zIndex.drawer + 2000,
      color: '#fff',
    },
  }),
);

const theme = createMuiTheme(
  {
    palette: {
      primary: { main: '#006699' },
    },
  },
  ptBR,
);

const titulo = 'D E M O N S T R A T I V O S';

const TipoDemGer: React.FC = () => {
  const { user } = useAuth();
  const { addToast } = useToast();
  const formRef = useRef<FormHandles>(null);
  const [demGerParametro, setTipoDemGer] = useState<ITipoDemGer>();
  const [listTipoDemGer, setListTipoDemGer] = useState<ITipoDemGer[]>([]);
  const [hiddenPesquisar, setHiddenPesquisar] = useState<boolean>(true);
  const [hiddenCadastrar, setHiddenCadastrar] = useState<boolean>(true);
  const [tipoDemGerIndex, setTipoDemGerIndex] = useState<number>(0);
  const [rowToEdit, setRowToEdit] = useState(null);
  const [idDeleteTipoDemGer, setIdDeleteTipoDemGer] = useState<string>('');
  const [selectedIds, setSelectedIds] = useState<GridRowId[]>([]);
  const [openDeleteSingle, setOpenDeleteSingle] = useState(false);
  const [openDeleteMultiple, setOpenDeleteMultiple] = useState(false);
  const [openLoading, setOpenLoading] = useState(false);
  const cssLoading = useStylesLoading();
  const [gridRowHeight, setGridRowHeight] = useState<number>(46);

  const handleGridRowHeightUp = useCallback(async () => {
    const heightValue: number = gridRowHeight + 1;
    setGridRowHeight(heightValue);
    localStorage.setItem(getPathGrid(), heightValue.toString());
  }, [gridRowHeight]);

  const handleGridRowHeightDown = useCallback(async () => {
    const heightValue: number = gridRowHeight - 1;
    setGridRowHeight(heightValue);
    localStorage.setItem(getPathGrid(), heightValue.toString());
  }, [gridRowHeight]);

  const licencaInvalida = useCallback((): boolean => {
    if (!user.license || user.license.id === '') {
      addToast({
        type: 'error',
        title: 'Licença inválida',
        description: 'Usuário não está vinculado a nenhuma licença',
      });

      return true;
    }

    return false;
  }, [addToast, user]);

  const defineTipoDemGer = useCallback(async (data: ITipoDemGer) => {
    setTipoDemGer(data);
    formRef.current?.setData(data);
  }, []);

  const onNewRegister = useCallback(async () => {
    defineTipoDemGer(emptyTipoDemGer);
  }, [defineTipoDemGer]);

  const OnGridSetRegisterToEdit = useCallback(
    async (params: GridCellParams) => {
      const localTipoDemGer = params.row as ITipoDemGer;

      setTipoDemGerIndex(listTipoDemGer.indexOf(localTipoDemGer));
      setHiddenCadastrar(false);
      setHiddenPesquisar(true);
      setRowToEdit(null);
      defineTipoDemGer(localTipoDemGer);
    },
    [listTipoDemGer, defineTipoDemGer],
  );

  const OnSetRegisterToAdd = useCallback(async () => {
    onNewRegister();
    setHiddenCadastrar(false);
    setHiddenPesquisar(true);
  }, [onNewRegister]);

  const OnGridRowDuplicate = useCallback(
    async (baseTipoDemGer: ITipoDemGer | undefined) => {
      if (baseTipoDemGer !== undefined) {
        const newTipoDemGer: ITipoDemGer = {
          acao: baseTipoDemGer.acao,
          id: '',
          codigo: baseTipoDemGer.codigo,
          descricao: baseTipoDemGer.descricao,
          obs: baseTipoDemGer.obs,
          permite_calc_projecao_orc: baseTipoDemGer.permite_calc_projecao_orc,
        };

        defineTipoDemGer(newTipoDemGer);
        setHiddenCadastrar(false);
        setHiddenPesquisar(true);
      }
    },
    [defineTipoDemGer],
  );

  const OnConfirmationDeleteSingle = useCallback(
    async (id: string) => {
      if (licencaInvalida()) return;

      if (id === '') return;

      setIdDeleteTipoDemGer(id);
      setOpenDeleteSingle(true);
    },
    [licencaInvalida],
  );

  const colunaAcao: GridColDef = {
    field: 'acao',
    headerName: 'Ação',
    editable: false,
    filterable: false,
    hideSortIcons: true,
    sortable: false,
    disableColumnMenu: true,
    width: 160,
    renderCell: (params: GridCellParams) => (
      <strong>
        <Tooltip title="Editar Registro" placement="top">
          <IconButton color="primary" aria-label="Editar" onClick={() => OnGridSetRegisterToEdit(params)}>
            <MdEdit />
          </IconButton>
        </Tooltip>

        <Tooltip title="Duplicar Registro" placement="top">
          <IconButton
            color="primary"
            aria-label="Duplicar"
            onClick={() => OnGridRowDuplicate(params.row as ITipoDemGer)}
          >
            <FiCopy />
          </IconButton>
        </Tooltip>

        <Tooltip title="Excluir Registro" placement="top">
          <IconButton
            color="primary"
            aria-label="Excluir"
            onClick={() => OnConfirmationDeleteSingle((params.row as ITipoDemGer).id)}
          >
            <MdDelete />
          </IconButton>
        </Tooltip>
      </strong>
    ),
  };

  const columns: GridColDef[] = [
    colunaAcao,
    ColumnCodigo,
    ColumnDescricao,
    ColumnPermiteCalcularProjecaoOrcamentaria,
    ColumnObservacao,
    ColumnId,
  ];

  const getTipoDemGer = useCallback(async () => {
    const response = await api.get<ITipoDemGer[]>(`/tipodemger/all`);
    setListTipoDemGer(response.data);
    if (rowToEdit !== null) {
      const findTipoDemGer = response.data.find(item => item.id === rowToEdit);

      if (findTipoDemGer) {
        defineTipoDemGer(findTipoDemGer);
        setTipoDemGerIndex(response.data.indexOf(findTipoDemGer));
        setHiddenCadastrar(false);
        setHiddenPesquisar(true);
        setRowToEdit(null);
      }
    }
  }, [defineTipoDemGer, rowToEdit]);

  const getValues = useCallback(async () => {
    setOpenLoading(true);

    await getTipoDemGer();

    setOpenLoading(false);
    setHiddenPesquisar(false);
  }, [getTipoDemGer]);

  useEffect(() => {
    getValues();

    const rowHeight = localStorage.getItem(getPathGrid());
    if (rowHeight) {
      setGridRowHeight(parseInt(rowHeight, 10));
    }
  }, [getValues]);

  const handleOnRowDeleteSingle = useCallback(async () => {
    setOpenLoading(true);
    await api.delete(`/tipodemger/${idDeleteTipoDemGer}`);

    setListTipoDemGer(OldValue => OldValue.filter(item => item.id !== idDeleteTipoDemGer));

    defineTipoDemGer(emptyTipoDemGer);

    addToast({
      type: 'success',
      title: 'Removido',
      description: 'Registro excluído com sucesso!',
    });

    setOpenDeleteSingle(false);
    setOpenLoading(false);
  }, [idDeleteTipoDemGer, addToast, defineTipoDemGer]);

  const handleOnRowDeleteMultiple = useCallback(async () => {
    setOpenLoading(true);
    if (selectedIds.length > 0) {
      await Promise.all(
        selectedIds.map(itemId => {
          setListTipoDemGer(OldValue => OldValue.filter(item => item.id !== itemId));

          return api.delete(`/tipodemger/${itemId}`);
        }),
      );

      defineTipoDemGer(emptyTipoDemGer);

      addToast({
        type: 'success',
        title: 'Removido',
        description: 'Registro excluído com sucesso!',
      });
    }

    setSelectedIds([]);
    setOpenDeleteMultiple(false);
    setOpenLoading(false);
  }, [addToast, defineTipoDemGer, selectedIds]);

  const handleClick = (id: string): void => {
    setHiddenCadastrar(id === 'pesquisar');
    setHiddenPesquisar(id === 'cadastrar');
  };

  const onNextRegister = useCallback(async () => {
    if (tipoDemGerIndex !== undefined) {
      if (tipoDemGerIndex < listTipoDemGer.length - 1) {
        const localIndex = tipoDemGerIndex + 1;
        setTipoDemGerIndex(localIndex);
        const findItem = listTipoDemGer[localIndex];
        if (findItem) {
          defineTipoDemGer(findItem);
        }
      }
    }
  }, [tipoDemGerIndex, listTipoDemGer, defineTipoDemGer]);

  const onFirstRegister = useCallback(async () => {
    if (listTipoDemGer.length > 0) {
      setTipoDemGerIndex(0);
      const findItem = listTipoDemGer[0];
      if (findItem) {
        defineTipoDemGer(findItem);
      }
    }
  }, [listTipoDemGer, defineTipoDemGer]);

  const onLastRegister = useCallback(async () => {
    if (listTipoDemGer.length > 0) {
      setTipoDemGerIndex(listTipoDemGer.length - 1);
      const findItem = listTipoDemGer[listTipoDemGer.length - 1];
      if (findItem) {
        defineTipoDemGer(findItem);
      }
    }
  }, [listTipoDemGer, defineTipoDemGer]);

  const onPriorRegister = useCallback(async () => {
    if (tipoDemGerIndex !== undefined) {
      if (tipoDemGerIndex > 0) {
        const localIndex = tipoDemGerIndex - 1;
        setTipoDemGerIndex(localIndex);
        const findItem = listTipoDemGer[localIndex];
        if (findItem) {
          defineTipoDemGer(findItem);
        }
      }
    }
  }, [tipoDemGerIndex, listTipoDemGer, defineTipoDemGer]);

  const onCancelRegister = useCallback(async () => {
    formRef.current?.setErrors({});

    if (tipoDemGerIndex !== undefined) {
      const findItem = listTipoDemGer[tipoDemGerIndex];
      if (findItem) {
        defineTipoDemGer(findItem);
      }
    }

    onNewRegister();
  }, [tipoDemGerIndex, listTipoDemGer, onNewRegister, defineTipoDemGer]);

  const handleSubmit = useCallback(
    async (data: ITipoDemGer, { reset }) => {
      if (licencaInvalida()) return;

      formRef.current?.setErrors({});
      try {
        const schema = Yup.object().shape({
          codigo: Yup.string().required('Código é obrigatório'),
          descricao: Yup.string().required('Descrição é obrigatória'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        setOpenLoading(true);
        const formData: ITipoDemGer = {
          codigo: data.codigo,
          descricao: data.descricao,
          obs: data.obs,
          permite_calc_projecao_orc: data.permite_calc_projecao_orc,
          id: data.id,
          acao: data.acao,
        };

        if (data.id === '') {
          const response = await api.post(`/tipodemger`, formData);
          formData.id = response.data.id;

          setListTipoDemGer(oldLicenses => [...oldLicenses, data]);
          addToast({
            type: 'success',
            title: 'Adicionado',
            description: 'Registro adicionado com sucesso!',
          });
        } else {
          await api.put(`/tipodemger/${data.id}`, formData);
          setListTipoDemGer(getListUpdate(listTipoDemGer, formData));
          addToast({
            type: 'success',
            title: 'Alterado',
            description: 'Registro alterado com sucesso!',
          });
        }

        reset();
        setOpenLoading(false);
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          formRef.current?.setErrors(errors);
        }

        setOpenLoading(false);
      }
    },
    [licencaInvalida, listTipoDemGer, addToast],
  );

  return (
    <Container>
      <Header />

      <Tab>
        <TabButtonArea>
          <TabButton id="pesquisar" isActive={!hiddenPesquisar} onClick={() => handleClick('pesquisar')}>
            Listagem
          </TabButton>
          <TabButton id="cadastrar" isActive={!hiddenCadastrar} onClick={() => handleClick('cadastrar')}>
            Cadastro
          </TabButton>

          <Tooltip title="Reduzir o tamanho da linha" placement="top">
            <TabButtonGridRowHeight id="gridrowheightdown" onClick={() => handleGridRowHeightDown()}>
              <AiOutlineCaretDown />
            </TabButtonGridRowHeight>
          </Tooltip>

          <Tooltip title="Aumentar o tamanho da linha" placement="top">
            <TabButtonGridRowHeight id="gridrowheightup" onClick={() => handleGridRowHeightUp()}>
              <AiOutlineCaretUp />
            </TabButtonGridRowHeight>
          </Tooltip>

          <Tooltip title="Novo Registro" placement="top">
            <TabButtonNovo id="new_register" onClick={() => OnSetRegisterToAdd()}>
              <GoPlus />
              Novo
            </TabButtonNovo>
          </Tooltip>

          <Tooltip title="Remover Registros Selecionados" placement="top">
            <span>
              <span hidden={selectedIds.length <= 0}>
                <TabButtonDelete
                  id="remove_all"
                  onClick={() => {
                    setIdDeleteTipoDemGer('');
                    setOpenDeleteMultiple(true);
                  }}
                >
                  <RiDeleteBinLine />
                  Remover
                </TabButtonDelete>
              </span>
            </span>
          </Tooltip>
        </TabButtonArea>

        <TabTitulo>
          <span>{titulo}</span>
        </TabTitulo>
      </Tab>

      <TabContent id="pesquisar" isActive={!hiddenPesquisar}>
        <div style={{ height: '85vh', width: '100%' }}>
          <ThemeProvider theme={theme}>
            <DataGrid
              rows={listTipoDemGer}
              columns={columns}
              rowHeight={gridRowHeight}
              checkboxSelection
              selectionModel={selectedIds}
              disableSelectionOnClick
              onSelectionModelChange={e => setSelectedIds(e.selectionModel)}
            />
          </ThemeProvider>
        </div>
      </TabContent>

      <TabContent id="cadasto" isActive={!hiddenCadastrar}>
        <Form ref={formRef} onSubmit={handleSubmit} initialData={demGerParametro}>
          <FormContainer>
            <FormNavigate>
              <Tooltip title="Novo Registro" placement="top">
                <IconButton color="primary" aria-label="adicionar" onClick={() => onNewRegister()}>
                  <AiOutlinePlus />
                </IconButton>
              </Tooltip>

              <Tooltip title="Duplicar Registro" placement="top">
                <IconButton color="primary" aria-label="Duplicar" onClick={() => OnGridRowDuplicate(demGerParametro)}>
                  <MdContentCopy />
                </IconButton>
              </Tooltip>

              <Tooltip title="Excluir Registro" placement="top">
                <IconButton
                  color="primary"
                  aria-label="Excluir"
                  onClick={() => OnConfirmationDeleteSingle(demGerParametro ? demGerParametro.id : '')}
                >
                  <RiDeleteBinLine />
                </IconButton>
              </Tooltip>

              <Tooltip title="Cancelar" placement="top">
                <IconButton type="reset" color="primary" aria-label="Cancelar" onClick={() => onCancelRegister()}>
                  <MdClear />
                </IconButton>
              </Tooltip>

              <Tooltip title="Salvar" placement="top">
                <IconButton type="submit" color="primary" aria-label="Confirmar">
                  <AiOutlineSave />
                </IconButton>
              </Tooltip>

              <Tooltip title="Primeiro Registro" placement="top">
                <IconButton color="primary" aria-label="Primeiro" onClick={() => onFirstRegister()}>
                  <AiFillFastBackward />
                </IconButton>
              </Tooltip>

              <Tooltip title="Registro Anterior" placement="top">
                <IconButton color="primary" aria-label="Anterior" onClick={() => onPriorRegister()}>
                  <MdSkipPrevious />
                </IconButton>
              </Tooltip>

              <Tooltip title="Próximo Registro" placement="top">
                <IconButton color="primary" aria-label="Próximo" onClick={() => onNextRegister()}>
                  <MdSkipNext />
                </IconButton>
              </Tooltip>

              <Tooltip title="Último Registro" placement="top">
                <IconButton color="primary" aria-label="Último registro" onClick={() => onLastRegister()}>
                  <AiFillFastForward />
                </IconButton>
              </Tooltip>
            </FormNavigate>

            <FormFields>
              <ContainerGroup>
                <ContainerFields field="T200px">
                  <span>Código:</span>
                  <InputComum autoFocus name="codigo" />
                </ContainerFields>

                <ContainerFieldCheckbox className="field2">
                  <Checkbox name="permite_calc_projecao_orc" />
                  <span>Permite Calcular Projeção Orçamentária</span>
                </ContainerFieldCheckbox>
              </ContainerGroup>

              <ContainerFields field="T100">
                <span>Descrição:</span>
                <InputComum autoFocus name="descricao" />
              </ContainerFields>
              <ContainerFields field="T100">
                <span>Observação:</span>
                <InputComum autoFocus name="obs" />
              </ContainerFields>
              <ContainerFields field="id">
                <InputComum name="id" hidden />
              </ContainerFields>
            </FormFields>
          </FormContainer>
        </Form>
      </TabContent>

      <FormModalDialogConfirm
        open={openDeleteSingle}
        onClose={() => setOpenDeleteSingle(false)}
        onConfirm={() => handleOnRowDeleteSingle()}
        buttonCancelText="Cancelar"
        buttonConfirmText="Sim"
        formTitle="Excluir?"
        messageConfirm="Tem certeza que deseja excluir?"
      />

      <FormModalDialogConfirm
        open={openDeleteMultiple}
        onClose={() => setOpenDeleteMultiple(false)}
        onConfirm={() => handleOnRowDeleteMultiple()}
        buttonCancelText="Cancelar"
        buttonConfirmText="Sim"
        formTitle="Excluir?"
        messageConfirm="Tem certeza que deseja excluir todos os registros selecionados?"
      />

      <Backdrop className={cssLoading.backdrop} open={openLoading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </Container>
  );
};

export default TipoDemGer;
