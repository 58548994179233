import React, { useRef, useState, useEffect, useCallback } from 'react';
import * as Yup from 'yup';
import { DataGrid, GridColDef, GridCellParams, GridRowId, ptBR } from '@material-ui/data-grid';
import { createMuiTheme, ThemeProvider, makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';

import { FiCopy } from 'react-icons/fi';
import { MdDelete, MdClear, MdSkipNext, MdSkipPrevious, MdContentCopy } from 'react-icons/md';
import {
  AiOutlinePlus,
  AiOutlineSave,
  AiFillFastBackward,
  AiFillFastForward,
  AiOutlineCaretDown,
  AiOutlineCaretUp,
} from 'react-icons/ai';
import { GoPlus } from 'react-icons/go';
import { RiDeleteBinLine } from 'react-icons/ri';

import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';

import InputComum from '../../components/InputComum';
import FormModalDialogConfirm from '../../components/FormModalDialogConfirm';

import api from '../../services/api';
import { useAuth } from '../../hooks/Auth';
import { useToast } from '../../hooks/Toast';

import Header from '../../components/Header';
import { ColumnName, ColumnToken, ColumnMacaddress, ColumnIdLicense, ColumnId } from './GridColumns';

import IKeyAssociate, { emptyKeyAssociate } from './IKeyAssociate';

import getListUpdate from '../../utils/getListUpdate';
import { getPathGrid } from '../../utils/storagePath';

import {
  Container,
  ContainerFields,
  Tab,
  TabButton,
  TabContent,
  FormFields,
  FormContainer,
  FormNavigate,
  TabTitulo,
  TabButtonArea,
  TabButtonNovo,
  TabButtonDelete,
  TabButtonGridRowHeight,
} from './styles';

import getValidationErrors from '../../utils/getValidationErrors';

const useStylesLoading = makeStyles((theme: Theme) =>
  createStyles({
    backdrop: {
      zIndex: theme.zIndex.drawer + 2000,
      color: '#fff',
    },
  }),
);

const theme = createMuiTheme(
  {
    palette: {
      primary: { main: '#006699' },
    },
  },
  ptBR,
);

const KeyAssociate: React.FC = () => {
  const { user } = useAuth();
  const { addToast } = useToast();
  const formRef = useRef<FormHandles>(null);
  const [demGerOrcado, setKeyAssociate] = useState<IKeyAssociate>();
  const [listKeyAssociate, setListKeyAssociate] = useState<IKeyAssociate[]>([]);
  const [hiddenPesquisar, setHiddenPesquisar] = useState<boolean>(true);
  const [hiddenCadastrar, setHiddenCadastrar] = useState<boolean>(true);
  const [keyAssociateIndex, setKeyAssociateIndex] = useState<number>(0);
  const [rowToEdit, setRowToEdit] = useState(null);
  const [selectedIds, setSelectedIds] = useState<GridRowId[]>([]);
  const [idDeleteKeyAssociate, setIdDeleteKeyAssociate] = useState<string>('');
  const [openDeleteSingle, setOpenDeleteSingle] = useState(false);
  const [openDeleteMultiple, setOpenDeleteMultiple] = useState(false);
  const [openLoading, setOpenLoading] = useState(false);
  const cssLoading = useStylesLoading();
  const [gridRowHeight, setGridRowHeight] = useState<number>(46);

  const handleGridRowHeightUp = useCallback(async () => {
    const heightValue: number = gridRowHeight + 1;
    setGridRowHeight(heightValue);
    localStorage.setItem(getPathGrid(), heightValue.toString());
  }, [gridRowHeight]);

  const handleGridRowHeightDown = useCallback(async () => {
    const heightValue: number = gridRowHeight - 1;
    setGridRowHeight(heightValue);
    localStorage.setItem(getPathGrid(), heightValue.toString());
  }, [gridRowHeight]);

  const licencaInvalida = useCallback((): boolean => {
    if (!user.license || user.license.id === '') {
      addToast({
        type: 'error',
        title: 'Licença inválida',
        description: 'Usuário não está vinculado a nenhuma licença',
      });

      return true;
    }

    return false;
  }, [addToast, user]);

  const defineKeyAssociate = useCallback(async (data: IKeyAssociate) => {
    setKeyAssociate(data);
    formRef.current?.setData(data);
  }, []);

  const onNewRegister = useCallback(async () => {
    defineKeyAssociate(emptyKeyAssociate);
  }, [defineKeyAssociate]);

  const OnSetRegisterToAdd = useCallback(async () => {
    onNewRegister();
    setHiddenCadastrar(false);
    setHiddenPesquisar(true);
  }, [onNewRegister]);

  const OnGridRowDuplicate = useCallback(
    async (baseKeyAssociate: IKeyAssociate | undefined) => {
      if (baseKeyAssociate !== undefined) {
        const newKeyAssociate: IKeyAssociate = {
          acao: baseKeyAssociate.acao,
          id: '',
          macaddress: baseKeyAssociate.macaddress,
          token: baseKeyAssociate.token,
          name: baseKeyAssociate.name,
          id_license: baseKeyAssociate.id_license,
        };

        defineKeyAssociate(newKeyAssociate);
        setHiddenCadastrar(false);
        setHiddenPesquisar(true);
      }
    },
    [defineKeyAssociate],
  );

  const OnConfirmationDeleteSingle = useCallback(
    async (id: string) => {
      if (licencaInvalida()) return;

      if (id === '') return;

      setIdDeleteKeyAssociate(id);
      setOpenDeleteSingle(true);
    },
    [licencaInvalida],
  );

  const colunaAcao: GridColDef = {
    field: 'acao',
    headerName: 'Ação',
    editable: false,
    filterable: false,
    hideSortIcons: true,
    sortable: false,
    disableColumnMenu: true,
    width: 120,
    renderCell: (params: GridCellParams) => (
      <strong>
        <Tooltip title="Duplicar Registro" placement="top">
          <IconButton
            color="primary"
            aria-label="Duplicar"
            onClick={() => OnGridRowDuplicate(params.row as IKeyAssociate)}
          >
            <FiCopy />
          </IconButton>
        </Tooltip>

        <Tooltip title="Excluir Registro" placement="top">
          <span>
            <IconButton
              color="primary"
              aria-label="Excluir"
              onClick={() => OnConfirmationDeleteSingle((params.row as IKeyAssociate).id)}
            >
              <MdDelete />
            </IconButton>
          </span>
        </Tooltip>
      </strong>
    ),
  };

  const columns: GridColDef[] = [colunaAcao, ColumnName, ColumnToken, ColumnMacaddress, ColumnIdLicense, ColumnId];

  const getKeyAssociate = useCallback(async () => {
    if (licencaInvalida()) return;

    setOpenLoading(true);
    const response = await api.get<IKeyAssociate[]>(`/keyAssociate/all/${user.license.id}`);

    setListKeyAssociate(response.data);

    if (rowToEdit !== null) {
      const findEditKeyAssociate = response.data.find(item => item.id === rowToEdit);

      if (findEditKeyAssociate) {
        defineKeyAssociate(findEditKeyAssociate);
        setKeyAssociateIndex(response.data.indexOf(findEditKeyAssociate));
        setHiddenCadastrar(false);
        setHiddenPesquisar(true);
        setRowToEdit(null);
      }
    }
    setOpenLoading(false);
  }, [user, licencaInvalida, rowToEdit, defineKeyAssociate]);

  const getValues = useCallback(async () => {
    await getKeyAssociate();
    setHiddenPesquisar(false);
  }, [getKeyAssociate]);

  useEffect(() => {
    getValues();

    const rowHeight = localStorage.getItem(getPathGrid());
    if (rowHeight) {
      setGridRowHeight(parseInt(rowHeight, 10));
    }
  }, [getValues]);

  const handleOnRowDeleteSingle = useCallback(async () => {
    setOpenLoading(true);
    await api.delete(`/keyAssociate/${idDeleteKeyAssociate}`);

    setListKeyAssociate(oldKeyAssociate => oldKeyAssociate.filter(item => item.id !== idDeleteKeyAssociate));

    defineKeyAssociate(emptyKeyAssociate);

    addToast({
      type: 'success',
      title: 'Removido',
      description: 'Registro excluído com sucesso!',
    });

    setOpenDeleteSingle(false);
    setOpenLoading(false);
  }, [idDeleteKeyAssociate, addToast, defineKeyAssociate]);

  const handleOnRowDeleteMultiple = useCallback(async () => {
    setOpenLoading(true);
    if (selectedIds.length > 0) {
      await Promise.all(
        selectedIds.map(itemId => {
          setListKeyAssociate(oldKeyAssociate => oldKeyAssociate.filter(item => item.id !== itemId));

          return api.delete(`/keyAssociate/${itemId}`);
        }),
      );

      defineKeyAssociate(emptyKeyAssociate);

      addToast({
        type: 'success',
        title: 'Removido',
        description: 'Registro excluído com sucesso!',
      });
    }

    setSelectedIds([]);
    setOpenDeleteMultiple(false);
    setOpenLoading(false);
  }, [addToast, defineKeyAssociate, selectedIds]);

  const handleClick = (id: string): void => {
    setHiddenCadastrar(id === 'pesquisar');
    setHiddenPesquisar(id === 'cadastrar');
  };

  const onNextRegister = useCallback(async () => {
    if (keyAssociateIndex !== undefined) {
      if (keyAssociateIndex < listKeyAssociate.length - 1) {
        const localIndex = keyAssociateIndex + 1;
        setKeyAssociateIndex(localIndex);
        const findKeyAssociate = listKeyAssociate[localIndex];
        if (findKeyAssociate) {
          defineKeyAssociate(findKeyAssociate);
        }
      }
    }
  }, [keyAssociateIndex, listKeyAssociate, defineKeyAssociate]);

  const onFirstRegister = useCallback(async () => {
    if (listKeyAssociate.length > 0) {
      setKeyAssociateIndex(0);
      const findKeyAssociate = listKeyAssociate[0];
      if (findKeyAssociate) {
        defineKeyAssociate(findKeyAssociate);
      }
    }
  }, [listKeyAssociate, defineKeyAssociate]);

  const onLastRegister = useCallback(async () => {
    if (listKeyAssociate.length > 0) {
      setKeyAssociateIndex(listKeyAssociate.length - 1);
      const findKeyAssociate = listKeyAssociate[listKeyAssociate.length - 1];
      if (findKeyAssociate) {
        defineKeyAssociate(findKeyAssociate);
      }
    }
  }, [listKeyAssociate, defineKeyAssociate]);

  const onPriorRegister = useCallback(async () => {
    if (keyAssociateIndex !== undefined) {
      if (keyAssociateIndex > 0) {
        const localIndex = keyAssociateIndex - 1;
        setKeyAssociateIndex(localIndex);
        const findKeyAssociate = listKeyAssociate[localIndex];
        if (findKeyAssociate) {
          defineKeyAssociate(findKeyAssociate);
        }
      }
    }
  }, [keyAssociateIndex, listKeyAssociate, defineKeyAssociate]);

  const onCancelRegister = useCallback(async () => {
    formRef.current?.setErrors({});

    if (keyAssociateIndex !== undefined) {
      const findKeyAssociate = listKeyAssociate[keyAssociateIndex];
      if (findKeyAssociate) {
        defineKeyAssociate(findKeyAssociate);
      }
    }

    onNewRegister();
  }, [keyAssociateIndex, listKeyAssociate, onNewRegister, defineKeyAssociate]);

  const handleSubmit = useCallback(
    async (data: IKeyAssociate, { reset }) => {
      if (licencaInvalida()) return;

      formRef.current?.setErrors({});
      try {
        const schema = Yup.object().shape({
          name: Yup.string().required('Descrição é obrigatória'),
          macaddress: Yup.string().required('Mac-Address é obrigatório'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        setOpenLoading(true);
        if (data.id === '') {
          const formData: IKeyAssociate = {
            id_license: user.license.id,
            name: data.name ? data.name : '',
            macaddress: data.macaddress,
            acao: data.acao,
            id: data.id,
            token: data.token,
          };

          const response = await api.post(`/keyAssociate`, formData);
          setListKeyAssociate(oldLicenses => [...oldLicenses, response.data]);

          addToast({
            type: 'success',
            title: 'Adicionado',
            description: 'Registro adicionado com sucesso!',
          });
        } else {
          await api.put(`/keyAssociate/${data.id}`, data);
          setListKeyAssociate(getListUpdate(listKeyAssociate, data));

          addToast({
            type: 'success',
            title: 'Alterado',
            description: 'Registro alterado com sucesso!',
          });
        }

        reset();
        setOpenLoading(false);
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          formRef.current?.setErrors(errors);
        }
        setOpenLoading(false);
      }
    },
    [licencaInvalida, listKeyAssociate, user, addToast],
  );

  return (
    <Container>
      <Header />

      <Tab>
        <TabButtonArea>
          <TabButton id="pesquisar" isActive={!hiddenPesquisar} onClick={() => handleClick('pesquisar')}>
            Listagem
          </TabButton>
          <TabButton id="cadastrar" isActive={!hiddenCadastrar} onClick={() => handleClick('cadastrar')}>
            Cadastro
          </TabButton>

          <Tooltip title="Reduzir o tamanho da linha" placement="top">
            <TabButtonGridRowHeight id="gridrowheightdown" onClick={() => handleGridRowHeightDown()}>
              <AiOutlineCaretDown />
            </TabButtonGridRowHeight>
          </Tooltip>

          <Tooltip title="Aumentar o tamanho da linha" placement="top">
            <TabButtonGridRowHeight id="gridrowheightup" onClick={() => handleGridRowHeightUp()}>
              <AiOutlineCaretUp />
            </TabButtonGridRowHeight>
          </Tooltip>

          <Tooltip title="Novo Registro" placement="top">
            <TabButtonNovo id="new_register" onClick={() => OnSetRegisterToAdd()}>
              <GoPlus />
              Novo
            </TabButtonNovo>
          </Tooltip>

          <Tooltip title="Remover Registros Selecionados" placement="top">
            <span>
              <span hidden={selectedIds.length <= 0}>
                <TabButtonDelete
                  id="remove_all"
                  onClick={() => {
                    setIdDeleteKeyAssociate('');
                    setOpenDeleteMultiple(true);
                  }}
                >
                  <RiDeleteBinLine />
                  Remover
                </TabButtonDelete>
              </span>
            </span>
          </Tooltip>
        </TabButtonArea>

        <TabTitulo>
          <span>C O M P U T A D O R E S</span>
        </TabTitulo>
      </Tab>

      <TabContent id="pesquisar" isActive={!hiddenPesquisar}>
        <div style={{ height: '86vh', width: '100%' }}>
          <ThemeProvider theme={theme}>
            <DataGrid
              rows={listKeyAssociate}
              columns={columns}
              rowHeight={gridRowHeight}
              checkboxSelection
              selectionModel={selectedIds}
              disableSelectionOnClick
              onSelectionModelChange={e => setSelectedIds(e.selectionModel)}
            />
          </ThemeProvider>
        </div>
      </TabContent>

      <TabContent id="cadasto" isActive={!hiddenCadastrar}>
        <Form ref={formRef} onSubmit={handleSubmit} initialData={demGerOrcado}>
          <FormContainer>
            <FormNavigate>
              <Tooltip title="Novo Registro" placement="top">
                <IconButton color="primary" aria-label="adicionar" onClick={() => onNewRegister()}>
                  <AiOutlinePlus />
                </IconButton>
              </Tooltip>

              <Tooltip title="Duplicar Registro" placement="top">
                <IconButton color="primary" aria-label="Duplicar" onClick={() => OnGridRowDuplicate(demGerOrcado)}>
                  <MdContentCopy />
                </IconButton>
              </Tooltip>

              <Tooltip title="Excluir Registro" placement="top">
                <IconButton
                  color="primary"
                  aria-label="Excluir"
                  onClick={() => OnConfirmationDeleteSingle(demGerOrcado ? demGerOrcado.id : '')}
                >
                  <RiDeleteBinLine />
                </IconButton>
              </Tooltip>

              <Tooltip title="Cancelar" placement="top">
                <IconButton type="reset" color="primary" aria-label="Cancelar" onClick={() => onCancelRegister()}>
                  <MdClear />
                </IconButton>
              </Tooltip>

              <Tooltip title="Salvar" placement="top">
                <IconButton type="submit" color="primary" aria-label="Confirmar">
                  <AiOutlineSave />
                </IconButton>
              </Tooltip>

              <Tooltip title="Primeiro Registro" placement="top">
                <IconButton color="primary" aria-label="Primeiro" onClick={() => onFirstRegister()}>
                  <AiFillFastBackward />
                </IconButton>
              </Tooltip>

              <Tooltip title="Registro Anterior" placement="top">
                <IconButton color="primary" aria-label="Anterior" onClick={() => onPriorRegister()}>
                  <MdSkipPrevious />
                </IconButton>
              </Tooltip>

              <Tooltip title="Próximo Registro" placement="top">
                <IconButton color="primary" aria-label="Próximo" onClick={() => onNextRegister()}>
                  <MdSkipNext />
                </IconButton>
              </Tooltip>

              <Tooltip title="Último Registro" placement="top">
                <IconButton color="primary" aria-label="Último registro" onClick={() => onLastRegister()}>
                  <AiFillFastForward />
                </IconButton>
              </Tooltip>
            </FormNavigate>

            <FormFields>
              <ContainerFields field="text">
                <span>Descrição:</span>
                <InputComum autoFocus name="name" />
              </ContainerFields>
              <ContainerFields field="text">
                <span>Chave:</span>
                <InputComum autoFocus name="macaddress" />
              </ContainerFields>
              <ContainerFields field="text" className="desabilitado">
                <span>Token - Licença:</span>
                <InputComum autoFocus name="token" disabled />
              </ContainerFields>
              <ContainerFields field="id">
                <InputComum name="id" hidden />
              </ContainerFields>
              <ContainerFields field="id">
                <InputComum name="id_license" hidden />
              </ContainerFields>
            </FormFields>
          </FormContainer>
        </Form>
      </TabContent>

      <FormModalDialogConfirm
        open={openDeleteSingle}
        onClose={() => setOpenDeleteSingle(false)}
        onConfirm={() => handleOnRowDeleteSingle()}
        buttonCancelText="Cancelar"
        buttonConfirmText="Sim"
        formTitle="Excluir?"
        messageConfirm="Tem certeza que deseja excluir?"
      />

      <FormModalDialogConfirm
        open={openDeleteMultiple}
        onClose={() => setOpenDeleteMultiple(false)}
        onConfirm={() => handleOnRowDeleteMultiple()}
        buttonCancelText="Cancelar"
        buttonConfirmText="Sim"
        formTitle="Excluir?"
        messageConfirm="Tem certeza que deseja excluir todos os registros selecionados?"
      />

      <Backdrop className={cssLoading.backdrop} open={openLoading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </Container>
  );
};

export default KeyAssociate;
