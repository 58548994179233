import ICreateCadHistPadraoDTO from './IHistoricoPadrao';

export default interface IDemGerCadConta {
  id: string;
  id_company: string;
  id_license: string;
  ind_cta: string;
  nivel: number;
  cod_cta: string;
  nome_cta: string;
  cod_cta_superior: string;
  cod_nat: string;
  classificacao: string;
  tp_classif_dre: string;
  exigir_centro_custo: boolean;
  historico_permite_complemento: boolean;
  ativa: boolean;
  obs: string;
  inf1: string;
  inf2: string;
  inf3: string;
  inf4: string;
  inf5: string;
  acao: string;
  historico: ICreateCadHistPadraoDTO[];
}

export const emptyDemGerCadConta: IDemGerCadConta = {
  acao: '',
  classificacao: '',
  tp_classif_dre: '00-Não Influência',
  cod_cta: '',
  cod_cta_superior: '',
  cod_nat: '01-Contas de Ativo',
  ind_cta: '1-Sintético',
  inf1: '',
  inf2: '',
  inf3: '',
  inf4: '',
  inf5: '',
  nome_cta: '',
  obs: '',
  nivel: 1,
  id: '',
  id_company: '',
  id_license: '',
  exigir_centro_custo: false,
  historico_permite_complemento: false,
  ativa: true,
  historico: [],
};
