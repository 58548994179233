import React from 'react';

import Header from '../../components/Header';

import { Container, Section } from './styles';

const UserRequestAcess: React.FC = () => {
  return (
    <Container>
      <Header />
      <Section>
        <h1>Ops, </h1>
        <span>infelizmente você ainda não possui permissão de acesso a nenhuma empresa...</span>
        <h1>:-(</h1>
      </Section>
    </Container>
  );
};

export default UserRequestAcess;
