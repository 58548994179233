export default interface ITipoDemGer {
  acao: string;
  id: string;
  codigo: string;
  descricao: string;
  obs: string;
  permite_calc_projecao_orc: boolean;
}

export const emptyTipoDemGer: ITipoDemGer = {
  acao: '',
  id: '',
  codigo: '',
  descricao: '',
  obs: '',
  permite_calc_projecao_orc: false,
};
