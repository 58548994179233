import styled from 'styled-components';

export const Container = styled.div``;

export const ContainerGroup = styled.div`
  margin-top: 20px;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 10px auto 0;
  width: 480px;
  background-color: #fff;

  form {
    margin: 80px 0;
    width: 400px;
    text-align: center;
    display: flex;
    flex-direction: column;
  }

  h1 {
    margin-top: -60px;
    margin-bottom: 20px;
    font-size: 20px;
    text-align: center;
  }
`;

export const MySelect = styled.select`
  margin-top: 8px;
  margin-bottom: 28px;
  width: 100%;
  padding: 8px 16px;
  border: 1px solid transparent;
  border-color: rgba(0.1, 0.1, 0.1, 0.1) transparent;
  cursor: pointer;
  z-index: 99;
  background-color: #fff;

  option {
    padding: 8px 16px;
    border: 1px solid transparent;
    font-size: 16px;
    cursor: pointer;
  }
`;

export const ContainerChip = styled.div`
  margin-top: 8px;
`;
