import { GridColDef } from '@material-ui/data-grid';

export const ColumnTotalizador: GridColDef = {
  headerName: 'Totalizador',
  field: 'totalizador',
  width: 400,
};

export const ColumnCNPJ: GridColDef = {
  headerName: 'CNPJ',
  field: 'cnpj_estab_0000',
  width: 150,
};

export const ColumnCodCtaI050: GridColDef = {
  headerName: 'Conta',
  field: 'cod_cta_i050',
  width: 200,
};

export const ColumnCodCCustoI100: GridColDef = {
  headerName: 'Centro de Custo',
  field: 'cod_ccusto_i100',
  width: 200,
};

export const ColumnDescComplCta: GridColDef = {
  headerName: 'Descrição complementar conta',
  field: 'desc_compl_cta',
  width: 400,
};

export const ColumnNomeGestorCta: GridColDef = {
  headerName: 'Nome gestor conta',
  field: 'nome_gestor_cta',
  width: 400,
};

export const ColumnNomeAuditorCta: GridColDef = {
  headerName: 'Nome auditor conta',
  field: 'nome_auditor_cta',
  width: 400,
};

export const ColumnTPClassifDRE: GridColDef = {
  headerName: 'Tipo classificação DRE',
  field: 'tp_classif_dre',
  width: 260,
};

export const ColumnObservacao: GridColDef = {
  headerName: 'Observação',
  field: 'obs',
  width: 400,
};

export const ColumnInf1: GridColDef = {
  headerName: 'Informação 1',
  field: 'inf1',
  width: 400,
};

export const ColumnInf2: GridColDef = {
  headerName: 'Informação 2',
  field: 'inf2',
  width: 400,
};

export const ColumnInf3: GridColDef = {
  headerName: 'Informação 3',
  field: 'inf3',
  width: 400,
};

export const ColumnInf4: GridColDef = {
  headerName: 'Informação 4',
  field: 'inf4',
  width: 400,
};

export const ColumnInf5: GridColDef = {
  headerName: 'Informação 5',
  field: 'inf5',
  width: 400,
};

export const ColumnPercentRelacTot: GridColDef = {
  headerName: 'Percentual Participação',
  field: 'percent_relac_tot',
  width: 240,
  type: 'number',
  valueFormatter: params => {
    // onst valueFormatted = Number(params.value * 100).toLocaleString();
    // return `${valueFormatted} %`;
    return `${params.value} %`;
  },
};

export const ColumnIdDemGer: GridColDef = {
  headerName: 'IdDemGer',
  field: 'id_dem_ger',
  hide: true,
  width: 400,
  editable: false,
  filterable: false,
  hideSortIcons: true,
  sortable: false,
  disableColumnMenu: true,
};

export const ColumnIdLicense: GridColDef = {
  headerName: 'License',
  field: 'id_license',
  hide: true,
  width: 400,
  editable: false,
  filterable: false,
  hideSortIcons: true,
  sortable: false,
  disableColumnMenu: true,
};

export const ColumnId: GridColDef = {
  headerName: '#',
  field: 'id',
  hide: true,
  width: 400,
  editable: false,
  filterable: false,
  hideSortIcons: true,
  sortable: false,
  disableColumnMenu: true,
};
