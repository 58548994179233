import React, { useState, useEffect, useCallback } from 'react';
import * as echarts from 'echarts';
import { Grid, Backdrop, CircularProgress, IconButton } from '@material-ui/core';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { AiOutlineSearch } from 'react-icons/ai';
import api from '../../services/api';
import { useAuth } from '../../hooks/Auth';

import Header from '../../components/Header';
import {
  CardBigNumbers,
  CardBigNumbersKindOptions,
  CardCheckingAccount,
  TableCashFlow,
  ChartTeste,
} from '../../components/templates/dashboard';

import IFinMovimentosBigNumbersDTO, {
  IBankBalancesDTO,
  emptyFinMovimentosBigNumbersDTO,
  IDailyCashFlowDTO,
} from './IFinMovimentosBigNumbersDTO';
import './style.css';
import ModalFilter from './Filter';
import IFinancialReleasesDTO from './IFinancialReleasesDTO';
import optionsChartFinMovimentos from './OptionsChartFinMovimentos';
import optionsChartReceitasDespesas from './OptionsChartReceitasDespesas';
import { IFilterFluxoCaixa, emptyFilterFluxoCaixa } from '../../components/templates/dashboard/IFilterFluxoCaixa';
import { IFilter } from './Filter/IFilter';

type EChartsOption = echarts.EChartsOption;

const useStylesLoading = makeStyles((theme: Theme) =>
  createStyles({
    backdrop: {
      zIndex: theme.zIndex.drawer + 2000,
      color: '#fff',
    },
  }),
);

interface ITempChartValueDTO {
  value: number;
  dateValue: Date;
  kind: number;
}

const Dashboard: React.FC = () => {
  const cssLoading = useStylesLoading();
  const { user } = useAuth();
  const [textFiltro, setTextFiltro] = useState<string>('Filtrado por: Data vencimento. Período: Hoje');
  const [openFilter, setOpenFilter] = useState(false);
  const [openLoading, setOpenLoading] = useState(false);
  const [receivable, setReceivable] = useState<IFinMovimentosBigNumbersDTO>(emptyFinMovimentosBigNumbersDTO);
  const [toPay, setToPay] = useState<IFinMovimentosBigNumbersDTO>(emptyFinMovimentosBigNumbersDTO);
  const [receivableAndToPayDiference, setReceivableAndToPayDiference] = useState<IFinMovimentosBigNumbersDTO>(
    emptyFinMovimentosBigNumbersDTO,
  );
  const [bankBalances, setBankBalances] = useState<IBankBalancesDTO[]>([]);
  const [dailyCashFlow, setDailyCashFlow] = useState<IDailyCashFlowDTO[]>([]);
  const [filterFieldPeriod, setFilterFieldPeriod] = useState(1);

  const [valueOptionsChartFinMovimentos, setValueOptionsChartFinMovimentos] = useState<EChartsOption>({});

  const option1: EChartsOption = {
    tooltip: {
      trigger: 'item',
    },
    legend: {
      top: 'bottom',
    },
    title: {
      text: 'Receitas por conta financeira',
      left: 'center',
    },
    series: [
      {
        type: 'pie',
        radius: ['40%', '70%'],
        avoidLabelOverlap: false,
        itemStyle: {
          borderRadius: 10,
          borderColor: '#fff',
          borderWidth: 2,
        },
        label: {
          show: false,
          position: 'center',
        },
        emphasis: {
          label: {
            show: true,
            fontSize: 14,
            fontWeight: 'bold',
          },
        },
        labelLine: {
          show: false,
        },
        data: [
          { value: 8900.58, name: 'Prestação de serviços' },
          { value: 3252.12, name: 'Receita locação' },
        ],
      },
    ],
  };

  const option2: EChartsOption = {
    tooltip: {
      trigger: 'item',
    },
    legend: {
      top: 'bottom',
    },
    title: {
      text: 'Despesas por conta financeira',
      left: 'center',
    },
    series: [
      {
        type: 'pie',
        radius: ['40%', '70%'],
        avoidLabelOverlap: false,
        itemStyle: {
          borderRadius: 10,
          borderColor: '#fff',
          borderWidth: 2,
        },
        label: {
          show: false,
          position: 'center',
        },
        emphasis: {
          label: {
            show: true,
            fontSize: 14,
            fontWeight: 'bold',
          },
        },
        labelLine: {
          show: false,
        },
        data: [
          { value: 3900.58, name: 'Despesa aluguel' },
          { value: 800.79, name: 'Custo serviços contábeis' },
          { value: 352.12, name: 'Outras despesas' },
        ],
      },
    ],
  };

  const getLabel = useCallback((inValue: number): string => {
    if (Number(inValue) === 1) return 'Hoje';
    if (Number(inValue) === 2) return 'Próximos 7 dias';
    if (Number(inValue) === 3) return 'Próximos 14 dias';
    if (Number(inValue) === 4) return 'Próximos 28 dias';
    if (Number(inValue) === 5) return 'Próximos 56 dias';
    if (Number(inValue) === 6) return 'Próximos 112 dias';
    if (Number(inValue) === 7) return 'Próximos 224 dias';
    if (Number(inValue) === 8) return 'Próximos 448 dias';

    return 'Próximos ? dias';
  }, []);

  const defineReceivable = useCallback(
    (
      inFilterFieldDate: string,
      inFilterFieldPeriod: number,
      inSumReleasesOverdueAmounts: number,
      inSumToPayOverdueAmounts: number,
      inlist: IFinancialReleasesDTO[],
    ) => {
      const localFieldDate = inFilterFieldDate === 'DATA' ? 'data' : 'data_vencimento';
      const localReceivable: IFinMovimentosBigNumbersDTO = {
        next14DaysValue: 0,
        next28DaysValue: 0,
        next56DaysValue: 0,
        todayValue: 0,
        overdueAmountsValue: inSumReleasesOverdueAmounts,
      };

      const localToPay: IFinMovimentosBigNumbersDTO = {
        next14DaysValue: 0,
        next28DaysValue: 0,
        next56DaysValue: 0,
        todayValue: 0,
        overdueAmountsValue: inSumToPayOverdueAmounts,
      };

      const compareDate01 = new Date();
      const compareDate02 = new Date();
      const compareDate03 = new Date();
      if (Number(inFilterFieldPeriod) === 1) {
        compareDate02.setDate(compareDate02.getDate() + 7);
        compareDate03.setDate(compareDate03.getDate() + 14);
      } else if (Number(inFilterFieldPeriod) === 2) {
        compareDate01.setDate(compareDate01.getDate() + 7);
        compareDate02.setDate(compareDate02.getDate() + 14);
        compareDate03.setDate(compareDate03.getDate() + 28);
      } else if (Number(inFilterFieldPeriod) === 3) {
        compareDate01.setDate(compareDate01.getDate() + 14);
        compareDate02.setDate(compareDate02.getDate() + 28);
        compareDate03.setDate(compareDate03.getDate() + 56);
      } else if (Number(inFilterFieldPeriod) === 4) {
        compareDate01.setDate(compareDate01.getDate() + 28);
        compareDate02.setDate(compareDate02.getDate() + 56);
        compareDate03.setDate(compareDate03.getDate() + 112);
      } else if (Number(inFilterFieldPeriod) === 5) {
        compareDate01.setDate(compareDate01.getDate() + 56);
        compareDate02.setDate(compareDate02.getDate() + 112);
        compareDate03.setDate(compareDate03.getDate() + 225);
      }
      compareDate01.setHours(0, 0, 0, 0);
      compareDate02.setHours(0, 0, 0, 0);
      compareDate03.setHours(0, 0, 0, 0);
      inlist.map(itemList => {
        const compareDate = new Date(itemList[localFieldDate]);
        compareDate.setHours(0, 0, 0, 0);
        if (itemList.tipo === '1-Receita') {
          if (Number(inFilterFieldPeriod) === 1) {
            // 1-Hoje
            if (compareDate.getTime() === compareDate01.getTime()) {
              localReceivable.todayValue += Number(
                itemList.valor_quitacao > 0 ? itemList.valor_quitacao : itemList.valor_original,
              );
            } else if (compareDate.getTime() <= compareDate02.getTime()) {
              localReceivable.next14DaysValue += Number(
                itemList.valor_quitacao > 0 ? itemList.valor_quitacao : itemList.valor_original,
              );
            } else if (compareDate.getTime() <= compareDate03.getTime()) {
              localReceivable.next28DaysValue += Number(
                itemList.valor_quitacao > 0 ? itemList.valor_quitacao : itemList.valor_original,
              );
            } else {
              localReceivable.next56DaysValue += Number(
                itemList.valor_quitacao > 0 ? itemList.valor_quitacao : itemList.valor_original,
              );
            }
          } else if (Number(inFilterFieldPeriod) !== 1) {
            if (compareDate.getTime() <= compareDate01.getTime()) {
              localReceivable.todayValue += Number(
                itemList.valor_quitacao > 0 ? itemList.valor_quitacao : itemList.valor_original,
              );
            } else if (compareDate.getTime() <= compareDate02.getTime()) {
              localReceivable.next14DaysValue += Number(
                itemList.valor_quitacao > 0 ? itemList.valor_quitacao : itemList.valor_original,
              );
            } else if (compareDate.getTime() <= compareDate03.getTime()) {
              localReceivable.next28DaysValue += Number(
                itemList.valor_quitacao > 0 ? itemList.valor_quitacao : itemList.valor_original,
              );
            } else {
              localReceivable.next56DaysValue += Number(
                itemList.valor_quitacao > 0 ? itemList.valor_quitacao : itemList.valor_original,
              );
            }
          }
        } else if (itemList.tipo === '2-Despesa') {
          if (Number(inFilterFieldPeriod) === 1) {
            // 1-Hoje
            if (compareDate.getTime() === compareDate01.getTime()) {
              localToPay.todayValue += Number(
                itemList.valor_quitacao > 0 ? itemList.valor_quitacao : itemList.valor_original,
              );
            } else if (compareDate.getTime() <= compareDate02.getTime()) {
              localToPay.next14DaysValue += Number(
                itemList.valor_quitacao > 0 ? itemList.valor_quitacao : itemList.valor_original,
              );
            } else if (compareDate.getTime() <= compareDate03.getTime()) {
              localToPay.next28DaysValue += Number(
                itemList.valor_quitacao > 0 ? itemList.valor_quitacao : itemList.valor_original,
              );
            } else {
              localToPay.next56DaysValue += Number(
                itemList.valor_quitacao > 0 ? itemList.valor_quitacao : itemList.valor_original,
              );
            }
          } else if (Number(inFilterFieldPeriod) !== 1) {
            if (compareDate.getTime() <= compareDate01.getTime()) {
              localToPay.todayValue += Number(
                itemList.valor_quitacao > 0 ? itemList.valor_quitacao : itemList.valor_original,
              );
            } else if (compareDate.getTime() <= compareDate02.getTime()) {
              localToPay.next14DaysValue += Number(
                itemList.valor_quitacao > 0 ? itemList.valor_quitacao : itemList.valor_original,
              );
            } else if (compareDate.getTime() <= compareDate03.getTime()) {
              localToPay.next28DaysValue += Number(
                itemList.valor_quitacao > 0 ? itemList.valor_quitacao : itemList.valor_original,
              );
            } else {
              localToPay.next56DaysValue += Number(
                itemList.valor_quitacao > 0 ? itemList.valor_quitacao : itemList.valor_original,
              );
            }
          }
        }

        return itemList;
      });

      setReceivable(localReceivable);
      setToPay(localToPay);
      setReceivableAndToPayDiference({
        todayValue: localReceivable.todayValue - localToPay.todayValue,
        overdueAmountsValue: localReceivable.next56DaysValue - localToPay.next56DaysValue,
        next14DaysValue: localReceivable.next14DaysValue - localToPay.next14DaysValue,
        next28DaysValue: localReceivable.next28DaysValue - localToPay.next28DaysValue,
        next56DaysValue: localReceivable.next56DaysValue - localToPay.next56DaysValue,
      });
    },
    [],
  );

  const getDailyCashFlow = useCallback(
    async (inFilter: IFilterFluxoCaixa) => {
      setOpenLoading(true);
      try {
        const localSituacao = [];
        if (!inFilter.situacao.pendente && !inFilter.situacao.previsto && !inFilter.situacao.quitado) {
          localSituacao.push('1-Previsto');
          localSituacao.push('2-Pendente');
          localSituacao.push('3-Quitado');
        } else {
          if (inFilter.situacao.previsto) localSituacao.push('1-Previsto');
          if (inFilter.situacao.pendente) localSituacao.push('2-Pendente');
          if (inFilter.situacao.quitado) localSituacao.push('3-Quitado');
        }

        const localTipo = [];
        if (!inFilter.tipo.despesa && !inFilter.tipo.receita && !inFilter.tipo.transferencia) {
          localTipo.push('1-Receita');
          localTipo.push('2-Despesa');
          localTipo.push('3-Transferência');
        } else {
          if (inFilter.tipo.receita) localTipo.push('1-Receita');
          if (inFilter.tipo.despesa) localTipo.push('2-Despesa');
          if (inFilter.tipo.transferencia) localTipo.push('3-Transferência');
        }

        const responseDailyCashFlow = await api.post<IDailyCashFlowDTO[]>(`/finmovimentos/dashboard/dailycashflow`, {
          ...inFilter,
          situacao: localSituacao.map(itemList => itemList),
          tipo: localTipo.map(itemList => itemList),
          license: user.license.id,
          id_company: user.empresa.id,
        });

        if (responseDailyCashFlow.status === 200) {
          setDailyCashFlow(
            responseDailyCashFlow.data.map(itemList => {
              return {
                ...itemList,
                tipo: itemList.tipo.substring(2, itemList.tipo.length),
                situacao: itemList.situacao.substring(2, itemList.situacao.length),
              };
            }),
          );
        }
        setOpenLoading(false);
      } catch (error) {
        setOpenLoading(false);
      }
    },
    [user],
  );

  const getReceivable = useCallback(
    async (inFilterFieldDate: string, inFilterFieldPeriod: number) => {
      setOpenLoading(true);
      try {
        const responseFinancialReleasesOverdueAmounts = await api.post<IFinancialReleasesDTO[]>(
          `/finmovimentos/dashboard/financialreleasesoverdueamounts`,
          {
            id_license: user.license.id,
            id_company: user.empresa.id,
            fieldDate: inFilterFieldDate,
            period: inFilterFieldPeriod,
          },
        );

        let sumReleasesOverdueAmounts = 0;
        let sumToPayOverdueAmounts = 0;
        if (responseFinancialReleasesOverdueAmounts.status === 200) {
          responseFinancialReleasesOverdueAmounts.data.map(itemList => {
            if (itemList.tipo === '1-Receita') {
              sumReleasesOverdueAmounts += Number(
                itemList.valor_quitacao > 0 ? itemList.valor_quitacao : itemList.valor_original,
              );
            } else if (itemList.tipo === '2-Despesa') {
              sumToPayOverdueAmounts += Number(
                itemList.valor_quitacao > 0 ? itemList.valor_quitacao : itemList.valor_original,
              );
            }

            return itemList;
          });
        }

        let localListFinancialReleases: IFinancialReleasesDTO[] = [];
        const responseFinancialReleases = await api.post<IFinancialReleasesDTO[]>(
          `/finmovimentos/dashboard/financialreleases`,
          {
            id_license: user.license.id,
            id_company: user.empresa.id,
            fieldDate: inFilterFieldDate,
            period: inFilterFieldPeriod,
          },
        );

        if (responseFinancialReleases.status === 200) {
          localListFinancialReleases = responseFinancialReleases.data;
        }

        defineReceivable(
          inFilterFieldDate,
          inFilterFieldPeriod,
          sumReleasesOverdueAmounts,
          sumToPayOverdueAmounts,
          localListFinancialReleases,
        );

        const responseBankBalances = await api.post<IBankBalancesDTO[]>(`/finmovimentos/dashboard/bankbalances`, {
          license: user.license.id,
          id_company: user.empresa.id,
        });
        if (responseBankBalances.status === 200) {
          setBankBalances(responseBankBalances.data);
        }

        const localFieldDate = inFilterFieldDate === 'DATA' ? 'data' : 'data_vencimento';
        const localListReceitas: number[] = [];
        const localListDespesas: number[] = [];
        const localListTemp: ITempChartValueDTO[] = [];
        const localListTempSaldo: ITempChartValueDTO[] = [];
        localListFinancialReleases
          .map(itemList => {
            const localDate = new Date(itemList[localFieldDate]);
            localDate.setHours(0, 0, 0, 0);
            if (itemList.tipo === '1-Receita') {
              return {
                kind: 1,
                dateValue: localDate,
                value: Math.abs(
                  itemList.valor_quitacao > 0 ? Number(itemList.valor_quitacao) : Number(itemList.valor_original),
                ),
              };
            }

            if (itemList.tipo === '2-Despesa') {
              return {
                kind: 2,
                dateValue: localDate,
                value: Math.abs(
                  itemList.valor_quitacao > 0 ? Number(itemList.valor_quitacao) : Number(itemList.valor_original),
                ),
              };
            }

            return undefined;
          })
          .sort((a, b) => {
            if (!a || !b) return 0;
            return a.dateValue.getTime() < b.dateValue.getTime() ? -1 : 1;
          })
          .map(itemList => {
            if (itemList) {
              if (itemList.kind === 1) {
                const findReceita = localListTemp.find(
                  element =>
                    element.dateValue.getTime() === itemList.dateValue.getTime() && element.kind === itemList.kind,
                );
                if (findReceita) {
                  findReceita.value = Number(findReceita.value) + Number(itemList.value);
                } else {
                  localListTemp.push({ ...itemList });
                }

                const findSaldo = localListTempSaldo.find(
                  element => element.dateValue.getTime() === itemList.dateValue.getTime(),
                );
                if (findSaldo) {
                  findSaldo.value = Number(findSaldo.value) + Number(itemList.value);
                } else {
                  localListTempSaldo.push({ ...itemList });
                }
              } else {
                const findDespesa = localListTemp.find(
                  element =>
                    element.dateValue.getTime() === itemList.dateValue.getTime() && element.kind === itemList.kind,
                );
                if (findDespesa) {
                  findDespesa.value = Number(findDespesa.value) + Number(itemList.value);
                } else {
                  localListTemp.push({ ...itemList });
                }

                const findSaldo = localListTempSaldo.find(
                  element => element.dateValue.getTime() === itemList.dateValue.getTime(),
                );
                if (findSaldo) {
                  findSaldo.value = Number(findSaldo.value) - Number(itemList.value);
                } else {
                  localListTempSaldo.push({
                    ...itemList,
                    value: Number(itemList.value) * -1,
                  });
                }
              }
            }

            return itemList;
          });

        const localListSaldos: number[] = localListTempSaldo.map(itemList => Number(itemList.value));
        const localListDays: string[] = [];
        localListTemp.map(itemList => {
          if (itemList) {
            if (itemList.value > 0) {
              const findDate = localListDays.find(element => element === itemList.dateValue.toLocaleDateString());
              if (!findDate) {
                localListDays.push(itemList.dateValue.toLocaleDateString());
              }
            }

            if (itemList.kind === 1) {
              localListReceitas.push(Number(itemList.value));
            } else if (itemList.kind === 2) {
              localListDespesas.push(Number(itemList.value));
            }
          }
          return itemList;
        });

        setValueOptionsChartFinMovimentos({
          ...optionsChartFinMovimentos,
          legend: {
            data: ['A receber', 'A pagar', 'Saldo'],
          },
          xAxis: [
            {
              type: 'category',
              data: localListDays,
              axisPointer: {
                type: 'shadow',
              },
            },
          ],
          series: [
            {
              name: 'A receber',
              type: 'bar',
              tooltip: {
                valueFormatter: value => {
                  return `R$ ${value as number}`;
                },
              },
              data: localListReceitas,
            },
            {
              name: 'A pagar',
              type: 'bar',
              tooltip: {
                valueFormatter: value => {
                  return `R$ ${value as number}`;
                },
              },
              data: localListDespesas,
            },
            {
              name: 'Saldo',
              type: 'line',
              yAxisIndex: 1,
              tooltip: {
                valueFormatter: value => {
                  return `R$ ${value as number}`;
                },
              },
              data: localListSaldos,
            },
          ],
        });

        setOpenLoading(false);
      } catch (error) {
        setOpenLoading(false);
      }
    },
    [user, defineReceivable],
  );

  const getValues = useCallback(async () => {
    await getReceivable('DATA_VENCIMENTO', 1);
    await getDailyCashFlow(emptyFilterFluxoCaixa);
  }, [getReceivable, getDailyCashFlow]);

  useEffect(() => {
    getValues();
  }, [getValues]);

  const onCloseFilter = useCallback(() => {
    setOpenFilter(false);
  }, []);

  const onConfirmFilterCashFlow = useCallback(
    async (inFilter: IFilterFluxoCaixa) => {
      await getDailyCashFlow(inFilter);
    },
    [getDailyCashFlow],
  );

  const onFilter = useCallback(
    (inFilter: IFilter) => {
      setOpenFilter(false);
      setFilterFieldPeriod(inFilter.fieldPeriod);
      getReceivable(inFilter.fieldDate, inFilter.fieldPeriod);

      setTextFiltro(
        `   Filtrado por: Data ${inFilter.fieldDate === 'DATA' ? 'competência' : 'vencimento'}. Período: ${getLabel(
          inFilter.fieldPeriod,
        )}`,
      );
    },
    [getReceivable, getLabel],
  );

  return (
    <div className="astContainer">
      <Header />
      <div className="astDashboardHeader">
        <IconButton id="gridrowheightdown" color="primary" onClick={() => setOpenFilter(true)}>
          <AiOutlineSearch />
        </IconButton>

        <span className="astTextFilter">{textFiltro}</span>
      </div>

      <Grid container className="astBigNumbers">
        <Grid item xs={12} md={3}>
          <CardBigNumbers
            title="A receber"
            value1={receivable.todayValue}
            value2={receivable.next14DaysValue}
            value3={receivable.next28DaysValue}
            value4={receivable.overdueAmountsValue}
            label1={getLabel(Number(filterFieldPeriod))}
            label2={getLabel(Number(filterFieldPeriod) + Number(1))}
            label3={getLabel(Number(filterFieldPeriod) + Number(2))}
            label4="Vencidos"
            kindCard={CardBigNumbersKindOptions.optionReceivable}
          />
        </Grid>

        <Grid item xs={12} md={3}>
          <CardBigNumbers
            title="A pagar"
            value1={toPay.todayValue}
            value2={toPay.next14DaysValue}
            value3={toPay.next28DaysValue}
            value4={toPay.overdueAmountsValue}
            label1={getLabel(Number(filterFieldPeriod))}
            label2={getLabel(Number(filterFieldPeriod) + Number(1))}
            label3={getLabel(Number(filterFieldPeriod) + Number(2))}
            label4="Vencidos"
            kindCard={CardBigNumbersKindOptions.optionPayable}
          />
        </Grid>

        <Grid item xs={12} md={3}>
          <CardBigNumbers
            title="Saldo"
            value1={receivableAndToPayDiference.todayValue}
            value2={receivableAndToPayDiference.next14DaysValue}
            value3={receivableAndToPayDiference.next28DaysValue}
            value4={receivableAndToPayDiference.next56DaysValue}
            label1={getLabel(Number(filterFieldPeriod))}
            label2={getLabel(Number(filterFieldPeriod) + Number(1))}
            label3={getLabel(Number(filterFieldPeriod) + Number(2))}
            label4={getLabel(Number(filterFieldPeriod) + Number(3))}
            kindCard={CardBigNumbersKindOptions.optionCashFlow}
          />
        </Grid>

        <Grid item xs={12} md={3}>
          <CardCheckingAccount listCheckingAccount={bankBalances} />
        </Grid>

        <Grid item xs={12} style={{ marginTop: '50px' }}>
          <ChartTeste option={valueOptionsChartFinMovimentos} />
        </Grid>

        <Grid item xs={12} md={6}>
          <ChartTeste option={optionsChartReceitasDespesas} />
        </Grid>

        <Grid item xs={12} md={3}>
          <ChartTeste option={option1} />
        </Grid>

        <Grid item xs={12} md={3}>
          <ChartTeste option={option2} />
        </Grid>

        <Grid item xs={12} style={{ marginTop: '50px' }}>
          <TableCashFlow listDailyCashFlow={dailyCashFlow} onConfirmFilter={onConfirmFilterCashFlow} />
        </Grid>
      </Grid>

      <ModalFilter open={openFilter} onConfirm={onFilter} onCancel={onCloseFilter} />

      <Backdrop className={cssLoading.backdrop} open={openLoading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
  );
};

export default Dashboard;
