export default interface ICfgPeriodoContabil {
  id: number;
  access_key: string;
  id_license: string;
  id_company: string;
  company: string;
  ano: number;
  situacao: string;
  origem: string;
  ind_tipo_movimento: string;
  id_usuario_responsavel: string;
  usuario_responsavel: string;
  data_ultima_atualizacao: Date | undefined;
  obs: string;
  inf1: string;
  inf2: string;
  inf3: string;
  inf4: string;
  inf5: string;
  acao: string;
  created_at: Date | undefined;
  updated_at: Date | undefined;
}

export const emptyCfgPeriodoContabil: ICfgPeriodoContabil = {
  id: 0,
  access_key: '',
  id_license: '',
  id_company: '',
  company: '',
  ano: 0,
  situacao: '',
  origem: '',
  ind_tipo_movimento: '1-Saldos contábeis',
  id_usuario_responsavel: '',
  usuario_responsavel: '',
  data_ultima_atualizacao: undefined,
  obs: '',
  inf1: '',
  inf2: '',
  inf3: '',
  inf4: '',
  inf5: '',
  acao: '',
  created_at: undefined,
  updated_at: undefined,
};
