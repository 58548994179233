import styled, { css } from 'styled-components';

interface IContainerProps {
  isActive: boolean;
}

interface IContainerFieldsProps {
  field: 'T200px' | 'T300px' | 'T400px' | 'T100' | 'id' | 'TID';
}

export const Container = styled.div`
  > header {
    height: 100px;
    background: #28262e;

    display: flex;
    align-items: center;

    div {
      width: 100%;
      max-width: 20px;
      margin: 40px;

      svg {
        color: #999591;
        width: 24px;
        height: 24px;
      }
    }
  }
`;

export const Content = styled.div`
  display: flex;
  max-width: 3000px;
  align-items: center;
  justify-content: center;
  margin: 10px auto;
`;

export const Licenses = styled.div`
  width: 100%;
  border: solid 1px #006699;
  padding: 16px 16px 30px 16px;
`;

export const Tab = styled.div`
  overflow: hidden;
  border: 1px solid #fff;
  background-color: #fff;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-areas: 'tabbuttoms tabtitle';

  svg {
    background-color: #fff;
    color: #006699;
    width: 22px;
    height: 22px;

    &:hover {
      background-color: #006699;
      opacity: 0.8;
      font-size: 18px;
      color: #fff;
      font-weight: bold;
    }
  }

  @media (max-width: 1020px) {
    grid-template-columns: 1fr;
    grid-template-areas:
      'tabtitle'
      'tabbuttoms';
  }
`;

export const TabButton = styled.button<IContainerProps>`
  background-color: inherit;
  float: left;
  border: none;
  outline: none;
  cursor: pointer;
  padding: 10px 12px;
  transition: 0.3s;
  font-size: 16px;
  font-family: sans-serif;

  &:hover {
    background-color: #006699;
    opacity: 0.8;
    font-size: 18px;
    color: #fff;
    font-weight: bold;
  }

  &:active {
    background-color: #006699;
    opacity: 0.2;
  }

  ${props =>
    props.isActive &&
    css`
      background-color: #006699;
      color: #fff;
      font-weight: bold;
      font-size: 16px;
    `}
`;

export const TabContent = styled.div<IContainerProps>`
  /* Style the tab content */
  display: none;
  background-color: #fff;
  border: 1px solid #666360;
  border-radius: 2px;
  height: 100%;

  form {
    height: 100%;
  }

  ${props =>
    props.isActive &&
    css`
      display: block;
    `}
`;

export const TabContentForm = styled.div<IContainerProps>`
  /* Style the tab content */
  display: none;
  background-color: #fff;

  ${props =>
    props.isActive &&
    css`
      display: block;
    `}
`;

export const FormFields = styled.div`
  margin-left: 20px;
  margin-right: 20px;
  width: 800px;

  span {
    margin: 10px 0;
    text-align: left;
  }

  @media (max-width: 810px) {
    width: 600px;
  }

  @media (max-width: 720px) {
    width: 450px;
  }
`;

export const FormButtons = styled.div`
  width: 450px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  button {
    margin: 0 10px 10px 0;
  }
`;

export const FormButtonDelete = styled.div`
  cursor: pointer;
  width: 80px;

  svg {
    color: #dc143c;
    width: 55px;
    height: 55px;
  }
`;

export const ContainerFieldCheckbox = styled.div<IContainerFieldsProps>`
  margin-top: 6px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;


  ${props =>
    props.field &&
    props.field === 'T200px' &&
    css`
      width: 200px;
    `}

  ${props =>
    props.field &&
    props.field === 'T300px' &&
    css`
      width: 300px;
    `}

  ${props =>
    props.field &&
    props.field === 'T400px' &&
    css`
      width: 400px;
    `}


  ${props =>
    props.field &&
    props.field === 'T100' &&
    css`
      width: 100%;
    `}

  ${props =>
    props.field &&
    props.field === 'id' &&
    css`
      height: 0px;
      width: 0px;
    `}

   ${props =>
     props.field &&
     props.field === 'TID' &&
     css`
       height: 0px;
       width: 0px;
     `}

  &.field2 {
    margin-left: 40px;
  }

  &.desabilitado {
    color: gray;

    input {
      color: gray;
    }
  }


  @media (max-width: 720px) {
    &.field2 {
      margin-left: 0px;
    }
  }
`;

export const ContainerFieldCnpj = styled.div`
  display: flex;
  flex-direction: row;

  @media (max-width: 720px) {
    flex-direction: column;
  }
`;

export const ContainerFields = styled.div<IContainerFieldsProps>`
  padding: 10px 0px 10px 0px;

  ${props =>
    props.field &&
    props.field === 'T200px' &&
    css`
      width: 200px;
    `}

  ${props =>
    props.field &&
    props.field === 'T300px' &&
    css`
      width: 300px;
    `}

  ${props =>
    props.field &&
    props.field === 'T100' &&
    css`
      width: 100%;
    `}

  ${props =>
    props.field &&
    props.field === 'id' &&
    css`
      height: 0px;
      width: 0px;
    `}


  ${props =>
    props.field &&
    props.field === 'TID' &&
    css`
      padding: 0px 0px 30px 0px;
      height: 0px;
      width: 0px;
    `}

  &.field2 {
    margin-left: 60px;
  }


  &.desabilitado {
    color: gray;

    input {
      color: gray;
    }
  }

  @media (max-width: 720px) {
    &.field2 {
      margin-left: 0px;
    }
  }
`;

export const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const FormNavigate = styled.div`
  width: 800px;
  margin-top: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  align-content: flex-start;

  border-bottom: 2px solid #006699;

  button {
    margin: 0 10px 10px 0;
  }

  @media (max-width: 810px) {
    width: 600px;
  }

  @media (max-width: 720px) {
    width: 450px;
    justify-content: space-between;
  }
`;

export const GradeButtons = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

export const ContainerChip = styled.div`
  margin-right: 8px;
`;

export const TabTitulo = styled.div`
  grid-area: tabtitle;
  width: 100%;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;

  span {
    margin-top: 10px;
    width: 100%;
    height: 100%;
    color: #006699;
    font-weight: bold;
    font-family: sans-serif;
    text-align: left;
  }

  @media (max-width: 1020px) {
    span {
      text-align: center;
    }
  }
`;

export const TabButtonArea = styled.div`
  grid-area: tabbuttoms;
  display: flex;
  flex-direction: row;
  align-items: center;
  align-content: center;
  height: 100%;
`;

export const TabButtonGridRowHeight = styled.button`
  background-color: inherit;
  float: left;
  border: none;
  outline: none;
  cursor: pointer;
  padding: 4px 4px;
  transition: 0.3s;
  font-size: 16px;
  font-family: sans-serif;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: 1px;

  svg {
    color: #006699;
  }

  &:hover {
    background-color: #006699;
    opacity: 0.8;
    font-size: 18px;
    color: #fff;
    font-weight: bold;

    svg {
      background-color: #006699;
      opacity: 0.8;
      color: #fff;
    }
  }
`;

export const TabButtonNovo = styled.button`
  background-color: inherit;
  float: left;
  border: none;
  outline: none;
  cursor: pointer;
  padding: 10px 12px;
  transition: 0.3s;
  font-size: 16px;
  font-family: sans-serif;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: 10px;

  svg {
    margin-right: 8px;
    background-color: #fff;
    color: #7ec850;
  }

  &:hover {
    background-color: #7ec850;
    opacity: 0.8;
    font-size: 18px;
    color: #fff;
    font-weight: bold;

    svg {
      background-color: #7ec850;
      opacity: 0.8;
      color: #fff;
    }
  }
`;

export const TabButtonDelete = styled.button`
  background-color: inherit;
  float: left;
  border: none;
  outline: none;
  cursor: pointer;
  padding: 10px 12px;
  transition: 0.3s;
  font-size: 16px;
  font-family: sans-serif;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: 10px;

  svg {
    margin-right: 8px;
    background-color: #fff;
    color: #cd001a;
  }

  &:hover {
    background-color: #cd001a;
    opacity: 0.8;
    font-size: 18px;
    color: #fff;
    font-weight: bold;

    svg {
      background-color: #cd001a;
      opacity: 0.8;
      color: #fff;
    }
  }
`;

export const TabButtonWhatsApp = styled.a`
  background-color: inherit;
  float: left;
  border: none;
  outline: none;
  cursor: pointer;
  padding: 10px 12px;
  transition: 0.3s;
  font-size: 16px;
  font-family: sans-serif;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: 10px;

  svg {
    margin-right: 8px;
    background-color: #fff;
    color: #7ec850;
  }

  &:hover {
    background-color: #7ec850;
    opacity: 0.8;
    font-size: 18px;
    color: #fff;
    font-weight: bold;

    svg {
      background-color: #7ec850;
      opacity: 0.8;
      color: #fff;
    }
  }
`;

export const SpanLicencas = styled.span`
  margin-left: 10px;
  font-size: 10px;
`;

export const SpanLicencasNumber = styled.span`
  font-size: 12px;
  font-weight: bold;
  margin-left: 4px;
  margin-right: 10px;
`;
