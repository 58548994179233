import styled, { css } from 'styled-components';
import { shade } from 'polished';

interface IContainerProps {
  somenteImagem?: boolean;
  textoEhmagem?: boolean;
  setColor?: 'red' | 'green' | 'defaul';
}

export const Container = styled.button<IContainerProps>`
  background: #006699;
  height: 56px;
  border-radius: 6px;
  border: 0;
  padding: 0 16px;
  color: #fff;
  width: 100%;
  font-weight: 500;
  margin-top: 16px;
  transition: background-color 0.2s;

  svg {
    margin-right: 16px;
  }

  ${props =>
    props.somenteImagem &&
    css`
      height: 40px;
      width: 46px;
      padding: 10px 10px;

      svg {
        margin-right: 0px;
      }
    `}

  ${props =>
    props.textoEhmagem &&
    css`
      height: 40px;
      width: 130px;
      padding: 10px 10px;
      display: flex;
      align-items: center;

      svg {
        margin-right: 16px;
      }
    `}

  &:hover {
    background: ${shade(0.2, '#006699')};
  }

  ${props =>
    props.setColor &&
    props.setColor === 'red' &&
    css`
      background: #ff6347;

      &:hover {
        background: ${shade(0.2, '#FF6347')};
      }
    `}
`;
