import styled from 'styled-components';

export const HeaderMenu = styled.div`
  display: flex;
  align-items: center;
  background-color: #f5f5f5;

  > a {
    img {
      height: 32px;
      margin-left: 16px;
    }
  }

  > span {
    margin-left: 32px;
  }
`;

export const Informacao = styled.div`
  background-color: #f5f5f5;
  width: 100%;
  display: flex;
  padding-top: 6px;
  padding-bottom: 10px;
`;

export const LicenseInfo = styled.span`
  margin-left: 32px;
  margin-right: 4px;
  width: 100%;
  background-color: #f5f5f5;
  font-size: 10px;
  color: #006699;
`;

export const CompanyInfo = styled.span`
  margin-right: 32px;
  margin-left: 4px;
  width: 100%;
  background-color: #f5f5f5;
  font-size: 10px;
  color: #006699;
  text-align: right;
`;

export const Navbar = styled.div`
  overflow: hidden;
  background-color: #f5f5f5;
  width: 100%;
`;

export const ContainerDropdown = styled.div`
  float: right;
  overflow: hidden;
  font-size: 16px;
  border: none;
  outline: none;
  color: white;
  background-color: inherit;
  font-family: inherit;
  margin: 0;

  > button {
    font-size: 16px;
    border: none;
    outline: none;
    color: white;
    padding: 10px 12px;
    background-color: inherit;
    font-family: inherit;
    margin: 0;

    svg {
      color: #006699;
    }

    &:hover {
      background-color: #ddd;
    }
  }

  &:hover {
    background-color: #ddd;
  }
`;

export const DropdownContent = styled.div`
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;

  ${ContainerDropdown}:hover & {
    display: block;
  }
`;

export const DropdownContentUserSettings = styled.div`
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  right: 0;
  margin-right: 48px;

  ${ContainerDropdown}:hover & {
    display: block;
  }
`;

export const DropdownContentUser = styled.div`
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  right: 0;
  margin-right: 95px;

  ${ContainerDropdown}:hover & {
    display: block;
  }
`;

export const ButtonItem = styled.button`
  width: 100%;
  float: none;
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  text-align: left;
  border: none;
  outline: none;
  display: flex;
  align-items: center;
  background-color: #fff;

  a {
    float: none;
    color: black;
    text-decoration: none;
    text-align: left;
  }

  svg {
    margin-right: 8px;
    color: #006699;
  }

  &:hover {
    background-color: #ddd;
  }
`;
