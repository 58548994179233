export default interface IFinMovimentosBigNumbersDTO {
  todayValue: number;
  next14DaysValue: number;
  next28DaysValue: number;
  next56DaysValue: number;
  overdueAmountsValue: number;
}

export interface IBankBalancesDTO {
  label: string;
  value: number;
  descricao_conta: string;
  banco: string;
  descricao_banco: string;
  agencia: string;
  conta_corrente: string;
}

export const emptyFinMovimentosBigNumbersDTO: IFinMovimentosBigNumbersDTO = {
  todayValue: 0,
  next14DaysValue: 0,
  next28DaysValue: 0,
  next56DaysValue: 0,
  overdueAmountsValue: 0,
};

export const emptyIBankBalancesDTO: IBankBalancesDTO = {
  label: '',
  value: 0,
  descricao_conta: '',
  banco: '',
  descricao_banco: '',
  agencia: '',
  conta_corrente: '',
};

export interface IDailyCashFlowDTO {
  id: string;
  id_conta_corrente: string;
  id_conta_corrente_transferencia: string;
  tipo: string;
  valor_original: number;
  valor_quitacao: number;
  situacao: string;
  data: Date;
  data_vencimento: Date;
  data_quitacao: Date;
  descricao_movimento: string;
}
