import { GridColDef } from '@material-ui/data-grid';

export const ColumnCnpj: GridColDef = {
  field: 'cnpj',
  headerName: 'CNPJ',
  width: 150,
};

export const ColumnDescricao: GridColDef = {
  field: 'descricao',
  headerName: 'Razão social',
  width: 400,
};

export const ColumnEmail: GridColDef = {
  field: 'email_admin_user',
  headerName: 'E-mail administrador',
  width: 400,
};

export const ColumnQuantidadeCnpj: GridColDef = {
  field: 'quantidade_cnpj',
  headerName: 'Quantidade CNPJ',
  width: 200,
  type: 'number',
};

export const ColumnCnpjCadastrados: GridColDef = {
  field: 'quantidadeCnpjUtilizada',
  headerName: 'CNPJ cadastrados',
  width: 200,
  type: 'number',
};

export const ColumnObservacao: GridColDef = {
  field: 'observacao',
  headerName: 'Observação',
  width: 400,
};

export const ColumnBloqueado: GridColDef = {
  field: 'blocked',
  headerName: 'Bloqueado',
  width: 150,
  type: 'boolean',
};

export const ColumnRLSPowerBI: GridColDef = {
  field: 'rls_power_bi',
  headerName: 'RLS Power BI',
  width: 200,
  type: 'boolean',
};

export const ColumnMensagemCliente: GridColDef = {
  field: 'mensagem_cliente',
  headerName: 'Mensagem Cliente',
  width: 400,
};

export const ColumnToken: GridColDef = {
  field: 'token',
  headerName: 'Token',
  width: 400,
};

export const ColumnURLPowerBIAccountingAnalysis: GridColDef = {
  field: 'url_powerbi',
  headerName: 'URL Power BI - Accounting Analysis',
  width: 2000,
};

export const ColumnURLPowerBIFinancialaccounts: GridColDef = {
  field: 'url_powerbi_financial_accounts',
  headerName: 'URL Power BI - Financial Accounts',
  width: 2000,
};

export const ColumnURLPowerBIHumanResources: GridColDef = {
  field: 'url_powerbi_human_resources',
  headerName: 'URL Power BI - Human Resources',
  width: 2000,
};

export const ColumnURLPowerBICostAnalysis: GridColDef = {
  field: 'url_powerbi_cost_analysis',
  headerName: 'URL Power BI - Cost Analysis',
  width: 2000,
};

export const ColumnNroSerie: GridColDef = {
  field: 'nro_serie',
  headerName: 'Número série',
  width: 200,
};

export const ColumnTipoLicenca: GridColDef = {
  field: 'tipo_licenca',
  headerName: 'Tipo licença',
  width: 250,
};

export const ColumnIdLicenciadoAgrupador: GridColDef = {
  field: 'id_licenciado_agrupador',
  headerName: 'Id licenciado agrupador',
  width: 400,
};

export const ColumnId: GridColDef = {
  field: 'id',
  headerName: 'License',
  width: 400,
  editable: false,
  filterable: false,
  hideSortIcons: true,
  sortable: false,
  disableColumnMenu: true,
};
