import { GridColDef } from '@material-ui/data-grid';

export const ColumnTotalizador: GridColDef = {
  field: 'totalizador',
  headerName: 'Totalizador',
  width: 300,
};

export const ColumnTotalizadorBC: GridColDef = {
  field: 'totalizador_bc',
  headerName: 'Totalizador de Base de Cálculo',
  width: 300,
};

export const ColumnAno: GridColDef = {
  field: 'ano',
  headerName: 'Ano',
  width: 120,
  type: 'number',
};

export const ColumnTipoValor: GridColDef = {
  field: 'tipo_valor',
  headerName: 'Tipo',
  width: 200,
};

export const ColumnJaneiro: GridColDef = {
  field: 'janeiro',
  headerName: 'Janeiro',
  width: 150,
  type: 'number',
};

export const ColumnFevereiro: GridColDef = {
  field: 'fevereiro',
  headerName: 'Fevereiro',
  width: 150,
  type: 'number',
};

export const ColumnMarco: GridColDef = {
  field: 'marco',
  headerName: 'Março',
  width: 150,
  type: 'number',
};

export const ColumnAbril: GridColDef = {
  field: 'abril',
  headerName: 'Abril',
  width: 150,
  type: 'number',
};

export const ColumnMaio: GridColDef = {
  field: 'maio',
  headerName: 'Maio',
  width: 150,
  type: 'number',
};

export const ColumnJunho: GridColDef = {
  field: 'junho',
  headerName: 'Junho',
  width: 150,
  type: 'number',
};

export const ColumnJulho: GridColDef = {
  field: 'julho',
  headerName: 'Julho',
  width: 150,
  type: 'number',
};

export const ColumnAgosto: GridColDef = {
  field: 'agosto',
  headerName: 'Agosto',
  width: 150,
  type: 'number',
};

export const ColumnSetembro: GridColDef = {
  field: 'setembro',
  headerName: 'Setembro',
  width: 150,
  type: 'number',
};

export const ColumnOutubro: GridColDef = {
  field: 'outubro',
  headerName: 'Outubro',
  width: 150,
  type: 'number',
};

export const ColumnNovembro: GridColDef = {
  field: 'novembro',
  headerName: 'Novembro',
  width: 150,
  type: 'number',
};

export const ColumnDezembro: GridColDef = {
  field: 'dezembro',
  headerName: 'Dezembro',
  width: 150,
  type: 'number',
};

export const ColumnObservacao: GridColDef = {
  field: 'observacao',
  headerName: 'obs',
  width: 400,
};

export const ColumnTipo: GridColDef = {
  field: 'tipo',
  headerName: 'Indicador do Totalizador',
  width: 250,
};

export const ColumnIdTotalizador: GridColDef = {
  field: 'id_dem_ger',
  headerName: '# - ID Totalizador',
  hide: true,
  width: 220,
  editable: false,
  filterable: false,
  hideSortIcons: true,
  sortable: false,
  disableColumnMenu: true,
};

export const ColumnIdTotalizadorBC: GridColDef = {
  field: 'id_dem_ger_bc',
  headerName: '# - ID Totalizador de Base de Cálculo',
  hide: true,
  width: 320,
  editable: false,
  filterable: false,
  hideSortIcons: true,
  sortable: false,
  disableColumnMenu: true,
};

export const ColumnId: GridColDef = {
  headerName: '#',
  field: 'id',
  hide: true,
  width: 400,
  editable: false,
  filterable: false,
  hideSortIcons: true,
  sortable: false,
  disableColumnMenu: true,
};
