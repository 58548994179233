import { GridColDef } from '@material-ui/data-grid';

export const GridColumnCodTot: GridColDef = {
  headerName: 'Código',
  field: 'cod_tot',
  width: 150,
};

export const GridColumnNomeTot: GridColDef = {
  headerName: 'Descrição',
  field: 'nome_tot',
  width: 400,
};

export const GridColumnClassifTot: GridColDef = {
  headerName: 'Classificação',
  field: 'classif_tot',
  width: 300,
};

export const GridColumnCodNat: GridColDef = {
  headerName: 'Grupo Contas',
  field: 'cod_nat',
  width: 400,
};

export const GridColumnId: GridColDef = {
  headerName: '#',
  field: 'id',
  hide: true,
  width: 400,
  editable: false,
  filterable: false,
  hideSortIcons: true,
  sortable: false,
  disableColumnMenu: true,
};
