import React, { useRef, useState, useEffect, useCallback } from 'react';
import * as Yup from 'yup';

import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';

import { DataGrid, GridColDef, GridCellParams, GridRowId, ptBR } from '@material-ui/data-grid';
import { createMuiTheme, ThemeProvider, makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import ButtonDialog from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import { FiCopy } from 'react-icons/fi';
import { MdDelete, MdEdit, MdClear, MdSkipNext, MdSkipPrevious, MdContentCopy } from 'react-icons/md';
import {
  AiOutlinePlus,
  AiOutlineSave,
  AiOutlineSearch,
  AiFillFastBackward,
  AiFillFastForward,
  AiOutlineCalculator,
  AiOutlineCaretDown,
  AiOutlineCaretUp,
} from 'react-icons/ai';
import { GoPlus } from 'react-icons/go';
import { RiDeleteBinLine } from 'react-icons/ri';

import InputComum from '../../components/InputComum';
import Select from '../../components/Select';
import ModalUploadFile from '../../components/ModalUploadFile';
import LookupDemGer from '../../components/LookupDemGer';
import Header from '../../components/Header';
import FormModalDialogConfirm from '../../components/FormModalDialogConfirm';

import api from '../../services/api';
import { useAuth } from '../../hooks/Auth';
import { useToast } from '../../hooks/Toast';
import { useLookup } from '../../hooks/Lookup/index';
import IDemGerLookup from '../../hooks/Lookup/IDemGerLookup';

import getListUpdate from '../../utils/getListUpdate';
import getValidationErrors from '../../utils/getValidationErrors';
import { getCurrency } from '../../utils/formatValues';
import { getPathGrid } from '../../utils/storagePath';

import {
  ColumnAno,
  ColumnJaneiro,
  ColumnFevereiro,
  ColumnMarco,
  ColumnAbril,
  ColumnMaio,
  ColumnJunho,
  ColumnJulho,
  ColumnAgosto,
  ColumnSetembro,
  ColumnOutubro,
  ColumnNovembro,
  ColumnDezembro,
  ColumnObservacao,
  ColumnTotalizador,
  ColumnTotalizadorBC,
  ColumnTipo,
  ColumnTipoValor,
  ColumnIdTotalizador,
  ColumnIdTotalizadorBC,
  ColumnId,
} from './GridColumns';

import IDemGerOrcadoTot, { emptyDemGerOrcadoTotTot } from './IDemGerOrcadoTot';
// import ICalcularOrcamento from './ICalcularOrcamento';

import {
  Container,
  ContainerFields,
  Tab,
  TabButton,
  TabContent,
  FormFields,
  ContainerAllMonthPrice,
  ContainerQuadro,
  FormContainer,
  FormNavigate,
  SpanFiltro,
  TabTitulo,
  TabButtonArea,
  SpanTitleCalcular,
  SpanFiltroCalcular,
  FormContainerValue,
  FormContainerValueMes,
  ContainerFieldCheckboxFilter,
  ContainerFieldTextFilter,
  Separator,
  TabButtonNovo,
  TabButtonDelete,
  TabButtonGridRowHeight,
} from './styles';

const useStylesLoading = makeStyles((in_theme: Theme) =>
  createStyles({
    backdrop: {
      zIndex: in_theme.zIndex.drawer + 1700,
      color: '#fff',
    },
  }),
);

const theme = createMuiTheme(
  {
    palette: {
      primary: { main: '#006699' },
    },
  },
  ptBR,
);

const options_tipo = [
  { value: '1-Previsto', label: '1-Previsto' },
  { value: '2-Estimado', label: '2-Estimado' },
  { value: '3-Calculado AV', label: '3-Calculado AV' },
  { value: '4-Calculado AH', label: '4-Calculado AH' },
  {
    value: '5-Calculado Rateio Totalizador',
    labe: '5-Calculado Rateio Totalizador',
  },
];

const options_tipo_valor = [
  { value: '0-Valor', label: '0-Valor' },
  { value: '1-Percentual', label: '1-Percentual' },
];

const titulo = 'O R Ç A M E N T O S   P O R   T O T A L I Z A D O R';

const DemGerOrcadoTot: React.FC = () => {
  const { user } = useAuth();
  const { addToast } = useToast();

  const formRef = useRef<FormHandles>(null);
  const [demGerOrcadoTot, setDemGerOrcadoTot] = useState<IDemGerOrcadoTot>();
  const [listDemGerOrcadoTot, setListDemGerOrcadoTot] = useState<IDemGerOrcadoTot[]>([]);
  const [hiddenPesquisar, setHiddenPesquisar] = useState<boolean>(true);
  const [hiddenCadastrar, setHiddenCadastrar] = useState<boolean>(true);

  const [demGerOrcadoTotIndex, setDemGerOrcadoTotIndex] = useState<number>(0);
  const [rowToEdit, setRowToEdit] = useState(null);
  const [idDeleteDemGerOrcadoTot, setIdDeleteDemGerOrcadoTot] = useState<string>('');
  const [idDemGerPesquisar, setIdDemGerPesquisar] = useState<string>('');
  const [idDemGerPesquisarBC, setIdDemGerPesquisarBC] = useState<string | undefined>('');
  const [codTotBase, setCodTotBase] = useState<string>('');
  const [hiddenDemGerPesquisarBC, setHiddenDemGerPesquisarBC] = useState<boolean>(true);
  const [selectedIds, setSelectedIds] = useState<GridRowId[]>([]);
  const [openDeleteSingle, setOpenDeleteSingle] = useState(false);
  const [openDeleteMultiple, setOpenDeleteMultiple] = useState(false);
  const [gridRowHeight, setGridRowHeight] = useState<number>(46);
  const [openFilterDemGer, setOpenFilterDemGer] = React.useState(true);
  const [openRecalcularOrcamento, setOpenRecalcularOrcamento] = React.useState(false);
  const [textFiltro, setTextFiltro] = useState<string>('');
  const [openUploadFile, setOpenUploadFile] = useState<boolean>(false);
  const { getListDemGer, updateListDemGer } = useLookup();
  const [openLoading, setOpenLoading] = React.useState(false);
  const cssLoading = useStylesLoading();

  const handleGridRowHeightUp = useCallback(async () => {
    const heightValue: number = gridRowHeight + 1;
    setGridRowHeight(heightValue);
    localStorage.setItem(getPathGrid(), heightValue.toString());
  }, [gridRowHeight]);

  const handleGridRowHeightDown = useCallback(async () => {
    const heightValue: number = gridRowHeight - 1;
    setGridRowHeight(heightValue);
    localStorage.setItem(getPathGrid(), heightValue.toString());
  }, [gridRowHeight]);

  const licencaInvalida = useCallback((): boolean => {
    if (!user.license || user.license.id === '') {
      addToast({
        type: 'error',
        title: 'Licença inválida',
        description: 'Usuário não está vinculado a nenhuma licença',
      });

      return true;
    }

    return false;
  }, [addToast, user]);

  const defineDemGerOrcadoTot = useCallback(async (data: IDemGerOrcadoTot) => {
    setIdDemGerPesquisar(data.id_dem_ger);
    setIdDemGerPesquisarBC(data.id_dem_ger_bc);
    setHiddenDemGerPesquisarBC(data.tipo_valor === '0-Valor');
    setCodTotBase(data.cod_tot_base);

    setDemGerOrcadoTot(data);
    formRef.current?.setData(data);
  }, []);

  const onIdDemGerLookup = useCallback((): string => {
    return idDemGerPesquisar;
  }, [idDemGerPesquisar]);

  const onIdDemGerBCLookup = useCallback((): string => {
    return idDemGerPesquisarBC === undefined ? '' : idDemGerPesquisarBC;
  }, [idDemGerPesquisarBC]);

  const onClearIdDemGerLookup = useCallback((): void => {
    setIdDemGerPesquisar('');
  }, []);

  const onClearIdDemGerBCLookup = useCallback((): void => {
    setIdDemGerPesquisarBC('');
  }, []);

  const onCodigoTotalizadorBase = useCallback((): string => {
    return codTotBase;
  }, [codTotBase]);

  const onNewRegister = useCallback(async () => {
    defineDemGerOrcadoTot(emptyDemGerOrcadoTotTot);
  }, [defineDemGerOrcadoTot]);

  const OnGridSetRegisterToEdit = useCallback(
    async (params: GridCellParams) => {
      const localDemGerOrcadoTot = params.row as IDemGerOrcadoTot;

      setDemGerOrcadoTotIndex(listDemGerOrcadoTot.indexOf(localDemGerOrcadoTot));
      setHiddenCadastrar(false);
      setHiddenPesquisar(true);
      setRowToEdit(null);
      defineDemGerOrcadoTot(localDemGerOrcadoTot);
    },
    [listDemGerOrcadoTot, defineDemGerOrcadoTot],
  );

  const OnSetRegisterToAdd = useCallback(async () => {
    onNewRegister();
    setHiddenCadastrar(false);
    setHiddenPesquisar(true);
  }, [onNewRegister]);

  const OnGridRowDuplicate = useCallback(
    async (baseDemGerOrcadoTot: IDemGerOrcadoTot | undefined) => {
      if (baseDemGerOrcadoTot !== undefined) {
        const newDemGerOrcadoTot: IDemGerOrcadoTot = {
          acao: baseDemGerOrcadoTot.acao,
          id: '',
          id_license: baseDemGerOrcadoTot.id_license,
          cnpj_estab_0000: baseDemGerOrcadoTot.cnpj_estab_0000,
          ano: baseDemGerOrcadoTot.ano,
          janeiro: baseDemGerOrcadoTot.janeiro,
          fevereiro: baseDemGerOrcadoTot.fevereiro,
          marco: baseDemGerOrcadoTot.marco,
          abril: baseDemGerOrcadoTot.abril,
          maio: baseDemGerOrcadoTot.maio,
          junho: baseDemGerOrcadoTot.junho,
          julho: baseDemGerOrcadoTot.julho,
          agosto: baseDemGerOrcadoTot.agosto,
          setembro: baseDemGerOrcadoTot.setembro,
          outubro: baseDemGerOrcadoTot.outubro,
          novembro: baseDemGerOrcadoTot.novembro,
          dezembro: baseDemGerOrcadoTot.dezembro,
          obs: baseDemGerOrcadoTot.obs,
          tipo: baseDemGerOrcadoTot.tipo,
          id_dem_ger: baseDemGerOrcadoTot.id_dem_ger,
          totalizador: baseDemGerOrcadoTot.totalizador,
          totalizador_bc: baseDemGerOrcadoTot.totalizador_bc,
          tipo_valor: baseDemGerOrcadoTot.tipo_valor,
          id_dem_ger_bc: baseDemGerOrcadoTot.id_dem_ger_bc,
          cod_tot_base: baseDemGerOrcadoTot.cod_tot_base,
        };

        defineDemGerOrcadoTot(newDemGerOrcadoTot);
        setHiddenCadastrar(false);
        setHiddenPesquisar(true);
      }
    },
    [defineDemGerOrcadoTot],
  );

  const OnConfirmationDeleteSingle = useCallback(
    async (id: string) => {
      if (licencaInvalida()) return;

      if (id === '') return;

      setIdDeleteDemGerOrcadoTot(id);
      setOpenDeleteSingle(true);
    },
    [licencaInvalida],
  );

  const handleOnConfirmDemGerSearchRegister = useCallback((list: IDemGerLookup[]): void => {
    // let tempValue = '';
    if (!list.length) return;
    const demGerLookupData: IDemGerLookup = list[0];

    setCodTotBase(demGerLookupData.cod_tot);
    const fieldCodTotDemGer = document.getElementById('345-0m-77-53m');
    if (fieldCodTotDemGer) {
      // tempValue = (fieldCodTotDemGer as HTMLSelectElement).value;
      (fieldCodTotDemGer as HTMLSelectElement).value = demGerLookupData.cod_tot;
    }

    const fieldIdDemGer = document.getElementById('saas764d2z2m2');
    if (fieldIdDemGer) {
      (fieldIdDemGer as HTMLSelectElement).value = demGerLookupData.id;
    }

    const fieldDemGerNome = document.getElementById('777u78rw239XA');
    if (fieldDemGerNome) {
      (fieldDemGerNome as HTMLSelectElement).value = `${demGerLookupData.cod_tot.trim()}-${demGerLookupData.nome_tot.trim()}`;
    }
  }, []);

  const handleOnConfirmDemGerBCSearchRegister = useCallback((list: IDemGerLookup[]): void => {
    if (!list.length) return;
    const demGerLookupData: IDemGerLookup = list[0];
    const fieldIdDemGer = document.getElementById('s654asd56asd2m2');
    if (fieldIdDemGer) {
      (fieldIdDemGer as HTMLSelectElement).value = demGerLookupData.id;
    }

    const fieldDemGerNome = document.getElementById('asd-32434f-d');
    if (fieldDemGerNome) {
      (fieldDemGerNome as HTMLSelectElement).value = `${demGerLookupData.cod_tot.trim()}-${demGerLookupData.nome_tot.trim()}`;
    }
  }, []);

  const handleOnConfirmDemGerSearch = useCallback((list: IDemGerLookup[]): void => {
    //
  }, []);

  const colunaAcao: GridColDef = {
    field: 'acao',
    headerName: 'Ação',
    editable: false,
    filterable: false,
    hideSortIcons: true,
    sortable: false,
    disableColumnMenu: true,
    width: 160,
    renderCell: (params: GridCellParams) => (
      <strong>
        <Tooltip title="Editar Registro" placement="top">
          <IconButton color="primary" aria-label="Editar" onClick={() => OnGridSetRegisterToEdit(params)}>
            <MdEdit />
          </IconButton>
        </Tooltip>

        <Tooltip title="Duplicar Registro" placement="top">
          <IconButton
            color="primary"
            aria-label="Duplicar"
            onClick={() => OnGridRowDuplicate(params.row as IDemGerOrcadoTot)}
          >
            <FiCopy />
          </IconButton>
        </Tooltip>

        <Tooltip title="Excluir Registro" placement="top">
          <IconButton
            color="primary"
            aria-label="Excluir"
            onClick={() => OnConfirmationDeleteSingle((params.row as IDemGerOrcadoTot).id)}
          >
            <MdDelete />
          </IconButton>
        </Tooltip>
      </strong>
    ),
  };

  const columns: GridColDef[] = [
    colunaAcao,
    ColumnAno,
    ColumnTipo,
    ColumnTotalizador,
    ColumnTipoValor,
    ColumnTotalizadorBC,
    ColumnJaneiro,
    ColumnFevereiro,
    ColumnMarco,
    ColumnAbril,
    ColumnMaio,
    ColumnJunho,
    ColumnJulho,
    ColumnAgosto,
    ColumnSetembro,
    ColumnOutubro,
    ColumnNovembro,
    ColumnDezembro,
    ColumnObservacao,
    ColumnIdTotalizador,
    ColumnIdTotalizadorBC,
    ColumnId,
  ];

  const handleCloseFilterDemGer = useCallback(() => {
    setOpenFilterDemGer(false);
  }, []);

  const handleCloseCalcularOrcamento = useCallback(() => {
    setOpenRecalcularOrcamento(false);
  }, []);

  const getDemGerOrcadoTot = useCallback(
    async (listDemGerIDs: string[]) => {
      if (licencaInvalida()) return;
      setOpenLoading(true);
      const response = await api.get<IDemGerOrcadoTot[]>(`/demgerorcadotot/cnpjanddemger`, {
        params: {
          license: user.license.id,
          id_dem_ger: listDemGerIDs.join(),
          cnpj: `'${user.empresa.cnpj}'`,
        },
      });

      setListDemGerOrcadoTot(response.data);
      setOpenLoading(false);
      if (rowToEdit !== null) {
        const findEditDemGerOrcadoTot = response.data.find(item => item.id === rowToEdit);

        if (findEditDemGerOrcadoTot) {
          defineDemGerOrcadoTot(findEditDemGerOrcadoTot);
          setDemGerOrcadoTotIndex(response.data.indexOf(findEditDemGerOrcadoTot));
          setHiddenCadastrar(false);
          setHiddenPesquisar(true);
          setRowToEdit(null);
        }
      }

      setHiddenPesquisar(false);
    },
    [user, licencaInvalida, rowToEdit, defineDemGerOrcadoTot],
  );

  const getValues = useCallback(async () => {
    // await getDemGer('');
  }, []);

  useEffect(() => {
    getValues();

    const rowHeight = localStorage.getItem(getPathGrid());
    if (rowHeight) {
      setGridRowHeight(parseInt(rowHeight, 10));
    }
  }, [getValues]);

  const onUploadFileClose = useCallback(async () => {
    setOpenLoading(true);

    await getDemGerOrcadoTot([`'${idDemGerPesquisar}'`]);

    setOpenLoading(false);
    setOpenUploadFile(false);
  }, [getDemGerOrcadoTot, idDemGerPesquisar]);

  const handleOnRowDeleteSingle = useCallback(async () => {
    setOpenLoading(true);
    await api.delete(`/demgerorcadotot/${idDeleteDemGerOrcadoTot}`);

    setListDemGerOrcadoTot(oldDemGerOrcadoTot =>
      oldDemGerOrcadoTot.filter(item => item.id !== idDeleteDemGerOrcadoTot),
    );

    defineDemGerOrcadoTot(emptyDemGerOrcadoTotTot);

    addToast({
      type: 'success',
      title: 'Removido',
      description: 'Registro excluído com sucesso!',
    });

    setOpenDeleteSingle(false);
    setOpenLoading(false);
  }, [idDeleteDemGerOrcadoTot, addToast, defineDemGerOrcadoTot]);

  const handleOnRowDeleteMultiple = useCallback(async () => {
    setOpenLoading(true);
    if (selectedIds.length > 0) {
      await Promise.all(
        selectedIds.map(itemId => {
          setListDemGerOrcadoTot(oldDemGerOrcadoTot => oldDemGerOrcadoTot.filter(item => item.id !== itemId));

          return api.delete(`/demgerorcadotot/${itemId}`);
        }),
      );

      defineDemGerOrcadoTot(emptyDemGerOrcadoTotTot);

      addToast({
        type: 'success',
        title: 'Removido',
        description: 'Registro excluído com sucesso!',
      });
    }

    setSelectedIds([]);
    setOpenDeleteMultiple(false);
    setOpenLoading(false);
  }, [addToast, defineDemGerOrcadoTot, selectedIds]);

  const handleClick = useCallback(
    (id: string): void => {
      if (id === 'filtro') {
        updateListDemGer([]);
        setOpenFilterDemGer(id === 'filtro');
        return;
      }

      setHiddenCadastrar(id === 'pesquisar');
      setHiddenPesquisar(id === 'cadastrar');
    },
    [updateListDemGer],
  );

  const handleOnFilter = useCallback(async () => {
    setOpenLoading(true);
    const localListDemGer = getListDemGer();
    const list = localListDemGer.map(itemDemGer => {
      return `'${itemDemGer.id}'`;
    });

    const listFilterText = localListDemGer.map(itemDemGer => {
      return `${itemDemGer.cod_tot.trim()}-${itemDemGer.nome_tot.trim()}`;
    });

    let text = listFilterText.join();
    if (text.length > 120) {
      text = `${text.substring(0, 120)}...`;
    }

    if (text.trim() === '') {
      text = 'Todos';
    }

    setTextFiltro(`   Filtrado por: ${text}`);

    await getDemGerOrcadoTot(list);

    setOpenFilterDemGer(false);
    setOpenLoading(false);
    handleClick('pesquisar');
  }, [getDemGerOrcadoTot, handleClick, getListDemGer]);

  const handleOnRecalcularOrcamento = useCallback(async () => {
    let calcularJaneiro = false;
    let calcularFevereiro = false;
    let calcularMarco = false;
    let calcularAbril = false;
    let calcularMaio = false;
    let calcularJunho = false;
    let calcularJulho = false;
    let calcularAgosto = false;
    let calcularSetembro = false;
    let calcularOutubro = false;
    let calcularNovembro = false;
    let calcularDezembro = false;

    const fieldCheckedJan = document.getElementById('jan');
    if (fieldCheckedJan) {
      calcularJaneiro = (fieldCheckedJan as HTMLInputElement).checked;
    }

    const fieldCheckedFev = document.getElementById('fev');
    if (fieldCheckedFev) {
      calcularFevereiro = (fieldCheckedFev as HTMLInputElement).checked;
    }

    const fieldCheckedMar = document.getElementById('mar');
    if (fieldCheckedMar) {
      calcularMarco = (fieldCheckedMar as HTMLInputElement).checked;
    }

    const fieldCheckedAbr = document.getElementById('abr');
    if (fieldCheckedAbr) {
      calcularAbril = (fieldCheckedAbr as HTMLInputElement).checked;
    }

    const fieldCheckedMaio = document.getElementById('mai');
    if (fieldCheckedMaio) {
      calcularMaio = (fieldCheckedMaio as HTMLInputElement).checked;
    }

    const fieldCheckedJun = document.getElementById('jun');
    if (fieldCheckedJun) {
      calcularJunho = (fieldCheckedJun as HTMLInputElement).checked;
    }

    const fieldCheckedJul = document.getElementById('jul');
    if (fieldCheckedJul) {
      calcularJulho = (fieldCheckedJul as HTMLInputElement).checked;
    }

    const fieldCheckedAgo = document.getElementById('ago');
    if (fieldCheckedAgo) {
      calcularAgosto = (fieldCheckedAgo as HTMLInputElement).checked;
    }

    const fieldCheckedSet = document.getElementById('set');
    if (fieldCheckedSet) {
      calcularSetembro = (fieldCheckedSet as HTMLInputElement).checked;
    }

    const fieldCheckedOut = document.getElementById('out');
    if (fieldCheckedOut) {
      calcularOutubro = (fieldCheckedOut as HTMLInputElement).checked;
    }

    const fieldCheckedNov = document.getElementById('nov');
    if (fieldCheckedNov) {
      calcularNovembro = (fieldCheckedNov as HTMLInputElement).checked;
    }

    const fieldCheckedDez = document.getElementById('dez');
    if (fieldCheckedDez) {
      calcularDezembro = (fieldCheckedDez as HTMLInputElement).checked;
    }

    let ano = 0;
    const fieldCheckedAno = document.getElementById('ano');
    if (fieldCheckedAno) {
      ano = parseInt((fieldCheckedAno as HTMLInputElement).value, 10);
    }

    if (ano < 1950) {
      addToast({
        type: 'error',
        title: 'Ano inválido',
        description: 'Por favor informe uma data válida',
      });

      return;
    }

    let manter_valores = true;
    const fieldCheckedSubst = document.getElementById('substituirregistros');
    if (fieldCheckedSubst) {
      manter_valores = !(fieldCheckedSubst as HTMLInputElement).checked;
    }

    const localListDemGer = getListDemGer();
    const list = localListDemGer.map(itemDemGer => {
      return `'${itemDemGer.id}'`;
    });

    const formData = {
      // ICalcularOrcamento
      id_license: user.license.id,
      ano,
      cnpj: user.empresa.cnpj,
      list_id_dem_ger: list.join(),
      manter_valores,
      janeiro: calcularJaneiro,
      fevereiro: calcularFevereiro,
      marco: calcularMarco,
      abril: calcularAbril,
      maio: calcularMaio,
      junho: calcularJunho,
      julho: calcularJulho,
      agosto: calcularAgosto,
      setembro: calcularSetembro,
      outubro: calcularOutubro,
      novembro: calcularNovembro,
      dezembro: calcularDezembro,
    };

    setOpenLoading(true);
    try {
      const response = await api.post<string[]>(`/demgerorcadotot/calc`, formData);

      addToast({
        type: 'info',
        title: 'Cálculo realizado com sucesso',
        description: response.data.length <= 0 ? 'Nenhum registro alterado' : response.data.join(),
      });
    } catch (error) {
      addToast({
        type: 'error',
        title: 'Erro',
        description: 'Ocorreu algum erro ao realizar o cálculo. Por favor entre em contato com o suporte.',
      });
    }

    setOpenLoading(false);

    setOpenRecalcularOrcamento(false);
  }, [user, addToast, getListDemGer]);

  const onNextRegister = useCallback(async () => {
    if (demGerOrcadoTotIndex !== undefined) {
      if (demGerOrcadoTotIndex < listDemGerOrcadoTot.length - 1) {
        const localIndex = demGerOrcadoTotIndex + 1;
        setDemGerOrcadoTotIndex(localIndex);
        const findDemGerOrcadoTot = listDemGerOrcadoTot[localIndex];
        if (findDemGerOrcadoTot) {
          defineDemGerOrcadoTot(findDemGerOrcadoTot);
        }
      }
    }
  }, [demGerOrcadoTotIndex, listDemGerOrcadoTot, defineDemGerOrcadoTot]);

  const onFirstRegister = useCallback(async () => {
    if (listDemGerOrcadoTot.length > 0) {
      setDemGerOrcadoTotIndex(0);
      const findDemGerOrcadoTot = listDemGerOrcadoTot[0];
      if (findDemGerOrcadoTot) {
        defineDemGerOrcadoTot(findDemGerOrcadoTot);
      }
    }
  }, [listDemGerOrcadoTot, defineDemGerOrcadoTot]);

  const onLastRegister = useCallback(async () => {
    if (listDemGerOrcadoTot.length > 0) {
      setDemGerOrcadoTotIndex(listDemGerOrcadoTot.length - 1);
      const findDemGerOrcadoTot = listDemGerOrcadoTot[listDemGerOrcadoTot.length - 1];
      if (findDemGerOrcadoTot) {
        defineDemGerOrcadoTot(findDemGerOrcadoTot);
      }
    }
  }, [listDemGerOrcadoTot, defineDemGerOrcadoTot]);

  const onPriorRegister = useCallback(async () => {
    if (demGerOrcadoTotIndex !== undefined) {
      if (demGerOrcadoTotIndex > 0) {
        const localIndex = demGerOrcadoTotIndex - 1;
        setDemGerOrcadoTotIndex(localIndex);
        const findDemGerOrcadoTot = listDemGerOrcadoTot[localIndex];
        if (findDemGerOrcadoTot) {
          defineDemGerOrcadoTot(findDemGerOrcadoTot);
        }
      }
    }
  }, [demGerOrcadoTotIndex, listDemGerOrcadoTot, defineDemGerOrcadoTot]);

  const onCancelRegister = useCallback(async () => {
    formRef.current?.setErrors({});

    if (demGerOrcadoTotIndex !== undefined) {
      const findDemGerOrcadoTot = listDemGerOrcadoTot[demGerOrcadoTotIndex];
      if (findDemGerOrcadoTot) {
        defineDemGerOrcadoTot(findDemGerOrcadoTot);
      }
    }

    onNewRegister();
  }, [demGerOrcadoTotIndex, listDemGerOrcadoTot, onNewRegister, defineDemGerOrcadoTot]);

  const handleSubmit = useCallback(
    async (data: IDemGerOrcadoTot, { reset }) => {
      if (licencaInvalida()) return;

      if (data.id_dem_ger === null || data.id_dem_ger === undefined || data.id_dem_ger.trim() === '') {
        addToast({
          type: 'error',
          title: 'Totalizador inválido',
          description: 'Por favor informe um totalizador!',
        });

        return;
      }

      formRef.current?.setErrors({});
      try {
        const schema = Yup.object().shape({
          id_dem_ger: Yup.string().required('Totalizador é obrigatório'),
          ano: Yup.number().moreThan(0, `Ano deve ser maior que 0`).required(`Ano é obrigatório`),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        setOpenLoading(true);
        const formData: IDemGerOrcadoTot = {
          acao: data.acao,
          id: data.id,
          id_license: user.license.id,
          cnpj_estab_0000: user.empresa.cnpj,
          id_dem_ger: data.id_dem_ger,
          ano: data.ano,
          janeiro: getCurrency(data.janeiro),
          fevereiro: getCurrency(data.fevereiro),
          marco: getCurrency(data.marco),
          abril: getCurrency(data.abril),
          maio: getCurrency(data.maio),
          junho: getCurrency(data.junho),
          julho: getCurrency(data.julho),
          agosto: getCurrency(data.agosto),
          setembro: getCurrency(data.setembro),
          outubro: getCurrency(data.outubro),
          novembro: getCurrency(data.novembro),
          dezembro: getCurrency(data.dezembro),
          tipo_valor: data.tipo_valor,
          obs: data.obs ? data.obs : '',
          tipo: data.tipo,
          totalizador: data.totalizador,
          id_dem_ger_bc: data.tipo_valor === '0-Valor' ? undefined : data.id_dem_ger_bc,
          totalizador_bc: data.tipo_valor === '0-Valor' ? '' : data.totalizador_bc,
          cod_tot_base: data.cod_tot_base,
        };

        if (formData.id === '') {
          setOpenLoading(true);
          const response = await api.post(`/demgerorcadotot`, formData);
          formData.id = response.data.id;

          setListDemGerOrcadoTot(oldLicenses => [...oldLicenses, formData]);
          setOpenLoading(false);
          addToast({
            type: 'success',
            title: 'Adicionado',
            description: 'Registro adicionado com sucesso!',
          });
        } else {
          setOpenLoading(true);
          await api.put(`/demgerorcadotot/${formData.id}`, formData);
          setListDemGerOrcadoTot(getListUpdate(listDemGerOrcadoTot, formData));
          setOpenLoading(false);
          addToast({
            type: 'success',
            title: 'Alterado',
            description: 'Registro alterado com sucesso!',
          });
        }

        updateListDemGer([]);
        setIdDemGerPesquisar('');
        setIdDemGerPesquisarBC('');
        reset();
        setOpenLoading(false);
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          formRef.current?.setErrors(errors);
        }

        setOpenLoading(false);
      }
    },
    [licencaInvalida, updateListDemGer, listDemGerOrcadoTot, user, addToast],
  );

  const handleOnChangeTipo = (event: React.ChangeEvent<{ value: unknown }>): void => {
    const fieldSelect = document.getElementById('uz1u-123-fgh99-000');
    let lHidden = true;
    if (fieldSelect) {
      const valor = (fieldSelect as HTMLSelectElement).value;
      lHidden = valor === '0-Valor';
    }

    if (lHidden) {
      const fieldIdDemGerBc = document.getElementById('s654asd56asd2m2');
      if (fieldIdDemGerBc) {
        (fieldIdDemGerBc as HTMLSelectElement).value = '';
      }

      const fieldIdDemGerDescBc = document.getElementById('asd-32434f-d');
      if (fieldIdDemGerDescBc) {
        (fieldIdDemGerDescBc as HTMLSelectElement).value = '';
      }
    }

    setHiddenDemGerPesquisarBC(lHidden);
  };

  const handleChangeAllCheckbox = (event: React.ChangeEvent<HTMLInputElement>): void => {
    let localChecked = true;
    const fieldCheckedTodos = document.getElementById('todos-54-dki-pof3');
    if (fieldCheckedTodos) {
      localChecked = (fieldCheckedTodos as HTMLInputElement).checked;
    }

    const fieldCheckedJan = document.getElementById('jan');
    if (fieldCheckedJan) {
      (fieldCheckedJan as HTMLInputElement).checked = localChecked;
    }

    const fieldCheckedFev = document.getElementById('fev');
    if (fieldCheckedFev) {
      (fieldCheckedFev as HTMLInputElement).checked = localChecked;
    }

    const fieldCheckedMar = document.getElementById('mar');
    if (fieldCheckedMar) {
      (fieldCheckedMar as HTMLInputElement).checked = localChecked;
    }

    const fieldCheckedAbr = document.getElementById('abr');
    if (fieldCheckedAbr) {
      (fieldCheckedAbr as HTMLInputElement).checked = localChecked;
    }

    const fieldCheckedMaio = document.getElementById('mai');
    if (fieldCheckedMaio) {
      (fieldCheckedMaio as HTMLInputElement).checked = localChecked;
    }

    const fieldCheckedJun = document.getElementById('jun');
    if (fieldCheckedJun) {
      (fieldCheckedJun as HTMLInputElement).checked = localChecked;
    }

    const fieldCheckedJul = document.getElementById('jul');
    if (fieldCheckedJul) {
      (fieldCheckedJul as HTMLInputElement).checked = localChecked;
    }

    const fieldCheckedAgo = document.getElementById('ago');
    if (fieldCheckedAgo) {
      (fieldCheckedAgo as HTMLInputElement).checked = localChecked;
    }

    const fieldCheckedSet = document.getElementById('set');
    if (fieldCheckedSet) {
      (fieldCheckedSet as HTMLInputElement).checked = localChecked;
    }

    const fieldCheckedOut = document.getElementById('out');
    if (fieldCheckedOut) {
      (fieldCheckedOut as HTMLInputElement).checked = localChecked;
    }

    const fieldCheckedNov = document.getElementById('nov');
    if (fieldCheckedNov) {
      (fieldCheckedNov as HTMLInputElement).checked = localChecked;
    }

    const fieldCheckedDez = document.getElementById('dez');
    if (fieldCheckedDez) {
      (fieldCheckedDez as HTMLInputElement).checked = localChecked;
    }
  };

  return (
    <Container>
      <Header />

      <Tab>
        <TabButtonArea>
          <TabButton id="pesquisar" isActive={!hiddenPesquisar} onClick={() => handleClick('pesquisar')}>
            Listagem
          </TabButton>
          <TabButton id="cadastrar" isActive={!hiddenCadastrar} onClick={() => handleClick('cadastrar')}>
            Cadastro
          </TabButton>
          <Tooltip title="Reduzir o tamanho da linha" placement="top">
            <TabButtonGridRowHeight id="gridrowheightdown" onClick={() => handleGridRowHeightDown()}>
              <AiOutlineCaretDown />
            </TabButtonGridRowHeight>
          </Tooltip>

          <Tooltip title="Aumentar o tamanho da linha" placement="top">
            <TabButtonGridRowHeight id="gridrowheightup" onClick={() => handleGridRowHeightUp()}>
              <AiOutlineCaretUp />
            </TabButtonGridRowHeight>
          </Tooltip>

          <TabButton id="filtro" isActive={false} onClick={() => handleClick('filtro')}>
            <AiOutlineSearch />
          </TabButton>

          <Tooltip hidden title="Importar arquivo Excel" placement="top">
            <TabButton id="importar_xls" isActive={false} onClick={() => setOpenUploadFile(true)} hidden>
              <CloudUploadIcon />
            </TabButton>
          </Tooltip>

          <Tooltip title="Calcular Rateios do Orçamento" placement="top">
            <TabButton id="id_recalcular_orcamentos" isActive={false} onClick={() => setOpenRecalcularOrcamento(true)}>
              <AiOutlineCalculator />
            </TabButton>
          </Tooltip>
          <Tooltip title="Novo Registro" placement="top">
            <TabButtonNovo id="new_register" onClick={() => OnSetRegisterToAdd()}>
              <GoPlus />
              Novo
            </TabButtonNovo>
          </Tooltip>

          <Tooltip title="Remover Registros Selecionados" placement="top">
            <span>
              <span hidden={selectedIds.length <= 0}>
                <TabButtonDelete
                  id="remove_all"
                  onClick={() => {
                    setIdDeleteDemGerOrcadoTot('');
                    setOpenDeleteMultiple(true);
                  }}
                >
                  <RiDeleteBinLine />
                  Remover
                </TabButtonDelete>
              </span>
            </span>
          </Tooltip>
        </TabButtonArea>

        <TabTitulo>
          <span>{titulo}</span>
        </TabTitulo>
      </Tab>
      <SpanFiltro>{textFiltro}</SpanFiltro>

      <TabContent id="pesquisar" isActive={!hiddenPesquisar}>
        <div style={{ height: '85vh', width: '100%' }}>
          <ThemeProvider theme={theme}>
            <DataGrid
              rows={listDemGerOrcadoTot}
              columns={columns}
              rowHeight={gridRowHeight}
              checkboxSelection
              selectionModel={selectedIds}
              disableSelectionOnClick
              onSelectionModelChange={e => setSelectedIds(e.selectionModel)}
            />
          </ThemeProvider>
        </div>
      </TabContent>

      <TabContent id="cadasto" isActive={!hiddenCadastrar}>
        <Form ref={formRef} onSubmit={handleSubmit} initialData={demGerOrcadoTot}>
          <FormContainer>
            <FormNavigate>
              <Tooltip title="Novo Registro" placement="top">
                <IconButton color="primary" aria-label="adicionar" onClick={() => onNewRegister()}>
                  <AiOutlinePlus />
                </IconButton>
              </Tooltip>

              <Tooltip title="Duplicar Registro" placement="top">
                <IconButton color="primary" aria-label="Duplicar" onClick={() => OnGridRowDuplicate(demGerOrcadoTot)}>
                  <MdContentCopy />
                </IconButton>
              </Tooltip>

              <Tooltip title="Excluir Registro" placement="top">
                <IconButton
                  color="primary"
                  aria-label="Excluir"
                  onClick={() => OnConfirmationDeleteSingle(demGerOrcadoTot ? demGerOrcadoTot.id : '')}
                >
                  <RiDeleteBinLine />
                </IconButton>
              </Tooltip>

              <Tooltip title="Cancelar" placement="top">
                <IconButton type="reset" color="primary" aria-label="Cancelar" onClick={() => onCancelRegister()}>
                  <MdClear />
                </IconButton>
              </Tooltip>

              <Tooltip title="Salvar" placement="top">
                <IconButton type="submit" color="primary" aria-label="Confirmar">
                  <AiOutlineSave />
                </IconButton>
              </Tooltip>

              <Tooltip title="Primeiro Registro" placement="top">
                <IconButton color="primary" aria-label="Primeiro" onClick={() => onFirstRegister()}>
                  <AiFillFastBackward />
                </IconButton>
              </Tooltip>

              <Tooltip title="Registro Anterior" placement="top">
                <IconButton color="primary" aria-label="Anterior" onClick={() => onPriorRegister()}>
                  <MdSkipPrevious />
                </IconButton>
              </Tooltip>

              <Tooltip title="Próximo Registro" placement="top">
                <IconButton color="primary" aria-label="Próximo" onClick={() => onNextRegister()}>
                  <MdSkipNext />
                </IconButton>
              </Tooltip>

              <Tooltip title="Último Registro" placement="top">
                <IconButton color="primary" aria-label="Último registro" onClick={() => onLastRegister()}>
                  <AiFillFastForward />
                </IconButton>
              </Tooltip>
            </FormNavigate>

            <FormFields>
              <ContainerFields field="T100">
                <span>Ano:</span>
                <InputComum autoFocus name="ano" />
              </ContainerFields>

              <ContainerQuadro>
                <ContainerFields field="T100">
                  <span>Totalizador:</span>
                  <LookupDemGer
                    idInputLookup="457e41fcfbeb879885d734e16dc9f178"
                    in_id={idDemGerPesquisar}
                    onConfirm={handleOnConfirmDemGerSearchRegister}
                    onIdDemGer={onIdDemGerLookup}
                    onClearId={onClearIdDemGerLookup}
                    permiteCalcProjecaoOrc
                  />
                </ContainerFields>

                <ContainerFields field="T300px" className="field2">
                  <span>Tipo:</span>
                  <FormContainerValueMes>
                    <Select
                      id="uz1u-123-fgh99-000"
                      name="tipo_valor"
                      defaultValue="0-Valor"
                      onChange={handleOnChangeTipo}
                    >
                      {options_tipo_valor.map(indicador => (
                        <option key={indicador.value} value={indicador.value}>
                          {indicador.value === '0-Valor' ? 'Valor (R$)' : 'Percentual (%)'}
                        </option>
                      ))}
                    </Select>
                  </FormContainerValueMes>
                </ContainerFields>
              </ContainerQuadro>

              <ContainerFields field="T100" hidden={hiddenDemGerPesquisarBC}>
                <span>Totalizador de Base de Cálculo:</span>
                <LookupDemGer
                  idInputLookup="hhh-h7253d-734-e16d-c9f178"
                  in_id={idDemGerPesquisarBC}
                  onConfirm={handleOnConfirmDemGerBCSearchRegister}
                  onIdDemGer={onIdDemGerBCLookup}
                  onClearId={onClearIdDemGerBCLookup}
                  onCodigoTotalizadorBase={onCodigoTotalizadorBase}
                  permiteCalcProjecaoOrc
                />
              </ContainerFields>

              <ContainerFields field="T300px">
                <span>Indicador totalizador:</span>
                <Select name="tipo" defaultValue="1-Previsto">
                  {options_tipo.map(indicador => (
                    <option key={indicador.value} value={indicador.value}>
                      {indicador.label}
                    </option>
                  ))}
                </Select>
              </ContainerFields>

              <ContainerAllMonthPrice>
                <ContainerQuadro>
                  <ContainerFields field="T300px">
                    <span>Janeiro:</span>

                    <FormContainerValue>
                      <FormContainerValueMes>
                        <InputComum name="janeiro" placeholder="0,0000" type="number" step="0.0001" />
                      </FormContainerValueMes>
                    </FormContainerValue>
                  </ContainerFields>

                  <ContainerFields field="T300px" className="field2">
                    <span>Fevereiro:</span>
                    <FormContainerValue>
                      <FormContainerValueMes>
                        <InputComum name="fevereiro" placeholder="0,0000" type="number" step="0.0001" />
                      </FormContainerValueMes>
                    </FormContainerValue>
                  </ContainerFields>
                </ContainerQuadro>

                <ContainerQuadro>
                  <ContainerFields field="T300px">
                    <span>Março:</span>
                    <FormContainerValue>
                      <FormContainerValueMes>
                        <InputComum name="marco" placeholder="0,0000" type="number" step="0.0001" />
                      </FormContainerValueMes>
                    </FormContainerValue>
                  </ContainerFields>

                  <ContainerFields field="T300px" className="field2">
                    <span>Abril:</span>
                    <FormContainerValue>
                      <FormContainerValueMes>
                        <InputComum name="abril" placeholder="0,0000" type="number" step="0.0001" />
                      </FormContainerValueMes>
                    </FormContainerValue>
                  </ContainerFields>
                </ContainerQuadro>

                <ContainerQuadro>
                  <ContainerFields field="T300px">
                    <span>Maio:</span>
                    <FormContainerValue>
                      <FormContainerValueMes>
                        <InputComum name="maio" placeholder="0,0000" type="number" step="0.0001" />
                      </FormContainerValueMes>
                    </FormContainerValue>
                  </ContainerFields>
                  <ContainerFields field="T300px" className="field2">
                    <span>Junho:</span>
                    <FormContainerValue>
                      <FormContainerValueMes>
                        <InputComum name="junho" placeholder="0,0000" type="number" step="0.0001" />
                      </FormContainerValueMes>
                    </FormContainerValue>
                  </ContainerFields>
                </ContainerQuadro>

                <ContainerQuadro>
                  <ContainerFields field="T300px">
                    <span>Julho:</span>
                    <FormContainerValue>
                      <FormContainerValueMes>
                        <InputComum name="julho" placeholder="0,0000" type="number" step="0.0001" />
                      </FormContainerValueMes>
                    </FormContainerValue>
                  </ContainerFields>

                  <ContainerFields field="T300px" className="field2">
                    <span>Agosto:</span>
                    <FormContainerValue>
                      <FormContainerValueMes>
                        <InputComum name="agosto" placeholder="0,0000" type="number" step="0.0001" />
                      </FormContainerValueMes>
                    </FormContainerValue>
                  </ContainerFields>
                </ContainerQuadro>

                <ContainerQuadro>
                  <ContainerFields field="T300px">
                    <span>Setembro:</span>
                    <FormContainerValue>
                      <FormContainerValueMes>
                        <InputComum name="setembro" placeholder="0,0000" type="number" step="0.0001" />
                      </FormContainerValueMes>
                    </FormContainerValue>
                  </ContainerFields>

                  <ContainerFields field="T300px" className="field2">
                    <span>Outubro:</span>
                    <FormContainerValue>
                      <FormContainerValueMes>
                        <InputComum name="outubro" placeholder="0,0000" type="number" step="0.0001" />
                      </FormContainerValueMes>
                    </FormContainerValue>
                  </ContainerFields>
                </ContainerQuadro>

                <ContainerQuadro>
                  <ContainerFields field="T300px">
                    <span>Novembro:</span>
                    <FormContainerValue>
                      <FormContainerValueMes>
                        <InputComum name="novembro" placeholder="0,0000" type="number" step="0.0001" />
                      </FormContainerValueMes>
                    </FormContainerValue>
                  </ContainerFields>

                  <ContainerFields field="T300px" className="field2">
                    <span>Dezembro:</span>
                    <FormContainerValue>
                      <FormContainerValueMes>
                        <InputComum name="dezembro" placeholder="0,0000" type="number" step="0.0001" />
                      </FormContainerValueMes>
                    </FormContainerValue>
                  </ContainerFields>
                </ContainerQuadro>
              </ContainerAllMonthPrice>

              <ContainerFields field="T100">
                <span>Observação:</span>
                <InputComum autoFocus name="obs" />
              </ContainerFields>
              <ContainerFields field="TID">
                <InputComum name="id" hidden />
              </ContainerFields>
              <ContainerFields field="TID">
                <InputComum name="id_dem_ger" id="saas764d2z2m2" hidden />
              </ContainerFields>
              <ContainerFields field="TID">
                <InputComum name="id_dem_ger_bc" id="s654asd56asd2m2" hidden />
              </ContainerFields>
              <ContainerFields field="TID">
                <InputComum name="totalizador" id="777u78rw239XA" hidden />
              </ContainerFields>
              <ContainerFields field="TID">
                <InputComum name="totalizador_bc" id="asd-32434f-d" hidden />
              </ContainerFields>
              <ContainerFields field="TID">
                <InputComum name="cod_tot_base" id="345-0m-77-53m" hidden />
              </ContainerFields>
            </FormFields>
          </FormContainer>
        </Form>
      </TabContent>

      <Dialog
        open={openFilterDemGer}
        onClose={handleCloseFilterDemGer}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Filtro</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">Totalizador:</DialogContentText>
          <LookupDemGer
            idInputLookup="hyb3fgasy33242"
            onConfirm={handleOnConfirmDemGerSearch}
            multiSelect
            permiteCalcProjecaoOrc
          />
        </DialogContent>

        <DialogActions>
          <ButtonDialog onClick={handleCloseFilterDemGer} color="primary" autoFocus>
            Cancelar
          </ButtonDialog>
          <ButtonDialog onClick={handleOnFilter} color="primary">
            Filtrar
          </ButtonDialog>
        </DialogActions>
      </Dialog>

      <FormModalDialogConfirm
        open={openDeleteSingle}
        onClose={() => setOpenDeleteSingle(false)}
        onConfirm={() => handleOnRowDeleteSingle()}
        buttonCancelText="Cancelar"
        buttonConfirmText="Sim"
        formTitle="Excluir?"
        messageConfirm="Tem certeza que deseja excluir?"
      />

      <FormModalDialogConfirm
        open={openDeleteMultiple}
        onClose={() => setOpenDeleteMultiple(false)}
        onConfirm={() => handleOnRowDeleteMultiple()}
        buttonCancelText="Cancelar"
        buttonConfirmText="Sim"
        formTitle="Excluir?"
        messageConfirm="Tem certeza que deseja excluir todos os registros selecionados?"
      />

      <Dialog
        open={openRecalcularOrcamento}
        onClose={handleCloseCalcularOrcamento}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="xs"
        fullWidth
      >
        <DialogTitle id="alert-dialog-title">
          <SpanTitleCalcular>Calcular Rateios do Orçamento</SpanTitleCalcular>
        </DialogTitle>
        <SpanFiltroCalcular>
          Empresa: {user.empresa.cnpj.trim()}-{user.empresa.name.trim()}
        </SpanFiltroCalcular>

        <Separator className="lineOne" />
        <ContainerFieldTextFilter>
          <span>Ano:</span>
          <input type="number" name="ano" id="ano" step={1} defaultValue={new Date().getFullYear()} />
        </ContainerFieldTextFilter>

        <Separator className="lineOne" />
        <ContainerFieldCheckboxFilter style={{ width: '300px' }} className="marcardesmarcar">
          <input
            type="checkbox"
            name="todos"
            id="todos-54-dki-pof3"
            onChange={handleChangeAllCheckbox}
            defaultChecked
          />
          <span>Marcar/Desmarcar Todos</span>
        </ContainerFieldCheckboxFilter>
        <ContainerQuadro>
          <ContainerFieldCheckboxFilter style={{ width: '150px' }} className="field1">
            <input type="checkbox" name="janeiro" id="jan" defaultChecked />
            <span>Janeiro</span>
          </ContainerFieldCheckboxFilter>

          <ContainerFieldCheckboxFilter style={{ width: '150px' }} className="field2">
            <input type="checkbox" name="fevereiro" id="fev" defaultChecked />
            <span>Fevereiro</span>
          </ContainerFieldCheckboxFilter>
        </ContainerQuadro>
        <ContainerQuadro>
          <ContainerFieldCheckboxFilter style={{ width: '150px' }} className="field1">
            <input type="checkbox" name="marco" id="mar" defaultChecked />
            <span>Março</span>
          </ContainerFieldCheckboxFilter>

          <ContainerFieldCheckboxFilter style={{ width: '150px' }} className="field2">
            <input type="checkbox" name="abril" id="abr" defaultChecked />
            <span>Abril</span>
          </ContainerFieldCheckboxFilter>
        </ContainerQuadro>
        <ContainerQuadro>
          <ContainerFieldCheckboxFilter style={{ width: '150px' }} className="field1">
            <input type="checkbox" name="maio" id="mai" defaultChecked />
            <span>Maio</span>
          </ContainerFieldCheckboxFilter>

          <ContainerFieldCheckboxFilter style={{ width: '150px' }} className="field2">
            <input type="checkbox" name="junho" id="jun" defaultChecked />
            <span>Junho</span>
          </ContainerFieldCheckboxFilter>
        </ContainerQuadro>
        <ContainerQuadro>
          <ContainerFieldCheckboxFilter style={{ width: '150px' }} className="field1">
            <input type="checkbox" name="julho" id="jul" defaultChecked />
            <span>Julho</span>
          </ContainerFieldCheckboxFilter>

          <ContainerFieldCheckboxFilter style={{ width: '150px' }} className="field2">
            <input type="checkbox" name="agosto" id="ago" defaultChecked />
            <span>Agosto</span>
          </ContainerFieldCheckboxFilter>
        </ContainerQuadro>
        <ContainerQuadro>
          <ContainerFieldCheckboxFilter style={{ width: '150px' }} className="field1">
            <input type="checkbox" name="setembro" id="set" defaultChecked />
            <span>Setembro</span>
          </ContainerFieldCheckboxFilter>

          <ContainerFieldCheckboxFilter style={{ width: '150px' }} className="field2">
            <input type="checkbox" name="outubro" id="out" defaultChecked />
            <span>Outubro</span>
          </ContainerFieldCheckboxFilter>
        </ContainerQuadro>
        <ContainerQuadro>
          <ContainerFieldCheckboxFilter style={{ width: '150px' }} className="field1">
            <input type="checkbox" name="novembro" id="nov" defaultChecked />
            <span>Novembro</span>
          </ContainerFieldCheckboxFilter>

          <ContainerFieldCheckboxFilter style={{ width: '150px' }} className="field2">
            <input type="checkbox" name="dezembro" id="dez" defaultChecked />
            <span>Dezembro</span>
          </ContainerFieldCheckboxFilter>
        </ContainerQuadro>

        <ContainerFieldCheckboxFilter style={{ width: '300px' }} className="fieldtodos">
          <input type="checkbox" name="todos" id="substituirregistros" />
          <span>Substituir Registros Existentes</span>
        </ContainerFieldCheckboxFilter>

        <DialogContent>
          <span>Totalizador:</span>
          <LookupDemGer
            idInputLookup="hyb3fgasy33242"
            multiSelect
            permiteCalcProjecaoOrc
            inPlaceholder="Cálcular todos os totalizadores"
          />
        </DialogContent>

        <DialogActions style={{ justifyContent: 'space-between' }}>
          <ButtonDialog onClick={handleCloseCalcularOrcamento} color="secondary" autoFocus>
            Cancelar
          </ButtonDialog>
          <ButtonDialog onClick={handleOnRecalcularOrcamento} color="primary">
            Calcular
          </ButtonDialog>
        </DialogActions>
      </Dialog>

      <Backdrop className={cssLoading.backdrop} open={openLoading}>
        <CircularProgress color="inherit" />
      </Backdrop>

      <ModalUploadFile
        open={openUploadFile}
        onClose={onUploadFileClose}
        apiSource={`/demgerorcadotot/file/${user.license.id}?cnpj_estab=${user.empresa.cnpj}`}
        apiSourceFileExample="/demgerorcadotot/file/example"
      />
    </Container>
  );
};

export default DemGerOrcadoTot;
