import React, { InputHTMLAttributes, useEffect, useRef } from 'react';
import { useField } from '@unform/core';

import { Container } from './styles';

interface IInputProps extends InputHTMLAttributes<HTMLInputElement> {
  name: string;
  containerStyle?: object;
}

const Checkbox: React.FC<IInputProps> = ({ name, containerStyle = {}, ...rest }) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const { fieldName, defaultValue, registerField } = useField(name);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef,
      getValue: ref => {
        return ref.current.checked;
      },
      setValue: (ref, value) => {
        ref.current.checked = value;
      },
      clearValue: ref => {
        ref.current.value = true;
      },
    });
  }, [defaultValue, fieldName, registerField]);
  return (
    <Container style={containerStyle}>
      <input
        type="checkbox"
        defaultValue={defaultValue}
        ref={inputRef}
        defaultChecked={defaultValue}
        id={fieldName}
        {...rest}
      />
    </Container>
  );
};

export default Checkbox;
