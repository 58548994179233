import React, { useEffect, useState } from 'react';

import Header from '../../components/Header';

import { Container, Content } from './styles';

import { useAuth } from '../../hooks/Auth';
import IFramePowerBI from '../../components/IFramePowerBI';

const Home: React.FC = () => {
  const { user } = useAuth();
  const [urlPowerBI, setUrlPowerBI] = useState<string>('');

  useEffect(() => {
    if (user.empresa) {
      setUrlPowerBI(user.empresa.url_powerbi);
    }

    if (user.license && user.email === user.license.email_admin_user) {
      setUrlPowerBI(user.license.url_powerbi);
    }
  }, [user]);

  return (
    <Container>
      <Header />

      <Content>{urlPowerBI !== '' && <IFramePowerBI title="Analise" src={urlPowerBI} ehDashboard={false} />}</Content>
    </Container>
  );
};

export default Home;
