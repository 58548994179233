import styled, { css } from 'styled-components';

interface IDropContainerProps {
  isDragActive?: boolean;
  isDragReject?: boolean;
}

interface IUploadMessageProps {
  type?: 'success' | 'error' | 'default';
}

export const Container = styled.div`
  width: 100%;
  background: #fff;
  border-radius: 4px;
`;

const dragActive = css`
  border-color: #000;
`;

export const DropContainer = styled.div<IDropContainerProps>`
  &.dropzone {
    border: 1px solid #ddd;
    border-style: dashed;
    border-radius: 4px;
    cursor: pointer;
    transition: height 0.2s ease;

    ${props => props.isDragActive && dragActive};

    input {
      width: 100%;
      height: 40px;
    }
  }
`;

const messageColors = {
  default: '#999',
  error: '#e57878',
  success: '#000',
};

export const UploadMessage = styled.p<IUploadMessageProps>`
  display: flex;
  flex-direction: column;
  color: ${props => messageColors[props.type || 'default']};
  justify-content: center;
  align-items: center;
  padding: 15px 0;
`;
