import React, { useCallback, useState, useEffect, KeyboardEvent } from 'react';
import Modal from '@material-ui/core/Modal';
import { DataGrid, GridColDef, GridRowId, ptBR } from '@material-ui/data-grid';
import { createMuiTheme, ThemeProvider, makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import { Backdrop, Tooltip, Fade, Button } from '@material-ui/core';
import PageviewIcon from '@material-ui/icons/Pageview';
import { useLookup } from '../../hooks/Lookup/index';
import { useAuth } from '../../hooks/Auth';
import IIndicadorPersonalizadoLookup, {
  emptyIndicadorPersonalizadoLookup,
} from '../../hooks/Lookup/IIndicadorPersonalizadoLookup';
import api from '../../services/api';

import {
  ColumnCodIndicador,
  ColumnCodIndicadorSup,
  ColumnGrupoIndicador,
  ColumnDescIndicador,
  ColumnIdCadIndicPerson,
  ColumnTipoIndicador,
} from './GridColumns';
import { TitleSearch, ButtonGroup, ButtonSearch, ContainerLookup, ContainerSearch } from './styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    paper: {
      backgroundColor: theme.palette.background.paper,
      border: '1px solid #006699',
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
    },
  }),
);

const theme = createMuiTheme(
  {
    palette: {
      primary: { main: '#006699' },
    },
  },
  ptBR,
);

type functionConfirm = (list: IIndicadorPersonalizadoLookup[]) => void;
type functionGetTextInput = (value: string) => void;
type getIdIndicadorPersonalizado = () => string;
interface ILookupIndicadorPersonalizadoProps {
  in_id?: string;
  idInputLookup: string;
  onIdIndicadorPersonalizado?: getIdIndicadorPersonalizado;
  onConfirm?: functionConfirm;
  onGetTextInput?: functionGetTextInput;
  multiSelect?: boolean;
  clearInputSearchField?: boolean;
}

const LookupIndicadorPersonalizado: React.FC<ILookupIndicadorPersonalizadoProps> = ({
  in_id,
  idInputLookup,
  onIdIndicadorPersonalizado,
  multiSelect,
  onConfirm,
  onGetTextInput,
  clearInputSearchField,
}) => {
  const { user } = useAuth();
  const [open, setOpen] = React.useState(false);
  const [idIndicadorPersonalizado, setIdIndicadorPersonalizado] = React.useState<string>('');
  const [inputTextValue, setInputTextValue] = React.useState<string>('');
  const [selectedIds, setSelectedIds] = useState<GridRowId[]>([]);
  const { getListIndicadorPersonalizado, updateListIndicadorPersonalizado } = useLookup();
  const [listIndicadorPersonalizadoRegister, setListIndicadorPersonalizadoRegister] = useState<
    IIndicadorPersonalizadoLookup[]
  >([]);
  const classes = useStyles();

  const columns: GridColDef[] = [
    ColumnCodIndicador,
    ColumnDescIndicador,
    ColumnCodIndicadorSup,
    ColumnGrupoIndicador,
    ColumnTipoIndicador,
    ColumnIdCadIndicPerson,
  ];

  const handleOnClickConfirm = useCallback((): void => {
    const filterList: IIndicadorPersonalizadoLookup[] = selectedIds.map(id_cad_indic_person => {
      const item = listIndicadorPersonalizadoRegister.find(valor => valor.id_cad_indic_person === id_cad_indic_person);

      if (item) {
        return {
          kind_interface: 'cad_indic_person',
          id_cad_indic_person: item.id_cad_indic_person,
          id_license: item.id_license,
          sistema: item.sistema,
          grupo_indicador: item.grupo_indicador,
          cod_indicador: item.cod_indicador,
          cod_indicador_sup: item.cod_indicador_sup,
          tipo_indicador: item.tipo_indicador,
          desc_indicador: item.desc_indicador,
          nome_gestor: item.nome_gestor,
          nome_auditor: item.nome_auditor,
          obs: item.obs,
          informacao01: item.informacao01,
          informacao02: item.informacao02,
          informacao03: item.informacao03,
          informacao04: item.informacao04,
          informacao05: item.informacao05,
          upload_ftp: item.upload_ftp,
        };
      }

      return emptyIndicadorPersonalizadoLookup;
    });

    const list: IIndicadorPersonalizadoLookup[] = filterList.filter(item => item.id_cad_indic_person !== '');

    const fieldInputSearch = document.getElementById(idInputLookup);
    if (fieldInputSearch) {
      if (clearInputSearchField) {
        setInputTextValue('');
        (fieldInputSearch as HTMLInputElement).value = '';
      } else {
        const inputValue = list.map(item => item.desc_indicador);
        if (onGetTextInput) {
          onGetTextInput(inputValue.join());
        }
        setInputTextValue(inputValue.join());
        (fieldInputSearch as HTMLInputElement).value = inputValue.join();
      }
    }

    updateListIndicadorPersonalizado(list);

    setOpen(false);
    if (onConfirm) {
      onConfirm(list);
    }
  }, [
    selectedIds,
    listIndicadorPersonalizadoRegister,
    updateListIndicadorPersonalizado,
    onConfirm,
    onGetTextInput,
    clearInputSearchField,
    idInputLookup,
  ]);

  const handleOnClickCancel = useCallback((): void => {
    setOpen(false);
  }, []);

  const getCadIndicadorPersonalizadoById = useCallback(
    async (inIdCadIndicPerson: string) => {
      let responseList: IIndicadorPersonalizadoLookup[] = [];
      if (inIdCadIndicPerson) {
        const response = await api.post<IIndicadorPersonalizadoLookup[]>(`/cadindicadorpersonalizado/v1/filter`, {
          id_license: user.license.id,
          sistema: user.empresa.system_kind,
          id_cad_indic_person: inIdCadIndicPerson,
          desc_indicador: '',
        });

        responseList = response.data;
      } else {
        setInputTextValue('');
      }

      setListIndicadorPersonalizadoRegister(responseList);
      let item: IIndicadorPersonalizadoLookup = emptyIndicadorPersonalizadoLookup;
      if (responseList.length > 0) {
        const findItem = responseList.find(itemList => itemList.id_cad_indic_person === inIdCadIndicPerson);
        if (findItem) {
          item = {
            ...findItem,
            kind_interface: 'cad_indic_person',
          };
        }
      }

      const fieldInputSearch = document.getElementById(idInputLookup);
      if (fieldInputSearch) {
        setInputTextValue(item.desc_indicador);
        (fieldInputSearch as HTMLInputElement).value = item.desc_indicador;
      }

      updateListIndicadorPersonalizado([item]);

      if (onConfirm) {
        onConfirm([item]);
      }
    },
    [updateListIndicadorPersonalizado, onConfirm, idInputLookup, user],
  );

  const getInputValue = useCallback((): string => {
    const fieldInputSearch = document.getElementById(idInputLookup);
    let inputValue = '';
    if (fieldInputSearch) {
      inputValue = (fieldInputSearch as HTMLInputElement).value;
    }

    return inputValue;
  }, [idInputLookup]);

  const getCadIndicadorPersonalizado = useCallback(
    async (inDescricao: string, inNew: boolean) => {
      let responseList: IIndicadorPersonalizadoLookup[] = [];
      let id_cad_indic_person = '';
      let assignIdIndicadorPersonalizado = false;

      if (onIdIndicadorPersonalizado && !inNew) {
        id_cad_indic_person = onIdIndicadorPersonalizado();
        assignIdIndicadorPersonalizado =
          id_cad_indic_person !== '' && id_cad_indic_person !== null && id_cad_indic_person !== undefined;

        if (assignIdIndicadorPersonalizado) {
          const response = await api.post<IIndicadorPersonalizadoLookup[]>(`/cadindicadorpersonalizado/v1/filter`, {
            id_license: user.license.id,
            sistema: user.empresa.system_kind,
            id_cad_indic_person,
            desc_indicador: '',
          });
          responseList = response.data;
        }

        if (!assignIdIndicadorPersonalizado || responseList.length <= 0) {
          const response = await api.post<IIndicadorPersonalizadoLookup[]>(`/cadindicadorpersonalizado/v1/filter`, {
            id_license: user.license.id,
            sistema: user.empresa.system_kind,
            id_cad_indic_person: '',
            desc_indicador: inDescricao,
          });
          responseList = response.data;
        }
      } else if (inDescricao.trim() !== '' && inputTextValue !== getInputValue()) {
        const response = await api.post<IIndicadorPersonalizadoLookup[]>(`/cadindicadorpersonalizado/v1/filter`, {
          id_license: user.license.id,
          sistema: user.empresa.system_kind,
          id_cad_indic_person: '',
          desc_indicador: inDescricao,
        });
        responseList = response.data;
      } else {
        const response = await api.post<IIndicadorPersonalizadoLookup[]>(`/cadindicadorpersonalizado/v1/filter`, {
          id_license: user.license.id,
          sistema: user.empresa.system_kind,
          id_cad_indic_person: '',
          desc_indicador: '',
        });
        responseList = response.data;
      }

      setListIndicadorPersonalizadoRegister(responseList);
      const listCadIndicadorPersonalizado = getListIndicadorPersonalizado();

      const list = responseList.map(item => {
        const fintItem = listCadIndicadorPersonalizado.find(
          itemList => itemList.id_cad_indic_person === item.id_cad_indic_person,
        );
        if (fintItem) return fintItem.id_cad_indic_person;

        return '';
      });

      const filterList = list.filter(item => item !== '');

      setSelectedIds(filterList);

      setOpen(true);
    },
    [onIdIndicadorPersonalizado, getListIndicadorPersonalizado, getInputValue, inputTextValue, user],
  );

  useEffect(() => {
    if (in_id !== idIndicadorPersonalizado) {
      setIdIndicadorPersonalizado(in_id || '');
      getCadIndicadorPersonalizadoById(in_id || '');
    }
  }, [idIndicadorPersonalizado, in_id, getCadIndicadorPersonalizadoById]);

  const handleOnClickOpen = useCallback(async () => {
    await getCadIndicadorPersonalizado(getInputValue(), true);
  }, [getCadIndicadorPersonalizado, getInputValue]);

  const handleOnKeyUpInputSearch = useCallback(
    (e: KeyboardEvent<HTMLInputElement>) => {
      if (e.key.toUpperCase() === 'ENTER') {
        e.preventDefault();
        getCadIndicadorPersonalizado(e.currentTarget.value, true);
      } else if (e.key.toUpperCase() === 'BACKSPACE') {
        updateListIndicadorPersonalizado([]);
      }
    },
    [getCadIndicadorPersonalizado, updateListIndicadorPersonalizado],
  );

  return (
    <div id={`w2${idInputLookup}87`} style={{ width: '100%' }}>
      <ContainerLookup>
        <ContainerSearch>
          <Tooltip title="Pesquisar indicador" placement="top">
            <ButtonSearch type="button" onClick={handleOnClickOpen}>
              <PageviewIcon />
            </ButtonSearch>
          </Tooltip>

          <input
            id={idInputLookup}
            type="text"
            placeholder="Pesquisar indicador..."
            onKeyDown={handleOnKeyUpInputSearch}
          />
        </ContainerSearch>
      </ContainerLookup>

      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleOnClickConfirm}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
        disableBackdropClick
      >
        <Fade in={open}>
          <div className={classes.paper}>
            <TitleSearch id="transition-modal-title">I N D I C A D O R E S</TitleSearch>
            <div style={{ height: '450px', width: '700px' }}>
              <ThemeProvider theme={theme}>
                <DataGrid
                  headerHeight={40}
                  rowHeight={25}
                  rows={listIndicadorPersonalizadoRegister}
                  columns={columns}
                  checkboxSelection={multiSelect}
                  selectionModel={selectedIds}
                  getRowId={r => r.id_cad_indic_person}
                  onSelectionModelChange={e => setSelectedIds(e.selectionModel)}
                  onRowDoubleClick={handleOnClickConfirm}
                />
              </ThemeProvider>
            </div>
            <ButtonGroup>
              <Button onClick={handleOnClickCancel} color="secondary" autoFocus>
                Cancelar
              </Button>
              <Button onClick={handleOnClickConfirm} color="primary">
                Confirmar
              </Button>
            </ButtonGroup>
          </div>
        </Fade>
      </Modal>
    </div>
  );
};

export default LookupIndicadorPersonalizado;
