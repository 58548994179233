export interface IFilterFluxoCaixaTipo {
  receita: boolean;
  despesa: boolean;
  transferencia: boolean;
}

export const emptyFilterFluxoCaixaTipo: IFilterFluxoCaixaTipo = {
  receita: true,
  despesa: true,
  transferencia: true,
};
