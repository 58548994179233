import React, { useRef, useState, useEffect, useCallback } from 'react';
import * as Yup from 'yup';

import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';

import { DataGrid, GridColDef, GridCellParams, GridRowId, ptBR } from '@material-ui/data-grid';
import { createMuiTheme, ThemeProvider, makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';

import { FiCopy } from 'react-icons/fi';
import { MdDelete, MdEdit, MdClear, MdSkipNext, MdSkipPrevious, MdContentCopy } from 'react-icons/md';
import {
  AiOutlinePlus,
  AiOutlineSave,
  AiFillFastBackward,
  AiFillFastForward,
  AiOutlineCaretDown,
  AiOutlineCaretUp,
} from 'react-icons/ai';
import { GoPlus } from 'react-icons/go';
import { RiDeleteBinLine } from 'react-icons/ri';

import InputComum from '../../components/InputComum';
import Checkbox from '../../components/Checkbox';
import Header from '../../components/Header';
import FormModalDialogConfirm from '../../components/FormModalDialogConfirm';

import api from '../../services/api';
import { useAuth } from '../../hooks/Auth';
import { useToast } from '../../hooks/Toast';

import getListUpdate from '../../utils/getListUpdate';
import getValidationErrors from '../../utils/getValidationErrors';
import { getPathGrid } from '../../utils/storagePath';

import { ColumnEmail, ColumnName, ColumnActive, ColumnAdmin, ColumnViewer, ColumnId } from './GridColumns';

import IUserLicense from './IUserLicense';

import {
  Container,
  ContainerFields,
  ContainerMonthPrice,
  ContainerAllMonthPrice,
  Tab,
  TabButton,
  TabContent,
  FormFields,
  FormContainer,
  FormNavigate,
  ContainerFieldCheckbox,
  TabTitulo,
  TabButtonArea,
  TabButtonNovo,
  TabButtonDelete,
  TabButtonGridRowHeight,
} from './styles';

const useStylesLoading = makeStyles((theme: Theme) =>
  createStyles({
    backdrop: {
      zIndex: theme.zIndex.drawer + 2000,
      color: '#fff',
    },
  }),
);

const theme = createMuiTheme(
  {
    palette: {
      primary: { main: '#006699' },
    },
  },
  ptBR,
);

const emptyUserLicense: IUserLicense = {
  acao: '',
  id: '',
  id_license: '',
  active: true,
  admin: '',
  viewer: false,
  email: '',
  name: '',
};

const titulo = 'U S U Á R I O S';

const UsersLicense: React.FC = () => {
  const { user } = useAuth();
  const { addToast } = useToast();
  const formRef = useRef<FormHandles>(null);
  const [demGerParametro, setUserLicense] = useState<IUserLicense>();
  const [listUserLicense, setListUserLicense] = useState<IUserLicense[]>([]);
  const [hiddenPesquisar, setHiddenPesquisar] = useState<boolean>(true);
  const [hiddenCadastrar, setHiddenCadastrar] = useState<boolean>(true);
  const [userLicenseIndex, setUserLicenseIndex] = useState<number>(0);
  const [rowToEdit, setRowToEdit] = useState(null);
  const [idDeleteUserLicense, setIdDeleteUserLicense] = useState<string>('');
  const [selectedIds, setSelectedIds] = useState<GridRowId[]>([]);
  const [openDeleteSingle, setOpenDeleteSingle] = useState(false);
  const [openDeleteMultiple, setOpenDeleteMultiple] = useState(false);
  const [openLoading, setOpenLoading] = useState(false);
  const cssLoading = useStylesLoading();
  const [gridRowHeight, setGridRowHeight] = useState<number>(46);

  const handleGridRowHeightUp = useCallback(async () => {
    const heightValue: number = gridRowHeight + 1;
    setGridRowHeight(heightValue);
    localStorage.setItem(getPathGrid(), heightValue.toString());
  }, [gridRowHeight]);

  const handleGridRowHeightDown = useCallback(async () => {
    const heightValue: number = gridRowHeight - 1;
    setGridRowHeight(heightValue);
    localStorage.setItem(getPathGrid(), heightValue.toString());
  }, [gridRowHeight]);

  const licencaInvalida = useCallback((): boolean => {
    if (!user.license || user.license.id === '') {
      addToast({
        type: 'error',
        title: 'Licença inválida',
        description: 'Usuário não está vinculado a nenhuma licença',
      });

      return true;
    }

    return false;
  }, [addToast, user]);

  const clearCheckbox = (id: string): void => {
    const fieldInput = document.getElementById(id);
    if (fieldInput) {
      (fieldInput as HTMLInputElement).checked = false;
    }
  };

  const defineUserLicense = useCallback(async (data: IUserLicense) => {
    setUserLicense(data);
    formRef.current?.setData(data);
  }, []);

  const onNewRegister = useCallback(async () => {
    defineUserLicense(emptyUserLicense);
  }, [defineUserLicense]);

  const OnGridSetRegisterToEdit = useCallback(
    async (params: GridCellParams) => {
      const localUsersCompany = params.row as IUserLicense;

      setUserLicenseIndex(listUserLicense.indexOf(localUsersCompany));
      setHiddenCadastrar(false);
      setHiddenPesquisar(true);
      setRowToEdit(null);
      defineUserLicense(localUsersCompany);
    },
    [listUserLicense, defineUserLicense],
  );

  const OnSetRegisterToAdd = useCallback(async () => {
    onNewRegister();
    setHiddenCadastrar(false);
    setHiddenPesquisar(true);
  }, [onNewRegister]);

  const OnGridRowDuplicate = useCallback(
    async (baseUserLicense: IUserLicense | undefined) => {
      if (baseUserLicense !== undefined) {
        const newUserLicense: IUserLicense = {
          acao: baseUserLicense.acao,
          id: '',
          id_license: baseUserLicense.id_license,
          email: baseUserLicense.email,
          active: baseUserLicense.active,
          admin: baseUserLicense.admin,
          viewer: baseUserLicense.viewer,
          name: baseUserLicense.name,
        };

        defineUserLicense(newUserLicense);
        setHiddenCadastrar(false);
        setHiddenPesquisar(true);
      }
    },
    [defineUserLicense],
  );

  const OnConfirmationDeleteSingle = useCallback(
    async (id: string) => {
      if (licencaInvalida()) return;

      if (id === '') return;

      setIdDeleteUserLicense(id);
      setOpenDeleteSingle(true);
    },
    [licencaInvalida],
  );

  const colunaAcao: GridColDef = {
    field: 'acao',
    headerName: 'Ação',
    editable: false,
    filterable: false,
    hideSortIcons: true,
    sortable: false,
    disableColumnMenu: true,
    width: 160,
    renderCell: (params: GridCellParams) => (
      <strong>
        <Tooltip title="Editar Registro" placement="top">
          <IconButton color="primary" aria-label="Editar" onClick={() => OnGridSetRegisterToEdit(params)}>
            <MdEdit />
          </IconButton>
        </Tooltip>

        <Tooltip title="Duplicar Registro" placement="top">
          <IconButton
            color="primary"
            aria-label="Duplicar"
            onClick={() => OnGridRowDuplicate(params.row as IUserLicense)}
          >
            <FiCopy />
          </IconButton>
        </Tooltip>

        <Tooltip title="Excluir Registro" placement="top">
          <IconButton
            color="primary"
            aria-label="Excluir"
            onClick={() => OnConfirmationDeleteSingle((params.row as IUserLicense).id)}
          >
            <MdDelete />
          </IconButton>
        </Tooltip>
      </strong>
    ),
  };

  const columns: GridColDef[] = [
    colunaAcao,
    ColumnEmail,
    ColumnName,
    ColumnActive,
    ColumnAdmin,
    ColumnViewer,
    ColumnId,
  ];

  const getUserLicense = useCallback(async () => {
    const response = await api.get<IUserLicense[]>(`/userlicense/all/${user.license.id}`);
    const list = response.data.filter(item => item.email !== 'suporte@assessortech.com.br');
    setListUserLicense(list);

    if (rowToEdit !== null) {
      const findEditUserLicense = response.data.find(item => item.id === rowToEdit);

      if (findEditUserLicense) {
        defineUserLicense(findEditUserLicense);
        setUserLicenseIndex(response.data.indexOf(findEditUserLicense));
        setHiddenCadastrar(false);
        setHiddenPesquisar(true);
        setRowToEdit(null);
      }
    }
  }, [user, defineUserLicense, rowToEdit]);

  const getValues = useCallback(async () => {
    setOpenLoading(true);

    await getUserLicense();

    setOpenLoading(false);
    setHiddenPesquisar(false);
  }, [getUserLicense]);

  useEffect(() => {
    getValues();

    const rowHeight = localStorage.getItem(getPathGrid());
    if (rowHeight) {
      setGridRowHeight(parseInt(rowHeight, 10));
    }
  }, [getValues]);

  const handleOnRowDeleteSingle = useCallback(async () => {
    setOpenLoading(true);
    await api.delete(`/userlicense/${idDeleteUserLicense}`);

    setListUserLicense(OldValue => OldValue.filter(item => item.id !== idDeleteUserLicense));

    defineUserLicense(emptyUserLicense);

    addToast({
      type: 'success',
      title: 'Removido',
      description: 'Registro excluído com sucesso!',
    });

    setOpenDeleteSingle(false);
    setOpenLoading(false);
  }, [idDeleteUserLicense, addToast, defineUserLicense]);

  const handleOnRowDeleteMultiple = useCallback(async () => {
    setOpenLoading(true);
    if (selectedIds.length > 0) {
      await Promise.all(
        selectedIds.map(itemId => {
          setListUserLicense(OldValue => OldValue.filter(item => item.id !== itemId));

          return api.delete(`/userlicense/${itemId}`);
        }),
      );

      defineUserLicense(emptyUserLicense);

      addToast({
        type: 'success',
        title: 'Removido',
        description: 'Registro excluído com sucesso!',
      });
    }

    setSelectedIds([]);
    setOpenDeleteMultiple(false);
    setOpenLoading(false);
  }, [addToast, defineUserLicense, selectedIds]);

  const handleClick = (id: string): void => {
    setHiddenCadastrar(id === 'pesquisar');
    setHiddenPesquisar(id === 'cadastrar');
  };

  const onNextRegister = useCallback(async () => {
    if (userLicenseIndex !== undefined) {
      if (userLicenseIndex < listUserLicense.length - 1) {
        const localIndex = userLicenseIndex + 1;
        setUserLicenseIndex(localIndex);
        const findItem = listUserLicense[localIndex];
        if (findItem) {
          defineUserLicense(findItem);
        }
      }
    }
  }, [userLicenseIndex, listUserLicense, defineUserLicense]);

  const onFirstRegister = useCallback(async () => {
    if (listUserLicense.length > 0) {
      setUserLicenseIndex(0);
      const findItem = listUserLicense[0];
      if (findItem) {
        defineUserLicense(findItem);
      }
    }
  }, [listUserLicense, defineUserLicense]);

  const onLastRegister = useCallback(async () => {
    if (listUserLicense.length > 0) {
      setUserLicenseIndex(listUserLicense.length - 1);
      const findItem = listUserLicense[listUserLicense.length - 1];
      if (findItem) {
        defineUserLicense(findItem);
      }
    }
  }, [listUserLicense, defineUserLicense]);

  const onPriorRegister = useCallback(async () => {
    if (userLicenseIndex !== undefined) {
      if (userLicenseIndex > 0) {
        const localIndex = userLicenseIndex - 1;
        setUserLicenseIndex(localIndex);
        const findItem = listUserLicense[localIndex];
        if (findItem) {
          defineUserLicense(findItem);
        }
      }
    }
  }, [userLicenseIndex, listUserLicense, defineUserLicense]);

  const onCancelRegister = useCallback(async () => {
    formRef.current?.setErrors({});

    if (userLicenseIndex !== undefined) {
      const findItem = listUserLicense[userLicenseIndex];
      if (findItem) {
        defineUserLicense(findItem);
      }
    }

    onNewRegister();
  }, [userLicenseIndex, listUserLicense, onNewRegister, defineUserLicense]);

  const handleSubmit = useCallback(
    async (data: IUserLicense, { reset }) => {
      if (licencaInvalida()) return;

      formRef.current?.setErrors({});
      try {
        const schema = Yup.object().shape({
          email: Yup.string().email().required('E-mail é obrigatório'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        setOpenLoading(true);
        if (data.id === '') {
          const formData = {
            id_license: user.license.id,
            email: data.email,
            admin: data.admin,
            active: data.active,
            viewer: data.viewer,
            name: data.name,
          };

          await api.post(`/userlicense`, formData);

          setListUserLicense(oldLicenses => [...oldLicenses, data]);

          addToast({
            type: 'success',
            title: 'Adicionado',
            description: 'Registro adicionado com sucesso!',
          });
        } else {
          const formEdit = {
            id_license: user.license.id,
            email: data.email,
            admin: data.admin,
            active: data.active,
            viewer: data.viewer,
            name: data.name,
          };

          await api.put(`/userlicense/${data.id}`, formEdit);

          setListUserLicense(getListUpdate(listUserLicense, data));

          addToast({
            type: 'success',
            title: 'Alterado',
            description: 'Registro alterado com sucesso!',
          });
        }

        reset();
        setOpenLoading(false);
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          formRef.current?.setErrors(errors);
        }

        setOpenLoading(false);
      }
    },
    [licencaInvalida, listUserLicense, user, addToast],
  );

  return (
    <Container>
      <Header />

      <Tab>
        <TabButtonArea>
          <TabButton id="pesquisar" isActive={!hiddenPesquisar} onClick={() => handleClick('pesquisar')}>
            Listagem
          </TabButton>
          <TabButton id="cadastrar" isActive={!hiddenCadastrar} onClick={() => handleClick('cadastrar')}>
            Cadastro
          </TabButton>
          <Tooltip title="Reduzir o tamanho da linha" placement="top">
            <TabButtonGridRowHeight id="gridrowheightdown" onClick={() => handleGridRowHeightDown()}>
              <AiOutlineCaretDown />
            </TabButtonGridRowHeight>
          </Tooltip>

          <Tooltip title="Aumentar o tamanho da linha" placement="top">
            <TabButtonGridRowHeight id="gridrowheightup" onClick={() => handleGridRowHeightUp()}>
              <AiOutlineCaretUp />
            </TabButtonGridRowHeight>
          </Tooltip>

          <Tooltip title="Novo Registro" placement="top">
            <TabButtonNovo id="new_register" onClick={() => OnSetRegisterToAdd()}>
              <GoPlus />
              Novo
            </TabButtonNovo>
          </Tooltip>

          <Tooltip title="Remover Registros Selecionados" placement="top">
            <span>
              <span hidden={selectedIds.length <= 0}>
                <TabButtonDelete
                  id="remove_all"
                  onClick={() => {
                    setIdDeleteUserLicense('');
                    setOpenDeleteMultiple(true);
                  }}
                >
                  <RiDeleteBinLine />
                  Remover
                </TabButtonDelete>
              </span>
            </span>
          </Tooltip>
        </TabButtonArea>

        <TabTitulo>
          <span>{titulo}</span>
        </TabTitulo>
      </Tab>

      <TabContent id="pesquisar" isActive={!hiddenPesquisar}>
        <div style={{ height: '85vh', width: '100%' }}>
          <ThemeProvider theme={theme}>
            <DataGrid
              rows={listUserLicense}
              columns={columns}
              rowHeight={gridRowHeight}
              checkboxSelection
              selectionModel={selectedIds}
              disableSelectionOnClick
              onSelectionModelChange={e => setSelectedIds(e.selectionModel)}
            />
          </ThemeProvider>
        </div>
      </TabContent>

      <TabContent id="cadasto" isActive={!hiddenCadastrar}>
        <Form ref={formRef} onSubmit={handleSubmit} initialData={demGerParametro}>
          <FormContainer>
            <FormNavigate>
              <Tooltip title="Novo Registro" placement="top">
                <IconButton color="primary" aria-label="adicionar" onClick={() => onNewRegister()}>
                  <AiOutlinePlus />
                </IconButton>
              </Tooltip>

              <Tooltip title="Duplicar Registro" placement="top">
                <IconButton color="primary" aria-label="Duplicar" onClick={() => OnGridRowDuplicate(demGerParametro)}>
                  <MdContentCopy />
                </IconButton>
              </Tooltip>

              <Tooltip title="Excluir Registro" placement="top">
                <IconButton
                  color="primary"
                  aria-label="Excluir"
                  onClick={() => OnConfirmationDeleteSingle(demGerParametro ? demGerParametro.id : '')}
                >
                  <RiDeleteBinLine />
                </IconButton>
              </Tooltip>

              <Tooltip title="Cancelar" placement="top">
                <IconButton type="reset" color="primary" aria-label="Cancelar" onClick={() => onCancelRegister()}>
                  <MdClear />
                </IconButton>
              </Tooltip>

              <Tooltip title="Salvar" placement="top">
                <IconButton type="submit" color="primary" aria-label="Confirmar">
                  <AiOutlineSave />
                </IconButton>
              </Tooltip>

              <Tooltip title="Primeiro Registro" placement="top">
                <IconButton color="primary" aria-label="Primeiro" onClick={() => onFirstRegister()}>
                  <AiFillFastBackward />
                </IconButton>
              </Tooltip>

              <Tooltip title="Registro Anterior" placement="top">
                <IconButton color="primary" aria-label="Anterior" onClick={() => onPriorRegister()}>
                  <MdSkipPrevious />
                </IconButton>
              </Tooltip>

              <Tooltip title="Próximo Registro" placement="top">
                <IconButton color="primary" aria-label="Próximo" onClick={() => onNextRegister()}>
                  <MdSkipNext />
                </IconButton>
              </Tooltip>

              <Tooltip title="Último Registro" placement="top">
                <IconButton color="primary" aria-label="Último registro" onClick={() => onLastRegister()}>
                  <AiFillFastForward />
                </IconButton>
              </Tooltip>
            </FormNavigate>

            <FormFields>
              <ContainerFieldCheckbox>
                <Checkbox name="active" />
                <span>Ativo</span>
              </ContainerFieldCheckbox>

              <ContainerAllMonthPrice>
                <ContainerMonthPrice>
                  <Checkbox
                    id="as65d4a6s5das56df"
                    name="admin"
                    onChange={(e: React.ChangeEvent<{ value: unknown }>) => {
                      const localValue = (e.target as HTMLInputElement).checked;
                      if (localValue) {
                        clearCheckbox('sdfsdf434987sdfg');
                      }
                    }}
                  />
                  <span>Administrador</span>
                </ContainerMonthPrice>
                <ContainerMonthPrice className="price2">
                  <Checkbox
                    id="sdfsdf434987sdfg"
                    name="viewer"
                    onChange={(e: React.ChangeEvent<{ value: unknown }>) => {
                      const localValue = (e.target as HTMLInputElement).checked;
                      if (localValue) {
                        clearCheckbox('as65d4a6s5das56df');
                      }
                    }}
                  />
                  <span>Visualizador</span>
                </ContainerMonthPrice>
              </ContainerAllMonthPrice>

              <ContainerFields field="T100">
                <span>E-mail:</span>
                <InputComum autoFocus name="email" />
              </ContainerFields>
              <ContainerFields field="T100" className="desabilitado">
                <span>Usuário:</span>
                <InputComum autoFocus name="name" disabled />
              </ContainerFields>

              <ContainerFields field="id">
                <InputComum name="id" hidden />
              </ContainerFields>
            </FormFields>
          </FormContainer>
        </Form>
      </TabContent>

      <FormModalDialogConfirm
        open={openDeleteSingle}
        onClose={() => setOpenDeleteSingle(false)}
        onConfirm={() => handleOnRowDeleteSingle()}
        buttonCancelText="Cancelar"
        buttonConfirmText="Sim"
        formTitle="Excluir?"
        messageConfirm="Tem certeza que deseja excluir?"
      />

      <FormModalDialogConfirm
        open={openDeleteMultiple}
        onClose={() => setOpenDeleteMultiple(false)}
        onConfirm={() => handleOnRowDeleteMultiple()}
        buttonCancelText="Cancelar"
        buttonConfirmText="Sim"
        formTitle="Excluir?"
        messageConfirm="Tem certeza que deseja excluir todos os registros selecionados?"
      />

      <Backdrop className={cssLoading.backdrop} open={openLoading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </Container>
  );
};

export default UsersLicense;
