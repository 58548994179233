import React, { useRef, useCallback, useState, useEffect } from 'react';
import { uuid } from 'uuidv4';

import Dialog from '@material-ui/core/Dialog';
import ButtonDialog from '@material-ui/core/Button';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

import api from '../../services/api';
import { useToast } from '../../hooks/Toast';
import { useAuth } from '../../hooks/Auth';

import IDemGerCadConta from './IDemGerCadConta';
import IDemGerCadContaCorrente from './IDemGerCadContaCorrente';
import IFinMovimentosFilterDownload from './IFinMovimentosFilterDownload';
import IFinMovimentosDownload from './IFinMovimentosDownload';

import {
  ContainerFields,
  ContainerFieldGoup,
  ContainerFilterSelect,
  ContainerFilterInput,
  TituloFiltro,
} from './styles';

const useStylesLoading = makeStyles((theme: Theme) =>
  createStyles({
    backdrop: {
      zIndex: theme.zIndex.drawer + 2010,
      color: '#fff',
    },
  }),
);

const options_tipo = [
  { value: '1-Receita', label: '1-Receita' },
  { value: '2-Despesa', label: '2-Despesa' },
  { value: '3-Transferência', label: '3-Transferência' },
  { value: '11-Receita Estimada', label: '11-Receita Estimada' },
  { value: '12-Despesa Estimada', label: '12-Despesa Estimada' },
];

const options_situacao = [
  { value: '1-Previsto', label: '1-Previsto' },
  { value: '2-Pendente', label: '2-Pendente' },
  { value: '3-Quitado', label: '3-Quitado' },
];

type functionVoid = () => void;

interface IModalUploadFileOFXProps {
  open: boolean;
  onClose: functionVoid;
}

const idFieldDownload = 'as54dI-UY-HG55b-a-csice';

const ModalDownloadContabilidade: React.FC<IModalUploadFileOFXProps> = ({ onClose, open }) => {
  const { user } = useAuth();
  const { addToast } = useToast();
  const formDownloadRef = useRef(null);
  const formFilterIdFieldSituacao = uuid();
  const formFilterIdFieldContaTipoLancamento = uuid();
  const formFilterIdFieldContaSistemaContábil = uuid();
  const formFilterIdFieldContaCorrente = uuid();
  const formFilterIdFieldContaFinanceira = uuid();
  const formFilterIdFieldDataInicio = uuid();
  const formFilterIdFieldDataFim = uuid();
  const formFilterIdFieldTipoDataPesquisa = uuid();
  const cssLoading = useStylesLoading();
  const [openLoading, setOpenLoading] = React.useState(false);
  const [listDemGerCadConta, setListDemGerCadConta] = useState<IDemGerCadConta[]>([]);
  const [listDemGerCadContaCorrente, setListDemGerCadContaCorrente] = useState<IDemGerCadContaCorrente[]>([]);

  const downloadTxtFile = useCallback((fileName: string, text: string): void => {
    const file = new Blob([text], { type: 'text/plain; charset=ISO-8859-1' });

    setOpenLoading(false);
    const fieldDownload = document.getElementById(idFieldDownload);
    if (fieldDownload) {
      const element: HTMLAnchorElement = fieldDownload as HTMLAnchorElement;
      element.href = URL.createObjectURL(file);
      element.download = fileName;
      element.click();
    }

    /*
      const element = document.createElement('a');
      element.href = URL.createObjectURL(file);
      element.download = fileName;
      document.body.appendChild(element);
      element.click(); */
  }, []);

  const replaceAll = useCallback((oldValue: string, newValue: string, text: string): string => {
    if (text === null || text === undefined) return '';

    const regExp = new RegExp(oldValue, 'g');
    return text.replace(regExp, newValue);
  }, []);

  const handleClose = useCallback(() => {
    if (onClose) {
      onClose();
    }
  }, [onClose]);

  const formatDateToJBLeaiute80 = useCallback((text: string): string => {
    const ano = text.substring(0, 4);
    const mes = text.substring(5, 7);
    const dia = text.substring(8, 10);

    const valor = `${dia}${mes}${ano}`;
    if (valor.trim() === '') return '';

    return `"${valor}"`;
  }, []);

  const formatTextToJBLeaiute80 = useCallback(
    (text: string): string => {
      const valor = `${replaceAll(';', ',', text)}`;
      if (valor.trim() === '') return '';

      return `"${valor.trim()}"`;
    },
    [replaceAll],
  );

  const formatNumberToJBLeaiute80 = useCallback((text: string): string => {
    if (text.trim() === '') return text;

    const valor = text.replace('.', ',');

    return valor;
  }, []);

  const finMovimentosToText = useCallback(
    (data: IFinMovimentosDownload[]): string => {
      let text = '';
      data.map(item => {
        const line = `${formatTextToJBLeaiute80(item.cnpjempresa)};${formatTextToJBLeaiute80(
          item.ieempresa,
        )};${formatTextToJBLeaiute80(item.origem)};${formatTextToJBLeaiute80(item.carteira)};${formatTextToJBLeaiute80(
          item.codmovimento,
        )};${formatTextToJBLeaiute80(item.modelodoc)};${formatTextToJBLeaiute80(
          item.especiedoc,
        )};${formatTextToJBLeaiute80(item.codbanco)};${formatTextToJBLeaiute80(
          item.fatogerador,
        )};${formatTextToJBLeaiute80(item.acao)};${formatTextToJBLeaiute80(item.nrolctoerp)};${replaceAll(
          ';',
          ',',
          item.codloteremessa,
        )};${replaceAll(';', ',', item.grupolcto)};${formatDateToJBLeaiute80(item.data)};${formatDateToJBLeaiute80(
          item.datavencimento,
        )};${formatDateToJBLeaiute80(item.datacontabil)};${replaceAll(
          ';',
          ',',
          item.numerodoc,
        )};${formatTextToJBLeaiute80(item.seriedoc)};${formatTextToJBLeaiute80(item.nroparcela)};${replaceAll(
          ';',
          ',',
          item.codccusto,
        )};${replaceAll(';', ',', item.codhistorico)};${formatTextToJBLeaiute80(
          item.complhistorico,
        )};${formatNumberToJBLeaiute80(item.vlrprincipal)};${formatNumberToJBLeaiute80(
          item.vlrliquido,
        )};${formatNumberToJBLeaiute80(item.vlrjuros)};${formatNumberToJBLeaiute80(
          item.vlrdesconto,
        )};${formatNumberToJBLeaiute80(item.vrlacrecimo)};${formatNumberToJBLeaiute80(
          item.vlrabatimento,
        )};${formatNumberToJBLeaiute80(item.vlrestorno)};${formatNumberToJBLeaiute80(
          item.vlrreembolso,
        )};${formatNumberToJBLeaiute80(item.vlrtarifa)};${formatNumberToJBLeaiute80(
          item.vlrmulta,
        )};${formatNumberToJBLeaiute80(item.vlrdespesa)};${formatNumberToJBLeaiute80(
          item.vlriof,
        )};${formatNumberToJBLeaiute80(item.vlrvarcambial)};${formatNumberToJBLeaiute80(
          item.vlroutros,
        )};${formatNumberToJBLeaiute80(item.vlrfrete)};${formatNumberToJBLeaiute80(
          item.vlrsestsenat,
        )};${formatNumberToJBLeaiute80(item.vlrseguro)};${formatNumberToJBLeaiute80(
          item.vlrpedagio,
        )};${formatNumberToJBLeaiute80(item.vlradiantamento)};${formatNumberToJBLeaiute80(
          item.vlrvarcambialpassiva,
        )};${formatNumberToJBLeaiute80(item.vlrjurosprefixado)};${formatNumberToJBLeaiute80(
          item.vlroutros001,
        )};${formatNumberToJBLeaiute80(item.vlroutros002)};${formatNumberToJBLeaiute80(
          item.vlroutros003,
        )};${formatNumberToJBLeaiute80(item.vlroutros004)};${formatNumberToJBLeaiute80(
          item.vlroutros005,
        )};${formatNumberToJBLeaiute80(item.vlroutros006)};${formatNumberToJBLeaiute80(
          item.vlroutros007,
        )};${formatNumberToJBLeaiute80(item.vlroutros008)};${formatNumberToJBLeaiute80(
          item.vlroutros009,
        )};${formatNumberToJBLeaiute80(item.vlroutros010)};${formatNumberToJBLeaiute80(
          item.vlrinssempresa,
        )};${formatNumberToJBLeaiute80(item.vlrpis)};${formatNumberToJBLeaiute80(
          item.vlrcofins,
        )};${formatNumberToJBLeaiute80(item.vlrissqn)};${formatNumberToJBLeaiute80(
          item.vlrirpj,
        )};${formatNumberToJBLeaiute80(item.vlrcsll)};${formatNumberToJBLeaiute80(
          item.vlrretpis,
        )};${formatNumberToJBLeaiute80(item.vlrretcofins)};${formatNumberToJBLeaiute80(
          item.vlrretcsll,
        )};${formatNumberToJBLeaiute80(item.vlrretagrupado)};${formatNumberToJBLeaiute80(
          item.vlrretissqn,
        )};${formatNumberToJBLeaiute80(item.vlrretinss)};${formatNumberToJBLeaiute80(
          item.vlrretirrf,
        )};${formatTextToJBLeaiute80(item.codrecpis)};${formatTextToJBLeaiute80(
          item.codreccofins,
        )};${formatTextToJBLeaiute80(item.codreccsll)};${formatTextToJBLeaiute80(
          item.codrecagrupado,
        )};${formatTextToJBLeaiute80(item.codrecissqn)};${formatTextToJBLeaiute80(
          item.codrecinss,
        )};${formatTextToJBLeaiute80(item.codrecirrf)};${formatTextToJBLeaiute80(
          item.cnpjcliente,
        )};${formatTextToJBLeaiute80(item.iecliente)};${formatTextToJBLeaiute80(
          item.nomecliente,
        )};${formatTextToJBLeaiute80(item.cnpjfornecedor)};${formatTextToJBLeaiute80(
          item.iefornecedor,
        )};${formatTextToJBLeaiute80(item.nomefornecedor)};${formatTextToJBLeaiute80(item.observacoes)};\n`;

        text = `${text}${line}`;
        return line;
      });

      return text;
    },
    [replaceAll, formatDateToJBLeaiute80, formatTextToJBLeaiute80, formatNumberToJBLeaiute80],
  );

  const handleDownload = useCallback(async () => {
    let id_tipo = '';
    let id_situacao = '';
    let tipoDataPesquisa = '';
    let dt_inicio = '';
    let dt_fim = '';
    let idContaCorrente = '';
    let idContaFinanceira = '';
    let sistemaContabil = '';

    const fieldInputDataInicio = document.getElementById(formFilterIdFieldDataInicio);
    if (fieldInputDataInicio) {
      dt_inicio = (fieldInputDataInicio as HTMLInputElement).value;
    }

    const fieldInputDataFim = document.getElementById(formFilterIdFieldDataFim);
    if (fieldInputDataFim) {
      dt_fim = (fieldInputDataFim as HTMLInputElement).value;
    }

    let filtroInvalido = false;
    if (new Date(dt_inicio).toString() === 'Invalid Date') {
      addToast({
        type: 'error',
        title: 'Data início',
        description: 'Data início é obigatória',
      });

      filtroInvalido = true;
    }

    if (new Date(dt_fim).toString() === 'Invalid Date') {
      addToast({
        type: 'error',
        title: 'Data fim',
        description: 'Data fim é obigatória',
      });

      filtroInvalido = true;
    }

    if (filtroInvalido) {
      return;
    }

    setOpenLoading(true);
    const fieldSelectTipo = document.getElementById(formFilterIdFieldContaTipoLancamento);
    if (fieldSelectTipo) {
      id_tipo = (fieldSelectTipo as HTMLSelectElement).value;
    }

    const fieldSelectSituacao = document.getElementById(formFilterIdFieldSituacao);
    if (fieldSelectSituacao) {
      id_situacao = (fieldSelectSituacao as HTMLSelectElement).value;
    }

    const fieldSelectContaCorrente = document.getElementById(formFilterIdFieldContaCorrente);
    if (fieldSelectContaCorrente) {
      idContaCorrente = (fieldSelectContaCorrente as HTMLSelectElement).value;
    }

    const fieldSelectContaFinanceira = document.getElementById(formFilterIdFieldContaFinanceira);
    if (fieldSelectContaFinanceira) {
      idContaFinanceira = (fieldSelectContaFinanceira as HTMLSelectElement).value;
    }

    const fieldSelectTipoDataPesquisa = document.getElementById(formFilterIdFieldTipoDataPesquisa);
    if (fieldSelectTipoDataPesquisa) {
      tipoDataPesquisa = (fieldSelectTipoDataPesquisa as HTMLInputElement).value;
    }

    const fieldSelectSistemaContabil = document.getElementById(formFilterIdFieldContaSistemaContábil);
    if (fieldSelectSistemaContabil) {
      sistemaContabil = (fieldSelectSistemaContabil as HTMLInputElement).value;
    }

    const formData: IFinMovimentosFilterDownload = {
      id_license: user.license.id,
      id_company: user.empresa.id,
      situacao: id_situacao,
      tipo_lancamento: id_tipo,
      data_inicio: `${dt_inicio.substring(0, 10)}T12:00:00`,
      data_fim: `${dt_fim.substring(0, 10)}T12:00:00`,
      sistema_contabil: sistemaContabil,
      tipo_data_pesquisa: tipoDataPesquisa,
      idContaCorrente,
      idContaFinanceira,
    };

    const response = await api.post<IFinMovimentosDownload[]>(
      `/finmovimentos/download/${formData.id_license}`,
      formData,
    );

    const text = finMovimentosToText(response.data);

    const fileName = `${user.empresa.name.trim()}-${formData.sistema_contabil.trim()}-${formData.data_inicio
      .substring(0, 10)
      .trim()}-${formData.data_fim.substring(0, 10).trim()}.txt`;

    downloadTxtFile(fileName, text);
  }, [
    addToast,
    user,
    formFilterIdFieldDataInicio,
    formFilterIdFieldDataFim,
    formFilterIdFieldSituacao,
    formFilterIdFieldContaTipoLancamento,
    formFilterIdFieldContaSistemaContábil,
    formFilterIdFieldContaCorrente,
    formFilterIdFieldContaFinanceira,
    formFilterIdFieldTipoDataPesquisa,
    downloadTxtFile,
    finMovimentosToText,
  ]);

  const getDemGerCadConta = useCallback(async () => {
    const response = await api.get<IDemGerCadConta[]>(`/demgercadconta`, {
      params: {
        id_license: user.license.id,
        id_company: user.empresa.id,
        ind_cta: '2-Analítico',
        ativa: true,
      },
    });

    setListDemGerCadConta(response.data);
  }, [user]);

  const getDemGerCadContaCorrente = useCallback(async () => {
    const response = await api.post<IDemGerCadContaCorrente[]>(`/demgercadcontacorrente/filter`, {
      id_license: user.license.id,
      id_company: user.empresa.id,
      ativa: true,
    });

    setListDemGerCadContaCorrente(response.data);
  }, [user]);

  useEffect(() => {
    if (user.license === undefined) return;
    if (user.empresa === undefined) return;

    getDemGerCadConta();
    getDemGerCadContaCorrente();
  }, [getDemGerCadConta, getDemGerCadContaCorrente, user.license, user.empresa]);

  const defaultFilterDataInicio = (): string => {
    const data = new Date();
    const mes = data.getMonth() + 1;
    const ano = data.getFullYear();
    const mesText: string = mes < 10 ? `0${mes}` : `${mes}`;
    return `${ano}-${mesText}-01`;
  };

  const getLastDay = (y: number, m: number): number => {
    return new Date(y, m, 0).getDate();
  };

  const defaultFilterDataFim = (): string => {
    const data = new Date();
    const mes = data.getMonth() + 1;
    const ano = data.getFullYear();
    const dia = getLastDay(ano, mes);
    const mesText: string = mes < 10 ? `0${mes}` : `${mes}`;
    return `${ano}-${mesText}-${dia}`;
  };

  return (
    <>
      <Dialog ref={formDownloadRef} open={open} onClose={handleClose}>
        <TituloFiltro id="alert-dialog-title">Filtro</TituloFiltro>
        <DialogContent>
          <ContainerFieldGoup>
            <ContainerFields field="T250px" filter_field>
              <span>Tipo Lançamento:</span>
              <ContainerFilterSelect>
                <select id={formFilterIdFieldContaTipoLancamento} defaultValue="99-Todos">
                  {options_tipo.map(itemTipo => (
                    <option key={itemTipo.value} value={itemTipo.value}>
                      {itemTipo.label}
                    </option>
                  ))}

                  <option key="99-Todos" value="99-Todos">
                    99-Todos
                  </option>
                </select>
              </ContainerFilterSelect>
            </ContainerFields>

            <ContainerFields field="T250px" filter_field>
              <span>Situação:</span>
              <ContainerFilterSelect>
                <select id={formFilterIdFieldSituacao} defaultValue="9-Todos">
                  {options_situacao.map(itemTipo => (
                    <option key={itemTipo.value} value={itemTipo.value}>
                      {itemTipo.label}
                    </option>
                  ))}

                  <option key="4-Estimada" value="4-Estimada">
                    4-Estimada
                  </option>
                  <option key="5-Transferência Realizada" value="5-Transferência Realizada">
                    5-Transferência Realizada
                  </option>
                  <option key="9-Todos" value="9-Todos">
                    9-Todos
                  </option>
                </select>
              </ContainerFilterSelect>
            </ContainerFields>
          </ContainerFieldGoup>

          <ContainerFields field="T100" filter_field>
            <span>Conta Corrente:</span>
            <ContainerFilterSelect>
              <select id={formFilterIdFieldContaCorrente} defaultValue="9-Todos">
                {listDemGerCadContaCorrente.map(itemContaCorrente => (
                  <option key={itemContaCorrente.id} value={itemContaCorrente.id}>
                    {itemContaCorrente.descricao_banco.trim()}-{itemContaCorrente.conta_corrente.trim()}-
                    {itemContaCorrente.descricao_conta.trim()}
                  </option>
                ))}
                <option key="9-Todos" value="9-Todos">
                  9-Todos
                </option>
              </select>
            </ContainerFilterSelect>
          </ContainerFields>

          <ContainerFields field="T100" filter_field>
            <span>Conta Financeira:</span>
            <ContainerFilterSelect>
              <select id={formFilterIdFieldContaFinanceira} defaultValue="9-Todos">
                {listDemGerCadConta.map(itemConta => (
                  <option key={itemConta.id} value={itemConta.id}>
                    {itemConta.cod_cta.trim()}-{itemConta.nome_cta.trim()}
                  </option>
                ))}
                <option key="9-Todos" value="9-Todos">
                  9-Todos
                </option>
              </select>
            </ContainerFilterSelect>
          </ContainerFields>

          <ContainerFieldGoup>
            <ContainerFields field="T250px" filter_field>
              <span>Data Inicio:</span>
              <ContainerFilterInput>
                <input type="date" id={formFilterIdFieldDataInicio} required defaultValue={defaultFilterDataInicio()} />
              </ContainerFilterInput>
            </ContainerFields>

            <ContainerFields field="T250px" className="field2filter" filter_field>
              <span>Data Fim:</span>
              <ContainerFilterInput>
                <input type="date" id={formFilterIdFieldDataFim} required defaultValue={defaultFilterDataFim()} />
              </ContainerFilterInput>
            </ContainerFields>

            <ContainerFields field="T250px" className="field2filter" filter_field>
              <span>Tipo Data pesquisa:</span>
              <ContainerFilterSelect>
                <select id={formFilterIdFieldTipoDataPesquisa} defaultValue="1-Data Competência">
                  <option key="1-Data Competência" value="1-Data Competência">
                    1-Data Competência
                  </option>
                  <option key="2-Data Vencimento" value="2-Data Vencimento">
                    2-Data Vencimento
                  </option>
                  <option key="3-Data Quitação" value="3-Data Quitação">
                    3-Data Quitação
                  </option>
                </select>
              </ContainerFilterSelect>
            </ContainerFields>
          </ContainerFieldGoup>

          <ContainerFields field="T100" filter_field>
            <span>Sistema Contábil:</span>
            <ContainerFilterSelect>
              <select id={formFilterIdFieldContaSistemaContábil} defaultValue="1-JBSoftware Layout 80">
                <option key="1-JBSoftware Layout 80" value="1-JBSoftware Layout 80">
                  1-JBSoftware Layout 80
                </option>
              </select>
            </ContainerFilterSelect>
          </ContainerFields>
        </DialogContent>
        <a href="#/" id={idFieldDownload} hidden>
          .
        </a>

        <DialogActions>
          <ButtonDialog onClick={handleClose} color="secondary" autoFocus variant="contained">
            Cancelar
          </ButtonDialog>
          <ButtonDialog onClick={handleDownload} color="primary" variant="contained">
            Download
          </ButtonDialog>
        </DialogActions>
      </Dialog>

      <Backdrop className={cssLoading.backdrop} open={openLoading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
};

export default ModalDownloadContabilidade;
