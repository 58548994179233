import styled from 'styled-components';

export const Container = styled.div`
  background: #fff;
  padding: 4px;
  width: 30px;
  color: #666360;

  & + div {
    margin-top: 8px;
  }

  input {
    flex: 1;
    background: transparent;
    border: 0;
    color: #000;
    height: 20px;
    width: 20px;

    &::placeholder {
      color: #989898;
    }
  }
`;
