import React, { useRef, useState, useEffect, useCallback } from 'react';
import * as Yup from 'yup';

import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';

import { DataGrid, GridColDef, GridCellParams, GridRowId, ptBR } from '@material-ui/data-grid';
import { createMuiTheme, ThemeProvider, makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import ButtonDialog from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';

import { FiCopy } from 'react-icons/fi';
import { MdDelete, MdEdit, MdClear, MdSkipNext, MdSkipPrevious, MdContentCopy } from 'react-icons/md';
import {
  AiOutlineSearch,
  AiOutlinePlus,
  AiOutlineSave,
  AiFillFastBackward,
  AiFillFastForward,
  AiOutlineCaretDown,
  AiOutlineCaretUp,
} from 'react-icons/ai';
import { GoPlus } from 'react-icons/go';
import { RiDeleteBinLine } from 'react-icons/ri';

import InputComum from '../../components/InputComum';
import InputID from '../../components/InputID';
import InputDate from '../../components/InputDate';
import Select from '../../components/Select';
import ModalUploadFile from '../../components/ModalUploadFile';
import Header from '../../components/Header';
import FormModalDialogConfirm from '../../components/FormModalDialogConfirm';

import api from '../../services/api';
import { useAuth } from '../../hooks/Auth';
import { useToast } from '../../hooks/Toast';

import getListUpdate from '../../utils/getListUpdate';
import getValidationErrors from '../../utils/getValidationErrors';
import { getPathGrid } from '../../utils/storagePath';

import {
  ColumnDemonstrativo,
  ColumnConta,
  ColumnCentroCusto,
  ColumnValorAjuste,
  ColumnHistorico,
  ColumnTipoAjuste,
  ColumnId,
} from './GridColumns';

import ITipoDemGer from './ITipoDemGer';
import IDemGerLctos from './IDemGerLctos';

import {
  Container,
  ContainerFields,
  Tab,
  TabButton,
  TabContent,
  FormFields,
  FormContainer,
  FormNavigate,
  TabTitulo,
  TabButtonArea,
  TabButtonNovo,
  TabButtonDelete,
  TabButtonGridRowHeight,
  ContainerFieldGroup,
  TituloFiltro,
  SpanFiltro,
  ContainerFilterInput,
} from './styles';

const useStylesLoading = makeStyles((theme: Theme) =>
  createStyles({
    backdrop: {
      zIndex: theme.zIndex.drawer + 2000,
      color: '#fff',
    },
  }),
);

const theme = createMuiTheme(
  {
    palette: {
      primary: { main: '#006699' },
    },
  },
  ptBR,
);

const options_tp_lcto = [
  { value: '1-Débito', label: 'Débito' },
  { value: '-1-Crédito', label: 'Crédito' },
];

const options_tp_ajuste = [
  { value: '00-Ajuste Gerencial', label: '00-Ajuste Gerencial' },
  { value: '10-Rateio de Receitas', label: '10-Rateio de Receitas' },
  { value: '20-Rateio de Impostos', label: '20-Rateio de Impostos' },
  { value: '30-Rateio de Custos Fixos', label: '30-Rateio de Custos Fixos' },
  {
    value: '40-Rateio de Custos Variáveis',
    label: '40-Rateio de Custos Variáveis',
  },
  {
    value: '50-Rateio de Despesas Fixas',
    label: '50-Rateio de Despesas Fixas',
  },
  {
    value: '60-Rateio de Despesas Variáveis',
    label: '60-Rateio de Despesas Variáveis',
  },
  { value: '70-Ajuste entre Contas', label: '70-Ajuste entre Contas' },
  {
    value: '80-Ajuste entre Estabelecimentos',
    label: '80-Ajuste entre Estabelecimentos',
  },
  { value: '90-Provisões', label: '90-Provisões' },
  {
    value: '99-Outros Ajustes Gerenciais',
    label: '99-Outros Ajustes Gerenciais',
  },
];

const emptyDemGerLctos: IDemGerLctos = {
  acao: '',
  id: '',
  id_tipo_dem_ger: '',
  cnpj_estab_0000: '',
  data: '',
  cod_cta_i050: '',
  cod_ccusto_i100: '',
  tp_lcto: '1-Débito',
  tp_ajuste: '00-Ajuste Gerencial',
  valor_ajuste: 0,
  valor_orcado: 0,
  historico: '',
  demonstrativo: '',
};

interface IFilter {
  data_inicio?: Date;
  data_fim?: Date;
}

const idfieldSelect = 'nibkju23reiuhfe0u9nj8cr';
const fieldFilterDataInicio = '1861267f10e92453f1fd8495c61ed94';
const fieldFilterDataFim = 'fced4d843aee1682a79c157743a9496';
const titulo = 'A J U S T E S     G E R E N C I A I S';
const localStorageIdFilter = '@AssessortechWEBCli:filter_412637b102cf6dc1e9acd306e733b00';

const DemGerLctos: React.FC = () => {
  const { user } = useAuth();
  const { addToast } = useToast();

  const formRef = useRef<FormHandles>(null);
  const [listTipoDemGer, setListTipoDemGer] = useState<ITipoDemGer[]>([]);
  const [demGerLctos, setDemGerLctos] = useState<IDemGerLctos>();
  const [listDemGerLctos, setListDemGerLctos] = useState<IDemGerLctos[]>([]);
  const [hiddenPesquisar, setHiddenPesquisar] = useState<boolean>(true);
  const [hiddenCadastrar, setHiddenCadastrar] = useState<boolean>(true);
  const [demGerLctosIndex, setDemGerLctosIndex] = useState<number>(0);
  const [rowToEdit, setRowToEdit] = useState(null);
  const [idDeleteDemGerLctos, setIdDeleteDemGerLctos] = useState<string>('');
  const [selectedIds, setSelectedIds] = useState<GridRowId[]>([]);
  const [openDeleteSingle, setOpenDeleteSingle] = useState(false);
  const [openDeleteMultiple, setOpenDeleteMultiple] = useState(false);
  const [openLoading, setOpenLoading] = useState(false);
  const cssLoading = useStylesLoading();
  const [gridRowHeight, setGridRowHeight] = useState<number>(46);
  const [openUploadFile, setOpenUploadFile] = useState<boolean>(false);
  const formFilterRef = useRef(null);
  const [openFilter, setOpenFilter] = React.useState(true);
  const [textFiltro, setTextFiltro] = useState<string>('');

  const handleGridRowHeightUp = useCallback(async () => {
    const heightValue: number = gridRowHeight + 1;
    setGridRowHeight(heightValue);
    localStorage.setItem(getPathGrid(), heightValue.toString());
  }, [gridRowHeight]);

  const handleGridRowHeightDown = useCallback(async () => {
    const heightValue: number = gridRowHeight - 1;
    setGridRowHeight(heightValue);
    localStorage.setItem(getPathGrid(), heightValue.toString());
  }, [gridRowHeight]);

  const licencaInvalida = useCallback((): boolean => {
    if (!user.license || user.license.id === '') {
      addToast({
        type: 'error',
        title: 'Licença inválida',
        description: 'Usuário não está vinculado a nenhuma licença',
      });

      return true;
    }

    return false;
  }, [addToast, user]);

  const defineDemGerLctos = useCallback(async (data: IDemGerLctos) => {
    setDemGerLctos(data);
    formRef.current?.setData(data);
  }, []);

  const onNewRegister = useCallback(async () => {
    defineDemGerLctos(emptyDemGerLctos);
  }, [defineDemGerLctos]);

  const OnGridSetRegisterToEdit = useCallback(
    async (params: GridCellParams) => {
      const localDemGerLctos = params.row as IDemGerLctos;

      setDemGerLctosIndex(listDemGerLctos.indexOf(localDemGerLctos));
      setHiddenCadastrar(false);
      setHiddenPesquisar(true);
      setRowToEdit(null);
      defineDemGerLctos(localDemGerLctos);
    },
    [listDemGerLctos, defineDemGerLctos],
  );

  const OnSetRegisterToAdd = useCallback(async () => {
    onNewRegister();
    setHiddenCadastrar(false);
    setHiddenPesquisar(true);
  }, [onNewRegister]);

  const OnGridRowDuplicate = useCallback(
    async (baseDemGerLctos: IDemGerLctos | undefined) => {
      if (baseDemGerLctos !== undefined) {
        const newDemGerLctos: IDemGerLctos = {
          acao: baseDemGerLctos.acao,
          id: '',
          cnpj_estab_0000: user.empresa.cnpj,
          id_tipo_dem_ger: baseDemGerLctos.id_tipo_dem_ger,
          data: baseDemGerLctos.data,
          cod_cta_i050: baseDemGerLctos.cod_cta_i050,
          cod_ccusto_i100: baseDemGerLctos.cod_ccusto_i100,
          tp_lcto: baseDemGerLctos.tp_lcto,
          tp_ajuste: baseDemGerLctos.tp_ajuste,
          valor_ajuste: baseDemGerLctos.valor_ajuste,
          valor_orcado: baseDemGerLctos.valor_orcado,
          historico: baseDemGerLctos.historico,
          demonstrativo: baseDemGerLctos.demonstrativo,
        };

        defineDemGerLctos(newDemGerLctos);
        setHiddenCadastrar(false);
        setHiddenPesquisar(true);
      }
    },
    [defineDemGerLctos, user],
  );

  const OnConfirmationDeleteSingle = useCallback(
    async (id: string) => {
      if (licencaInvalida()) return;

      if (id === '') return;

      setIdDeleteDemGerLctos(id);
      setOpenDeleteSingle(true);
    },
    [licencaInvalida],
  );

  const colunaAcao: GridColDef = {
    field: 'acao',
    headerName: 'Ação',
    editable: false,
    filterable: false,
    hideSortIcons: true,
    sortable: false,
    disableColumnMenu: true,
    width: 160,
    renderCell: (params: GridCellParams) => (
      <strong>
        <Tooltip title="Editar Registro" placement="top">
          <IconButton color="primary" aria-label="Editar" onClick={() => OnGridSetRegisterToEdit(params)}>
            <MdEdit />
          </IconButton>
        </Tooltip>

        <Tooltip title="Duplicar Registro" placement="top">
          <IconButton
            color="primary"
            aria-label="Duplicar"
            onClick={() => OnGridRowDuplicate(params.row as IDemGerLctos)}
          >
            <FiCopy />
          </IconButton>
        </Tooltip>

        <Tooltip title="Excluir Registro" placement="top">
          <IconButton
            color="primary"
            aria-label="Excluir"
            onClick={() => OnConfirmationDeleteSingle((params.row as IDemGerLctos).id)}
          >
            <MdDelete />
          </IconButton>
        </Tooltip>
      </strong>
    ),
  };

  const ColumnData: GridColDef = {
    headerName: 'Data',
    field: 'data',
    width: 150,
    type: 'date',
    renderCell: (params: GridCellParams) => {
      if (params.value === null) return <strong />;
      const ano = (params.value as string).substring(0, 4);
      const mes = (params.value as string).substring(5, 7);
      const dia = (params.value as string).substring(8, 10);

      const dataFormatada = `${dia}/${mes}/${ano}`;
      return <strong>{dataFormatada}</strong>;
    },
  };

  const ColumnTipoLancamento: GridColDef = {
    headerName: 'Tipo Lançamento',
    field: 'tp_lcto',
    width: 200,

    renderCell: (params: GridCellParams) => {
      if (params.value === '1-Débito') {
        return <strong>Débito</strong>;
      }
      return <strong>Crédito</strong>;
    },
  };

  const columns: GridColDef[] = [
    colunaAcao,
    ColumnDemonstrativo,
    ColumnData,
    ColumnConta,
    ColumnCentroCusto,
    ColumnTipoLancamento,
    ColumnValorAjuste,
    ColumnTipoAjuste,
    ColumnHistorico,
    ColumnId,
  ];

  const getTipoDemGer = useCallback(async () => {
    if (licencaInvalida()) return;

    const response = await api.get<ITipoDemGer[]>(`/tipodemger/all/company/${user.empresa.id}`);

    setListTipoDemGer(response.data);
  }, [user, licencaInvalida]);

  const getDemGerLctos = useCallback(
    async (filtro: IFilter) => {
      if (licencaInvalida()) return;

      const response = await api.get<IDemGerLctos[]>(`/demgerlctos/${user.empresa.cnpj}`, {
        params: {
          data_inicio: filtro.data_inicio,
          data_fim: filtro.data_fim,
        },
      });

      setListDemGerLctos(response.data);

      if (rowToEdit !== null) {
        const findEditDemGerLctos = response.data.find(item => item.id === rowToEdit);

        if (findEditDemGerLctos) {
          defineDemGerLctos(findEditDemGerLctos);
          setDemGerLctosIndex(response.data.indexOf(findEditDemGerLctos));
          setHiddenCadastrar(false);
          setHiddenPesquisar(true);
          setRowToEdit(null);
        }
      }
      setHiddenPesquisar(false);
      setOpenFilter(false);
    },
    [user, licencaInvalida, rowToEdit, defineDemGerLctos],
  );

  const getValues = useCallback(async () => {
    setOpenLoading(true);
    await getTipoDemGer();
    setOpenLoading(false);
  }, [getTipoDemGer]);

  useEffect(() => {
    getValues();

    const rowHeight = localStorage.getItem(getPathGrid());
    if (rowHeight) {
      setGridRowHeight(parseInt(rowHeight, 10));
    }
  }, [getValues]);

  const onUploadFileClose = useCallback(() => {
    getValues();
    setOpenUploadFile(false);
  }, [getValues]);

  const getDescricaoTipoDemGerSelectOption = useCallback((value: ITipoDemGer): string => {
    if (!value) return '';

    return value.descricao;
  }, []);

  const getTipoDemGerSelectOption = useCallback(
    (id: string): ITipoDemGer => {
      return listTipoDemGer.filter(item => item.id === id)[0];
    },
    [listTipoDemGer],
  );

  const handleOnRowDeleteSingle = useCallback(async () => {
    setOpenLoading(true);
    await api.delete(`/demgerlctos/${idDeleteDemGerLctos}`);

    setListDemGerLctos(oldDemGerLctos => oldDemGerLctos.filter(item => item.id !== idDeleteDemGerLctos));

    defineDemGerLctos(emptyDemGerLctos);

    addToast({
      type: 'success',
      title: 'Removido',
      description: 'Registro excluído com sucesso!',
    });

    setOpenDeleteSingle(false);
    setOpenLoading(false);
  }, [idDeleteDemGerLctos, addToast, defineDemGerLctos]);

  const handleOnRowDeleteMultiple = useCallback(async () => {
    setOpenLoading(true);
    if (selectedIds.length > 0) {
      await Promise.all(
        selectedIds.map(itemId => {
          setListDemGerLctos(oldDemGerLctos => oldDemGerLctos.filter(item => item.id !== itemId));

          return api.delete(`/demgerlctos/${itemId}`);
        }),
      );

      defineDemGerLctos(emptyDemGerLctos);

      addToast({
        type: 'success',
        title: 'Removido',
        description: 'Registro excluído com sucesso!',
      });
    }

    setSelectedIds([]);
    setOpenDeleteMultiple(false);
    setOpenLoading(false);
  }, [addToast, defineDemGerLctos, selectedIds]);

  const handleClick = (id: string): void => {
    if (id === 'filtro') {
      setOpenFilter(id === 'filtro');
      return;
    }

    setHiddenCadastrar(id === 'pesquisar');
    setHiddenPesquisar(id === 'cadastrar');
  };

  const handleCloseFilter = useCallback(() => {
    setOpenFilter(false);
  }, []);

  const onNextRegister = useCallback(async () => {
    if (demGerLctosIndex !== undefined) {
      if (demGerLctosIndex < listDemGerLctos.length - 1) {
        const localIndex = demGerLctosIndex + 1;
        setDemGerLctosIndex(localIndex);
        const findDemGerLctos = listDemGerLctos[localIndex];
        if (findDemGerLctos) {
          defineDemGerLctos(findDemGerLctos);
        }
      }
    }
  }, [demGerLctosIndex, listDemGerLctos, defineDemGerLctos]);

  const onFirstRegister = useCallback(async () => {
    if (listDemGerLctos.length > 0) {
      setDemGerLctosIndex(0);
      const findDemGerLctos = listDemGerLctos[0];
      if (findDemGerLctos) {
        defineDemGerLctos(findDemGerLctos);
      }
    }
  }, [listDemGerLctos, defineDemGerLctos]);

  const onLastRegister = useCallback(async () => {
    if (listDemGerLctos.length > 0) {
      setDemGerLctosIndex(listDemGerLctos.length - 1);
      const findDemGerLctos = listDemGerLctos[listDemGerLctos.length - 1];
      if (findDemGerLctos) {
        defineDemGerLctos(findDemGerLctos);
      }
    }
  }, [listDemGerLctos, defineDemGerLctos]);

  const onPriorRegister = useCallback(async () => {
    if (demGerLctosIndex !== undefined) {
      if (demGerLctosIndex > 0) {
        const localIndex = demGerLctosIndex - 1;
        setDemGerLctosIndex(localIndex);
        const findDemGerLctos = listDemGerLctos[localIndex];
        if (findDemGerLctos) {
          defineDemGerLctos(findDemGerLctos);
        }
      }
    }
  }, [demGerLctosIndex, listDemGerLctos, defineDemGerLctos]);

  const onCancelRegister = useCallback(async () => {
    formRef.current?.setErrors({});

    if (demGerLctosIndex !== undefined) {
      const findDemGerLctos = listDemGerLctos[demGerLctosIndex];
      if (findDemGerLctos) {
        defineDemGerLctos(findDemGerLctos);
      }
    }

    onNewRegister();
  }, [demGerLctosIndex, listDemGerLctos, onNewRegister, defineDemGerLctos]);

  const handleSubmit = useCallback(
    async (data: IDemGerLctos, { reset }) => {
      if (licencaInvalida()) return;

      const fieldSelect = document.getElementById(idfieldSelect);
      if (fieldSelect) {
        const id = (fieldSelect as HTMLSelectElement).value;
        data.id_tipo_dem_ger = id;
        data.demonstrativo = getDescricaoTipoDemGerSelectOption(getTipoDemGerSelectOption(id));
      }

      formRef.current?.setErrors({});
      try {
        const schema = Yup.object().shape({
          id_tipo_dem_ger: Yup.string().required('Demonstrativo é obrigatório'),
          cod_cta_i050: Yup.string().required('Código da conta é obrigatório'),
          historico: Yup.string().trim().required('Histórico é obrigatório'),
          data: Yup.date().label('Data inválida').required('Data é obrigatória'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        setOpenLoading(true);
        if (data.id === '') {
          const formData = {
            id_license: user.license.id,
            cnpj_estab_0000: user.empresa.cnpj,
            id_tipo_dem_ger: data.id_tipo_dem_ger,
            cod_cta_i050: data.cod_cta_i050,
            cod_ccusto_i100: data.cod_ccusto_i100,
            data: data.data,
            tp_lcto: data.tp_lcto,
            tp_ajuste: data.tp_ajuste,
            valor_ajuste: data.valor_ajuste,
            valor_orcado: data.valor_orcado,
            historico: data.historico,
          };

          await api.post(`/demgerlctos`, formData);
          setListDemGerLctos(oldLicenses => [...oldLicenses, data]);

          addToast({
            type: 'success',
            title: 'Adicionado',
            description: 'Registro adicionado com sucesso!',
          });
        } else {
          await api.put(`/demgerlctos/${data.id}`, data);
          data.demonstrativo = getDescricaoTipoDemGerSelectOption(getTipoDemGerSelectOption(data.id_tipo_dem_ger));
          setListDemGerLctos(getListUpdate(listDemGerLctos, data));

          addToast({
            type: 'success',
            title: 'Alterado',
            description: 'Registro alterado com sucesso!',
          });
        }

        reset();
        setOpenLoading(false);
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          formRef.current?.setErrors(errors);
        }

        setOpenLoading(false);
      }
    },
    [licencaInvalida, listDemGerLctos, user, addToast, getDescricaoTipoDemGerSelectOption, getTipoDemGerSelectOption],
  );

  const defaultFilterDataInicio = (): string => {
    const filterValue = localStorage.getItem(localStorageIdFilter);
    if (filterValue && JSON.parse(filterValue).data_inicio) {
      return (JSON.parse(filterValue).data_inicio as string).substring(0, 10);
    }

    const data = new Date();
    let mes = data.getMonth();
    let ano = data.getFullYear();

    const mesRest = mes - 5;

    if (mesRest < 0) {
      ano -= 1;
      mes = 11 - mesRest;
    } else {
      mes -= 5;
    }
    const mesText: string = mes < 10 ? `0${mes}` : `${mes}`;
    return `${ano}-${mesText}-01`;
  };

  const getLastDay = (y: number, m: number): number => {
    return new Date(y, m, 0).getDate();
  };

  const defaultFilterDataFim = (): string => {
    const filterValue = localStorage.getItem(localStorageIdFilter);
    if (filterValue && JSON.parse(filterValue).data_fim) {
      return (JSON.parse(filterValue).data_fim as string).substring(0, 10);
    }

    const data = new Date();
    let mes = data.getMonth() + 1;
    let ano = data.getFullYear();

    const mesRest = mes + 6;
    if (mesRest > 12) {
      ano += 1;
      mes = mesRest - 12;
    } else {
      mes += 6;
      if (mes === 13) mes = 1;
    }

    const dia = getLastDay(ano, mes);
    const mesText: string = mes < 10 ? `0${mes}` : `${mes}`;
    return `${ano}-${mesText}-${dia}`;
  };

  const formatedDateFilter = (inDate: string): string => {
    const tmpValue = inDate.split('-');
    return `${tmpValue[2]}/${tmpValue[1]}/${tmpValue[0]}`;
  };

  const handleOnFilter = useCallback(async () => {
    let dt_inicio = '';
    let dt_fim = '';

    const fieldInputDataInicio = document.getElementById(fieldFilterDataInicio);
    if (fieldInputDataInicio) {
      dt_inicio = (fieldInputDataInicio as HTMLInputElement).value;
    }

    const fieldInputDataFim = document.getElementById(fieldFilterDataFim);
    if (fieldInputDataFim) {
      dt_fim = (fieldInputDataFim as HTMLInputElement).value;
    }

    let dataInicioInvalida = false;
    let dataFimInvalida = false;
    let textDataInicio = '';
    let textDataFim = '';
    if (new Date(dt_inicio).toString() === 'Invalid Date') {
      dataInicioInvalida = true;
    } else {
      textDataInicio = formatedDateFilter(dt_inicio.substring(0, 10));
    }

    if (new Date(dt_fim).toString() === 'Invalid Date') {
      dataFimInvalida = true;
    } else {
      textDataFim = formatedDateFilter(dt_fim.substring(0, 10));
    }

    setOpenLoading(true);

    const filtro: IFilter = {
      data_inicio: !dataInicioInvalida ? new Date(`${dt_inicio.substring(0, 10)}T12:00:00`) : undefined,
      data_fim: !dataFimInvalida ? new Date(`${dt_fim.substring(0, 10)}T12:00:00`) : undefined,
    };

    localStorage.setItem(localStorageIdFilter, JSON.stringify(filtro));

    if (textDataInicio || textDataFim) {
      setTextFiltro(`   Filtrado por: ${textDataInicio} ${textDataInicio && textDataFim ? ' à ' : ''} ${textDataFim}`);
    } else {
      setTextFiltro('');
    }

    getValues();
    await getDemGerLctos(filtro);
    setOpenLoading(false);
    handleClick('pesquisar');
  }, [getValues, getDemGerLctos]);

  return (
    <Container>
      <Header />

      <Tab>
        <TabButtonArea>
          <TabButton id="pesquisar" isActive={!hiddenPesquisar} onClick={() => handleClick('pesquisar')}>
            Listagem
          </TabButton>
          <TabButton id="cadastrar" isActive={!hiddenCadastrar} onClick={() => handleClick('cadastrar')}>
            Cadastro
          </TabButton>
          <Tooltip title="Reduzir o tamanho da linha" placement="top">
            <TabButtonGridRowHeight id="gridrowheightdown" onClick={() => handleGridRowHeightDown()}>
              <AiOutlineCaretDown />
            </TabButtonGridRowHeight>
          </Tooltip>

          <Tooltip title="Aumentar o tamanho da linha" placement="top">
            <TabButtonGridRowHeight id="gridrowheightup" onClick={() => handleGridRowHeightUp()}>
              <AiOutlineCaretUp />
            </TabButtonGridRowHeight>
          </Tooltip>

          <TabButton id="filtro" isActive={false} onClick={() => handleClick('filtro')}>
            <AiOutlineSearch />
          </TabButton>

          <Tooltip title="Importar arquivo Excel" placement="top">
            <TabButton id="importar_xls" isActive={false} onClick={() => setOpenUploadFile(true)}>
              <CloudUploadIcon />
            </TabButton>
          </Tooltip>
          <Tooltip title="Novo Registro" placement="top">
            <TabButtonNovo id="new_register" onClick={() => OnSetRegisterToAdd()}>
              <GoPlus />
              Novo
            </TabButtonNovo>
          </Tooltip>

          <Tooltip title="Remover Registros Selecionados" placement="top">
            <span>
              <span hidden={selectedIds.length <= 0}>
                <TabButtonDelete
                  id="remove_all"
                  onClick={() => {
                    setIdDeleteDemGerLctos('');
                    setOpenDeleteMultiple(true);
                  }}
                >
                  <RiDeleteBinLine />
                  Remover
                </TabButtonDelete>
              </span>
            </span>
          </Tooltip>
        </TabButtonArea>

        <TabTitulo>
          <span>{titulo}</span>
        </TabTitulo>
      </Tab>
      <SpanFiltro>{textFiltro}</SpanFiltro>

      <TabContent id="pesquisar" isActive={!hiddenPesquisar}>
        <div style={{ height: '85vh', width: '100%' }}>
          <ThemeProvider theme={theme}>
            <DataGrid
              rows={listDemGerLctos}
              columns={columns}
              rowHeight={gridRowHeight}
              checkboxSelection
              selectionModel={selectedIds}
              disableSelectionOnClick
              onSelectionModelChange={e => setSelectedIds(e.selectionModel)}
            />
          </ThemeProvider>
        </div>
      </TabContent>

      <TabContent id="cadasto" isActive={!hiddenCadastrar}>
        <Form ref={formRef} onSubmit={handleSubmit} initialData={demGerLctos}>
          <FormContainer>
            <FormNavigate>
              <Tooltip title="Novo Registro" placement="top">
                <IconButton color="primary" aria-label="adicionar" onClick={() => onNewRegister()}>
                  <AiOutlinePlus />
                </IconButton>
              </Tooltip>

              <Tooltip title="Duplicar Registro" placement="top">
                <IconButton color="primary" aria-label="Duplicar" onClick={() => OnGridRowDuplicate(demGerLctos)}>
                  <MdContentCopy />
                </IconButton>
              </Tooltip>

              <Tooltip title="Excluir Registro" placement="top">
                <IconButton
                  color="primary"
                  aria-label="Excluir"
                  onClick={() => OnConfirmationDeleteSingle(demGerLctos ? demGerLctos.id : '')}
                >
                  <RiDeleteBinLine />
                </IconButton>
              </Tooltip>

              <Tooltip title="Cancelar" placement="top">
                <IconButton type="reset" color="primary" aria-label="Cancelar" onClick={() => onCancelRegister()}>
                  <MdClear />
                </IconButton>
              </Tooltip>

              <Tooltip title="Salvar" placement="top">
                <IconButton type="submit" color="primary" aria-label="Confirmar">
                  <AiOutlineSave />
                </IconButton>
              </Tooltip>

              <Tooltip title="Primeiro Registro" placement="top">
                <IconButton color="primary" aria-label="Primeiro" onClick={() => onFirstRegister()}>
                  <AiFillFastBackward />
                </IconButton>
              </Tooltip>

              <Tooltip title="Registro Anterior" placement="top">
                <IconButton color="primary" aria-label="Anterior" onClick={() => onPriorRegister()}>
                  <MdSkipPrevious />
                </IconButton>
              </Tooltip>

              <Tooltip title="Próximo Registro" placement="top">
                <IconButton color="primary" aria-label="Próximo" onClick={() => onNextRegister()}>
                  <MdSkipNext />
                </IconButton>
              </Tooltip>

              <Tooltip title="Último Registro" placement="top">
                <IconButton color="primary" aria-label="Último registro" onClick={() => onLastRegister()}>
                  <AiFillFastForward />
                </IconButton>
              </Tooltip>
            </FormNavigate>

            <FormFields>
              <ContainerFields field="T100">
                <span>Demonstrativo:</span>
                <Select id={idfieldSelect} name="id_tipo_dem_ger">
                  {listTipoDemGer.map(option => (
                    <option key={option.id} value={option.id}>
                      {option.codigo.trim()}-{option.descricao.trim()}
                    </option>
                  ))}
                </Select>
              </ContainerFields>

              <ContainerFieldGroup>
                <ContainerFields field="T300px">
                  <span>Conta:</span>
                  <InputComum autoFocus name="cod_cta_i050" />
                </ContainerFields>
                <ContainerFields field="T300px">
                  <span>Centro custo:</span>
                  <InputComum autoFocus name="cod_ccusto_i100" />
                </ContainerFields>
              </ContainerFieldGroup>

              <ContainerFieldGroup>
                <ContainerFields field="T300px">
                  <span>Data:</span>
                  <InputDate name="data" />
                </ContainerFields>

                <ContainerFields field="T300px">
                  <span>Tipo Ajuste:</span>
                  <Select name="tp_ajuste" defaultValue="00-Ajuste Gerencial">
                    {options_tp_ajuste.map(itemTpAjuste => (
                      <option key={itemTpAjuste.value} value={itemTpAjuste.value}>
                        {itemTpAjuste.label}
                      </option>
                    ))}
                  </Select>
                </ContainerFields>
              </ContainerFieldGroup>

              <ContainerFieldGroup>
                <ContainerFields field="T300px">
                  <span>Tipo lançamento:</span>
                  <Select name="tp_lcto" defaultValue="1-Débito">
                    {options_tp_lcto.map(option => (
                      <option key={option.value} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </Select>
                </ContainerFields>

                <ContainerFields field="T300px">
                  <span>Valor ajuste:</span>
                  <InputComum name="valor_ajuste" placeholder="0,00" type="number" step="0.01" />
                </ContainerFields>
              </ContainerFieldGroup>

              <ContainerFields field="T100">
                <span>Histórico:</span>
                <InputComum autoFocus name="historico" />
              </ContainerFields>
              <ContainerFields field="id">
                <InputID name="id" hidden />
              </ContainerFields>
            </FormFields>
          </FormContainer>
        </Form>
      </TabContent>

      <FormModalDialogConfirm
        open={openDeleteSingle}
        onClose={() => setOpenDeleteSingle(false)}
        onConfirm={() => handleOnRowDeleteSingle()}
        buttonCancelText="Cancelar"
        buttonConfirmText="Sim"
        formTitle="Excluir?"
        messageConfirm="Tem certeza que deseja excluir?"
      />

      <FormModalDialogConfirm
        open={openDeleteMultiple}
        onClose={() => setOpenDeleteMultiple(false)}
        onConfirm={() => handleOnRowDeleteMultiple()}
        buttonCancelText="Cancelar"
        buttonConfirmText="Sim"
        formTitle="Excluir?"
        messageConfirm="Tem certeza que deseja excluir todos os registros selecionados?"
      />

      <ModalUploadFile
        open={openUploadFile}
        onClose={onUploadFileClose}
        apiSource={`/demgerlctos/file/${user.license.id}?cnpj_estab=${user.empresa.cnpj}`}
        apiSourceFileExample="/demgerlctos/file/example"
      />

      <Dialog ref={formFilterRef} open={openFilter} onClose={handleCloseFilter}>
        <TituloFiltro id="alert-dialog-title">Filtro</TituloFiltro>
        <DialogContent>
          <ContainerFields field="T280px" filter_field>
            <span>Data Inicio:</span>
            <ContainerFilterInput>
              <input type="date" id={fieldFilterDataInicio} required defaultValue={defaultFilterDataInicio()} />
            </ContainerFilterInput>
          </ContainerFields>

          <ContainerFields field="T280px" className="field2filter" filter_field>
            <span>Data Fim:</span>
            <ContainerFilterInput>
              <input type="date" id={fieldFilterDataFim} required defaultValue={defaultFilterDataFim()} />
            </ContainerFilterInput>
          </ContainerFields>
        </DialogContent>

        <DialogActions>
          <ButtonDialog onClick={handleCloseFilter} color="primary" autoFocus>
            Cancelar
          </ButtonDialog>
          <ButtonDialog onClick={handleOnFilter} color="primary">
            Filtrar
          </ButtonDialog>
        </DialogActions>
      </Dialog>

      <Backdrop className={cssLoading.backdrop} open={openLoading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </Container>
  );
};

export default DemGerLctos;
