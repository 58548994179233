import styled, { css } from 'styled-components';

import Tooltip from '../Tooltip';

interface IContainerProps {
  isFocused: boolean;
  isFilled: boolean;
  isErrored: boolean;
  disableBorder: boolean;
}

export const Container = styled.div<IContainerProps>`
  background: #fff;
  border-radius: 4px;
  padding: 5.5px;
  width: 100%;

  border: 1px solid #666360;
  color: #666360;

  display: flex;
  align-items: center;

  & + div {
    margin-top: 8px;
  }

  ${props =>
    props.disableBorder &&
    css`
      border: 0;
    `}


  ${props =>
    props.isErrored &&
    css`
      border-color: #c53030;
    `}

  ${props =>
    props.isFocused &&
    css`
      color: #006699;
      border-color: #006699;
      border: 2px solid;
    `}

  ${props =>
    props.isFilled &&
    css`
      color: #006699;
    `}

  select {
    flex: 1;
    background: transparent;
    border: 0;
    color: #000;
    width: 100%;
    border: none;
    height: 100%;
    font-size: 16px;
    cursor: pointer;


    &::placeholder {
      color: #989898;
    }
  }

  option {
    display: block;
    position: absolute;
    cursor: pointer;
    padding: 15px 0;
    font-size: 16px;
    zoom: 1.2;
  }

  svg {
    margin-right: 16px;
  }
`;

export const Error = styled(Tooltip)`
  height: 20px;
  margin-left: 16px;

  svg {
    margin: 0;
  }

  span {
    background: #c53030;
    color: #fff;

    &::before {
      border-color: #c53030 transparent;
    }
  }
`;
