import React, { useCallback, useState, useEffect } from 'react';
import { uuid } from 'uuidv4';
import filesize from 'filesize';
import { DropEvent } from 'react-dropzone';

import Dialog from '@material-ui/core/Dialog';
import ButtonDialog from '@material-ui/core/Button';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

import UploadFileIcon from '@material-ui/icons/Description';

import api from '../../services/api';
import { useToast } from '../../hooks/Toast';

import UploadFile from '../UploadFile';
import FileList from '../FileList';
import IFile from '../FileList/IFile';

import { Container, TitleHead, TitleDiv } from './styles';

type functionVoid = () => void;

interface IModalUploadFileOFXProps {
  open: boolean;
  onClose: functionVoid;
  apiSource: string;
}

const ModalUploadFileOFX: React.FC<IModalUploadFileOFXProps> = ({ apiSource, onClose, open }) => {
  const { addToast } = useToast();
  const [uploadedFiles, setUploadedFiles] = useState<IFile[]>([]);
  const [uploadedFilesError, setUploadedFilesError] = useState<IFile[]>([]);
  const [uploadedFilesFinished, setUploadedFilesFinished] = useState<IFile[]>([]);

  const handleClose = useCallback(() => {
    setUploadedFiles([]);
    if (onClose) {
      onClose();
    }
  }, [onClose]);

  const processUpload = useCallback(
    async uploadedFile => {
      const data = new FormData();

      data.append('file', uploadedFile.file, uploadedFile.name);

      const item: IFile = {
        uploaded: false,
        id: uploadedFile.id,
        name: uploadedFile.name,
        readableSize: uploadedFile.readableSize,
        isUploading: true,
        error: false,
      };

      api
        .post(apiSource, data)
        .then(response => {
          item.uploaded = true;
          item.isUploading = false;

          setUploadedFilesFinished([...uploadedFilesFinished, item]);

          addToast({
            type: 'success',
            title: 'Carregado',
            description: `Arquivo ${uploadedFile.name} carregado com sucesso`,
          });
        })

        .catch(() => {
          item.uploaded = false;
          item.error = true;
          item.isUploading = false;

          setUploadedFilesError([...uploadedFilesError, item]);

          addToast({
            type: 'error',
            title: 'Falhou',
            description: `Ocorreu uma falha ao carregar o arquivo ${uploadedFile.name}. Por favor verifique se a conta corrente está cadastrada.`,
          });
        });
    },
    [addToast, apiSource, uploadedFilesFinished, uploadedFilesError],
  );

  const handleUpload = useCallback(async () => {
    const newList = await Promise.all(
      uploadedFiles.map(item => ({
        ...item,
        error: false,
        isUploading: !item.uploaded,
      })),
    );
    setUploadedFiles(newList);

    const listUpload = newList.filter(item => item.isUploading);

    listUpload.forEach(processUpload);
  }, [uploadedFiles, processUpload]);

  const handleSelectFiles = useCallback(
    <T extends File>(files: T[], event: DropEvent): void => {
      const uploadedList = files.map(file => {
        const newFile: IFile = {
          file,
          id: uuid(),
          name: file.name,
          readableSize: filesize(file.size),
          isUploading: false,
          uploaded: false,
          error: false,
        };

        return newFile;
      });

      const newList = uploadedList.concat(uploadedFiles);

      setUploadedFiles(newList);
    },
    [uploadedFiles],
  );

  useEffect(() => {
    uploadedFilesFinished.map(item => {
      const findItem = uploadedFiles.find(it => it.id === item.id);
      if (findItem) {
        findItem.isUploading = false;
        findItem.uploaded = true;
      }

      return findItem;
    });

    uploadedFilesError.map(item => {
      const findItem = uploadedFiles.find(it => it.id === item.id);
      if (findItem) {
        findItem.isUploading = false;
        findItem.error = true;
      }

      return findItem;
    });
  }, [uploadedFiles, uploadedFilesError, uploadedFilesFinished]);

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <TitleHead>
        <TitleDiv>
          <UploadFileIcon />

          <DialogTitle id="alert-dialog-title">Carregar arquivos</DialogTitle>
        </TitleDiv>
      </TitleHead>

      <DialogContent>
        <Container>
          <p className="ext_file">Arquivos válidos: OFX</p>

          <UploadFile isOFXFile onUpload={handleSelectFiles} />
          {!!uploadedFiles.length && <FileList files={uploadedFiles} />}
        </Container>
      </DialogContent>
      <DialogActions>
        <ButtonDialog onClick={handleClose} color="secondary" autoFocus variant="contained">
          Cancelar
        </ButtonDialog>
        <ButtonDialog onClick={handleUpload} color="primary" variant="contained">
          Enviar
        </ButtonDialog>
      </DialogActions>
    </Dialog>
  );
};

export default ModalUploadFileOFX;
