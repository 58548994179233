import React, { useRef, useEffect, SelectHTMLAttributes, ReactNode, useState, useCallback } from 'react';

import { useField } from '@unform/core';
import { FiAlertCircle } from 'react-icons/fi';
import { Container, Error } from './styles';

interface ISelectProps {
  name: string;
  children: ReactNode;
  disableBorder?: boolean;
}

type Props = SelectHTMLAttributes<HTMLSelectElement> & ISelectProps;

const Select: React.FC<Props> = ({ name, children, disableBorder, ...rest }) => {
  const selectRef = useRef<HTMLSelectElement>(null);
  const [isFocused, setIsFocused] = useState(false);
  const [isFilled, setIsFilled] = useState(false);
  const { fieldName, defaultValue, registerField, error } = useField(name);

  const handleInputBlur = useCallback(() => {
    setIsFocused(false);

    setIsFilled(!!selectRef.current?.value);
  }, []);

  const handleInputFocus = useCallback(() => {
    setIsFocused(true);
  }, []);

  useEffect(() => {
    registerField({
      ref: selectRef,
      name: fieldName,
      getValue: ref => {
        return ref.current?.value;
      },
      setValue: (ref, newValue) => {
        ref.current.value = newValue;
      },
      clearValue: ref => {
        ref.current.value = '';
      },
    });
  }, [fieldName, registerField]);

  return (
    <div>
      <Container isErrored={!!error} isFilled={isFilled} isFocused={isFocused} disableBorder={disableBorder || false}>
        <select
          id={fieldName}
          ref={selectRef}
          defaultValue={defaultValue}
          onFocus={handleInputFocus}
          onBlur={handleInputBlur}
          {...rest}
        >
          {children}
        </select>

        {error && (
          <Error title={error}>
            <FiAlertCircle color="#c53030" size={20} />
          </Error>
        )}
      </Container>
    </div>
  );
};

export default Select;
