import styled, { css } from 'styled-components';

interface IContainerProps {
  isActive: boolean;
}

export const Container = styled.div`
  form {
    margin-top: 100px;
    margin-left: 50px;
    margin-right: 50px;

    max-height: 500px;
    max-width: 450px;
  }
`;

export const Formulario = styled.div`
  width: 550px;
  border: solid 1px #006699;
  padding: 16px 16px 30px 16px;
  align-items: center;
  margin-top: 30px;
  border-radius: 10px;

  Header {
  }
`;

export const Section = styled.section`
  margin-top: 48px;
  display: flex;
  flex-direction: row;
  align-items: baseline;

  h1 {
    font-size: 36px;
    margin-left: 48px;
  }

  span {
    margin-left: 10px;
  }

  > strong {
    color: #999591;
    font-size: 20px;
    line-height: 26px;
    border-bottom: 1px solid #3e3b47;
    display: block;
    padding-bottom: 16px;
    margin-bottom: 16px;
  }

  > p {
    color: #999591;
  }
`;

export const Company = styled.div<IContainerProps>`
  color: #c53030;
  border-color: #c53030;

  ${props =>
    props.isActive &&
    css`
      color: #009900;
      border-color: #009900;
    `}
`;
