import React, { createContext, useState, useContext, useCallback } from 'react';

import IDemGerLookup from './IDemGerLookup';
import IHistoricoLookup from './IHistoricoLookup';
import IBancoLookup from './IBancoLookup';
import IIndicadorPersonalizadoLookup from './IIndicadorPersonalizadoLookup';

import ILookupState from './ILookupState';
import ILookupContextData from './ILookupContextData';

const LookupContext = createContext<ILookupContextData>({} as ILookupContextData);

const LookupProvider: React.FC = ({ children }) => {
  const [data, setData] = useState<ILookupState>(() => {
    return {
      listDemGer: [],
      listHistoricoPadrao: [],
      listBanco: [],
      listIndicadorPersonalizado: [],
    } as ILookupState;
  });

  const updateListDemGer = useCallback(
    (inListDemGer: IDemGerLookup[]): void => {
      const newData = data;
      newData.listDemGer = inListDemGer;
      setData(newData);
    },
    [data],
  );

  const updateListHistoricoPadrao = useCallback(
    (historicos: IHistoricoLookup[]): void => {
      const newData = data;
      newData.listHistoricoPadrao = historicos;
      setData(newData);
    },
    [data],
  );

  const updateListBanco = useCallback(
    (bancos: IBancoLookup[]): void => {
      const newData = data;
      newData.listBanco = bancos;
      setData(newData);
    },
    [data],
  );

  const updateListIndicadorPersonalizado = useCallback(
    (inValue: IIndicadorPersonalizadoLookup[]): void => {
      const newData = data;
      newData.listIndicadorPersonalizado = inValue;
      setData(newData);
    },
    [data],
  );

  const getListDemGer = (): IDemGerLookup[] => {
    return data.listDemGer;
  };

  const getListHistoricoPadrao = (): IHistoricoLookup[] => {
    return data.listHistoricoPadrao;
  };

  const getListBanco = (): IBancoLookup[] => {
    return data.listBanco;
  };

  const getListIndicadorPersonalizado = (): IIndicadorPersonalizadoLookup[] => {
    return data.listIndicadorPersonalizado;
  };

  return (
    <LookupContext.Provider
      value={{
        updateListDemGer,
        updateListHistoricoPadrao,
        updateListBanco,
        updateListIndicadorPersonalizado,

        getListDemGer,
        getListHistoricoPadrao,
        getListBanco,
        getListIndicadorPersonalizado,
      }}
    >
      {children}
    </LookupContext.Provider>
  );
};

function useLookup(): ILookupContextData {
  const context = useContext(LookupContext);
  if (!context) throw new Error('useLookup must be used within a LookupProvider');

  const {
    updateListDemGer,
    updateListHistoricoPadrao,
    updateListBanco,
    updateListIndicadorPersonalizado,

    getListDemGer,
    getListHistoricoPadrao,
    getListBanco,
    getListIndicadorPersonalizado,
  } = context;

  return {
    updateListDemGer,
    updateListHistoricoPadrao,
    updateListBanco,
    updateListIndicadorPersonalizado,

    getListDemGer,
    getListHistoricoPadrao,
    getListBanco,
    getListIndicadorPersonalizado,
  };
}

export { LookupProvider, useLookup };
