import React, { useRef, useState, useEffect, useCallback } from 'react';
import * as Yup from 'yup';

import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';

import { DataGrid, GridColDef, GridCellParams, GridRowId, ptBR } from '@material-ui/data-grid';
import { createMuiTheme, ThemeProvider, makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';

import { FiCopy } from 'react-icons/fi';
import { MdDelete, MdEdit, MdClear, MdSkipNext, MdSkipPrevious, MdContentCopy } from 'react-icons/md';
import {
  AiOutlinePlus,
  AiOutlineSave,
  AiFillFastBackward,
  AiFillFastForward,
  AiOutlineCaretDown,
  AiOutlineCaretUp,
} from 'react-icons/ai';
import { GoPlus } from 'react-icons/go';
import { RiDeleteBinLine } from 'react-icons/ri';

import InputComum from '../../components/InputComum';
import InputID from '../../components/InputID';
import InputDate from '../../components/InputDate';
import Select from '../../components/Select';
import Header from '../../components/Header';
import FormModalDialogConfirm from '../../components/FormModalDialogConfirm';

import api from '../../services/api';
import { useAuth } from '../../hooks/Auth';
import { useToast } from '../../hooks/Toast';

import getListUpdate from '../../utils/getListUpdate';
import getValidationErrors from '../../utils/getValidationErrors';
import { getPathGrid } from '../../utils/storagePath';

import {
  ColumnDemonstrativo,
  ColumnConta,
  ColumnCentroCusto,
  ColumnComentario1,
  ColumnComentario2,
  ColumnComentario3,
  ColumnComentario4,
  ColumnComentario5,
  ColumnIdTipoDemGer,
  ColumnValor1,
  ColumnValor2,
  ColumnValor3,
  ColumnValor4,
  ColumnValor5,
  ColumnId,
} from './GridColumns';

import ITipoDemGer from './ITipoDemGer';
import IDemGerLctosComentarios, { emptyDemGerLctosComentarios } from './IDemGerLctosComentarios';

import {
  Container,
  ContainerFields,
  Tab,
  TabButton,
  TabContent,
  FormFields,
  FormContainer,
  FormNavigate,
  TabTitulo,
  TabButtonArea,
  TabButtonNovo,
  TabButtonDelete,
  TabButtonGridRowHeight,
  ContainerFieldGroup,
} from './styles';

const useStylesLoading = makeStyles((theme: Theme) =>
  createStyles({
    backdrop: {
      zIndex: theme.zIndex.drawer + 2000,
      color: '#fff',
    },
  }),
);

const theme = createMuiTheme(
  {
    palette: {
      primary: { main: '#006699' },
    },
  },
  ptBR,
);

const idfieldSelect = 'n55547u23reASD9fe0u9nj8cr';

const titulo = 'N O T A S     E     C O M E N T Á R I O S';

const DemGerLctosComentarios: React.FC = () => {
  const { user } = useAuth();
  const { addToast } = useToast();

  const formRef = useRef<FormHandles>(null);
  const [listTipoDemGer, setListTipoDemGer] = useState<ITipoDemGer[]>([]);
  const [demGerLctosComentarios, setDemGerLctosComentarios] = useState<IDemGerLctosComentarios>();
  const [listDemGerLctosComentarios, setListDemGerLctosComentarios] = useState<IDemGerLctosComentarios[]>([]);
  const [hiddenPesquisar, setHiddenPesquisar] = useState<boolean>(true);
  const [hiddenCadastrar, setHiddenCadastrar] = useState<boolean>(true);
  const [demGerLctosComentariosIndex, setDemGerLctosComentariosIndex] = useState<number>(0);
  const [rowToEdit, setRowToEdit] = useState(null);
  const [idDeleteDemGerLctosComentarios, setIdDeleteDemGerLctosComentarios] = useState<string>('');
  const [selectedIds, setSelectedIds] = useState<GridRowId[]>([]);
  const [openDeleteSingle, setOpenDeleteSingle] = useState(false);
  const [openDeleteMultiple, setOpenDeleteMultiple] = useState(false);
  const [openLoading, setOpenLoading] = useState(false);
  const cssLoading = useStylesLoading();
  const [gridRowHeight, setGridRowHeight] = useState<number>(46);

  const handleGridRowHeightUp = useCallback(async () => {
    const heightValue: number = gridRowHeight + 1;
    setGridRowHeight(heightValue);
    localStorage.setItem(getPathGrid(), heightValue.toString());
  }, [gridRowHeight]);

  const handleGridRowHeightDown = useCallback(async () => {
    const heightValue: number = gridRowHeight - 1;
    setGridRowHeight(heightValue);
    localStorage.setItem(getPathGrid(), heightValue.toString());
  }, [gridRowHeight]);

  const licencaInvalida = useCallback((): boolean => {
    if (!user.license || user.license.id === '') {
      addToast({
        type: 'error',
        title: 'Licença inválida',
        description: 'Usuário não está vinculado a nenhuma licença',
      });

      return true;
    }

    return false;
  }, [addToast, user]);

  const defineDemGerLctosComentarios = useCallback(async (data: IDemGerLctosComentarios) => {
    setDemGerLctosComentarios(data);
    formRef.current?.setData(data);
  }, []);

  const onNewRegister = useCallback(async () => {
    defineDemGerLctosComentarios(emptyDemGerLctosComentarios);
  }, [defineDemGerLctosComentarios]);

  const OnGridSetRegisterToEdit = useCallback(
    async (params: GridCellParams) => {
      const localDemGerLctosComentarios = params.row as IDemGerLctosComentarios;

      setDemGerLctosComentariosIndex(listDemGerLctosComentarios.indexOf(localDemGerLctosComentarios));
      setHiddenCadastrar(false);
      setHiddenPesquisar(true);
      setRowToEdit(null);
      defineDemGerLctosComentarios(localDemGerLctosComentarios);
    },
    [listDemGerLctosComentarios, defineDemGerLctosComentarios],
  );

  const OnSetRegisterToAdd = useCallback(async () => {
    onNewRegister();
    setHiddenCadastrar(false);
    setHiddenPesquisar(true);
  }, [onNewRegister]);

  const OnGridRowDuplicate = useCallback(
    async (baseDemGerLctosComentarios: IDemGerLctosComentarios | undefined) => {
      if (baseDemGerLctosComentarios !== undefined) {
        const newDemGerLctos: IDemGerLctosComentarios = {
          acao: baseDemGerLctosComentarios.acao,
          id: '',
          id_tipo_dem_ger: baseDemGerLctosComentarios.id_tipo_dem_ger,
          data: baseDemGerLctosComentarios.data,
          cod_cta_i050: baseDemGerLctosComentarios.cod_cta_i050,
          cod_ccusto_i100: baseDemGerLctosComentarios.cod_ccusto_i100,
          id_company: baseDemGerLctosComentarios.id_company,
          id_license: baseDemGerLctosComentarios.id_license,
          valor1: baseDemGerLctosComentarios.valor1,
          valor2: baseDemGerLctosComentarios.valor2,
          valor3: baseDemGerLctosComentarios.valor3,
          valor4: baseDemGerLctosComentarios.valor4,
          valor5: baseDemGerLctosComentarios.valor5,
          comentario1: baseDemGerLctosComentarios.comentario1,
          comentario2: baseDemGerLctosComentarios.comentario2,
          comentario3: baseDemGerLctosComentarios.comentario3,
          comentario4: baseDemGerLctosComentarios.comentario4,
          comentario5: baseDemGerLctosComentarios.comentario5,
          demonstrativo: baseDemGerLctosComentarios.demonstrativo,
        };

        defineDemGerLctosComentarios(newDemGerLctos);
        setHiddenCadastrar(false);
        setHiddenPesquisar(true);
      }
    },
    [defineDemGerLctosComentarios],
  );

  const OnConfirmationDeleteSingle = useCallback(
    async (id: string) => {
      if (licencaInvalida()) return;

      if (id === '') return;

      setIdDeleteDemGerLctosComentarios(id);
      setOpenDeleteSingle(true);
    },
    [licencaInvalida],
  );

  const colunaAcao: GridColDef = {
    field: 'acao',
    headerName: 'Ação',
    editable: false,
    filterable: false,
    hideSortIcons: true,
    sortable: false,
    disableColumnMenu: true,
    width: 160,
    renderCell: (params: GridCellParams) => (
      <strong>
        <Tooltip title="Editar Registro" placement="top">
          <IconButton color="primary" aria-label="Editar" onClick={() => OnGridSetRegisterToEdit(params)}>
            <MdEdit />
          </IconButton>
        </Tooltip>

        <Tooltip title="Duplicar Registro" placement="top">
          <IconButton
            color="primary"
            aria-label="Duplicar"
            onClick={() => OnGridRowDuplicate(params.row as IDemGerLctosComentarios)}
          >
            <FiCopy />
          </IconButton>
        </Tooltip>

        <Tooltip title="Excluir Registro" placement="top">
          <IconButton
            color="primary"
            aria-label="Excluir"
            onClick={() => OnConfirmationDeleteSingle((params.row as IDemGerLctosComentarios).id)}
          >
            <MdDelete />
          </IconButton>
        </Tooltip>
      </strong>
    ),
  };

  const ColumnData: GridColDef = {
    headerName: 'Data',
    field: 'data',
    width: 150,
    type: 'date',
    renderCell: (params: GridCellParams) => {
      if (params.value === null) return <strong />;
      const ano = (params.value as string).substring(0, 4);
      const mes = (params.value as string).substring(5, 7);
      const dia = (params.value as string).substring(8, 10);

      const dataFormatada = `${dia}/${mes}/${ano}`;
      return <strong>{dataFormatada}</strong>;
    },
  };

  const columns: GridColDef[] = [
    colunaAcao,
    ColumnDemonstrativo,
    ColumnData,
    ColumnConta,
    ColumnCentroCusto,
    ColumnValor1,
    ColumnComentario1,
    ColumnValor2,
    ColumnComentario2,
    ColumnValor3,
    ColumnComentario3,
    ColumnValor4,
    ColumnComentario4,
    ColumnValor5,
    ColumnComentario5,
    ColumnIdTipoDemGer,
    ColumnId,
  ];

  const getTipoDemGer = useCallback(async () => {
    if (licencaInvalida()) return;

    const response = await api.get<ITipoDemGer[]>(`/tipodemger/all/company/${user.empresa.id}`);

    setListTipoDemGer(response.data);
  }, [user, licencaInvalida]);

  const getDemGerLctosComentarios = useCallback(async () => {
    if (licencaInvalida()) return;

    const response = await api.get<IDemGerLctosComentarios[]>(`/demgerlctoscomentarios/filter`, {
      params: {
        id_license: user.license.id,
        cnpj: user.empresa.cnpj,
      },
    });

    setListDemGerLctosComentarios(response.data);

    if (rowToEdit !== null) {
      const findEditDemGerLctosComentarios = response.data.find(item => item.id === rowToEdit);

      if (findEditDemGerLctosComentarios) {
        defineDemGerLctosComentarios(findEditDemGerLctosComentarios);
        setDemGerLctosComentariosIndex(response.data.indexOf(findEditDemGerLctosComentarios));
        setHiddenCadastrar(false);
        setHiddenPesquisar(true);
        setRowToEdit(null);
      }
    }
  }, [user, licencaInvalida, rowToEdit, defineDemGerLctosComentarios]);

  const getValues = useCallback(async () => {
    setOpenLoading(true);

    await getTipoDemGer();
    await getDemGerLctosComentarios();

    setOpenLoading(false);
    setHiddenPesquisar(false);
  }, [getTipoDemGer, getDemGerLctosComentarios]);

  useEffect(() => {
    getValues();

    const rowHeight = localStorage.getItem(getPathGrid());
    if (rowHeight) {
      setGridRowHeight(parseInt(rowHeight, 10));
    }
  }, [getValues]);

  const getDescricaoTipoDemGerSelectOption = useCallback((value: ITipoDemGer): string => {
    if (!value) return '';

    return value.descricao;
  }, []);

  const getTipoDemGerSelectOption = useCallback(
    (id: string): ITipoDemGer => {
      return listTipoDemGer.filter(item => item.id === id)[0];
    },
    [listTipoDemGer],
  );

  const handleOnRowDeleteSingle = useCallback(async () => {
    setOpenLoading(true);
    await api.delete(`/demgerlctoscomentarios/${idDeleteDemGerLctosComentarios}`);

    setListDemGerLctosComentarios(oldValues => oldValues.filter(item => item.id !== idDeleteDemGerLctosComentarios));

    defineDemGerLctosComentarios(emptyDemGerLctosComentarios);

    addToast({
      type: 'success',
      title: 'Removido',
      description: 'Registro excluído com sucesso!',
    });

    setOpenDeleteSingle(false);
    setOpenLoading(false);
  }, [idDeleteDemGerLctosComentarios, addToast, defineDemGerLctosComentarios]);

  const handleOnRowDeleteMultiple = useCallback(async () => {
    setOpenLoading(true);
    if (selectedIds.length > 0) {
      await Promise.all(
        selectedIds.map(itemId => {
          setListDemGerLctosComentarios(oldValues => oldValues.filter(item => item.id !== itemId));

          return api.delete(`/demgerlctoscomentarios/${itemId}`);
        }),
      );

      defineDemGerLctosComentarios(emptyDemGerLctosComentarios);

      addToast({
        type: 'success',
        title: 'Removido',
        description: 'Registro excluído com sucesso!',
      });
    }

    setSelectedIds([]);
    setOpenDeleteMultiple(false);
    setOpenLoading(false);
  }, [addToast, defineDemGerLctosComentarios, selectedIds]);

  const handleClick = (id: string): void => {
    setHiddenCadastrar(id === 'pesquisar');
    setHiddenPesquisar(id === 'cadastrar');
  };

  const onNextRegister = useCallback(async () => {
    if (demGerLctosComentariosIndex !== undefined) {
      if (demGerLctosComentariosIndex < listDemGerLctosComentarios.length - 1) {
        const localIndex = demGerLctosComentariosIndex + 1;
        setDemGerLctosComentariosIndex(localIndex);
        const findDemGerLctosComentarios = listDemGerLctosComentarios[localIndex];
        if (findDemGerLctosComentarios) {
          defineDemGerLctosComentarios(findDemGerLctosComentarios);
        }
      }
    }
  }, [demGerLctosComentariosIndex, listDemGerLctosComentarios, defineDemGerLctosComentarios]);

  const onFirstRegister = useCallback(async () => {
    if (listDemGerLctosComentarios.length > 0) {
      setDemGerLctosComentariosIndex(0);
      const findDemGerLctosComentarios = listDemGerLctosComentarios[0];
      if (findDemGerLctosComentarios) {
        defineDemGerLctosComentarios(findDemGerLctosComentarios);
      }
    }
  }, [listDemGerLctosComentarios, defineDemGerLctosComentarios]);

  const onLastRegister = useCallback(async () => {
    if (listDemGerLctosComentarios.length > 0) {
      setDemGerLctosComentariosIndex(listDemGerLctosComentarios.length - 1);
      const findDemGerLctosComentarios = listDemGerLctosComentarios[listDemGerLctosComentarios.length - 1];
      if (findDemGerLctosComentarios) {
        defineDemGerLctosComentarios(findDemGerLctosComentarios);
      }
    }
  }, [listDemGerLctosComentarios, defineDemGerLctosComentarios]);

  const onPriorRegister = useCallback(async () => {
    if (demGerLctosComentariosIndex !== undefined) {
      if (demGerLctosComentariosIndex > 0) {
        const localIndex = demGerLctosComentariosIndex - 1;
        setDemGerLctosComentariosIndex(localIndex);
        const findDemGerLctosComentarios = listDemGerLctosComentarios[localIndex];
        if (findDemGerLctosComentarios) {
          defineDemGerLctosComentarios(findDemGerLctosComentarios);
        }
      }
    }
  }, [demGerLctosComentariosIndex, listDemGerLctosComentarios, defineDemGerLctosComentarios]);

  const onCancelRegister = useCallback(async () => {
    formRef.current?.setErrors({});

    if (demGerLctosComentariosIndex !== undefined) {
      const findDemGerLctosComentarios = listDemGerLctosComentarios[demGerLctosComentariosIndex];
      if (findDemGerLctosComentarios) {
        defineDemGerLctosComentarios(findDemGerLctosComentarios);
      }
    }

    onNewRegister();
  }, [demGerLctosComentariosIndex, listDemGerLctosComentarios, onNewRegister, defineDemGerLctosComentarios]);

  const handleSubmit = useCallback(
    async (data: IDemGerLctosComentarios, { reset }) => {
      if (licencaInvalida()) return;

      const fieldSelect = document.getElementById(idfieldSelect);
      if (fieldSelect) {
        const id = (fieldSelect as HTMLSelectElement).value;
        data.id_tipo_dem_ger = id;
        data.demonstrativo = getDescricaoTipoDemGerSelectOption(getTipoDemGerSelectOption(id));
      }

      formRef.current?.setErrors({});
      try {
        const schema = Yup.object().shape({
          id_tipo_dem_ger: Yup.string().required('Demonstrativo é obrigatório'),
          cod_cta_i050: Yup.string().required('Código da conta é obrigatório'),
          comentario1: Yup.string().trim().required('Comentário 1 é obrigatório'),
          data: Yup.date().label('Data inválida').required('Data é obrigatória'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        setOpenLoading(true);
        if (data.id === '') {
          const formData: IDemGerLctosComentarios = {
            acao: data.acao,
            id: data.id,
            id_license: user.license.id,
            id_company: user.empresa.id,
            id_tipo_dem_ger: data.id_tipo_dem_ger,
            cod_cta_i050: data.cod_cta_i050,
            cod_ccusto_i100: data.cod_ccusto_i100,
            data: data.data,
            comentario1: data.comentario1,
            comentario2: data.comentario2,
            comentario3: data.comentario3,
            comentario4: data.comentario4,
            comentario5: data.comentario5,
            valor1: data.valor1,
            valor2: data.valor2,
            valor3: data.valor3,
            valor4: data.valor4,
            valor5: data.valor5,
            demonstrativo: data.demonstrativo,
          };

          await api.post(`/demgerlctoscomentarios`, formData);
          setListDemGerLctosComentarios(oldValues => [...oldValues, data]);

          addToast({
            type: 'success',
            title: 'Adicionado',
            description: 'Registro adicionado com sucesso!',
          });
        } else {
          await api.put(`/demgerlctoscomentarios/${data.id}`, data);
          data.demonstrativo = getDescricaoTipoDemGerSelectOption(getTipoDemGerSelectOption(data.id_tipo_dem_ger));
          setListDemGerLctosComentarios(getListUpdate(listDemGerLctosComentarios, data));

          addToast({
            type: 'success',
            title: 'Alterado',
            description: 'Registro alterado com sucesso!',
          });
        }

        reset();
        setOpenLoading(false);
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          formRef.current?.setErrors(errors);
        }

        setOpenLoading(false);
      }
    },
    [
      licencaInvalida,
      listDemGerLctosComentarios,
      user,
      addToast,
      getDescricaoTipoDemGerSelectOption,
      getTipoDemGerSelectOption,
    ],
  );

  return (
    <Container>
      <Header />

      <Tab>
        <TabButtonArea>
          <TabButton id="pesquisar" isActive={!hiddenPesquisar} onClick={() => handleClick('pesquisar')}>
            Listagem
          </TabButton>
          <TabButton id="cadastrar" isActive={!hiddenCadastrar} onClick={() => handleClick('cadastrar')}>
            Cadastro
          </TabButton>
          <Tooltip title="Reduzir o tamanho da linha" placement="top">
            <TabButtonGridRowHeight id="gridrowheightdown" onClick={() => handleGridRowHeightDown()}>
              <AiOutlineCaretDown />
            </TabButtonGridRowHeight>
          </Tooltip>

          <Tooltip title="Aumentar o tamanho da linha" placement="top">
            <TabButtonGridRowHeight id="gridrowheightup" onClick={() => handleGridRowHeightUp()}>
              <AiOutlineCaretUp />
            </TabButtonGridRowHeight>
          </Tooltip>

          <Tooltip title="Novo Registro" placement="top">
            <TabButtonNovo id="new_register" onClick={() => OnSetRegisterToAdd()}>
              <GoPlus />
              Novo
            </TabButtonNovo>
          </Tooltip>

          <Tooltip title="Remover Registros Selecionados" placement="top">
            <span>
              <span hidden={selectedIds.length <= 0}>
                <TabButtonDelete
                  id="remove_all"
                  onClick={() => {
                    setIdDeleteDemGerLctosComentarios('');
                    setOpenDeleteMultiple(true);
                  }}
                >
                  <RiDeleteBinLine />
                  Remover
                </TabButtonDelete>
              </span>
            </span>
          </Tooltip>
        </TabButtonArea>

        <TabTitulo>
          <span>{titulo}</span>
        </TabTitulo>
      </Tab>

      <TabContent id="pesquisar" isActive={!hiddenPesquisar}>
        <div style={{ height: '85vh', width: '100%' }}>
          <ThemeProvider theme={theme}>
            <DataGrid
              rows={listDemGerLctosComentarios}
              columns={columns}
              rowHeight={gridRowHeight}
              checkboxSelection
              selectionModel={selectedIds}
              disableSelectionOnClick
              onSelectionModelChange={e => setSelectedIds(e.selectionModel)}
            />
          </ThemeProvider>
        </div>
      </TabContent>

      <TabContent id="cadasto" isActive={!hiddenCadastrar}>
        <Form ref={formRef} onSubmit={handleSubmit} initialData={demGerLctosComentarios}>
          <FormContainer>
            <FormNavigate>
              <Tooltip title="Novo Registro" placement="top">
                <IconButton color="primary" aria-label="adicionar" onClick={() => onNewRegister()}>
                  <AiOutlinePlus />
                </IconButton>
              </Tooltip>

              <Tooltip title="Duplicar Registro" placement="top">
                <IconButton
                  color="primary"
                  aria-label="Duplicar"
                  onClick={() => OnGridRowDuplicate(demGerLctosComentarios)}
                >
                  <MdContentCopy />
                </IconButton>
              </Tooltip>

              <Tooltip title="Excluir Registro" placement="top">
                <IconButton
                  color="primary"
                  aria-label="Excluir"
                  onClick={() => OnConfirmationDeleteSingle(demGerLctosComentarios ? demGerLctosComentarios.id : '')}
                >
                  <RiDeleteBinLine />
                </IconButton>
              </Tooltip>

              <Tooltip title="Cancelar" placement="top">
                <IconButton type="reset" color="primary" aria-label="Cancelar" onClick={() => onCancelRegister()}>
                  <MdClear />
                </IconButton>
              </Tooltip>

              <Tooltip title="Salvar" placement="top">
                <IconButton type="submit" color="primary" aria-label="Confirmar">
                  <AiOutlineSave />
                </IconButton>
              </Tooltip>

              <Tooltip title="Primeiro Registro" placement="top">
                <IconButton color="primary" aria-label="Primeiro" onClick={() => onFirstRegister()}>
                  <AiFillFastBackward />
                </IconButton>
              </Tooltip>

              <Tooltip title="Registro Anterior" placement="top">
                <IconButton color="primary" aria-label="Anterior" onClick={() => onPriorRegister()}>
                  <MdSkipPrevious />
                </IconButton>
              </Tooltip>

              <Tooltip title="Próximo Registro" placement="top">
                <IconButton color="primary" aria-label="Próximo" onClick={() => onNextRegister()}>
                  <MdSkipNext />
                </IconButton>
              </Tooltip>

              <Tooltip title="Último Registro" placement="top">
                <IconButton color="primary" aria-label="Último registro" onClick={() => onLastRegister()}>
                  <AiFillFastForward />
                </IconButton>
              </Tooltip>
            </FormNavigate>

            <FormFields>
              <ContainerFields field="T100">
                <span>Demonstrativo:</span>
                <Select id={idfieldSelect} name="id_tipo_dem_ger">
                  {listTipoDemGer.map(option => (
                    <option key={option.id} value={option.id}>
                      {option.codigo.trim()}-{option.descricao.trim()}
                    </option>
                  ))}
                </Select>
              </ContainerFields>

              <ContainerFieldGroup>
                <ContainerFields field="T300px">
                  <span>Conta:</span>
                  <InputComum autoFocus name="cod_cta_i050" />
                </ContainerFields>
                <ContainerFields field="T300px" className="field2">
                  <span>Centro custo:</span>
                  <InputComum autoFocus name="cod_ccusto_i100" />
                </ContainerFields>
              </ContainerFieldGroup>

              <ContainerFields field="T300px">
                <span>Data:</span>
                <InputDate name="data" />
              </ContainerFields>

              <ContainerFieldGroup>
                <ContainerFields field="T90">
                  <span>Comentário 1:</span>
                  <InputComum autoFocus name="comentario1" />
                </ContainerFields>

                <ContainerFields field="T300px">
                  <span>Valor 1:</span>
                  <InputComum name="valor1" placeholder="0,00" type="number" step="0.01" />
                </ContainerFields>
              </ContainerFieldGroup>

              <ContainerFieldGroup>
                <ContainerFields field="T90">
                  <span>Comentário 2:</span>
                  <InputComum autoFocus name="comentario2" />
                </ContainerFields>

                <ContainerFields field="T300px">
                  <span>Valor 2:</span>
                  <InputComum name="valor2" placeholder="0,00" type="number" step="0.01" />
                </ContainerFields>
              </ContainerFieldGroup>

              <ContainerFieldGroup>
                <ContainerFields field="T90">
                  <span>Comentário 3:</span>
                  <InputComum autoFocus name="comentario3" />
                </ContainerFields>

                <ContainerFields field="T300px">
                  <span>Valor 3:</span>
                  <InputComum name="valor3" placeholder="0,00" type="number" step="0.01" />
                </ContainerFields>
              </ContainerFieldGroup>

              <ContainerFieldGroup>
                <ContainerFields field="T90">
                  <span>Comentário 4:</span>
                  <InputComum autoFocus name="comentario4" />
                </ContainerFields>

                <ContainerFields field="T300px">
                  <span>Valor 4:</span>
                  <InputComum name="valor4" placeholder="0,00" type="number" step="0.01" />
                </ContainerFields>
              </ContainerFieldGroup>

              <ContainerFieldGroup>
                <ContainerFields field="T90">
                  <span>Comentário 5:</span>
                  <InputComum autoFocus name="comentario5" />
                </ContainerFields>

                <ContainerFields field="T300px">
                  <span>Valor 5:</span>
                  <InputComum name="valor5" placeholder="0,00" type="number" step="0.01" />
                </ContainerFields>
              </ContainerFieldGroup>

              <ContainerFields field="id">
                <InputID name="id" hidden />
                <InputID name="id_license" hidden />
                <InputID name="acao" hidden />
                <InputID name="id_company" hidden />
                <InputID name="demonstrativo" hidden />
              </ContainerFields>
            </FormFields>
          </FormContainer>
        </Form>
      </TabContent>

      <FormModalDialogConfirm
        open={openDeleteSingle}
        onClose={() => setOpenDeleteSingle(false)}
        onConfirm={() => handleOnRowDeleteSingle()}
        buttonCancelText="Cancelar"
        buttonConfirmText="Sim"
        formTitle="Excluir?"
        messageConfirm="Tem certeza que deseja excluir?"
      />

      <FormModalDialogConfirm
        open={openDeleteMultiple}
        onClose={() => setOpenDeleteMultiple(false)}
        onConfirm={() => handleOnRowDeleteMultiple()}
        buttonCancelText="Cancelar"
        buttonConfirmText="Sim"
        formTitle="Excluir?"
        messageConfirm="Tem certeza que deseja excluir todos os registros selecionados?"
      />

      <Backdrop className={cssLoading.backdrop} open={openLoading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </Container>
  );
};

export default DemGerLctosComentarios;
