import { GridColDef } from '@material-ui/data-grid';

export const ColumnAtiva: GridColDef = {
  headerName: 'Ativa',
  field: 'ativa',
  width: 120,
  type: 'boolean',
};

export const ColumnConsiderarFluxoCaixa: GridColDef = {
  headerName: 'Considerar fluxo caixa',
  field: 'considerar_fluxo_caixa',
  width: 220,
  type: 'boolean',
};

export const ColumnAgencia: GridColDef = {
  headerName: 'Agência',
  field: 'agencia',
  width: 200,
};

export const ColumnCodigoContaCorrente: GridColDef = {
  headerName: 'Conta corrente',
  field: 'conta_corrente',
  width: 200,
};

export const ColumnDescricaoConta: GridColDef = {
  field: 'descricao_conta',
  headerName: 'Descricao',
  width: 400,
};

export const ColumnBanco: GridColDef = {
  field: 'descricao_banco',
  headerName: 'Banco',
  width: 400,
  editable: false,
};

export const ColumnObservacao: GridColDef = {
  headerName: 'Observação',
  field: 'obs',
  width: 400,
};

export const ColumnContaContabilIntegracao: GridColDef = {
  headerName: 'Conta Contábil Integração',
  field: 'conta_contabil',
  width: 400,
};

export const ColumnSaldoInicial: GridColDef = {
  headerName: 'Saldo Inicial',
  field: 'saldo_inicial',
  width: 200,
  type: 'number',
};

export const ColumnId: GridColDef = {
  headerName: '#',
  field: 'id',
  hide: true,
  width: 400,
  editable: false,
  filterable: false,
  hideSortIcons: true,
  sortable: false,
  disableColumnMenu: true,
};
