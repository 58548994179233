import { IFilterFluxoCaixaSituacao, emptyFilterFluxoCaixaSituacao } from './IFilterFluxoCaixaSituacao';
import { IFilterFluxoCaixaTipo, emptyFilterFluxoCaixaTipo } from './IFilterFluxoCaixaTipo';
import { defaultFilterDataInicio, defaultFilterDataFim } from '../../../utils/functions';

export interface IFilterFluxoCaixa {
  tipo: IFilterFluxoCaixaTipo;
  situacao: IFilterFluxoCaixaSituacao;
  tipoData: string;
  dataInicio: string;
  dataFim: string;
}

export const emptyFilterFluxoCaixa: IFilterFluxoCaixa = {
  tipo: emptyFilterFluxoCaixaTipo,
  situacao: emptyFilterFluxoCaixaSituacao,
  tipoData: '2-Data Vencimento',
  dataInicio: defaultFilterDataInicio(),
  dataFim: defaultFilterDataFim(),
};
