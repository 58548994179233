import { GridColDef } from '@material-ui/data-grid';

export const ColumnAno: GridColDef = {
  field: 'ano',
  headerName: 'ano',
  width: 120,
  type: 'number',
};

export const Columnsituacao: GridColDef = {
  field: 'situacao',
  headerName: 'situação',
  width: 180,
};

export const ColumnNomeOrigem: GridColDef = {
  field: 'origem',
  headerName: 'Origem',
  width: 200,
};

export const ColumnIndTipoMovimento: GridColDef = {
  field: 'ind_tipo_movimento',
  headerName: 'Indicador tipo movimento',
  width: 250,
};

export const ColumnIdUsuarioResponsavel: GridColDef = {
  field: 'id_usuario_responsavel',
  headerName: '# Usuário responsável',
  hide: true,
  width: 300,
};

export const ColumnUsuarioResponsavel: GridColDef = {
  field: 'usuario_responsavel',
  headerName: 'Usuário responsável',
  width: 550,
};

export const ColumnObservacao: GridColDef = {
  field: 'obs',
  headerName: 'Observação',
  width: 500,
};

export const ColumnInformacao1: GridColDef = {
  field: 'inf1',
  headerName: 'Informação 1',
  width: 500,
};

export const ColumnInformacao2: GridColDef = {
  field: 'inf2',
  headerName: 'Informação 2',
  width: 500,
};

export const ColumnInformacao3: GridColDef = {
  field: 'inf3',
  headerName: 'Informação 3',
  width: 500,
};

export const ColumnInformacao4: GridColDef = {
  field: 'inf4',
  headerName: 'Informação 4',
  width: 500,
};

export const ColumnInformacao5: GridColDef = {
  field: 'inf5',
  headerName: 'Informação 5',
  width: 500,
};

export const ColumnCompany: GridColDef = {
  field: 'company',
  headerName: 'Empresa',
  width: 500,
};

export const ColumnID: GridColDef = {
  field: 'access_key',
  headerName: '#',
  width: 400,
  hide: true,
  editable: false,
  filterable: false,
  hideSortIcons: true,
  sortable: false,
  disableColumnMenu: true,
};
