export enum ActiveSystemKindOptions {
  Option_AccountingAnalysis = 1,
  Option_FinancialAccounts = 2,
  Option_CostAnalysis = 4,
  Option_HumanResources = 8,
}

interface IAppInfoUser {
  id: string;
  nome: string;
  email: string;
  redirectToCompanyRequest: boolean;
  admin: boolean;
  lastAcessIdLicense: string;
  lastAcessIdCompany: string;
}

export interface IAppInfoEmpresa {
  id: string;
  code: string;
  cnpj: string;
  cnpj_estab_tot: string;
  name: string;
  active: boolean;
  url_powerbi: string;
  url_powerbi_cost_analysis: string;
  url_powerbi_financial_accounts: string;
  url_powerbi_human_resources: string;
  user_powerbi: {
    url_powerbi_accouting_analysis: string;
    url_powerbi_financial_accounts: string;
    url_powerbi_human_resources: string;
    url_powerbi_cost_analysis: string;
  };
  admin: boolean;
  viewer: boolean;
  system_kind: number;
  active_system_kind: ActiveSystemKindOptions;
}

export interface IAppInfoLicenciamento {
  id: string;
  token: string;
  cnpj: string;
  email_admin_user: string;
  descricao: string;
  quantidade_cnpj: number;
  data_inicio: Date;
  data_fim?: Date;
  url_powerbi: string;
  url_powerbi_cost_analysis: string;
  url_powerbi_financial_accounts: string;
  url_powerbi_human_resources: string;
  blocked: boolean;
  tipo_licenca: string;
}

interface IAppInfo {
  licence: string;
  admin: boolean;
  menuAtivo: string;

  usuario: IAppInfoUser;
  empresa: IAppInfoEmpresa;
}

export const appInfo: IAppInfo = {
  licence: '',
  admin: false,
  menuAtivo: '',

  usuario: {
    id: '',
    nome: '',
    email: '',
    redirectToCompanyRequest: true,
    admin: false,
    lastAcessIdLicense: '',
    lastAcessIdCompany: '',
  },

  empresa: {
    id: '',
    code: '',
    cnpj: '',
    cnpj_estab_tot: '',
    name: '',
    active: false,
    url_powerbi: '',
    url_powerbi_cost_analysis: '',
    url_powerbi_financial_accounts: '',
    url_powerbi_human_resources: '',
    user_powerbi: {
      url_powerbi_accouting_analysis: '',
      url_powerbi_financial_accounts: '',
      url_powerbi_human_resources: '',
      url_powerbi_cost_analysis: '',
    },
    admin: false,
    viewer: true,
    active_system_kind: ActiveSystemKindOptions.Option_AccountingAnalysis,
    system_kind: 1,
  },
};

export default appInfo;
