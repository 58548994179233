export default interface IDemGerOrcado {
  acao: string;
  id: string;
  id_license: string;
  id_tipo_dem_ger: string;
  cnpj_estab_0000: string;
  ano: number;
  cod_cta_i050: string;
  cod_ccusto_i100: string;
  janeiro: number;
  fevereiro: number;
  marco: number;
  abril: number;
  maio: number;
  junho: number;
  julho: number;
  agosto: number;
  setembro: number;
  outubro: number;
  novembro: number;
  dezembro: number;
  obs: string;
  tipo: string;
  status_janeiro: string;
  status_fevereiro: string;
  status_marco: string;
  status_abril: string;
  status_maio: string;
  status_junho: string;
  status_julho: string;
  status_agosto: string;
  status_setembro: string;
  status_outubro: string;
  status_novembro: string;
  status_dezembro: string;
}

export const emptyDemGerOrcado: IDemGerOrcado = {
  acao: '',
  id: '',
  id_license: '',
  cnpj_estab_0000: '',
  cod_ccusto_i100: '',
  cod_cta_i050: '',
  ano: 0,
  janeiro: 0,
  fevereiro: 0,
  marco: 0,
  abril: 0,
  maio: 0,
  junho: 0,
  julho: 0,
  agosto: 0,
  setembro: 0,
  outubro: 0,
  novembro: 0,
  dezembro: 0,
  obs: '',
  tipo: '1-Previsto',
  id_tipo_dem_ger: '',
  status_janeiro: '0-Inserido pelo usuário',
  status_fevereiro: '0-Inserido pelo usuário',
  status_marco: '0-Inserido pelo usuário',
  status_abril: '0-Inserido pelo usuário',
  status_maio: '0-Inserido pelo usuário',
  status_junho: '0-Inserido pelo usuário',
  status_julho: '0-Inserido pelo usuário',
  status_agosto: '0-Inserido pelo usuário',
  status_setembro: '0-Inserido pelo usuário',
  status_outubro: '0-Inserido pelo usuário',
  status_novembro: '0-Inserido pelo usuário',
  status_dezembro: '0-Inserido pelo usuário',
};
