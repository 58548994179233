import { GridColDef } from '@material-ui/data-grid';

export const ColumnCodigoConta: GridColDef = {
  field: 'cod_cta',
  headerName: 'Conta',
  width: 200,
};

export const ColumnCodigoContaSuperior: GridColDef = {
  field: 'cod_cta_superior',
  headerName: 'Conta superior',
  width: 200,
};

export const ColumnNomeConta: GridColDef = {
  field: 'nome_cta',
  headerName: 'Nome conta',
  width: 400,
};

export const ColumnIndicadorConta: GridColDef = {
  field: 'ind_cta',
  headerName: 'Indicador conta',
  width: 200,
};

export const ColumnNivel: GridColDef = {
  field: 'nivel',
  headerName: 'Nível',
  width: 120,
  type: 'number',
};

export const ColumnAtiva: GridColDef = {
  headerName: 'Ativa',
  field: 'ativa',
  width: 120,
  type: 'boolean',
};

export const ColumnClassificacao: GridColDef = {
  field: 'classificacao',
  headerName: 'Classificação',
  width: 300,
};

export const ColumnTPClassifDRE: GridColDef = {
  headerName: 'Tipo classificação DRE',
  field: 'tp_classif_dre',
  width: 260,
};

export const ColumnCodNat: GridColDef = {
  field: 'cod_nat',
  headerName: 'Grupo de contas',
  width: 200,
};

export const ColumnExigirCentroCusto: GridColDef = {
  headerName: 'Exigir Centro Custo',
  field: 'exigir_centro_custo',
  width: 250,
  type: 'boolean',
};

export const ColumnObservacao: GridColDef = {
  headerName: 'Observação',
  field: 'obs',
  width: 400,
};

export const ColumnInf1: GridColDef = {
  headerName: 'Informação 1',
  field: 'inf1',
  width: 400,
};

export const ColumnInf2: GridColDef = {
  headerName: 'Informação 2',
  field: 'inf2',
  width: 400,
};

export const ColumnInf3: GridColDef = {
  headerName: 'Informação 3',
  field: 'inf3',
  width: 400,
};

export const ColumnInf4: GridColDef = {
  headerName: 'Informação 4',
  field: 'inf4',
  width: 400,
};

export const ColumnInf5: GridColDef = {
  headerName: 'Informação 5',
  field: 'inf5',
  width: 400,
};

export const ColumnId: GridColDef = {
  headerName: '#',
  field: 'id',
  hide: true,
  width: 400,
  editable: false,
  filterable: false,
  hideSortIcons: true,
  sortable: false,
  disableColumnMenu: true,
};
