import styled from 'styled-components';

export const Container = styled.div``;

export const Header = styled.header`
  padding: 32px 0;
  background: #28262e;
`;

export const HeaderContent = styled.div`
  max-width: 1120px;
  margin: 0 auto;
  display: flex;
  align-items: center;

  > img {
    height: 80px;
  }

  button {
    margin-left: auto;
    background: transparent;
    border: 0;

    svg {
      color: #999591;
      width: 20px;
      height: 20px;
    }
  }
`;

export const Profile = styled.div`
  display: flex;
  align-items: center;
  margin-left: 80px;

  img {
    width: 56px;
    height: 56px;
    border-radius: 50%;
  }

  svg {
    width: 56px;
    height: 56px;
    border-radius: 50%;
  }

  div {
    display: flex;
    flex-direction: column;
    margin-left: 16px;
    line-height: 24px;

    span {
      color: #f4ede8;
    }

    a {
      text-decoration: none;
      color: #006699;

      &:hover {
        opacity: 0.8;
      }
    }
  }
`;
export const Content = styled.div`
  display: flex;
`;

export const Menu = styled.div`
  margin-top: 5px;
  margin-right: 5px;
  width: 300px;
  height: 100%;

  flex-direction: column;

  div {
    margin-top: 10px;
  }
`;

export const ContainerPowerBI = styled.div`
  background: #232129;
  border-radius: 10px;
  padding: 16px;
  width: 900px;
  height: 100%;
  margin-top: 10px;
  margin-left: 10px;

  border: 2px solid #006699;
  color: #006699;
  display: flex;
  align-items: center;
  flex-direction: column;

  a {
    text-decoration: none;
    color: #fff;

    &:hover {
      opacity: 0.8;
    }
  }
`;
