import styled from 'styled-components';
import { shade } from 'polished';

export const TitleSearch = styled.span`
  padding-left: 30%;
  color: #006699;
  font-weight: bold;
  font-family: sans-serif;
  white-space: pre-wrap;
`;

export const ButtonGroup = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const ContainerLookup = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  border-radius: 4px;
  height: 32px;
  align-items: center;

  > svg {
    margin-right: 10px;
  }
`;

export const ContainerSearch = styled.div`
  display: flex;
  flex-direction: row;
  background: #dbe4ee;
  border-radius: 4px;
  height: 32px;
  align-items: center;
  width: 100%;

  input {
    margin-left: 10px;
    border: none;
    width: 100%;
    background: #dbe4ee;
    border-radius: 4px;
  }

  button {
    margin-left: 4px;
  }
`;

export const ButtonSearch = styled.button`
  width: 24px;
  height: 24px;
  border: 0px;
  border-radius: 4px;
  background: transparent;

  &:hover {
    background: ${shade(0.2, '#dbe4ee')};
  }

  svg {
    width: 100%;
    height: 100%;
    color: #006699;
  }
`;
