import { GridColDef } from '@material-ui/data-grid';

export const ColumnCentroCusto: GridColDef = {
  headerName: 'Centro Custo',
  field: 'cod_ccusto',
  width: 200,
};

export const ColumnValorFolha: GridColDef = {
  headerName: 'Valor folha',
  field: 'valor_folha',
  width: 200,
  type: 'number',
};

export const ColumnValorProlabore: GridColDef = {
  headerName: 'Valor pró-labore',
  field: 'valor_prolabore',
  width: 230,
  type: 'number',
};

export const ColumnQtdFuncionarios: GridColDef = {
  headerName: 'Quantidade Funcionários',
  field: 'qtd_funcionarios',
  width: 250,
  type: 'number',
};

export const ColumnQtdAdmissoes: GridColDef = {
  headerName: 'Quantidade Admissões',
  field: 'qtd_admissoes',
  width: 230,
  type: 'number',
};

export const ColumnQtdDemissoes: GridColDef = {
  headerName: 'Quantidade Demissões',
  field: 'qtd_demissoes',
  width: 230,
  type: 'number',
};

export const ColumnQtdAfastamentos: GridColDef = {
  headerName: 'Quantidade Afastamentos',
  field: 'qtd_afastamentos',
  width: 250,
  type: 'number',
};

export const ColumnQtdHorasUteisMes: GridColDef = {
  headerName: 'Quantidade Horas Uteis Mês',
  field: 'qtd_horas_uteis_mes',
  width: 300,
  type: 'number',
};

export const ColumnQtdHorasTrabalhadas: GridColDef = {
  headerName: 'Quantidade Horas Trabalhadas',
  field: 'qtd_horas_trabalhadas',
  width: 280,
  type: 'number',
};

export const ColumnQtdHorasExtras: GridColDef = {
  headerName: 'Quantidade Horas Extras',
  field: 'qtd_horas_extras',
  width: 250,
  type: 'number',
};

export const ColumnQtdHorasFalta: GridColDef = {
  headerName: 'Quantidade Horas Falta',
  field: 'qtd_horas_falta',
  width: 230,
  type: 'number',
};

export const ColumnQtdHorasFerias: GridColDef = {
  headerName: 'Quantidade Horas Férias',
  field: 'qtd_horas_ferias',
  width: 250,
  type: 'number',
};

export const ColumnObservacao: GridColDef = {
  headerName: 'Observação',
  field: 'obs',
  width: 400,
};

export const ColumnInf1: GridColDef = {
  headerName: 'Informação 1',
  field: 'inf1',
  width: 400,
};

export const ColumnInf2: GridColDef = {
  headerName: 'Informação 2',
  field: 'inf2',
  width: 400,
};

export const ColumnInf3: GridColDef = {
  headerName: 'Informação 3',
  field: 'inf3',
  width: 400,
};

export const ColumnInf4: GridColDef = {
  headerName: 'Informação 4',
  field: 'inf4',
  width: 400,
};

export const ColumnInf5: GridColDef = {
  headerName: 'Informação 5',
  field: 'inf5',
  width: 400,
};

export const ColumnId: GridColDef = {
  headerName: '#',
  field: 'id',
  hide: true,
  width: 400,
  editable: false,
  filterable: false,
  hideSortIcons: true,
  sortable: false,
  disableColumnMenu: true,
};
