import styled from 'styled-components';

export const Container = styled.div`
  > header {
    height: 80px;
    background: #28262e;

    display: flex;
    align-items: center;

    > div {
      width: 100%;
      max-width: 20px;
      margin: 40px;

      svg {
        color: #999591;
        width: 24px;
        height: 24px;
      }
    }
  }
`;

export const Content = styled.div`
  display: flex;
  max-width: 3000px;
  align-items: center;
  justify-content: center;
  margin: 10px auto;
`;
