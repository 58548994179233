function getCurrency(value: string | number): number {
  if (value === undefined) return 0;
  if (value === null) return 0;
  if (value === '') return 0;

  if (typeof value === 'number') return value;

  return parseFloat(value);
}

function getDecimal(value: string | number, decimal: number): number {
  if (value === undefined) return 0;
  if (value === null) return 0;
  if (value === '') return 0;

  if (typeof value === 'number') return value;

  return parseFloat(parseFloat(value).toFixed(decimal));
}

function getFormatedDate(inValue: Date): string {
  const dia = inValue.getDate();
  const mes = inValue.getMonth() + 1;
  const ano = inValue.getFullYear();
  return `${dia.toString().padStart(2, '0')}-${mes.toString().padStart(2, '0')}/${ano}`;
}

export { getCurrency, getDecimal, getFormatedDate };
