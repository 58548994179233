import { GridColDef } from '@material-ui/data-grid';

export const ColumnDemonstrativo: GridColDef = {
  headerName: 'Demonstrativo',
  field: 'demonstrativo',
  width: 300,
};

export const ColumnConta: GridColDef = {
  field: 'cod_cta_i050',
  headerName: 'Conta',
  width: 160,
};

export const ColumnCentroCusto: GridColDef = {
  headerName: 'Centro Custo',
  field: 'cod_ccusto_i100',
  width: 200,
};

export const ColumnValor1: GridColDef = {
  headerName: 'Valor 1',
  field: 'valor1',
  width: 200,
  type: 'number',
};

export const ColumnValor2: GridColDef = {
  headerName: 'Valor 2',
  field: 'valor2',
  width: 200,
  type: 'number',
};

export const ColumnValor3: GridColDef = {
  headerName: 'Valor 3',
  field: 'valor3',
  width: 200,
  type: 'number',
};

export const ColumnValor4: GridColDef = {
  headerName: 'Valor 4',
  field: 'valor4',
  width: 200,
  type: 'number',
};

export const ColumnValor5: GridColDef = {
  headerName: 'Valor 5',
  field: 'valor5',
  width: 200,
  type: 'number',
};

export const ColumnComentario1: GridColDef = {
  headerName: 'Comentário 1',
  field: 'comentario1',
  width: 500,
};

export const ColumnComentario2: GridColDef = {
  headerName: 'Comentário 2',
  field: 'comentario2',
  width: 500,
};

export const ColumnComentario3: GridColDef = {
  headerName: 'Comentário 3',
  field: 'comentario3',
  width: 500,
};

export const ColumnComentario4: GridColDef = {
  headerName: 'Comentário 4',
  field: 'comentario4',
  width: 500,
};

export const ColumnComentario5: GridColDef = {
  headerName: 'Comentário 5',
  field: 'comentario5',
  width: 500,
};

export const ColumnIdTipoDemGer: GridColDef = {
  headerName: 'ID Demonstrativo',
  field: 'id_tipo_dem_ger',
  hide: true,
  width: 400,
  editable: false,
  filterable: false,
  hideSortIcons: true,
  sortable: false,
  disableColumnMenu: true,
};

export const ColumnId: GridColDef = {
  headerName: '#',
  field: 'id',
  hide: true,
  width: 400,
  editable: false,
  filterable: false,
  hideSortIcons: true,
  sortable: false,
  disableColumnMenu: true,
};
