import styled, { css } from 'styled-components';

export const Container = styled.div`
  width: 400px;

  p {
    &.ext_file {
      font-size: 10px;
      color: #006699;
      margin-bottom: 10px;
    }
  }
`;

export const TitleHead = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  align-content: space-between;
`;

export const TitleDiv = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  align-content: space-between;
  width: 85%;

  svg {
    margin-left: 20px;
    width: 32px;
    height: 32px;
  }
`;

export const DivDownload = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  align-content: space-between;

  svg {
    color: #217346;
  }
`;

interface IContainerFieldsProps {
  field: 'T227px' | 'T250px' | 'T300px' | 'T500px' | 'T100' | 'check' | 'id';
  disable?: boolean;
  filter_field?: boolean;
  ocultar?: boolean;
}

export const ContainerFields = styled.div<IContainerFieldsProps>`
  padding: 10px 0px 0px 0px;

  ${props =>
    props.field &&
    props.field === 'T227px' &&
    css`
      width: 227px;
    `}

  ${props =>
    props.field &&
    props.field === 'T250px' &&
    css`
      width: 250px;
    `}

  ${props =>
    props.field &&
    props.field === 'T300px' &&
    css`
      width: 300px;
    `}

  ${props =>
    props.field &&
    props.field === 'T500px' &&
    css`
      width: 516px;
    `}

  ${props =>
    props.field &&
    props.field === 'T100' &&
    css`
      width: 100%;
    `}

  ${props =>
    props.field &&
    props.field === 'id' &&
    css`
      padding: 0px 0px 30px 0px;
      height: 0px;
      width: 0px;
    `}

  span {
    ${props =>
      props.disable &&
      props.disable === true &&
      css`
        color: gray;
      `}

      ${props =>
        props.filter_field &&
        props.filter_field === true &&
        css`
          color: #006699;
        `}
  }

  div {
    ${props =>
      props.disable &&
      props.disable === true &&
      css`
        border-color: gray;
      `}

    ${props =>
      props.filter_field &&
      props.filter_field === true &&
      css`
        margin-top: 8px;
      `}

    input {
      ${props =>
        props.disable &&
        props.disable === true &&
        css`
          color: gray;
        `}
    }
  }

  select {
    ${props =>
      props.disable &&
      props.disable === true &&
      css`
        color: gray;
        border-color: gray;
      `}
  }

  ${props =>
    props.ocultar &&
    props.ocultar === true &&
    css`
      display: none;
    `}

  &.field2 {
    margin-left: 60px;
  }

  &.field2filter {
    margin-left: 6px;

    select {
      height: 23.2px;
    }
  }

  &.desabilitado {
    color: gray;

    input {
      color: gray;
    }
  }

  @media (max-width: 720px) {
    &.field2 {
      margin-left: 0px;
    }
  }

  @media (max-width: 720px) {
    &.field2filter {
      margin-left: 0px;
    }
  }
`;

export const ContainerFieldGoup = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  &.field2 {
    margin-left: 60px;
  }

  @media (max-width: 720px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

export const ContainerFilterSelect = styled.div`
  background: #fff;
  border-radius: 4px;
  padding: 5px;
  width: 100%;
  border: 1px solid #006699;
  color: #666360;
  display: flex;
  align-items: center;

  & + div {
    margin-top: 1px;
  }

  select {
    flex: 1;
    background: transparent;
    border: 0;
    color: #000;
    width: 100%;
    border: none;
    height: 100%;
    font-size: 16px;
    cursor: pointer;

    &::placeholder {
      color: #989898;
    }
  }

  option {
    display: block;
    position: absolute;
    cursor: pointer;
    padding: 15px 0;
    font-size: 16px;
    zoom: 1.2;
  }

  svg {
    margin-right: 16px;
  }
`;

export const ContainerFilterInput = styled.div`
  background: #fff;
  border-radius: 4px;
  padding: 5px;
  width: 100%;
  border: 1px solid #006699;
  color: #666360;
  display: flex;
  align-items: center;

  & + div {
    margin-top: 1px;
  }

  input {
    border: none;
    width: 100%;
  }
`;
