import React, { useState, useEffect, useCallback } from 'react';
import { DataGrid, GridColDef, GridCellParams, ptBR } from '@material-ui/data-grid';
import { createMuiTheme, ThemeProvider, makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import { MdDelete, MdCancel } from 'react-icons/md';
import { AiOutlineCaretDown, AiOutlineCaretUp } from 'react-icons/ai';
import { FiUploadCloud } from 'react-icons/fi';
import Header from '../../components/Header';
import FormModalDialogConfirm from '../../components/FormModalDialogConfirm';
import api from '../../services/api';
import { useAuth } from '../../hooks/Auth';
import { useToast } from '../../hooks/Toast';
import { getPathGrid } from '../../utils/storagePath';
import ModalUploadFileTXT from '../../components/ModalUploadFileTXT';
import {
  ColumnRemover,
  ColumnReferencia,
  ColumnTipo,
  ColumnId,
  ColumnFTP,
  ColumnFile,
  ColumnObservacao,
} from './GridColumns';
import IHistoricoImportacao from './IHistoricoImportacao';
import {
  Container,
  Tab,
  TabButton,
  TabContent,
  TabTitulo,
  TabButtonArea,
  TabButtonGridRowHeight,
  ContainerChip,
} from './styles';

const useStylesLoading = makeStyles((theme: Theme) =>
  createStyles({
    backdrop: {
      zIndex: theme.zIndex.drawer + 2000,
      color: '#fff',
    },
  }),
);

const theme = createMuiTheme(
  {
    palette: {
      primary: { main: '#006699' },
    },
  },
  ptBR,
);

const titulo = 'A R Q U I V O S    S P E D';

interface IHistoricoImportacaoUpdate {
  remover: boolean;
}

const CadHistImportacao: React.FC = () => {
  const { user } = useAuth();
  const { addToast } = useToast();
  const cssLoading = useStylesLoading();
  const [gridRowHeight, setGridRowHeight] = useState<number>(46);
  const [openLoading, setOpenLoading] = useState(false);
  const [listHistoricoImportacao, setListHistoricoImportacao] = useState<IHistoricoImportacao[]>([]);
  const [openUploadFileTxt, setOpenUploadFileTxt] = useState<boolean>(false);
  const [openDeleteSingle, setOpenDeleteSingle] = useState(false);
  const [idDelete, setIdDelete] = useState<string>('');

  const handleGridRowHeightUp = useCallback(async () => {
    const heightValue: number = gridRowHeight + 1;
    setGridRowHeight(heightValue);
    localStorage.setItem(getPathGrid(), heightValue.toString());
  }, [gridRowHeight]);

  const handleGridRowHeightDown = useCallback(async () => {
    const heightValue: number = gridRowHeight - 1;
    setGridRowHeight(heightValue);
    localStorage.setItem(getPathGrid(), heightValue.toString());
  }, [gridRowHeight]);

  const licencaInvalida = useCallback((): boolean => {
    if (!user.license || user.license.id === '') {
      addToast({
        type: 'error',
        title: 'Licença inválida',
        description: 'Usuário não está vinculado a nenhuma licença',
      });

      return true;
    }

    return false;
  }, [addToast, user]);

  const OnClickChipRemover = useCallback(
    async (id: string, remover: boolean, ftp: boolean) => {
      const formData: IHistoricoImportacaoUpdate = {
        remover,
      };

      if (ftp) {
        setIdDelete(id);
        setOpenDeleteSingle(true);
      } else {
        try {
          setOpenLoading(true);
          await api.put(`/historicoimportacao/${id}`, formData);
          const historico = listHistoricoImportacao.find(item => item.id === id);
          if (historico) {
            historico.remover = remover;
          }
          addToast({
            type: 'success',
            title: 'Alterado',
            description: 'Registro alterado com sucesso!',
          });
          setOpenLoading(false);
        } catch (err) {
          setOpenLoading(false);
        }
      }
    },
    [addToast, listHistoricoImportacao],
  );

  const ColumnDataInicio: GridColDef = {
    headerName: 'Data Início',
    field: 'data_inicio',
    width: 170,
    type: 'date',
    renderCell: (params: GridCellParams) => {
      if (params.value === null) return <strong />;
      const ano = (params.value as string).substring(0, 4);
      const mes = (params.value as string).substring(5, 7);
      const dia = (params.value as string).substring(8, 10);

      const dataFormatada = `${dia}/${mes}/${ano}`;
      return <strong>{dataFormatada}</strong>;
    },
  };

  const ColumnDataFim: GridColDef = {
    headerName: 'Data Fim',
    field: 'data_fim',
    width: 170,
    type: 'date',
    renderCell: (params: GridCellParams) => {
      if (params.value === null) return <strong />;
      const ano = (params.value as string).substring(0, 4);
      const mes = (params.value as string).substring(5, 7);
      const dia = (params.value as string).substring(8, 10);

      const dataFormatada = `${dia}/${mes}/${ano}`;
      return <strong>{dataFormatada}</strong>;
    },
  };

  const ColumnDataCarregamento: GridColDef = {
    headerName: 'Data Carregamento',
    field: 'data_carregamento',
    width: 240,
    renderCell: (params: GridCellParams) => {
      if (params.value === null) return <strong />;
      const x = new Date(`${params.value}`);

      const year = x.getFullYear();
      const month = x.getMonth() + 1;
      const monthText = month < 9 ? `0${month}` : `${month}`;
      const date = x.getDate();
      const dateText = date < 9 ? `0${date}` : `${date}`;

      const hour = x.getHours();
      const hourText = hour < 9 ? `0${hour}` : `${hour}`;
      const min = x.getMinutes();
      const minText = min < 9 ? `0${min}` : `${min}`;
      const sec = x.getSeconds();
      const secText = sec < 9 ? `0${sec}` : `${sec}`;
      const time = `${dateText}/${monthText}/${year} ${hourText}:${minText}:${secText}`;

      return <strong>{time}</strong>;
    },
  };

  const ColumnAcao: GridColDef = {
    headerName: ' ',
    field: 'acao',
    width: 120,
    align: 'center',
    editable: false,
    filterable: false,
    hideSortIcons: true,
    sortable: false,
    disableColumnMenu: true,

    renderCell: (params: GridCellParams) => {
      const id: string = params.row.id as string;
      const remover: boolean = params.row.remover as boolean;
      const ftp: boolean = params.row.ftp as boolean;
      if (remover === true) {
        return (
          <ContainerChip>
            <Tooltip title="Cancelar" placement="top">
              <IconButton color="primary" aria-label="Cancelar" onClick={() => OnClickChipRemover(id, !remover, ftp)}>
                <MdCancel />
              </IconButton>
            </Tooltip>
          </ContainerChip>
        );
      }

      return (
        <ContainerChip>
          <Tooltip title="Excluir Importação" placement="top">
            <IconButton color="primary" aria-label="Excluir" onClick={() => OnClickChipRemover(id, !remover, ftp)}>
              <MdDelete />
            </IconButton>
          </Tooltip>
        </ContainerChip>
      );
    },
  };

  const columns: GridColDef[] = [
    ColumnAcao,
    ColumnRemover,
    ColumnFTP,
    ColumnTipo,
    ColumnDataInicio,
    ColumnDataFim,
    ColumnDataCarregamento,
    ColumnReferencia,
    ColumnObservacao,
    ColumnFile,
    ColumnId,
  ];

  const getHistoricoImportacao = useCallback(async () => {
    if (licencaInvalida()) return;

    const response = await api.get<IHistoricoImportacao[]>(`/historicoimportacao/${user.empresa.id}`);

    setListHistoricoImportacao(response.data);
  }, [user, licencaInvalida]);

  const getValues = useCallback(async () => {
    setOpenLoading(true);

    await getHistoricoImportacao();

    setOpenLoading(false);
  }, [getHistoricoImportacao]);

  useEffect(() => {
    getValues();

    const rowHeight = localStorage.getItem(getPathGrid());
    if (rowHeight) {
      setGridRowHeight(parseInt(rowHeight, 10));
    }
  }, [getValues]);

  const onUploadFileClose = useCallback(() => {
    setOpenUploadFileTxt(false);
    getValues();
  }, [getValues]);

  const handleOnRowDeleteSingle = useCallback(async () => {
    setOpenLoading(true);
    await api.delete(`historicoimportacao/${idDelete}`);
    setListHistoricoImportacao(oldValue => oldValue.filter(item => item.id !== idDelete));
    addToast({
      type: 'success',
      title: 'Removido',
      description: 'Registro excluído com sucesso!',
    });
    setOpenDeleteSingle(false);
    setOpenLoading(false);
  }, [idDelete, addToast]);

  return (
    <Container>
      <Header />

      <Tab>
        <TabButtonArea>
          <TabButton id="pesquisar" isActive>
            Listagem
          </TabButton>
          <Tooltip title="Reduzir o tamanho da linha" placement="top">
            <TabButtonGridRowHeight id="gridrowheightdown" onClick={() => handleGridRowHeightDown()}>
              <AiOutlineCaretDown />
            </TabButtonGridRowHeight>
          </Tooltip>

          <Tooltip title="Aumentar o tamanho da linha" placement="top">
            <TabButtonGridRowHeight id="gridrowheightup" onClick={() => handleGridRowHeightUp()}>
              <AiOutlineCaretUp />
            </TabButtonGridRowHeight>
          </Tooltip>

          <Tooltip title="Importar arquivo" placement="top">
            <TabButton id="importar_csv" isActive={false} onClick={() => setOpenUploadFileTxt(true)}>
              <FiUploadCloud />
            </TabButton>
          </Tooltip>
        </TabButtonArea>

        <TabTitulo>
          <span>{titulo}</span>
        </TabTitulo>
      </Tab>

      <TabContent id="pesquisar" isActive>
        <div style={{ height: '85vh', width: '100%' }}>
          <ThemeProvider theme={theme}>
            <DataGrid
              rows={listHistoricoImportacao}
              columns={columns}
              rowHeight={gridRowHeight}
              disableSelectionOnClick
            />
          </ThemeProvider>
        </div>
      </TabContent>

      <ModalUploadFileTXT open={openUploadFileTxt} onClose={onUploadFileClose} />

      <FormModalDialogConfirm
        open={openDeleteSingle}
        onClose={() => setOpenDeleteSingle(false)}
        onConfirm={() => handleOnRowDeleteSingle()}
        buttonCancelText="Cancelar"
        buttonConfirmText="Sim"
        formTitle="Excluir?"
        messageConfirm="Tem certeza que deseja excluir?"
      />

      <Backdrop className={cssLoading.backdrop} open={openLoading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </Container>
  );
};

export default CadHistImportacao;
