import React, { useRef, useState, useEffect, useCallback } from 'react';
import * as Yup from 'yup';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import { uuid } from 'uuidv4';
import filesize from 'filesize';
import { DataGrid, GridColDef, GridCellParams, GridRowId, ptBR } from '@material-ui/data-grid';
import { createMuiTheme, ThemeProvider, makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import Backdrop from '@material-ui/core/Backdrop';
import ButtonDialog from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { FiCopy } from 'react-icons/fi';
import { MdDelete, MdEdit, MdClear, MdSkipNext, MdSkipPrevious, MdContentCopy } from 'react-icons/md';
import {
  AiOutlinePlus,
  AiOutlineSave,
  AiOutlineSearch,
  AiFillFastBackward,
  AiFillFastForward,
  AiOutlineCaretDown,
  AiOutlineCaretUp,
} from 'react-icons/ai';
import { GoPlus } from 'react-icons/go';
import { RiDeleteBinLine } from 'react-icons/ri';
import InputComum from '../../components/InputComum';
import Select from '../../components/Select';
import Header from '../../components/Header';
import UploadFile from '../../components/UploadFile';
import FileList from '../../components/FileList';
import IFile from '../../components/FileList/IFile';
import FormModalDialogConfirm from '../../components/FormModalDialogConfirm';
import LookupIndicadorPersonalizado from '../../components/LookupIndicadorPersonalizado';
import IIndicadorPersonalizadoLookup from '../../hooks/Lookup/IIndicadorPersonalizadoLookup';
import api from '../../services/api';
import { useAuth } from '../../hooks/Auth';
import { useToast } from '../../hooks/Toast';
import { useLookup } from '../../hooks/Lookup/index';
import getGenericListUpdate from '../../utils/getGenericListUpdate';
import getValidationErrors from '../../utils/getValidationErrors';
import { getDecimal } from '../../utils/formatValues';
import { getPathGrid } from '../../utils/storagePath';
import * as GridColumns from './GridColumns';
import IIndicadorPersonalizadoLcto, { emptyIndicadorPersonalizadoLcto } from './IIndicadorPersonalizadoLcto';
import IIndicadorPersonalizadoLctoFilter from './IIndicadorPersonalizadoLctoFilter';
import {
  Container,
  ContainerFields,
  Tab,
  TabButton,
  TabContent,
  FormFields,
  ContainerAllMonthPrice,
  ContainerQuadro,
  FormContainer,
  FormNavigate,
  SpanFiltro,
  TabTitulo,
  TabButtonArea,
  FormContainerValue,
  FormContainerValueMes,
  TabButtonNovo,
  TabButtonDelete,
  TabButtonGridRowHeight,
  ContainerFieldTextFilter,
  ContainerFilterSelect,
} from './styles';

const useStylesLoading = makeStyles((in_theme: Theme) =>
  createStyles({
    backdrop: {
      zIndex: in_theme.zIndex.drawer + 1700,
      color: '#fff',
    },
  }),
);

const theme = createMuiTheme(
  {
    palette: {
      primary: { main: '#006699' },
    },
  },
  ptBR,
);

const options_tipo_apontamento = [
  { value: '1-Meta', label: '1-Meta' },
  { value: '2-Realizado', label: '2-Realizado' },
  { value: '3-Estimado', label: '3-Estimado' },
  { value: '9-Outros', label: '9-Outros' },
];

const titulo = 'L A N Ç A M E N T O   D O S   I N D I C A D O R E S';
const FTP_KIND_FILE_INDICADORES_CSV = '4';
const FTP_KIND_FILE_INDICADORES_HTML = '5';
const FTP_KIND_FILE_INDICADORES_JSON = '6';
const FTP_KIND_FILE_INDICADORES_OUTROS = '7';
const FTP_KIND_FILE_INDICADORES_TXT = '8';
const FTP_KIND_FILE_INDICADORES_XLS = '9';
const FTP_KIND_FILE_INDICADORES_XLSM = '10';
const FTP_KIND_FILE_INDICADORES_XLSX = '11';
const FTP_KIND_FILE_INDICADORES_XML = '12';

const IndicadorPersonalizadoLcto: React.FC = () => {
  const { user } = useAuth();
  const { addToast } = useToast();
  const formRef = useRef<FormHandles>(null);
  const [indicadorPersonalizadoLcto, setIndicadorPersonalizadoLcto] = useState<IIndicadorPersonalizadoLcto>();
  const [listIndicadorPersonalizadoLcto, setListIndicadorPersonalizadoLcto] = useState<IIndicadorPersonalizadoLcto[]>(
    [],
  );
  const [hiddenPesquisar, setHiddenPesquisar] = useState<boolean>(true);
  const [hiddenCadastrar, setHiddenCadastrar] = useState<boolean>(true);
  const [IndicadorPersonalizadoLctoIndex, setIndicadorPersonalizadoLctoIndex] = useState<number>(0);
  const [rowToEdit, setRowToEdit] = useState(null);
  const [idDeleteIndicadorPersonalizadoLcto, setIdDeleteIndicadorPersonalizadoLcto] = useState<string>('');
  const [openDeleteSingle, setOpenDeleteSingle] = useState(false);
  const [openDeleteMultiple, setOpenDeleteMultiple] = useState(false);
  const [openFilter, setOpenFilter] = React.useState(true);
  const [gridRowHeight, setGridRowHeight] = useState<number>(46);
  const [textFiltro, setTextFiltro] = useState<string>('');
  const [openLoading, setOpenLoading] = React.useState(false);
  const cssLoading = useStylesLoading();
  const [selectedIds, setSelectedIds] = useState<GridRowId[]>([]);
  const { getListIndicadorPersonalizado, updateListIndicadorPersonalizado } = useLookup();
  const handleGridRowHeightUp = useCallback(async () => {
    const heightValue: number = gridRowHeight + 1;
    setGridRowHeight(heightValue);
    localStorage.setItem(getPathGrid(), heightValue.toString());
  }, [gridRowHeight]);
  const [uploadFTP, setUploadFTP] = useState<number>(0);
  const [uploadedFiles, setUploadedFiles] = useState<IFile[]>([]);
  const [uploadedFilesError, setUploadedFilesError] = useState<IFile[]>([]);
  const [uploadedFilesFinished, setUploadedFilesFinished] = useState<IFile[]>([]);

  const handleGridRowHeightDown = useCallback(async () => {
    const heightValue: number = gridRowHeight - 1;
    setGridRowHeight(heightValue);
    localStorage.setItem(getPathGrid(), heightValue.toString());
  }, [gridRowHeight]);

  const licencaInvalida = useCallback((): boolean => {
    if (!user.license || user.license.id === '') {
      addToast({
        type: 'error',
        title: 'Licença inválida',
        description: 'Usuário não está vinculado a nenhuma licença',
      });

      return true;
    }

    return false;
  }, [addToast, user]);

  const handleCloseFilter = useCallback(() => {
    setOpenFilter(false);
  }, []);

  const defineIndicadorPersonalizadoLcto = useCallback(async (data: IIndicadorPersonalizadoLcto) => {
    setIndicadorPersonalizadoLcto(data);
    formRef.current?.setData(data);
    setUploadedFiles([]);
  }, []);

  const onNewRegister = useCallback(async () => {
    defineIndicadorPersonalizadoLcto(emptyIndicadorPersonalizadoLcto);
  }, [defineIndicadorPersonalizadoLcto]);

  const OnGridSetRegisterToEdit = useCallback(
    async (params: GridCellParams) => {
      const localIndicadorPersonalizadoLcto = params.row as IIndicadorPersonalizadoLcto;

      setIndicadorPersonalizadoLctoIndex(listIndicadorPersonalizadoLcto.indexOf(localIndicadorPersonalizadoLcto));
      setHiddenCadastrar(false);
      setHiddenPesquisar(true);
      setRowToEdit(null);
      defineIndicadorPersonalizadoLcto(localIndicadorPersonalizadoLcto);
    },
    [listIndicadorPersonalizadoLcto, defineIndicadorPersonalizadoLcto],
  );

  const OnSetRegisterToAdd = useCallback(async () => {
    onNewRegister();
    setHiddenCadastrar(false);
    setHiddenPesquisar(true);
  }, [onNewRegister]);

  const OnGridRowDuplicate = useCallback(
    async (baseIndicadorPersonalizadoLcto: IIndicadorPersonalizadoLcto | undefined) => {
      if (baseIndicadorPersonalizadoLcto !== undefined) {
        const newIndicadorPersonalizadoLcto: IIndicadorPersonalizadoLcto = {
          acao: baseIndicadorPersonalizadoLcto.acao,
          id_indic_person_lcto: '',
          id_license: baseIndicadorPersonalizadoLcto.id_license,
          ano: baseIndicadorPersonalizadoLcto.ano,
          vlr_janeiro: baseIndicadorPersonalizadoLcto.vlr_janeiro,
          vlr_fevereiro: baseIndicadorPersonalizadoLcto.vlr_fevereiro,
          vlr_marco: baseIndicadorPersonalizadoLcto.vlr_marco,
          vlr_abril: baseIndicadorPersonalizadoLcto.vlr_abril,
          vlr_maio: baseIndicadorPersonalizadoLcto.vlr_maio,
          vlr_junho: baseIndicadorPersonalizadoLcto.vlr_junho,
          vlr_julho: baseIndicadorPersonalizadoLcto.vlr_julho,
          vlr_agosto: baseIndicadorPersonalizadoLcto.vlr_agosto,
          vlr_setembro: baseIndicadorPersonalizadoLcto.vlr_setembro,
          vlr_outubro: baseIndicadorPersonalizadoLcto.vlr_outubro,
          vlr_novembro: baseIndicadorPersonalizadoLcto.vlr_novembro,
          vlr_dezembro: baseIndicadorPersonalizadoLcto.vlr_dezembro,
          obs: baseIndicadorPersonalizadoLcto.obs,
          inf1: baseIndicadorPersonalizadoLcto.inf1,
          inf2: baseIndicadorPersonalizadoLcto.inf2,
          inf3: baseIndicadorPersonalizadoLcto.inf3,
          inf4: baseIndicadorPersonalizadoLcto.inf4,
          inf5: baseIndicadorPersonalizadoLcto.inf5,
          tipo_apontamento: baseIndicadorPersonalizadoLcto.tipo_apontamento,
          cod_indicador: baseIndicadorPersonalizadoLcto.cod_indicador,
          desc_indicador: baseIndicadorPersonalizadoLcto.desc_indicador,
          kind_interface: 'indic_person_lcto',
          id_company: baseIndicadorPersonalizadoLcto.id_company,
          id_cad_indic_person: baseIndicadorPersonalizadoLcto.id_cad_indic_person,
          file_name: baseIndicadorPersonalizadoLcto.file_name,
          file_path: baseIndicadorPersonalizadoLcto.file_path,
          file_type: baseIndicadorPersonalizadoLcto.file_type,
        };

        defineIndicadorPersonalizadoLcto(newIndicadorPersonalizadoLcto);
        setHiddenCadastrar(false);
        setHiddenPesquisar(true);
      }
    },
    [defineIndicadorPersonalizadoLcto],
  );

  const OnConfirmationDeleteSingle = useCallback(
    async (id: string) => {
      if (licencaInvalida()) return;

      if (id === '') return;

      setIdDeleteIndicadorPersonalizadoLcto(id);
      setOpenDeleteSingle(true);
    },
    [licencaInvalida],
  );

  const colunaAcao: GridColDef = {
    field: 'acao',
    headerName: 'Ação',
    editable: false,
    filterable: false,
    hideSortIcons: true,
    sortable: false,
    disableColumnMenu: true,
    width: 160,
    renderCell: (params: GridCellParams) => (
      <strong>
        <Tooltip title="Editar Registro" placement="top">
          <IconButton color="primary" aria-label="Editar" onClick={() => OnGridSetRegisterToEdit(params)}>
            <MdEdit />
          </IconButton>
        </Tooltip>

        <Tooltip title="Duplicar Registro" placement="top">
          <IconButton
            color="primary"
            aria-label="Duplicar"
            onClick={() => OnGridRowDuplicate(params.row as IIndicadorPersonalizadoLcto)}
          >
            <FiCopy />
          </IconButton>
        </Tooltip>

        <Tooltip title="Excluir Registro" placement="top">
          <IconButton
            color="primary"
            aria-label="Excluir"
            onClick={() => OnConfirmationDeleteSingle((params.row as IIndicadorPersonalizadoLcto).id_indic_person_lcto)}
          >
            <MdDelete />
          </IconButton>
        </Tooltip>
      </strong>
    ),
  };

  const columns: GridColDef[] = [
    colunaAcao,
    GridColumns.ColumnAno,
    GridColumns.ColumnCodigoIndicador,
    GridColumns.ColumnIndicador,
    GridColumns.ColumnTipoApontamento,
    GridColumns.ColumnVlrJaneiro,
    GridColumns.ColumnVlrFevereiro,
    GridColumns.ColumnVlrMarco,
    GridColumns.ColumnVlrAbril,
    GridColumns.ColumnVlrMaio,
    GridColumns.ColumnVlrJunho,
    GridColumns.ColumnVlrJulho,
    GridColumns.ColumnVlrAgosto,
    GridColumns.ColumnVlrSetembro,
    GridColumns.ColumnVlrOutubro,
    GridColumns.ColumnVlrNovembro,
    GridColumns.ColumnVlrDezembro,
    GridColumns.ColumnObservacao,
    GridColumns.ColumnInf1,
    GridColumns.ColumnInf2,
    GridColumns.ColumnInf3,
    GridColumns.ColumnInf4,
    GridColumns.ColumnInf5,
    GridColumns.ColumnIdCadIndicPerson,
    GridColumns.ColumnIdIndicPersonLcto,
  ];

  const getIndicadorPersonalizadoLcto = useCallback(
    async (inFilter: IIndicadorPersonalizadoLctoFilter) => {
      if (licencaInvalida()) return;

      setOpenLoading(true);
      try {
        const response = await api.post<IIndicadorPersonalizadoLcto[]>(`indicadorpersonalizadolcto/v1/filter`, {
          id_license: user.license.id,
          id_company: user.empresa.id,
          ano: inFilter.ano,
          tipo_apontamento: inFilter.tipo_apontamento,
          listIdsCadIndicPerson: inFilter.listIdsCadIndicPerson,
        });

        setListIndicadorPersonalizadoLcto(response.data);
        if (rowToEdit !== null) {
          const findEditIndicadorPersonalizadoLcto = response.data.find(
            item => item.id_indic_person_lcto === rowToEdit,
          );

          if (findEditIndicadorPersonalizadoLcto) {
            defineIndicadorPersonalizadoLcto(findEditIndicadorPersonalizadoLcto);
            setIndicadorPersonalizadoLctoIndex(response.data.indexOf(findEditIndicadorPersonalizadoLcto));
            setHiddenCadastrar(false);
            setHiddenPesquisar(true);
            setRowToEdit(null);
          }
        }
      } catch (error) {
        let localMessage = '';
        if (typeof error === 'string') {
          localMessage = error.toUpperCase();
        } else if (error instanceof Error) {
          localMessage = error.message;
        }

        addToast({
          type: 'error',
          title: 'Erro na requisição',
          description: localMessage,
        });
      }

      setHiddenPesquisar(false);
      setOpenLoading(false);
    },
    [user, licencaInvalida, rowToEdit, defineIndicadorPersonalizadoLcto, addToast],
  );

  useEffect(() => {
    const rowHeight = localStorage.getItem(getPathGrid());
    if (rowHeight) {
      setGridRowHeight(parseInt(rowHeight, 10));
    }

    uploadedFilesFinished.map(item => {
      const findItem = uploadedFiles.find(it => it.id === item.id);
      if (findItem) {
        findItem.isUploading = false;
        findItem.uploaded = true;
      }

      return findItem;
    });

    uploadedFilesError.map(item => {
      const findItem = uploadedFiles.find(it => it.id === item.id);
      if (findItem) {
        findItem.isUploading = false;
        findItem.error = true;
      }

      return findItem;
    });
  }, [uploadedFiles, uploadedFilesError, uploadedFilesFinished]);

  const handleOnRowDeleteSingle = useCallback(async () => {
    setOpenLoading(true);
    await api.delete(`indicadorpersonalizadolcto/v1/${idDeleteIndicadorPersonalizadoLcto}`);

    setListIndicadorPersonalizadoLcto(oldValue =>
      oldValue.filter(item => item.id_indic_person_lcto !== idDeleteIndicadorPersonalizadoLcto),
    );

    defineIndicadorPersonalizadoLcto(emptyIndicadorPersonalizadoLcto);

    addToast({
      type: 'success',
      title: 'Removido',
      description: 'Registro excluído com sucesso!',
    });

    setOpenDeleteSingle(false);
    setOpenLoading(false);
  }, [idDeleteIndicadorPersonalizadoLcto, addToast, defineIndicadorPersonalizadoLcto]);

  const handleOnRowDeleteMultiple = useCallback(async () => {
    setOpenLoading(true);
    if (selectedIds.length > 0) {
      await Promise.all(
        selectedIds.map(itemId => {
          setListIndicadorPersonalizadoLcto(oldValue => oldValue.filter(item => item.id_indic_person_lcto !== itemId));

          return api.delete(`indicadorpersonalizadolcto/v1/${itemId}`);
        }),
      );

      defineIndicadorPersonalizadoLcto(emptyIndicadorPersonalizadoLcto);

      addToast({
        type: 'success',
        title: 'Removido',
        description: 'Registro excluído com sucesso!',
      });
    }

    setSelectedIds([]);
    setOpenDeleteMultiple(false);
    setOpenLoading(false);
  }, [addToast, defineIndicadorPersonalizadoLcto, selectedIds]);

  const handleClick = useCallback(
    (id: string): void => {
      if (id === 'filtro') {
        updateListIndicadorPersonalizado([]);
        setOpenFilter(id === 'filtro');
        return;
      }

      setHiddenCadastrar(id === 'pesquisar');
      setHiddenPesquisar(id === 'cadastrar');
    },
    [updateListIndicadorPersonalizado],
  );

  const onNextRegister = useCallback(async () => {
    if (IndicadorPersonalizadoLctoIndex !== undefined) {
      if (IndicadorPersonalizadoLctoIndex < listIndicadorPersonalizadoLcto.length - 1) {
        const localIndex = IndicadorPersonalizadoLctoIndex + 1;
        setIndicadorPersonalizadoLctoIndex(localIndex);
        const findIndicadorPersonalizadoLcto = listIndicadorPersonalizadoLcto[localIndex];
        if (findIndicadorPersonalizadoLcto) {
          defineIndicadorPersonalizadoLcto(findIndicadorPersonalizadoLcto);
        }
      }
    }
  }, [IndicadorPersonalizadoLctoIndex, listIndicadorPersonalizadoLcto, defineIndicadorPersonalizadoLcto]);

  const onFirstRegister = useCallback(async () => {
    if (listIndicadorPersonalizadoLcto.length > 0) {
      setIndicadorPersonalizadoLctoIndex(0);
      const findIndicadorPersonalizadoLcto = listIndicadorPersonalizadoLcto[0];
      if (findIndicadorPersonalizadoLcto) {
        defineIndicadorPersonalizadoLcto(findIndicadorPersonalizadoLcto);
      }
    }
  }, [listIndicadorPersonalizadoLcto, defineIndicadorPersonalizadoLcto]);

  const onLastRegister = useCallback(async () => {
    if (listIndicadorPersonalizadoLcto.length > 0) {
      setIndicadorPersonalizadoLctoIndex(listIndicadorPersonalizadoLcto.length - 1);
      const findIndicadorPersonalizadoLcto = listIndicadorPersonalizadoLcto[listIndicadorPersonalizadoLcto.length - 1];
      if (findIndicadorPersonalizadoLcto) {
        defineIndicadorPersonalizadoLcto(findIndicadorPersonalizadoLcto);
      }
    }
  }, [listIndicadorPersonalizadoLcto, defineIndicadorPersonalizadoLcto]);

  const onPriorRegister = useCallback(async () => {
    if (IndicadorPersonalizadoLctoIndex !== undefined) {
      if (IndicadorPersonalizadoLctoIndex > 0) {
        const localIndex = IndicadorPersonalizadoLctoIndex - 1;
        setIndicadorPersonalizadoLctoIndex(localIndex);
        const findIndicadorPersonalizadoLcto = listIndicadorPersonalizadoLcto[localIndex];
        if (findIndicadorPersonalizadoLcto) {
          defineIndicadorPersonalizadoLcto(findIndicadorPersonalizadoLcto);
        }
      }
    }
  }, [IndicadorPersonalizadoLctoIndex, listIndicadorPersonalizadoLcto, defineIndicadorPersonalizadoLcto]);

  const onCancelRegister = useCallback(async () => {
    formRef.current?.setErrors({});
    setUploadedFiles([]);
    if (IndicadorPersonalizadoLctoIndex !== undefined) {
      const findIndicadorPersonalizadoLcto = listIndicadorPersonalizadoLcto[IndicadorPersonalizadoLctoIndex];
      if (findIndicadorPersonalizadoLcto) {
        defineIndicadorPersonalizadoLcto(findIndicadorPersonalizadoLcto);
      }
    }
    onNewRegister();
  }, [
    IndicadorPersonalizadoLctoIndex,
    listIndicadorPersonalizadoLcto,
    onNewRegister,
    defineIndicadorPersonalizadoLcto,
  ]);

  const handleSubmit = useCallback(
    async (data: IIndicadorPersonalizadoLcto, { reset }) => {
      if (licencaInvalida()) return;

      if (
        data.id_cad_indic_person === null ||
        data.id_cad_indic_person === undefined ||
        data.id_cad_indic_person.trim() === ''
      ) {
        addToast({
          type: 'error',
          title: 'Indicador inválido',
          description: 'Por favor informe um indicador!',
        });

        return;
      }

      formRef.current?.setErrors({});
      try {
        const schema = Yup.object().shape({
          id_cad_indic_person: Yup.string().required('Indicador é obrigatório'),
          ano: Yup.number()
            .moreThan(1900, `Ano deve ser maior que 1900`)
            .lessThan(2200, `Ano deve ser menor que 2200`)
            .required(`Ano é obrigatório`),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        setOpenLoading(true);

        const formData: IIndicadorPersonalizadoLcto = {
          acao: data.acao,
          id_indic_person_lcto: data.id_indic_person_lcto,
          id_license: user.license.id,
          id_company: user.empresa.id,
          id_cad_indic_person: data.id_cad_indic_person,
          ano: data.ano,
          vlr_janeiro: getDecimal(data.vlr_janeiro, 4),
          vlr_fevereiro: getDecimal(data.vlr_fevereiro, 4),
          vlr_marco: getDecimal(data.vlr_marco, 4),
          vlr_abril: getDecimal(data.vlr_abril, 4),
          vlr_maio: getDecimal(data.vlr_maio, 4),
          vlr_junho: getDecimal(data.vlr_junho, 4),
          vlr_julho: getDecimal(data.vlr_julho, 4),
          vlr_agosto: getDecimal(data.vlr_agosto, 4),
          vlr_setembro: getDecimal(data.vlr_setembro, 4),
          vlr_outubro: getDecimal(data.vlr_outubro, 4),
          vlr_novembro: getDecimal(data.vlr_novembro, 4),
          vlr_dezembro: getDecimal(data.vlr_dezembro, 4),
          tipo_apontamento: data.tipo_apontamento,
          obs: data.obs ? data.obs : '',
          inf1: data.inf1 ? data.inf1 : '',
          inf2: data.inf2 ? data.inf2 : '',
          inf3: data.inf3 ? data.inf3 : '',
          inf4: data.inf4 ? data.inf4 : '',
          inf5: data.inf5 ? data.inf5 : '',
          cod_indicador: data.cod_indicador,
          desc_indicador: data.desc_indicador,
          kind_interface: 'indic_person_lcto',
          file_name: data.file_name,
          file_path: data.file_path,
          file_type: data.file_type,
        };

        if (formData.id_indic_person_lcto === '') {
          setOpenLoading(true);
          const response = await api.post(`indicadorpersonalizadolcto/v1`, formData);
          formData.id_indic_person_lcto = response.data.id_indic_person_lcto;
          setListIndicadorPersonalizadoLcto(oldValues => [...oldValues, formData]);
          setOpenLoading(false);
          addToast({
            type: 'success',
            title: 'Adicionado',
            description: 'Registro adicionado com sucesso!',
          });
        } else {
          setOpenLoading(true);
          await api.put(`indicadorpersonalizadolcto/v1/${formData.id_indic_person_lcto}`, formData);
          setListIndicadorPersonalizadoLcto(getGenericListUpdate(listIndicadorPersonalizadoLcto, formData));
          setOpenLoading(false);
          addToast({
            type: 'success',
            title: 'Alterado',
            description: 'Registro alterado com sucesso!',
          });
        }

        const newList = await Promise.all(
          uploadedFiles.map(item => ({
            ...item,
            error: false,
            isUploading: !item.uploaded,
          })),
        );
        setUploadedFiles(newList);

        const listUpload = newList.filter(item => item.isUploading);
        listUpload.forEach(async uploadedFile => {
          let tpArquivo = FTP_KIND_FILE_INDICADORES_OUTROS;
          if (uploadFTP.toString() === '1') {
            tpArquivo = FTP_KIND_FILE_INDICADORES_JSON;
          } else if (uploadFTP.toString() === '2') {
            tpArquivo = FTP_KIND_FILE_INDICADORES_XML;
          } else if (uploadFTP.toString() === '3') {
            tpArquivo = FTP_KIND_FILE_INDICADORES_HTML;
          } else if (uploadFTP.toString() === '4') {
            tpArquivo = FTP_KIND_FILE_INDICADORES_TXT;
          } else if (uploadFTP.toString() === '5') {
            tpArquivo = FTP_KIND_FILE_INDICADORES_CSV;
          } else if (uploadFTP.toString() === '6') {
            tpArquivo = FTP_KIND_FILE_INDICADORES_XLS;
          } else if (uploadFTP.toString() === '7') {
            tpArquivo = FTP_KIND_FILE_INDICADORES_XLSX;
          } else if (uploadFTP.toString() === '8') {
            tpArquivo = FTP_KIND_FILE_INDICADORES_XLSM;
          }

          const dataUpload = new FormData();
          dataUpload.append('file', uploadedFile.file, uploadedFile.name);
          dataUpload.append('file_name_original', uploadedFile.name || '');
          dataUpload.append('id_license', user.license.id);
          dataUpload.append('id_company', user.empresa.id);
          dataUpload.append('tp_arquivo', tpArquivo);
          dataUpload.append('system_kind', user.empresa.active_system_kind.toString());
          dataUpload.append('ano', formData.ano.toString());
          dataUpload.append('id_indic_person_lcto', formData.id_indic_person_lcto);
          dataUpload.append('old_file_path', formData.file_path || '');

          const item: IFile = {
            uploaded: false,
            id: uploadedFile.id,
            name: uploadedFile.name,
            readableSize: uploadedFile.readableSize,
            isUploading: true,
            error: false,
          };

          await api
            .post('/indicadorpersonalizadolcto/v1/file', dataUpload)
            .then(response => {
              item.uploaded = true;
              item.isUploading = false;
              setUploadedFilesFinished([...uploadedFilesFinished, item]);
              addToast({
                type: 'success',
                title: 'Carregado',
                description: `Arquivo ${uploadedFile.name} carregado com sucesso`,
              });
              formData.file_name = response.data.data.file_name;
              formData.file_path = response.data.data.file_path;
              formData.file_type = response.data.data.file_type;
            })
            .catch(() => {
              item.uploaded = false;
              item.error = true;
              item.isUploading = false;
              setUploadedFilesError([...uploadedFilesError, item]);
              addToast({
                type: 'error',
                title: 'Falhou',
                description: `Ocorreu uma falha ao carregar o arquivo ${uploadedFile.name}. Por favor verifique a estrutura do arquivo.`,
              });
            });
        });

        setUploadedFiles([]);

        reset();
        setOpenLoading(false);
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          formRef.current?.setErrors(errors);
        }

        setOpenLoading(false);
        addToast({
          type: 'error',
          title: 'Erro',
          description: 'Ocorreu um erro ao salvar o registro!',
        });
      }
    },
    [
      licencaInvalida,
      listIndicadorPersonalizadoLcto,
      user,
      addToast,
      uploadedFilesFinished,
      uploadedFiles,
      uploadedFilesError,
      uploadFTP,
    ],
  );

  const handleOnFilter = useCallback(async () => {
    setOpenFilter(false);

    const localListIndicadorPersonalizado = getListIndicadorPersonalizado();
    const list = localListIndicadorPersonalizado
      .filter(itemList => itemList.id_cad_indic_person !== '')
      .map(itemList => {
        return `'${itemList.id_cad_indic_person}'`;
      })
      .join();

    let YearTextFilter = '';
    let yearFilterValue = 0;
    const fieldFiltroAno = document.getElementById('uzsad1u-sdf123-fgh99-as0');
    if (fieldFiltroAno && (fieldFiltroAno as HTMLSelectElement).value.trim() !== '') {
      yearFilterValue = parseInt((fieldFiltroAno as HTMLSelectElement).value, 10);
      YearTextFilter = `Ano: ${yearFilterValue}`;
    }

    let typeTextFilter = '';
    let typeFilterValue = '';
    const fieldFiltroSelect = document.getElementById('uzsad1u-sdf123-fgh99-as1');
    if (fieldFiltroSelect) {
      typeFilterValue = (fieldFiltroSelect as HTMLSelectElement).value;
      typeTextFilter = `Tipo: ${typeFilterValue}`;
      if (typeFilterValue === '0-Todos') typeFilterValue = '';
    }

    const listFilterText = localListIndicadorPersonalizado.map(itemList => {
      return `${itemList.cod_indicador.trim()}-${itemList.desc_indicador.trim()}`;
    });

    let text = listFilterText.join();
    if (text.length > 120) {
      text = `${text.substring(0, 120)}...`;
    }

    if (text.trim() === '') {
      text = 'Todos';
    }

    text = `Indicadores: ${text}`;

    setTextFiltro(`   Filtrado por: ${YearTextFilter} - ${typeTextFilter} - ${text}`);

    await getIndicadorPersonalizadoLcto({
      ano: yearFilterValue,
      tipo_apontamento: typeFilterValue,
      listIdsCadIndicPerson: list,
    });
    handleClick('pesquisar');
  }, [handleClick, getListIndicadorPersonalizado, getIndicadorPersonalizadoLcto]);

  const handleOnConfirmIndicadorPersonalizadoSearch = useCallback((list: IIndicadorPersonalizadoLookup[]): void => {
    if (list[0]) {
      setUploadFTP(list[0].upload_ftp);
      const localFindElementIdCadIndicPerson = document.getElementById('345-0m-77-54m');
      if (localFindElementIdCadIndicPerson)
        (localFindElementIdCadIndicPerson as HTMLSelectElement).value = list[0].id_cad_indic_person;

      const localFindElementDescIndicador = document.getElementById('345-0m-77-53m');
      if (localFindElementDescIndicador)
        (localFindElementDescIndicador as HTMLSelectElement).value = list[0].desc_indicador;

      const localFindElementCodIndicador = document.getElementById('asd-32434f-d');
      if (localFindElementCodIndicador)
        (localFindElementCodIndicador as HTMLSelectElement).value = list[0].cod_indicador;
    } else {
      setUploadFTP(0);
    }
  }, []);

  const getIdIndicadorPersonalizado = (): string => {
    const returnId = indicadorPersonalizadoLcto ? indicadorPersonalizadoLcto.id_cad_indic_person : '';
    return returnId;
  };

  const handleSelectFiles = useCallback(<T extends File>(files: T[]): void => {
    const uploadedList = files.map(file => {
      const newFile: IFile = {
        file,
        id: uuid(),
        name: file.name,
        readableSize: filesize(file.size),
        isUploading: false,
        uploaded: false,
        error: false,
      };
      return newFile;
    });

    const newList = [uploadedList[0]];

    setUploadedFiles(newList);
  }, []);

  return (
    <Container>
      <Header />

      <Tab>
        <TabButtonArea>
          <TabButton id="pesquisar" isActive={!hiddenPesquisar} onClick={() => handleClick('pesquisar')}>
            Listagem
          </TabButton>
          <TabButton id="cadastrar" isActive={!hiddenCadastrar} onClick={() => handleClick('cadastrar')}>
            Cadastro
          </TabButton>
          <Tooltip title="Reduzir o tamanho da linha" placement="top">
            <TabButtonGridRowHeight id="gridrowheightdown" onClick={() => handleGridRowHeightDown()}>
              <AiOutlineCaretDown />
            </TabButtonGridRowHeight>
          </Tooltip>

          <Tooltip title="Aumentar o tamanho da linha" placement="top">
            <TabButtonGridRowHeight id="gridrowheightup" onClick={() => handleGridRowHeightUp()}>
              <AiOutlineCaretUp />
            </TabButtonGridRowHeight>
          </Tooltip>

          <TabButton id="filtro" isActive={false} onClick={() => handleClick('filtro')}>
            <AiOutlineSearch />
          </TabButton>

          <Tooltip title="Novo Registro" placement="top">
            <TabButtonNovo id="new_register" onClick={() => OnSetRegisterToAdd()}>
              <GoPlus />
              Novo
            </TabButtonNovo>
          </Tooltip>

          <Tooltip title="Remover Registros Selecionados" placement="top">
            <span>
              <span hidden={selectedIds.length <= 0}>
                <TabButtonDelete
                  id="remove_all"
                  onClick={() => {
                    setIdDeleteIndicadorPersonalizadoLcto('');
                    setOpenDeleteMultiple(true);
                  }}
                >
                  <RiDeleteBinLine />
                  Remover
                </TabButtonDelete>
              </span>
            </span>
          </Tooltip>
        </TabButtonArea>

        <TabTitulo>
          <span>{titulo}</span>
        </TabTitulo>
      </Tab>
      <SpanFiltro>{textFiltro}</SpanFiltro>

      <TabContent id="pesquisar" isActive={!hiddenPesquisar}>
        <div style={{ height: '85vh', width: '100%' }}>
          <ThemeProvider theme={theme}>
            <DataGrid
              rows={listIndicadorPersonalizadoLcto}
              columns={columns}
              rowHeight={gridRowHeight}
              checkboxSelection
              selectionModel={selectedIds}
              disableSelectionOnClick
              onSelectionModelChange={e => setSelectedIds(e.selectionModel)}
              getRowId={r => r.id_indic_person_lcto}
            />
          </ThemeProvider>
        </div>
      </TabContent>

      <TabContent id="cadasto" isActive={!hiddenCadastrar}>
        <Form ref={formRef} onSubmit={handleSubmit} initialData={indicadorPersonalizadoLcto}>
          <FormContainer>
            <FormNavigate>
              <Tooltip title="Novo Registro" placement="top">
                <IconButton color="primary" aria-label="adicionar" onClick={() => onNewRegister()}>
                  <AiOutlinePlus />
                </IconButton>
              </Tooltip>

              <Tooltip title="Duplicar Registro" placement="top">
                <IconButton
                  color="primary"
                  aria-label="Duplicar"
                  onClick={() => OnGridRowDuplicate(indicadorPersonalizadoLcto)}
                >
                  <MdContentCopy />
                </IconButton>
              </Tooltip>

              <Tooltip title="Excluir Registro" placement="top">
                <IconButton
                  color="primary"
                  aria-label="Excluir"
                  onClick={() => OnConfirmationDeleteSingle(indicadorPersonalizadoLcto?.id_indic_person_lcto || '')}
                >
                  <RiDeleteBinLine />
                </IconButton>
              </Tooltip>

              <Tooltip title="Cancelar" placement="top">
                <IconButton type="reset" color="primary" aria-label="Cancelar" onClick={() => onCancelRegister()}>
                  <MdClear />
                </IconButton>
              </Tooltip>

              <Tooltip title="Salvar" placement="top">
                <IconButton type="submit" color="primary" aria-label="Confirmar">
                  <AiOutlineSave />
                </IconButton>
              </Tooltip>

              <Tooltip title="Primeiro Registro" placement="top">
                <IconButton color="primary" aria-label="Primeiro" onClick={() => onFirstRegister()}>
                  <AiFillFastBackward />
                </IconButton>
              </Tooltip>

              <Tooltip title="Registro Anterior" placement="top">
                <IconButton color="primary" aria-label="Anterior" onClick={() => onPriorRegister()}>
                  <MdSkipPrevious />
                </IconButton>
              </Tooltip>

              <Tooltip title="Próximo Registro" placement="top">
                <IconButton color="primary" aria-label="Próximo" onClick={() => onNextRegister()}>
                  <MdSkipNext />
                </IconButton>
              </Tooltip>

              <Tooltip title="Último Registro" placement="top">
                <IconButton color="primary" aria-label="Último registro" onClick={() => onLastRegister()}>
                  <AiFillFastForward />
                </IconButton>
              </Tooltip>
            </FormNavigate>

            <FormFields>
              <ContainerQuadro>
                <ContainerFields field="T300px">
                  <span>Ano:</span>
                  <InputComum autoFocus name="ano" maxLength={4} />
                </ContainerFields>

                <ContainerFields field="T300px" className="field2">
                  <span>Tipo:</span>
                  <FormContainerValueMes>
                    <Select id="uz1u-123-fgh99-000" name="tipo_apontamento" defaultValue="1-Meta">
                      {options_tipo_apontamento.map(indicador => (
                        <option key={indicador.value} value={indicador.value}>
                          {indicador.label}
                        </option>
                      ))}
                    </Select>
                  </FormContainerValueMes>
                </ContainerFields>
              </ContainerQuadro>

              <ContainerFields field="T100">
                <span>Indicador:</span>
                <LookupIndicadorPersonalizado
                  idInputLookup="as00-d8as-d98a79-s7rb-1w8T"
                  in_id={indicadorPersonalizadoLcto?.id_cad_indic_person}
                  onIdIndicadorPersonalizado={getIdIndicadorPersonalizado}
                  onConfirm={handleOnConfirmIndicadorPersonalizadoSearch}
                />
              </ContainerFields>

              <ContainerAllMonthPrice>
                <ContainerQuadro>
                  <ContainerFields field="T300px">
                    <span>Janeiro:</span>

                    <FormContainerValue>
                      <FormContainerValueMes>
                        <InputComum name="vlr_janeiro" placeholder="0,0000" type="number" step="0.0001" />
                      </FormContainerValueMes>
                    </FormContainerValue>
                  </ContainerFields>

                  <ContainerFields field="T300px" className="field2">
                    <span>Fevereiro:</span>
                    <FormContainerValue>
                      <FormContainerValueMes>
                        <InputComum name="vlr_fevereiro" placeholder="0,0000" type="number" step="0.0001" />
                      </FormContainerValueMes>
                    </FormContainerValue>
                  </ContainerFields>
                </ContainerQuadro>

                <ContainerQuadro>
                  <ContainerFields field="T300px">
                    <span>Março:</span>
                    <FormContainerValue>
                      <FormContainerValueMes>
                        <InputComum name="vlr_marco" placeholder="0,0000" type="number" step="0.0001" />
                      </FormContainerValueMes>
                    </FormContainerValue>
                  </ContainerFields>

                  <ContainerFields field="T300px" className="field2">
                    <span>Abril:</span>
                    <FormContainerValue>
                      <FormContainerValueMes>
                        <InputComum name="vlr_abril" placeholder="0,0000" type="number" step="0.0001" />
                      </FormContainerValueMes>
                    </FormContainerValue>
                  </ContainerFields>
                </ContainerQuadro>

                <ContainerQuadro>
                  <ContainerFields field="T300px">
                    <span>Maio:</span>
                    <FormContainerValue>
                      <FormContainerValueMes>
                        <InputComum name="vlr_maio" placeholder="0,0000" type="number" step="0.0001" />
                      </FormContainerValueMes>
                    </FormContainerValue>
                  </ContainerFields>
                  <ContainerFields field="T300px" className="field2">
                    <span>Junho:</span>
                    <FormContainerValue>
                      <FormContainerValueMes>
                        <InputComum name="vlr_junho" placeholder="0,0000" type="number" step="0.0001" />
                      </FormContainerValueMes>
                    </FormContainerValue>
                  </ContainerFields>
                </ContainerQuadro>

                <ContainerQuadro>
                  <ContainerFields field="T300px">
                    <span>Julho:</span>
                    <FormContainerValue>
                      <FormContainerValueMes>
                        <InputComum name="vlr_julho" placeholder="0,0000" type="number" step="0.0001" />
                      </FormContainerValueMes>
                    </FormContainerValue>
                  </ContainerFields>

                  <ContainerFields field="T300px" className="field2">
                    <span>Agosto:</span>
                    <FormContainerValue>
                      <FormContainerValueMes>
                        <InputComum name="vlr_agosto" placeholder="0,0000" type="number" step="0.0001" />
                      </FormContainerValueMes>
                    </FormContainerValue>
                  </ContainerFields>
                </ContainerQuadro>

                <ContainerQuadro>
                  <ContainerFields field="T300px">
                    <span>Setembro:</span>
                    <FormContainerValue>
                      <FormContainerValueMes>
                        <InputComum name="vlr_setembro" placeholder="0,0000" type="number" step="0.0001" />
                      </FormContainerValueMes>
                    </FormContainerValue>
                  </ContainerFields>

                  <ContainerFields field="T300px" className="field2">
                    <span>Outubro:</span>
                    <FormContainerValue>
                      <FormContainerValueMes>
                        <InputComum name="vlr_outubro" placeholder="0,0000" type="number" step="0.0001" />
                      </FormContainerValueMes>
                    </FormContainerValue>
                  </ContainerFields>
                </ContainerQuadro>

                <ContainerQuadro>
                  <ContainerFields field="T300px">
                    <span>Novembro:</span>
                    <FormContainerValue>
                      <FormContainerValueMes>
                        <InputComum name="vlr_novembro" placeholder="0,0000" type="number" step="0.0001" />
                      </FormContainerValueMes>
                    </FormContainerValue>
                  </ContainerFields>

                  <ContainerFields field="T300px" className="field2">
                    <span>Dezembro:</span>
                    <FormContainerValue>
                      <FormContainerValueMes>
                        <InputComum name="vlr_dezembro" placeholder="0,0000" type="number" step="0.0001" />
                      </FormContainerValueMes>
                    </FormContainerValue>
                  </ContainerFields>
                </ContainerQuadro>
              </ContainerAllMonthPrice>

              <ContainerFields field="T100">
                <span>Observação:</span>
                <InputComum name="obs" />
              </ContainerFields>

              <ContainerFields field="T100">
                <span>Informação 1:</span>
                <InputComum name="inf1" />
              </ContainerFields>

              <ContainerFields field="T100">
                <span>Informação 2:</span>
                <InputComum name="inf2" />
              </ContainerFields>

              <ContainerFields field="T100">
                <span>Informação 3:</span>
                <InputComum name="inf3" />
              </ContainerFields>

              <ContainerFields field="T100">
                <span>Informação 4:</span>
                <InputComum name="inf4" />
              </ContainerFields>

              <ContainerFields field="T100">
                <span>Informação 5:</span>
                <InputComum name="inf5" />
              </ContainerFields>

              {uploadFTP > 0 && (
                <ContainerFields field="T100">
                  <div>
                    <span>Arquivo:</span>
                  </div>
                  <div>
                    <span>{indicadorPersonalizadoLcto?.file_name || ''}</span>
                  </div>

                  <UploadFile
                    isAllFiles={['99'].includes(uploadFTP.toString())}
                    isCSVFile={['5'].includes(uploadFTP.toString())}
                    isXLSMFile={['8'].includes(uploadFTP.toString())}
                    isTXTFile={['4'].includes(uploadFTP.toString())}
                    isHTMLFile={['3'].includes(uploadFTP.toString())}
                    isJSONFile={['1'].includes(uploadFTP.toString())}
                    isXMLFile={['2'].includes(uploadFTP.toString())}
                    isExcellFile={['6', '7'].includes(uploadFTP.toString())}
                    onUpload={handleSelectFiles}
                  />
                  {!!uploadedFiles.length && <FileList files={uploadedFiles} />}
                </ContainerFields>
              )}

              <ContainerFields field="TID">
                <InputComum name="id_indic_person_lcto" hidden />
              </ContainerFields>
              <ContainerFields field="TID">
                <InputComum name="id_license" id="saas764d2z2m2" hidden />
              </ContainerFields>
              <ContainerFields field="TID">
                <InputComum name="id_company" id="s654asd56asd2m2" hidden />
              </ContainerFields>
              <ContainerFields field="TID">
                <InputComum name="cod_indicador" id="asd-32434f-d" hidden />
              </ContainerFields>
              <ContainerFields field="TID">
                <InputComum name="desc_indicador" id="345-0m-77-53m" hidden />
              </ContainerFields>
              <ContainerFields field="TID">
                <InputComum name="id_cad_indic_person" id="345-0m-77-54m" hidden />
              </ContainerFields>
              <ContainerFields field="TID">
                <InputComum name="file_name" id="345-0m-77-54m" hidden />
              </ContainerFields>
              <ContainerFields field="TID">
                <InputComum name="file_path" id="345-0m-77-54m" hidden />
              </ContainerFields>
              <ContainerFields field="TID">
                <InputComum name="file_type" id="345-0m-77-54m" hidden />
              </ContainerFields>
            </FormFields>
          </FormContainer>
        </Form>
      </TabContent>

      <FormModalDialogConfirm
        open={openDeleteSingle}
        onClose={() => setOpenDeleteSingle(false)}
        onConfirm={() => handleOnRowDeleteSingle()}
        buttonCancelText="Cancelar"
        buttonConfirmText="Sim"
        formTitle="Excluir?"
        messageConfirm="Tem certeza que deseja excluir?"
      />

      <FormModalDialogConfirm
        open={openDeleteMultiple}
        onClose={() => setOpenDeleteMultiple(false)}
        onConfirm={() => handleOnRowDeleteMultiple()}
        buttonCancelText="Cancelar"
        buttonConfirmText="Sim"
        formTitle="Excluir?"
        messageConfirm="Tem certeza que deseja excluir todos os registros selecionados?"
      />

      <Dialog
        open={openFilter}
        onClose={handleCloseFilter}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle id="alert-dialog-title">Filtro</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">Ano:</DialogContentText>

          <ContainerFieldTextFilter>
            <ContainerFields field="T200px">
              <input
                type="number"
                name="sano"
                id="uzsad1u-sdf123-fgh99-as0"
                step={1}
                defaultValue={new Date().getFullYear()}
              />
            </ContainerFields>
          </ContainerFieldTextFilter>

          <DialogContentText id="alert-dialog-description">Tipo:</DialogContentText>
          <ContainerFields field="T100">
            <ContainerFilterSelect>
              <select id="uzsad1u-sdf123-fgh99-as1" defaultValue="0-Todos">
                <option key="0-Todos" value="0-Todos">
                  0-Todos
                </option>
                {options_tipo_apontamento.map(itemList => (
                  <option key={itemList.value} value={itemList.value}>
                    {itemList.label}
                  </option>
                ))}
              </select>
            </ContainerFilterSelect>
          </ContainerFields>

          <DialogContentText id="alert-dialog-description">Indicador:</DialogContentText>
          <ContainerFields field="T100">
            <LookupIndicadorPersonalizado idInputLookup="23DJ49834I0FDO2J03498T" multiSelect />
          </ContainerFields>
        </DialogContent>
        <DialogActions>
          <ButtonDialog onClick={handleCloseFilter} color="primary" autoFocus>
            Cancelar
          </ButtonDialog>
          <ButtonDialog onClick={handleOnFilter} color="primary">
            Filtrar
          </ButtonDialog>
        </DialogActions>
      </Dialog>

      <Backdrop className={cssLoading.backdrop} open={openLoading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </Container>
  );
};

export default IndicadorPersonalizadoLcto;
