import React, { FC, useState, useCallback } from 'react';
import {
  Button as ButtonDialog,
  Dialog,
  DialogActions,
  DialogContent,
  Select,
  MenuItem,
  FormControl,
} from '@material-ui/core';
import './style.css';
import IFilterProps from './IFilterProps';

const ModalFilter: FC<IFilterProps> = ({ onConfirm, onCancel, open }) => {
  const [filterFieldDate, setFilterFieldDate] = useState('DATA_VENCIMENTO');
  const [selectFieldPeriod, setSelectFieldPeriod] = useState(1);

  const onCloseFilter = useCallback(() => {
    if (onCancel) {
      onCancel();
    }
  }, [onCancel]);

  const onFilter = useCallback(() => {
    if (onConfirm) {
      onConfirm({
        fieldDate: filterFieldDate,
        fieldPeriod: selectFieldPeriod,
      });
    }
  }, [onConfirm, filterFieldDate, selectFieldPeriod]);

  const onChangeFilterData = (event: React.ChangeEvent<{ value: unknown }>): void => {
    setFilterFieldDate(event.target.value as string);
  };

  const onChangeFilterPeriod = (event: React.ChangeEvent<{ value: unknown }>): void => {
    setSelectFieldPeriod(event.target.value as number);
  };

  return (
    <Dialog open={open} onClose={onCloseFilter}>
      <h2 className="astDialogFilterTitle">Filtro</h2>
      <DialogContent style={{ width: '400px', height: '260px' }}>
        <div style={{ marginTop: '20px' }}>
          <div style={{ marginBottom: '8px' }}>
            <span>Tipo Data pesquisa:</span>
          </div>
          <div>
            <FormControl variant="outlined" style={{ width: '100%' }}>
              <Select value={filterFieldDate} onChange={onChangeFilterData}>
                <MenuItem value="DATA">Data Competência</MenuItem>
                <MenuItem value="DATA_VENCIMENTO">Data Vencimento</MenuItem>
              </Select>
            </FormControl>
          </div>
        </div>

        <div style={{ marginTop: '20px' }}>
          <div style={{ marginBottom: '8px' }}>
            <span>Período:</span>
          </div>
          <div>
            <FormControl variant="outlined" style={{ width: '100%' }}>
              <Select value={selectFieldPeriod} onChange={onChangeFilterPeriod}>
                <MenuItem value="1">Hoje</MenuItem>
                <MenuItem value="2">Próximos 7 Dias</MenuItem>
                <MenuItem value="3">Próximos 14 Dias</MenuItem>
                <MenuItem value="4">Próximos 28 dias</MenuItem>
                <MenuItem value="5">Próximos 56 dias</MenuItem>
              </Select>
            </FormControl>
          </div>
        </div>
      </DialogContent>

      <DialogActions>
        <ButtonDialog onClick={onCloseFilter} color="primary" autoFocus>
          Cancelar
        </ButtonDialog>
        <ButtonDialog onClick={onFilter} color="primary">
          Filtrar
        </ButtonDialog>
      </DialogActions>
    </Dialog>
  );
};

export default ModalFilter;
