export default interface IKeyAssociate {
  acao: string;
  id: string;
  token: string;
  name: string;
  macaddress: string;
  id_license: string;
}

export const emptyKeyAssociate: IKeyAssociate = {
  acao: '',
  id: '',
  macaddress: '',
  name: '',
  token: '',
  id_license: '',
};
