import React, { useRef, useState, useEffect, useCallback } from 'react';
import * as Yup from 'yup';

import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';

import { DataGrid, GridColDef, GridCellParams, GridRowId, ptBR } from '@material-ui/data-grid';
import { createMuiTheme, ThemeProvider, makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';

import { FiCopy } from 'react-icons/fi';
import { MdDelete, MdEdit, MdClear, MdSkipNext, MdSkipPrevious, MdContentCopy } from 'react-icons/md';
import {
  AiOutlinePlus,
  AiOutlineSave,
  AiFillFastBackward,
  AiFillFastForward,
  AiOutlineCaretDown,
  AiOutlineCaretUp,
} from 'react-icons/ai';
import { GoPlus } from 'react-icons/go';
import { RiDeleteBinLine } from 'react-icons/ri';

import InputComum from '../../components/InputComum';
import InputDate from '../../components/InputDate';
import Checkbox from '../../components/Checkbox';
import LookupBanco from '../../components/LookupBanco';
import Header from '../../components/Header';
import FormModalDialogConfirm from '../../components/FormModalDialogConfirm';

import api from '../../services/api';
import { useAuth } from '../../hooks/Auth';
import { useToast } from '../../hooks/Toast';
import IBancoLookup from '../../hooks/Lookup/IBancoLookup';

import getListUpdate from '../../utils/getListUpdate';
import { getPathGrid } from '../../utils/storagePath';
import getValidationErrors from '../../utils/getValidationErrors';

import {
  ColumnAtiva,
  ColumnAgencia,
  ColumnCodigoContaCorrente,
  ColumnDescricaoConta,
  ColumnBanco,
  ColumnSaldoInicial,
  ColumnContaContabilIntegracao,
  ColumnObservacao,
  ColumnId,
  ColumnConsiderarFluxoCaixa,
} from './GridColumns';

import IDemGerCadContaCorrente, { emptyContaCorrente } from './IDemGerCadContaCorrente';
import IDemGerCadContaCorrenteSubmit from './IDemGerCadContaCorrenteSubmit';
import IBanco from './IBanco';

import {
  Container,
  ContainerFields,
  Tab,
  TabButton,
  TabContent,
  FormFields,
  FormContainer,
  FormNavigate,
  ContainerFieldDate,
  TabTitulo,
  TabButtonArea,
  ContainerFieldCheckbox,
  TabButtonNovo,
  TabButtonDelete,
  TabButtonGridRowHeight,
} from './styles';

const useStylesLoading = makeStyles((theme: Theme) =>
  createStyles({
    backdrop: {
      zIndex: theme.zIndex.drawer + 2000,
      color: '#fff',
    },
  }),
);

const theme = createMuiTheme(
  {
    palette: {
      primary: { main: '#006699' },
    },
  },
  ptBR,
);

const titulo = 'C O N T A     C O R R E N T E';

const DemGerCadContaCorrente: React.FC = () => {
  const { user } = useAuth();
  const { addToast } = useToast();
  const formRef = useRef<FormHandles>(null);
  const [contaCorrente, setContaCorrente] = useState<IDemGerCadContaCorrente>();
  const [listContaCorrente, setListContaCorrente] = useState<IDemGerCadContaCorrente[]>([]);

  const [listBanco, setListBanco] = useState<IBanco[]>([]);
  const [idBancoPesquisar, setIdBancoPesquisar] = useState<string>('');
  const [hiddenPesquisar, setHiddenPesquisar] = useState<boolean>(false);
  const [hiddenCadastrar, setHiddenCadastrar] = useState<boolean>(true);
  const [contaCorrenteIndex, setContaCorrenteIndex] = useState<number>(0);
  const [rowToEdit, setRowToEdit] = useState(null);
  const [idDeleteContaCorrente, setIdDeleteContaCorrente] = useState<string>('');
  const [selectedIds, setSelectedIds] = useState<GridRowId[]>([]);
  const [openDeleteSingle, setOpenDeleteSingle] = useState(false);
  const [openDeleteMultiple, setOpenDeleteMultiple] = useState(false);
  const [openLoading, setOpenLoading] = useState(false);
  const cssLoading = useStylesLoading();
  const [gridRowHeight, setGridRowHeight] = useState<number>(46);

  const handleGridRowHeightUp = useCallback(async () => {
    const heightValue: number = gridRowHeight + 1;
    setGridRowHeight(heightValue);
    localStorage.setItem(getPathGrid(), heightValue.toString());
  }, [gridRowHeight]);

  const handleGridRowHeightDown = useCallback(async () => {
    const heightValue: number = gridRowHeight - 1;
    setGridRowHeight(heightValue);
    localStorage.setItem(getPathGrid(), heightValue.toString());
  }, [gridRowHeight]);

  const licencaInvalida = useCallback((): boolean => {
    if (!user.license || user.license.id === '') {
      addToast({
        type: 'error',
        title: 'Licença inválida',
        description: 'Usuário não está vinculado a nenhuma licença',
      });

      return true;
    }

    return false;
  }, [addToast, user]);

  const defineContaCorrente = useCallback(async (data: IDemGerCadContaCorrente) => {
    setIdBancoPesquisar(data.id_banco);
    setContaCorrente(data);
    formRef.current?.setData(data);
  }, []);

  const onNewRegister = useCallback(async () => {
    defineContaCorrente(emptyContaCorrente);
  }, [defineContaCorrente]);

  const OnGridSetRegisterToEdit = useCallback(
    async (params: GridCellParams) => {
      const localContaCorrente = params.row as IDemGerCadContaCorrente;

      setContaCorrenteIndex(listContaCorrente.indexOf(localContaCorrente));
      setHiddenCadastrar(false);
      setHiddenPesquisar(true);
      setRowToEdit(null);
      defineContaCorrente(localContaCorrente);
    },
    [listContaCorrente, defineContaCorrente],
  );

  const OnSetRegisterToAdd = useCallback(async () => {
    onNewRegister();
    setHiddenCadastrar(false);
    setHiddenPesquisar(true);
  }, [onNewRegister]);

  const OnGridRowDuplicate = useCallback(
    async (baseContaCorrente: IDemGerCadContaCorrente | undefined) => {
      if (baseContaCorrente !== undefined) {
        const newContaCorrente: IDemGerCadContaCorrente = {
          acao: baseContaCorrente.acao,
          id: '',
          id_license: baseContaCorrente.id_license,
          id_company: baseContaCorrente.id_company,
          id_banco: baseContaCorrente.id_banco,
          agencia: baseContaCorrente.agencia,
          conta_corrente: baseContaCorrente.conta_corrente,
          descricao_conta: baseContaCorrente.descricao_conta,
          obs: baseContaCorrente.obs,
          saldo_inicial: baseContaCorrente.saldo_inicial,
          data_inicio: baseContaCorrente.data_inicio,
          data_fim: baseContaCorrente.data_fim,
          ativa: baseContaCorrente.ativa,
          descricao_banco: baseContaCorrente.descricao_banco,
          conta_contabil: baseContaCorrente.conta_contabil,
          created_user: baseContaCorrente.created_user,
          considerar_fluxo_caixa: baseContaCorrente.considerar_fluxo_caixa,
        };

        defineContaCorrente(newContaCorrente);
        setHiddenCadastrar(false);
        setHiddenPesquisar(true);
      }
    },
    [defineContaCorrente],
  );

  const OnConfirmationDeleteSingle = useCallback(
    async (id: string) => {
      if (licencaInvalida()) return;

      if (id === '') return;

      setIdDeleteContaCorrente(id);
      setOpenDeleteSingle(true);
    },
    [licencaInvalida],
  );

  const ColumnAcao: GridColDef = {
    field: 'acao',
    headerName: 'Ação',
    editable: false,
    filterable: false,
    hideSortIcons: true,
    sortable: false,
    disableColumnMenu: true,
    width: 160,
    renderCell: (params: GridCellParams) => (
      <strong>
        <Tooltip title="Editar Registro" placement="top">
          <IconButton color="primary" aria-label="Editar" onClick={() => OnGridSetRegisterToEdit(params)}>
            <MdEdit />
          </IconButton>
        </Tooltip>

        <Tooltip title="Duplicar Registro" placement="top">
          <IconButton
            color="primary"
            aria-label="Duplicar"
            onClick={() => OnGridRowDuplicate(params.row as IDemGerCadContaCorrente)}
          >
            <FiCopy />
          </IconButton>
        </Tooltip>

        <Tooltip title="Excluir Registro" placement="top">
          <IconButton
            color="primary"
            aria-label="Excluir"
            onClick={() => OnConfirmationDeleteSingle((params.row as IDemGerCadContaCorrente).id)}
          >
            <MdDelete />
          </IconButton>
        </Tooltip>
      </strong>
    ),
  };

  const ColumnDataInicio: GridColDef = {
    field: 'data_inicio',
    headerName: 'Data início',
    width: 150,
    type: 'date',
    renderCell: (params: GridCellParams) => {
      if (params.value === null) return <strong />;
      const ano = (params.value as string).substring(0, 4);
      const mes = (params.value as string).substring(5, 7);
      const dia = (params.value as string).substring(8, 10);

      const dataFormatada = `${dia}/${mes}/${ano}`;
      return <strong>{dataFormatada}</strong>;
    },
  };

  const ColumnDataFim: GridColDef = {
    field: 'data_fim',
    headerName: 'Data fim',
    width: 150,
    type: 'date',
    renderCell: (params: GridCellParams) => {
      if (params.value === null) return <strong />;
      const ano = (params.value as string).substring(0, 4);
      const mes = (params.value as string).substring(5, 7);
      const dia = (params.value as string).substring(8, 10);

      const dataFormatada = `${dia}/${mes}/${ano}`;
      return <strong>{dataFormatada}</strong>;
    },
  };

  const columns: GridColDef[] = [
    ColumnAcao,
    ColumnBanco,
    ColumnAgencia,
    ColumnCodigoContaCorrente,
    ColumnSaldoInicial,
    ColumnDataInicio,
    ColumnDataFim,
    ColumnAtiva,
    ColumnDescricaoConta,
    ColumnContaContabilIntegracao,
    ColumnConsiderarFluxoCaixa,
    ColumnObservacao,
    ColumnId,
  ];

  const getBanco = useCallback(async () => {
    const response = await api.get<IBanco[]>(`/cadbanco`);

    setListBanco(response.data);
  }, []);

  const getDemGerCadContaCorrente = useCallback(async () => {
    const response = await api.post<IDemGerCadContaCorrente[]>(`/demgercadcontacorrente/filter`, {
      id_license: user.license.id,
      id_company: user.empresa.id,
    });

    setListContaCorrente(response.data);

    if (rowToEdit !== null) {
      const findEditContaCorrente = response.data.find(item => item.id === rowToEdit);

      if (findEditContaCorrente) {
        defineContaCorrente(findEditContaCorrente);
        setContaCorrenteIndex(response.data.indexOf(findEditContaCorrente));
        setHiddenCadastrar(false);
        setHiddenPesquisar(true);
        setRowToEdit(null);
      }
    }
  }, [user, rowToEdit, defineContaCorrente]);

  const getValues = useCallback(async () => {
    if (licencaInvalida()) return;

    setOpenLoading(true);

    await getBanco();
    await getDemGerCadContaCorrente();

    setOpenLoading(false);
    setHiddenPesquisar(false);
  }, [licencaInvalida, getBanco, getDemGerCadContaCorrente]);

  useEffect(() => {
    getValues();

    const rowHeight = localStorage.getItem(getPathGrid());
    if (rowHeight) {
      setGridRowHeight(parseInt(rowHeight, 10));
    }
  }, [getValues]);

  const handleOnRowDeleteSingle = useCallback(async () => {
    setOpenLoading(true);

    await api.delete(`/demgercadcontacorrente/${idDeleteContaCorrente}`);

    setListContaCorrente(oldDemGerCadContaCorrente =>
      oldDemGerCadContaCorrente.filter(item => item.id !== idDeleteContaCorrente),
    );

    defineContaCorrente(emptyContaCorrente);

    addToast({
      type: 'success',
      title: 'Removido',
      description: 'Registro excluído com sucesso!',
    });

    setOpenDeleteSingle(false);
    setOpenLoading(false);
  }, [idDeleteContaCorrente, addToast, defineContaCorrente]);

  const handleOnRowDeleteMultiple = useCallback(async () => {
    setOpenLoading(true);

    if (selectedIds.length > 0) {
      await Promise.all(
        selectedIds.map(itemId => {
          setListContaCorrente(oldDemGerCadContaCorrente =>
            oldDemGerCadContaCorrente.filter(item => item.id !== itemId),
          );

          return api.delete(`/demgercadcontacorrente/${itemId}`);
        }),
      );

      defineContaCorrente(emptyContaCorrente);

      addToast({
        type: 'success',
        title: 'Removido',
        description: 'Registro excluído com sucesso!',
      });
    }

    setSelectedIds([]);
    setOpenDeleteMultiple(false);
    setOpenLoading(false);
  }, [addToast, defineContaCorrente, selectedIds]);

  const handleClick = (id: string): void => {
    setHiddenCadastrar(id === 'pesquisar');
    setHiddenPesquisar(id === 'cadastrar');
  };

  const onNextRegister = useCallback(async () => {
    if (contaCorrenteIndex !== undefined) {
      if (contaCorrenteIndex < listContaCorrente.length - 1) {
        const localIndex = contaCorrenteIndex + 1;
        setContaCorrenteIndex(localIndex);
        const findEditContaCorrente = listContaCorrente[localIndex];
        if (findEditContaCorrente) {
          defineContaCorrente(findEditContaCorrente);
        }
      }
    }
  }, [contaCorrenteIndex, listContaCorrente, defineContaCorrente]);

  const onFirstRegister = useCallback(async () => {
    if (listContaCorrente.length > 0) {
      setContaCorrenteIndex(0);
      const findEditContaCorrente = listContaCorrente[0];
      if (findEditContaCorrente) {
        defineContaCorrente(findEditContaCorrente);
      }
    }
  }, [listContaCorrente, defineContaCorrente]);

  const onLastRegister = useCallback(async () => {
    if (listContaCorrente.length > 0) {
      setContaCorrenteIndex(listContaCorrente.length - 1);
      const findEditContaCorrente = listContaCorrente[listContaCorrente.length - 1];
      if (findEditContaCorrente) {
        defineContaCorrente(findEditContaCorrente);
      }
    }
  }, [listContaCorrente, defineContaCorrente]);

  const onPriorRegister = useCallback(async () => {
    if (contaCorrenteIndex !== undefined) {
      if (contaCorrenteIndex > 0) {
        const localIndex = contaCorrenteIndex - 1;
        setContaCorrenteIndex(localIndex);
        const findEditContaCorrente = listContaCorrente[localIndex];
        if (findEditContaCorrente) {
          defineContaCorrente(findEditContaCorrente);
        }
      }
    }
  }, [contaCorrenteIndex, listContaCorrente, defineContaCorrente]);

  const onCancelRegister = useCallback(async () => {
    formRef.current?.setErrors({});

    if (contaCorrenteIndex !== undefined) {
      const findEditContaCorrente = listContaCorrente[contaCorrenteIndex];
      if (findEditContaCorrente) {
        defineContaCorrente(findEditContaCorrente);
      }
    }

    onNewRegister();
  }, [contaCorrenteIndex, listContaCorrente, onNewRegister, defineContaCorrente]);

  const getDescricaoBanco = useCallback(
    (id: string): string => {
      const findBanco = listBanco.find(item => item.id === id);
      if (findBanco) {
        return `${findBanco.banco.trim()}-${findBanco.descricao.trim()}`;
      }

      return '';
    },
    [listBanco],
  );

  const handleSubmit = useCallback(
    async (data: IDemGerCadContaCorrente, { reset }) => {
      if (licencaInvalida()) return;

      formRef.current?.setErrors({});
      try {
        const schema = Yup.object().shape({
          id_banco: Yup.string().required('Banco é obrigatório'),
          agencia: Yup.string().required('Agência é obrigatória'),
          conta_corrente: Yup.string().required('Conta corrente é obrigatória'),
          descricao_conta: Yup.string().required('Nome da conta é obrigatória'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        setOpenLoading(true);
        const formData: IDemGerCadContaCorrenteSubmit = {
          id: data.id,
          id_company: user.empresa.id,
          id_license: user.license.id,
          id_banco: data.id_banco,
          agencia: data.agencia,
          conta_corrente: data.conta_corrente,
          descricao_conta: data.descricao_conta,
          saldo_inicial: data.saldo_inicial ? data.saldo_inicial : 0,
          obs: data.obs,
          descricao_banco: data.descricao_banco,
          data_inicio: new Date(`${data.data_inicio.substring(0, 10)}T12:00:00`),
          data_fim: data.data_fim ? new Date(`${data.data_fim.substring(0, 10)}T12:00:00`) : null,
          ativa: data.ativa,
          conta_contabil: data.conta_contabil,
          created_user: user.id,
          acao: data.acao,
          considerar_fluxo_caixa: data.considerar_fluxo_caixa,
        };

        data.descricao_banco = getDescricaoBanco(data.id_banco);
        if (data.id === '') {
          const response = await api.post(`/demgercadcontacorrente`, formData);
          formData.id = response.data.id;

          const newContaCorrente: IDemGerCadContaCorrente = {
            ...formData,
            id: response.data.id,
            data_inicio: data.data_inicio,
            data_fim: data.data_fim,
          };

          setListContaCorrente(oldValues => [...oldValues, newContaCorrente]);

          addToast({
            type: 'success',
            title: 'Adicionado',
            description: 'Registro adicionado com sucesso!',
          });
        } else {
          await api.put(`/demgercadcontacorrente/${data.id}`, data);

          setListContaCorrente(getListUpdate(listContaCorrente, data));

          addToast({
            type: 'success',
            title: 'Alterado',
            description: 'Registro alterado com sucesso!',
          });
        }

        setIdBancoPesquisar('');
        reset();
        setOpenLoading(false);
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          formRef.current?.setErrors(errors);
        }
        setOpenLoading(false);
      }
    },
    [licencaInvalida, listContaCorrente, user, addToast, getDescricaoBanco],
  );

  const onIdBancoLookup = useCallback((): string => {
    return idBancoPesquisar;
  }, [idBancoPesquisar]);

  const handleOnConfirmBancoSearchRegister = useCallback((list: IBancoLookup[]): void => {
    if (!list.length) return;
    const bancoLookupData: IBancoLookup = list[0];
    const fieldIdBanco = document.getElementById('saas764d2z2m2');
    if (fieldIdBanco) {
      (fieldIdBanco as HTMLSelectElement).value = bancoLookupData.id;
    }

    const fieldBancoNome = document.getElementById('777u78rw239XA');
    if (fieldBancoNome) {
      (fieldBancoNome as HTMLSelectElement).value = `${bancoLookupData.banco.trim()}-${bancoLookupData.descricao.trim()}`;
    }
  }, []);

  return (
    <Container>
      <Header />

      <Tab>
        <TabButtonArea>
          <TabButton id="pesquisar" isActive={!hiddenPesquisar} onClick={() => handleClick('pesquisar')}>
            Listagem
          </TabButton>
          <TabButton id="cadastrar" isActive={!hiddenCadastrar} onClick={() => handleClick('cadastrar')}>
            Cadastro
          </TabButton>

          <Tooltip title="Reduzir o tamanho da linha" placement="top">
            <TabButtonGridRowHeight id="gridrowheightdown" onClick={() => handleGridRowHeightDown()}>
              <AiOutlineCaretDown />
            </TabButtonGridRowHeight>
          </Tooltip>

          <Tooltip title="Aumentar o tamanho da linha" placement="top">
            <TabButtonGridRowHeight id="gridrowheightup" onClick={() => handleGridRowHeightUp()}>
              <AiOutlineCaretUp />
            </TabButtonGridRowHeight>
          </Tooltip>

          <Tooltip title="Novo Registro" placement="top">
            <TabButtonNovo id="new_register" onClick={() => OnSetRegisterToAdd()}>
              <GoPlus />
              Novo
            </TabButtonNovo>
          </Tooltip>

          <Tooltip title="Remover Registros Selecionados" placement="top">
            <span>
              <span hidden={selectedIds.length <= 0}>
                <TabButtonDelete
                  id="remove_all"
                  onClick={() => {
                    setIdDeleteContaCorrente('');
                    setOpenDeleteMultiple(true);
                  }}
                >
                  <RiDeleteBinLine />
                  Remover
                </TabButtonDelete>
              </span>
            </span>
          </Tooltip>
        </TabButtonArea>

        <TabTitulo>
          <span>{titulo}</span>
        </TabTitulo>
      </Tab>

      <TabContent id="pesquisar" isActive={!hiddenPesquisar}>
        <div style={{ height: '85vh', width: '100%' }}>
          <ThemeProvider theme={theme}>
            <DataGrid
              rows={listContaCorrente}
              columns={columns}
              rowHeight={gridRowHeight}
              checkboxSelection
              selectionModel={selectedIds}
              disableSelectionOnClick
              onSelectionModelChange={e => setSelectedIds(e.selectionModel)}
            />
          </ThemeProvider>
          ;
        </div>
      </TabContent>

      <TabContent id="cadasto" isActive={!hiddenCadastrar}>
        <Form ref={formRef} onSubmit={handleSubmit} initialData={contaCorrente}>
          <FormContainer>
            <FormNavigate>
              <Tooltip title="Novo Registro" placement="top">
                <IconButton color="primary" aria-label="adicionar" onClick={() => onNewRegister()}>
                  <AiOutlinePlus />
                </IconButton>
              </Tooltip>

              <Tooltip title="Duplicar Registro" placement="top">
                <IconButton color="primary" aria-label="Duplicar" onClick={() => OnGridRowDuplicate(contaCorrente)}>
                  <MdContentCopy />
                </IconButton>
              </Tooltip>

              <Tooltip title="Excluir Registro" placement="top">
                <IconButton
                  color="primary"
                  aria-label="Excluir"
                  onClick={() => OnConfirmationDeleteSingle(contaCorrente ? contaCorrente.id : '')}
                >
                  <RiDeleteBinLine />
                </IconButton>
              </Tooltip>

              <Tooltip title="Cancelar" placement="top">
                <IconButton type="reset" color="primary" aria-label="Cancelar" onClick={() => onCancelRegister()}>
                  <MdClear />
                </IconButton>
              </Tooltip>

              <Tooltip title="Salvar" placement="top">
                <IconButton type="submit" color="primary" aria-label="Confirmar">
                  <AiOutlineSave />
                </IconButton>
              </Tooltip>

              <Tooltip title="Primeiro Registro" placement="top">
                <IconButton color="primary" aria-label="Primeiro" onClick={() => onFirstRegister()}>
                  <AiFillFastBackward />
                </IconButton>
              </Tooltip>

              <Tooltip title="Registro Anterior" placement="top">
                <IconButton color="primary" aria-label="Anterior" onClick={() => onPriorRegister()}>
                  <MdSkipPrevious />
                </IconButton>
              </Tooltip>

              <Tooltip title="Próximo Registro" placement="top">
                <IconButton color="primary" aria-label="Próximo" onClick={() => onNextRegister()}>
                  <MdSkipNext />
                </IconButton>
              </Tooltip>

              <Tooltip title="Último Registro" placement="top">
                <IconButton color="primary" aria-label="Último registro" onClick={() => onLastRegister()}>
                  <AiFillFastForward />
                </IconButton>
              </Tooltip>
            </FormNavigate>

            <FormFields>
              <ContainerFieldDate>
                <ContainerFieldCheckbox field="T300px">
                  <Checkbox name="ativa" />
                  <span>Ativa</span>
                </ContainerFieldCheckbox>

                <ContainerFieldCheckbox field="T300px" className="field2">
                  <Checkbox name="considerar_fluxo_caixa" />
                  <span>Considerar fluxo caixa</span>
                </ContainerFieldCheckbox>
              </ContainerFieldDate>

              <ContainerFields field="T100">
                <span>Banco:</span>
                <LookupBanco
                  idInputLookup="457e41fcfbeb879885d734e16dc9f178"
                  in_id={idBancoPesquisar}
                  onConfirm={handleOnConfirmBancoSearchRegister}
                  onIdBanco={onIdBancoLookup}
                />
              </ContainerFields>

              <ContainerFields field="T200px">
                <span>Agência:</span>
                <InputComum autoFocus name="agencia" maxLength={255} />
              </ContainerFields>

              <ContainerFields field="T200px">
                <span>Conta corrente:</span>
                <InputComum name="conta_corrente" maxLength={255} />
              </ContainerFields>

              <ContainerFields field="T200px">
                <span>Saldo Inicial:</span>
                <InputComum name="saldo_inicial" placeholder="0,00" type="number" step="0.01" />
              </ContainerFields>

              <ContainerFieldDate>
                <ContainerFields field="T300px">
                  <span>Data início:</span>
                  <InputDate name="data_inicio" />
                </ContainerFields>

                <ContainerFields field="T300px" className="field2">
                  <span>Data fim:</span>
                  <InputDate name="data_fim" />
                </ContainerFields>
              </ContainerFieldDate>

              <ContainerFields field="T100">
                <span>Descrição:</span>
                <InputComum name="descricao_conta" maxLength={255} />
              </ContainerFields>

              <ContainerFields field="T100">
                <span>Observação:</span>
                <InputComum name="obs" maxLength={500} />
              </ContainerFields>

              <ContainerFields field="T100">
                <span>Conta Contábil Integração:</span>
                <InputComum name="conta_contabil" maxLength={100} />
              </ContainerFields>

              <ContainerFields field="id">
                <InputComum name="id_banco" id="saas764d2z2m2" hidden />
              </ContainerFields>
              <ContainerFields field="id">
                <InputComum name="descricao_banco" id="777u78rw239XA" hidden />
              </ContainerFields>
              <ContainerFields field="id">
                <InputComum name="id" hidden />
              </ContainerFields>
            </FormFields>
          </FormContainer>
        </Form>
      </TabContent>

      <FormModalDialogConfirm
        open={openDeleteSingle}
        onClose={() => setOpenDeleteSingle(false)}
        onConfirm={() => handleOnRowDeleteSingle()}
        buttonCancelText="Cancelar"
        buttonConfirmText="Sim"
        formTitle="Excluir?"
        messageConfirm="Tem certeza que deseja excluir?"
      />

      <FormModalDialogConfirm
        open={openDeleteMultiple}
        onClose={() => setOpenDeleteMultiple(false)}
        onConfirm={() => handleOnRowDeleteMultiple()}
        buttonCancelText="Cancelar"
        buttonConfirmText="Sim"
        formTitle="Excluir?"
        messageConfirm="Tem certeza que deseja excluir todos os registros selecionados?"
      />

      <Backdrop className={cssLoading.backdrop} open={openLoading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </Container>
  );
};

export default DemGerCadContaCorrente;
