import React from 'react';
import { useHistory } from 'react-router-dom';
import ViewList from '@material-ui/icons/ViewList';
import Description from '@material-ui/icons/Description';
import { FaTools } from 'react-icons/fa';

import {
  ROUTE_DEM_GER_CAD_CONTA,
  ROUTE_DEM_GER_CAD_CENTRO_CUSTO,
  ROUTE_HISTORICO_IMPORTACAO,
  ROUTE_CFG_PERIODO_CONTABIL,
} from '../../utils/constants';
import ButtonDropdownMenu from './ButtonDropdownMenu';
import { ContainerDropdown, DropdownContent } from './styles';

const MenuTool: React.FC = () => {
  const history = useHistory();

  const handleGoDemGerCadConta = (): void => {
    history.push(ROUTE_DEM_GER_CAD_CONTA);
  };

  const handleGoDemGerCadCCusto = (): void => {
    history.push(ROUTE_DEM_GER_CAD_CENTRO_CUSTO);
  };

  const handleGoHistoricoImportacao = (): void => {
    history.push(ROUTE_HISTORICO_IMPORTACAO);
  };

  const handleGoCfgPeriodoContabil = (): void => {
    history.push(ROUTE_CFG_PERIODO_CONTABIL);
  };

  return (
    <ContainerDropdown>
      <button type="button" style={{ height: '47.99px' }}>
        <FaTools size={19} />
      </button>
      <DropdownContent>
        <ButtonDropdownMenu onClick={handleGoCfgPeriodoContabil} icon={ViewList}>
          <span>Período Contábil</span>
        </ButtonDropdownMenu>
        <ButtonDropdownMenu onClick={handleGoDemGerCadConta} icon={ViewList}>
          <span>Contas</span>
        </ButtonDropdownMenu>
        <ButtonDropdownMenu onClick={handleGoDemGerCadCCusto} icon={ViewList}>
          <span>Centro de Custo</span>
        </ButtonDropdownMenu>
        <ButtonDropdownMenu onClick={handleGoHistoricoImportacao} icon={Description}>
          <span>Arquivos SPED</span>
        </ButtonDropdownMenu>
      </DropdownContent>
    </ContainerDropdown>
  );
};

export default MenuTool;
