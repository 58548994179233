import React, { useCallback, useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import Button from '../../components/Button';
import { useAuth } from '../../hooks/Auth';
import { getPathUser } from '../../utils/storagePath';
import { IAppInfoEmpresa, ActiveSystemKindOptions } from '../../utils/appInfo';
import api from '../../services/api';
import ILicence from './ILicense';
import ComponentDescriptionSystemKind from './ComponentDescriptionSystemKind';
import { Container, Content, MySelect, ContainerGroup } from './styles';

const idSystemKind = '6dfs4';

interface IUserCompanyDTO {
  id_user: string;
  id_license: string;
  id_company: string;
  active: boolean;
  admin: boolean;
  license_user: boolean;
  system_kind: number;
  url_powerbi_accouting_analysis: string;
  url_powerbi_financial_accounts: string;
  url_powerbi_human_resources: string;
  url_powerbi_cost_analysis: string;
  tipo_licenca: string;
}

const Profile: React.FC = () => {
  const { user } = useAuth();
  const history = useHistory();
  const [listLicenses, setListLicenses] = useState<ILicence[]>([]);
  const [listCompanies, setListCompanies] = useState<IAppInfoEmpresa[]>([]);
  const [listSystemKind, setListSystemKind] = useState<string[]>([]);
  const [idLicense, setIdLicense] = useState('');
  const [idCompany, setIdCompany] = useState('');
  const accountingAnalysis = 'Accounting Analysis';
  const financialAccounts = 'Financial Accounts';
  const costAnalysis = 'Cost Analysis';
  const humanResources = 'Human Resources';

  const getActiveSystemKindOptions = (id: string): ActiveSystemKindOptions => {
    if (id === accountingAnalysis) return ActiveSystemKindOptions.Option_AccountingAnalysis;
    if (id === financialAccounts) return ActiveSystemKindOptions.Option_FinancialAccounts;
    if (id === costAnalysis) return ActiveSystemKindOptions.Option_CostAnalysis;
    if (id === humanResources) return ActiveSystemKindOptions.Option_HumanResources;
    return ActiveSystemKindOptions.Option_AccountingAnalysis;
  };

  const handleSubmit = useCallback(async () => {
    if (idLicense !== '' && idCompany !== '') {
      const localLicense = listLicenses.find(item => item.id === idLicense);
      const localCompany = listCompanies.find(item => item.id === idCompany);
      if (localLicense && localCompany) {
        user.license = {
          id: localLicense.id,
          blocked: localLicense.blocked,
          cnpj: localLicense.cnpj,
          data_inicio: localLicense.data_inicio,
          data_fim: localLicense.data_fim,
          descricao: localLicense.descricao,
          email_admin_user: localLicense.email_admin_user,
          token: localLicense.token,
          url_powerbi: localLicense.url_powerbi,
          url_powerbi_cost_analysis: localLicense.url_powerbi_cost_analysis,
          url_powerbi_financial_accounts: localLicense.url_powerbi_financial_accounts,
          url_powerbi_human_resources: localLicense.url_powerbi_human_resources,
          quantidade_cnpj: localLicense.quantidade_cnpj,
          tipo_licenca: localLicense.tipo_licenca,
        };

        let system_kind_temp: ActiveSystemKindOptions = ActiveSystemKindOptions.Option_AccountingAnalysis;

        if (listSystemKind.length === 1) {
          system_kind_temp = getActiveSystemKindOptions(listSystemKind[0]);
        } else {
          const fieldSelectSystemKind = document.getElementById(idSystemKind);
          if (fieldSelectSystemKind) {
            const id = (fieldSelectSystemKind as HTMLSelectElement).value;
            system_kind_temp = getActiveSystemKindOptions(id);
          }
        }

        localCompany.active_system_kind = system_kind_temp;
        user.empresa = localCompany;

        localStorage.removeItem(getPathUser());
        localStorage.setItem(getPathUser(), JSON.stringify(user));
        history.push('/dashboardpowerbi');
        // history.push('/');
      }
    }
  }, [user, idLicense, idCompany, history, listLicenses, listCompanies, listSystemKind]);

  const setSelectSystemKind = (kind: string): void => {
    const localKind: number = parseInt(kind, 0);
    const localListSystemKind: string[] = [];

    if ((localKind & 1) > 0) {
      localListSystemKind.push(accountingAnalysis);
    }

    if ((localKind & 2) > 0) {
      localListSystemKind.push(financialAccounts);
    }

    if ((localKind & 4) > 0) {
      localListSystemKind.push(costAnalysis);
    }

    if ((localKind & 8) > 0) {
      localListSystemKind.push(humanResources);
    }

    setListSystemKind(localListSystemKind);
  };

  const defineKind = useCallback(
    async (idEmpresa: string): Promise<void> => {
      const findCompany = listCompanies.find(item => item.id === idEmpresa);
      if (findCompany) {
        setSelectSystemKind(findCompany.system_kind.toString());
      }
    },
    [listCompanies],
  );

  const carregarEmpresas = useCallback(
    async (inIdLicense): Promise<void> => {
      const responseCompany = await api.get<IAppInfoEmpresa[]>(`/company/${inIdLicense}`);

      const responseUserCompanies = await api.get<IUserCompanyDTO[]>(`/usercompany/user/all`, {
        params: {
          license: inIdLicense,
          email: user.email,
        },
      });

      const localCompanies = responseCompany.data
        .map(itemList => {
          const findUserCompany = responseUserCompanies.data.find(element => element.id_company === itemList.id);

          if (findUserCompany) {
            return {
              ...itemList,
              system_kind:
                findUserCompany.admin || findUserCompany.license_user
                  ? itemList.system_kind
                  : findUserCompany.system_kind,
              user_powerbi: {
                url_powerbi_accouting_analysis: findUserCompany.url_powerbi_accouting_analysis,
                url_powerbi_financial_accounts: findUserCompany.url_powerbi_financial_accounts,
                url_powerbi_human_resources: findUserCompany.url_powerbi_human_resources,
                url_powerbi_cost_analysis: findUserCompany.url_powerbi_cost_analysis,
              },
            };
          }

          return {
            ...itemList,
            system_kind: itemList.admin ? itemList.system_kind : 0,
            user_powerbi: {
              url_powerbi_accouting_analysis: '',
              url_powerbi_financial_accounts: '',
              url_powerbi_human_resources: '',
              url_powerbi_cost_analysis: '',
            },
          };
        })
        .filter(itemList => itemList.system_kind > 0);

      setListCompanies(localCompanies);
      if (localCompanies && localCompanies.length > 0) {
        let idEmpresa = localCompanies[0].id;
        setSelectSystemKind(localCompanies[0].system_kind.toString());
        if (user.empresa) {
          const findCompany = localCompanies.find(item => item.id === user.empresa.id);

          if (findCompany) {
            idEmpresa = findCompany.id;
            setSelectSystemKind(findCompany.system_kind.toString());
          }
        }

        setIdCompany(idEmpresa);
      }
    },
    [user],
  );

  const carregarLicencas = useCallback(async (): Promise<void> => {
    const responseLicense = await api.get<ILicence[]>(`/profile/alllicenses/${user.email}`);
    setListLicenses(responseLicense.data);
    if (responseLicense.data && responseLicense.data.length > 0) {
      let { id } = responseLicense.data[0];
      if (user.license) {
        const findLicense = responseLicense.data.find(item => item.id === user.license.id);

        if (findLicense) id = findLicense.id;
      }

      setIdLicense(id);
      carregarEmpresas(id);
    }
  }, [user, carregarEmpresas]);

  const handleSelectLicenseChange = (event: React.ChangeEvent<{ value: unknown }>): void => {
    const id: string = event.target.value as string;
    setIdLicense(id);
    carregarEmpresas(id);
  };

  const handleSelectCompanyChange = (event: React.ChangeEvent<{ value: unknown }>): void => {
    const id: string = event.target.value as string;
    setIdCompany(id);
    defineKind(id);
  };

  useEffect(() => {
    carregarLicencas();
  }, [carregarLicencas]);

  return (
    <Container>
      <Content>
        <form onSubmit={handleSubmit}>
          <h1>Selecione a empresa</h1>
          <ContainerGroup>
            <span>Licença</span>
            <MySelect value={idLicense} onChange={handleSelectLicenseChange}>
              {listLicenses.map(item => (
                <option value={item.id} key={item.id}>
                  {item.cnpj.trim()}-{item.descricao.trim()}
                </option>
              ))}
            </MySelect>
          </ContainerGroup>

          <ContainerGroup>
            <span>Empresa</span>
            <MySelect value={idCompany} onChange={handleSelectCompanyChange}>
              {listCompanies.map(item => (
                <option value={item.id} key={item.id}>
                  {item.cnpj.trim()}-{item.name.trim()}
                </option>
              ))}
            </MySelect>
          </ContainerGroup>

          <ContainerGroup>
            <span>Sistema</span>

            {listSystemKind.length > 1 && (
              <MySelect id={idSystemKind}>
                {listSystemKind.map(item => (
                  <option value={item} key={item}>
                    {item}
                  </option>
                ))}
              </MySelect>
            )}

            {listSystemKind.length === 1 && <ComponentDescriptionSystemKind kind={listSystemKind[0]} />}
          </ContainerGroup>

          <Button type="submit">Confirmar</Button>
        </form>
      </Content>
    </Container>
  );
};

export default Profile;
