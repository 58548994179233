import React from 'react';
import { Box, Paper } from '@material-ui/core';

import './style.css';

interface IAccountValues {
  label: string;
  value: number;
  descricao_conta: string;
  banco: string;
  descricao_banco: string;
  agencia: string;
  conta_corrente: string;
}

interface ICardBigNumbersProps {
  listCheckingAccount: IAccountValues[];
}

const CardCheckingAccount: React.FC<ICardBigNumbersProps> = ({ listCheckingAccount }) => {
  return (
    <Paper variant="outlined" style={{ height: '160px', margin: '5px' }}>
      <Box>
        <div className="astCardCheckingAccountTitle">
          <div>
            <span className="astCardCheckingAccountTitleSpan">Saldo contas correntes</span>
          </div>
          <div className="astCardCheckingAccountLegenda">
            <span className="astCardCheckingAccountLegendaItem">Conta</span>
            <span className="astCardCheckingAccountLegendaItem">Valor (R$)</span>
          </div>
        </div>

        <div className="astCardCheckingAccountList">
          {listCheckingAccount.map(itemList => {
            return (
              <div key={`${itemList.label}-${itemList.value}`} className="astCardCheckingAccountValues">
                <div>
                  <div>
                    <span
                      style={{
                        fontSize: '14px',
                        color: itemList.value < 0 ? 'red' : 'default',
                      }}
                    >
                      {itemList.descricao_conta}
                    </span>
                  </div>
                </div>

                <div style={{ minWidth: '100px', textAlign: 'end' }}>
                  <span style={{ color: itemList.value < 0 ? 'red' : 'default' }}>
                    {new Intl.NumberFormat('pt-BR', {
                      minimumFractionDigits: 2,
                    }).format(Math.abs(itemList.value))}
                  </span>
                </div>
              </div>
            );
          })}
        </div>
      </Box>
    </Paper>
  );
};

export default CardCheckingAccount;
