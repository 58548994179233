import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import { MdCheckCircle, MdError } from 'react-icons/md';

import IFile from './IFile';
import { Container, FileInfo } from './styles';

interface IFileListProps {
  files: IFile[];
}

const FileList: React.FC<IFileListProps> = ({ files }) => {
  return (
    <Container>
      {files.map(uploadedFile => (
        <li key={uploadedFile.id}>
          <FileInfo>
            <div>
              <strong>{uploadedFile.name}</strong>
              <span>{uploadedFile.readableSize} </span>
              {uploadedFile.error && (
                <span
                  style={{
                    color: '#e57878',
                  }}
                >
                  {uploadedFile.message}{' '}
                </span>
              )}
            </div>
          </FileInfo>

          <div>
            {uploadedFile.isUploading && !uploadedFile.error && <CircularProgress />}

            {uploadedFile.uploaded && <MdCheckCircle size={24} color="#78e5d5" />}
            {uploadedFile.error && <MdError size={24} color="#e57878" />}
          </div>
        </li>
      ))}
    </Container>
  );
};

export default FileList;
