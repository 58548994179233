import React, { useRef, useState, useEffect, useCallback } from 'react';
import * as Yup from 'yup';

import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';

import { DataGrid, GridColDef, GridCellParams, GridRowId, ptBR } from '@material-ui/data-grid';
import { createMuiTheme, ThemeProvider, makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';

import { FiCopy } from 'react-icons/fi';
import { MdDelete, MdEdit, MdClear, MdSkipNext, MdSkipPrevious, MdContentCopy } from 'react-icons/md';
import {
  AiOutlinePlus,
  AiOutlineSave,
  AiFillFastBackward,
  AiFillFastForward,
  AiOutlineCaretDown,
  AiOutlineCaretUp,
} from 'react-icons/ai';
import { GoPlus } from 'react-icons/go';
import { RiDeleteBinLine } from 'react-icons/ri';

import InputComum from '../../components/InputComum';
import Select from '../../components/Select';
import Header from '../../components/Header';
import FormModalDialogConfirm from '../../components/FormModalDialogConfirm';

import api from '../../services/api';
import { useAuth } from '../../hooks/Auth';
import { useToast } from '../../hooks/Toast';

import getListUpdate from '../../utils/getListUpdate';
import getValidationErrors from '../../utils/getValidationErrors';
import { getPathGrid } from '../../utils/storagePath';

import { ColumnDemonstrativo, ColumnEmpresa, ColumnId } from './GridColumns';

import ITipoDemGerCompany from './ITipoDemGerCompany';
import ICompany from './ICompany';
import ITipoDemGer from './ITipoDemGer';

import {
  Container,
  ContainerFields,
  Tab,
  TabButton,
  TabContent,
  FormFields,
  FormContainer,
  FormNavigate,
  TabTitulo,
  TabButtonArea,
  TabButtonNovo,
  TabButtonDelete,
  TabButtonGridRowHeight,
} from './styles';

const useStylesLoading = makeStyles((theme: Theme) =>
  createStyles({
    backdrop: {
      zIndex: theme.zIndex.drawer + 2000,
      color: '#fff',
    },
  }),
);

const theme = createMuiTheme(
  {
    palette: {
      primary: { main: '#006699' },
    },
  },
  ptBR,
);

const emptyTipoDemGerCompany: ITipoDemGerCompany = {
  acao: '',
  id: '',
  id_company: '',
  id_tipo_dem_ger: '',
  id_license: '',
  empresa: '',
  demonstrativo: '',
};

const titulo = 'D E M O N S T R A T I V O S';
const idfieldSelectCompany = 'a00000265njbkfdswonikjd2';
const idfieldSelectTipoDemGer = 'a0000s51swdf515533r';

const TipoDemGerCompany: React.FC = () => {
  const { user } = useAuth();
  const { addToast } = useToast();
  const formRef = useRef<FormHandles>(null);
  const [demGerParametro, setTipoDemGerCompany] = useState<ITipoDemGerCompany>();
  const [listTipoDemGerCompany, setListTipoDemGerCompany] = useState<ITipoDemGerCompany[]>([]);
  const [listTipoDemGer, setListTipoDemGer] = useState<ITipoDemGer[]>([]);
  const [listCompany, setListCompany] = useState<ICompany[]>([]);
  const [hiddenPesquisar, setHiddenPesquisar] = useState<boolean>(true);
  const [hiddenCadastrar, setHiddenCadastrar] = useState<boolean>(true);
  const [tipoDemGerCompanyIndex, setTipoDemGerCompanyIndex] = useState<number>(0);
  const [rowToEdit, setRowToEdit] = useState(null);
  const [idDeleteTipoDemGerCompany, setIdDeleteTipoDemGerCompany] = useState<string>('');
  const [selectedIds, setSelectedIds] = useState<GridRowId[]>([]);
  const [openDeleteSingle, setOpenDeleteSingle] = useState(false);
  const [openDeleteMultiple, setOpenDeleteMultiple] = useState(false);
  const [openLoading, setOpenLoading] = useState(false);
  const cssLoading = useStylesLoading();
  const [gridRowHeight, setGridRowHeight] = useState<number>(46);

  const handleGridRowHeightUp = useCallback(async () => {
    const heightValue: number = gridRowHeight + 1;
    setGridRowHeight(heightValue);
    localStorage.setItem(getPathGrid(), heightValue.toString());
  }, [gridRowHeight]);

  const handleGridRowHeightDown = useCallback(async () => {
    const heightValue: number = gridRowHeight - 1;
    setGridRowHeight(heightValue);
    localStorage.setItem(getPathGrid(), heightValue.toString());
  }, [gridRowHeight]);

  const licencaInvalida = useCallback((): boolean => {
    if (!user.license || user.license.id === '') {
      addToast({
        type: 'error',
        title: 'Licença inválida',
        description: 'Usuário não está vinculado a nenhuma licença',
      });

      return true;
    }

    return false;
  }, [addToast, user]);

  const getDescricaoCompanySelectOption = useCallback((value: ICompany): string => {
    if (!value) return '';

    return `${value.code.trim()}-${value.name.trim()}[${value.cnpj.trim()}]`;
  }, []);

  const getCompanySelectOption = useCallback(
    (id: string): ICompany => {
      return listCompany.filter(item => item.id === id)[0];
    },
    [listCompany],
  );

  const getDescricaoTipoDemGerSelectOption = useCallback((value: ITipoDemGer): string => {
    if (!value) return '';

    return `${value.codigo.trim()}-${value.descricao.trim()}`;
  }, []);

  const getTipoDemGerSelectOption = useCallback(
    (id: string): ITipoDemGer => {
      return listTipoDemGer.filter(item => item.id === id)[0];
    },
    [listTipoDemGer],
  );

  const defineTipoDemGerCompany = useCallback(async (data: ITipoDemGerCompany) => {
    setTipoDemGerCompany(data);
    formRef.current?.setData(data);
  }, []);

  const onNewRegister = useCallback(async () => {
    defineTipoDemGerCompany(emptyTipoDemGerCompany);
  }, [defineTipoDemGerCompany]);

  const OnGridSetRegisterToEdit = useCallback(
    async (params: GridCellParams) => {
      const localTipoDemGerCompany = params.row as ITipoDemGerCompany;

      setTipoDemGerCompanyIndex(listTipoDemGerCompany.indexOf(localTipoDemGerCompany));
      setHiddenCadastrar(false);
      setHiddenPesquisar(true);
      setRowToEdit(null);
      defineTipoDemGerCompany(localTipoDemGerCompany);
    },
    [listTipoDemGerCompany, defineTipoDemGerCompany],
  );

  const OnSetRegisterToAdd = useCallback(async () => {
    onNewRegister();
    setHiddenCadastrar(false);
    setHiddenPesquisar(true);
  }, [onNewRegister]);

  const OnGridRowDuplicate = useCallback(
    async (baseTipoDemGerCompany: ITipoDemGerCompany | undefined) => {
      if (baseTipoDemGerCompany !== undefined) {
        const newTipoDemGerCompany: ITipoDemGerCompany = {
          acao: baseTipoDemGerCompany.acao,
          id: '',
          id_company: baseTipoDemGerCompany.id_company,
          id_tipo_dem_ger: baseTipoDemGerCompany.id_tipo_dem_ger,
          id_license: baseTipoDemGerCompany.id_license,
          empresa: baseTipoDemGerCompany.empresa,
          demonstrativo: baseTipoDemGerCompany.demonstrativo,
        };

        defineTipoDemGerCompany(newTipoDemGerCompany);
        setHiddenCadastrar(false);
        setHiddenPesquisar(true);
      }
    },
    [defineTipoDemGerCompany],
  );

  const OnConfirmationDeleteSingle = useCallback(
    async (id: string) => {
      if (licencaInvalida()) return;

      if (id === '') return;

      setIdDeleteTipoDemGerCompany(id);
      setOpenDeleteSingle(true);
    },
    [licencaInvalida],
  );

  const colunaAcao: GridColDef = {
    field: 'acao',
    headerName: 'Ação',
    editable: false,
    filterable: false,
    hideSortIcons: true,
    sortable: false,
    disableColumnMenu: true,
    width: 160,
    renderCell: (params: GridCellParams) => (
      <strong>
        <Tooltip title="Editar Registro" placement="top">
          <IconButton color="primary" aria-label="Editar" onClick={() => OnGridSetRegisterToEdit(params)}>
            <MdEdit />
          </IconButton>
        </Tooltip>

        <Tooltip title="Duplicar Registro" placement="top">
          <IconButton
            color="primary"
            aria-label="Duplicar"
            onClick={() => OnGridRowDuplicate(params.row as ITipoDemGerCompany)}
          >
            <FiCopy />
          </IconButton>
        </Tooltip>

        <Tooltip title="Excluir Registro" placement="top">
          <IconButton
            color="primary"
            aria-label="Excluir"
            onClick={() => OnConfirmationDeleteSingle((params.row as ITipoDemGerCompany).id)}
          >
            <MdDelete />
          </IconButton>
        </Tooltip>
      </strong>
    ),
  };

  const columns: GridColDef[] = [colunaAcao, ColumnDemonstrativo, ColumnEmpresa, ColumnId];

  const getTipoDemGer = useCallback(async () => {
    const response = await api.get<ITipoDemGer[]>(`/tipodemger/all`);
    setListTipoDemGer(response.data);
  }, []);

  const getCompany = useCallback(async () => {
    const response = await api.get<ICompany[]>(`/company/${user.license.id}`);
    setListCompany(response.data);
  }, [user]);

  const getTipoDemGerCompany = useCallback(async () => {
    const response = await api.get<ITipoDemGerCompany[]>(`/tipodemgercompany/all/me/${user.license.id}`);
    setListTipoDemGerCompany(response.data);

    if (rowToEdit !== null) {
      const findEditUserLicense = response.data.find(item => item.id === rowToEdit);

      if (findEditUserLicense) {
        defineTipoDemGerCompany(findEditUserLicense);
        setTipoDemGerCompanyIndex(response.data.indexOf(findEditUserLicense));
        setHiddenCadastrar(false);
        setHiddenPesquisar(true);
        setRowToEdit(null);
      }
    }
  }, [user, defineTipoDemGerCompany, rowToEdit]);

  const getValues = useCallback(async () => {
    setOpenLoading(true);

    await getTipoDemGer();
    await getCompany();
    await getTipoDemGerCompany();

    setOpenLoading(false);
    setHiddenPesquisar(false);
  }, [getTipoDemGerCompany, getTipoDemGer, getCompany]);

  useEffect(() => {
    getValues();

    const rowHeight = localStorage.getItem(getPathGrid());
    if (rowHeight) {
      setGridRowHeight(parseInt(rowHeight, 10));
    }
  }, [getValues]);

  const handleOnRowDeleteSingle = useCallback(async () => {
    setOpenLoading(true);
    await api.delete(`/tipodemgercompany/${idDeleteTipoDemGerCompany}`);

    setListTipoDemGerCompany(OldValue => OldValue.filter(item => item.id !== idDeleteTipoDemGerCompany));

    defineTipoDemGerCompany(emptyTipoDemGerCompany);

    addToast({
      type: 'success',
      title: 'Removido',
      description: 'Registro excluído com sucesso!',
    });

    setOpenDeleteSingle(false);
    setOpenLoading(false);
  }, [idDeleteTipoDemGerCompany, addToast, defineTipoDemGerCompany]);

  const handleOnRowDeleteMultiple = useCallback(async () => {
    setOpenLoading(true);
    if (selectedIds.length > 0) {
      await Promise.all(
        selectedIds.map(itemId => {
          setListTipoDemGerCompany(OldValue => OldValue.filter(item => item.id !== itemId));

          return api.delete(`/tipodemgercompany/${itemId}`);
        }),
      );

      defineTipoDemGerCompany(emptyTipoDemGerCompany);

      addToast({
        type: 'success',
        title: 'Removido',
        description: 'Registro excluído com sucesso!',
      });
    }

    setSelectedIds([]);
    setOpenDeleteMultiple(false);
    setOpenLoading(false);
  }, [addToast, defineTipoDemGerCompany, selectedIds]);

  const handleClick = (id: string): void => {
    setHiddenCadastrar(id === 'pesquisar');
    setHiddenPesquisar(id === 'cadastrar');
  };

  const onNextRegister = useCallback(async () => {
    if (tipoDemGerCompanyIndex !== undefined) {
      if (tipoDemGerCompanyIndex < listTipoDemGerCompany.length - 1) {
        const localIndex = tipoDemGerCompanyIndex + 1;
        setTipoDemGerCompanyIndex(localIndex);
        const findItem = listTipoDemGerCompany[localIndex];
        if (findItem) {
          defineTipoDemGerCompany(findItem);
        }
      }
    }
  }, [tipoDemGerCompanyIndex, listTipoDemGerCompany, defineTipoDemGerCompany]);

  const onFirstRegister = useCallback(async () => {
    if (listTipoDemGerCompany.length > 0) {
      setTipoDemGerCompanyIndex(0);
      const findItem = listTipoDemGerCompany[0];
      if (findItem) {
        defineTipoDemGerCompany(findItem);
      }
    }
  }, [listTipoDemGerCompany, defineTipoDemGerCompany]);

  const onLastRegister = useCallback(async () => {
    if (listTipoDemGerCompany.length > 0) {
      setTipoDemGerCompanyIndex(listTipoDemGerCompany.length - 1);
      const findItem = listTipoDemGerCompany[listTipoDemGerCompany.length - 1];
      if (findItem) {
        defineTipoDemGerCompany(findItem);
      }
    }
  }, [listTipoDemGerCompany, defineTipoDemGerCompany]);

  const onPriorRegister = useCallback(async () => {
    if (tipoDemGerCompanyIndex !== undefined) {
      if (tipoDemGerCompanyIndex > 0) {
        const localIndex = tipoDemGerCompanyIndex - 1;
        setTipoDemGerCompanyIndex(localIndex);
        const findItem = listTipoDemGerCompany[localIndex];
        if (findItem) {
          defineTipoDemGerCompany(findItem);
        }
      }
    }
  }, [tipoDemGerCompanyIndex, listTipoDemGerCompany, defineTipoDemGerCompany]);

  const onCancelRegister = useCallback(async () => {
    formRef.current?.setErrors({});

    if (tipoDemGerCompanyIndex !== undefined) {
      const findItem = listTipoDemGerCompany[tipoDemGerCompanyIndex];
      if (findItem) {
        defineTipoDemGerCompany(findItem);
      }
    }

    onNewRegister();
  }, [tipoDemGerCompanyIndex, listTipoDemGerCompany, onNewRegister, defineTipoDemGerCompany]);

  const handleSubmit = useCallback(
    async (data: ITipoDemGerCompany, { reset }) => {
      if (licencaInvalida()) return;

      const fieldSelectCompany = document.getElementById(idfieldSelectCompany);
      if (fieldSelectCompany) {
        const id = (fieldSelectCompany as HTMLSelectElement).value;
        data.id_company = id;
        data.empresa = getDescricaoCompanySelectOption(getCompanySelectOption(id));
      }

      const fieldSelectTipoDemGer = document.getElementById(idfieldSelectTipoDemGer);
      if (fieldSelectTipoDemGer) {
        const id = (fieldSelectTipoDemGer as HTMLSelectElement).value;
        data.id_tipo_dem_ger = id;
        data.demonstrativo = getDescricaoTipoDemGerSelectOption(getTipoDemGerSelectOption(id));
      }

      formRef.current?.setErrors({});
      try {
        const schema = Yup.object().shape({
          id_tipo_dem_ger: Yup.string().required('Demonstrativo é obrigatório'),
          id_company: Yup.string().required('Empresa é obrigatória'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        setOpenLoading(true);
        if (data.id === '') {
          const formData: ITipoDemGerCompany = {
            acao: data.acao,
            id: '',
            id_license: user.license.id,
            id_company: data.id_company,
            id_tipo_dem_ger: data.id_tipo_dem_ger,
            empresa: data.empresa,
            demonstrativo: data.demonstrativo,
          };

          const response = await api.post(`/tipodemgercompany`, formData);
          formData.id = response.data.id;

          setListTipoDemGerCompany(oldValues => [...oldValues, formData]);

          addToast({
            type: 'success',
            title: 'Adicionado',
            description: 'Registro adicionado com sucesso!',
          });
        } else {
          const formEdit = {
            id_license: user.license.id,
            id_company: data.id_company,
            id_tipo_dem_ger: data.id_tipo_dem_ger,
            empresa: data.empresa,
            demonstrativo: data.demonstrativo,
          };

          await api.put(`/tipodemgercompany/${data.id}`, formEdit);

          setListTipoDemGerCompany(getListUpdate(listTipoDemGerCompany, data));

          addToast({
            type: 'success',
            title: 'Alterado',
            description: 'Registro alterado com sucesso!',
          });
        }

        reset();
        setOpenLoading(false);
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          formRef.current?.setErrors(errors);
        }

        setOpenLoading(false);
      }
    },
    [
      licencaInvalida,
      listTipoDemGerCompany,
      user,
      addToast,
      getCompanySelectOption,
      getDescricaoCompanySelectOption,
      getDescricaoTipoDemGerSelectOption,
      getTipoDemGerSelectOption,
    ],
  );

  return (
    <Container>
      <Header />

      <Tab>
        <TabButtonArea>
          <TabButton id="pesquisar" isActive={!hiddenPesquisar} onClick={() => handleClick('pesquisar')}>
            Listagem
          </TabButton>
          <TabButton id="cadastrar" isActive={!hiddenCadastrar} onClick={() => handleClick('cadastrar')}>
            Cadastro
          </TabButton>
          <Tooltip title="Reduzir o tamanho da linha" placement="top">
            <TabButtonGridRowHeight id="gridrowheightdown" onClick={() => handleGridRowHeightDown()}>
              <AiOutlineCaretDown />
            </TabButtonGridRowHeight>
          </Tooltip>

          <Tooltip title="Aumentar o tamanho da linha" placement="top">
            <TabButtonGridRowHeight id="gridrowheightup" onClick={() => handleGridRowHeightUp()}>
              <AiOutlineCaretUp />
            </TabButtonGridRowHeight>
          </Tooltip>

          <Tooltip title="Novo Registro" placement="top">
            <TabButtonNovo id="new_register" onClick={() => OnSetRegisterToAdd()}>
              <GoPlus />
              Novo
            </TabButtonNovo>
          </Tooltip>

          <Tooltip title="Remover Registros Selecionados" placement="top">
            <span>
              <span hidden={selectedIds.length <= 0}>
                <TabButtonDelete
                  id="remove_all"
                  onClick={() => {
                    setIdDeleteTipoDemGerCompany('');
                    setOpenDeleteMultiple(true);
                  }}
                >
                  <RiDeleteBinLine />
                  Remover
                </TabButtonDelete>
              </span>
            </span>
          </Tooltip>
        </TabButtonArea>

        <TabTitulo>
          <span>{titulo}</span>
        </TabTitulo>
      </Tab>

      <TabContent id="pesquisar" isActive={!hiddenPesquisar}>
        <div style={{ height: '85vh', width: '100%' }}>
          <ThemeProvider theme={theme}>
            <DataGrid
              rows={listTipoDemGerCompany}
              columns={columns}
              rowHeight={gridRowHeight}
              checkboxSelection
              selectionModel={selectedIds}
              disableSelectionOnClick
              onSelectionModelChange={e => setSelectedIds(e.selectionModel)}
            />
          </ThemeProvider>
        </div>
      </TabContent>

      <TabContent id="cadasto" isActive={!hiddenCadastrar}>
        <Form ref={formRef} onSubmit={handleSubmit} initialData={demGerParametro}>
          <FormContainer>
            <FormNavigate>
              <Tooltip title="Novo Registro" placement="top">
                <IconButton color="primary" aria-label="adicionar" onClick={() => onNewRegister()}>
                  <AiOutlinePlus />
                </IconButton>
              </Tooltip>

              <Tooltip title="Duplicar Registro" placement="top">
                <IconButton color="primary" aria-label="Duplicar" onClick={() => OnGridRowDuplicate(demGerParametro)}>
                  <MdContentCopy />
                </IconButton>
              </Tooltip>

              <Tooltip title="Excluir Registro" placement="top">
                <IconButton
                  color="primary"
                  aria-label="Excluir"
                  onClick={() => OnConfirmationDeleteSingle(demGerParametro ? demGerParametro.id : '')}
                >
                  <RiDeleteBinLine />
                </IconButton>
              </Tooltip>

              <Tooltip title="Cancelar" placement="top">
                <IconButton type="reset" color="primary" aria-label="Cancelar" onClick={() => onCancelRegister()}>
                  <MdClear />
                </IconButton>
              </Tooltip>

              <Tooltip title="Salvar" placement="top">
                <IconButton type="submit" color="primary" aria-label="Confirmar">
                  <AiOutlineSave />
                </IconButton>
              </Tooltip>

              <Tooltip title="Primeiro Registro" placement="top">
                <IconButton color="primary" aria-label="Primeiro" onClick={() => onFirstRegister()}>
                  <AiFillFastBackward />
                </IconButton>
              </Tooltip>

              <Tooltip title="Registro Anterior" placement="top">
                <IconButton color="primary" aria-label="Anterior" onClick={() => onPriorRegister()}>
                  <MdSkipPrevious />
                </IconButton>
              </Tooltip>

              <Tooltip title="Próximo Registro" placement="top">
                <IconButton color="primary" aria-label="Próximo" onClick={() => onNextRegister()}>
                  <MdSkipNext />
                </IconButton>
              </Tooltip>

              <Tooltip title="Último Registro" placement="top">
                <IconButton color="primary" aria-label="Último registro" onClick={() => onLastRegister()}>
                  <AiFillFastForward />
                </IconButton>
              </Tooltip>
            </FormNavigate>

            <FormFields>
              <ContainerFields field="T100">
                <span>Demonstrativo:</span>
                <Select id={idfieldSelectTipoDemGer} name="id_tipo_dem_ger">
                  {listTipoDemGer.map(item => (
                    <option key={item.id} value={item.id}>
                      {getDescricaoTipoDemGerSelectOption(item)}
                    </option>
                  ))}
                </Select>
              </ContainerFields>

              <ContainerFields field="T100">
                <span>Empresa:</span>
                <Select id={idfieldSelectCompany} name="id_company">
                  {listCompany.map(item => (
                    <option key={item.id} value={item.id}>
                      {getDescricaoCompanySelectOption(item)}
                    </option>
                  ))}
                </Select>
              </ContainerFields>

              <ContainerFields field="id">
                <InputComum name="id" hidden />
              </ContainerFields>
            </FormFields>
          </FormContainer>
        </Form>
      </TabContent>

      <FormModalDialogConfirm
        open={openDeleteSingle}
        onClose={() => setOpenDeleteSingle(false)}
        onConfirm={() => handleOnRowDeleteSingle()}
        buttonCancelText="Cancelar"
        buttonConfirmText="Sim"
        formTitle="Excluir?"
        messageConfirm="Tem certeza que deseja excluir?"
      />

      <FormModalDialogConfirm
        open={openDeleteMultiple}
        onClose={() => setOpenDeleteMultiple(false)}
        onConfirm={() => handleOnRowDeleteMultiple()}
        buttonCancelText="Cancelar"
        buttonConfirmText="Sim"
        formTitle="Excluir?"
        messageConfirm="Tem certeza que deseja excluir todos os registros selecionados?"
      />

      <Backdrop className={cssLoading.backdrop} open={openLoading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </Container>
  );
};

export default TipoDemGerCompany;
